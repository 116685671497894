import { gql } from '@apollo/client';

import {
    GetTeamInvitesQuery,
    LicenseTypesEnum,
    TeamFeaturesByTeamIdFeatureQuery,
    TeamRolesEnum,
} from 'codegen/graphql';

// Drop down in UI will map to two tables at the same time:
// =======================================================
// UI: Reviewer         > Role: User    + License: Reviewer
// UI: Full License     > Role: User    + License: Full
// UI: Admin            > Role: Admin   + License: Full

export type roleLabelsType = 'role-owner-label' | 'role-admin-label' | 'role-full-label' | 'role-reviewer-label';

export type TeamInviteData = GetTeamInvitesQuery['team_invites'][number];
export type TeamInvitesData = GetTeamInvitesQuery['team_invites'];
export type TeamFeaturesByTeamIdFeatureData = TeamFeaturesByTeamIdFeatureQuery['data'];

const TEAM_INVITE_DETAILS = gql`
    fragment TeamInviteDetails on team_invites {
        id
        invitee
        role
        licenseType
        status
        createdAt
        team {
            seatsCount
        }
    }
`;

export const CREATE_TEAM_INVITES = gql`
    mutation CreateTeamInvites($invites: [team_invites_insert_input!]!) {
        insert_team_invites(objects: $invites, on_conflict: { constraint: team_invites_pkey, update_columns: [] }) {
            affected_rows
        }
    }
`;

export const UPDATE_TEAM_INVITE_BY_ID = gql`
    mutation UpdateTeamInviteById($id: String!, $set: team_invites_set_input!) {
        update_team_invites_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
            ...TeamInviteDetails
        }
    }
    ${TEAM_INVITE_DETAILS}
`;

export const REMOVE_TEAM_INVITE = gql`
    mutation RemoveTeamInvite($id: String!) {
        delete_team_invites_by_pk(id: $id) {
            id
        }
    }
`;

export const GET_TEAM_INVITES = gql`
    query GetTeamInvites($where: team_invites_bool_exp!, $orderBy: team_invites_order_by!) {
        team_invites(where: $where, order_by: [$orderBy]) {
            ...TeamInviteDetails
        }
    }
    ${TEAM_INVITE_DETAILS}
`;

const TEAM_MEMBERS_DETAILS = gql`
    fragment TeamMembersDetails on users {
        id
        fullName
        email
        role
        licenseType
        status
        team {
            seatsCount
        }
        createdAt
    }
`;

export interface TeamMember {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    role: TeamRolesEnum;
    licenseType: LicenseTypesEnum;
    status: string;
    createdAt: number;
    team: { seatsCount: number };
}

export const GET_TEAM_MEMBERS = gql`
    query GetTeamMembers($where: users_bool_exp!, $orderBy: users_order_by!) {
        users(where: $where, order_by: [$orderBy]) {
            ...TeamMembersDetails
        }
    }
    ${TEAM_MEMBERS_DETAILS}
`;

export interface TeamMatesData {
    currentUser: {
        team: {
            users: Array<{
                id: string;
                fullName: string;
            }>;
        };
    };
}

export const GET_TEAMMATES_OF_USER = gql`
    query GetTeammatesOfUserById($id: String!) {
        currentUser: users_by_pk(id: $id) {
            team {
                users {
                    id
                    fullName
                    status
                }
            }
        }
    }
`;

export const GET_TEAM_AI_SOURCING_CREDITS = gql`
    query TeamAISourcingCredits {
        teams {
            id
            aiSourcerCredits
        }
    }
`;

export const GET_TEAM_BY_ID = gql`
    query Teams($id: String!) {
        teams_by_pk(id: $id) {
            id
            name
            planType
            seatsCount
            aiSourcerCredits
            customerId
            subscriptionId
        }
    }
`;

export const GET_USER_TEAM = gql`
    query UserTeam {
        teams {
            id
            name
            planType
            seatsCount
            aiSourcerCredits
            customerId
            subscriptionId
            owner: users(where: { role: { _eq: "owner" } }) {
                id
                fullName
            }
        }
    }
`;

export const GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE = gql`
    query TeamFeaturesByTeamIdFeature($teamId: String!, $feature: hireflow_enums_team_features_enum!) {
        data: team_features(where: { teamId: { _eq: $teamId }, feature: { _eq: $feature } }) {
            feature
        }
    }
`;

export const TEAM_FEATURE_CHECK = gql`
    query TeamFeatureCheck($feature: hireflow_enums_team_features_enum!) {
        team_features(where: { feature: { _eq: $feature } }) {
            feature
        }
    }
`;
