import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme, StepperProps as MuiStepperProps, Stepper as MuiStepper, Step, StepLabel } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Title } from 'shared/components/presentational';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface StepperProps extends MuiStepperProps {
    steps: string[];
    activeStep: number;
}

const StepperContainer: FC<StepperProps> = (props) => {
    const { steps, activeStep, className } = props;
    const translate = useTranslations('sequence');
    const theme = useTheme();

    return (
        <MuiStepper {...props} alternativeLabel className={className}>
            {steps.map((step: string, index: number) => (
                <Step
                    key={step}
                    css={css`
                        border-bottom: 3px solid
                            ${activeStep === index ? theme?.palette.primary.main : theme?.palette.grey[100]};
                    `}
                >
                    <StepLabel
                        css={css`
                            * {
                                color: ${activeStep < index && theme?.palette.grey[100]};
                            }
                        `}
                    >
                        <p
                            css={css`
                                font-size: ${fontSizes.f14};
                                margin: 0;
                                text-align: left;
                            `}
                        >
                            {translate('step')} {index + 1}
                        </p>
                        <Title
                            type="h3"
                            css={css`
                                font-size: ${fontSizes.f18};
                                text-align: left;
                            `}
                        >
                            {step}
                        </Title>
                    </StepLabel>
                </Step>
            ))}
        </MuiStepper>
    );
};

const Stepper = styled(StepperContainer)<StepperProps>`
    .MuiStepLabel-iconContainer,
    .MuiStepConnector-line {
        display: none;
    }
`;

export { Stepper };
