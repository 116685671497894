import { gql } from '@apollo/client';

export const GET_SEQUENCE_ANALYTICS_SENDS_BY_STAGE_BY_TIME_PERIOD = gql`
    query SequenceAnalyticsSendsByStageByTimePeriod(
        $sequence: String
        $start_time: bigint
        $end_time: bigint
        $time_unit: String
        $time_zone: String
    ) {
        stats: get_sequence_analytics_sends_by_stage_by_time_period(
            args: {
                sequence: $sequence
                start_time: $start_time
                end_time: $end_time
                time_unit: $time_unit
                time_zone: $time_zone
            }
        ) {
            sequenceId
            date
            stageIndex
            sends
        }
    }
`;

export const GET_SEQUENCE_ANALYTICS_REPLIES_BY_TIME_PERIOD = gql`
    query SequenceAnalyticsRepliesByTimePeriod(
        $sequence: String
        $start_time: bigint
        $end_time: bigint
        $time_unit: String
        $time_zone: String
    ) {
        stats: get_sequence_analytics_replies_by_time_period(
            args: {
                sequence: $sequence
                start_time: $start_time
                end_time: $end_time
                time_unit: $time_unit
                time_zone: $time_zone
            }
        ) {
            sequenceId
            date
            replies
            interested
        }
    }
`;
