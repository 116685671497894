import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Hyperlink, IconButton } from 'shared/components/presentational';
import { LinkedinLogo } from 'shared/components/svgs';
import { addHttpsToUrl } from 'shared/services';
import { FC } from 'shared/types';

interface ProspectCardLinkedInButtonProps {
    linkedinUrl?: string;
}

const ProspectCardLinkedInButton: FC<ProspectCardLinkedInButtonProps> = ({ linkedinUrl }) => {
    const theme = useTheme();

    const button = (
        <IconButton
            css={css`
                width: 44px;
                height: 44px;
                background: ${theme.palette.primary.contrastText};
            `}
        >
            <LinkedinLogo width={24} height={24} />
        </IconButton>
    );

    if (linkedinUrl) {
        return (
            <Hyperlink href={addHttpsToUrl(linkedinUrl)} newTab>
                {button}
            </Hyperlink>
        );
    }
    return button;
};

export { ProspectCardLinkedInButton };
