import { useTranslations } from 'next-intl';
import * as Yup from 'yup';

import { GreenhouseApplicationTypeEnum } from 'codegen/graphql';
import { ConnectEditFormButtons } from 'integrations/components/composite/greenhouse';
import { ConnectEditTemplate } from 'integrations/components/templates/greenhouse';
import { CreateEditFormValues } from 'integrations/types/greenhouse';
import { Form } from 'shared/components/form';
import { useDynamicSchemaForm, useSnackbarAlert } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

interface ConnectFormProps {
    onClose: () => void;
    refetch: () => void;
}

const ConnectForm: FC<ConnectFormProps> = ({ onClose, refetch }) => {
    const translate = useTranslations('integrations.greenhouse');

    const { showSnackbarAlert } = useSnackbarAlert();

    const validationSchema = Yup.object().shape({
        apiToken: Yup.string().required(translate('error-api-token')),
    });

    const formInitialValues: CreateEditFormValues = {
        apiToken: '',
        defaultSourceId: '',
        addAsApplicationType: GreenhouseApplicationTypeEnum.Prospect,
    };

    const formMethods = useDynamicSchemaForm<CreateEditFormValues>({
        mode: 'onBlur',
        defaultValues: formInitialValues,
        schema: validationSchema,
    });

    const onSubmit = async () => {
        const values = formMethods.getValues();
        const { apiToken } = values;
        const restApiClient = createRestApiClient();
        const connectResponse = await restApiClient.connectGreenhouseToken({
            apiToken,
        });

        if (connectResponse?.success) {
            refetch();
            onClose();
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-saved'),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-saved'),
            });
        }
    };

    return (
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <ConnectEditTemplate formMethods={formMethods} />
            <ConnectEditFormButtons canEdit onClose={onClose} />
        </Form>
    );
};

export { ConnectForm };
