import { css, useTheme } from '@mui/material';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import { standardizeUrl } from 'profile-parser';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { SourcerMemberStatusEnum, SourcerStatusEnum } from 'codegen/graphql';
import { EditName, ProspectTopBar, ScrollSectionEnum } from 'prospects';
import { AiSourcedTarget, StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Button, Hyperlink, Title } from 'shared/components/presentational';
import { ArrowUpRight } from 'shared/components/svgs';
import { windowFeatures, windowTarget } from 'shared/constants';
import { ProspectRecipientData } from 'shared/graphql/recipients';
import { useProspectPanel } from 'shared/hooks';
import { appRoutes, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectProjectMember, ProspectSourcerMember } from 'shared/types/prospect';

interface ProspectTitleBarProps {
    onClose: () => void;
    openAddTab: () => void;
    redirectToSection: (section: ScrollSectionEnum) => void;
}

const ProspectTitleBar: FC<ProspectTitleBarProps> = (props) => {
    const { recipients, prospectInfo, context, sourcerMembers, members } = useProspectPanel();
    const { openAddTab, onClose, redirectToSection } = props;
    const translate = useTranslations('prospects.profile');
    const theme = useTheme();
    const [hideEdit, setHideEdit] = useState<boolean>(true);
    const [hideNameEdit, setHideNameEdit] = useState<boolean>(true);
    const [recipient, setRecipient] = useState<ProspectRecipientData>();
    const [projectMember, setProjectMember] = useState<ProspectProjectMember>();
    const [sourcerMember, setSourcerMember] = useState<ProspectSourcerMember>();

    const profile = prospectInfo?.profile;
    const urls = prospectInfo?.urls!;
    const isProspectAiSourced = Number(sourcerMembers?.length) > 0;

    useEffect(() => {
        if (recipients) {
            if (context?.sequenceId && context.flow === 'recipient') {
                setRecipient(recipients!.filter((f) => f.sequence.id === context!.sequenceId)[0]);
            } else {
                setRecipient(recipients.slice().sort((a, b) => b.createdAt - a.createdAt)[0]);
            }
        }
    }, [recipients, context]);

    useEffect(() => {
        if (urls && urls[0]?.url && sourcerMembers) {
            setSourcerMember(
                sourcerMembers?.filter(
                    (f) =>
                        f.urls.some((u) => standardizeUrl(u.url) === standardizeUrl(urls[0]?.url)) &&
                        [SourcerMemberStatusEnum.Snoozed, SourcerMemberStatusEnum.ToReview].includes(f.status) &&
                        f.sourcer.status === SourcerStatusEnum.Active
                )[0]
            );
        }
    }, [sourcerMembers, urls]);

    useEffect(() => {
        if (members) {
            setProjectMember(members.slice().sort((a, b) => b.createdAt - a.createdAt)[0]);
        }
    }, [members]);

    const statusChip = recipient ? (
        <>
            <StatusChip
                status={recipient.status}
                lastStageSent={recipient.lastStageSent}
                // latest recipient response sentiment
                sentiment={recipient.recipientResponses[0]?.sentiment}
            />{' '}
            <Hyperlink href={appRoutes.sequences.index(recipient.sequence.id)} newTab>
                <Box
                    css={css`
                        margin: 10px;
                    `}
                >
                    {recipient.sequence.title}
                </Box>
            </Hyperlink>
        </>
    ) : projectMember ? (
        <>
            <StatusChip />
            <Hyperlink href={appRoutes.projects.index(projectMember.project.id)} newTab>
                <Box
                    css={css`
                        margin: 10px;
                    `}
                >
                    {projectMember.project.title}
                </Box>
            </Hyperlink>
        </>
    ) : sourcerMember ? (
        <>
            <StatusChip status={SourcerMemberStatusEnum.ToReview} />
            <Hyperlink href={appRoutes.sourcing.index(sourcerMember.sourcer.id)} newTab>
                <Box
                    css={css`
                        margin: 10px;
                    `}
                >
                    {sourcerMember.sourcer.title}
                </Box>
            </Hyperlink>
        </>
    ) : null;

    const handleLinkedInClick = () => {
        window.open(getPrimaryProfileUrl(urls), windowTarget, windowFeatures);
        trackEvent('click_linkedin_link', { prospect_id: prospectInfo?.id });
    };

    const handleEditClick = () => {
        setHideNameEdit(!hideNameEdit);
    };

    const handleMouseOver = () => {
        setHideEdit(false);
    };

    const handleMouseOut = () => {
        setHideEdit(true);
    };

    const handleCloseEditName = () => {
        setHideNameEdit(true);
    };

    return (
        <>
            <ProspectTopBar
                openAddTab={openAddTab}
                recipient={recipient}
                onClose={onClose}
                redirectToSection={redirectToSection}
            />
            <Box
                css={css`
                    width: 100%;
                    padding-top: ${spacing.space12px};
                `}
            >
                <Box
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    css={css`
                        display: flex;
                        place-content: end space-between;
                    `}
                >
                    <Box
                        css={css`
                            width: 80%;
                        `}
                    >
                        <Box
                            css={css`
                                display: flex;
                                align-items: center;
                                gap: 8px;
                            `}
                        >
                            <Title
                                type="h3"
                                css={css`
                                    font-size: ${fontSizes.f24};
                                `}
                            >
                                {translate('prospect-name', {
                                    first: profile?.firstNameCustomized ?? profile?.firstName,
                                    middleLast: profile?.lastNameCustomized ?? profile?.lastName,
                                })}
                            </Title>
                            {isProspectAiSourced && (
                                <AiSourcedTarget
                                    title={translate('ai-sourced-tooltip')}
                                    width={20}
                                    height={20}
                                    isRowItem={false}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        hidden={hideEdit && hideNameEdit}
                        css={css`
                        height: ${spacing.space24px}
                        font-size: ${fontSizes.f16};
                        color: ${theme.palette.primary.main};
                    `}
                    >
                        <Button
                            variant="text"
                            onClick={handleEditClick}
                            css={css`
                                height: ${spacing.space24px};
                            `}
                        >
                            {translate.rich('edit-label', {
                                // eslint-disable-next-line react/no-unstable-nested-components
                                bold: (children) => (
                                    <Box
                                        component="span"
                                        css={css`
                                            font-size: ${fontSizes.f16};
                                            font-weight: ${fontWeights.bold};
                                            color: ${theme.palette.primary.main};
                                            text-overflow: ellipsis;
                                        `}
                                    >
                                        {children}
                                    </Box>
                                ),
                            })}
                        </Button>
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-start;
                    `}
                >
                    <Box>{statusChip}</Box>
                    <Box>
                        {context?.flow !== 'extension' && (
                            <Button
                                css={css`
                                    height: ${spacing.space24px};
                                `}
                                variant="text"
                                onClick={handleLinkedInClick}
                                endIcon={
                                    <ArrowUpRight
                                        stroke={theme.palette.primary.main}
                                        onClick={handleLinkedInClick}
                                        height={8}
                                        width={8}
                                    />
                                }
                            >
                                {translate.rich('linked-in-label', {
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    bold: (children) => (
                                        <Box
                                            component="span"
                                            css={css`
                                                font-size: ${fontSizes.f16};
                                                font-weight: ${fontWeights.bold};
                                                color: ${theme.palette.primary.main};
                                                text-overflow: ellipsis;
                                            `}
                                        >
                                            {children}
                                        </Box>
                                    ),
                                })}
                            </Button>
                        )}
                    </Box>
                </Box>
                <EditName hidden={hideNameEdit} onClose={handleCloseEditName} />
            </Box>
        </>
    );
};

export { ProspectTitleBar };
