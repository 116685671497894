import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { CheckCircleBroken } from 'shared/components/svgs';
import { useProspectPanel } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddProjectSequenceConfirmProps {
    refresh: boolean;
    titles: string[];
}

const AddProjectSequenceConfirm: FC<AddProjectSequenceConfirmProps> = (props) => {
    const { titles, refresh } = props;
    const { prospectInfo } = useProspectPanel();
    const translate = useTranslations('prospects.tabs.add-tab');
    const [names, setNames] = useState<string[]>();
    const profile = prospectInfo?.profile;

    useEffect(() => {
        if (refresh && titles) {
            setNames(titles);
        }
    }, [titles, refresh]);

    const confirmMessage =
        names && names.length > 1
            ? names.map((value, index) => {
                  // eslint-disable-next-line no-magic-numbers
                  if (index === names.length - 2) {
                      return translate.rich('add-project-sequence-label-second-last', { value, bold: Bold });
                  }
                  if (index === names.length - 1) {
                      return translate.rich('add-project-sequence-label-last', { value, bold: Bold });
                  }
                  return translate.rich('add-project-sequence-label', { value, bold: Bold });
              })
            : names && names.length === 1
            ? [translate.rich('add-project-sequence-label-last', { value: names[0], bold: Bold })]
            : null;

    return (
        <Box
            css={css`
                flex: 1;
                vertical-align: middle;
                margin-top: 35%;
                height: 100%;
                padding: ${spacing.space24px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                `}
            >
                <CheckCircleBroken />
            </Box>
            <Box
                css={css`
                    margin-top: ${spacing.space24px};
                    text-align: center;
                `}
            >
                {translate.rich('add-name-label', {
                    name: profile?.firstNameCustomized ?? profile?.firstName,
                })}
                {confirmMessage}
            </Box>
        </Box>
    );
};

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);

export { AddProjectSequenceConfirm };
