import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { SourcerMembersQuery, SourcerMemberStatusEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC, SourcerData } from 'shared/types';
import { SourcerMemberCardModal, SourcerMemberCardReview } from 'sourcing/components/composite';

interface SourcerMemberCardEditBarProps {
    reviews: Map<string, SourcerMemberCardReview>;
    sourcerMembers: SourcerMembersQuery['sourcer_members'];
    sourcer: SourcerData;
}

const SourcerMemberCardEditBar: FC<SourcerMemberCardEditBarProps> = React.forwardRef(
    ({ reviews, sourcerMembers, sourcer }, ref) => {
        const translate = useTranslations('sourcing.sourcer-member-card.edit-bar');
        const theme = useTheme();

        const [open, setOpen] = useState(false);

        const handleModalOpen = () => {
            setOpen(true);
        };

        const handleModalClose = () => {
            setOpen(false);
        };

        const rejectedReviewsCount = [...reviews].filter(
            ([_id, review]) => review.status === SourcerMemberStatusEnum.Rejected
        ).length;

        const snoozedReviewsCount = [...reviews].filter(
            ([_id, review]) => review.status === SourcerMemberStatusEnum.Snoozed
        ).length;

        const approvedReviewsCount = [...reviews].filter(
            ([_id, review]) => review.status === SourcerMemberStatusEnum.Accepted
        ).length;

        const buttonText =
            approvedReviewsCount > 0 && rejectedReviewsCount === 0 && snoozedReviewsCount === 0
                ? translate('confirm-add-button', { count: approvedReviewsCount })
                : translate('confirm-button', { count: reviews.size });

        return (
            <>
                <Box
                    css={css`
                        display: flex;
                        align-items: center;

                        width: 960px;
                        background-color: ${theme.palette.primary.contrastText};
                        padding: 16px 16px 16px 24px;
                        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                    `}
                    ref={ref}
                >
                    <Box
                        css={css`
                            margin-right: auto;
                            color: ${theme.palette.grey[200]};
                        `}
                    >
                        {translate('approved-rejected-count', {
                            approvedCount: approvedReviewsCount,
                            rejectedCount: rejectedReviewsCount,
                        })}
                    </Box>
                    <Box
                        css={css`
                            display: flex;
                            font-size: ${fontSizes.f16};
                            gap: ${spacing.space16px};
                        `}
                    >
                        <Button
                            variant="contained"
                            css={css`
                                height: 44px;
                            `}
                            onClick={handleModalOpen}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </Box>
                <SourcerMemberCardModal
                    open={open}
                    onClose={handleModalClose}
                    sourcer={sourcer}
                    reviews={reviews}
                    sourcerMembers={sourcerMembers}
                />
            </>
        );
    }
);

export { SourcerMemberCardEditBar };
