import { SettingsPopover, SettingsPopoverProps } from 'settings/components/presentational';
import { ApisAndIntegrations } from 'settings/components/presentational/ats-integrations';
import { FC } from 'shared/types';

interface AtsIntegrationsSettingPopoverProps extends SettingsPopoverProps {}

const AtsIntegrationsSettingPopover: FC<AtsIntegrationsSettingPopoverProps> = ({ ...rest }) => (
    <SettingsPopover {...rest}>
        <ApisAndIntegrations />
    </SettingsPopover>
);

export { AtsIntegrationsSettingPopover };
