import { css } from '@emotion/react';
import Image from 'next/image';

import { ErrorTemplate } from 'errors/components/templates/error-template';
import { useProfile } from 'shared/hooks/use-profile';
import { FC } from 'shared/types';

interface TemplateExtensionProps {}

const TemplateExtension: FC<TemplateExtensionProps> = () => {
    const { error } = useProfile();

    if (!error) return null;

    return (
        <ErrorTemplate errorCode={error}>
            <Image
                css={css`
                    border-radius: 50% 0% 50% 50%;

                    object-fit: cover;
                    object-position: left bottom;
                `}
                src="/extension-errors.gif"
                alt="Extension error"
                width={296.5}
                height={298}
            />
        </ErrorTemplate>
    );
};

export { TemplateExtension };
