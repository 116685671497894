/* eslint-disable max-len */
import { gql } from '@apollo/client';

import {
    AshbyAccountByIdQuery,
    AshbyInterviewPlanStagesQuery,
    AshbyJobsQuery,
    AshbyProjectMappingByProjectIdQuery,
    AshbyProspectApplicationsQuery,
    AshbySequenceMappingBySequenceIdQuery,
    AshbySourcesQuery,
    AshbyUrlApplicationsQuery,
    AshbyUsersQuery,
} from 'codegen/graphql';

export type AshbySourcesData = AshbySourcesQuery['data'];
export type AshbyAccountByIdData = AshbyAccountByIdQuery['data'];
export type AshbyJobsData = AshbyJobsQuery['data'];
export type AshbyInterviewPlanStagesData = AshbyInterviewPlanStagesQuery['data'];
export type AshbyProjectMappingByProjectIdData = AshbyProjectMappingByProjectIdQuery['data'];
export type AshbySequenceMappingBySequenceIdData = AshbySequenceMappingBySequenceIdQuery['data'];
export type AshbyUsersData = AshbyUsersQuery['data'];
export type AshbyProspectApplicationsData = AshbyProspectApplicationsQuery['data'];
export type AshbyUrlApplicationsData = AshbyUrlApplicationsQuery['data'];

export const ASHBY_ACCOUNT_DETAILS = gql`
    fragment AshbyAccountDetails on ashby_accounts {
        id
        teamId
        ownerId
        apiToken
        defaultSourceId
    }
`;

export const GET_ASHBY_ACCOUNT_BY_TEAM_ID = gql`
    query AshbyAccountByTeamId($teamId: String!) {
        data: ashby_accounts(where: { teamId: { _eq: $teamId } }) {
            ...AshbyAccountDetails
        }
    }
    ${ASHBY_ACCOUNT_DETAILS}
`;

export const GET_ASHBY_ACCOUNT_BY_ID = gql`
    query AshbyAccountById($id: String!) {
        data: ashby_accounts_by_pk(id: $id) {
            ...AshbyAccountDetails
        }
    }
    ${ASHBY_ACCOUNT_DETAILS}
`;

export const CREATE_ASHBY_PROJECT_MAPPING = gql`
    mutation CreateAshbyProjectMapping($ashby_project_mappings: ashby_project_mappings_insert_input!) {
        inserted: insert_ashby_project_mappings_one(object: $ashby_project_mappings) {
            id
        }
    }
`;

export const GET_ASHBY_PROJECT_MAPPING_BY_PROJECT_ID = gql`
    query AshbyProjectMappingByProjectId($projectId: String!) {
        data: ashby_project_mappings(where: { projectId: { _eq: $projectId } }) {
            id
            projectId
            jobId
            projectAddedStageId
            assigneeId
        }
    }
`;

export const UPDATE_ASHBY_PROJECT_MAPPING_BY_ID = gql`
    mutation UpdateAshbyProjectMappingById($id: String!, $set: ashby_project_mappings_set_input!) {
        updated: update_ashby_project_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_ASHBY_PROJECT_MAPPING_BY_ID = gql`
    mutation DeleteAshbyProjectMappingById($id: String!) {
        deleted: delete_ashby_project_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID = gql`
    query AshbySequenceMappingBySequenceId($sequenceId: String!) {
        data: ashby_sequence_mappings(where: { sequenceId: { _eq: $sequenceId } }) {
            id
            sequenceId
            jobId
            assigneeId
            syncProspectToggleActive
            createEventType
            repliedMoveStageActive
            repliedStageId
            interestedReplyMoveStageActive
            interestedReplyStageId
            meetingBookedMoveStageActive
            meetingBookedStageId
            defaultAddStageId
        }
    }
`;

export const CREATE_ASHBY_SEQUENCE_MAPPING = gql`
    mutation CreateAshbySequenceMapping($ashby_sequence_mappings: ashby_sequence_mappings_insert_input!) {
        inserted: insert_ashby_sequence_mappings_one(object: $ashby_sequence_mappings) {
            id
        }
    }
`;

export const UPDATE_ASHBY_SEQUENCE_MAPPING_BY_ID = gql`
    mutation UpdateAshbySequenceMappingById($id: String!, $set: ashby_sequence_mappings_set_input!) {
        updated: update_ashby_sequence_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_ASHBY_SEQUENCE_MAPPING_BY_ID = gql`
    mutation DeleteAshbySequenceMappingById($id: String!) {
        deleted: delete_ashby_sequence_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const ASHBY_CANDIDATE_DETAILS = gql`
    fragment AshbyCandidateDetails on ashby_candidates {
        id
        remoteId
        accountId
        name
        primaryEmailAddress {
            value
            type
        }
    }
`;

export const GET_ASHBY_CANDIDATES = gql`
    query AshbyCandidates {
        data: ashby_candidates {
            ...AshbyCandidateDetails
        }
    }
    ${ASHBY_CANDIDATE_DETAILS}
`;

export const ASHBY_SOURCE_DETAILS = gql`
    fragment AshbySourceDetails on ashby_sources {
        id
        remoteId
        accountId
        name
    }
`;

export const GET_ASHBY_SOURCES = gql`
    query AshbySources($order_by: [ashby_sources_order_by!] = { name: asc }) {
        data: ashby_sources(order_by: $order_by) {
            ...AshbySourceDetails
        }
    }
    ${ASHBY_SOURCE_DETAILS}
`;

export const ASHBY_JOB_DETAILS = gql`
    fragment AshbyJobDetails on ashby_jobs {
        id
        remoteId
        accountId
        name
        status
    }
`;

export const GET_ASHBY_JOBS = gql`
    query AshbyJobs {
        data: ashby_jobs {
            ...AshbyJobDetails
        }
    }
    ${ASHBY_JOB_DETAILS}
`;

export const ASHBY_INTERVIEW_PLAN_STAGE_DETAILS = gql`
    fragment AshbyInterviewPlanStageDetails on ashby_interview_plan_stages {
        id
        remoteId
        accountId
        name
        interviewPlanId
        orderInInterviewPlan
    }
`;

export const ASHBY_INTERVIEW_PLAN_DETAILS = gql`
    fragment AshbyInterviewPlanDetails on ashby_interview_plans {
        id
        remoteId
        accountId
        name
        stages {
            ...AshbyInterviewPlanStageDetails
        }
    }
    ${ASHBY_INTERVIEW_PLAN_STAGE_DETAILS}
`;

export const GET_ASHBY_INTERVIEW_PLAN_STAGES = gql`
    query AshbyInterviewPlanStages {
        data: ashby_interview_plan_stages {
            ...AshbyInterviewPlanStageDetails
        }
    }
    ${ASHBY_INTERVIEW_PLAN_STAGE_DETAILS}
`;

export const ASHBY_JOB_INTERVIEW_PLAN_DETAILS = gql`
    fragment AshbyJobInterviewPlanDetails on ashby_job_interview_plans {
        id
        job {
            ...AshbyJobDetails
        }
        interviewPlan {
            ...AshbyInterviewPlanDetails
        }
    }
    ${ASHBY_JOB_DETAILS}
    ${ASHBY_INTERVIEW_PLAN_DETAILS}
`;

export const GET_ASHBY_JOB_INTERVIEW_PLANS_BY_JOB_ID = gql`
    query AshbyJobInterviewPlansByJobId($jobId: String!) {
        data: ashby_job_interview_plans(where: { jobId: { _eq: $jobId } }) {
            ...AshbyJobInterviewPlanDetails
        }
    }
    ${ASHBY_JOB_INTERVIEW_PLAN_DETAILS}
`;

export const ASHBY_USER_DETAILS = gql`
    fragment AshbyUserDetails on ashby_users {
        id
        firstName
        lastName
        email
    }
`;

export const GET_ASHBY_USERS = gql`
    query AshbyUsers {
        data: ashby_users {
            ...AshbyUserDetails
        }
    }
    ${ASHBY_USER_DETAILS}
`;

export const GET_ASHBY_PROSPECT_APPLICATIONS = gql`
    query AshbyProspectApplications($prospectId: String!) {
        data: ashby_find_prospect_applications(args: { prospectId: $prospectId }) {
            id
            status
            lastActivityAt: remoteUpdatedAt
            addedAt: remoteCreatedAt
            job {
                ...AshbyJobDetails
            }
            addedByUser: user {
                ...AshbyUserDetails
            }
            candidate {
                profileUrl
            }
        }
    }
    ${ASHBY_JOB_DETAILS}
    ${ASHBY_USER_DETAILS}
`;

export const GET_ASHBY_URL_APPLICATIONS = gql`
    query AshbyUrlApplications($url: String!) {
        data: ashby_find_applications_by_url(args: { url: $url }) {
            id
            status
            lastActivityAt: remoteUpdatedAt
            addedAt: remoteCreatedAt
            job {
                ...AshbyJobDetails
            }
            addedByUser: user {
                ...AshbyUserDetails
            }
            candidate {
                profileUrl
            }
        }
    }
    ${ASHBY_JOB_DETAILS}
    ${ASHBY_USER_DETAILS}
`;
