import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { formatEmail } from 'hireflow-shared/lib/emails/email-utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    ContactChannelsEnum,
    ContactKindsEnum,
    DeleteAndUpdateProspectPrimaryEmailMutation,
    DeleteRecipientEmailMutation,
    RecipientStatusEnum,
    UpdateRecipientsMutation,
} from 'codegen/graphql';
import {
    ChooseSequenceModal,
    ReportWrongEmailMultipleContacts,
    ReportWrongEmailOneContact,
} from 'sequences/components/modals';
import { DELETE_RECIPIENT_EMAIL, UPDATE_RECIPIENT_EMAIL } from 'shared/graphql/contacts';
import { GET_PROJECT_MEMBERS_BY_PROJECT } from 'shared/graphql/project-members';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { GET_RECIPIENTS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { useSnackbarAlert } from 'shared/hooks';
import { isEmailValid } from 'shared/services';
import { FC } from 'shared/types';

interface RecipientDetailsForWrongEmailModal {
    id: string;
    status: RecipientStatusEnum;
    sequence: { id: string; title: string };
    contacts: Array<{
        contactId: string;
    }>;
    prospect: {
        id: string;
        profile?: {
            fullName: string;
        } | null;
        contacts?: Array<{
            id: string;
            kind: string;
            channel: string;
            value: string;
            primary?: boolean | null;
            invalid?: boolean | null;
        }> | null;
    };
}
interface ReportWrongEmailModalProps {
    recipients: RecipientDetailsForWrongEmailModal[];
    open: boolean;
    onClose: () => void;
}

const ReportWrongEmailModal: FC<ReportWrongEmailModalProps> = (props) => {
    const { recipients, onClose, open } = props;
    const translate = useTranslations('sequence.recipient-list-table.report-wrong-email-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [selectedRecipient, setSelectedRecipient] = useState<RecipientDetailsForWrongEmailModal | undefined>(
        recipients.length === 1 ? recipients[0] : undefined
    );

    const [selectedSequence, setSelectedSequence] = useState<string | undefined>();

    const [updateRecipients] = useMutation<UpdateRecipientsMutation>(UPDATE_RECIPIENTS, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
        ],
    });

    const [updateRecipientEmail] = useMutation<DeleteAndUpdateProspectPrimaryEmailMutation>(UPDATE_RECIPIENT_EMAIL, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
        ],
    });

    const [deleteRecipientEmail] = useMutation<DeleteRecipientEmailMutation>(DELETE_RECIPIENT_EMAIL, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
        ],
    });

    const handleConfirmSequence = () => {
        setSelectedRecipient(recipients.find((recipient) => recipient.sequence.id === selectedSequence));
    };

    const handleSequenceChange = (value: string) => {
        setSelectedSequence(value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleReportWrongEmail = async (email: string) => {
        if (!selectedRecipient) {
            return;
        }

        await updateRecipients({
            variables: {
                where: {
                    prospectId: { _eq: selectedRecipient.prospect.id },
                    sequenceId: { _eq: selectedRecipient.sequence.id },
                },
                set: {
                    status: RecipientStatusEnum.NotStarted,
                    lastStageSent: 0,
                    lastStageSentAt: null,
                    nextMessageScheduledAt: null,
                },
            },
        });
        if (email?.length > 0 && isEmailValid(email)) {
            // removing the old email and setting a new one
            await updateRecipientEmail({
                variables: {
                    recipientId: selectedRecipient.id,
                    prospectId: selectedRecipient.prospect.id,
                    contactId: selectedRecipient.contacts[0].contactId, // contact id to remove
                    value: formatEmail(email),
                    kind:
                        selectedRecipient.prospect.contacts!.find((f) => f.value === email)?.kind ??
                        ContactKindsEnum.Unknown,
                },
            });
        } else {
            // removing the email and not providing a new one
            await deleteRecipientEmail({
                variables: {
                    recipientId: selectedRecipient.id,
                    prospectId: selectedRecipient.prospect.id,
                    contactId: selectedRecipient.contacts[0].contactId,
                },
            });
        }

        handleClose();
        showSnackbarAlert({
            message: translate('success-alert', {
                name: selectedRecipient.prospect.profile?.fullName,
            }),
        });
        trackEvent('click_report_wrong_email', { prospect_id: selectedRecipient.prospect.id });
    };

    return selectedRecipient ? (
        selectedRecipient.prospect.contacts!.length === 1 ? (
            <ReportWrongEmailOneContact
                open={open}
                onClose={handleClose}
                reportedEmail={
                    selectedRecipient.prospect.contacts!.filter(
                        (f) => f.id === selectedRecipient.contacts[0]?.contactId
                    )[0]?.value
                }
                onReport={handleReportWrongEmail}
            />
        ) : (
            <ReportWrongEmailMultipleContacts
                open={open}
                onClose={handleClose}
                reportedEmail={
                    selectedRecipient.prospect.contacts!.filter(
                        (f) => f.id === selectedRecipient.contacts[0]?.contactId
                    )[0]?.value
                }
                options={selectedRecipient.prospect
                    .contacts!.filter(
                        (f) =>
                            f.id !== selectedRecipient.contacts[0]?.contactId && f.channel !== ContactChannelsEnum.Phone
                    )
                    .map((c) => ({ label: c.value, value: c.value, disabled: c.invalid ?? false }))}
                onReport={handleReportWrongEmail}
            />
        )
    ) : (
        <ChooseSequenceModal
            open={open}
            handleClose={handleClose}
            recipients={recipients}
            selectedSequence={selectedSequence}
            handleSequenceChange={handleSequenceChange}
            handleConfirmSequence={handleConfirmSequence}
        />
    );
};

export { ReportWrongEmailModal };
