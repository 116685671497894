import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateSettingsFormButtonsProps {
    onSaveAsDraft: () => void;
    onGoToPreviousStep: () => void;
}

const CreateSettingsFormButtons: FC<CreateSettingsFormButtonsProps> = (props) => {
    const { onSaveAsDraft, onGoToPreviousStep } = props;
    const translate = useTranslations('sequence');

    return (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;

                padding-bottom: ${spacing.space48px};

                @media (max-width: 1200px) {
                    padding-bottom: ${spacing.space16px};
                }
            `}
        >
            <Button onClick={onGoToPreviousStep} variant="outlined">
                {translate('back')}
            </Button>
            <Box>
                <Button onClick={onSaveAsDraft} variant="outlined">
                    {translate('save-as-draft')}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    css={css`
                        margin-left: ${spacing.space16px};
                    `}
                >
                    {translate('save-and-finish')}
                </Button>
            </Box>
        </Box>
    );
};

export { CreateSettingsFormButtons };
