import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import {
    LicenseTypesEnum,
    PlanTypesEnum,
    RemoveTeamInviteMutation,
    TeamRolesEnum,
    UpdateUserMutation,
    UpdateUserRoleMutation,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserStatusEnum,
} from 'codegen/graphql';
import { ArchiveUserModal, NoSeatsModal } from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import { GET_TEAM_INVITES, GET_TEAM_MEMBERS, REMOVE_TEAM_INVITE } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { GET_USER_BY_ID, UPDATE_USER, UPDATE_USER_ROLE } from 'shared/graphql/users';
import { useConfirmationModal, useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface MemberListSubmenuProps {
    id: string;
    member: boolean;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
    isArchived: boolean;
    totalSeats: number;
    seatsAvailable: number;
    planType: PlanTypesEnum;
}
const MemberListSubmenu: FC<MemberListSubmenuProps> = (props) => {
    const { session } = useSession();

    const { anchor, setAnchor, id, member, isArchived, seatsAvailable, planType, totalSeats } = props;

    const { showSnackbarAlert } = useSnackbarAlert();
    const { showModal, hideModal } = useConfirmationModal();

    const [showNoSeatsModal, setShowNoSeatsModal] = useState(false);
    const [showArchiveUserModal, setShowArchiveUserModal] = useState(false);

    const translate = useTranslations('settings.team-settings.member-list-submenu');

    const { data } = useQuery<UserByIdQuery, UserByIdQueryVariables>(GET_USER_BY_ID, {
        skip: !member,
        variables: { id },
    });

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER, {
        refetchQueries: [getOperationName(GET_TEAM_MEMBERS) as string],
    });

    const [removeTeamInvite] = useMutation<RemoveTeamInviteMutation>(REMOVE_TEAM_INVITE, {
        refetchQueries: [getOperationName(GET_TEAM_INVITES) as string],
    });

    const [updateMemberRole] = useMutation<UpdateUserRoleMutation>(UPDATE_USER_ROLE, {
        refetchQueries: [
            getOperationName(GET_TEAM_MEMBERS) as string,
            getOperationName(GET_USER_BY_ID) as string,
            getOperationName(GET_USER_ROLE) as string,
        ],
    });

    const handleCloseSubmenuPopover = () => setAnchor(undefined);

    const handleUnarchiveUserClick = async () => {
        /**
         * if planType is solo / free trial
         * check for the available seat -> unarchived user will be given a full license and will be counted as a seat
         *
         * if the planType is enterprise / enterprise trial
         * make the user as a full reviewer if there is a seat available otherwise a reviewer
         * unarchived user in this case will not be counted as a seat
         */

        if (seatsAvailable <= 0 && [PlanTypesEnum.Solo, PlanTypesEnum.FreeTrial].includes(planType)) {
            setShowNoSeatsModal(true);
            return;
        }

        let role: TeamRolesEnum | undefined;
        let licenseType: LicenseTypesEnum | undefined;

        if ([PlanTypesEnum.Solo, PlanTypesEnum.FreeTrial].includes(planType)) {
            role = TeamRolesEnum.User;
            licenseType = LicenseTypesEnum.Full;
        } else if ([PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType)) {
            role = TeamRolesEnum.User;
            licenseType = seatsAvailable > 0 ? LicenseTypesEnum.Full : LicenseTypesEnum.Reviewer;
        }

        if (role && licenseType) {
            await updateMemberRole({ variables: { userId: id, set: { role, licenseType } } });
            showSnackbarAlert({
                severity: 'success',
                message: translate('un-archive-user-success-alert'),
            });
        }

        handleCloseSubmenuPopover();
    };

    const handleArchiveUserClick = () => {
        setShowArchiveUserModal(true);
    };

    const handleArchiveModalClose = () => {
        setShowArchiveUserModal(false);
        handleCloseSubmenuPopover();
    };

    const handleArchiveUser = async () => {
        setShowArchiveUserModal(false);
        await updateUser({
            variables: {
                id,
                set: { status: UserStatusEnum.Archived },
            },
        });
        hideModal();
        handleCloseSubmenuPopover();
    };

    const handleCancelInviteClick = () => {
        showModal({
            title: translate('cancel-invite-modal.title'),
            confirmButton: {
                color: 'error',
                text: translate('cancel-invite-modal.confirm-button-label'),
                onClick: async () => {
                    await removeTeamInvite({ variables: { id } });
                    showSnackbarAlert({
                        severity: 'success',
                        message: translate('cancel-invite-modal.team-invite-removed'),
                    });
                    hideModal();
                    handleCloseSubmenuPopover();
                },
            },
            cancelButton: {
                text: translate('cancel-invite-modal.cancel-button-label'),
                onClick: () => {
                    hideModal();
                    handleCloseSubmenuPopover();
                },
            },
        });
    };

    const submenuOptionText = member
        ? isArchived
            ? translate('un-archive-user-label')
            : translate('archive-user-label')
        : translate('cancel-invite-label');

    const handleSubmenuOptionClick = member
        ? isArchived
            ? handleUnarchiveUserClick
            : handleArchiveUserClick
        : handleCancelInviteClick;

    if (session?.user.id === id && member) return null;

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleCloseSubmenuPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    <SubmenuButton onClick={handleSubmenuOptionClick}>{submenuOptionText}</SubmenuButton>
                </Box>
            </Popover>
            <NoSeatsModal open={showNoSeatsModal} onClose={() => setShowNoSeatsModal(false)} totalSeats={totalSeats} />
            <ArchiveUserModal
                userName={data?.users_by_pk?.fullName}
                open={showArchiveUserModal}
                onClose={handleArchiveModalClose}
                onArchiveClick={handleArchiveUser}
            />
        </>
    );
};

export { MemberListSubmenu };
