import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { FC } from 'shared/types';

interface SnackbarAlertContent {
    severity?: 'success' | 'info' | 'warning' | 'error';
    message: string | ReactNode;
    startIcon?: JSX.Element;
    backgroundColor?: string;
}

interface SnackbarAlertContextProps {
    snackbarAlertContent: SnackbarAlertContent;
    isSnackbarAlertOpen: boolean;
    hideSnackbarAlert: () => void;
    showSnackbarAlert: (content: SnackbarAlertContent) => void;
}

const SnackbarAlertContext = createContext<SnackbarAlertContextProps>({} as SnackbarAlertContextProps);

const SnackbarAlertProvider: FC<unknown> = (props) => {
    const { children } = props;
    const [snackbarAlertContent, setSnackbarAlertContent] = useState<SnackbarAlertContent>({
        message: '',
        severity: 'success',
    });
    const [isSnackbarAlertOpen, setIsSnackbarAlertOpen] = useState<boolean>(false);

    const hideSnackbarAlert = useCallback(() => {
        setIsSnackbarAlertOpen(false);
    }, [setIsSnackbarAlertOpen]);

    const showSnackbarAlert = useCallback(
        (content: SnackbarAlertContent) => {
            setIsSnackbarAlertOpen(true);
            setSnackbarAlertContent(content);
        },
        [setIsSnackbarAlertOpen, setSnackbarAlertContent]
    );

    const value = useMemo(
        () => ({
            snackbarAlertContent,
            isSnackbarAlertOpen,
            hideSnackbarAlert,
            showSnackbarAlert,
        }),
        [hideSnackbarAlert, showSnackbarAlert, snackbarAlertContent, isSnackbarAlertOpen]
    );

    return <SnackbarAlertContext.Provider value={value}>{children}</SnackbarAlertContext.Provider>;
};

const useSnackbarAlert = (): SnackbarAlertContextProps => {
    const context = useContext(SnackbarAlertContext);
    return { ...context };
};

export { useSnackbarAlert, SnackbarAlertProvider };
