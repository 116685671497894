import { gql } from '@apollo/client';

export const CREATE_UNSUBSCRIBE_REQUEST = gql`
    mutation CreateUnsubscribeRequest($recipientId: String!, $email: String, $stageId: String) {
        insert_unsubscribe_requests_one(
            object: { recipientId: $recipientId, email: $email, stageId: $stageId }
            on_conflict: { constraint: unsubscribe_requests_recipientId_key, update_columns: [email, stageId] }
        ) {
            id
        }
    }
`;
