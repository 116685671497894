import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLineChart: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '18'}
        height={height ?? '18'}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M16.5 16.5H2.83333C2.36662 16.5 2.13327 16.5 1.95501 16.4092C1.79821 16.3293 1.67072 16.2018 1.59083 16.045C1.5 15.8667 1.5 15.6334 1.5 15.1667V1.5M16.5 4.83333L11.9714 9.36193C11.8064 9.52694 11.7239 9.60944 11.6288 9.64035C11.5451 9.66754 11.4549 9.66754 11.3712 9.64035C11.2761 9.60944 11.1936 9.52694 11.0286 9.36193L9.4714 7.80474C9.3064 7.63973 9.22389 7.55723 9.12876 7.52632C9.04507 7.49912 8.95493 7.49912 8.87124 7.52632C8.77611 7.55723 8.6936 7.63973 8.5286 7.80474L4.83333 11.5M16.5 4.83333H13.1667M16.5 4.83333V8.16667"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const LineChart = withTheme(ThemedLineChart);

export { LineChart };
