import styled from '@emotion/styled';
import { Table as MuiTable, TableProps as MuiTableProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableProps extends MuiTableProps {}

const Table: FC<TableProps> = (props) => <MuiTable {...props} />;

const StyledTable = styled(Table)`
    border-collapse: separate;
`;

export type { TableProps };
export { StyledTable as Table };
