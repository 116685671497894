/**
 * =====================================================================================================================
 * dynamically set the schema for react-hook-form
 * adds a custom useForm hook
 * very useful when there are hidden fields on the page that may or may not require validation
 * depending on other selections
 * =====================================================================================================================
 * inspired by: https://github.com/react-hook-form/react-hook-form/discussions/3972#discussioncomment-3631609
 * =====================================================================================================================
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

type WidenSchema<T> = T extends (...args: any[]) => any
    ? T
    : { [K in keyof T]: T[K] extends Record<string, unknown> ? WidenSchema<T[K]> : any };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CustomObjectShape<T, U> = ObjectShape;

type useDynamicSchemaFormSchema<TFieldValues> = Yup.ObjectSchema<
    CustomObjectShape<object | undefined, Partial<WidenSchema<TFieldValues>>>,
    object
>;

type useDynamicSchemaFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = any> = UseFormReturn<
    TFieldValues,
    TContext
> & {
    setSchema: (newSchema: useDynamicSchemaFormSchema<TFieldValues>) => void;
    updateSchema: (newSchema: useDynamicSchemaFormSchema<TFieldValues>) => void;
};

const useDynamicSchemaForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
    props?: Omit<UseFormProps<TFieldValues, TContext>, 'resolver'> & {
        schema?: useDynamicSchemaFormSchema<TFieldValues>;
    }
): useDynamicSchemaFormReturn<TFieldValues> => {
    const [customFormSchema, setCustomFormSchema] = useState<useDynamicSchemaFormSchema<TFieldValues> | null>(
        props?.schema || null
    );

    /**
     * wipes the old schema and replaces it with the new one
     */
    const setSchema = useCallback((newSchema: useDynamicSchemaFormSchema<TFieldValues>) => {
        setCustomFormSchema(() => newSchema);
    }, []);

    /**
     * adds the new fields to the schema already in place
     */
    const updateSchema = useCallback((newSchema: useDynamicSchemaFormSchema<TFieldValues>) => {
        setCustomFormSchema((oldSchema) => {
            if (oldSchema) {
                return Yup.object().shape({
                    ...oldSchema.fields,
                    ...newSchema.fields,
                }) as useDynamicSchemaFormSchema<TFieldValues>;
            }
            return newSchema;
        });
    }, []);

    const form = useForm({
        ...props,
        resolver: customFormSchema
            ? yupResolver<useDynamicSchemaFormSchema<TFieldValues>>(customFormSchema)
            : undefined,
    });

    return {
        ...form,
        setSchema,
        updateSchema,
    };
};

export type { useDynamicSchemaFormSchema as useCustomFormSchema, useDynamicSchemaFormReturn as UseCustomFormReturn };
export { useDynamicSchemaForm };
