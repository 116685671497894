import { createContext, useContext, useMemo } from 'react';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { UserProjectsForProspectPanelQuery, UserSequencesQuery } from 'codegen/graphql';
import { ProjectMemberActivity } from 'projects/types';
import { PanelContext } from 'prospects/prospect-panel-provider';
import { ContactUpdate } from 'shared/graphql/contacts';
import {
    AshbyProspectApplicationsData,
    GreenhouseProspectApplicationsData,
    LeverProspectApplicationsData,
    AshbyUrlApplicationsData,
    GreenhouseUrlApplicationsData,
    LeverUrlApplicationsData,
} from 'shared/graphql/integrations';
import { ProjectsData } from 'shared/graphql/projects';
import { RecipientActivity } from 'shared/graphql/recipient-activities';
import { ProspectRecipientData } from 'shared/graphql/recipients';
import { SendFromAccountData } from 'shared/graphql/send-from-accounts';
import { FC, UserTeam } from 'shared/types';
import {
    Prospect,
    ProspectContact,
    ProspectProfile,
    ProspectProjectMember,
    ProspectSourcerMember,
    RecipientConversationsData,
} from 'shared/types/prospect';

type UserProspectForProspectPanel = DeepExtractTypeSkipArrays<
    UserProjectsForProspectPanelQuery,
    ['user_projects', 'project']
>;

interface ProspectPanelContextData {
    contacts: ProspectContact[] | undefined;
    projectsData: UserProspectForProspectPanel[] | undefined;
    defaultUserProjectsData: ProjectsData[] | undefined;
    sequencesData: UserSequencesQuery['sequences'] | undefined;
    sendFromAccountsData: SendFromAccountData[] | undefined;
    recipients: ProspectRecipientData[] | undefined;
    recipientsConversations: RecipientConversationsData[] | undefined;
    members: ProspectProjectMember[] | undefined;
    sourcerMembers: ProspectSourcerMember[] | undefined;
    prospectInfo: Prospect | undefined;
    context: PanelContext | undefined;
    recipientActivities: RecipientActivity[] | undefined;
    projectMemberActivities: ProjectMemberActivity[] | undefined;
    ashbyData?: AshbyProspectApplicationsData | AshbyUrlApplicationsData;
    greenhouseData?: GreenhouseProspectApplicationsData | GreenhouseUrlApplicationsData;
    leverData?: LeverProspectApplicationsData | LeverUrlApplicationsData;
    updateContext: (updates: PanelContext) => void;
    addProspectContact: (contacts: ContactUpdate[], recipientIds: string[]) => void;
    createCustomMessage: (prospectCustomMessage: {
        prospectId: string;
        sequenceId: string;
        customMessage: string;
    }) => void;
    addOrUpdateProspect: (profile: ProspectProfile, url: string) => Promise<string | undefined>;
    teamData: UserTeam | undefined;
}

const ProspectPanelContext = createContext<ProspectPanelContextData>({
    contacts: undefined,
    sequencesData: undefined,
    projectsData: undefined,
    defaultUserProjectsData: undefined,
    sendFromAccountsData: undefined,
    recipients: undefined,
    recipientsConversations: undefined,
    members: undefined,
    sourcerMembers: undefined,
    prospectInfo: undefined,
    context: undefined,
    recipientActivities: undefined,
    projectMemberActivities: undefined,
    ashbyData: undefined,
    greenhouseData: undefined,
    leverData: undefined,
    updateContext: () => {},
    addProspectContact: () => {},
    createCustomMessage: () => {},
    addOrUpdateProspect: (_profile: ProspectProfile, _url: string) => new Promise<string | undefined>(() => {}),
    teamData: undefined,
});

const ProspectPanelContextProvider: FC<ProspectPanelContextData> = ({
    children,
    contacts,
    sequencesData,
    recipients,
    recipientsConversations,
    members,
    sourcerMembers,
    projectsData,
    defaultUserProjectsData,
    sendFromAccountsData,
    prospectInfo,
    context,
    recipientActivities,
    projectMemberActivities,
    ashbyData,
    greenhouseData,
    leverData,
    updateContext,
    addProspectContact,
    createCustomMessage,
    addOrUpdateProspect,
    teamData,
}) => {
    const value = useMemo(
        () => ({
            contacts,
            sequencesData,
            projectsData,
            defaultUserProjectsData,
            sendFromAccountsData,
            recipients,
            recipientsConversations,
            members,
            sourcerMembers,
            prospectInfo,
            context,
            recipientActivities,
            projectMemberActivities,
            ashbyData,
            greenhouseData,
            leverData,
            updateContext,
            addProspectContact,
            createCustomMessage,
            addOrUpdateProspect,
            teamData,
        }),
        [
            sequencesData,
            projectsData,
            defaultUserProjectsData,
            sendFromAccountsData,
            contacts,
            recipients,
            recipientsConversations,
            members,
            sourcerMembers,
            prospectInfo,
            context,
            recipientActivities,
            projectMemberActivities,
            ashbyData,
            greenhouseData,
            leverData,
            updateContext,
            addProspectContact,
            createCustomMessage,
            addOrUpdateProspect,
            teamData,
        ]
    );
    return <ProspectPanelContext.Provider value={value}>{children}</ProspectPanelContext.Provider>;
};

const useProspectPanel = () => useContext(ProspectPanelContext);

export { ProspectPanelContext, ProspectPanelContextProvider, useProspectPanel };
export type { ProspectPanelContextData };
