import { LicenseTypesEnum, PlanTypesEnum } from 'codegen/graphql';
import { SettingsPopover, SettingsPopoverProps } from 'settings/components/presentational/';
import {
    AccountInformation,
    AdvancedOptions,
    CommunicationPreferences,
    EmailAccounts,
    Personalization,
} from 'settings/components/presentational/account-and-emails';
import { FC } from 'shared/types';

interface AccountAndEmailsSettingsPopoverProps extends SettingsPopoverProps {
    planType: PlanTypesEnum;
    licenseType: LicenseTypesEnum;
}

const AccountAndEmailsSettingsPopover: FC<AccountAndEmailsSettingsPopoverProps> = ({
    planType,
    licenseType,
    ...rest
}) => (
    <SettingsPopover {...rest}>
        <AccountInformation />
        <EmailAccounts planType={planType} licenseType={licenseType} />
        <CommunicationPreferences />
        <AdvancedOptions />
        {planType && planType !== PlanTypesEnum.Enterprise ? <Personalization /> : null}
    </SettingsPopover>
);

export { AccountAndEmailsSettingsPopover };
