import { isEmpty } from 'lodash';

import { AshbyJobStatusEnum, GreenhouseJobStatusEnum, LeverPostingStateEnum } from 'codegen/graphql';
import { GroupedSelectOption, SelectOption } from 'shared/components/form';
import {
    AshbyJobsData,
    AshbyUsersData,
    GreenhouseJobsData,
    GreenhouseUsersData,
    LeverPostingsData,
    LeverUsersData,
} from 'shared/graphql/integrations';

type AtsJobSelectGroupOption = { id: string; name: string };
type AtsJobSelectGroup = {
    [key in AshbyJobStatusEnum | GreenhouseJobStatusEnum | LeverPostingStateEnum]: AtsJobSelectGroupOption[];
};

export const filterUndesiredJobs = ({
    jobsData,
    selectedOption,
}: {
    // have to reference the types as follow to suppress this compilation error:
    // https://github.com/microsoft/TypeScript/issues/33591#issuecomment-841832390
    jobsData: Array<GreenhouseJobsData[number] | LeverPostingsData[number] | AshbyJobsData[number]>;
    selectedOption: string;
}): GreenhouseJobsData | LeverPostingsData | AshbyJobsData => {
    const filteredJobsData = jobsData.filter((option) => {
        let res = false;

        // also show a selected option irrespective of state/status
        if (option.id === selectedOption) {
            return true;
        }

        // eslint-disable-next-line no-underscore-dangle
        switch (option.__typename) {
            case 'ashby_jobs':
                res = option.status !== AshbyJobStatusEnum.Archived && option.status !== AshbyJobStatusEnum.Closed;
                break;
            case 'greenhouse_jobs':
                res = option.status !== GreenhouseJobStatusEnum.Closed;
                break;
            case 'lever_postings':
                res = option.state !== LeverPostingStateEnum.Closed;
                break;
            default:
                break;
        }
        return res;
    });
    return filteredJobsData as GreenhouseJobsData | LeverPostingsData | AshbyJobsData;
};

export const getAtsJobOptions = (filteredJobsData: GreenhouseJobsData | LeverPostingsData | AshbyJobsData) => {
    let groups: AtsJobSelectGroup = {
        [AshbyJobStatusEnum.Open]: [],
        [AshbyJobStatusEnum.Draft]: [],
        [AshbyJobStatusEnum.Archived]: [],
        [AshbyJobStatusEnum.Closed]: [],
        [GreenhouseJobStatusEnum.Open]: [],
        [GreenhouseJobStatusEnum.Draft]: [],
        [GreenhouseJobStatusEnum.Closed]: [],
        [LeverPostingStateEnum.Published]: [],
        [LeverPostingStateEnum.Internal]: [],
        [LeverPostingStateEnum.Pending]: [],
        [LeverPostingStateEnum.Rejected]: [],
    };

    filteredJobsData?.forEach((option) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (option.__typename) {
            case 'ashby_jobs':
            case 'greenhouse_jobs':
                groups = {
                    ...groups,
                    [option.status]: [...groups[option.status], { id: option.id, name: option.name }],
                };
                break;
            case 'lever_postings':
                groups = {
                    ...groups,
                    [option.state]: [...groups[option.state], { id: option.id, name: option.name }],
                };
                break;
            default:
                break;
        }
    });

    let res: GroupedSelectOption[] = [];
    for (const [status, options] of Object.entries(groups)) {
        // only add the group if there are values to be displayed
        if (!isEmpty(options)) {
            res = [
                ...res,
                { label: '-', value: '', group: status },
                ...options.map((option) => ({
                    label: option.name,
                    value: option.id,
                    group: status,
                })),
            ];
        }
    }
    return res;
};

export const getAtsUserOptions = (usersData: GreenhouseUsersData | LeverUsersData | AshbyUsersData): SelectOption[] => {
    const selectOptions: (SelectOption | null)[] = usersData?.map((option) => {
        let name: string;
        let isValid: boolean = true;
        // eslint-disable-next-line no-underscore-dangle
        switch (option.__typename) {
            case 'ashby_users':
                name = `${option.firstName || ''} ${option.lastName || ''}`;
                if (name.trim().length === 0) name = option.email || '';
                break;
            case 'greenhouse_users':
                name = `${option.firstName || ''} ${option.lastName || ''}`;
                if (name.trim().length === 0) name = option.primaryEmailAddress || '';

                if (option.deactivated) isValid = false;
                break;
            case 'lever_users':
                name = option.name || '';
                if (name.trim().length === 0) name = option.username || '';
                if (name.trim().length === 0) name = option.email || '';
                break;
            default:
                name = '';
                break;
        }

        // don't show deactivated users, etc.
        if (!isValid) return null;

        // not going to show options where the label is an empty string
        if (!name) return null;

        return {
            label: name,
            value: option.id,
        };
    });
    // removing options that are null (using predicate to suppress type errors)
    return selectOptions.filter((f): f is Exclude<typeof f, null> => f != null);
};
