const concatenateStrings = (originalValue: string, index: number, insertValue: string): string =>
    originalValue.substr(0, index) + insertValue + originalValue.substr(index);

const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

const addHttpsToUrl = (url: string) => {
    if (url.startsWith('https')) {
        return url;
    }
    return `https://${url}`;
};

const insertTextWithinString = (
    original: string,
    startIndex: number,
    endIndex: number,
    insertValue: string
): string => {
    const before = original.substring(0, startIndex);
    const after = original.substring(endIndex);
    const updatedString = before + insertValue + after;

    return updatedString;
};

export { concatenateStrings, capitalizeFirstLetter, addHttpsToUrl, insertTextWithinString };
