import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { trackEvent } from 'analytics';
import {
    SendFromAccountStatusEnum,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SequenceStageSendTimeEnum,
    SequenceStageSendTimeOfDayEnum,
    SequenceStageSendTypeOfDayEnum,
    SequenceStatusEnum,
    UpdateSequenceStagesMutation,
} from 'codegen/graphql';
import { SequenceEditBar } from 'sequences/components/presentational';
import { CreateEditStagesTemplate } from 'sequences/components/templates';
import { emailTemplates } from 'sequences/settings';
import { CreateEditSequenceStagesFormValues, Stage, UnsavedSequenceStages } from 'sequences/types';
import { isMagicSentenceUsed } from 'sequences/utils';
import {
    handleStageContentTrailingNewLine,
    handleStagesTrailingNewLines,
    isAnySubjectContentChange,
} from 'sequences/utils/form-stage';
import { getUnknownVariablesError, validVariables } from 'sequences/utils/unknown-variables';
import { Form } from 'shared/components/form';
import { Button, Snackbar } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import { GET_SEND_FROM_ACCOUNTS } from 'shared/graphql/send-from-accounts';
import { GET_SEQUENCE_COLLABORATORS } from 'shared/graphql/sequence-collaborators';
import { GET_SEQUENCE_BY_ID, SequenceData, UPDATE_SEQUENCE_STAGES } from 'shared/graphql/sequences';
import {
    MagicSentenceContextProvider,
    SequenceStageOperationsContextProvider,
    useAccessControl,
    useConfirmationModal,
    useSession,
    useSnackbarAlert,
} from 'shared/hooks';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { FC } from 'shared/types';

interface EditStagesFormProps {
    sequence: SequenceData;
}

const timeInterval = 2000;

const EditStagesForm: FC<EditStagesFormProps> = (props) => {
    const { sequence } = props;
    const { session, loaded: sessionLoaded } = useSession();
    const { canEditSequence } = useAccessControl();

    const translate = useTranslations('sequence');

    const [isEditSnackBarOpen, setIsEditSnackBarOpen] = useState<boolean>(false);
    const [magicSentenceUsed, setMagicSentenceUsed] = useState<boolean>(false);

    const { showModal, hideModal } = useConfirmationModal();
    const { showSnackbarAlert } = useSnackbarAlert();

    const theme = useTheme();

    const { data } = useQuery<SendFromAccountsQuery, SendFromAccountsQueryVariables>(GET_SEND_FROM_ACCOUNTS, {
        variables: {
            where: {
                status: {
                    _eq: SendFromAccountStatusEnum.Approved,
                },
                userId: {
                    _eq: session?.user.id,
                },
            },
        },
        skip: !sessionLoaded,
    });

    const [updateSequenceStages] = useMutation<UpdateSequenceStagesMutation>(UPDATE_SEQUENCE_STAGES, {
        refetchQueries: [
            getOperationName(GET_SEQUENCE_BY_ID) as string,
            getOperationName(GET_SEQUENCE_COLLABORATORS) as string,
        ],
    });

    const getErrorString = (value: string) => {
        const errorMessage = getUnknownVariablesError(value);
        if (errorMessage) {
            return errorMessage.count
                ? translate('details.unknown-variables-alert-with-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                      count: errorMessage.count,
                  })
                : translate('details.unknown-variables-alert-without-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                  });
        }
        return '';
    };

    const validationSchema = Yup.object().shape({
        stages: Yup.array().of(
            Yup.object().shape({
                sendTypeOfDays: Yup.string().required(translate('create.stages.error-generic-option')),
                subject: Yup.string()
                    .required(translate('create.stages.error-subject'))
                    .test(
                        'unknown-variables-in-subject',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
                content: Yup.string()
                    .required(translate('create.stages.error-email-content'))
                    .test(
                        'unknown-variables-in-content',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
            })
        ),
    });

    const formInitialValues: CreateEditSequenceStagesFormValues = {
        stages: sequence.stages.map((stage) => {
            const formStage: Stage = {
                bcc: stage.bcc,
                cc: stage.cc,
                content: handleStageContentTrailingNewLine(stage.content),
                numberOfDays: stage.numberOfDays ?? undefined,
                replyToLastEmail: stage.replyToLastEmail,
                sendFromAccountId: stage.sendFromAccount?.id ?? '', // must be empty string else edit bar will be shown
                sendTime: stage.sendTime ?? undefined,
                signature: stage.signature ?? undefined,
                useSignature: stage.useSignature,
                subject: stage.subject,
                sendTimeOfDay: stage.sendTimeOfDay ?? undefined,
                sendTypeOfDays: stage.sendTypeOfDays ?? undefined,
            };
            // eslint-disable-next-line no-underscore-dangle
            return formStage;
        }),
    };

    const [storedEditSequence, setStoredEditSequence, loaded] = useLocalStorage<UnsavedSequenceStages | undefined>(
        'sequenceUnsavedStages',
        undefined
    );

    const formMethods = useForm<CreateEditSequenceStagesFormValues>({
        mode: 'onTouched',
        defaultValues: formInitialValues,
        resolver: yupResolver(validationSchema),
    });

    // "stages" contain methods used to manipulate the stages array
    const stages = useFieldArray({
        control: formMethods.control,
        name: 'stages',
    });

    // subscribe to form state changes
    const { formState } = formMethods;

    const checkMagicSentenceUsed = () => {
        setMagicSentenceUsed(isMagicSentenceUsed(formMethods.getValues('stages')));
    };

    const updateSequenceStageLocalStorage = useCallback(() => {
        if (!loaded) return;
        // perform a deep comparison between current form values and stored form values to avoid unnecessary re-renders
        setStoredEditSequence((storedValue) => {
            const formSequenceStages = formMethods.getValues('stages');
            const areStagesEqual = isEqual(
                storedValue?.stages.map((stage) => pickBy(stage, (v) => v !== undefined)),
                formSequenceStages.map((stage) => pickBy(stage, (v) => v !== undefined))
            );
            if (!areStagesEqual) {
                return {
                    stages: formSequenceStages,
                    sequenceId: sequence.id,
                };
            }
            return storedValue;
        });
    }, [formMethods, sequence.id, setStoredEditSequence, loaded]);

    /**
     * reset the form to the initial values when the sequence changes (when a different sequence id is detected)
     * this fixes issues with the "pins" feature
     * also checking if we have a locally stored form with unsaved stage changes,
     * if yes, then setting the formMethods values to the unsaved ones and setting the form to dirty
     */
    useEffect(() => {
        if (!loaded) return;
        const sequenceStages = storedEditSequence?.stages.map((stage) => pickBy(stage, (v) => v !== undefined));
        const formInitialStages = formInitialValues.stages.map((stage) => pickBy(stage, (v) => v !== undefined));

        // compare stored sequence stages with stored sequence stages
        if (
            storedEditSequence &&
            storedEditSequence.sequenceId === sequence.id &&
            storedEditSequence.stages.length === formInitialValues.stages.length &&
            !isEqual(sequenceStages, formInitialStages)
        ) {
            formMethods.setValue('stages', storedEditSequence.stages, {
                shouldDirty: true,
            });
        } else {
            formMethods.reset(formInitialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequence.id, loaded]);

    useEffect(() => {
        const intervalPeriod = timeInterval;
        const interval = setInterval(() => {
            updateSequenceStageLocalStorage();
        }, intervalPeriod);
        return () => {
            clearInterval(interval);
        };
    }, [updateSequenceStageLocalStorage]);

    useEffect(() => {
        // don't worry about values that are undefined; we can exclude these in the comparison
        if (
            !isEqual(
                formInitialValues.stages.map((stage) => pickBy(stage, (v) => v !== undefined)),
                formMethods.getValues('stages').map((stage) => pickBy(stage, (v) => v !== undefined))
            )
        ) {
            setIsEditSnackBarOpen(true);
        } else {
            setIsEditSnackBarOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const handleSaveChangesConfirm = async () => {
        let values = formMethods.getValues();
        values = {
            ...values,
            stages: handleStagesTrailingNewLines(values.stages),
        };

        const res: FetchResult<
            UpdateSequenceStagesMutation,
            Record<string, any>,
            Record<string, any>
        > = await updateSequenceStages({
            variables: {
                sequenceId: sequence.id,
                stages: values.stages.map((stage, index) => {
                    const sequenceStageInput = {
                        ...stage,
                        sequenceId: sequence.id,
                        stageIndex: index,
                        sendFromAccountId: stage.sendFromAccountId || null,
                    };
                    return sequenceStageInput;
                }),
            },
        });

        if (res.data?.update_sequence_stages) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('details.edit-success-message', { title: sequence.title }),
            });
            hideModal();
            formMethods.reset(values);
            updateSequenceStageLocalStorage();
            checkMagicSentenceUsed();
        }

        const { contentChanged, subjectChanged } = isAnySubjectContentChange(formInitialValues.stages, values.stages);
        if (contentChanged) {
            trackEvent('edit_email_content', {
                page_name: 'sequence_details',
                tab_name: 'edit_tab',
                sequence_id: sequence.id,
            });
        }
        if (subjectChanged) {
            trackEvent('edit_email_subject', {
                page_name: 'sequence_details',
                tab_name: 'edit_tab',
                sequence_id: sequence.id,
            });
        }
    };

    const handleDiscardChangesClick = () => {
        formMethods.reset(formInitialValues);
        updateSequenceStageLocalStorage();
        checkMagicSentenceUsed();
    };

    const handleRemoveStage = (selectedStageIndex: number) => {
        formMethods.setValue(
            'stages',
            [
                ...formMethods.getValues('stages').slice(0, selectedStageIndex),
                ...formMethods
                    .getValues('stages')
                    .slice(selectedStageIndex + 1, formMethods.getValues('stages').length),
            ],
            { shouldDirty: true }
        );
        checkMagicSentenceUsed();
    };

    const handleSaveChangesClick = async () => {
        // validate form before saving
        await formMethods.trigger(undefined, { shouldFocus: true });
        if (!isEmpty(formMethods.formState.errors)) {
            const combinedStageContent = formMethods
                .getValues('stages')
                .map((stage) => stage.subject.concat(stage.content))
                .join(' ');

            if (!validVariables(combinedStageContent)) {
                const message = getErrorString(combinedStageContent);
                showSnackbarAlert({
                    severity: 'error',
                    message,
                });
            }
            return;
        }

        if (sequence.stats?.[0].added === 0) {
            formMethods.handleSubmit(handleSaveChangesConfirm)();
        } else {
            showModal({
                title: translate('details.edit-sequence-modal-header'),
                description: translate('details.edit-sequence-modal-description'),
                cancelButton: {
                    text: translate('details.edit-sequence-cancel-button'),
                    onClick: () => {
                        hideModal();
                        handleDiscardChangesClick();
                    },
                },
                confirmButton: {
                    text: translate('details.save-changes'),
                    onClick: formMethods.handleSubmit(handleSaveChangesConfirm),
                },
            });
        }
        checkMagicSentenceUsed();
    };

    /**
     * check if the magic sentence has been used on mount
     */
    useEffect(() => {
        checkMagicSentenceUsed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddAdditionalStage = () => {
        let defaultSendFromEmailAccount = '';
        if (data?.send_from_accounts) {
            const sendFromAccounts = data.send_from_accounts;

            const userSendFromAccount = sendFromAccounts.find(
                (emailAccount) => emailAccount.email === session?.user.email
            );

            if (sendFromAccounts.length === 1) {
                defaultSendFromEmailAccount = sendFromAccounts[0].id;
            } else if (userSendFromAccount) {
                defaultSendFromEmailAccount = userSendFromAccount.id;
            }
        }

        const firstStage = formMethods.getValues('stages')[0];

        const defaultInitialValues: Omit<Stage, 'content'> = {
            bcc: [],
            cc: [],
            numberOfDays: 5,
            replyToLastEmail: true,
            sendFromAccountId: defaultSendFromEmailAccount,
            sendTime: SequenceStageSendTimeEnum.AsSoonAsPossible,
            subject: "{{First Name}}, let's connect?",
            sendTimeOfDay: SequenceStageSendTimeOfDayEnum.AutoOptimizeTime,
            sendTypeOfDays: SequenceStageSendTypeOfDayEnum.WeekDays,
            signature: firstStage.signature,
            useSignature: firstStage.useSignature,
        };

        formMethods.setValue('stages', [
            ...formMethods.getValues('stages'),
            {
                content: emailTemplates.blank,
                ...defaultInitialValues,
                signature: firstStage.signature,
            },
        ]);

        trackEvent('click_add_stage', {
            page_name: 'sequence_details',
            sequence_id: sequence.id,
        });
    };

    if (!loaded) {
        return null;
    }

    return (
        <MagicSentenceContextProvider
            magicSentenceUsed={magicSentenceUsed}
            setMagicSentenceUsed={setMagicSentenceUsed}
            checkMagicSentenceUsed={checkMagicSentenceUsed}
        >
            <SequenceStageOperationsContextProvider handleRemoveStage={handleRemoveStage}>
                <Form onSubmit={formMethods.handleSubmit(handleSaveChangesConfirm)}>
                    <CreateEditStagesTemplate
                        isEdit
                        formMethods={formMethods}
                        stages={stages}
                        readOnly={sequence.status === SequenceStatusEnum.Archived || !canEditSequence(sequence)}
                        sequenceStatus={sequence.status}
                        sequenceId={sequence.id}
                    />
                    {sequence.stats?.[0].added === 0 && (
                        <Button
                            type="submit"
                            variant="outlined"
                            onClick={onAddAdditionalStage}
                            startIcon={<Plus stroke={theme.palette.primary.main} />}
                        >
                            {translate('add-additional-stage')}
                        </Button>
                    )}
                </Form>
                <Snackbar
                    open={isEditSnackBarOpen}
                    autoHideDuration={undefined}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <SequenceEditBar
                        onDiscardChanges={handleDiscardChangesClick}
                        onSaveChanges={handleSaveChangesClick}
                    />
                </Snackbar>
            </SequenceStageOperationsContextProvider>
        </MagicSentenceContextProvider>
    );
};

export { EditStagesForm };
