import { Timezone } from 'hireflow-shared/types/timezone';
import { useRouter } from 'next/router';
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { setMixpanelUser } from 'analytics';
import {
    EmailAccountProviderEnum,
    HireflowEnumsUserFeaturesEnum,
    LicenseTypesEnum,
    TeamRolesEnum,
} from 'codegen/graphql';
import { registerClarity } from 'external/clarity';
import { registerIntercom, signOutIntercom } from 'external/intercom';
import { setBugsnagUser } from 'shared/hooks/bugsnag-error-boundary';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { createRestApiClient } from 'shared/services';

export interface Session {
    user: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        fullName: string;
        primaryTimezone: Timezone;
        addLabelToHiresequenceMessages: boolean;
        autoArchiveBouncedEmails: boolean;
        autoArchiveOutOfOfficeEmails: boolean;
        includeUnsubscribeLinkToEmailOutreaches: boolean;
        calendarLink?: string;
        draftResponses?: any;
        findAlternateEmailIfInitialBounces: boolean;
        hireflowSignature?: string;
        sendEmailsHourEnd: number;
        sendEmailsHourStart: number;
        teamId: string;
        teamDomain: string;
        lastUsedSequence?: string;
        lastUsedProjects: string[];
        role: TeamRolesEnum;
        licenseType: LicenseTypesEnum;
        createdAt: number;
        onboarded: boolean;
        features?: HireflowEnumsUserFeaturesEnum[];
        loginEmailProvider?: EmailAccountProviderEnum;
    };
    isImpersonating?: boolean;
}

const SessionContext = createContext<{
    session: Session | undefined;
    setSession: (data: Session | undefined) => void;
    loaded: boolean | undefined;
}>({
    session: undefined,
    setSession: () => {
        /** no-op */
    },
    loaded: undefined,
});

const sessionKey = 'session';
// eslint-disable-next-line no-magic-numbers
const sessionExpirationMs = 90 * 24 * 3600 * 1000; // 90 days

const SessionProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const [session, setSession, loaded] = useLocalStorage<Session | undefined>(
        sessionKey,
        undefined,
        sessionExpirationMs
    );
    const [thisSession, setThisSession] = useState<Session | undefined>(session);
    const [thisLoaded, setThisLoaded] = useState<boolean>(false);

    useEffect(() => {
        // it is safe to consider the session loaded if thisSession is not undefined
        if (thisSession && !thisLoaded) {
            setThisLoaded(true);
        }

        setSession(thisSession);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thisSession]);

    const fetchSession = useCallback(async () => {
        if (router.pathname.startsWith('/login')) {
            // don't try to fetch the session if we are in the login page
            return;
        }

        try {
            const restApiClient = createRestApiClient();
            if (!router.pathname.startsWith('/outlook')) {
                const sessionResponse = await restApiClient.getSession();
                if (sessionResponse.success && sessionResponse.data) {
                    setThisSession(sessionResponse.data);
                } else {
                    setThisLoaded(true);
                }
            }
        } catch (error) {
            setThisLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setThisSession]);

    useEffect(() => {
        if (!loaded) return;

        if (!session) {
            fetchSession();
        } else {
            setThisSession(session);
        }
    }, [fetchSession, loaded, session]);

    useEffect(() => {
        if (
            thisLoaded &&
            !thisSession &&
            router.pathname !== '/login' &&
            router.pathname !== '/approve-sender' &&
            router.pathname !== '/unsubscribe' &&
            // Not Extension path
            router.pathname !== '/popup.html' && // TODO: remove when ext. v2.3.0 is adopted
            router.pathname !== '/extension-action' &&
            router.pathname !== '/extension-launcher' &&
            router.pathname !== '/extension-panel' &&
            !router.pathname.startsWith('/outlook')
        ) {
            router.push('/login');
        }

        if (thisLoaded && thisSession && !thisSession.isImpersonating) {
            setBugsnagUser(thisSession);
            setMixpanelUser(thisSession);
            if (
                // Not Extension path
                router.pathname !== '/popup.html' && // TODO: remove when ext. v2.3.0 is adopted
                router.pathname !== '/extension-action' &&
                router.pathname !== '/extension-launcher' &&
                router.pathname !== '/extension-panel' &&
                // ignore impersonate path
                router.pathname !== '/impersonate/[email]'
            ) {
                registerIntercom(thisSession);
                registerClarity(thisSession);
            }
        } else if (thisSession && thisSession.isImpersonating) {
            signOutIntercom();
        }
    }, [thisLoaded, thisSession, router]);

    const handleVisibilityChange = async () => {
        // ignore if not visible
        if (document.visibilityState !== 'visible') {
            return;
        }

        // don't do anything if the session exists
        if (thisSession) {
            return;
        }

        fetchSession();
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sessionProviderValue = useMemo(
        () => ({
            session: thisSession,
            setSession: setThisSession,
            loaded: thisLoaded,
        }),
        [thisSession, setThisSession, thisLoaded]
    );

    return <SessionContext.Provider value={sessionProviderValue}>{children}</SessionContext.Provider>;
};

const useSession = () => useContext(SessionContext);

export { SessionProvider, useSession };
