import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';

import { SourcerMemberRejectionReasonsEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { SourcerMemberCardRejectionNotes } from 'sourcing/components/composite/sourcer-member-card-rejection-notes';
import { SourcerMemberCardRejectionReasons } from 'sourcing/components/composite/sourcer-member-card-rejection-reasons';

// prevents the notes from getting re-rendered (and re-focused) when the reasons are toggled
const SourcerMemberCardRejectionNotesMemo = React.memo(SourcerMemberCardRejectionNotes);

interface SourcerMemberCardRejectionDetailsProps {
    sourcerMemberId: string;
    rejectionReasons?: SourcerMemberRejectionReasonsEnum[];
    notes?: string;
    onNotesChange: (sourcerMemberId: string, newNotes: string) => void;
    onRejectionReasonsChange: (
        sourcerMemberId: string,
        newRejectionReasons: SourcerMemberRejectionReasonsEnum[]
    ) => void;
    showRejectionReasons?: boolean;
}

const SourcerMemberCardRejectionDetails: FC<SourcerMemberCardRejectionDetailsProps> = ({
    sourcerMemberId,
    notes,
    onNotesChange,
    rejectionReasons,
    onRejectionReasonsChange,
    showRejectionReasons = false,
}) => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.sourcer-member-card.rejection-notes-section');

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: ${spacing.space12px};
                padding: ${spacing.space24px};
                background: ${theme.palette.grey[300]};
                border: 1px solid ${theme.palette.grey[500]};
                border-radius: 4px;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    gap: ${spacing.space12px};
                `}
            >
                <Box
                    css={css`
                        font-weight: ${fontWeights.bold};
                    `}
                    component="span"
                >
                    {translate('why-did-you-reject-this-prospect?')}
                </Box>
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                    `}
                    component="span"
                >
                    {translate('optional')}
                </Box>
            </Box>
            {showRejectionReasons && (
                <SourcerMemberCardRejectionReasons
                    sourcerMemberId={sourcerMemberId}
                    rejectionReasons={rejectionReasons}
                    onRejectionReasonsChange={onRejectionReasonsChange}
                />
            )}

            <SourcerMemberCardRejectionNotesMemo
                sourcerMemberId={sourcerMemberId}
                notes={notes}
                onNotesChange={onNotesChange}
            />
        </Box>
    );
};

export { SourcerMemberCardRejectionDetails };
