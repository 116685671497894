import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { LicenseTypesEnum, TeamRolesEnum } from 'codegen/graphql';
import { TeamMemberData } from 'settings/components/presentational/plans-and-billing/plan-privileges/enterprise-plan';
import { Box } from 'shared/components/containers';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface SeatsProps {
    totalSeats: number;
    teamMembers: TeamMemberData[];
}

const Seats: FC<SeatsProps> = (props) => {
    const theme = useTheme();
    const translate = useTranslations('settings.plans-and-billing-settings');
    const { totalSeats, teamMembers } = props;

    return (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;
                p {
                    font-weight: ${fontWeights.normal};
                    font-size: ${fontSizes.f16};
                    line-height: 19px;
                    color: ${theme.palette.common.black};
                    margin: 8px 0;
                }
            `}
        >
            <p>{translate('users-label')}</p>
            <p>
                {translate('seats-total', {
                    used: teamMembers.filter(
                        (member) =>
                            (member.role === TeamRolesEnum.User && member.licenseType === LicenseTypesEnum.Full) ||
                            member.role === TeamRolesEnum.Owner ||
                            member.role === TeamRolesEnum.Admin
                    ).length,
                    total: totalSeats,
                })}
            </p>
        </Box>
    );
};

export { Seats };
