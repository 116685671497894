import styled from '@emotion/styled';
import { TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableRowProps extends MuiTableRowProps {}

const TableRow: FC<TableRowProps> = (props) => <MuiTableRow {...props} />;

const StyledTableRow = styled(TableRow)`
    &.Mui-selected .MuiTableCell-root {
        ${({ theme }) => `
        background-color: ${theme.palette.primary.contrastText};
        `}
    }
`;

export { StyledTableRow as TableRow };
