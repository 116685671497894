import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import {
    AshbyJobInterviewPlansByJobIdQuery,
    AshbyJobsQuery,
    AshbyUsersQuery,
    GreenhouseJobStagesQuery,
    GreenhouseJobsQuery,
    GreenhouseUsersQuery,
    LeverPostingsQuery,
    LeverStagesQuery,
    LeverUsersQuery,
} from 'codegen/graphql';
import { ConnectAtsPrompt } from 'integrations/components/templates';
import { AtsSyncSettingsSequenceTemplate } from 'integrations/components/templates/sequences';
import { atsTypeAshby, atsTypeGreenhouse, atsTypeLever } from 'integrations/constants';
import { preferenceOptions } from 'sequences/settings';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { Box, Container } from 'shared/components/containers';
import { SelectChip, Select, MenuItem } from 'shared/components/form';
import { FormSection } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import {
    AshbyInterviewPlanStagesData,
    AshbyUsersData,
    GET_ASHBY_JOBS,
    GET_ASHBY_JOB_INTERVIEW_PLANS_BY_JOB_ID,
    GET_ASHBY_USERS,
} from 'shared/graphql/integrations/ashby';
import {
    GET_GREENHOUSE_JOBS,
    GET_GREENHOUSE_JOB_STAGES,
    GET_GREENHOUSE_PERMISSIONED_JOB_USERS,
    GreenhouseJobStagesData,
    GreenhouseUsersData,
} from 'shared/graphql/integrations/greenhouse';
import {
    GET_LEVER_POSTINGS,
    GET_LEVER_STAGES,
    GET_LEVER_USERS,
    LeverStagesData,
    LeverUsersData,
} from 'shared/graphql/integrations/lever';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateEditSettingsTemplateProps {
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    projects: Array<{ label: string; value: string }>;
    readOnly?: boolean;
    greenhouseConnected?: boolean;
    leverConnected?: boolean;
    ashbyConnected?: boolean;
    isEdit?: boolean;
}

const CreateEditSettingsTemplate: FC<CreateEditSettingsTemplateProps> = (props) => {
    const {
        formMethods,
        projects,
        readOnly = false,
        ashbyConnected = false,
        greenhouseConnected = false,
        leverConnected = false,
        isEdit,
    } = props;
    const translate = useTranslations('sequence.create.settings');

    // ashby
    const [ashbyInterviewPlanStagesData, setAshbyInterviewPlanStagesData] = useState<AshbyInterviewPlanStagesData>([]);

    const { data: ashbyJobsData } = useQuery<AshbyJobsQuery>(GET_ASHBY_JOBS);

    const [ashbyUsersData, setAshbyUsersData] = useState<AshbyUsersData>([]);

    const [getAshbyUsersData, { data: ashbyUsersLazyQueryData }] = useLazyQuery<AshbyUsersQuery>(GET_ASHBY_USERS);

    useEffect(() => {
        setAshbyUsersData(ashbyUsersLazyQueryData?.data ?? []);
    }, [ashbyUsersLazyQueryData]);

    const [getAshbyInterviewPlanStagesData, { data: ashbyLazyQueryData }] =
        useLazyQuery<AshbyJobInterviewPlansByJobIdQuery>(GET_ASHBY_JOB_INTERVIEW_PLANS_BY_JOB_ID, {
            variables: { jobId: formMethods.getValues('ashbyJobId') },
        });

    useEffect(() => {
        const flattened: AshbyInterviewPlanStagesData =
            _.flatten(
                ashbyLazyQueryData?.data.map((a) =>
                    a.interviewPlan.stages.map((b) => b).sort((c, d) => c.orderInInterviewPlan - d.orderInInterviewPlan)
                )
            ) ?? [];
        setAshbyInterviewPlanStagesData(flattened);
    }, [ashbyLazyQueryData]);

    // greenhouse
    const [greenhouseJobStagesData, setGreenhouseJobStagesData] = useState<GreenhouseJobStagesData>([]);

    const { data: greenhouseJobsData } = useQuery<GreenhouseJobsQuery>(GET_GREENHOUSE_JOBS);

    const [greenhouseUsersData, setGreenhouseUsersData] = useState<GreenhouseUsersData>([]);

    const [getGreenhouseUsersData, { data: greenhouseUsersLazyQueryData }] = useLazyQuery<GreenhouseUsersQuery>(
        GET_GREENHOUSE_PERMISSIONED_JOB_USERS,
        { variables: { jobId: formMethods.getValues('greenhouseJobId') } }
    );

    const [getGreenhouseJobStagesData, { data: greenhouseLazyQueryData }] = useLazyQuery<GreenhouseJobStagesQuery>(
        GET_GREENHOUSE_JOB_STAGES,
        { variables: { jobId: formMethods.getValues('greenhouseJobId') } }
    );

    useEffect(() => {
        setGreenhouseJobStagesData(greenhouseLazyQueryData?.data ?? []);
    }, [greenhouseLazyQueryData]);

    useEffect(() => {
        setGreenhouseUsersData(greenhouseUsersLazyQueryData?.data ?? []);
    }, [greenhouseUsersLazyQueryData]);

    // lever
    const [leverStagesData, setLeverStagesData] = useState<LeverStagesData>([]);

    const { data: leverPostingsData } = useQuery<LeverPostingsQuery>(GET_LEVER_POSTINGS);

    const [leverUsersData, setLeverUsersData] = useState<LeverUsersData>([]);

    const [getLeverUsersData, { data: leverUsersLazyQueryData }] = useLazyQuery<LeverUsersQuery>(GET_LEVER_USERS);
    useEffect(() => {
        setLeverUsersData(leverUsersLazyQueryData?.data ?? []);
    }, [leverUsersLazyQueryData]);

    const [getLeverStagesData, { data: leverLazyQueryData }] = useLazyQuery<LeverStagesQuery>(GET_LEVER_STAGES);

    useEffect(() => {
        setLeverStagesData(leverLazyQueryData?.data ?? []);
    }, [leverLazyQueryData]);

    const emailPreferenceState = formMethods.getFieldState(`emailPreference`, formMethods.formState);

    const emailPreference = useWatch({ control: formMethods.control, name: 'emailPreference' });
    useEffect(() => {
        if (emailPreferenceState.isDirty) {
            trackEvent('select_email_preference', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
            });
        }
    }, [emailPreferenceState.isDirty, emailPreference, isEdit]);

    const getOptions = () =>
        preferenceOptions().map((option) => (
            <MenuItem value={option.value} key={option.label}>
                {option.value === formMethods.getValues('emailPreference') && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {option.label}
            </MenuItem>
        ));

    const formatAddProjectLabel = (inputValue: string) => translate('add-project', { projectName: inputValue });

    const isAtsConnected: boolean = ashbyConnected || greenhouseConnected || leverConnected;

    return (
        <Container
            css={css`
                margin-bottom: ${spacing.space48px};
                padding: ${spacing.space48px} ${spacing.space48px};
            `}
        >
            <FormSection title={translate('would-you-like-to-associate-this-sequence-to-any-projects')}>
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: ${spacing.space48px};
                    `}
                >
                    <SelectChip
                        isDisabled={readOnly}
                        isCreatable
                        options={projects}
                        name="projects"
                        placeholder={translate('projects')}
                        formMethods={formMethods}
                        value={formMethods.getValues('projects')}
                        formatCreateLabel={formatAddProjectLabel}
                        css={css`
                            width: 580px;

                            @media (max-width: 1000px) {
                                width: 354px;
                            }
                        `}
                    />
                </Box>
            </FormSection>

            <FormSection title={translate('preference-in-regards-to-personal-or-work-email')}>
                <Select
                    css={css`
                        z-index: 0;
                    `}
                    disabled={readOnly}
                    label={translate('preference')}
                    formMethods={formMethods}
                    name="emailPreference"
                    value={formMethods.getValues('emailPreference')}
                >
                    {getOptions()}
                </Select>
            </FormSection>

            {!isAtsConnected && (
                <Box
                    css={css`
                        margin-top: ${spacing.space36px};
                        padding: ${spacing.space16px};
                        border-radius: ${spacing.space8px};

                        background-color: ${colors.grays.alabaster};
                    `}
                >
                    <ConnectAtsPrompt />
                </Box>
            )}

            {ashbyConnected && (
                <AtsSyncSettingsSequenceTemplate
                    formMethods={formMethods}
                    atsType={atsTypeAshby}
                    jobsData={ashbyJobsData?.data ?? []}
                    stagesData={ashbyInterviewPlanStagesData}
                    getStagesData={getAshbyInterviewPlanStagesData}
                    usersData={ashbyUsersData}
                    getUsersData={getAshbyUsersData}
                />
            )}

            {greenhouseConnected && (
                <AtsSyncSettingsSequenceTemplate
                    formMethods={formMethods}
                    atsType={atsTypeGreenhouse}
                    jobsData={greenhouseJobsData?.data ?? []}
                    stagesData={greenhouseJobStagesData}
                    getStagesData={getGreenhouseJobStagesData}
                    usersData={greenhouseUsersData}
                    getUsersData={getGreenhouseUsersData}
                />
            )}

            {leverConnected && (
                <AtsSyncSettingsSequenceTemplate
                    formMethods={formMethods}
                    atsType={atsTypeLever}
                    jobsData={leverPostingsData?.data ?? []}
                    stagesData={leverStagesData}
                    getStagesData={getLeverStagesData}
                    usersData={leverUsersData}
                    getUsersData={getLeverUsersData}
                />
            )}
        </Container>
    );
};

export { CreateEditSettingsTemplate };
