import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedUserCheck: FC<SvgProps> = ({ stroke, theme, width, height, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '24'}
        height={height ?? '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M15.6001 18V16.8C15.6001 15.1198 15.6001 14.2798 15.2731 13.638C14.9855 13.0735 14.5266 12.6146 13.9621 12.327C13.3203 12 12.4803 12 10.8001 12H6.4001C4.71994 12 3.87986 12 3.23813 12.327C2.67364 12.6146 2.2147 13.0735 1.92708 13.638C1.6001 14.2798 1.6001 15.1198 1.6001 16.8V18M15.6001 3L17.6001 5L21.6001 1M12.1001 4.5C12.1001 6.433 10.5331 8 8.6001 8C6.6671 8 5.1001 6.433 5.1001 4.5C5.1001 2.567 6.6671 1 8.6001 1C10.5331 1 12.1001 2.567 12.1001 4.5Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const UserCheck = withTheme(ThemedUserCheck);

export { UserCheck };
