import { css } from '@emotion/react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { SequenceStatusEnum } from 'codegen/graphql';
import { ComposeEmail } from 'sequences/components/form';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface CreateEditStagesTemplateProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    stages: UseFieldArrayReturn<CreateEditSequenceStagesFormValues, 'stages', 'id'>;
    isEdit?: boolean;
    readOnly?: boolean;
    sequenceStatus?: SequenceStatusEnum;
    sequenceId?: string;
}

const CreateEditStagesTemplate: FC<CreateEditStagesTemplateProps> = (props) => {
    const { formMethods, stages, isEdit = false, readOnly = false, sequenceId, sequenceStatus } = props;

    return (
        <Box
            css={css`
                max-width: 960px;
                margin: auto;
            `}
        >
            {stages.fields?.map((_stage, index) => (
                <Box key={index}>
                    <ComposeEmail
                        formMethods={formMethods}
                        index={index}
                        readOnly={readOnly}
                        isEdit={isEdit}
                        sequenceStatus={sequenceStatus}
                        sequenceId={sequenceId}
                    />
                </Box>
            ))}
        </Box>
    );
};

export { CreateEditStagesTemplate };
