import { useTranslations } from 'next-intl';

import { NonEditableSettingsSection } from 'settings/components/containers';
import { useSession } from 'shared/hooks/use-session';

const PrimaryEmail = () => {
    const translate = useTranslations('settings.account-settings.primary-email');
    const { session } = useSession();

    return <NonEditableSettingsSection title={translate('title')}>{session?.user?.email}</NonEditableSettingsSection>;
};

export { PrimaryEmail };
