import styled from '@emotion/styled';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { CheckboxChecked, CheckboxIndeterminate, CheckboxUnchecked } from 'shared/components/svgs';
import { FCProps } from 'shared/types';

interface CheckboxProps<Values extends FieldValues> extends MuiCheckboxProps {
    name?: Path<Values>;
    formMethods?: UseFormReturn<Values>;
}

const CheckboxContainer = <Values extends FieldValues>(props: CheckboxProps<Values> & FCProps) => {
    const { name, formMethods, ...muiProps } = props;
    const { checked, onChange, ...rest } = muiProps;

    // custom hook to re-render the select component if field is being controlled by a form
    if (name) formMethods?.watch(name);

    if (name && formMethods) {
        return (
            <Controller
                name={name}
                control={formMethods?.control}
                render={({ field: { ref, onChange: _onChange } }) => (
                    <MuiCheckbox
                        inputRef={ref}
                        name={name}
                        checked={checked ?? (name && formMethods?.getValues(name))}
                        onChange={(_event: ChangeEvent<HTMLInputElement>, _checked: boolean) => {
                            if (onChange) onChange(_event, _checked); // allow onChange method to execute iff exists
                            _onChange(_event, _checked); // this.onChange will always be executed (rhf)
                        }}
                        icon={<CheckboxUnchecked />}
                        checkedIcon={<CheckboxChecked />}
                        indeterminateIcon={<CheckboxIndeterminate />}
                        {...rest} // handle any other props
                    />
                )}
            />
        );
    }

    return (
        <MuiCheckbox
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />}
            indeterminateIcon={<CheckboxIndeterminate />}
            {...muiProps} // don't want name or formMethods props passed here
        />
    );
};

const Checkbox = styled(CheckboxContainer)`` as typeof CheckboxContainer;

export { Checkbox };
