import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ThemedVectorFrontTopHalf: FC<SvgProps> = ({ width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '298'}
        height={height ?? '255'}
        viewBox="0 0 298 255"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g style={{ mixBlendMode: 'multiply' }}>
            <path
                // eslint-disable-next-line max-len
                d="M148.941 0.500214L297.416 0.500214L43.93 254.036C23.1632 233.264 9.02142 206.802 3.29251 177.994C-2.4364 149.186 0.504852 119.326 11.7444 92.19C22.9839 65.0536 42.017 41.8591 66.4374 25.5387C90.8578 9.21825 119.569 0.504883 148.941 0.500214Z"
                fill="#EDB742"
            />
        </g>
    </svg>
);

const VectorFrontTopHalf = withTheme(ThemedVectorFrontTopHalf);

export { VectorFrontTopHalf };
