import { config } from 'config';

const extensionId = config.ExtensionLink?.split('/').pop();

/**
 * check to see if the extension is installed
 * chrome.runtime will only be populated for matches in externally_connectable in the manifest.json
 * (for local testing, ensure to include "http://localhost:3005/*", but also ensure to checkout before pushing)
 * @returns {Promise<boolean>}
 */
export const isChromeExtensionInstalled = async (): Promise<boolean> => {
    // ignore for local development
    if (config.Env === 'development') {
        return true;
    }

    if (typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined' && extensionId) {
        return new Promise((resolve, _reject) => {
            try {
                chrome.runtime.sendMessage?.(extensionId, { messageType: 'checkInstallation' }, (response) => {
                    if (response) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } catch (err) {
                resolve(false);
            }
        });
    }
    return false;
};
