import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { EmailAccountProviderEnum } from 'codegen/graphql';
import { config } from 'config';
import { signOutIntercom } from 'external/intercom';
import { logger } from 'logger';
import { Box } from 'shared/components/containers';
import { Button, Title } from 'shared/components/presentational';
import { Modal } from 'shared/components/presentational/modal';
import { useArchivedUserCheck, useSession } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ArchiveUserNoticeProps {}

const ArchiveUserNotice: FC<ArchiveUserNoticeProps> = (props) => {
    const { ...rest } = props;
    const restApiClient = createRestApiClient();
    const { session, loaded } = useSession();
    const { isUserArchived } = useArchivedUserCheck();
    const translate = useTranslations('shared.user-archived-modal');

    const onClose = async () => {
        try {
            await restApiClient.logout();
        } catch (error: any) {
            logger.error(`Error logging out: ${error}`);
        } finally {
            const redirectUrl =
                session?.user.loginEmailProvider === EmailAccountProviderEnum.Outlook
                    ? // eslint-disable-next-line max-len
                      `https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id=${config.OutlookOAuthAppConfig.clientId}&post_logout_redirect_uri=${config.PublicPath}`
                    : '/';
            window.location.href = redirectUrl;
            signOutIntercom();
        }
    };

    if (!loaded || !isUserArchived) {
        return null;
    }

    return (
        <Modal
            css={css`
                backdrop-filter: blur(${spacing.space4px});
            `}
            {...rest}
            open={isUserArchived}
            onClose={() => {}}
        >
            <Box
                css={css`
                    position: relative;
                    padding: ${spacing.space32px};
                `}
            >
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title')}
                </Title>

                <p>{translate('description')}</p>

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space32px};
                    `}
                >
                    <Button color="error" variant="contained" onClick={onClose}>
                        {translate('confirm-button')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ArchiveUserNotice };
