import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const MenuTitle: FC<unknown> = (props) => {
    const { children } = props;

    return (
        <Box
            css={css`
                font-family: ${fontFamilies.inter};
                font-size: ${fontSizes.f12};
                font-weight: ${fontWeights.medium};
                color: ${colors.grays.tundora};
                margin-bottom: ${spacing.space4px};
                margin-top: ${spacing.space32px};
            `}
        >
            {children}
        </Box>
    );
};

export { MenuTitle };
