import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import { css, useTheme } from '@mui/material';
import { getFollowUpSendTime } from 'hireflow-shared/lib/time';
import { Timezone } from 'hireflow-shared/types/timezone';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';

import {
    RecipientStatusEnum,
    SequenceStageSendTimeOfDayEnum,
    SequenceStageSendTypeOfDayEnum,
    SignatureOptionsEnum,
    UpdateRecipientCustomStagesMutation,
} from 'codegen/graphql';
import { RemovePersonalization } from 'prospects';
import { EmailToAndFrom } from 'prospects/email-to-and-from';
import { DraftInQueueSequenceStages } from 'prospects/types/form-types';
import { Stage } from 'sequences/types';
import { getUnknownVariablesError, validVariables } from 'sequences/utils/unknown-variables';
import { Box } from 'shared/components/containers';
import { CompactEmailEditor, EmailContentPreview } from 'shared/components/editor';
import { Button, Chip, NoSsr } from 'shared/components/presentational';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { UPDATE_RECIPIENT_CUSTOM_STAGES } from 'shared/graphql/recipient-custom-stages';
import { GET_RECIPIENTS } from 'shared/graphql/recipients';
import { useProspectPanel, useSession, useSnackbarAlert } from 'shared/hooks';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { dateFormatter } from 'shared/services';
import { customStageFromPersonalization } from 'shared/services/custom-stage-utils';
import { colors, fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface MessagePreviewPersonalizeProps {
    anchor: HTMLElement;
    recipient: RecipientConversationsData;
    stage: Stage;
}

const MessagePreviewPersonalize: FC<MessagePreviewPersonalizeProps> = (props) => {
    const { anchor, recipient, stage } = props;

    const { session } = useSession();
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs.conversations-tab');
    const translateEmail = useTranslations('settings.email-settings.queue-draft-folder-response');
    const { showSnackbarAlert } = useSnackbarAlert();

    const { sendFromAccountsData, contacts, context } = useProspectPanel();

    const [personalize, setPersonalize] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const [signatureHtml, setSignatureHtml] = useState<string>();
    const [time, setTime] = useState<number>();

    const [draftInQueueMessages, setDraftInQueueMessages, loaded] = useLocalStorage<
        DraftInQueueSequenceStages | undefined
    >('personalizedInQueueMessages', undefined);

    const { useSignature, signature } = recipient.sequence.stages.find((f) => f.stageIndex === stage.stageIndex) ?? {};

    useEffect(() => {
        if (useSignature && signature) {
            const sendFromAccount = sendFromAccountsData?.find((account) => account.id === stage.sendFromAccountId);
            switch (signature) {
                case SignatureOptionsEnum.HireflowSettings: {
                    setSignatureHtml(`<br>${sendFromAccount?.email_account.user.hireflowSignature}` ?? '');
                    break;
                }
                case SignatureOptionsEnum.ImportFromGmail: {
                    setSignatureHtml(`<br>${sendFromAccount?.email_account.signature}` ?? '');
                    break;
                }
                default: {
                    setSignatureHtml('');
                    break;
                }
            }
        }
    }, [stage, recipient, session, sendFromAccountsData, signature, useSignature]);

    useEffect(() => {
        if (
            (recipient.status === RecipientStatusEnum.NotStarted ||
                recipient.status === RecipientStatusEnum.InProgress) &&
            session &&
            session.user &&
            recipient.nextMessageScheduledAt
        ) {
            let timeOfEmail = recipient.nextMessageScheduledAt!;

            for (const [index, sequenceStage] of recipient.sequence.stages.entries()) {
                if (index <= stage.stageIndex! && index > recipient.lastStageSent) {
                    const stageBetween = recipient.customStages.find((f) => f.stageIndex === index) ?? sequenceStage;

                    timeOfEmail = getFollowUpSendTime(
                        timeOfEmail,
                        stageBetween.numberOfDays ?? 1,
                        session.user.primaryTimezone as Timezone,
                        session.user.sendEmailsHourStart,
                        session.user.sendEmailsHourEnd,
                        (stageBetween.sendTimeOfDay as SequenceStageSendTimeOfDayEnum) ??
                            SequenceStageSendTimeOfDayEnum.AutoOptimizeTime,
                        (stageBetween.sendTypeOfDays as SequenceStageSendTypeOfDayEnum) ??
                            SequenceStageSendTypeOfDayEnum.WeekDays
                    );
                }
            }

            setTime(timeOfEmail);
        }
    }, [stage, recipient, session]);

    const toEmail = contacts?.find((f) => recipient.contacts.map((c) => c.contactId).includes(f.id))?.standardizedValue;
    const fromEmail = sendFromAccountsData?.find((f) => f.id === stage.sendFromAccountId)?.email;

    const togglePersonalize = () => {
        setPersonalize(!personalize);
    };

    const getErrorString = (value: string) => {
        const errorMessage = getUnknownVariablesError(value);
        if (errorMessage) {
            return errorMessage.count
                ? translate('unknown-variables-alert-with-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                      count: errorMessage.count,
                  })
                : translate('unknown-variables-alert-without-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                  });
        }
        return '';
    };

    const validationSchema = Yup.object().shape({
        stages: Yup.array().of(
            Yup.object().shape({
                subject: Yup.string()
                    .required(translateEmail('error-subject'))
                    .test(
                        'unknown-variables',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
                content: Yup.string()
                    .required(translateEmail('error-email-content'))
                    .test(
                        'unknown-variables',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
            })
        ),
    });

    const formMethods = useForm<{ stages: Partial<Stage[]> }>({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });

    const { formState } = formMethods;
    const { isDirty } = formMethods.getFieldState('stages', formState);
    const messageStage = useWatch({ control: formMethods.control, name: 'stages' });

    const [savePersonalizedStage] = useMutation<UpdateRecipientCustomStagesMutation>(UPDATE_RECIPIENT_CUSTOM_STAGES, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    useEffect(() => {
        const personalizedStage = draftInQueueMessages?.messages.find(
            (f) => f.sequenceId === recipient.sequence.id && f.stageIndex === stage.stageIndex
        );
        if (
            personalize &&
            messageStage &&
            messageStage.length === 1 &&
            draftInQueueMessages &&
            draftInQueueMessages.userId === session?.user.id &&
            draftInQueueMessages.profileUrl === context?.url &&
            personalizedStage
        ) {
            formMethods.setValue('stages', [personalizedStage.stage]);
        } else {
            formMethods.setValue('stages', [stage]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage, formMethods, draftInQueueMessages]);

    useEffect(() => {
        if (context?.url && session?.user.id && messageStage && messageStage[0] && loaded && personalize) {
            setDraftInQueueMessages((prevDraftInQueueMessages) => {
                let updatedInQueueMessages = prevDraftInQueueMessages;
                if (
                    !updatedInQueueMessages ||
                    updatedInQueueMessages.userId !== session.user.id ||
                    updatedInQueueMessages.profileUrl !== context.url
                ) {
                    updatedInQueueMessages = {
                        profileUrl: context.url,
                        userId: session.user.id,
                        messages: [
                            {
                                sequenceId: recipient.sequence.id,
                                stageIndex: stage.stageIndex,
                                stage: messageStage[0]!,
                            },
                        ],
                    };
                } else {
                    const index = updatedInQueueMessages.messages.findIndex(
                        (f) => f.sequenceId === recipient.sequence.id && f.stageIndex === stage.stageIndex
                    );
                    if (index > -1) {
                        updatedInQueueMessages.messages[index].stage = messageStage[0]!;
                    } else {
                        updatedInQueueMessages.messages.push({
                            sequenceId: recipient.sequence.id,
                            stageIndex: stage.stageIndex,
                            stage: messageStage[0]!,
                        });
                    }
                }
                return updatedInQueueMessages;
            });
        } else {
            // remove from the local storage
            setDraftInQueueMessages((prevDraftInQueueMessages) => {
                const updatedInQueueMessages = prevDraftInQueueMessages;
                if (updatedInQueueMessages) {
                    const index = updatedInQueueMessages.messages.findIndex(
                        (f) => f.sequenceId === recipient.sequence.id && f.stageIndex === stage.stageIndex
                    );
                    if (index > -1) {
                        updatedInQueueMessages.messages.splice(index, 1);
                        if (updatedInQueueMessages.messages.length === 0) {
                            return undefined;
                        }
                    }
                }
                return updatedInQueueMessages;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalize, session, messageStage, context, recipient, loaded]);

    const handleRemovePersonalization = () => {
        setPersonalize(false);
        formMethods.reset({ stages: [stage] });
        setShowWarning(false);
    };

    const handleCancelPersonalize = () => {
        if (personalize && isDirty) {
            setShowWarning(true);
        } else {
            setPersonalize(!personalize);
        }
    };

    const handleSavePersonalize = async () => {
        const result = await formMethods.trigger();
        if (!result) {
            return;
        }

        const newStage = formMethods.getValues('stages');
        if (isDirty && newStage.length > 0 && newStage[0]) {
            const personalizedStage = customStageFromPersonalization(recipient.id, newStage[0], {
                ...stage,
                useSignature: useSignature ?? false,
            });
            await savePersonalizedStage({
                variables: {
                    objects: [personalizedStage],
                },
            });
            setPersonalize(false);
            showSnackbarAlert({
                severity: 'success',
                message: translate('personalization-saved', {
                    index: personalizedStage.stageIndex ? personalizedStage.stageIndex + 1 : 1,
                    sequenceName: recipient.sequence.title,
                    recipientName:
                        recipient.prospect.profile?.firstNameCustomized ?? recipient.prospect.profile?.firstName,
                }),
            });
        } else {
            setPersonalize(false);
        }
    };

    const editableContent = (
        <Box>
            <CompactEmailEditor
                key={`stages.${0}.compactEmail`}
                formMethods={formMethods}
                index={0}
                showSubject
                singleStageEdit
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'bullet' }, { list: 'ordered' }],
                    ],
                    clipboard: {
                        matchVisual: false,
                    },
                    magicUrl: true,
                }}
                signatureHtml={signatureHtml}
            />
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                `}
            >
                <Button
                    css={css`
                        width: 29%;
                    `}
                    variant="outlined"
                    onClick={handleCancelPersonalize}
                >
                    {translate('cancel-button-label')}
                </Button>
                <Button
                    css={css`
                        width: 69%;
                    `}
                    variant="contained"
                    onClick={handleSavePersonalize}
                >
                    {translate('save-button-label')}
                </Button>
            </Box>
        </Box>
    );

    const staticContent = (
        <Box
            css={css`
                padding: 16px;
                background-color: ${colors.yellows.picasso20};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f12};
                        color: ${colors.grays.tundora};
                        padding: 6px 0px;
                    `}
                >
                    {translate('email-preview-label')}
                </Box>
                <Box>
                    <Button
                        hidden={recipient.sequence.userId !== session!.user.id}
                        css={css`
                            padding: 0;
                            font-size: ${fontSizes.f14};
                        `}
                        onClick={togglePersonalize}
                    >
                        {translate('personalize-button-label')}
                    </Button>
                </Box>
            </Box>
            <EmailContentPreview content={stage.content} />
            {signatureHtml ? (
                <Box
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: signatureHtml }}
                />
            ) : null}
        </Box>
    );

    if (!loaded) {
        return null;
    }

    return (
        <Box
            key={`sequence-${stage.stageIndex}-stage`}
            css={css`
                margin-top: 24px;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    white-space: nowrap;
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                        font-weight: ${fontWeights.bold};
                        padding-top: 4px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    `}
                >
                    {stage.personalized
                        ? translate('stage-personalized-title-label', {
                              index: stage.stageIndex ? stage.stageIndex + 1 : 1,
                              sequenceName: recipient.sequence.title,
                          })
                        : translate('stage-title-label', {
                              index: stage.stageIndex ? stage.stageIndex + 1 : 1,
                              sequenceName: recipient.sequence.title,
                          })}
                </Box>
                <Box>
                    <Chip
                        css={css`
                            padding: 2px;
                            font-size: ${fontSizes.f12};
                            background-color: ${colors.yellows.picasso};
                        `}
                        size="small"
                        label={translate('in-queue-status')}
                    />

                    {time && time > 0 && (
                        <Box
                            component="span"
                            css={css`
                                margin-left: 4px;
                                color: ${theme.palette.grey[600]};
                                font-size: ${fontSizes.f12};
                                text-transform: uppercase;
                            `}
                        >
                            <NoSsr>{dateFormatter(time)}</NoSsr>
                        </Box>
                    )}
                </Box>
            </Box>
            <EmailToAndFrom to={toEmail} from={fromEmail} />
            <Box
                css={css`
                    border: ${personalize ? `none` : `1px solid ${colors.yellows.picasso}`};
                    border-radius: 4px;
                    margin-top: 12px;
                `}
            >
                {personalize ? editableContent : staticContent}
            </Box>
            {showWarning && (
                <RemovePersonalization
                    singleMessageEdit
                    anchor={anchor}
                    onCancel={() => setShowWarning(false)}
                    onRemove={handleRemovePersonalization}
                    open={showWarning}
                />
            )}
        </Box>
    );
};

export { MessagePreviewPersonalize };
