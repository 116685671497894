import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { UserTeamQuery } from 'codegen/graphql';
import { Bold } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { UserUp1 } from 'shared/components/svgs';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';

interface AddTabReviewerViewProps {}

const AddTabReviewerView: FC<AddTabReviewerViewProps> = (_props) => {
    const translate = useTranslations('prospects.tabs.add-tab.reviewer-view');
    const theme = useTheme();

    const { data: userTeamData } = useQuery<UserTeamQuery>(GET_USER_TEAM);

    const description = translate.rich('description', {
        owner: userTeamData?.teams?.[0]?.owner?.[0]?.fullName ?? '',
        bold: Bold,
    });

    return (
        <Box
            css={css`
                min-height: 60vh;

                margin: 24px;
                padding: 64px 24px;

                background-color: ${colors.grays.alabaster};
                border-radius: 8px;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 12px;

                text-align: center;
            `}
        >
            <Box
                css={css`
                    margin-bottom: 12px;
                `}
            >
                <UserUp1 width={64} height={64} stroke={theme.palette.common.black} strokeWidth="2" />
            </Box>
            <Title
                type="h4"
                css={css`
                    font-size: 24px;
                `}
            >
                {translate('title')}
            </Title>
            <Box
                css={css`
                    font-family: Inter, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                `}
            >
                {description}
            </Box>
        </Box>
    );
};

export { AddTabReviewerView };
