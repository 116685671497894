import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { SelectChangeEvent } from '@mui/material';
import { useTranslations } from 'next-intl';

import {
    InviteStatusEnum,
    LicenseTypesEnum,
    PlanTypesEnum,
    TeamRolesEnum,
    UpdateTeamInviteByIdMutation,
} from 'codegen/graphql';
import { InviteesListRow } from 'settings/components/presentational/team/invitees-list-row';
import { List } from 'shared/components/presentational';
import { GET_TEAM_INVITES, roleLabelsType, TeamInvitesData, UPDATE_TEAM_INVITE_BY_ID } from 'shared/graphql/teams';
import { useSnackbarAlert } from 'shared/hooks';
import { FC } from 'shared/types';

interface InviteesListProps {
    seatsUsed: number;
    currentUserRole: TeamRolesEnum;
    invitedUsers: TeamInvitesData;
    roleOptions: Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;
    planType: PlanTypesEnum;
    seatsAvailable: number;
}

export const InviteesList: FC<InviteesListProps> = ({
    currentUserRole,
    invitedUsers,
    seatsUsed,
    roleOptions,
    planType,
    seatsAvailable,
}) => {
    const translate = useTranslations('settings.team-settings');

    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateTeamInvite] = useMutation<UpdateTeamInviteByIdMutation>(UPDATE_TEAM_INVITE_BY_ID, {
        refetchQueries: [getOperationName(GET_TEAM_INVITES) as string],
    });

    const handleRoleChanged = (id: string) => async (event: SelectChangeEvent<any>) => {
        const role = event.target.value === TeamRolesEnum.Admin ? TeamRolesEnum.Admin : TeamRolesEnum.User;
        const licenseType =
            event.target.value === LicenseTypesEnum.Reviewer ? LicenseTypesEnum.Reviewer : LicenseTypesEnum.Full;

        await updateTeamInvite({ variables: { id, set: { role, licenseType } } });

        const label = roleOptions.find((r) => r.value === event.target.value)?.label as roleLabelsType;

        showSnackbarAlert({
            severity: 'success',
            message: translate('role-updated', {
                role: translate(label),
            }),
        });
    };

    const membersList = invitedUsers
        .filter((f) => f.status !== InviteStatusEnum.Accepted)
        .map((invite, i) => (
            <InviteesListRow
                key={i}
                currentUserRole={currentUserRole}
                seatsUsed={seatsUsed}
                invite={invite}
                roleOptions={roleOptions}
                onRoleChange={handleRoleChanged}
                planType={planType}
                currentUserLicenseType={invite.licenseType}
                seatsAvailable={seatsAvailable}
            />
        ));

    return <List type="unordered">{membersList}</List>;
};
