import { gql } from '@apollo/client';

import { RecipientActivitiesQuery } from 'codegen/graphql';

export type RecipientActivity = RecipientActivitiesQuery['recipient_activities'][number];

export const RECIPIENT_ACTIVITY = gql`
    fragment RecipientActivity on recipient_activities {
        id
        recipientId
        type
        lastStageSent
        oldValue
        newValue
        createdAt
        user {
            id
            fullName
        }
        recipient {
            sequence {
                id
                title
                userId
                collaborators {
                    id
                    userId
                }
            }
        }
    }
`;

export const GET_RECIPIENT_ACTIVITIES = gql`
    query RecipientActivities(
        $where: recipient_activities_bool_exp = {}
        $limit: Int = 2000
        $offset: Int = 0
        $order_by: [recipient_activities_order_by!] = { createdAt: desc }
    ) {
        recipient_activities(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
            ...RecipientActivity
        }
    }
    ${RECIPIENT_ACTIVITY}
`;
