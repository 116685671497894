import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedHireflowLogo: FC<SvgProps> = ({ width, height, ...rest }) => (
    /* eslint-disable max-len */
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? '69'}
        height={height ?? '68'}
        viewBox="0 0 69 68"
        fill="none"
        {...rest}
    >
        <path
            d="M1.45468 16.4438C1.84049 12.0989 3.96227 8.08295 7.35326 5.27942C10.7442 2.47589 15.1267 1.11443 19.5365 1.49457L36.1635 2.92786L34.7089 19.3104C34.3231 23.6554 32.2013 27.6713 28.8103 30.4749C25.4193 33.2784 21.0369 34.6398 16.6271 34.2597C12.2173 33.8796 8.14141 31.789 5.29603 28.4479C2.45065 25.1068 1.06888 20.7888 1.45468 16.4438Z"
            fill="#1D5661"
        />
        <path
            d="M34.7087 19.3117L36.1634 2.92915L5.29654 28.4486C7.41846 30.9399 10.2425 32.7554 13.4115 33.6654C16.5806 34.5755 19.9523 34.5392 23.1005 33.5613C26.2486 32.5833 29.0318 30.7075 31.098 28.1711C33.1642 25.6348 34.4207 22.5517 34.7087 19.3117Z"
            fill="#0F4A54"
        />
        <path
            d="M1.48251 47.9221L2.9372 31.5396L19.5643 32.9728C23.9741 33.353 28.05 35.4436 30.8953 38.7847C33.7407 42.1258 35.1225 46.4438 34.7367 50.7887C34.3509 55.1336 32.2291 59.1496 28.8381 61.9531C25.4471 64.7567 21.0647 66.1181 16.6549 65.738C12.2451 65.3578 8.16924 63.2673 5.32386 59.9261C2.47848 56.585 1.09671 52.267 1.48251 47.9221Z"
            fill="#4EC7B8"
        />
        <path
            d="M1.48233 47.9224L2.93701 31.5398L28.8373 61.9527C26.3086 64.0431 23.2075 65.3479 19.9262 65.7019C16.6449 66.0559 13.3308 65.4433 10.4028 63.9415C7.47488 62.4398 5.06461 60.1163 3.47678 57.265C1.88896 54.4136 1.19489 51.1624 1.48233 47.9224Z"
            fill="#46BBAD"
        />
        <path
            d="M48.576 65.7374L31.9489 64.3041L33.4036 47.9215C33.7894 43.5766 35.9112 39.5606 39.3022 36.7571C42.6932 33.9535 47.0756 32.5921 51.4854 32.9722C55.8952 33.3524 59.9711 35.4429 62.8165 38.7841C65.6618 42.1252 67.0436 46.4432 66.6578 50.7881C66.272 55.133 64.1502 59.149 60.7592 61.9525C57.3682 64.756 52.9858 66.1175 48.576 65.7374Z"
            fill="#55BC7C"
        />
        <path
            d="M48.5755 65.7374L31.9484 64.3041L62.8152 38.7846C64.9369 41.2762 66.261 44.3317 66.6203 47.5647C66.9796 50.7977 66.3579 54.0631 64.8337 56.948C63.3096 59.8329 60.9515 62.2078 58.0575 63.7722C55.1636 65.3367 51.8639 66.0206 48.5755 65.7374Z"
            fill="#339E5C"
        />
        <path
            d="M51.5109 1.49359C55.9207 1.87372 59.9966 3.9643 62.8419 7.30542C65.6873 10.6465 67.0691 14.9645 66.6833 19.3095L65.2286 35.692L48.6015 34.2587C44.1917 33.8786 40.1158 31.788 37.2705 28.4469C34.4251 25.1058 33.0433 20.7878 33.4291 16.4429C33.8149 12.0979 35.9367 8.08198 39.3277 5.27845C42.7187 2.47491 47.1011 1.11346 51.5109 1.49359Z"
            fill="#FFC657"
        />
        <path
            d="M48.6001 34.2592L65.2272 35.6925L39.3269 5.27961C36.7983 7.37032 34.9558 10.1528 34.0321 13.2753C33.1085 16.3977 33.1453 19.7199 34.1379 22.8217C35.1305 25.9236 37.0342 28.6658 39.6084 30.7016C42.1827 32.7375 45.3118 33.9755 48.6001 34.2592Z"
            fill="#F7B552"
        />
        <path
            d="M36.1634 2.92915L34.7087 19.3117C34.4207 22.5517 33.1642 25.6348 31.098 28.1711L5.29654 28.4486L36.1634 2.92915Z"
            fill="#0F4A54"
        />
    </svg>
    /* eslint-enable max-len */
);

const HireflowLogo = withTheme(ThemedHireflowLogo);

export { HireflowLogo };
