import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { Button, Hyperlink, Modal, ModalCloseButton, TextField, Title } from 'shared/components/presentational';
import { ArrowNarrowLeft, Circle, Plus } from 'shared/components/svgs';
import { Contacts } from 'shared/graphql/contacts';
import { isEmailValid } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings/';
import { FC } from 'shared/types';

interface ResendSequenceMultipleEmailsProps {
    handleSendSequence: (email: string) => void;
    onClose: () => void;
    contacts: Contacts[];
    open: boolean;
}

const ResendSequenceMultipleEmails: FC<ResendSequenceMultipleEmailsProps> = (props) => {
    const { contacts, onClose, open, handleSendSequence } = props;
    const translate = useTranslations('sequence.recipient-list-table.resend-sequence-new-email-modal');
    const theme = useTheme();

    const [newEmail, setNewEmail] = useState<string>('');
    const [isValid, setIsValid] = useState(true);
    const [selectedEmail, setSelectedEmail] = useState<Contacts>();
    const [addManual, setAddManual] = useState(false);

    useEffect(() => {
        if (contacts.length > 0) {
            setSelectedEmail(contacts.find((f) => f.primary && !f.invalid) ?? contacts.filter((f) => !f.invalid)[0]);
        }
    }, [contacts]);

    const reset = () => {
        setNewEmail('');
        setIsValid(true);
        setAddManual(false);
    };

    const handleChange = (event: any) => {
        setNewEmail(event.target.value);
        if (!isValid) {
            setIsValid(true);
        }
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleClickSendSequence = async () => {
        if (addManual && !isEmailValid(newEmail)) {
            setIsValid(false);
            return;
        }

        handleSendSequence(addManual ? newEmail : selectedEmail ? selectedEmail.value : '');
        onClose();
        reset();
    };

    const handleBackToEmailOnFile = () => {
        setNewEmail('');
        setAddManual(false);
    };

    const toggleAddManual = () => setAddManual(!addManual);

    const handleSelectEmailChange = (event: any) => {
        setSelectedEmail(contacts.filter((c) => c.id === event.target.value)[0]);
    };

    const menuOpts = contacts?.map((o) => (
        <MenuItem key={o.id} value={o.id} disabled={o.invalid}>
            {o.value === selectedEmail?.id && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            {o.invalid ? translate('bounced-email', { email: o.value }) : o.value}
        </MenuItem>
    ));

    const selectDropDown = addManual ? null : menuOpts ? (
        <Select
            disableUnderline
            label={translate('multiple-emails.select-field-label')}
            name="email"
            value={selectedEmail?.id ?? ''}
            width="100%"
            onChange={handleSelectEmailChange}
            variant="filled"
        >
            {menuOpts}
            <Button variant="text" onClick={toggleAddManual} startIcon={<Plus />}>
                {translate('multiple-emails.manually-add-email-label')}
            </Button>
        </Select>
    ) : null;

    return (
        <Modal onClose={onClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('multiple-emails.header')}
                </Title>
                <Box>
                    {selectDropDown}
                    <Box hidden={!addManual}>
                        <Box
                            css={css`
                                padding-top: ${spacing.space8px};
                                padding-bottom: ${spacing.space8px};
                            `}
                        >
                            <ArrowNarrowLeft
                                css={css`
                                    margin-right: ${spacing.space12px};
                                `}
                                stroke={theme.palette.primary.main}
                                onClick={handleBackToEmailOnFile}
                            />
                            <Hyperlink href="#" onClick={handleBackToEmailOnFile}>
                                {translate('multiple-emails.back-to-emails-on-file-label')}
                            </Hyperlink>
                        </Box>
                        <TextField
                            css={css`
                                width: 100%;
                            `}
                            error={!isValid}
                            helperText={isValid ? null : translate('manual-email.invalid-email')}
                            inputRef={(input) => input && input.focus()}
                            value={newEmail}
                            onChange={handleChange}
                            label={translate('manual-email.select-email-label')}
                        />
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        padding-top: ${spacing.space32px};
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" onClick={handleClickSendSequence}>
                        {translate('send-sequence-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ResendSequenceMultipleEmails };
