import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { SourcerCollaboratorTypeEnum, SourcerMembersQuery, SourcerMemberStatusEnum } from 'codegen/graphql';
import { ProspectPanel } from 'prospects';
import { PanelContext } from 'prospects/prospect-panel-provider';
import { StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Checkbox, EllipsisText, IconButton, NoSsr } from 'shared/components/presentational';
import { DotsVertical } from 'shared/components/svgs';
import { TableCell, TableRow } from 'shared/components/table';
import { dateFormatter } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { SourcerMembersListSubmenu } from 'sourcing/components/tables';

type SourcerMemberData = SourcerMembersQuery['sourcer_members'][number];

interface SourcerMembersListRowProps {
    creditsPerApproval: number;
    member: SourcerMemberData;
    status: SourcerMemberStatusEnum;
    isSelected: boolean;
    onToggleSelect: (checked: boolean, memberId: string) => void;
    sourcer: {
        id: string;
        userId: string;
        project: { id: string };
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    };
    readOnly?: boolean;
}
const SourcerMembersListRow: FC<SourcerMembersListRowProps> = ({
    creditsPerApproval,
    member,
    status,
    isSelected,
    onToggleSelect,
    sourcer,
    readOnly = false,
}) => {
    const translate = useTranslations('sourcing.members-list-table');
    const theme = useTheme();

    const primaryEmail = member?.prospect?.contacts![0]?.value;
    const latestRecipient =
        [...(member?.prospect?.recipients ?? [])].sort((a, b) => b.createdAt - a.createdAt)[0] || {};

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const [showPanel, setShowPanel] = useState<boolean>(false);

    const prospectContext: PanelContext = {
        flow: 'recipient',
        prospectId: member.prospect?.id!,
        url: member.prospect?.urls?.[0]?.url ?? '',
    };

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onToggleSelect(!isSelected, member.id);
    };

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (!event.defaultPrevented && status === SourcerMemberStatusEnum.Accepted) {
            setShowPanel(true);
        }
    };

    const onPanelClose = () => {
        setShowPanel(false);
    };

    const getEmail = () => {
        if (status !== SourcerMemberStatusEnum.Accepted) return null;
        return (
            <Box
                component="span"
                css={css`
                    font-size: ${fontSizes.f12};
                    color: ${theme.palette.grey[200]};
                `}
            >
                {primaryEmail || translate('empty-placeholder')}
            </Box>
        );
    };

    return (
        <>
            <TableRow
                key={member.id}
                css={css`
                    ${submenuAnchor &&
                    // simulate hover when submenu is opened
                    `
                    background-color: ${theme.palette.grey[300]};
                    .MuiTableCell-root {
                        background-color: ${theme.palette.grey[300]};
                    }
                    path {
                        stroke: ${theme.palette.primary.light};
                    }
                    `}
                `}
                selected={isSelected}
                onClick={handleRowClick}
            >
                <TableCell component="th" scope="row">
                    <Box
                        css={css`
                            display: flex;
                        `}
                    >
                        {!readOnly && (
                            <Checkbox
                                css={css`
                                    margin-right: 8px;
                                `}
                                checked={isSelected}
                                onClick={handleSelectChange}
                            />
                        )}
                        <Box
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: 100%;
                            `}
                        >
                            <Box>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                    title={member.fullName}
                                >
                                    {member.fullName}
                                </Box>
                                {getEmail()}
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
                {status === SourcerMemberStatusEnum.Accepted && (
                    <TableCell align="center">
                        <StatusChip
                            status={latestRecipient?.status}
                            lastStageSent={latestRecipient?.lastStageSent}
                            sentiment={latestRecipient?.recipientResponses?.[0]?.sentiment}
                        />
                        <EllipsisText
                            css={css`
                                font-size: ${fontSizes.f12};
                                color: ${theme.palette.primary.main};
                                text-align: left;
                            `}
                        >
                            {latestRecipient?.sequence?.title || translate('empty-placeholder')}
                        </EllipsisText>
                    </TableCell>
                )}

                <TableCell>
                    <Box
                        css={css`
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) min-content;
                            column-gap: ${spacing.space16px};
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <Box>
                            <Box
                                css={css`
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                            >
                                {member?.experience.length > 0
                                    ? member?.experience[0]?.companyName
                                    : translate('empty-placeholder')}
                            </Box>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                            >
                                {member?.experience.length > 0 ? member?.experience[0]?.title : ''}
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell>{member.location}</TableCell>
                <TableCell>
                    <Box
                        css={css`
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) min-content;
                            column-gap: ${spacing.space16px};
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <Box>
                            <NoSsr>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                >
                                    {status === SourcerMemberStatusEnum.Accepted
                                        ? dateFormatter(member.addedAt!)
                                        : status === SourcerMemberStatusEnum.Rejected
                                        ? dateFormatter(member.rejectedAt!)
                                        : status === SourcerMemberStatusEnum.Snoozed
                                        ? dateFormatter(member.snoozedAt!)
                                        : translate('empty-placeholder')}
                                </Box>
                            </NoSsr>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                            >
                                {translate('by')} {member.reviewer?.fullName}
                            </Box>
                        </Box>
                        <IconButton
                            css={css`
                                margin-left: auto;
                                ${submenuAnchor &&
                                `
                                padding: 0;
                                margin-right: 8px;
                                `}

                                &:hover path {
                                    stroke: ${theme.palette.primary.light};
                                }
                            `}
                            onClick={handleSubmenuButtonClick}
                            disableRipple
                        >
                            <DotsVertical />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <SourcerMembersListSubmenu
                creditsPerApproval={creditsPerApproval}
                member={member}
                anchor={submenuAnchor}
                setAnchor={setSubmenuAnchor}
                prospectPanelContext={prospectContext}
                sourcer={sourcer}
            />
            <ProspectPanel isPanelOpen={showPanel} hideProspectPanel={onPanelClose} prospectContext={prospectContext} />
        </>
    );
};

export { SourcerMembersListRow };
