import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { LinkedinLogo, MagnifyingGlass, Plus, User01 } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

const ActionPanel: FC<unknown> = () => {
    const theme = useTheme();
    const actionPanelButtonStyles = css`
        padding: 20px;
        background: ${theme.palette.common.white};
        border: 1px solid ${theme.palette.grey[500]};
        margin-bottom: ${spacing.space16px};
        &:hover {
            background: ${theme.palette.primary.contrastText};
        }
        &:last-child {
            margin-bottom: 0;
        }
    `;
    return (
        <Box
            css={css`
                position: sticky;
                top: 0;
                display: flex;
                flex-flow: column nowrap;
                padding: ${spacing.space32px} ${spacing.space32px} ${spacing.space32px} 0;
            `}
        >
            <IconButton css={actionPanelButtonStyles}>
                <MagnifyingGlass width={18} height={18} stroke={theme.palette.primary.main} strokeWidth="3" />
            </IconButton>
            <IconButton css={actionPanelButtonStyles}>
                <User01 width={18} height={18} stroke={theme.palette.primary.main} strokeWidth="3" />
            </IconButton>
            <IconButton css={actionPanelButtonStyles}>
                <LinkedinLogo width={18} height={18} strokeWidth="3" />
            </IconButton>
            <IconButton css={actionPanelButtonStyles}>
                <Plus width={18} height={18} stroke={theme.palette.primary.main} strokeWidth="3" />
            </IconButton>
        </Box>
    );
};

export { ActionPanel };
