import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedXClose: FC<SvgProps> = ({ stroke, theme, width, height, fill, ...rest }) => (
    <svg
        {...rest}
        width={width || '24'}
        height={height || '24'}
        viewBox={`0 0 ${width || '24'} ${height || '24'}`}
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18 6L6 18M6 6L18 18"
            stroke={stroke ?? theme?.palette.secondary.dark}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const XClose = withTheme(ThemedXClose);

export { XClose };
