import styled from '@emotion/styled';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import {
    TextField as PresentationalTextField,
    TextFieldProps as PresentationalTextFieldProps,
} from 'shared/components/presentational';
import { FCProps } from 'shared/types';

interface TextFieldProps<Values extends FieldValues> extends PresentationalTextFieldProps {
    control: Control<Values>;
    name: Path<Values>;
}

const TextFieldContainer = <Values extends FieldValues>({
    control,
    className,
    name,
    label,
    disabled,
    ...rest
}: TextFieldProps<Values> & FCProps) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { onBlur, onChange, value, ref }, fieldState: { error } }) => (
            <PresentationalTextField
                className={className}
                inputRef={ref}
                name={name}
                label={label}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!error}
                disabled={disabled}
                helperText={error?.message}
                {...rest}
            />
        )}
    />
);

const TextField = styled(TextFieldContainer)`
    min-width: 354px;
` as typeof TextFieldContainer;

export { TextField };
