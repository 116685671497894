import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { InvoiceInfo } from 'hireflow-shared/types/stripe/invoice-info';
import { getDollarAmount } from 'hireflow-shared/utils/amount-utils';
import { ProductName, getProductInfoByProductId } from 'hireflow-shared/utils/stripe/stripe-products';
import { uniq } from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { config } from 'config';
import { Box } from 'shared/components/containers';
import { Hyperlink, List, NoSsr, Title } from 'shared/components/presentational';
import { capitalizeFirstLetter, createRestApiClient, stripeDateTimeFormatter } from 'shared/services';
import { fontFamilies, fontSizes, fontWeights } from 'shared/settings';
import { FC, UserTeam } from 'shared/types';

interface InvoicesProps {
    team: UserTeam;
}

const Invoices: FC<InvoicesProps> = (props) => {
    const { team } = props;

    const translate = useTranslations('settings.plans-and-billing-settings');
    const theme = useTheme();
    const restApiClient = createRestApiClient();

    const [invoices, setInvoices] = useState<InvoiceInfo[]>();

    const useProd = config.Env === 'production';

    useEffect(() => {
        const fetchInvoiceData = async (customerId: string) => {
            const invoiceData = await restApiClient.stripeInvoiceDetails({
                customerId,
            });
            setInvoices(invoiceData?.data?.invoices);
        };
        if (team?.customerId) {
            fetchInvoiceData(team.customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.customerId]);

    const getInvoiceDescription = (invoice: InvoiceInfo) => {
        const invoiceItems = uniq(invoice.items.map((item) => getDescription(item)));
        const description = invoiceItems.map((itemDescription, itemIndex) => (
            <p
                key={itemIndex}
                css={css`
                    width: 500px;
                    font-family: ${fontFamilies.inter};
                    font-style: normal;
                    font-weight: ${fontWeights.normal};
                    font-size: ${fontSizes.f16};
                    line-height: 19px;
                    color: #09101d;
                    margin-top: ${itemIndex === 0 ? '16px' : '8px'};
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                `}
            >
                {itemDescription}
            </p>
        ));
        return description;
    };

    const getDescription = (item: InvoiceInfo['items'][0]) => {
        const productName = getProductInfoByProductId(item.productId, useProd)?.name;
        if (productName) {
            switch (productName) {
                case ProductName.Enterprise: {
                    return translate('invoice-description.enterprise');
                }
                case ProductName.EnterpriseTrial: {
                    return translate('invoice-description.enterprise-trial');
                }
                case ProductName.Expired: {
                    return translate('invoice-description.expired');
                }
                case ProductName.FreeTrial: {
                    return translate('invoice-description.free-trial');
                }
                case ProductName.Solo: {
                    return translate('invoice-description.solo');
                }
                case ProductName.AISourcingOneCredit:
                case ProductName.AISourcingCredit: {
                    return translate('invoice-description.ai-sourcing-credits');
                }
                default: {
                    return item.description;
                }
            }
        }
        return item.description;
    };

    // if any invoice is void, then it should not be displayed
    const invoicesElement = invoices
        ? invoices
              .filter((invoice) => invoice.status !== 'void')
              .map((i: InvoiceInfo, index: number) => (
                  <li
                      key={index}
                      css={css`
                          display: flex;
                          justify-content: space-between;
                          border-bottom: 1px solid ${theme.palette.grey[100]};
                          padding: 24px 0;
                          &:last-child {
                              border: none;
                          }
                      `}
                  >
                      <div>
                          <NoSsr>
                              <p
                                  css={css`
                                      font-family: ${fontFamilies.inter};
                                      font-style: normal;
                                      font-weight: ${fontWeights.bold};
                                      font-size: ${fontSizes.f16};
                                      line-height: 19px;
                                      color: ${theme.palette.common.black};
                                      margin: 0;
                                  `}
                              >
                                  {stripeDateTimeFormatter(i.date)}
                              </p>
                          </NoSsr>
                          <Hyperlink newTab href={i.link}>
                              <a
                                  css={css`
                                      font-family: ${fontFamilies.inter};
                                      font-style: normal;
                                      font-weight: ${fontWeights.semiBold};
                                      font-size: ${fontSizes.f16};
                                      line-height: 19px;
                                      color: ${theme.palette.primary.main};
                                  `}
                              >
                                  {i.id}
                              </a>
                          </Hyperlink>
                          {getInvoiceDescription(i)}
                      </div>
                      <div
                          css={css`
                              text-align: right;
                          `}
                      >
                          <h4
                              css={css`
                                  font-family: ${fontFamilies.poppins};
                                  font-style: normal;
                                  font-weight: ${fontWeights.bold};
                                  font-size: ${fontSizes.f20};
                                  line-height: 30px;
                                  text-align: right;
                                  color: ${theme.palette.common.black};
                                  margin: 0;
                              `}
                          >
                              {translate('amount', { amount: getDollarAmount(i.amount) })}
                          </h4>
                          {i.card.last4.length > 0 && (
                              <p
                                  css={css`
                                      font-family: ${fontFamilies.inter};
                                      font-style: normal;
                                      font-weight: ${fontWeights.normal};
                                      font-size: ${fontSizes.f12};
                                      line-height: 15px;
                                      text-align: right;
                                      color: #09101d;
                                      margin: 0;
                                      margin-bottom: 13px;
                                  `}
                              >
                                  {translate('charged-to-card-ending-in', {
                                      endingNumber: i.card.last4,
                                      brand: capitalizeFirstLetter(i.card.brand),
                                  })}
                              </p>
                          )}
                          <Hyperlink newTab href={i.link}>
                              <a
                                  css={css`
                                      font-family: ${fontFamilies.inter};
                                      font-style: normal;
                                      font-weight: 600;
                                      font-size: ${fontSizes.f16};
                                      line-height: 19px;
                                      text-align: right;
                                      color: ${theme.palette.primary.main};
                                  `}
                              >
                                  {translate('view-invoice')}
                              </a>
                          </Hyperlink>
                      </div>
                  </li>
              ))
        : null;

    return (
        <Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Title type="h2">{translate('invoices')}</Title>
            </Box>

            <Box>
                <List type="unordered">{invoicesElement}</List>
            </Box>
        </Box>
    );
};

export { Invoices };
