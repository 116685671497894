import { css, useTheme } from '@mui/material';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Button, IconButton, Popover, TextField, Title } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { isEmailValid } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RecipientAddManualEmailProps {
    anchor?: HTMLElement;
    open: boolean;
    onClose: () => void;
    handleSaveNewEmail: (email: string) => void;
}

const RecipientAddManualEmail: FC<RecipientAddManualEmailProps> = (props) => {
    const { anchor, onClose, open, handleSaveNewEmail } = props;
    const theme = useTheme();
    const [newEmail, setNewEmail] = useState<string>('');
    const [isValid, setIsValid] = useState(true);
    const translate = useTranslations('prospects.tabs.add-tab');

    const reset = () => {
        setIsValid(true);
        setNewEmail('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmail(event.target.value);
        if (isEmailValid(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const handleCancel = () => {
        reset();
        onClose();
    };

    const handleSave = () => {
        if (isEmailValid(newEmail)) {
            handleSaveNewEmail(newEmail);
            onClose();
            reset();
        } else {
            setIsValid(false);
        }
    };

    return (
        <Popover
            anchorReference="anchorEl"
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    width: extensionPanelWidth,
                    height: '100%',
                },
            }}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    padding: 8px;
                `}
            >
                <IconButton onClick={onClose}>
                    <XClose stroke={theme.palette.primary.main} />
                </IconButton>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    min-height: calc(100% - 100px);
                `}
            >
                <Box
                    css={css`
                        width: 100%;
                        margin: ${spacing.none} ${spacing.space48px};
                    `}
                >
                    <Title
                        css={css`
                            font-size: ${fontSizes.f24};
                        `}
                        type="h3"
                    >
                        {translate('add-new-email-manual-label')}
                    </Title>
                    <TextField
                        fullWidth
                        error={!isValid}
                        helperText={isValid ? null : translate('invalid-email')}
                        value={newEmail}
                        onChange={handleChange}
                        label={translate('select-email-label')}
                        inputRef={(input) => input && input.focus()}
                    />
                    <Box
                        css={css`
                            display: flex;
                            justify-content: right;
                            margin-top: 20px;
                        `}
                    >
                        <Button color="secondary" variant="outlined" onClick={handleCancel}>
                            {translate('add-new-email-manual-cancel-label')}
                        </Button>
                        <Button
                            css={css`
                                margin-left: 10px;
                            `}
                            variant="contained"
                            onClick={handleSave}
                        >
                            {translate('add-new-email-manual-save-label')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export { RecipientAddManualEmail };
