import styled from '@emotion/styled';
import Link from 'next/link';

import { FC } from 'shared/types';

interface HyperlinkProps {
    className?: string;
    href: string;
    testId?: string;
    onClick?: React.MouseEventHandler;
    target?: string;
    newTab?: boolean;
}

const HyperLinkContainer: FC<HyperlinkProps> = (props) => {
    const { children, testId, href, onClick, target, className, newTab, ...rest } = props;
    const newTabProps = newTab ? { target: '_blank', rel: 'noopener' } : {};

    return (
        <Link data-test-id={testId} href={href}>
            <a
                onClick={onClick as React.MouseEventHandler}
                target={target}
                href={href}
                className={className}
                {...rest}
                {...newTabProps}
            >
                {children}
            </a>
        </Link>
    );
};

const Hyperlink = styled(HyperLinkContainer)<HyperlinkProps>`
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
        color: ${({ theme }) => theme.palette.primary.light};
    }
`;

export { Hyperlink };
