import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { Button, Hyperlink, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { OUT_OF_AI_SOURCING_CREDITS } from 'shared/constants';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoAISourcingCreditsModalProps {
    open: boolean;
    onClose: () => void;
}

const NoAISourcingCreditsModal: FC<NoAISourcingCreditsModalProps> = (props) => {
    const { open, onClose } = props;
    const translate = useTranslations('sourcing.no-ai-sourcing-credits-notice');

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    position: relative;
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={onClose} />

                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title')}
                </Title>

                <p>{translate.rich('description', { link: Link })}</p>

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space32px};
                    `}
                >
                    <Button variant="contained" onClick={onClose}>
                        {translate('confirm-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink href={OUT_OF_AI_SOURCING_CREDITS} newTab>
        <u>{children}</u>
    </Hyperlink>
);

export { NoAISourcingCreditsModal };
