import { LazyQueryExecFunction, useLazyQuery } from '@apollo/client';
import { DebouncedFunc, debounce } from 'lodash';
import { createContext, useContext, useMemo } from 'react';

import {
    Exact,
    SearchProspectsArgs,
    SearchProspectsQuery,
    SearchProspectsQueryVariables,
    SearchProspectsResultsCountQuery,
    SearchProspectsResultsCountQueryVariables,
} from 'codegen/graphql';
import { GET_SEARCH_PROSPECTS_RESULTS_COUNT, SEARCH_PROSPECTS } from 'shared/graphql/prospects';
import { FC } from 'shared/types';

interface ProspectSearchContextProps {
    searchProspects: DebouncedFunc<
        LazyQueryExecFunction<
            SearchProspectsQuery,
            Exact<{
                query: SearchProspectsArgs;
            }>
        >
    >;
    searchResults: SearchProspectsQuery | undefined;
    areSearchResultsLoading: boolean;
    searchProspectsAggregate: DebouncedFunc<
        LazyQueryExecFunction<
            SearchProspectsResultsCountQuery,
            Exact<{
                query: SearchProspectsArgs;
            }>
        >
    >;
    aggregateResults: SearchProspectsResultsCountQuery | undefined;
    areAggregateResultsLoading: boolean;
}

const ProspectSearchContext = createContext<ProspectSearchContextProps>({} as ProspectSearchContextProps);

const ProspectSearchProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [searchProspectsLazyQuery, { data: searchResults, loading: areSearchResultsLoading }] = useLazyQuery<
        SearchProspectsQuery,
        SearchProspectsQueryVariables
    >(SEARCH_PROSPECTS);

    const [searchProspectsResultsCountLazyQuery, { data: aggregateResults, loading: areAggregateResultsLoading }] =
        useLazyQuery<SearchProspectsResultsCountQuery, SearchProspectsResultsCountQueryVariables>(
            GET_SEARCH_PROSPECTS_RESULTS_COUNT
        );

    const DEBOUNCE_TIME_MS = 500;
    const searchProspects = useMemo(
        () => debounce(searchProspectsLazyQuery, DEBOUNCE_TIME_MS),
        [searchProspectsLazyQuery]
    );

    const searchProspectsAggregate = useMemo(
        () => debounce(searchProspectsResultsCountLazyQuery, DEBOUNCE_TIME_MS),
        [searchProspectsResultsCountLazyQuery]
    );

    const value = useMemo(
        () => ({
            searchProspects,
            searchResults,
            areSearchResultsLoading,
            searchProspectsAggregate,
            aggregateResults,
            areAggregateResultsLoading,
        }),
        [
            searchProspects,
            searchResults,
            areSearchResultsLoading,
            searchProspectsAggregate,
            aggregateResults,
            areAggregateResultsLoading,
        ]
    );

    return <ProspectSearchContext.Provider value={value}>{children}</ProspectSearchContext.Provider>;
};

const useProspectSearch = () => {
    const context = useContext(ProspectSearchContext);
    return context;
};

export { ProspectSearchProvider, useProspectSearch };
