import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { SelectOption, SelectSequence } from 'shared/components/form';
import { LoadingButton } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { SourcerMemberCardModalSummary } from 'sourcing/components/composite';

interface SourcerMemberCardModalChooseSequenceProps {
    sourcer: { id: string; project: { id: string; title: string } };
    rejectedCount: number;
    snoozedCount: number;
    approvedCount: number;
    sequenceOptions: SelectOption[];
    selected: string;
    handleSequenceChange: (value: string) => void;
    handleReviewSourcerMembers: () => void;
    loading: boolean;
    description?: string;
}

const SourcerMemberCardModalChooseSequence: FC<SourcerMemberCardModalChooseSequenceProps> = ({
    sourcer,
    snoozedCount,
    approvedCount,
    rejectedCount,
    sequenceOptions,
    selected,
    handleSequenceChange,
    handleReviewSourcerMembers,
    loading,
    description,
}) => {
    const translate = useTranslations('sourcing.sourcer-member-card.modal');

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: ${spacing.space12px};
            `}
        >
            {Boolean(description) && <Box>{description}</Box>}
            <SourcerMemberCardModalSummary
                snoozedCount={snoozedCount}
                rejectedCount={rejectedCount}
                approvedCount={approvedCount}
                projectTitle={sourcer.project.title}
            />
            {Boolean(approvedCount) && (
                <SelectSequence
                    width="100%"
                    label={translate('sequence-select-label')}
                    name="seqSelect"
                    options={sequenceOptions}
                    value={selected}
                    onChange={handleSequenceChange}
                    noOptionsText={translate('no-sequence')}
                />
            )}

            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    padding: ${spacing.space16px} ${spacing.none} ${spacing.none};
                `}
            >
                <LoadingButton variant="contained" onClick={handleReviewSourcerMembers} loading={loading}>
                    {translate('confirm-button', {
                        approvedCount,
                    })}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export { SourcerMemberCardModalChooseSequence };
