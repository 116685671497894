import { extractReply } from 'hireflow-shared/lib/emails/email-utils';
import {
    getHtmlContent,
    getRecipientOnlyAddress,
    getSenderAddress,
} from 'hireflow-shared/lib/google/gmail/message-parser';
import {
    getOutlookMessageContent,
    getOutlookRecipientOnlyAddress,
    getOutlookSenderAddress,
} from 'hireflow-shared/lib/microsoft/outlook/message-parser';

import { EmailAccountProviderEnum } from 'codegen/graphql';
import { removeTrackingPixel } from 'shared/services/messages-utils';

export type EmailMessageDetails = {
    to?: string[];
    from?: string;
    content?: string;
};

export const getMessageViewDetails = (
    message: any,
    emailProvider: string | undefined
): EmailMessageDetails | undefined => {
    if (emailProvider === EmailAccountProviderEnum.Gmail) {
        const gmailMessage = message;
        const to = getRecipientOnlyAddress(gmailMessage);
        const from = getSenderAddress(gmailMessage);
        const content = extractReply(removeTrackingPixel(getHtmlContent(gmailMessage) ?? ''));

        return { to, from, content };
    }
    if (emailProvider === EmailAccountProviderEnum.Outlook) {
        const outlookMessage = message;
        const to = getOutlookRecipientOnlyAddress(outlookMessage);
        const from = getOutlookSenderAddress(outlookMessage);

        /**
         * to display the message in the conversations tab, we need to get the content inside the
         * <body> tag of the message. Outlook messages are formatted differently than Gmail messages
         * so we need to parse the message differently.
         */
        const contentFromMessage = extractReply(removeTrackingPixel(getOutlookMessageContent(outlookMessage) ?? ''));
        const dom = new DOMParser().parseFromString(contentFromMessage, 'text/html');
        const content = dom?.body?.innerHTML ?? contentFromMessage;

        return { to, from, content };
    }
    return undefined;
};
