import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from '@mui/material';
import {
    getDraftingVariables,
    getFieldName,
    getMissingVariables,
    interpolate,
    personalizeCalendarLinkVar,
    personalizeHireflowSignature,
    personalizeSenderFirstNameVar,
    personalizeCustomMessageVar,
    substituteMagicSentence,
    Variables,
} from 'hireflow-shared/utils/email-drafting-utils';
import _, { flatten, uniq } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';
import * as Yup from 'yup';

import { trackEvent } from 'analytics';
import {
    ContactChannelsEnum,
    ContactKindsEnum,
    CreateRecipientCustomStagesMutation,
    CreateRecipientMutation,
    LicenseTypesEnum,
    GetUserRoleQuery,
    ProjectRelatedSequencesQuery,
    ProjectRelatedSequencesQueryVariables,
    RecipientStatusEnum,
    SequencesQueryResult,
    SequenceStageSendTimeEnum,
    SequenceStatusEnum,
    UpdateUserMutation,
} from 'codegen/graphql';
import {
    AddProjectSequenceConfirm,
    AddTabBottomExtension,
    AddTabReviewerView,
    AddToProjectsSelect,
    AddToSequenceSelect,
    ProspectSourcerConfirmAdd,
    SelectRecipientEmail,
    SequenceStagesView,
} from 'prospects';
import { DraftPersonalizedSequenceStages, StageViewFormValues } from 'prospects/types/form-types';
import { CustomStage, Stage } from 'sequences/types';
import { isMagicSentenceUsed } from 'sequences/utils';
import { getUnknownVariablesError, validVariables } from 'sequences/utils/unknown-variables';
import { Box } from 'shared/components/containers';
import { SelectOption } from 'shared/components/form';
import { AppFlow } from 'shared/enums';
import { ContactUpdate } from 'shared/graphql/contacts';
import { GET_PROJECT_MEMBERS_BY_PROJECT } from 'shared/graphql/project-members';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { CREATE_RECIPIENT_CUSTOM_STAGES } from 'shared/graphql/recipient-custom-stages';
import { GET_PROJECT_RECIPIENT_SEQUENCES } from 'shared/graphql/recipient-projects';
import { CREATE_RECIPIENTS, GET_RECIPIENTS } from 'shared/graphql/recipients';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { GET_USER_BY_ID, UPDATE_USER } from 'shared/graphql/users';
import {
    useAccessControl,
    useCreateProjectMembers,
    useExtensionPanel,
    useProspectPanel,
    useSession,
} from 'shared/hooks';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { createRestApiClient, isEmailValid, StatusEligibleForContactUpdate } from 'shared/services';
import { customStageFromSequence } from 'shared/services/custom-stage-utils';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectSourcerMember } from 'shared/types/prospect';

export type SequenceStageData = DeepExtractTypeSkipArrays<SequencesQueryResult, ['data', 'sequences', 'stages']>;
const getUserFullName = (first: string, last: string) => `${first} ${last}`;

interface ProspectAddTabProps {
    anchor: HTMLElement;
    sourcerMemberInReview?: ProspectSourcerMember;
    handleShowSourcerReviewerTab: () => void;
}

const ProspectAddTab: FC<ProspectAddTabProps> = (props) => {
    const { anchor, handleShowSourcerReviewerTab, sourcerMemberInReview } = props;

    const translate = useTranslations('settings.email-settings.queue-draft-folder-response');
    const translateUnknownVariables = useTranslations('prospects.tabs.add-tab');

    const {
        prospectInfo,
        contacts,
        recipients,
        sequencesData,
        projectsData,
        defaultUserProjectsData,
        members,
        context,
        updateContext,
        addProspectContact,
        createCustomMessage,
        addOrUpdateProspect,
        teamData,
    } = useProspectPanel();
    const { accessControlLoaded, canAddToProjectFromExtension, canAddToSequenceFromExtension } = useAccessControl();
    const { session, setSession } = useSession();
    const { panelIsOpen } = useExtensionPanel();

    const [magicSentence, setMagicSentence] = useState<string | null>(null);
    const [generatedSentenceId, setGeneratedSentenceId] = useState<string | null>(null);

    const [hidden, setHidden] = useState<boolean>(false);

    const [selectedSequence, setSelectedSequence] = useState<string>();
    const [seqOptions, setSeqOptions] = useState<SelectOption[]>();

    const [projects, setProjects] = useState<string[]>();
    const [names, setNames] = useState<string[]>();
    const [containsProtectedProjects, setContainsProtectedProjects] = useState<boolean>(false);

    const [email, setEmail] = useState<string>();
    const [variables, setVariables] = useState<Variables[]>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [height, setHeight] = useState(0);
    const [showSourcerConfirmation, setShowSourcerConfirmation] = useState<boolean>(false);

    const [handleAddLoading, setHandleAddLoading] = useState<boolean>(false);

    const profile = prospectInfo?.profile;
    const url = prospectInfo?.urls?.[0]?.url;
    const SelectedSequenceCustomMessage = prospectInfo?.customMessages?.find(
        (customMessageObject) => customMessageObject.sequenceId === selectedSequence
    );
    const customMessage = SelectedSequenceCustomMessage?.customMessage;

    const [draftPersonalizedSequenceStages, setDraftPersonalizedSequenceStages, loaded] = useLocalStorage<
        DraftPersonalizedSequenceStages | undefined
    >('personalizedStages', undefined);

    const [getProjectRelatedSequences, { data: projectRelatedSequences }] = useLazyQuery<
        ProjectRelatedSequencesQuery,
        ProjectRelatedSequencesQueryVariables
    >(GET_PROJECT_RECIPIENT_SEQUENCES, { variables: { projectIds: projects }, fetchPolicy: 'network-only' });

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER, {
        refetchQueries: [getOperationName(GET_USER_BY_ID) as string],
    });

    const [createRecipients] = useMutation<CreateRecipientMutation>(CREATE_RECIPIENTS, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
        ],
    });
    const [createCustomStages] = useMutation<CreateRecipientCustomStagesMutation>(CREATE_RECIPIENT_CUSTOM_STAGES, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    const { createAndTrackProjectMembers } = useCreateProjectMembers([
        getOperationName(GET_RECIPIENTS) as string,
        getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
    ]);

    const { data: userRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    const getErrorString = (value: string) => {
        const errorMessage = getUnknownVariablesError(value);
        if (errorMessage) {
            return errorMessage.count
                ? translateUnknownVariables('unknown-variables-alert-with-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                      count: errorMessage.count,
                  })
                : translateUnknownVariables('unknown-variables-alert-without-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                  });
        }
        return '';
    };

    const validationSchema = Yup.object().shape({
        stages: Yup.array().of(
            Yup.object().shape({
                subject: Yup.string()
                    .required(translate('error-subject'))
                    .test(
                        'unknown-variables',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
                content: Yup.string()
                    .required(translate('error-email-content'))
                    .test(
                        'unknown-variables',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
            })
        ),
    });

    const formMethods = useForm<StageViewFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });

    const { formState } = formMethods;
    const { isDirty } = formMethods.getFieldState('stages', formState);
    const { stages: formMethodStages } = formMethods.getValues();
    const selectedSequenceStages = useWatch({ control: formMethods.control, name: 'stages' });
    const customizedInitialTime = useWatch({ control: formMethods.control, name: 'time' });

    useEffect(() => {
        if (email && context?.prospectId) {
            trackEvent('select_primary_email', {
                prospect_id: context.prospectId,
                tab: 'add',
                email_id: email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, context?.prospectId]);

    useEffect(() => {
        if (profile && formMethods && formMethods.getValues('stages')) {
            const selectedStages = formMethods.getValues('stages');
            setVariables(
                selectedStages?.map((stage) =>
                    getDraftingVariables(profile, stage.sendFromAccount?.email_account.user, customMessage)
                )
            );
        }
    }, [profile, formMethods, formMethodStages, customMessage, selectedSequence]);

    useEffect(() => {
        if (sequencesData && recipients && session) {
            const sequencesToShowFirst = projectRelatedSequences?.recipient_projects
                ?.filter(
                    (f) =>
                        [SequenceStatusEnum.Active, SequenceStatusEnum.Ready].includes(f.recipient.sequence.status) &&
                        f.recipient.sequence.userId === session.user.id
                )
                .map((r) => r.recipient.sequence.id);

            const recipientSequenceIds = recipients.map((r) => r.sequence.id);

            const options = sequencesData
                .map((s) => ({
                    label: s.title,
                    value: s.id,
                    disabled: recipientSequenceIds.indexOf(s.id) > -1,
                }))
                .sort((a, b) => {
                    const freqA = sequencesToShowFirst?.filter((id) => id === a.value).length ?? 0;
                    const freqB = sequencesToShowFirst?.filter((id) => id === b.value).length ?? 0;
                    return freqB - freqA;
                });

            setSeqOptions(options);
        }
    }, [sequencesData, recipients, session, projectRelatedSequences]);

    useEffect(() => {
        if (sequencesData && selectedSequence && profile) {
            let stages = sequencesData.find((f) => f.id === selectedSequence)?.stages;
            if (stages && stages.length > 0) {
                const interpolateVariables = stages.map((stage) =>
                    getDraftingVariables(profile, stage.sendFromAccount?.email_account.user, customMessage)
                );

                setVariables(interpolateVariables);

                if (
                    draftPersonalizedSequenceStages &&
                    draftPersonalizedSequenceStages.stages.length > 0 &&
                    context?.url === draftPersonalizedSequenceStages.profileUrl &&
                    selectedSequence === draftPersonalizedSequenceStages.sequenceId &&
                    session?.user.id === draftPersonalizedSequenceStages.userId
                ) {
                    stages = stages?.map((stage, index) => {
                        let { content, subject } = stage;

                        subject =
                            draftPersonalizedSequenceStages.stages[index]?.subject ??
                            interpolate(subject, interpolateVariables[index]);

                        content =
                            draftPersonalizedSequenceStages.stages[index]?.content ??
                            interpolate(content, interpolateVariables[index]);

                        /**
                         * we should always substitute magic sentence
                         * we don't want to show the user the magic sentence variable
                         * in the personalize stages
                         */
                        content = substituteMagicSentence(content, magicSentence);

                        return { ...stage, subject, content };
                    });

                    formMethods.setValue('stages', stages, { shouldDirty: true });
                    formMethods.setValue(
                        'time',
                        draftPersonalizedSequenceStages?.time
                            ? draftPersonalizedSequenceStages.time
                            : stages && stages.length > 0
                            ? stages[0].sendTime ?? SequenceStageSendTimeEnum.AsSoonAsPossible
                            : SequenceStageSendTimeEnum.AsSoonAsPossible
                    );
                } else {
                    setDraftPersonalizedSequenceStages(undefined);
                    stages = stages?.map((stage, index) => {
                        let { content, subject } = stage;

                        subject = interpolate(subject, interpolateVariables[index]);

                        content = interpolate(content, interpolateVariables[index]);
                        /**
                         * we should always substitute magic sentence
                         * we don't want to show the user the magic sentence variable
                         * in the personalize stages
                         */
                        content = substituteMagicSentence(content, magicSentence);

                        return { ...stage, subject, content };
                    });
                    formMethods.reset({
                        stages,
                        time:
                            stages && stages.length > 0
                                ? stages[0].sendTime ?? SequenceStageSendTimeEnum.AsSoonAsPossible
                                : SequenceStageSendTimeEnum.AsSoonAsPossible,
                    });
                }
            }
        } else {
            setVariables([]);
            formMethods.reset({ stages: [], time: SequenceStageSendTimeEnum.AsSoonAsPossible });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequencesData, selectedSequence, profile, magicSentence, customMessage]);

    useEffect(() => {
        if (
            profile &&
            session!.user &&
            formMethods.getValues('stages') &&
            selectedSequenceStages &&
            selectedSequenceStages.length > 0
        ) {
            setMissingFields(
                uniq(
                    flatten(
                        selectedSequenceStages.map((stage) =>
                            getMissingVariables(stage.content, profile, session!.user, customMessage)
                        )
                    )
                ).flatMap((f) => (f ? [f] : []))
            );
        } else {
            setMissingFields([]);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, session!.user, formMethods.getValues('stages'), selectedSequenceStages, customMessage]);

    useEffect(() => {
        if ((projects || selectedSequence) && projectsData && seqOptions) {
            const seqNames = seqOptions.filter((f) => f.value === selectedSequence)[0]?.label;
            const projectNames = projectsData.filter((f) => projects?.indexOf(f.id) !== -1).map((m) => m.title);
            setNames(selectedSequence ? projectNames.concat(seqNames) : projectNames);

            setContainsProtectedProjects(
                !!projectsData?.filter((f) => projects?.indexOf(f.id) !== -1).find((p) => p.isProtected)
            );
        }
    }, [projects, selectedSequence, projectsData, seqOptions]);

    /**
     * attempt to retrieve the magic sentence from the backend and set
     */
    const fetchMagicSentence = async () => {
        if (!context?.prospectId) return;
        const restApiClient = createRestApiClient();
        const res = await restApiClient.getMagicSentence({ prospectId: context.prospectId });
        const magicSentenceResponse = res?.data?.magicSentence || null;
        const generatedSentenceIdResponse = res?.data?.generatedSentenceId || null;
        setMagicSentence(magicSentenceResponse);
        setGeneratedSentenceId(generatedSentenceIdResponse);
        updateContext({ ...context, magicSentence: magicSentenceResponse });
    };

    useEffect(() => {
        fetchMagicSentence();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.prospectId]);

    /**
     * create the prospect if one does not exist, but only once a sequence has been selected
     * this prospect should not be bound to a project or sequence at this point
     * this is required for the email finding service
     */
    useEffect(() => {
        // stop if the panel is not open
        if (!panelIsOpen) {
            return;
        }

        if (!selectedSequence || context?.prospectId || !profile || !url) {
            return;
        }

        addOrUpdateProspect(profile, url); // this will also update the context
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSequence, url, profile, panelIsOpen]);

    useEffect(() => {
        if (projects) {
            getProjectRelatedSequences();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects, context?.url]);

    const handleSequenceSelect = (selected?: string) => {
        setSelectedSequence(selected);
    };

    const handleProjectsSelect = useCallback(
        (selectedProjects: string[]) => {
            setProjects(selectedProjects);
        },
        [setProjects]
    );

    const handleRecipientEmailSelect = (value: string | undefined) => {
        setEmail(value);
    };

    const handleVariablesSave = async (updates: Map<string, string>) => {
        const userUpdates: { [field: string]: any } = {};
        const profileUpdates: { [field: string]: any } = {};
        let customMessageUpdate: string | undefined;

        let updatedVariables = [...variables];

        for (const [key, value] of updates) {
            if (!value) {
                // eslint-disable-next-line no-continue
                continue;
            }

            if (key === personalizeSenderFirstNameVar) {
                const { lastName } = session!.user;
                const fullName = getUserFullName(value, lastName!);
                userUpdates.firstName = value;
                userUpdates.lastName = lastName;
                userUpdates.fullName = fullName;
            } else if (key === personalizeCalendarLinkVar || key === personalizeHireflowSignature) {
                userUpdates[getFieldName(key)] = value;
            } else if (key === personalizeCustomMessageVar) {
                customMessageUpdate = value;
            } else {
                profileUpdates[getFieldName(key)] = value;
            }
            updatedVariables = updatedVariables.map((v) => {
                const variableKey = Object.keys(v).find((variable) => v[variable] === key);
                return variableKey ? { ...v, [variableKey]: value } : v;
            });
        }

        setVariables(updatedVariables);

        if (!_.isEmpty(userUpdates)) {
            await updateUser({
                variables: {
                    id: session!.user.id,
                    set: { ...userUpdates },
                },
            });

            setSession({
                user: {
                    ...session!.user,
                    ...userUpdates,
                },
            });
        }

        if (prospectInfo && selectedSequence && customMessageUpdate) {
            createCustomMessage({
                prospectId: prospectInfo.id,
                sequenceId: selectedSequence,
                customMessage: customMessageUpdate,
            });
        }

        if (!_.isEmpty(profileUpdates) && profile && url) {
            const updatedProfile = { ...profile, ...profileUpdates };
            await addOrUpdateProspect(updatedProfile, url);
        }

        if (draftPersonalizedSequenceStages && (draftPersonalizedSequenceStages.stages ?? []).length > 0) {
            const updatedStage =
                (profile && draftPersonalizedSequenceStages?.stages)?.map((stage, index) => {
                    let { content, subject } = stage;

                    subject = interpolate(subject, updatedVariables[index]);
                    content = interpolate(content, updatedVariables[index]);

                    return { ...stage, subject, content };
                }) || [];

            if ((updatedStage ?? []).length > 0) {
                const updatedDraftPersonalizedSequenceStages = {
                    ...draftPersonalizedSequenceStages,
                    stages: updatedStage,
                };
                setDraftPersonalizedSequenceStages(updatedDraftPersonalizedSequenceStages);
            }
        }
    };

    const addToProjects = async (projectIds: string[], prospectId: string) => {
        const projectNewMembers = projectIds.map((projectId) => ({
            prospectId,
            projectId,
        }));
        await createAndTrackProjectMembers(
            projectNewMembers,
            context?.flow === 'extension' ? AppFlow.Extension : AppFlow.OtherPartOfWebapp
        );
    };

    const addToSequence = async (sequenceId: string, prospectId: string) => {
        const newStages = selectedSequenceStages;
        const newTime = customizedInitialTime;
        const oldStages = sequencesData!.filter((f) => f.id === selectedSequence)[0].stages as Stage[];

        // if a generatedSentenceId exists and the magic sentence is used in the sequence stages
        // then track the usage of this magic sentence for the given generatedSentenceId
        // we should check if the magic sentence was used in the old stages
        // as the user may remove the sentence in the new stages
        if (generatedSentenceId && isMagicSentenceUsed(oldStages)) {
            const restApiClient = createRestApiClient();
            // don't await and hold up the main thread
            restApiClient.trackGeneratedMagicSentence({ generatedSentenceId });
        }

        // add recipient
        const result = await createRecipients({
            variables: {
                recipients: {
                    prospectId,
                    sequenceId,
                    status: RecipientStatusEnum.FindingEmail,
                },
            },
        });

        const recipientId = result.data?.insert_recipients?.returning[0]?.id;

        if (recipientId) {
            // update custom stages
            let customStages: CustomStage[] = [];
            if (session!.user && (isDirty || newTime !== oldStages[0].sendTime)) {
                customStages = customStageFromSequence(
                    recipientId,
                    newStages as Stage[],
                    oldStages,
                    variables,
                    newTime
                );
            }
            if (customStages.length > 0) {
                await createCustomStages({
                    variables: {
                        recipientId,
                        stageIndex: customStages.map((stage) => stage.stageIndex),
                        objects: customStages,
                    },
                });
            }
            // update primary email
            if (email && isEmailValid(email)) {
                // We need to cast the enum here because the graphql code gen doesn't
                // generate the correct types for the enums fields when we're using a computed field
                const contactUpdates: ContactUpdate = {
                    kind:
                        (contacts?.find((f) => f.value === email)?.kind as ContactKindsEnum) ??
                        ContactKindsEnum.Unknown,
                    value: email,
                    standardizedValue: email,
                    channel: ContactChannelsEnum.Email,
                    invalid: false,
                    primary: true,
                    userMarkedInvalid: false,
                };
                const recipientIds = recipients
                    ?.filter((f) => f.user.id === session?.user.id && StatusEligibleForContactUpdate.includes(f.status))
                    .map((r) => r.id)
                    .concat(recipientId);

                addProspectContact([contactUpdates], recipientIds ?? []);
            }
        }
        setDraftPersonalizedSequenceStages(undefined);
    };

    const handleAddToProjectOrSequence = async (prospectId: string) => {
        // project member need to get created first
        // so trigger can properly create recipients to project mapping
        if (projects && projects.length > 0) {
            await addToProjects(projects, prospectId);
        } else if (members && members.length === 0 && defaultUserProjectsData && defaultUserProjectsData.length === 1) {
            // checking whether the prospect is already part of a project.
            // if not, add the prospect to the default user project, i.e., "Waiting Room"
            await addToProjects(
                defaultUserProjectsData.map((o) => o.id),
                prospectId
            );
        }

        if (selectedSequence !== undefined) {
            addToSequence(selectedSequence, prospectId);
        }
    };

    const updateUserLastUsed = async () => {
        const userLastUsedUpdated: { [field: string]: any } = {};
        userLastUsedUpdated.lastUsedSequence = selectedSequence || null;
        userLastUsedUpdated.lastUsedProjects = projects && projects.length > 0 ? projects : [];

        await updateUser({
            variables: {
                id: session!.user.id,
                set: { ...userLastUsedUpdated },
            },
        });

        setSession({
            user: {
                ...session!.user,
                ...userLastUsedUpdated,
            },
        });
    };

    const handleConfirmAndContinue = () => {
        setShowSourcerConfirmation(false);
        addToSequenceOrProjects();
    };

    const handleAddClick = () => {
        if (sourcerMemberInReview) {
            setShowSourcerConfirmation(true);
        } else {
            addToSequenceOrProjects();
        }
    };

    const addToSequenceOrProjects = async () => {
        const result = await formMethods.trigger(undefined, { shouldFocus: true });
        if (!result) {
            return;
        }
        setHandleAddLoading(true);
        let prospectId = prospectInfo?.id;
        if (context && context.flow === 'extension' && !context.prospectId && profile) {
            prospectId = await addOrUpdateProspect(profile, prospectInfo?.urls![0].url);
        }
        if (prospectId) {
            await handleAddToProjectOrSequence(prospectId);
            await updateUserLastUsed();
        }
        setHidden(true);
        trackEvent('click_add_prospect', {
            prospect_id: prospectId,
            sequence_id: selectedSequence,
            project_ids: projects,
        });
        setHandleAddLoading(false);
    };

    if (!accessControlLoaded || !teamData || !teamData.planType || !userRoleData || !userRoleData.user_roles_by_pk) {
        return null;
    }

    if (session?.user.licenseType === LicenseTypesEnum.Reviewer) {
        return <AddTabReviewerView />;
    }

    const hasAddToProjectAccess = canAddToProjectFromExtension(
        teamData.planType,
        userRoleData.user_roles_by_pk.licenseType
    );
    const hasAddToSequenceAccess = canAddToSequenceFromExtension(
        teamData.planType,
        userRoleData.user_roles_by_pk.licenseType
    );

    return showSourcerConfirmation && sourcerMemberInReview ? (
        <ProspectSourcerConfirmAdd
            sourcerMember={sourcerMemberInReview}
            handleGoBack={handleShowSourcerReviewerTab}
            handleConfirmAndContinue={handleConfirmAndContinue}
        />
    ) : (
        <>
            <Box hidden={hidden}>
                <Box
                    css={css`
                        padding: ${spacing.none} ${spacing.space24px};
                    `}
                >
                    {prospectInfo && projectsData && members && session!.user && (
                        <AddToProjectsSelect
                            hidden={hidden}
                            onChange={handleProjectsSelect}
                            disabled={!hasAddToProjectAccess}
                        />
                    )}
                    {prospectInfo && sequencesData && recipients && session!.user && seqOptions && (
                        <AddToSequenceSelect
                            anchor={anchor}
                            formMethods={formMethods}
                            hidden={hidden}
                            options={seqOptions}
                            onChange={handleSequenceSelect}
                            disabled={containsProtectedProjects || !hasAddToSequenceAccess}
                        />
                    )}
                    {selectedSequence !== undefined && hasAddToSequenceAccess && (
                        <SelectRecipientEmail
                            anchor={anchor}
                            contacts={contacts ?? []}
                            onSelect={handleRecipientEmailSelect}
                            sequenceId={selectedSequence}
                        />
                    )}
                </Box>
                {selectedSequence !== undefined && prospectInfo?.profile !== undefined && loaded && (
                    <Box
                        css={css`
                            margin-bottom: ${height}px;
                        `}
                    >
                        <SequenceStagesView
                            anchor={anchor}
                            formMethods={formMethods}
                            sequenceId={selectedSequence}
                            variables={variables}
                            setPersonalizedStages={setDraftPersonalizedSequenceStages}
                            personalizedSequenceStages={draftPersonalizedSequenceStages}
                            magicSentence={magicSentence}
                            missingFields={missingFields}
                        />
                    </Box>
                )}
            </Box>
            {((selectedSequence !== undefined && formMethods.getValues('time')) || (projects && projects.length > 0)) &&
                session!.user &&
                sequencesData &&
                projectsData &&
                variables && (
                    <Box
                        hidden={hidden}
                        css={css`
                            margin: 0px;
                            padding: 0px;
                            width: 100%;
                        `}
                    >
                        <AddTabBottomExtension
                            formMethods={formMethods}
                            sequenceId={selectedSequence}
                            onSaveAndApply={handleVariablesSave}
                            missingFields={missingFields}
                            onAdd={handleAddClick}
                            onAddLoading={handleAddLoading}
                            setHeight={setHeight}
                            selectedProjects={projects}
                            personalizedSequenceStages={draftPersonalizedSequenceStages}
                            hasAddToProjectAndSequencePermission={hasAddToProjectAccess && hasAddToSequenceAccess}
                        />
                    </Box>
                )}
            {names && (
                <Box hidden={!hidden}>
                    <AddProjectSequenceConfirm refresh={!hidden} titles={names} />
                </Box>
            )}
        </>
    );
};

export { ProspectAddTab };
