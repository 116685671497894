import { useQuery } from '@apollo/client';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { OwnerUserByTeamIdQuery, OwnerUserByTeamIdQueryVariables } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Hyperlink, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { ARCHIVE_USER_ARTICLE_URL } from 'shared/constants';
import { GET_OWNER_USER_BY_TEAM_ID } from 'shared/graphql/users';
import { useSession } from 'shared/hooks';
import { colors, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ArchiveUserModalProps {
    userName?: string;
    onClose: () => void;
    onArchiveClick: () => void;
    open: boolean;
}

const ArchiveUserModal: FC<ArchiveUserModalProps> = (props) => {
    const { session, loaded } = useSession();
    const { onClose, open, onArchiveClick, userName } = props;

    const translate = useTranslations('settings.team-settings.member-list-submenu.archive-user-modal');

    const { data, loading } = useQuery<OwnerUserByTeamIdQuery, OwnerUserByTeamIdQueryVariables>(
        GET_OWNER_USER_BY_TEAM_ID,
        {
            skip: !loaded,
            variables: { teamId: session?.user.teamId ?? '' },
        }
    );

    if (!loaded || loading || !data?.users[0]?.fullName) {
        return null;
    }

    const ownerName = data.users[0].fullName;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    padding: 24px;
                `}
            >
                <ModalCloseButton onClose={onClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title', { userName })}
                </Title>
                <Box>
                    {translate.rich('description', {
                        userName,
                        ownerName,
                    })}
                </Box>
                <Box
                    css={css`
                        margin-top: ${spacing.space16px};
                    `}
                >
                    {translate.rich('description-2', { link: Link })}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 16px;
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={onClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" color="error" onClick={onArchiveClick}>
                        {translate('confirm-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: ${colors.greens.eucalyptus};
        `}
        href={ARCHIVE_USER_ARTICLE_URL}
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

export { ArchiveUserModal };
