import { css } from '@emotion/react';
import { SelectChangeEvent, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { LicenseTypesEnum, PlanTypesEnum, TeamRolesEnum } from 'codegen/graphql';
import { MemberListSubmenu } from 'settings/components/presentational/team/member-list-submenu';
import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { EllipsisText, IconButton, NoSsr } from 'shared/components/presentational';
import { Circle, DotsVertical } from 'shared/components/svgs';
import { TeamInviteData, roleLabelsType } from 'shared/graphql/teams';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface InviteesListRowProps {
    seatsUsed: number;
    currentUserRole: TeamRolesEnum;
    invite: TeamInviteData;
    roleOptions: Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;
    onRoleChange: (id: string) => (event: SelectChangeEvent<any>) => void;
    planType: PlanTypesEnum;
    currentUserLicenseType: LicenseTypesEnum;
    seatsAvailable: number;
}

const InviteesListRow: FC<InviteesListRowProps> = ({
    invite,
    seatsUsed,
    roleOptions,
    currentUserRole,
    onRoleChange,
    planType,
    currentUserLicenseType,
    seatsAvailable,
}) => {
    const theme = useTheme();
    const translate = useTranslations('settings.team-settings');

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const canChangeRolePermission =
        (currentUserRole === TeamRolesEnum.Owner || currentUserRole === TeamRolesEnum.Admin) &&
        planType !== PlanTypesEnum.Solo;

    const selectedValue =
        invite.role === TeamRolesEnum.Admin
            ? TeamRolesEnum.Admin
            : invite.licenseType === LicenseTypesEnum.Reviewer
            ? LicenseTypesEnum.Reviewer
            : LicenseTypesEnum.Full;

    const canRemoveInviteePermission =
        currentUserRole === TeamRolesEnum.Owner ||
        (currentUserRole === TeamRolesEnum.Admin && TeamRolesEnum.User === invite.role);

    const iconButton = canRemoveInviteePermission ? (
        <IconButton
            css={css`
                margin: 0px;
                margin-left: 10px;
                &:hover path {
                    stroke: ${theme.palette.primary.light};
                }
            `}
            onClick={handleSubmenuButtonClick}
            disableRipple
        >
            <DotsVertical />
        </IconButton>
    ) : null;

    const availableSeats = invite.team.seatsCount - seatsUsed;

    return (
        <li
            css={css`
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid ${theme.palette.grey[500]};
                padding: 24px 0;
            `}
        >
            <Box>
                <Box
                    component="p"
                    css={css`
                        font-family: ${fontFamilies.inter};
                        font-weight: ${fontWeights.normal};
                        font-size: ${fontSizes.f12};
                        line-height: 15px;
                        color: ${theme.palette.grey[200]};
                        margin: 0;
                    `}
                >
                    {invite.invitee}
                </Box>
                <Box
                    component="p"
                    css={css`
                        font-family: ${fontFamilies.inter};
                        font-weight: ${fontWeights.normal};
                        font-size: ${fontSizes.f12};
                        line-height: 15px;
                        color: ${theme.palette.grey[200]};
                        margin: 8px 0 0 0;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <EllipsisText
                        css={css`
                            text-align: left;
                            padding: ${spacing.space4px} ${spacing.space8px};
                            background-color: ${colors.yellows.picasso100};
                            border-radius: ${spacing.space4px};
                            max-width: min-content;
                            margin-right: 10px;
                        `}
                    >
                        {translate(invite.status)}
                    </EllipsisText>
                    <NoSsr>
                        {translate('invited-on', {
                            date: DateTime.fromMillis(invite.createdAt).toFormat('MMM dd yyyy'),
                        })}
                    </NoSsr>
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Select
                    disabled={!canChangeRolePermission}
                    name="role"
                    value={selectedValue}
                    onChange={onRoleChange(invite.id)}
                    css={css`
                        width: 176px;
                        margin-bottom: 0px;
                        ${!canRemoveInviteePermission && 'margin-right: 30px;'}
                        .MuiSelect-select {
                            height: 24px;
                            margin: 0;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            font-family: ${fontFamilies.inter};
                            font-style: normal;
                            font-weight: ${fontWeights.normal};
                            font-size: ${fontSizes.f16};
                            line-height: 19px;
                            color: ${theme.palette.common.black};
                        }
                        .MuiSelect-icon {
                            right: 10px;
                            top: 10px !important;
                        }
                    `}
                >
                    {roleOptions.map((role, i) => (
                        <MenuItem
                            value={role.value}
                            disabled={availableSeats <= 0 && role.value !== LicenseTypesEnum.Reviewer}
                            key={i}
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                            `}
                        >
                            <Box
                                css={css`
                                    display: flex;
                                    align-items: center;
                                `}
                            >
                                {role.value === selectedValue && (
                                    <Circle
                                        css={css`
                                            margin-right: 10px;
                                        `}
                                    />
                                )}
                                {translate(role.label)}
                            </Box>
                            <Box
                                component="p"
                                css={css`
                                    margin: 0;
                                    font-size: ${fontSizes.f12};
                                    line-height: 15px;
                                    color: ${theme.palette.common.black};
                                `}
                            >
                                {currentUserLicenseType === LicenseTypesEnum.Reviewer &&
                                    role.value !== LicenseTypesEnum.Reviewer &&
                                    translate('seats-available', {
                                        availableSeats,
                                    })}
                            </Box>
                        </MenuItem>
                    ))}
                </Select>

                {iconButton}
            </Box>

            <MemberListSubmenu
                id={invite.id}
                member={false}
                anchor={submenuAnchor}
                setAnchor={setSubmenuAnchor}
                isArchived={false}
                seatsAvailable={seatsAvailable}
                planType={planType}
                totalSeats={seatsAvailable + seatsUsed}
            />
        </li>
    );
};

export { InviteesListRow };
