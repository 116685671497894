import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoProspectsModalProps {
    open: boolean;
    onClose: () => void;
}

const NoProspectsModal: FC<NoProspectsModalProps> = (props) => {
    const { open, onClose } = props;
    const translate = useTranslations('shared.no-prospects-modal');

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={onClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title')}
                </Title>
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    `}
                >
                    <Box>{translate('description')}</Box>
                    <Box
                        css={css`
                            display: flex;
                            justify-content: flex-end;

                            width: 100%;
                        `}
                    >
                        <Button variant="contained" onClick={onClose}>
                            {translate('input-label')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export { NoProspectsModal };
