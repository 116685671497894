import styled from '@emotion/styled';
import { Grid as MuiGrid, GridProps } from '@mui/material';

import { FC } from 'shared/types';

interface Props extends GridProps {}

const GridContainer: FC<Props> = (props) => <MuiGrid {...props} />;

const Grid = styled(GridContainer)``;

export { Grid };
