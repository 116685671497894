import { gql } from '@apollo/client';

import { UserByIdQuery } from 'codegen/graphql';

export type User = UserByIdQuery['users_by_pk'];

export const USER_DETAILS = gql`
    fragment UserDetails on users {
        id
        firstName
        lastName
        fullName
        role
        licenseType
        team {
            id
            seatsCount
            planType
        }
        sendEmailsHourStart
        sendEmailsHourEnd
        calendarLink
        autoArchiveOutOfOfficeEmails
        autoArchiveBouncedEmails
        findAlternateEmailIfInitialBounces
        addLabelToHiresequenceMessages
        includeUnsubscribeLinkToEmailOutreaches
        sendDossierEmails
        primaryTimezone
        draftResponses
        hireflowSignature
        lastUsedProjects
        lastUsedSequence
    }
`;

export const GET_USERS = gql`
    query Users(
        $orderBy: users_order_by! = { createdAt: asc }
        $limit: Int
        $offset: Int! = 0
        $where: users_bool_exp! = {}
    ) {
        users(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where) {
            id
            firstName
            lastName
            fullName
            email
        }
    }
`;

export const GET_USER_BY_ID = gql`
    query UserById($id: String!) {
        users_by_pk(id: $id) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($id: String!, $set: users_set_input!) {
        update_users_by_pk(pk_columns: { id: $id }, _set: $set) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`;

export const REMOVE_USER = gql`
    mutation RemoveUser($id: String!) {
        delete_users_by_pk(id: $id) {
            id
        }
    }
`;

export const UPDATE_USER_ROLE = gql`
    mutation UpdateUserRole($userId: String!, $set: user_roles_set_input!) {
        update_user_roles(where: { userId: { _eq: $userId } }, _set: $set) {
            returning {
                userId
            }
        }
    }
`;

export const GET_USERS_WITH_FEATURES = gql`
    query UsersWithFeatures($where: users_bool_exp = {}) {
        users(where: $where) {
            id
            firstName
            lastName
            fullName
            features {
                feature
            }
        }
    }
    ${USER_DETAILS}
`;

export const GET_OWNER_USER_BY_TEAM_ID = gql`
    query OwnerUserByTeamId($teamId: String!) {
        users(where: { teamId: { _eq: $teamId }, user_role: { role: { _eq: owner } } }) {
            ...UserDetails
        }
    }
    ${USER_DETAILS}
`;
