import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowNarrowUpRight: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M1 13L13 1M13 1H5M13 1V9"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowNarrowUpRight = withTheme(ThemedArrowNarrowUpRight);

export { ArrowNarrowUpRight };
