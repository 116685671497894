import { css } from '@emotion/react';
import { DraftResponses } from 'hireflow-shared/types/user';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { ComposeDraftResponse } from 'settings/components/presentational/account-and-emails';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { useSession } from 'shared/hooks/use-session';
import { fontWeights, spacing } from 'shared/settings';

const QueueDraftFolderResponse = () => {
    const translate = useTranslations('settings.email-settings.queue-draft-folder-response');
    const { session } = useSession();

    const [draftResponses, setDraftResponses] = useState<DraftResponses>();

    useEffect(() => {
        setDraftResponses(session!.user.draftResponses);
    }, [session]);

    return (
        <Box>
            <Title type="h2">{translate('title')}</Title>
            <Box>
                {translate.rich('description', {
                    // eslint-disable-next-line react/no-unstable-nested-components
                    bold: (children) => (
                        <Box
                            component="span"
                            css={css`
                                font-weight: ${fontWeights.bold};
                            `}
                        >
                            {children}
                        </Box>
                    ),
                })}
            </Box>

            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    row-gap: ${spacing.space16px};
                `}
            >
                <ComposeDraftResponse
                    title={translate('interested-prospects.title')}
                    draftResponsesSettings={draftResponses}
                    response="interestedProspects"
                />
                <ComposeDraftResponse
                    title={translate('uninterested-prospects.title')}
                    draftResponsesSettings={draftResponses}
                    response="uninterestedProspects"
                />
            </Box>
        </Box>
    );
};

export { QueueDraftFolderResponse };
