import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useState } from 'react';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { SourcerMemberRejectionReasonsEnum, SourcerMembersQuery, SourcerMemberStatusEnum } from 'codegen/graphql';
import {
    ProspectCardButtonsContainer,
    ProspectCardEducation,
    ProspectCardExperience,
    ProspectCardHeader,
    SourcerMemberCardInsights,
} from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { LargeSwitch } from 'shared/components/form';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import {
    SourcerMemberCardRejectionDetails,
    SourcerMemberCardStatusOptions,
    SourcerMemberCardSubmenu,
} from 'sourcing/components/composite';

type SourcerMemberExperience = DeepExtractTypeSkipArrays<SourcerMembersQuery, ['sourcer_members', 'experience']>;

type SourcerMemberEducation = DeepExtractTypeSkipArrays<SourcerMembersQuery, ['sourcer_members', 'education']>;

interface SourcerMemberExperienceWithTitle extends SourcerMemberExperience {
    title: NonNullable<SourcerMemberExperience['title']>;
}

interface SourcerMemberEducationWithFieldOfStudy extends SourcerMemberEducation {
    fieldOfStudy: NonNullable<SourcerMemberEducation['fieldOfStudy']>;
}

interface SourcerMemberCardProps {
    sourcerMember: SourcerMembersQuery['sourcer_members'][number];
    onStatusUpdate: (sourcerMemberId: string, status: SourcerMemberCardStatusOptions) => void;
    status?: SourcerMemberCardStatusOptions;
    rejectionReasons?: SourcerMemberRejectionReasonsEnum[];
    notes?: string;
    onNotesChange: (sourcerMemberId: string, newNotes: string) => void;
    onRejectionReasonsChange: (
        sourcerMemberId: string,
        newRejectionReasons: SourcerMemberRejectionReasonsEnum[]
    ) => void;
    creditsPerApproval: number;
    showInsights: boolean;
    viewOnly: boolean;
}

const SourcerMemberCard: FC<SourcerMemberCardProps> = ({
    creditsPerApproval,
    sourcerMember,
    onStatusUpdate,
    status,
    rejectionReasons,
    notes,
    onNotesChange,
    onRejectionReasonsChange,
    showInsights,
    viewOnly,
}) => {
    const theme = useTheme();

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newStatus = event.target.checked ? SourcerMemberStatusEnum.Accepted : SourcerMemberStatusEnum.Rejected;
        onStatusUpdate(sourcerMember.id, newStatus);
    };

    const displayInsights = sourcerMember.insights.length > 0 && showInsights;

    return (
        <>
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    padding: ${spacing.space24px};
                    gap: 21px;
                    background: ${theme.palette.common.white};
                    border: 1px solid ${theme.palette.grey[500]};
                    border-radius: 4px;
                    max-width: 1032px;
                `}
            >
                <ProspectCardHeader
                    firstName={sourcerMember.firstName}
                    lastName={sourcerMember.lastName}
                    fullName={sourcerMember.fullName}
                    location={sourcerMember.location}
                    profileImageUri={sourcerMember.profileImageUri}
                    linkedinUrl={getPrimaryProfileUrl(sourcerMember.urls)}
                    onSubmenuClick={viewOnly ? undefined : handleSubmenuButtonClick}
                />
                {displayInsights && <SourcerMemberCardInsights insights={sourcerMember.insights} />}

                <ProspectCardExperience
                    experiences={sourcerMember.experience.filter((exp): exp is SourcerMemberExperienceWithTitle =>
                        Boolean(exp.title)
                    )}
                />

                <ProspectCardEducation
                    educations={sourcerMember.education.filter((edu): edu is SourcerMemberEducationWithFieldOfStudy =>
                        Boolean(edu.fieldOfStudy)
                    )}
                />
                {viewOnly ? null : (
                    <>
                        <ProspectCardButtonsContainer>
                            <LargeSwitch
                                onChange={handleSwitchChange}
                                name="sourcer-member-accepted-rejected-switch"
                                checked={status === SourcerMemberStatusEnum.Accepted}
                            />
                        </ProspectCardButtonsContainer>
                        {status === SourcerMemberStatusEnum.Rejected ? (
                            <SourcerMemberCardRejectionDetails
                                sourcerMemberId={sourcerMember.id}
                                rejectionReasons={rejectionReasons}
                                notes={notes}
                                onNotesChange={onNotesChange}
                                onRejectionReasonsChange={onRejectionReasonsChange}
                                showRejectionReasons
                            />
                        ) : null}
                    </>
                )}
            </Box>
            {viewOnly ? null : (
                <SourcerMemberCardSubmenu
                    creditsPerApproval={creditsPerApproval}
                    sourcerMember={sourcerMember}
                    anchor={submenuAnchor}
                    setAnchor={setSubmenuAnchor}
                />
            )}
        </>
    );
};

export { SourcerMemberCard };
