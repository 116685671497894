import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { css, useTheme } from '@mui/material';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import { ScrollSectionEnum } from 'prospects/prospect-project-sequence-ats-overview';
import { ProspectSubmenu } from 'prospects/prospect-submenu';
import { Box } from 'shared/components/containers';
import { Chip, IconButton } from 'shared/components/presentational/';
import {
    AshbyLogo,
    Chevron,
    DotsVertical,
    Folder,
    GreenhouseLogo,
    LeverLogo,
    SequenceIcon,
} from 'shared/components/svgs';
import { ProspectRecipientData } from 'shared/graphql/recipients';
import { useProspectPanel } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectTopBarProps {
    recipient?: ProspectRecipientData;
    onClose: () => void;
    openAddTab: () => void;
    redirectToSection: (section: ScrollSectionEnum) => void;
}

const ProspectTopBar: FC<ProspectTopBarProps> = (props) => {
    const { context, ashbyData, greenhouseData, leverData } = useProspectPanel();
    const { openAddTab, onClose, recipient, redirectToSection } = props;
    const theme = useTheme();
    const { recipients, members } = useProspectPanel();
    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();

    const handleClose = () => {
        onClose();
    };

    const handleSubmenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleOnClick = (section: ScrollSectionEnum) => () => {
        redirectToSection(section);
        if (section === ScrollSectionEnum.Projects) {
            trackEvent('click_project_icon');
        } else if (section === ScrollSectionEnum.Sequences) {
            trackEvent('click_sequence_icon');
        } else if (section === ScrollSectionEnum.Ats) {
            trackEvent('click_ats_icon');
        }
    };

    const getProspectAtsApplicationCount = () => {
        let res: number = 0;
        if (ashbyData) res += ashbyData.length;
        if (greenhouseData) res += greenhouseData.length;
        if (leverData) res += leverData.length;
        return res;
    };

    const canShowAtsIcon = (): boolean => {
        if (
            (ashbyData && ashbyData.length > 0) ||
            (greenhouseData && greenhouseData.length > 0) ||
            (leverData && leverData.length > 0)
        )
            return true;
        return false;
    };

    const getProspectAtsApplicationIcon = (): EmotionJSX.Element | undefined => {
        let res;
        if (ashbyData && ashbyData.length > 0) res = <AshbyLogo fill={theme.palette.common.black} />;
        else if (greenhouseData && greenhouseData.length > 0)
            res = <GreenhouseLogo fill={theme.palette.common.black} />;
        else if (leverData && leverData.length > 0) res = <LeverLogo fill={theme.palette.common.black} />;
        return res;
    };

    return (
        <>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Box>
                    <IconButton
                        css={css`
                            padding: 0;
                        `}
                        onClick={handleClose}
                        disableRipple
                    >
                        {context?.flow !== 'extension' && <Chevron direction="left" />}
                    </IconButton>
                </Box>

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Chip
                        key="prospect-projects"
                        label={members?.length || 0}
                        icon={<Folder stroke={theme.palette.common.black} />}
                        css={css`
                            background-color: ${theme.palette.grey[300]};
                            margin-right: ${spacing.space12px};
                        `}
                        onClick={handleOnClick(ScrollSectionEnum.Projects)}
                    />
                    <Chip
                        key="prospect-sequences"
                        label={recipients?.length || 0}
                        icon={<SequenceIcon />}
                        css={css`
                            background-color: ${theme.palette.grey[300]};
                            margin-right: ${spacing.space12px};
                        `}
                        onClick={handleOnClick(ScrollSectionEnum.Sequences)}
                    />
                    {canShowAtsIcon() ? (
                        <Chip
                            key="prospect-ats"
                            label={getProspectAtsApplicationCount()}
                            icon={getProspectAtsApplicationIcon()}
                            css={css`
                                background-color: ${theme.palette.grey[300]};
                            `}
                            onClick={handleOnClick(ScrollSectionEnum.Ats)}
                        />
                    ) : null}
                    <IconButton
                        css={css`
                            margin-left: ${spacing.space24px};
                            padding: 0;
                            path {
                                stroke: ${theme.palette.primary.light};
                            }
                        `}
                        onClick={handleSubmenuClick}
                        disableRipple
                    >
                        <DotsVertical />
                    </IconButton>
                </Box>
            </Box>
            <ProspectSubmenu
                anchor={submenuAnchor}
                setAnchor={setSubmenuAnchor}
                recipient={recipient}
                openAddTab={openAddTab}
            />
        </>
    );
};

export { ProspectTopBar };
