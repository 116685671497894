import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { hireflowSupport } from 'shared/constants';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ChangeApiTokenSupportProps {}

const ChangeApiTokenSupport: FC<ChangeApiTokenSupportProps> = () => {
    const translate = useTranslations('integrations.contact-support');

    return (
        <Box
            css={css`
                margin-top: ${spacing.space8px};
            `}
        >
            <Box component="span">{translate('first')}</Box>{' '}
            <Hyperlink newTab href={hireflowSupport}>
                {translate('link')}
            </Hyperlink>{' '}
            <Box component="span">{translate('last')}</Box>
        </Box>
    );
};

export { ChangeApiTokenSupport };
