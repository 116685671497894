import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Popover, SubmenuButton } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { PauseAllSourcersModal } from 'sourcing/components/modals';

interface SourcingListTopSubmenuProps {
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
}
const SourcingListTopSubmenu: FC<SourcingListTopSubmenuProps> = ({ anchor, setAnchor }) => {
    const handleSubmenuPopoverClose = () => setAnchor(undefined);
    const translate = useTranslations('sourcing.list-top-submenu');
    const [openPauseAllSourcerModal, setOpenPauseAllSourcerModal] = useState(false);
    const handleOpenPauseAllSourcerModal = () => {
        setOpenPauseAllSourcerModal(true);
    };

    const handleClosePauseAllSourcerModal = () => {
        setOpenPauseAllSourcerModal(false);
        handleSubmenuPopoverClose();
    };

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleSubmenuPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    <SubmenuButton onClick={handleOpenPauseAllSourcerModal}>{translate('pause-all')}</SubmenuButton>
                </Box>
            </Popover>
            <PauseAllSourcersModal open={openPauseAllSourcerModal} onClose={handleClosePauseAllSourcerModal} />
        </>
    );
};

export { SourcingListTopSubmenu };
