import styled from '@emotion/styled';
import { InputAdornment as MuiInputAdornment, InputAdornmentProps as MuiInputAdornmentProps } from '@mui/material';

import { FC } from 'shared/types';

interface InputAdornmentProps extends MuiInputAdornmentProps {}

const InputAdornment: FC<InputAdornmentProps> = (props) => <MuiInputAdornment {...props} />;

const StyledInputAdornment = styled(InputAdornment)``;

export { StyledInputAdornment as InputAdornment };
