import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowUpRight: FC<SvgProps> = ({ stroke, theme, onClick, width, height, ...rest }) => (
    <svg
        {...{
            width: width || '12',
            height: height || '12',
            viewBox: '0 0 12 12',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
            ...(onClick && { onClick }),
            ...rest,
        }}
    >
        <path
            d="M1 11L11 1M11 1H1M11 1V11"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowUpRight = withTheme(ThemedArrowUpRight);

export { ArrowUpRight };
