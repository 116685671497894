import styled from '@emotion/styled';
import { TextFieldProps as MuiTextFieldProps, TextField as MuiTextField } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Button } from 'shared/components/presentational';
import { Plus, Minus } from 'shared/components/svgs';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface CounterProps {
    name: string;
    formMethods: UseFormReturn<any>;
}

const Counter: FC<CounterProps & MuiTextFieldProps> = (props) => {
    const { className, name, formMethods, ...rest } = props;

    return (
        <Controller
            name={name}
            control={formMethods.control}
            render={({ field: { onBlur, onChange, ref, value }, fieldState: { error } }) => {
                const handleAdd = () => {
                    formMethods.setValue(name, value + 1, { shouldDirty: true, shouldTouch: true });
                };

                const handleSubtract = () => {
                    if (value > 1) {
                        formMethods.setValue(name, value - 1, { shouldDirty: true, shouldTouch: true });
                    }
                };

                const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange({
                        target: {
                            name,
                            value: Number(e.target.value) > 0 ? Number(e.target.value) : 1,
                        },
                    });
                };

                return (
                    <MuiTextField
                        variant="outlined"
                        className={className}
                        id={name}
                        inputRef={ref}
                        name={name}
                        value={formMethods.getValues(name)}
                        onBlur={onBlur}
                        onChange={handleChange}
                        error={!!error}
                        helperText={error?.message}
                        InputProps={{
                            startAdornment: (
                                <Button disabled={rest.disabled} onClick={handleSubtract} startIcon={<Minus />} />
                            ),
                            endAdornment: <Button disabled={rest.disabled} onClick={handleAdd} startIcon={<Plus />} />,
                        }}
                        {...rest}
                    />
                );
            }}
        />
    );
};

const StyledCounter = styled(Counter)`
    width: 120px;

    .MuiFormHelperText-root {
        font-size: ${fontSizes.f14};
    }

    .MuiFilledInput-root {
        background-color: ${(props) => props.theme.palette.grey[300]};
    }

    .MuiInputBase-input {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
    }

    label,
    input {
        font-size: ${fontSizes.f16};
    }

    button {
        padding: 0;
        min-width: auto;

        > span {
            margin: 0;
        }
    }
`;

export { StyledCounter as Counter };
