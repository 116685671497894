import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface ListSubmenuTabProps {
    currentTab: number;
    setCurrentTab: Dispatch<SetStateAction<number>>;
}

const ListSubmenuTabContext = createContext<ListSubmenuTabProps>({
    currentTab: 0,
    setCurrentTab: () => dispatchEvent,
});

const ListSubmenuTabContextProvider: FC<ListSubmenuTabProps> = ({ currentTab, setCurrentTab, children }) => {
    const value = useMemo(() => ({ currentTab, setCurrentTab }), [currentTab, setCurrentTab]);

    return <ListSubmenuTabContext.Provider value={value}>{children}</ListSubmenuTabContext.Provider>;
};

const useListSubmenuTab = () => useContext(ListSubmenuTabContext);

export { ListSubmenuTabContext, ListSubmenuTabContextProvider, useListSubmenuTab };
