import { useMutation } from '@apollo/client';

import { trackEvent } from 'analytics';
import {
    SourcerMemberStatusEnum,
    UpdateSourcerMembersMutation,
    UpdateSourcerMembersMutationVariables,
} from 'codegen/graphql';
import { logger } from 'logger';
import { SourcerMemberApprovalAction } from 'shared/enums';
import { UPDATE_SOURCER_MEMBERS } from 'shared/graphql/sourcer-members';

export const useApproveSourcerMembers = (refetchQueries: string[]) => {
    const [updateSourcerMembers] = useMutation<UpdateSourcerMembersMutation, UpdateSourcerMembersMutationVariables>(
        UPDATE_SOURCER_MEMBERS,
        {
            refetchQueries,
        }
    );

    const trackApprovedSourcerMembers = ({
        sourcerMemberIds,
        flow,
        reviewerId,
    }: {
        sourcerMemberIds: string[];
        flow: SourcerMemberApprovalAction;
        reviewerId?: string;
    }) => {
        for (const sourcerMember of sourcerMemberIds) {
            trackEvent('prospect_approved_client', {
                sourcer_member_id: sourcerMember,
                reviewer_id: reviewerId,
                flow,
            });
        }
    };

    const approveAndTrackSourcerMembers = async ({
        sourcerMemberIds,
        flow,
        reviewerId,
    }: {
        sourcerMemberIds: string[];
        flow: SourcerMemberApprovalAction;
        reviewerId?: string;
    }) => {
        try {
            const result = await updateSourcerMembers({
                variables: {
                    where: { id: { _in: sourcerMemberIds } },
                    set: {
                        reviewerId,
                        addedAt: Date.now(),
                        status: SourcerMemberStatusEnum.Accepted,
                    },
                },
            });

            const updatedSourcerMembers = result.data?.update_sourcer_members?.returning ?? [];

            for (const sourcerMember of updatedSourcerMembers) {
                trackEvent('prospect_approved_client', {
                    sourcer_member_id: sourcerMember.id,
                    reviewer_id: reviewerId,
                    flow,
                });
            }

            return result;
        } catch (error) {
            logger.error('Error updating sourcer members :', error);
            throw error;
        }
    };
    return { approveAndTrackSourcerMembers, trackApprovedSourcerMembers };
};
