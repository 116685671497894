import { css, useTheme } from '@mui/material';

import ReactQuill from 'shared/components/editor/react-quill-wrapper';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface EmailContentPreviewProps {
    content: string;
    fontColor?: string;
}

const EmailContentPreview: FC<EmailContentPreviewProps> = (props) => {
    const { content, fontColor } = props;
    const theme = useTheme();

    return (
        <ReactQuill
            value={content}
            onClearVariable={() => {}}
            onEditorTouched={() => {}}
            onEdited={() => {}}
            setFormValue={() => {}}
            isSubjectTouched={false}
            selectedVariable=""
            readOnly
            modules={{
                toolbar: false,
                clipboard: {
                    matchVisual: false,
                },
            }}
            css={css`
                .editor {
                    border: 0px !important;
                }
                .ql-editor {
                    font-size: ${fontSizes.f16};
                    padding: 0;
                    p {
                        color: ${fontColor ?? theme.palette.common.black};
                        a {
                            text-decoration: none;
                            pointer-events: none;
                            color: ${fontColor ?? theme.palette.common.black};
                        }
                        img {
                            display: none;
                        }
                    }
                }
                .ql-editor ol,
                .ql-editor ul {
                    display: block;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                }
                .ql-container {
                    background: transparent;
                    border: 0px !important;
                    padding: 0px;
                    max-height: 100%;
                    overflow: auto;
                }
            `}
        />
    );
};

export { EmailContentPreview };
