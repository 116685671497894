import { TeamRolesEnum } from 'codegen/graphql';
import { Invoices } from 'settings/components/presentational/plans-and-billing/invoices';
import { PaymentMethods } from 'settings/components/presentational/plans-and-billing/payment-methods';
import { PlansAndBilling } from 'settings/components/presentational/plans-and-billing/plans-and-billing';
import { SettingsPopover, SettingsPopoverProps } from 'settings/components/presentational/settings-popover';
import { FC, UserTeam } from 'shared/types';

interface PlansAndBillingPopoverProps extends SettingsPopoverProps {
    team: UserTeam;
    role: TeamRolesEnum;
}

const PlansAndBillingPopover: FC<PlansAndBillingPopoverProps> = ({ team, role, ...rest }) => (
    <SettingsPopover {...rest}>
        <PlansAndBilling team={team} />
        <PaymentMethods team={team} role={role} />
        <Invoices team={team} />
    </SettingsPopover>
);

export { PlansAndBillingPopover };
