import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedUnion: FC<SvgProps> = ({ theme, fill, ...rest }) => (
    <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M1.5 1.5C1.5 1.22386 1.27614 1 1 1C0.723858 1 0.5 1.22386 0.5 1.5V19.416V20.0081V20.416H14.7058L12.3774 22.7444C12.1821 22.9397 12.1821 23.2563 12.3774 23.4515C12.5726 23.6468 12.8892 23.6468 13.0845 23.4515L16.2664 20.2696C16.4617 20.0743 16.4617 19.7577 16.2664 19.5625L13.0845 16.3805C12.8892 16.1852 12.5726 16.1852 12.3774 16.3805C12.1821 16.5757 12.1821 16.8923 12.3774 17.0876L14.7058 19.416H1.5V1.5Z"
            fill={fill ?? theme?.palette.primary.main}
        />
        <path
            // eslint-disable-next-line max-len
            d="M0.5 20.416H0V20.916H0.5V20.416ZM14.7058 20.416L15.0593 20.7696L15.9129 19.916H14.7058V20.416ZM12.3774 22.7444L12.0238 22.3909L12.0238 22.3909L12.3774 22.7444ZM13.0845 23.4515L12.7309 23.098L12.7309 23.098L13.0845 23.4515ZM16.2664 20.2696L16.62 20.6231L16.2664 20.2696ZM16.2664 19.5625L16.62 19.2089L16.2664 19.5625ZM13.0845 16.3805L12.7309 16.734L13.0845 16.3805ZM12.3774 17.0876L12.0238 17.4411L12.0238 17.4411L12.3774 17.0876ZM14.7058 19.416V19.916H15.9129L15.0593 19.0625L14.7058 19.416ZM1.5 19.416H1V19.916H1.5V19.416ZM1 1.5H2C2 0.947715 1.55228 0.5 1 0.5V1.5ZM1 1.5V0.5C0.447715 0.5 0 0.947715 0 1.5H1ZM1 19.416V1.5H0V19.416H1ZM1 20.0081V19.416H0V20.0081H1ZM1 20.416V20.0081H0V20.416H1ZM14.7058 19.916H0.5V20.916H14.7058V19.916ZM12.7309 23.098L15.0593 20.7696L14.3522 20.0625L12.0238 22.3909L12.7309 23.098ZM12.7309 23.098V23.098L12.0238 22.3909C11.6333 22.7814 11.6333 23.4146 12.0238 23.8051L12.7309 23.098ZM12.7309 23.098H12.7309L12.0238 23.8051C12.4143 24.1956 13.0475 24.1956 13.438 23.8051L12.7309 23.098ZM15.9129 19.916L12.7309 23.098L13.438 23.8051L16.62 20.6231L15.9129 19.916ZM15.9129 19.916L16.62 20.6231C17.0105 20.2326 17.0105 19.5994 16.62 19.2089L15.9129 19.916ZM12.7309 16.734L15.9129 19.916L16.62 19.2089L13.438 16.0269L12.7309 16.734ZM12.7309 16.734H12.7309L13.438 16.0269C13.0475 15.6364 12.4143 15.6364 12.0238 16.0269L12.7309 16.734ZM12.7309 16.734L12.7309 16.734L12.0238 16.0269C11.6333 16.4175 11.6333 17.0506 12.0238 17.4411L12.7309 16.734ZM15.0593 19.0625L12.7309 16.734L12.0238 17.4411L14.3522 19.7696L15.0593 19.0625ZM1.5 19.916H14.7058V18.916H1.5V19.916ZM1 1.5V19.416H2V1.5H1Z"
            fill={fill ?? theme?.palette.primary.main}
        />
    </svg>
);

const Union = withTheme(ThemedUnion);

export { Union };
