import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from 'shared/components/presentational';
import { GoogleLogo } from 'shared/components/svgs';
import { useGoogleAuth } from 'shared/hooks/use-google-auth';
import { useSession } from 'shared/hooks/use-session';
import { createRestApiClient } from 'shared/services';
import { colors } from 'shared/settings';

interface GoogleAuthButtonProps {
    onAuth: () => void;
}

const GoogleAuthButton = ({ onAuth }: GoogleAuthButtonProps) => {
    const { setSession } = useSession();
    const { libInit, cookiesDisabled, requestGoogleAuthCode } = useGoogleAuth();
    const translate = useTranslations('login');
    const [inProgress, setInProgress] = useState(false);
    const restApiClient = createRestApiClient();

    // TODO: create a site banner hook that can be used to display a
    // rails like flash message when cookies are disabled

    const handleClick = async () => {
        setInProgress(true);
        requestGoogleAuthCode({
            callback: async ({ code }) => {
                if (!code) {
                    setInProgress(false);
                } else {
                    const result = await restApiClient.authenticate({ code });
                    setSession(result.data.session);
                    onAuth();
                }
            },
        });
    };

    return (
        <Button
            onClick={handleClick}
            disabled={!libInit || cookiesDisabled || inProgress}
            startIcon={<GoogleLogo />}
            variant="outlined"
            css={css`
                width: 316px;
                height: 40px;
                background-color: ${colors.neutrals.white};
                border: 1px solid ${colors.grays.miscka};
                border-radius: 2px;
                color: ${colors.neutrals.black};
            `}
        >
            {translate('sign-in-with-google')}
        </Button>
    );
};

export { GoogleAuthButton };
