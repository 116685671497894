import { ProspectMultipleEmail, ProspectNoEmail } from 'prospects';
import { useProspectPanel } from 'shared/hooks';
import { FC } from 'shared/types';

interface ProspectEmailProps {
    anchor: HTMLElement;
}

const ProspectEmail: FC<ProspectEmailProps> = (props) => {
    const { anchor } = props;
    const { contacts, prospectInfo } = useProspectPanel();

    const prospectEmail =
        (contacts || []).length > 0 ? (
            <ProspectMultipleEmail anchor={anchor} prospectId={prospectInfo?.id ?? ''} contacts={contacts ?? []} />
        ) : (
            <ProspectNoEmail />
        );

    return prospectEmail;
};

export { ProspectEmail };
