import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import {
    AutocompleteRenderInputParams,
    FormControl,
    Grow,
    Autocomplete as MuiAutoComplete,
    Paper,
    css,
    useTheme,
} from '@mui/material';
import { HTMLAttributes, forwardRef } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { ListSubheader, MenuItem, SelectOption } from 'shared/components/form';
import { TextField } from 'shared/components/presentational';
import { Chevron, Circle } from 'shared/components/svgs';
import { colors, fontSizes } from 'shared/settings';
import { FCProps } from 'shared/types';

export interface GroupedSelectOption extends SelectOption {
    group?: string;
}

interface AutoCompleteProps<Values extends FieldValues> {
    name: Path<Values>;
    formMethods?: UseFormReturn<Values>;
    width?: string;
    options: SelectOption[] | GroupedSelectOption[];
    value?: string;
    onChange: (value: string) => void;
    label?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    noOptionsText: string;
    grouped?: boolean;
    parentBackgroundColor?: 'white' | 'gray' | 'green';
    renderOption?: (
        renderOptionProps: HTMLAttributes<HTMLLIElement>,
        option: GroupedSelectOption
    ) => EmotionJSX.Element;
    renderInput?: (params: AutocompleteRenderInputParams, label?: string, value?: string) => React.ReactNode;
}

const PaperComponent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => (
    <Grow in>
        <Paper
            css={css`
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
            `}
            {...props}
            ref={ref}
        />
    </Grow>
));

const AutoCompleteContainer = <Values extends FieldValues>(props: AutoCompleteProps<Values> & FCProps) => {
    const {
        name,
        formMethods,
        className,
        label,
        options,
        onChange,
        value,
        width = '354px',
        fullWidth,
        disabled,
        noOptionsText,
        grouped = false,
        parentBackgroundColor = 'white',
        renderOption,
        renderInput,
        ...rest
    } = props;

    const theme = useTheme();

    const fieldState = formMethods?.getFieldState(name);
    const isInvalid: boolean = !!fieldState?.error;
    const errorMessage = fieldState?.error?.message;

    // custom hook to re-render the select component if field is being controlled by a form
    formMethods?.watch(name);

    const handleChange = (_event: any, newOption: GroupedSelectOption | null) => {
        if (newOption) {
            onChange(newOption.value);
        }
    };

    return (
        <FormControl
            fullWidth={fullWidth}
            className={className}
            css={css`
                ${!fullWidth && `width: ${width}`};
            `}
        >
            <MuiAutoComplete
                id={`${name}-auto-complete-input`}
                disabled={disabled}
                disableClearable
                autoComplete
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                getOptionLabel={(option) => option.label}
                value={options.find((option) => option.value === value) || { label: '', value: '' }}
                onChange={handleChange}
                renderInput={(params) =>
                    renderInput ? (
                        renderInput(params, label, value)
                    ) : (
                        <TextField {...params} label={label} value={value} {...rest} />
                    )
                }
                PaperComponent={PaperComponent}
                groupBy={(option: GroupedSelectOption) => (grouped ? option.group || '' : '')}
                renderGroup={(params) => (
                    <div key={params.key}>
                        <ListSubheader key={params.group}>{params.group}</ListSubheader>
                        {params.children}
                    </div>
                )}
                renderOption={(renderOptionProps, option) =>
                    renderOption ? (
                        renderOption(renderOptionProps, option)
                    ) : (
                        <MenuItem
                            {...renderOptionProps}
                            key={renderOptionProps.id}
                            style={{
                                fontSize: '16px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                                textTransform: 'none',
                                color: theme.palette.common.black,
                            }}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.value === value && (
                                <Circle
                                    css={css`
                                        margin-right: 10px;
                                    `}
                                />
                            )}
                            {option.label}
                        </MenuItem>
                    )
                }
                popupIcon={
                    <Chevron
                        css={css`
                            top: 32% !important;
                            z-index: 99;
                        `}
                        stroke={disabled ? 'rgba(0, 0, 0, 0.26)' : undefined}
                    />
                }
                noOptionsText={
                    <span
                        css={css`
                            font-size: ${fontSizes.f16};
                        `}
                    >
                        {noOptionsText}
                    </span>
                }
                ListboxProps={{
                    style: {
                        maxHeight: '320px',
                        overflowY: 'auto',
                        padding: '0px',
                    },
                }}
                css={css`
                    .MuiInputLabel-root {
                        color: ${theme.palette.grey[200]};
                        &.Mui-disabled {
                            color: ${theme.palette.grey[200]};
                        }
                    }

                    .MuiFilledInput-root {
                        font-size: ${fontSizes.f16};
                        background-color: ${parentBackgroundColor === 'white'
                            ? theme.palette.grey[300]
                            : theme.palette.common.white};
                        border: 1px solid
                            ${parentBackgroundColor === 'green'
                                ? theme.palette.primary.contrastText
                                : theme.palette.grey[500]};
                        border-radius: 4px;

                        &:hover:not(.Mui-focused, .Mui-disabled) {
                            background-color: ${parentBackgroundColor === 'white'
                                ? theme.palette.grey[300]
                                : theme.palette.common.white};
                            border-color: ${parentBackgroundColor === 'green'
                                ? theme.palette.primary.dark
                                : theme.palette.grey[100]};
                        }

                        &.Mui-focused {
                            background-color: ${parentBackgroundColor === 'white'
                                ? theme.palette.grey[300]
                                : theme.palette.common.white};
                            border-color: ${theme.palette.grey[500]};
                        }

                        &.Mui-disabled {
                            color: ${theme.palette.grey[600]};
                            border-color: ${theme.palette.grey[500]};
                        }
                    }

                    .MuiOutlinedInput-root {
                        background-color: ${parentBackgroundColor === 'white'
                            ? theme.palette.grey[300]
                            : theme.palette.common.white};
                        .MuiOutlinedInput-notchedOutline {
                            border: 1px solid
                                ${parentBackgroundColor === 'green'
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.grey[500]};
                            border-radius: 4px;
                        }

                        &:hover:not(.Mui-focused, .Mui-disabled) {
                            background-color: ${parentBackgroundColor === 'white'
                                ? theme.palette.grey[300]
                                : theme.palette.common.white};
                            .MuiOutlinedInput-notchedOutline {
                                border-color: ${parentBackgroundColor === 'green'
                                    ? theme.palette.primary.dark
                                    : theme.palette.grey[100]};
                            }
                        }

                        &.Mui-focused {
                            background-color: ${parentBackgroundColor === 'white'
                                ? theme.palette.grey[300]
                                : theme.palette.common.white};
                            .MuiOutlinedInput-notchedOutline {
                                border-color: ${theme.palette.primary.main};
                            }
                        }

                        &.Mui-disabled {
                            .MuiOutlinedInput-notchedOutline {
                                border-color: ${theme.palette.grey[500]};
                            }
                        }
                    }
                `}
            />
            {isInvalid && <Error>{errorMessage}</Error>}
        </FormControl>
    );
};

const AutoComplete = styled(AutoCompleteContainer)`
    margin-bottom: 8px;
` as typeof AutoCompleteContainer;

const Error = styled.p`
    color: ${colors.reds.persianRed};
    font-size: ${fontSizes.f14};
    margin-top: 4px;
    margin-left: 15px;
`;

export { AutoComplete };
export type { AutoCompleteProps };
