import { useLazyQuery, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import {
    AddOrUpdateProspectMutation,
    AddOrUpdateProspectMutationVariables,
    ProjectMembersAndProspectsQuery,
    ProjectMembersAndProspectsQueryVariables,
    ProspectsQuery,
    ProspectsQueryVariables,
} from 'codegen/graphql';
import { SelectProjectModal } from 'shared/components/modals';
import { NoProspectsModal } from 'shared/components/presentational';
import { AppFlow } from 'shared/enums';
import { GET_PROJECT_MEMBERS_AND_PROSPECTS, GET_PROJECT_MEMBERS_BY_PROJECT } from 'shared/graphql/project-members';
import { GET_PROJECT_MEMBER_ACTIVITIES } from 'shared/graphql/project-members-activities';
import { ADD_OR_UPDATE_PROSPECT, GET_PROSPECTS } from 'shared/graphql/prospects';
import { useCreateProjectMembers, useSnackbarAlert } from 'shared/hooks';
import { FC } from 'shared/types';

interface AddMultipleProspectsToProjectProps {
    prospectIds: string[];
    sourceProjectId?: string;
    open: boolean;
    onClose: () => void;
}

const AddMultipleProspectsToProject: FC<AddMultipleProspectsToProjectProps> = ({
    open,
    onClose,
    prospectIds,
    sourceProjectId,
}) => {
    const translate = useTranslations('project.prospect-list-table.edit-bar.add-to-project-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [getProspectsQuery] = useLazyQuery<ProspectsQuery, ProspectsQueryVariables>(GET_PROSPECTS);

    const [getProjectMembersQuery] = useLazyQuery<
        ProjectMembersAndProspectsQuery,
        ProjectMembersAndProspectsQueryVariables
    >(GET_PROJECT_MEMBERS_AND_PROSPECTS);

    const [addOrUpdateProspectData] = useMutation<AddOrUpdateProspectMutation, AddOrUpdateProspectMutationVariables>(
        ADD_OR_UPDATE_PROSPECT
    );

    const { createAndTrackProjectMembers } = useCreateProjectMembers([
        getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
        getOperationName(GET_PROJECT_MEMBER_ACTIVITIES) as string,
    ]);

    const [loading, setLoading] = useState(false);

    const handleAddToProject = async (project: { id: string; title: string }) => {
        if (!loading) setLoading(true);
        // find or create prospects for this user
        const { data: prospectsData } = await getProspectsQuery({
            variables: { where: { id: { _in: prospectIds } }, withProfile: true, limit: prospectIds.length },
        });

        const prospects = prospectsData?.prospects ?? [];

        const prospectUrls = prospects.map((prospect) => prospect.urls![0].url);

        const { data: projectMembersData } = await getProjectMembersQuery({
            variables: {
                where: { projectId: { _eq: project.id }, prospect: { urls: { url: { _in: prospectUrls } } } },
            },
        });

        const projectMembersUrls =
            projectMembersData?.project_members?.map((projectMember) => projectMember.prospect!.urls![0].url) ?? [];

        // filter out prospects that are already in the project
        const prospectsToAdd = prospects.filter((prospect) => !projectMembersUrls.includes(prospect.urls![0].url));

        const addProspectInputObjects = prospectsToAdd.map((prospect) => {
            const url = prospect.urls?.[0].url;
            const { profile } = prospect;
            return { url, profile };
        });
        const addProspectRes = await addOrUpdateProspectData({ variables: { data: addProspectInputObjects } });

        const userProspectIds = addProspectRes.data?.add_prospect.map((prospect) => prospect.id) ?? [];
        const projectMembers = userProspectIds.map((prospectId) => {
            const newProjectMember = {
                prospectId,
                projectId: project.id,
            };
            return newProjectMember;
        });
        const res = await createAndTrackProjectMembers(projectMembers, AppFlow.OtherPartOfWebapp);

        if (res.data) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('add-success-alert', {
                    numberOfProspects: Math.min(
                        Number(res.data.insert_project_members?.affected_rows),
                        prospectIds.length
                    ),
                    projectTitle: project.title,
                }),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('add-error-alert'),
            });
        }
        setLoading(false);
        onClose();
    };

    return prospectIds.length > 0 ? (
        <SelectProjectModal
            open={open}
            onClose={onClose}
            onConfirm={handleAddToProject}
            buttonLabel={translate('add-button-label', { count: prospectIds.length })}
            loading={loading}
            {...(sourceProjectId && { projectsToExcludeFromDropdown: [sourceProjectId] })}
        />
    ) : (
        <NoProspectsModal open={open} onClose={onClose} />
    );
};

export { AddMultipleProspectsToProject };
