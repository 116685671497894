import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface RequestSourcerSpeedFormButtonsProps {
    onGoToPreviousStep: () => void;
}

const RequestSourcerSpeedFormButtons: FC<RequestSourcerSpeedFormButtonsProps> = (props) => {
    const { onGoToPreviousStep } = props;
    const translate = useTranslations('sourcing');

    return (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;
            `}
        >
            <Button onClick={onGoToPreviousStep} variant="outlined">
                {translate('request-a-sourcer-form.back-button')}
            </Button>
            <Box>
                <Button type="submit" variant="contained">
                    {translate('request-a-sourcer-form.next-button')}
                </Button>
            </Box>
        </Box>
    );
};

export { RequestSourcerSpeedFormButtons };
