import { css } from '@mui/material';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { MenuItem, Select, SelectOption } from 'shared/components/form';
import { Button, Modal, ModalCloseButton, TextField, Title } from 'shared/components/presentational';
import { Circle, Plus } from 'shared/components/svgs';
import { isEmailValid } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ReportWrongEmailMultipleContactsProps {
    onReport: (email: string) => void;
    options: SelectOption[];
    reportedEmail: string;
    onClose: () => void;
    open: boolean;
}

const ReportWrongEmailMultipleContacts: FC<ReportWrongEmailMultipleContactsProps> = (props) => {
    const { onClose, open, reportedEmail, options, onReport } = props;
    const translate = useTranslations('sequence.recipient-list-table.report-wrong-email-modal');

    const [email, setEmail] = useState<string>('');
    const [selected, setSelected] = useState<string>();
    const [addManual, setAddManual] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);

    useEffect(() => {
        if (options.length > 0 && open) {
            const firstOption = options.filter((f) => !f.disabled)[0];
            setSelected(firstOption?.value);
        }
    }, [options, open]);

    const toggleAddManual = () => setAddManual(!addManual);

    const reset = () => {
        setEmail('');
        setAddManual(false);
        setIsValid(true);
        setSelected(undefined);
    };

    const handleClickReport = () => {
        if (addManual && !isEmailValid(email)) {
            setIsValid(false);
            return;
        }

        onReport(addManual ? email : selected || '');
        reset();
        onClose();
    };

    const handleSelectedChange = (event: any) => {
        setSelected(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
        if (!isValid) {
            setIsValid(true);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleClickReport();
        }
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const menuOpts = options
        .map((o, index) => (
            <MenuItem key={`${o.value}-${index}`} value={o.value} disabled={o.disabled}>
                {o.value === selected && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {o.disabled ? translate('bounced-email', { email: o.label }) : o.label}
            </MenuItem>
        ))
        .concat(
            <MenuItem key="none-of-these-option-label" value="none-of-these-option-label">
                {translate('none-of-these-option-label')}
            </MenuItem>
        );

    const selectDropDown =
        addManual && menuOpts ? null : (
            <Select
                disableUnderline
                label={translate('new-primary-email-label')}
                name="new-primary-email"
                value={selected ?? ''}
                width="100%"
                onChange={handleSelectedChange}
                variant="filled"
            >
                {menuOpts}
                <Button variant="text" onClick={toggleAddManual} startIcon={<Plus />}>
                    {translate('manually-enter-email')}
                </Button>
            </Select>
        );

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('header')}
                </Title>
                <Box>
                    {translate.rich('description', {
                        email: reportedEmail,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        bold: (children) => (
                            <Box
                                component="span"
                                css={css`
                                    font-weight: ${fontWeights.bold};
                                    text-overflow: ellipsis;
                                `}
                            >
                                {children}
                            </Box>
                        ),
                    })}
                    <Box
                        css={css`
                            padding-top: ${spacing.space24px};
                            padding-bottom: ${spacing.space12px};
                        `}
                    />
                    <Box
                        css={css`
                            padding-bottom: ${spacing.space12px};
                        `}
                    >
                        {addManual ? translate('manually-enter-email') : translate('choose-primary-email')}
                        {selectDropDown}
                        <Box hidden={!addManual}>
                            <TextField
                                css={css`
                                    width: 100%;
                                `}
                                error={!isValid}
                                helperText={isValid ? null : translate('invalid-email')}
                                inputRef={(input) => input && input.focus()}
                                hidden={!addManual}
                                value={email}
                                onChange={handleEmailChange}
                                onKeyDown={handleKeyDown}
                                label={translate('new-email-optional-label')}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleClickReport}>
                        {translate('report-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ReportWrongEmailMultipleContacts };
