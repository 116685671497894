const appRoutes = {
    home: { index: (): string => `/` },
    login: { index: (): string => '/login' },
    projects: {
        create: {
            index: (): string => '/projects/create',
        },
        index: (id?: string) => `/projects${id ? `/${id}` : ''}`,
    },
    sequences: {
        create: {
            index: (): string => '/sequences/create',
            settings: { index: (): string => '/sequences/create/settings' },
        },
        draft: {
            index: (id?: string): string => `/sequences/drafts${id ? `/${id}` : ''}`,
        },
        index: (id?: string): string => `/sequences${id ? `/${id}` : ''}`,
    },
    teamMetrics: {
        index: (): string => `/team-metrics`,
    },
    sourcing: {
        create: {
            index: (): string => '/sourcing/create',
            'case-not-supported': { index: (): string => '/sourcing/create/case-not-supported' },
            'no-pro': { index: (): string => '/sourcing/create/no-pro' },
        },
        index: (id?: string): string => `/sourcing${id ? `/${id}` : ''}`,
    },
    reviewer: {
        index: (id?: string): string => `/reviewer${id ? `/${id}` : ''}`,
    },
    reports: {
        index: (): string => `/reports`,
        activityByStatus: (): string => `/reports/activity-by-status`,
        diversity: (): string => `/reports/diversity`,
        sendingVolume: (): string => `/reports/sending-volume`,
    },
    'cs-admin': {
        sourcing: {
            index: (id?: string): string => `/cs-admin/sourcing${id ? `/${id}` : ''}`,
        },
    },
    searchProspects: {
        index: (): string => `/search-prospects`,
    },
};

export { appRoutes };
