import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from 'shared/components/presentational/button';
import { GoogleLogo } from 'shared/components/svgs';
import { useGoogleAuth } from 'shared/hooks/use-google-auth';
import { AddEmailAccountResponse, createRestApiClient } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const gmailScope = 'https://www.googleapis.com/auth/gmail.modify';

interface AddGoogleEmailButtonProps {
    onEmailAdd: (response: AddEmailAccountResponse) => void;
}

const AddGoogleEmailButton: FC<AddGoogleEmailButtonProps> = ({ onEmailAdd }) => {
    const translate = useTranslations('settings.email-settings');
    const theme = useTheme();
    const { libInit, cookiesDisabled, requestGoogleAuthCode } = useGoogleAuth();
    const [inProgress, setInProgress] = useState(false);
    const restApiClient = createRestApiClient();

    // TODO: create a site banner hook that can be used to display a
    // rails like flash message when cookies are disabled

    const handleClick = async () => {
        setInProgress(true);
        requestGoogleAuthCode({
            callback: async ({ code }) => {
                const response = await restApiClient.addEmailAccount({ code });
                setInProgress(false);
                if (response.data) {
                    onEmailAdd(response);
                } else {
                    onEmailAdd({ data: {}, success: false });
                }
            },
            scope: gmailScope,
        });
    };

    return (
        <Button
            onClick={handleClick}
            disabled={!libInit || cookiesDisabled || inProgress}
            css={css`
                color: ${theme.palette.grey[200]};
                font-size: ${fontSizes.f14};
                border: 1px solid ${theme.palette.grey[100]};
                border-radius: 2px;
                background-color: ${theme.palette.common.white};
                padding: ${spacing.space8px} ${spacing.space32px} ${spacing.space8px} ${spacing.space16px};

                &:hover {
                    background-color: ${theme.palette.grey[300]};
                }
            `}
            startIcon={
                <GoogleLogo
                    css={css`
                        margin-right: ${spacing.space8px};
                    `}
                />
            }
        >
            {translate('sign-in-with-google-button-label')}
        </Button>
    );
};

export { AddGoogleEmailButton };
