import { gql } from '@apollo/client';

export const PROJECT_MEMBER_ACTIVITY = gql`
    fragment ProjectMemberActivity on project_member_activities {
        id
        type
        prospect {
            profile {
                fullName
                fullNameCustomized
            }
        }
        project {
            id
            title
            userId
            collaborators {
                id
                userId
            }
        }
        sequence {
            id
            title
        }
        createdAt
        user {
            id
            fullName
        }
        reviewer {
            id
            fullName
        }
    }
`;

export const GET_PROJECT_MEMBER_ACTIVITIES = gql`
    query ProjectMemberActivities(
        $where: project_member_activities_bool_exp = {}
        $limit: Int = 2000
        $offset: Int = 0
        $order_by: [project_member_activities_order_by!] = { createdAt: desc }
    ) {
        project_member_activities(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
            ...ProjectMemberActivity
        }
    }
    ${PROJECT_MEMBER_ACTIVITY}
`;

export const CREATE_PROJECT_MEMBER_ACTIVITIES = gql`
    mutation CreateProjectMemberActivities($projectMembersActivities: [project_member_activities_insert_input!]!) {
        insert_project_member_activities(objects: $projectMembersActivities) {
            affected_rows
        }
    }
`;
