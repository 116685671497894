import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { SourcerMemberStatusEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectAlreadyReviewedModalProps {
    open: boolean;
    onClose: () => void;
    sourcerMember: {
        id: string;
        status: SourcerMemberStatusEnum;
        reviewer?:
            | {
                  id: string;
                  fullName: string;
              }
            | null
            | undefined;
    };
}

const ProspectAlreadyReviewedModal: FC<ProspectAlreadyReviewedModalProps> = (props) => {
    const { open, onClose, sourcerMember } = props;
    const translate = useTranslations('sourcing.modals.prospect-already-reviewed-modal');
    const reviewerName = sourcerMember.reviewer?.fullName;
    const reviewAction =
        sourcerMember.status === SourcerMemberStatusEnum.Accepted
            ? 'approved'
            : sourcerMember.status === SourcerMemberStatusEnum.Rejected
            ? 'rejected'
            : 'snoozed';

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    position: relative;
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={onClose} />

                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title', { reviewerName, reviewAction })}
                </Title>

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space32px};
                    `}
                >
                    <Button variant="contained" onClick={onClose}>
                        {translate('confirm-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ProspectAlreadyReviewedModal };
