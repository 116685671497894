import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { getStartAndEndOfTargetPeriod } from 'hireflow-shared/lib/time';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { HireflowFunctionInterfacesSequenceDetailStats, SequenceDetailStatsByTimePeriodQuery } from 'codegen/graphql';
import {
    interestedPercentageThresholds,
    openPercentageThresholds,
    repliesPercentageThresholds,
    statsOptions,
} from 'sequences/settings';
import { Box } from 'shared/components/containers';
import { Select } from 'shared/components/form';
import { Hyperlink, StatsCard } from 'shared/components/presentational';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import { GET_SEQUENCE_DETAIL_STATS_BY_TIME_PERIOD, SequenceData } from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface DetailsStatsProps {
    sequence: SequenceData;
    onStatsFilterChange: (values: string) => void;
}

const sequenceDetailDataToStats = (sequenceStats: HireflowFunctionInterfacesSequenceDetailStats[]) => {
    if (sequenceStats.length > 0) {
        const all = sequenceStats.find((s) => s.stageIndex === null);
        const sortedStages = sequenceStats
            .filter((s) => s.stageIndex !== null)
            .sort((a, b) => a.stageIndex - b.stageIndex);
        const open = {
            title: all!.opensPercentage!,
            description: `${all!.opens!} Open`,
            status:
                all!.opensPercentage! >= openPercentageThresholds.green
                    ? 'green'
                    : all!.opensPercentage! >= openPercentageThresholds.yellow
                    ? 'yellow'
                    : 'red',
            stages: sortedStages.map((s) => s.opensPercentage!),
            type: 'opened',
        };
        const replies = {
            title: all!.repliesPercentage!,
            description: `${all!.replies!} Replies`,
            status:
                all!.repliesPercentage! >= repliesPercentageThresholds.green
                    ? 'green'
                    : all!.repliesPercentage! >= repliesPercentageThresholds.yellow
                    ? 'yellow'
                    : 'red',
            stages: sortedStages.map((s) => s.repliesPercentage!),
            type: 'replied',
        };
        const interested = {
            title: all!.interestedPercentage!,
            description: `${all!.interested!} Interested`,
            status:
                all!.interestedPercentage! >= interestedPercentageThresholds.green
                    ? 'green'
                    : all!.interestedPercentage! >= interestedPercentageThresholds.yellow
                    ? 'yellow'
                    : 'red',
            stages: sortedStages.map((s) => s.interestedPercentage!),
            type: 'interested',
        };
        const stats = [open, replies, interested];
        return stats;
    }
    const open = {
        title: 0,
        description: `0 Open`,
        status: 'neutral',
        // eslint-disable-next-line no-magic-numbers
        stages: [0, 0, 0],
        type: 'opened',
    };
    const replies = {
        title: 0,
        description: `0 Replies`,
        status: 'neutral',
        // eslint-disable-next-line no-magic-numbers
        stages: [0, 0, 0],
        type: 'replied',
    };
    const interested = {
        title: 0,
        description: `0 Interested`,
        status: 'neutral',
        // eslint-disable-next-line no-magic-numbers
        stages: [0, 0, 0],
        type: 'interested',
    };
    const stats = [open, replies, interested];
    return stats;
};

const DetailsStats: FC<DetailsStatsProps> = ({ sequence, onStatsFilterChange }) => {
    const translate = useTranslations('sequence');
    const { session } = useSession();

    const [isStatsOpen, setIsStatsOpen] = useState(false);
    const [startTime, setStartTime] = useState(sequence.createdAt);
    const [endTime, setEndTime] = useState(Date.now());
    const theme = useTheme();

    const { data: sequenceStats } = useQuery<SequenceDetailStatsByTimePeriodQuery>(
        GET_SEQUENCE_DETAIL_STATS_BY_TIME_PERIOD,
        {
            variables: {
                sequence: sequence.id,
                start_time: startTime,
                end_time: endTime,
            },
        }
    );

    const formMethods = useForm<{ option: string }>({
        defaultValues: {
            option: 'all_time',
        },
    });

    const handleBreakdownClick = () => {
        setIsStatsOpen(!isStatsOpen);
    };

    const handleTimeChange = (event: any) => {
        const t = event.target.value as string;

        if (t === 'all_time') {
            setStartTime(sequence.createdAt);
            setEndTime(Date.now());
        } else {
            const targetPeriod =
                t === 'yesterday' ? 'yesterday' : t === 'last_week' ? 'last-week-by-week' : 'last-month-by-month';
            const { from, to } = getStartAndEndOfTargetPeriod(targetPeriod, session!.user.primaryTimezone);
            setStartTime(from);
            setEndTime(to);
        }
        formMethods.setValue('option', t);
    };

    if (sequenceStats?.stats) {
        const stats = sequenceDetailDataToStats(sequenceStats.stats);
        return (
            <Box
                css={css`
                    margin: ${spacing.space8px} 0;
                    display: flex;

                    max-width: ${appMaxWidth};
                    overflow-x: scroll;

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        margin-right: 20px;
                    `}
                >
                    <Select
                        isDefault
                        name="option"
                        formMethods={formMethods}
                        value={formMethods.getValues('option')}
                        width="244px"
                        options={statsOptions(translate)}
                        onChange={handleTimeChange}
                        css={css`
                            .MuiOutlinedInput-notchedOutline {
                                background-color: ${theme.palette.common.white};
                            }
                        `}
                    />
                    <Hyperlink href="#" onClick={handleBreakdownClick}>
                        {isStatsOpen
                            ? translate('details.hide-the-breakdown')
                            : translate('details.breakdown-by-stages')}
                    </Hyperlink>
                </Box>

                <Box
                    css={css`
                        flex-grow: 1;
                        display: flex;
                        gap: ${spacing.space8px};
                    `}
                >
                    {stats.map((data, index) => (
                        <StatsCard
                            status={data.status as any}
                            title={data.title}
                            description={data.description}
                            isStatsOpen={isStatsOpen}
                            key={`${data.title}-${index}`}
                            stages={data.stages}
                            parentIndex={index}
                            type={data.type}
                            onStatsFilterChange={onStatsFilterChange}
                        />
                    ))}
                </Box>
            </Box>
        );
    }
    return null;
};

export { DetailsStats };
