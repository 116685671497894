import styled from '@emotion/styled';
import { LinearProgress as MuiLinearProgress, LinearProgressProps as MuiLinearProgressProps } from '@mui/material';

import { useLoader } from 'shared/hooks';
import { FC } from 'shared/types';

interface LinearProgressProps extends MuiLinearProgressProps {}

const Loader: FC<LinearProgressProps> = (props) => {
    const { isLoading } = useLoader();

    if (!isLoading) return null;

    return <MuiLinearProgress color="primary" variant="indeterminate" {...props} />;
};

const StyledLoader = styled(Loader)`
    position: fixed;
    top: 0;
    width: 100%;
    height: 6px;
`;

export { StyledLoader as Loader };
