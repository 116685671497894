import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Button, ButtonProps } from 'shared/components/presentational/button';
import { Chevron } from 'shared/components/svgs';
import { fontSizes } from 'shared/settings';

interface FilterButtonProps extends ButtonProps {
    state?: 'default' | 'hover' | 'active';
    noChevron?: boolean;
}

const FilterButton = ({ state = 'default', children, noChevron = false, ...rest }: FilterButtonProps) => {
    const theme = useTheme();
    return (
        <Button
            variant={state === 'default' ? 'outlined' : 'contained'}
            css={css`
                height: 36px;
                display: flex;
                border-radius: 44px;
                white-space: nowrap;
                padding: 0px 12px 0px 16px;
                border: 1px solid ${theme.palette.primary.main};
                background-color: ${theme.palette.common.white};
                ${state === 'hover' &&
                `
                color: ${theme.palette.primary.main};
                background-color: ${theme.palette.primary.contrastText};
                `};
                ${state === 'active' &&
                `
                color: ${theme.palette.common.white};
                background-color: ${theme.palette.primary.main};
                `};
            `}
            {...rest}
        >
            <Box
                css={css`
                    margin-right: 4px;
                    font-size: ${fontSizes.f14};
                `}
            >
                {children}
            </Box>
            {noChevron ? null : (
                <Chevron
                    css={css`
                        min-width: 24px;
                    `}
                    stroke={state === 'active' ? theme.palette.common.white : theme.palette.primary.main}
                />
            )}
        </Button>
    );
};

export { FilterButton };
