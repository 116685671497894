import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    FormControl,
    InputLabel,
    ListSubheader as MuiListSubheader,
    MenuItem as MuiMenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { Chevron, Circle } from 'shared/components/svgs';
import { colors, fontSizes, fontWeights } from 'shared/settings';
import { FCProps } from 'shared/types';

interface SelectOption {
    value: string;
    label: string;
    disabled?: boolean;
}

interface SelectProps<Values extends FieldValues> extends MuiSelectProps {
    name: Path<Values>;
    formMethods?: UseFormReturn<Values>;
    width?: string;
    isDefault?: boolean; // No list sub-header in MuiSelect
    parentBackgroundColor?: 'white' | 'gray' | 'green';
    options?: SelectOption[];
    value: string | string[];
}

const SelectContainer = <Values extends FieldValues>(props: SelectProps<Values> & FCProps) => {
    const {
        className,
        name,
        label,
        formMethods,
        children,
        isDefault,
        options,
        width = '354px',
        value,
        onChange,
        disabled,
        fullWidth,
        variant,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        parentBackgroundColor,
        ...rest
    } = props;

    const register = formMethods?.register(name);
    const fieldState = formMethods?.getFieldState(name);
    const errorMessage = fieldState?.error?.message;
    const isInvalid: boolean = !!fieldState?.error;

    // custom hook to re-render the select component if field is being controlled by a form
    formMethods?.watch(name);

    return (
        <FormControl
            fullWidth={fullWidth}
            className={className}
            css={css`
                ${!fullWidth && `width: ${width}`};
            `}
        >
            <InputLabel id={`${name}-select-input`}>{label}</InputLabel>
            <MuiSelect
                inputRef={register?.ref}
                variant={variant}
                {...(variant === 'filled' && { disableUnderline: true })}
                labelId={`${name}-select-input`}
                name={name}
                value={value}
                onBlur={register?.onBlur}
                onChange={onChange ?? register?.onChange}
                error={!!fieldState?.error}
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={(iconProps) => (
                    <Chevron
                        {...iconProps}
                        css={css`
                            top: 32% !important;
                            z-index: 99;
                        `}
                        stroke={disabled ? 'rgba(0, 0, 0, 0.26)' : undefined}
                    />
                )}
                MenuProps={{
                    MenuListProps: {
                        disablePadding: true,
                        style: {
                            maxHeight: '320px',
                            overflowY: 'auto',
                        },
                    },
                }}
                disabled={disabled}
                {...rest}
            >
                {isDefault
                    ? options?.map((option: SelectOption) => (
                          <MenuItem value={option.value} key={option.value} disabled={option.disabled}>
                              {option.value === value && (
                                  <Circle
                                      css={css`
                                          margin-right: 10px;
                                      `}
                                  />
                              )}
                              {option.label}
                          </MenuItem>
                      ))
                    : children}
            </MuiSelect>
            {isInvalid && <Error>{errorMessage}</Error>}
        </FormControl>
    );
};

const Select = styled(SelectContainer)`
    margin-bottom: 8px;

    .MuiFormHelperText-root {
        font-size: ${fontSizes.f14};
    }

    .MuiInputLabel-shrink,
    .MuiFormLabel-filled {
        top: 14px;
    }

    .MuiInputLabel-root {
        text-transform: uppercase;
        font-weight: ${fontWeights.medium};
        font-size: ${fontSizes.f12};
        color: ${(props) => props.theme.palette.grey[200]};
        &.Mui-focused {
            color: ${(props) => props.theme.palette.grey[200]};
        }
        &.Mui-disabled {
            color: ${(props) => props.theme.palette.grey[600]};
        }
    }

    .MuiSelect-select,
    label {
        font-size: ${fontSizes.f16};
    }

    .MuiSelect-select svg {
        display: none;
    }

    .MuiSelect-icon {
        ${({ isDefault, label }) =>
            isDefault &&
            label == null &&
            `
            top: 8px !important;
        `}
    }

    .MuiSelect-select {
        ${({ isDefault, label }) =>
            isDefault &&
            label == null &&
            `
            padding: 10px 32px 4px 10px;
            margin-top: 2px;
        `}
    }

    .MuiFilledInput-input {
        position: relative;
        left: 0px;
    }

    .MuiOutlinedInput-root {
        ${({ isDefault }) => !isDefault && ` padding-bottom: 0;`}
    }

    .MuiOutlinedInput-input {
        ${({ isDefault }) =>
            !isDefault &&
            `
        padding-top: 27px;
        padding-bottom: 10px;`}
        z-index: 99;
    }

    // background-color and border styles

    .MuiFilledInput-root {
        font-size: ${fontSizes.f16};
        background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
            parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
        border: 1px solid
            ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'green' ? theme.palette.primary.contrastText : theme.palette.grey[500]};
        border-radius: 4px;

        &:hover:not(.Mui-focused, .Mui-disabled) {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            border-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'green' ? theme.palette.primary.dark : theme.palette.grey[100]};
        }
        &.Mui-focused {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            border-color: ${({ theme }) => theme.palette.primary.main};
        }

        &.Mui-disabled {
            color: ${(props) => props.theme.palette.grey[600]};
            border-color: ${({ theme }) => theme.palette.grey[500]};
        }
    }

    .MuiOutlinedInput-root {
        background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
            parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid
                ${({ theme, parentBackgroundColor = 'white' }) =>
                    parentBackgroundColor === 'green' ? theme.palette.primary.contrastText : theme.palette.grey[500]};
            border-radius: 4px;
        }

        &:hover:not(.Mui-focused, .Mui-disabled) {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            .MuiOutlinedInput-notchedOutline {
                border-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                    parentBackgroundColor === 'green' ? theme.palette.primary.dark : theme.palette.grey[100]};
            }
        }
        &.Mui-focused {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            .MuiOutlinedInput-notchedOutline {
                border-color: ${({ theme }) => theme.palette.primary.main};
            }
        }

        &.Mui-disabled {
            color: ${(props) => props.theme.palette.grey[600]};
            .MuiOutlinedInput-notchedOutline {
                border-color: ${({ theme }) => theme.palette.grey[500]};
            }
        }
    }
` as typeof SelectContainer;

const ListSubheader = styled(MuiListSubheader)`
    text-transform: uppercase;
    font-size: ${fontSizes.f14};
    font-weight: ${fontWeights.semiBold};
`;

const MenuItem = styled(MuiMenuItem)`
    font-size: ${fontSizes.f16};
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const Error = styled.p`
    color: ${colors.reds.persianRed};
    font-size: ${fontSizes.f14};
    margin-top: 4px;
    margin-left: 15px;
`;

export { Select, ListSubheader, MenuItem };
export type { SelectProps, SelectOption };
