import { css } from '@mui/material';
import { standardizeUrl } from 'profile-parser';
import { useState } from 'react';

import { SourcerMemberStatusEnum, SourcerStatusEnum } from 'codegen/graphql';
import { ProspectAddTab } from 'prospects/prospect-add-tab';
import { SourcerReviewTab } from 'prospects/sourcer-review-tab';
import { useProspectPanel } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectOrSourcerAddTabProps {
    anchor: HTMLElement;
    hideProspectPanel: () => void;
}

const ProspectOrSourcerAddTab: FC<ProspectOrSourcerAddTabProps> = (props) => {
    const { anchor, hideProspectPanel } = props;
    const { prospectInfo, sourcerMembers } = useProspectPanel();
    const [showReview, setShowReview] = useState<boolean>(true);
    const url = prospectInfo?.urls?.[0]?.url;

    const sourcerMemberInReview =
        url && sourcerMembers
            ? sourcerMembers?.filter(
                  (f) =>
                      f.urls.some((u) => standardizeUrl(u.url) === standardizeUrl(url)) &&
                      [SourcerMemberStatusEnum.Snoozed, SourcerMemberStatusEnum.ToReview].includes(f.status) &&
                      f.sourcer.status === SourcerStatusEnum.Active
              )
            : [];

    /**
     * even if person was added to a project or a sequence we still want to show the review tab
     * if the person is in review in an active sourcer
     */
    const showReviewTab = showReview && sourcerMemberInReview.length > 0;

    const onClose = () => {
        setShowReview(false);
    };

    const handleConfirmAndClose = (action: SourcerMemberStatusEnum.Accepted | SourcerMemberStatusEnum.Rejected) => {
        // close the extension if the user rejected the prospect
        if (action === SourcerMemberStatusEnum.Rejected) {
            hideProspectPanel();
        }
        onClose();
    };

    return showReviewTab ? (
        <SourcerReviewTab
            onClose={onClose}
            handleConfirmAndClose={handleConfirmAndClose}
            sourcerMember={sourcerMemberInReview[0]}
        />
    ) : (
        <ProspectAddTab
            css={css`
                padding-bottom: ${spacing.none};
            `}
            anchor={anchor}
            handleShowSourcerReviewerTab={() => setShowReview(true)}
            sourcerMemberInReview={sourcerMemberInReview[0]}
        />
    );
};

export { ProspectOrSourcerAddTab };
