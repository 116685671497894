import { useTranslations } from 'next-intl';

import { CheckCircle, Nil } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ConnectedStatusProps {
    connected: boolean;
}

const ConnectedStatus: FC<ConnectedStatusProps> = ({ connected }) => {
    const translate = useTranslations('settings.account-settings');

    return connected ? (
        <>
            <CheckCircle />
            {translate('connected')}
        </>
    ) : (
        <>
            <Nil />
            {translate('no-connection-made-yet')}
        </>
    );
};

export { ConnectedStatus };
