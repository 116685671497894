import { NetworkStatus } from '@apollo/client';
import { css } from '@emotion/react';
import { escapeRegExp, isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import xor from 'lodash/xor';
import { useTranslations } from 'next-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    HireflowEnumsTeamFeaturesEnum,
    OrderBy,
    SourcerStatusEnum,
    SourcersBoolExp,
    SourcersOrderBy,
    SourcersQuery,
    SourcersQueryVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import {
    Button,
    Checkbox,
    FilterBarDescription,
    FilterButton,
    FilterTextField,
    Popover,
    Snackbar,
    TableHeaderEllipsisText,
} from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from 'shared/components/table';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import {
    GET_SOURCERS,
    SourcersProps,
    sourcersDefaultOrderBy,
    sourcersDefaultOrderByDirection,
    sourcersDefaultVariables,
} from 'shared/graphql/sourcers';
import { useQueryRefresh } from 'shared/hooks';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC, NestedKeys } from 'shared/types';
import { SourcerListEditBar, SourcerListRow, SourcerListTabs, SourcersOverViewBar } from 'sourcing/components/tables';
import { SourcerTab } from 'sourcing/types';

interface Header {
    label:
        | 'title-header'
        | 'sourcer-status-header'
        | 'added-header'
        | 'linked-project-header'
        | 'date-last-modified-header';
    field: SourcerData;
    width: string;
}
type SourcerData = NestedKeys<SourcersQuery['sourcers'][number]>;
type OrderByColumn = Header['label'];

const headers: Header[] = [
    { label: 'title-header', field: 'title', width: '33%' },
    { label: 'sourcer-status-header', field: 'member_stats.toReview', width: '14%' },
    { label: 'linked-project-header', field: 'jobDescriptionUrl', width: '24%' },
    { label: 'date-last-modified-header', field: 'modifiedAt', width: '24%' },
];
// eslint-disable-next-line no-magic-numbers
const defaultRowsPerPageOptions = [10, 25, 50, 100];
const defaultOrderByDirection = OrderBy.Desc;

const statusValues = ['nothing-to-review', 'candidates-to-review-filter'] as const;
type StatusFilterValue = typeof statusValues[number];

const filterTextDebounceTime = 500; // ms

interface SourcerListTableProps extends SourcersProps {}

const SourcerListTable: FC<SourcerListTableProps> = ({ initialData }) => {
    const translate = useTranslations('sourcing.sourcing-list-table');

    const [offset, setOffset] = useState(sourcersDefaultVariables.offset);
    const [limit, setLimit] = useState(sourcersDefaultVariables.limit);

    const [statusPopoverAnchor, setStatusPopoverAnchor] = useState<HTMLButtonElement>();
    const [statusFilters, setStatusFilters] = useState<StatusFilterValue[]>([]);
    const isStatusFilterApplied = statusFilters.length > 0;

    const [sourcerStatusFilters, setSourcerStatusFilters] = useState<SourcerStatusEnum[]>([
        SourcerStatusEnum.Active,
        SourcerStatusEnum.Draft,
    ]);

    const [keywordFilter, setKeywordFilter] = useState('');
    const isKeywordFilterApplied = keywordFilter !== '';

    const areFiltersApplied = isStatusFilterApplied || isKeywordFilterApplied;

    const [textFilter, setTextFilter] = useState<any[]>([{}]);
    const debouncedSetTextFilter = useMemo(() => debounce(setTextFilter, filterTextDebounceTime), []);
    useEffect(() => {
        const regexText = `%${escapeRegExp(keywordFilter)}%`;
        debouncedSetTextFilter(
            isKeywordFilterApplied
                ? [
                      {
                          user: {
                              fullName: {
                                  _ilike: regexText,
                              },
                          },
                      },
                      {
                          title: {
                              _ilike: regexText,
                          },
                      },
                  ]
                : [{}]
        );
    }, [keywordFilter, isKeywordFilterApplied, debouncedSetTextFilter]);

    const [orderBy, setOrderBy] = useState<OrderByColumn>(
        headers.find((h) => h.field === sourcersDefaultOrderBy)?.label || 'date-last-modified-header'
    );
    const [orderByDirection, setOrderByDirection] = useState<OrderBy.Asc | OrderBy.Desc>(
        sourcersDefaultOrderByDirection
    );

    const convertHeaderFieldToOrderByFilter = useCallback(
        (field: SourcerData): SourcersOrderBy => {
            switch (field) {
                case 'member_stats.toReview':
                    return { member_stats_aggregate: { sum: { toReview: orderByDirection } } };
                case 'member_stats.added':
                    return { member_stats_aggregate: { sum: { added: orderByDirection } } };
                default:
                    return { [sourcersDefaultOrderBy]: orderByDirection };
            }
        },
        [orderByDirection]
    );

    const getSourcersVariables = useMemo(() => {
        const filters: SourcersBoolExp[] = [{ status: { _in: sourcerStatusFilters } }];
        if (isStatusFilterApplied) {
            filters.push({
                member_stats: {
                    toReview:
                        statusFilters.includes('nothing-to-review') &&
                        statusFilters.includes('candidates-to-review-filter')
                            ? {}
                            : statusFilters.includes('nothing-to-review')
                            ? { _eq: 0 }
                            : statusFilters.includes('candidates-to-review-filter')
                            ? { _gt: 0 }
                            : {},
                },
            });
        }

        if (isKeywordFilterApplied) {
            filters.push({ _or: textFilter });
        }

        return {
            ...sourcersDefaultVariables,
            orderBy: {
                ...convertHeaderFieldToOrderByFilter(headers.find((h) => h.label === orderBy)?.field),
            },
            offset,
            limit,
            where: {
                _and: filters,
            },
        };
    }, [
        convertHeaderFieldToOrderByFilter,
        isKeywordFilterApplied,
        isStatusFilterApplied,
        limit,
        offset,
        orderBy,
        sourcerStatusFilters,
        statusFilters,
        textFilter,
    ]);

    const { data, loading, networkStatus } = useQueryRefresh<SourcersQuery, SourcersQueryVariables>(GET_SOURCERS, {
        variables: getSourcersVariables,
        fetchPolicy: 'cache-and-network',
    });

    const count =
        networkStatus === NetworkStatus.loading
            ? initialData.count
            : data?.sourcers_aggregate.aggregate?.count
            ? data.sourcers_aggregate.aggregate.count
            : 0;

    const [selected, setSelected] = useState<Set<string>>(new Set());

    useEffect(() => {
        setOffset(0);
        setSelected(new Set());
    }, [statusFilters, textFilter, sourcerStatusFilters]);

    const handleSelectAll = async () => {
        const subset = new Set(data?.sourcers.map((s) => s.id));
        const intersectionSet = new Set([...selected].filter((x) => subset.has(x)));
        if (intersectionSet.size !== subset.size) {
            setSelected((prev) => new Set([...prev, ...subset]));
        } else {
            const differenceSet = new Set([...selected].filter((x) => !subset.has(x)));
            setSelected(differenceSet);
        }
    };

    const handleEditBarClose = () => {
        setSelected(new Set());
    };

    const handleToggleSelect = (checked: boolean, sourcerId: string) => {
        if (checked) {
            setSelected((prev) => new Set([...prev, sourcerId]));
        } else {
            setSelected((prev) => {
                const next = new Set(prev);
                next.delete(sourcerId);
                return next;
            });
        }
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setOffset(newPage * limit);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setOffset(0);
        setLimit(newRowsPerPage);
    };

    const createSortHandler = (col: OrderByColumn) => () => {
        const isDesc = orderBy === col && orderByDirection === OrderBy.Desc;
        const direction = isDesc ? OrderBy.Asc : OrderBy.Desc;
        setOrderByDirection(direction);
        setOrderBy(col);
    };

    const handleStatusFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setStatusFilters(statusFilters);
        setStatusPopoverAnchor(event.currentTarget);
    };

    const handleStatusPopoverClose = () => setStatusPopoverAnchor(undefined);

    const handleStatusCheckboxClick = (value: StatusFilterValue) => () => setStatusFilters(xor(statusFilters, [value]));

    const handleClearStatusFilter = () => setStatusFilters([]);

    const statusOptionCheckboxes = statusValues.map((value) => (
        <FormControlLabel
            key={value}
            checked={statusFilters.includes(value)}
            onChange={handleStatusCheckboxClick(value)}
            label={translate(value)}
            control={<Checkbox />}
            css={css`
                .MuiFormControlLabel-label {
                    font-size: ${fontSizes.f16};
                    margin-left: 16px;
                }
            `}
        />
    ));

    const statusFilter = (
        <>
            <FilterButton
                state={statusPopoverAnchor ? 'hover' : isStatusFilterApplied ? 'active' : 'default'}
                onClick={handleStatusFilterButtonClick}
            >
                {translate('status-filter-label')}
            </FilterButton>
            <Popover
                open={Boolean(statusPopoverAnchor)}
                anchorEl={statusPopoverAnchor}
                onClose={handleStatusPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                        width: 337px;
                    `}
                >
                    {statusOptionCheckboxes}
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={handleClearStatusFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );

    const handleKeywordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeywordFilter(newKeyword);
    };

    const handleClearAllFilters = () => {
        setStatusFilters([]);
        setKeywordFilter('');
    };

    const computeFilterDescription = () => {
        if (!areFiltersApplied) {
            let msg: string = '';
            // only show the count if not loading
            if (loading) {
                msg = translate('no-filters-applied-description');
            } else {
                msg = `${translate('no-filters-applied-description')}${translate('filter-description-count', {
                    count,
                })}`;
            }
            return (
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                    `}
                >
                    {msg}
                </Box>
            );
        }

        // eslint-disable-next-line react/no-unstable-nested-components
        const BoldSpan: FC<unknown> = ({ children }) => (
            <Box
                component="span"
                css={css`
                    font-weight: ${fontWeights.bold};
                    text-overflow: ellipsis;
                `}
            >
                {children}
            </Box>
        );
        const bold = (children: React.ReactNode) => <BoldSpan>{children}</BoldSpan>;

        const filters = [];
        if (isStatusFilterApplied) {
            filters.push(
                translate.rich('status-filter-description', {
                    statuses: statusFilters
                        .map((statusFilterSelected) => translate(statusFilterSelected))
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }

        if (isKeywordFilterApplied) {
            filters.push(
                translate.rich('keyword-filter-description', {
                    index: filters.length,
                    keyword: keywordFilter,
                    bold,
                })
            );
        }

        return (
            <Box
                css={css`
                    font-size: ${fontSizes.f14};
                    display: flex;
                `}
            >
                <FilterBarDescription>{filters}</FilterBarDescription>
                <Box
                    css={css`
                        white-space: pre;
                    `}
                >
                    {!loading ? translate('filter-description-count', { count }) : ''}
                </Box>
            </Box>
        );
    };

    const filterBar = (
        <Box>
            <Box
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: ${spacing.space8px};
                    gap: ${spacing.space8px};
                `}
            >
                {statusFilter}
                <FilterTextField value={keywordFilter} onChange={handleKeywordFilterChange} />
            </Box>
            <Box
                css={css`
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) min-content;
                    column-gap: ${spacing.space16px};
                    align-items: center;
                    width: 100%;
                    margin-bottom: 4px;
                `}
            >
                {computeFilterDescription()}
                <Button
                    css={css`
                        font-size: ${fontSizes.f14};
                        font-weight: ${fontWeights.bold};
                        white-space: nowrap;

                        margin-left: auto;
                        padding: 0;
                        opacity: 0;

                        transition: opacity 0.4s ease-in-out;
                        ${areFiltersApplied && `opacity: 1;`}
                    `}
                    variant="text"
                    startIcon={<XClose />}
                    onClick={handleClearAllFilters}
                    disableRipple
                >
                    {translate('clear-all-filters-label')}
                </Button>
            </Box>
        </Box>
    );

    const tableHeaders = (
        <TableRow>
            {headers.map(({ label, width }) => {
                let align: 'flex-start' | 'center' = 'flex-start';
                let left: string = 'inherit';
                if (label === 'added-header') {
                    align = 'center';
                    left = '1em';
                }
                return (
                    <TableCell key={label} width={width}>
                        <Box
                            css={css`
                                display: flex;
                                justify-content: ${align};
                            `}
                        >
                            {label === 'title-header' && (
                                <Checkbox
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                    indeterminate={selected.size > 0 && selected.size < count}
                                    checked={count > 0 && selected.size === count}
                                    onChange={handleSelectAll}
                                />
                            )}

                            <TableSortLabel
                                active={label === orderBy}
                                direction={label === orderBy ? orderByDirection : defaultOrderByDirection}
                                onClick={createSortHandler(label)}
                                css={css`
                                    flex-grow: 1;
                                    left: ${left};
                                    justify-content: ${align};
                                    white-space: nowrap;
                                `}
                            >
                                <TableHeaderEllipsisText>{translate(label)}</TableHeaderEllipsisText>
                            </TableSortLabel>
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );

    const tableBody = (networkStatus === NetworkStatus.loading ? initialData.data : data?.sourcers)?.map((sourcer) => (
        <SourcerListRow
            key={sourcer.id}
            sourcer={sourcer}
            isSelected={selected.has(sourcer.id)}
            onToggleSelect={handleToggleSelect}
        />
    ));

    const showTableBody: boolean | undefined = tableBody && tableBody.length > 0;

    const activeFilter = [SourcerStatusEnum.Active, SourcerStatusEnum.Draft];
    const archivedFilter = [SourcerStatusEnum.Archived];
    const draftFilter = [SourcerStatusEnum.Draft];
    const pausedFilter = [SourcerStatusEnum.Paused];
    const allFilters = [
        SourcerStatusEnum.Active,
        SourcerStatusEnum.Archived,
        SourcerStatusEnum.Draft,
        SourcerStatusEnum.Paused,
    ];

    const handleTabChange = (_: any, newValue: string) => {
        switch (newValue) {
            case SourcerStatusEnum.Active:
                setSourcerStatusFilters(activeFilter);
                break;
            case SourcerStatusEnum.Archived:
                setSourcerStatusFilters(archivedFilter);
                break;
            case SourcerStatusEnum.Draft:
                setSourcerStatusFilters(draftFilter);
                break;
            case SourcerStatusEnum.Paused:
                setSourcerStatusFilters(pausedFilter);
                break;
            case 'all':
                setSourcerStatusFilters(allFilters);
                break;
            default:
                break;
        }
    };

    let selectedTab: SourcerTab;
    if (isEqual(sourcerStatusFilters, activeFilter)) selectedTab = SourcerStatusEnum.Active;
    else if (isEqual(sourcerStatusFilters, archivedFilter)) selectedTab = SourcerStatusEnum.Archived;
    else if (isEqual(sourcerStatusFilters, draftFilter)) selectedTab = SourcerStatusEnum.Draft;
    else if (isEqual(sourcerStatusFilters, pausedFilter)) selectedTab = SourcerStatusEnum.Paused;
    else selectedTab = 'all';

    const selectedSourcers = data?.sourcers.filter((sourcer) => selected.has(sourcer.id)) ?? [];
    return (
        <>
            <SourcersOverViewBar
                showCreditInfo={
                    !initialData.teamFeaturesData.find(
                        ({ feature }) => feature === HireflowEnumsTeamFeaturesEnum.AiSourcingPayPerSourcer
                    )
                }
            />
            <SourcerListTabs selectedTab={selectedTab} onTabChange={handleTabChange} />
            {filterBar}
            <TableContainer
                css={css`
                    margin-bottom: 8px;
                    max-width: ${appMaxWidth};

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <Table>
                    <TableHead>{tableHeaders}</TableHead>
                    <TableBody
                        colSpan={headers.length}
                        hidden={!showTableBody}
                        loading={loading}
                        clearFilters={handleClearAllFilters}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                // @ts-expect-error
                component="div"
                page={offset / limit}
                rowsPerPage={limit}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar
                open={selected.size > 0}
                autoHideDuration={undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SourcerListEditBar sourcers={selectedSourcers} onClose={handleEditBarClose} tab={selectedTab} />
            </Snackbar>
        </>
    );
};

export { SourcerListTable };
export type { SourcerListTableProps };
