/* eslint-disable no-magic-numbers */
import { DateTime } from 'luxon';

const ordinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const timeFormatter = (date: number) => DateTime.fromMillis(date).toFormat('h:mm a');
export const dateFormatter = (date: number) => DateTime.fromMillis(date).toFormat('MMM dd, yyyy');
export const date2Formatter = (date: number) =>
    DateTime.fromMillis(date).toFormat('MMM ') + ordinal(DateTime.fromMillis(date).day);
export const relateDateFormatter = (date: number) =>
    relateDateWithBaseFormatter(DateTime.now(), DateTime.fromMillis(date));
// https://github.com/moment/luxon/issues/394 (date comparison doesn't work when going through month/year boundaries)
export const relateDateWithBaseFormatter = (base: DateTime, compare: DateTime) =>
    compare.toRelativeCalendar({ base, unit: 'days' });
export const dateTimeFormatter = (date: number) => DateTime.fromMillis(date).toFormat('MMM dd, yyyy • h:mm a');
// cspell:disable-next-line
export const stripeDateTimeFormatter = (date: number) => DateTime.fromSeconds(date).toFormat("MMMM d'th', yyyy");
