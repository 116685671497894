import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert as MuiAlert, SnackbarProps as MuiSnackbarProps, useTheme } from '@mui/material';

import { IconButton } from 'shared/components/presentational';
import { Snackbar } from 'shared/components/presentational/snackbar';
import { XClose } from 'shared/components/svgs';
import { useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SnackbarProps extends MuiSnackbarProps {
    onClose?: () => void;
}

const SnackbarAlert: FC<SnackbarProps> = (props) => {
    const { className, message: _message, ...rest } = props;
    const theme = useTheme();
    const { snackbarAlertContent, isSnackbarAlertOpen, hideSnackbarAlert } = useSnackbarAlert();

    return (
        <Snackbar
            css={css`
                z-index: 1301 !important;
            `}
            open={isSnackbarAlertOpen}
            className={className}
            onClose={hideSnackbarAlert}
            {...rest}
        >
            <MuiAlert
                {...{
                    variant: 'filled',
                    severity: snackbarAlertContent?.severity || 'success',
                    ...(snackbarAlertContent.startIcon && { icon: snackbarAlertContent.startIcon }),
                    ...(snackbarAlertContent.backgroundColor && {
                        style: {
                            backgroundColor: `${snackbarAlertContent.backgroundColor}`,
                        },
                    }),
                }}
            >
                <span>{snackbarAlertContent?.message}</span>
                <IconButton
                    css={css`
                        min-width: 22px;
                        padding: 0;
                        margin-left: ${spacing.space8px};

                        > span {
                            margin-right: 0;
                        }
                    `}
                    onClick={hideSnackbarAlert}
                >
                    <XClose stroke={theme.palette.common.white} />
                </IconButton>
            </MuiAlert>
        </Snackbar>
    );
};

const StyledSnackbar = styled(SnackbarAlert)`
    ${({ theme }) => `
        .MuiAlert-root {
            min-width: 483px;
        }

        .MuiAlert-message {
            color: ${theme.palette.common.white};
            font-size: ${fontSizes.f16};
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                width: 22px;
                height: 22px;
            }
        }

        .MuiAlert-icon {
            padding-top: 9px;
        }
    `}
`;

export type { SnackbarProps };
export { StyledSnackbar as SnackbarAlert };
