import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';

import {
    CreateTeamInvitesMutation,
    LicenseTypesEnum,
    PlanTypesEnum,
    TeamMemberEmailAccountsQuery,
    TeamMemberEmailAccountsQueryVariables,
    TeamRolesEnum,
} from 'codegen/graphql';
import { InviteAlertModal, UserEmailAccounts } from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { MenuItem, Select, SelectChip } from 'shared/components/form';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { GET_TEAM_MEMBERS_EMAIL_ACCOUNTS } from 'shared/graphql/email-accounts';
import { CREATE_TEAM_INVITES, GET_TEAM_INVITES } from 'shared/graphql/teams';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { isEmailValid } from 'shared/services';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface FormValues {
    emails: Array<{ label: string; value: string }>;
    value: TeamRolesEnum | LicenseTypesEnum;
}

const roleOptions = [
    {
        label: 'role-reviewer-label',
        value: LicenseTypesEnum.Reviewer,
    },
    {
        label: 'role-full-label',
        value: LicenseTypesEnum.Full,
    },
    {
        label: 'role-admin-label',
        value: TeamRolesEnum.Admin,
    },
] as const;

interface AddMemberModalProps {
    seatsCount: number;
    seatsUsed: number;
    open: boolean;
    onClose: () => void;
    planType: PlanTypesEnum;
}

const AddMemberModal: FC<AddMemberModalProps> = ({ open, onClose, seatsCount, seatsUsed, planType }) => {
    const theme = useTheme();
    const translate = useTranslations('settings.team-settings');

    const { session, loaded } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [availableSeats, setAvailableSeats] = useState(0);
    const [showInviteAlertModal, setShowInviteAlertModal] = useState(false);

    const [archivedMemberInvites, setArchivedMemberInvites] = useState<UserEmailAccounts[]>([]);
    const [existingTeamMembersInvites, setExistingTeamMembersInvites] = useState<UserEmailAccounts[]>([]);

    const [createTeamInvites] = useMutation<CreateTeamInvitesMutation>(CREATE_TEAM_INVITES, {
        refetchQueries: [getOperationName(GET_TEAM_INVITES) as string],
    });

    const { data, loading } = useQuery<TeamMemberEmailAccountsQuery, TeamMemberEmailAccountsQueryVariables>(
        GET_TEAM_MEMBERS_EMAIL_ACCOUNTS,
        {
            skip: !loaded,
            variables: { teamId: session!.user.teamId },
        }
    );

    const validationSchema = Yup.object().shape({
        emails: Yup.array()
            .min(1, translate('add-member-modal.provide-at-least-one-email'))
            .test(
                'invite-limit',
                () =>
                    planType === PlanTypesEnum.Solo
                        ? translate('add-member-modal.invalid-number-of-invites-solo', {
                              count: seatsCount - seatsUsed,
                          })
                        : translate('add-member-modal.invalid-number-of-invites', {
                              count: seatsCount - seatsUsed,
                          }),
                (value) => {
                    const role = formMethods.getValues('value');
                    if (role !== LicenseTypesEnum.Reviewer || planType === PlanTypesEnum.Solo) {
                        if (value && value.length > seatsCount - seatsUsed) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .of(
                Yup.object().shape({
                    label: Yup.string().email(),
                    value: Yup.string().email(),
                })
            ),
        value: Yup.string().required(),
    });

    const formMethods = useForm<FormValues>({
        mode: 'onTouched',
        defaultValues: { emails: [], value: LicenseTypesEnum.Reviewer },
        resolver: yupResolver(validationSchema),
    });

    const emailsList = useWatch({ control: formMethods.control, name: 'emails' });
    const selectedRole = useWatch({ control: formMethods.control, name: 'value' });

    useEffect(() => {
        const seatsInvited = selectedRole !== LicenseTypesEnum.Reviewer ? emailsList.length : 0;
        setAvailableSeats(seatsCount - seatsUsed - seatsInvited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailsList, selectedRole, seatsUsed]);

    const formatAddMemberLabel = (inputValue: string) => translate('add-member-label', { memberName: inputValue });

    const handleCancel = () => {
        formMethods.reset();
        onClose();
    };

    const handleAddMember = async () => {
        const emails = emailsList.map((e) => e.value);
        const role: TeamRolesEnum =
            planType === PlanTypesEnum.Solo
                ? TeamRolesEnum.User
                : selectedRole === TeamRolesEnum.Admin
                ? TeamRolesEnum.Admin
                : TeamRolesEnum.User;
        const licenseType: LicenseTypesEnum =
            planType === PlanTypesEnum.Solo
                ? LicenseTypesEnum.Full
                : selectedRole === LicenseTypesEnum.Reviewer
                ? LicenseTypesEnum.Reviewer
                : LicenseTypesEnum.Full;

        const existingArchivedTeamMembers =
            data?.email_accounts.filter(
                (member) => emails.includes(member.email) && member.user.licenseType === LicenseTypesEnum.Archived
            ) ?? [];

        const existingNonArchivedTeamMembers =
            data?.email_accounts.filter(
                (member) => emails.includes(member.email) && member.user.licenseType !== LicenseTypesEnum.Archived
            ) ?? [];

        if (existingArchivedTeamMembers.length > 0 || existingNonArchivedTeamMembers.length > 0) {
            setArchivedMemberInvites(
                existingArchivedTeamMembers.map((m) => ({
                    userId: m.user.id,
                    email: m.email,
                    fullName: m.user.fullName,
                }))
            );
            setExistingTeamMembersInvites(
                existingNonArchivedTeamMembers.map((m) => ({
                    userId: m.user.id,
                    email: m.email,
                    fullName: m.user.fullName,
                }))
            );
            setShowInviteAlertModal(true);
            return;
        }

        try {
            await createTeamInvites({
                variables: {
                    invites: emails.map((invitee) => ({
                        invitee,
                        role,
                        licenseType,
                    })),
                },
            });

            formMethods.reset();
            onClose();

            showSnackbarAlert({
                severity: 'success',
                message: translate('add-member-modal.member-added-successfully-alert', { emails: emails.join(', ') }),
            });
        } catch (e: any) {
            if (e?.message.includes('Uniqueness violation')) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('add-member-modal.invitations-already-sent'),
                });
            }
        }
    };

    const onErrors = (errors: any) => {
        showSnackbarAlert({
            severity: 'error',
            message: errors?.emails?.message,
        });
    };

    const handleInviteAlertModalClose = () => {
        const updatedEmails = emailsList.filter(
            (email) =>
                !existingTeamMembersInvites.map((m) => m.email).includes(email.value) &&
                !archivedMemberInvites.map((m) => m.email).includes(email.value)
        );

        formMethods.setValue('emails', updatedEmails, { shouldDirty: true, shouldValidate: true });
        setArchivedMemberInvites([]);
        setExistingTeamMembersInvites([]);
        setShowInviteAlertModal(false);
    };

    if (loading) {
        return null;
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    css={css`
                        padding: ${spacing.space24px};
                    `}
                >
                    <ModalCloseButton onClose={onClose} />
                    <Title
                        type="h3"
                        css={css`
                            font-size: ${fontSizes.f24};
                            margin: ${spacing.none};
                        `}
                    >
                        {translate('add-member-modal.send-an-invite')}
                    </Title>
                    <Box
                        css={css`
                            margin-top: ${spacing.space12px};
                        `}
                    >
                        <SelectChip
                            isCreatable
                            options={[]}
                            name="emails"
                            placeholder="Emails"
                            formMethods={formMethods}
                            formatCreateLabel={formatAddMemberLabel}
                            isValidNewOption={isEmailValid}
                            css={css`
                                z-index: 100;
                            `}
                        />

                        {[PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) && (
                            <Select
                                label={translate('add-member-modal.permission-level')}
                                name="value"
                                formMethods={formMethods}
                                value={formMethods.getValues('value')}
                                placeholder={translate('add-member-modal.select-level')}
                                css={css`
                                    width: 100%;
                                    margin-bottom: 0px;
                                    .MuiSelect-select {
                                        min-height: 38px;
                                        margin: 0;
                                        padding: 12px 18px;
                                        display: flex;
                                        align-items: center;
                                        font-family: ${fontFamilies.inter};
                                        font-style: normal;
                                        font-weight: ${fontWeights.normal};
                                        font-size: ${fontSizes.f16};
                                        line-height: 19px;
                                        color: ${theme.palette.common.black};
                                    }
                                    .MuiSelect-icon {
                                        right: 18px;
                                        top: 20px !important;
                                    }
                                `}
                            >
                                {roleOptions.map((role, index) => (
                                    <MenuItem
                                        value={role.value}
                                        disabled={availableSeats <= 0 && role.value !== LicenseTypesEnum.Reviewer}
                                        key={index}
                                        css={css`
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                        `}
                                    >
                                        <Box
                                            css={css`
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            {role.value === formMethods.getValues('value') && (
                                                <Circle
                                                    css={css`
                                                        margin-right: 10px;
                                                    `}
                                                />
                                            )}
                                            {translate(role.label)}
                                        </Box>
                                        <Box
                                            component="p"
                                            css={css`
                                                margin: 0;
                                                font-size: ${fontSizes.f12};
                                                line-height: 15px;
                                                color: ${theme.palette.common.black};
                                            `}
                                        >
                                            {role.value !== formMethods.getValues('value') &&
                                                role.value !== LicenseTypesEnum.Reviewer &&
                                                translate('seats-available', { availableSeats })}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </Box>

                    <Box
                        css={css`
                            display: flex;
                            margin-top: ${spacing.space24px};
                            justify-content: flex-end;
                        `}
                    >
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={handleCancel}
                            css={css`
                                margin-right: ${spacing.space16px};
                                height: 44px;
                            `}
                        >
                            {translate('cancel-button-label')}
                        </Button>
                        <Button
                            disableElevation
                            variant="contained"
                            css={css`
                                height: 44px;
                            `}
                            onClick={formMethods.handleSubmit(handleAddMember, onErrors)}
                        >
                            {translate('add-member-modal.send-invite-button-label')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <InviteAlertModal
                open={showInviteAlertModal}
                onClose={handleInviteAlertModalClose}
                archivedMembers={archivedMemberInvites}
                existingMembers={existingTeamMembersInvites}
            />
        </>
    );
};

export { AddMemberModal };
