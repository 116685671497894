import { css, useTheme } from '@mui/material';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { IconButton, TextField } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface TextFieldIconButtonProps {
    onButtonClick: (value: string) => void;
    icon: JSX.Element;
}

const TextFieldIconButton: FC<TextFieldIconButtonProps> = (props) => {
    const { onButtonClick, icon } = props;
    const [text, setText] = useState<string>('');
    const theme = useTheme();

    const handleIconClick = () => {
        onButtonClick(text);
        setText('');
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    return (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <TextField
                value={text}
                css={css`
                    width: 100%;
                    height: fit-content;
                    background-color: ${theme.palette.common.white};

                    .MuiFilledInput-root {
                        padding: 8px 12px !important;
                    }
                `}
                multiline
                maxRows={4}
                onChange={handleTextChange}
                inputRef={(input) => input && input.focus()}
                InputProps={{
                    endAdornment: <IconButton onClick={handleIconClick}>{icon}</IconButton>,
                }}
            />
        </Box>
    );
};

export { TextFieldIconButton };
