import { SelectOption } from 'shared/components/form';

export const amountPerDayOptions: SelectOption[] = Array.from(
    // eslint-disable-next-line no-magic-numbers
    { length: 100 / 5 + 1 },
    (_, index) => {
        // eslint-disable-next-line no-magic-numbers
        const value = index * 5;
        return {
            label: value.toString(),
            value: value.toString(),
        };
    }
);
