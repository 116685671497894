import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSession } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

const deniedPaths: string[] = ['/extension-action', '/extension-launcher', '/popup.html', '/login'];
const excludedPaths: string[] = [...deniedPaths, '/extension-panel'];

interface VersionContent {
    message: string;
    buttonText: string;
}

const VersionContext = createContext<{
    isVisible: boolean;
    content: VersionContent | undefined;
}>({
    isVisible: false,
    content: undefined,
});

const VersionProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const { loaded } = useSession();

    const [showUpdateBar, setShowUpdateBar] = useState<boolean>(false);
    const [content, _setContent] = useState<VersionContent>({
        message: 'A new version of the app is available, please refresh the page.',
        buttonText: 'Refresh',
    });
    const [currentVersion, setCurrentVersion] = useState<string | undefined>(undefined);

    const checkForDeployment = useCallback(async () => {
        // wait for the session to be loaded
        if (!loaded) return;

        // we don't want to overload the server with requests for instances that do not require the version check
        if (deniedPaths.includes(router.pathname)) return;

        const restApiClient = createRestApiClient();
        const response = await restApiClient.getVersion();

        if (!response.success || !response.data || !response.data.version) return;

        if (!currentVersion) {
            setCurrentVersion(response.data.version);
            return;
        }

        if (currentVersion === response.data.version) return;

        if (!excludedPaths.includes(router.pathname) && !showUpdateBar) {
            // Not Extension path
            setShowUpdateBar(true);
        } else if (router.pathname === '/extension-panel') {
            // we only want to reload the panel, do nothing for the other extension routes
            // reload for any extension users
            window.location.reload();
        }
    }, [router, loaded, currentVersion, setCurrentVersion, showUpdateBar, setShowUpdateBar]);

    useEffect(() => {
        checkForDeployment();
        // eslint-disable-next-line no-magic-numbers
        const intervalPeriod: number = 3 * 60 * 1000; // 3 mins
        const intervalTimeout = setInterval(() => {
            checkForDeployment();
        }, intervalPeriod);
        return () => {
            clearInterval(intervalTimeout);
        };
    }, [checkForDeployment]);

    const value = useMemo(
        () => ({
            isVisible: showUpdateBar,
            content,
        }),
        [showUpdateBar, content]
    );

    return <VersionContext.Provider value={value}>{children}</VersionContext.Provider>;
};

const useVersion = () => useContext(VersionContext);

export { VersionProvider, VersionContext, useVersion };
