import { gql } from '@apollo/client';

export const TEAM_PROFILE_DETAILS = gql`
    fragment TeamProfileDetails on team_profiles {
        teamId
        createdAt
        modifiedAt
        crunchbaseHandle
        externalUrl
        standardizedLinkedInUrl
    }
`;

export const CREATE_TEAM_PROFILE = gql`
    mutation CreateTeamProfile($team_profile: team_profiles_insert_input!) {
        inserted: insert_team_profiles_one(object: $team_profile) {
            teamId
        }
    }
`;

export const GET_TEAM_PROFILE_BY_TEAM_ID = gql`
    query TeamProfileByTeamId($teamId: String!) {
        data: team_profiles(where: { teamId: { _eq: $teamId } }) {
            ...TeamProfileDetails
        }
    }
    ${TEAM_PROFILE_DETAILS}
`;

export const UPDATE_TEAM_PROFILE = gql`
    mutation UpdateTeamProfile($teamId: String!, $set: team_profiles_set_input!) {
        update_team_profiles(where: { teamId: { _eq: $teamId } }, _set: $set) {
            returning {
                teamId
            }
        }
    }
`;
