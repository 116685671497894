import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLinkedinLogo: FC<SvgProps> = ({ fill, theme, strokeWidth, ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            strokeWidth={strokeWidth}
            // eslint-disable-next-line max-len
            d="M0.35193 6.96942C0.35193 6.09454 1.06116 5.38531 1.93604 5.38531C2.81092 5.38531 3.52015 6.09454 3.52015 6.96942L3.52015 14.4159C3.52015 15.2908 2.81092 16 1.93604 16C1.06116 16 0.351931 15.2908 0.351931 14.4159L0.35193 6.96942ZM1.91682 -1.53557e-06C2.4252 -1.58001e-06 2.91275 0.201453 3.27222 0.560047C3.63169 0.91864 3.83365 1.40499 3.83365 1.91212C3.83365 2.41925 3.63169 2.9056 3.27222 3.2642C2.91275 3.62279 2.4252 3.82426 1.91682 3.82426C1.40845 3.82426 0.920897 3.62279 0.561423 3.2642C0.201949 2.9056 2.11498e-07 2.41925 1.67163e-07 1.91212C1.22828e-07 1.40499 0.201949 0.91864 0.561423 0.560047C0.920897 0.201453 1.40845 -1.49113e-06 1.91682 -1.53557e-06ZM5.39854 6.9502C5.39854 6.08594 6.09916 5.38531 6.96343 5.38531L8.18202 5.38531C8.37328 5.38531 8.52832 5.54036 8.52832 5.73162C8.52832 6.07361 9.02964 6.25004 9.29882 6.03908C9.46581 5.9082 9.6446 5.7918 9.83334 5.69152C10.3587 5.41238 10.945 5.26682 11.5402 5.26772L12.4013 5.26772C13.3545 5.27198 14.2675 5.65168 14.9415 6.32419C15.6155 6.99669 15.996 7.90755 16 8.8585L16 14.4351C16 15.2994 15.2994 16 14.4351 16L13.8459 16C13.307 16 12.8702 15.5632 12.8702 15.0243L12.8702 10.2594C12.8309 9.04936 12.01 8.11205 10.7996 8.11205C9.58668 8.11205 8.56932 9.04937 8.53089 10.2585L8.53089 14.4287C8.53089 15.2937 7.82969 15.9949 6.96471 15.9949C6.09974 15.9949 5.39854 15.2937 5.39854 14.4287L5.39854 6.9502Z"
            fill={fill ?? theme?.palette?.primary?.main}
        />
    </svg>
);

const LinkedinLogo = withTheme(ThemedLinkedinLogo);

export { LinkedinLogo };
