import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import {
    EmailAccountsQuery,
    EmailAccountsQueryVariables,
    EmailAccountStatusEnum,
    LicenseTypesEnum,
    PlanTypesEnum,
    RemoveEmailAccountMutation,
    RemoveSendFromAccountMutation,
    RemoveSendFromAccountMutationVariables,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SendFromAccountStatusEnum,
    SequenceStatusEnum,
    UpdateEmailAccountMutation,
    UpdateSendFromAccountMutation,
    UpdateSendFromAccountMutationVariables,
} from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { MvpUpgradeFlow } from 'settings/components/presentational/account-and-emails';
import { getDailySendLimitOptions } from 'settings/components/utils';
import { Box } from 'shared/components/containers';
import { Select } from 'shared/components/form';
import { AddEmailModal, Button, Chip, IconButton } from 'shared/components/presentational';
import { AlertTriangle, Check, Minus, Nil, Plus, XClose } from 'shared/components/svgs';
import {
    EMAIL_ACCOUNTS,
    emailAccountsDefaultVariables,
    REMOVE_EMAIL_ACCOUNT,
    UPDATE_EMAIL_ACCOUNT,
} from 'shared/graphql/email-accounts';
import {
    GET_SEND_FROM_ACCOUNTS,
    REMOVE_SEND_FROM_ACCOUNT,
    UPDATE_SEND_FROM_ACCOUNT,
} from 'shared/graphql/send-from-accounts';
import { useAccessControl, useConfirmationModal, useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { AddEmailAccountResponse } from 'shared/services';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface UserEmailAccountsProps {
    planType: PlanTypesEnum;
    licenseType: LicenseTypesEnum;
}

const UserEmailAccounts: FC<UserEmailAccountsProps> = (props) => {
    const { planType, licenseType } = props;
    const translate = useTranslations('settings.email-settings.user-email-accounts');
    const theme = useTheme();
    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();
    const { showModal, hideModal } = useConfirmationModal();

    const [isEditing, setIsEditing] = useState(false);
    const [showAddAccountModal, setShowAddAccountModal] = useState(false);

    const { canAddEmailAccounts, canUpdateSendLimit } = useAccessControl();

    const { previousData, data, refetch } = useQuery<EmailAccountsQuery, EmailAccountsQueryVariables>(EMAIL_ACCOUNTS, {
        variables: {
            ...emailAccountsDefaultVariables,
            where: {
                _or: [
                    { status: { _neq: EmailAccountStatusEnum.Disconnected } },
                    {
                        _and: [
                            { status: { _eq: EmailAccountStatusEnum.Disconnected } },
                            {
                                send_from_accounts: {
                                    _or: [
                                        {
                                            _and: [
                                                {
                                                    status: { _eq: SendFromAccountStatusEnum.Approved },
                                                    userId: { _neq: session!.user.id },
                                                },
                                            ],
                                        },
                                        {
                                            sequence_stages: {
                                                isDeleted: { _eq: false },
                                                sequence: {
                                                    status: {
                                                        _eq: SequenceStatusEnum.EmailDisconnected,
                                                    },
                                                },
                                            },
                                        },
                                        {
                                            recipient_custom_stages: {
                                                recipient: {
                                                    sequence: {
                                                        status: {
                                                            _eq: SequenceStatusEnum.EmailDisconnected,
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
                userId: { _eq: session!.user.id },
            },
        },
    });

    const canAddMoreEmails = canAddEmailAccounts(licenseType, planType, (data || previousData)?.email_accounts);

    const options = getDailySendLimitOptions(planType, licenseType);

    const { refetch: refetchEmailAccountsForSequenceCreation } = useQuery<
        SendFromAccountsQuery,
        SendFromAccountsQueryVariables
    >(GET_SEND_FROM_ACCOUNTS, {
        variables: {
            where: {
                userId: { _eq: session!.user.id },
                status: { _eq: SendFromAccountStatusEnum.Approved },
            },
        },
    });

    const [removeEmailAccount] = useMutation<RemoveEmailAccountMutation>(REMOVE_EMAIL_ACCOUNT, {
        refetchQueries: [getOperationName(GET_SEND_FROM_ACCOUNTS) as string],
    });
    const [updateEmailAccount] = useMutation<UpdateEmailAccountMutation>(UPDATE_EMAIL_ACCOUNT, {
        refetchQueries: [getOperationName(GET_SEND_FROM_ACCOUNTS) as string],
    });
    const [updateSendFromAccount] = useMutation<UpdateSendFromAccountMutation, UpdateSendFromAccountMutationVariables>(
        UPDATE_SEND_FROM_ACCOUNT
    );
    const [removeSendFromAccount] = useMutation<RemoveSendFromAccountMutation, RemoveSendFromAccountMutationVariables>(
        REMOVE_SEND_FROM_ACCOUNT
    );

    const handleUpdateDailySendingLimit = async (email: string, newLimit: number) => {
        await updateEmailAccount({ variables: { email, set: { dailySendingLimit: newLimit } } });
        refetch();
    };

    const handleShowAddAccountModal = () => setShowAddAccountModal(true);
    const handleCloseAddAccountModal = () => setShowAddAccountModal(false);

    const handleToggleEdit = () => setIsEditing(!isEditing);

    const handleOnEmailAdd = async ({ data: { emailAccount } }: AddEmailAccountResponse) => {
        setIsEditing(false);
        handleCloseAddAccountModal();
        if (!emailAccount) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('connected-email-alert-error'),
            });
        } else {
            await refetch();
            await refetchEmailAccountsForSequenceCreation();
            showSnackbarAlert({
                severity: 'success',
                message: translate('connected-email-alert', {
                    email: emailAccount.email,
                }),
            });
        }
    };

    const createApproveSendFromAccountRequestHandler = (id: string) => async () => {
        await updateSendFromAccount({
            variables: {
                id,
                set: { status: SendFromAccountStatusEnum.Approved },
            },
        });
        await refetch();
    };

    const createDenySendFromAccountRequestHandler = (id: string) => async () => {
        await removeSendFromAccount({
            variables: {
                id,
            },
        });
        await refetch();
    };

    const createRemoveSendFromAccountRequestHandler = createDenySendFromAccountRequestHandler;

    const createRemoveEmailAccountConfirmationHandler = (email: string) => () => {
        const handleRemoveEmailAccount = async () => {
            const res = await removeEmailAccount({ variables: { email } });
            if (res.data?.update_email_accounts_by_pk?.status === EmailAccountStatusEnum.Disconnected) {
                await refetch();
                setIsEditing(false);
                hideModal();
                showSnackbarAlert({
                    severity: 'success',
                    message: translate('disconnected-email-alert', {
                        email,
                    }),
                });
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('disconnected-email-alert-error', {
                        email,
                    }),
                });
            }
        };

        showModal({
            title: translate('remove-email-account-modal.header'),
            description: translate('remove-email-account-modal.description'),
            confirmButton: {
                text: translate('remove-email-account-modal.remove-account-button-label'),
                onClick: handleRemoveEmailAccount,
                variant: 'contained',
                color: 'error',
            },
            cancelButton: {
                text: translate('remove-email-account-modal.cancel-button-label'),
                onClick: hideModal,
                color: 'secondary',
            },
        });
    };

    const isGrowthPlan = planType === PlanTypesEnum.Enterprise || planType === PlanTypesEnum.EnterpriseFreeTrial;

    const noneAvailable =
        (data || previousData) && (data || previousData)!.email_accounts.length === 0 ? (
            <Box
                css={css`
                    color: ${theme.palette.grey[200]};
                    display: flex;
                    align-items: center;
                    column-gap: ${spacing.space8px};
                `}
            >
                <Nil />
                {translate('none-available')}
            </Box>
        ) : null;

    const nonEditableContent = (data || previousData) && (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                row-gap: ${spacing.space8px};
            `}
        >
            {noneAvailable}
            {(data || previousData)?.email_accounts?.map(
                ({ email, send_from_accounts, status: emailAccountStatus }) => {
                    const pendingAccounts = send_from_accounts.filter(
                        ({ status: sendFromAccountStatus }) =>
                            sendFromAccountStatus === SendFromAccountStatusEnum.PendingApproval
                    );
                    const sharedAccounts = send_from_accounts?.filter(
                        ({ status: sendFromAccountStatus, user }) =>
                            (sendFromAccountStatus === SendFromAccountStatusEnum.Approved ||
                                sendFromAccountStatus === SendFromAccountStatusEnum.Disconnected) &&
                            user.id !== session!.user.id
                    );
                    return (
                        <Box key={email}>
                            <Box>
                                <Box component="span">{`${email} • `}</Box>
                                <Box
                                    component="span"
                                    css={css`
                                        color: ${theme.palette.grey[200]};
                                    `}
                                >
                                    {translate('shared-with-description', {
                                        count: sharedAccounts.length || 0,
                                    })}
                                </Box>
                                {[EmailAccountStatusEnum.Disconnected, EmailAccountStatusEnum.AuthError].includes(
                                    emailAccountStatus
                                ) && (
                                    <Box
                                        css={css`
                                            margin-top: ${spacing.space14px};

                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            min-width: 580px;
                                            min-height: 76px;
                                            background-color: ${theme.palette.error.main};
                                            border-radius: ${spacing.space4px};
                                            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                                            padding: ${spacing.space16px} ${spacing.space24px};

                                            color: ${theme.palette.common.white};
                                            font-size: ${fontSizes.f16};
                                        `}
                                    >
                                        <AlertTriangle height={20} width={20} stroke={theme.palette.common.white} />
                                        <Box
                                            css={css`
                                                margin-left: ${spacing.space12px};
                                            `}
                                        >
                                            {translate('reconnect-account-message')}
                                        </Box>
                                        <Button
                                            css={css`
                                                white-space: nowrap;
                                                padding: ${spacing.space10px} ${spacing.space16px};
                                                margin-left: ${spacing.space10px};
                                                gap: ${spacing.space8px};
                                                width: 160px;
                                                height: 44px;
                                            `}
                                            variant="outlined"
                                            color="inherit"
                                            onClick={handleShowAddAccountModal}
                                        >
                                            {translate('reconnect-email-button-label')}
                                        </Button>
                                    </Box>
                                )}
                                {pendingAccounts.length > 0 && (
                                    <Chip
                                        label={translate('new-requests-chip', {
                                            count: pendingAccounts.length,
                                        })}
                                        css={css`
                                            margin-left: ${spacing.space8px};
                                            padding: 0 ${spacing.space8px};
                                            font-size: ${fontSizes.f12};
                                            background-color: ${theme.palette.primary.contrastText};
                                            .MuiChip-label {
                                                padding: 0;
                                            }
                                        `}
                                        size="small"
                                    />
                                )}
                            </Box>
                            <Box>
                                {pendingAccounts.map((pendingAccount) => (
                                    <Box
                                        key={pendingAccount.user.fullName}
                                        css={css`
                                            background-color: ${`${colors.greens.narvik30}`};
                                            padding: ${spacing.space16px};
                                            margin-top: ${spacing.space8px};
                                            border-radius: 8px;

                                            display: grid;
                                            grid-template-columns: minmax(0, 1fr) min-content;
                                            column-gap: ${spacing.space16px};
                                            align-items: center;
                                            width: 100%;
                                        `}
                                    >
                                        <Box>
                                            {translate.rich('share-with', {
                                                name: pendingAccount.user.fullName,
                                                // eslint-disable-next-line react/no-unstable-nested-components
                                                bold: (children) => (
                                                    <Box
                                                        component="span"
                                                        css={css`
                                                            font-weight: ${fontWeights.bold};
                                                            text-overflow: ellipsis;
                                                        `}
                                                    >
                                                        {children}
                                                    </Box>
                                                ),
                                            })}
                                        </Box>
                                        <Box
                                            css={css`
                                                display: flex;
                                                column-gap: 4px;
                                            `}
                                        >
                                            <IconButton
                                                css={css`
                                                    background-color: ${theme.palette.common.white};
                                                    height: 48px;
                                                    width: 48px;
                                                `}
                                                onClick={createApproveSendFromAccountRequestHandler(pendingAccount.id)}
                                            >
                                                <Check stroke={theme.palette.primary.main} />
                                            </IconButton>
                                            <IconButton
                                                css={css`
                                                    background-color: ${theme.palette.common.white};
                                                    height: 48px;
                                                    width: 48px;
                                                `}
                                                onClick={createDenySendFromAccountRequestHandler(pendingAccount.id)}
                                            >
                                                <XClose stroke={theme.palette.error.main} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    );
                }
            )}
            {(data || previousData) && (data || previousData)!.email_accounts.length > 0 && (
                <MvpUpgradeFlow planType={planType} type="email" />
            )}
        </Box>
    );

    const editableContent = (data || previousData) && (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                row-gap: ${spacing.space32px};
            `}
        >
            {noneAvailable}
            {(data || previousData)?.email_accounts?.map(
                ({ email, send_from_accounts, dailySendingLimit, status: emailAccountStatus }) => {
                    const sharedAccounts = send_from_accounts?.filter(
                        ({ status: sendFromAccountStatus, user }) =>
                            (sendFromAccountStatus === SendFromAccountStatusEnum.Approved ||
                                sendFromAccountStatus === SendFromAccountStatusEnum.Disconnected) &&
                            user.id !== session!.user.id
                    );
                    return (
                        <Box key={email}>
                            <Box
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: ${spacing.space8px};
                                `}
                            >
                                <Box
                                    component="span"
                                    css={css`
                                        font-weight: ${fontWeights.bold};
                                        white-space: pre;
                                    `}
                                >{`${email} • `}</Box>
                                <Box
                                    component="span"
                                    css={css`
                                        color: ${theme.palette.grey[200]};
                                    `}
                                >
                                    {translate('shared-with-description', {
                                        count: sharedAccounts.length || 0,
                                    })}
                                </Box>
                                <Button
                                    variant="text"
                                    color="error"
                                    css={css`
                                        margin-left: auto;
                                        padding: 0;
                                    `}
                                    disableRipple
                                    onClick={createRemoveEmailAccountConfirmationHandler(email)}
                                    startIcon={<Minus stroke={theme.palette.error.main} />}
                                    hidden={[
                                        EmailAccountStatusEnum.Disconnected,
                                        EmailAccountStatusEnum.AuthError,
                                    ].includes(emailAccountStatus)}
                                >
                                    {translate('remove-button-label')}
                                </Button>
                            </Box>
                            <Box
                                css={css`
                                    display: flex;
                                `}
                            >
                                <Box
                                    css={css`
                                        background-color: ${theme.palette.common.white};
                                        padding: ${spacing.space8px};
                                        border-radius: 8px;
                                        flex-grow: 1;
                                        margin-right: ${spacing.space8px};
                                        color: ${theme.palette.grey[200]};
                                    `}
                                >
                                    <Box
                                        css={css`
                                            font-size: ${fontSizes.f14};
                                            margin-bottom: 4px;
                                        `}
                                    >
                                        {translate('shared-with-label')}
                                    </Box>
                                    {sharedAccounts.length > 0 ? (
                                        <Box
                                            css={css`
                                                display: flex;
                                                gap: ${spacing.space16px};
                                            `}
                                        >
                                            {sharedAccounts.map((approvedAccount) => (
                                                <Chip
                                                    key={approvedAccount.user.fullName}
                                                    css={css`
                                                        background-color: ${theme.palette.primary.contrastText};
                                                        .MuiChip-label {
                                                            padding-left: ${spacing.space8px};
                                                            padding-right: 0;
                                                        }
                                                        .MuiChip-deleteIcon {
                                                            width: 12px;
                                                            height: 12px;
                                                            margin-left: ${spacing.space8px};
                                                            margin-right: ${spacing.space8px};
                                                        }
                                                    `}
                                                    label={approvedAccount.user.fullName}
                                                    onDelete={createRemoveSendFromAccountRequestHandler(
                                                        approvedAccount.id
                                                    )}
                                                    deleteIcon={<XClose stroke={theme.palette.common.black} />}
                                                    disabled={
                                                        emailAccountStatus === EmailAccountStatusEnum.Disconnected
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    ) : (
                                        <Box
                                            css={css`
                                                font-size: ${fontSizes.f16};
                                            `}
                                        >
                                            {translate('shared-with-no-one-label')}
                                        </Box>
                                    )}
                                </Box>
                                {options.length > 0 && (
                                    <Select
                                        isDefault
                                        disableUnderline
                                        parentBackgroundColor="green"
                                        options={options}
                                        label={translate('daily-sending-limit-text-field-label')}
                                        name="dailySendingLimit"
                                        css={css`
                                            margin-bottom: 0;
                                        `}
                                        value={String(dailySendingLimit)}
                                        width="25%"
                                        onChange={(e) =>
                                            handleUpdateDailySendingLimit(email, parseInt(e.target.value as string, 10))
                                        }
                                        variant="filled"
                                        disabled={
                                            emailAccountStatus === EmailAccountStatusEnum.Disconnected ||
                                            !canUpdateSendLimit(planType, licenseType)
                                        }
                                    />
                                )}
                            </Box>
                            {[EmailAccountStatusEnum.Disconnected, EmailAccountStatusEnum.AuthError].includes(
                                emailAccountStatus
                            ) && (
                                <Box
                                    css={css`
                                        margin-top: ${spacing.space14px};

                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        min-width: 580px;
                                        min-height: 76px;
                                        background-color: ${theme.palette.error.main};
                                        border-radius: ${spacing.space4px};
                                        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                                        padding: ${spacing.space16px} ${spacing.space24px};

                                        color: ${theme.palette.common.white};
                                        font-size: ${fontSizes.f16};
                                    `}
                                >
                                    <AlertTriangle height={20} width={20} stroke={theme.palette.common.white} />
                                    <Box
                                        css={css`
                                            margin-left: ${spacing.space12px};
                                        `}
                                    >
                                        {translate('reconnect-account-message')}
                                    </Box>
                                    <Button
                                        css={css`
                                            white-space: nowrap;
                                            padding: ${spacing.space10px} ${spacing.space16px};
                                            margin-left: ${spacing.space10px};
                                            gap: ${spacing.space8px};
                                            width: 160px;
                                            height: 44px;
                                        `}
                                        variant="outlined"
                                        color="inherit"
                                        onClick={handleShowAddAccountModal}
                                    >
                                        {translate('reconnect-email-button-label')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    );
                }
            )}
            <Box
                css={css`
                    display: flex;
                    margin-top: ${spacing.space16px};
                `}
            >
                {(isGrowthPlan || ((data || previousData) && (data || previousData)!.email_accounts.length === 0)) && (
                    <Button
                        variant="outlined"
                        onClick={handleShowAddAccountModal}
                        startIcon={<Plus />}
                        css={css`
                            margin-right: auto;
                        `}
                        disabled={!canAddMoreEmails}
                    >
                        {translate('add-new-account-button-label')}
                    </Button>
                )}
                <Button
                    css={css`
                        margin-left: auto;
                    `}
                    variant="contained"
                    onClick={handleToggleEdit}
                >
                    {translate('done-button-label')}
                </Button>
            </Box>
        </Box>
    );

    return (
        <>
            <EditableSettingsSection
                title={translate('title')}
                isEditing={isEditing}
                onToggleEdit={handleToggleEdit}
                editButtonLabel={
                    isGrowthPlan || ((data || previousData) && (data || previousData)!.email_accounts.length === 0)
                        ? translate('edit-or-add-button-label')
                        : translate('edit-button-label')
                }
            >
                {isEditing ? editableContent : nonEditableContent}
            </EditableSettingsSection>
            <AddEmailModal
                open={showAddAccountModal}
                onClose={handleCloseAddAccountModal}
                onAddEmail={handleOnEmailAdd}
            />
        </>
    );
};

export { UserEmailAccounts };
