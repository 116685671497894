import { truncate } from 'lodash';

import { ProjectOption, SequenceOption } from 'shared/components/form';

interface GetOwnerProps {
    option: ProjectOption | SequenceOption;
}

export const maxNameLength: number = 35;
export const maxNameLengthShort: number = 28;

export const getOwner = (props: GetOwnerProps): string => {
    const { option } = props;
    return truncate(option.user?.fullName ?? '', {
        length: maxNameLength,
    });
};

export const getAshbyJobLink = (remoteId: string): string => `https://app.ashbyhq.com/jobs/${remoteId}`;

export const getGreenhouseJobLink = (remoteId: number): string =>
    `https://app.greenhouse.io/sdash/${remoteId.toString()}`;

export const getLeverPostingLink = (remoteId: string): string => `https://hire.lever.co/jobs/postings/edit/${remoteId}`;
