import { css } from '@mui/material';

import { Button } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EditCloseComboButtonProps {
    editing: boolean;
    editButtonLabel: string;
    closeButtonLabel: string;
    onEdit: () => void;
    onClose: () => void;
}

const EditCloseButton: FC<EditCloseComboButtonProps> = (props) => {
    const { editing, onEdit, onClose, editButtonLabel, closeButtonLabel } = props;

    const editCloseButton = editing ? (
        <Button
            variant="text"
            onClick={onEdit}
            css={css`
                height: ${spacing.space16px};
                padding-right: ${spacing.none};
                font-size: ${fontSizes.f16};
            `}
        >
            {editButtonLabel}
        </Button>
    ) : (
        <Button
            variant="text"
            startIcon={<XClose />}
            onClick={onClose}
            css={css`
                height: ${spacing.space24px};
                padding-right: ${spacing.none};
                font-size: ${fontSizes.f16};
            `}
        >
            {closeButtonLabel}
        </Button>
    );

    return editCloseButton;
};

export { EditCloseButton };
