import { css, useTheme } from '@mui/material';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button, IconButton, Popover, Title } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RemovePersonalizationProps {
    anchor?: HTMLElement;
    open: boolean;
    onCancel: () => void;
    onRemove: () => void;
    singleMessageEdit?: boolean;
}

const RemovePersonalization: FC<RemovePersonalizationProps> = (props) => {
    const { anchor, open, onCancel, onRemove, singleMessageEdit } = props;
    const theme = useTheme();
    const translate = useTranslations(
        singleMessageEdit
            ? 'prospects.tabs.conversations-tab.personalization-modal'
            : 'prospects.tabs.add-tab.personalization-modal'
    );

    return (
        <Popover
            anchorReference="anchorEl"
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    width: extensionPanelWidth,
                    height: '100%',
                },
            }}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    padding: 8px;
                `}
            >
                <IconButton onClick={onCancel}>
                    <XClose stroke={theme.palette.primary.main} />
                </IconButton>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    min-height: calc(100% - 100px);
                `}
            >
                <Box
                    css={css`
                        width: 100%;
                        margin: ${spacing.none} ${spacing.space48px};
                    `}
                >
                    <Title
                        css={css`
                            font-size: ${fontSizes.f24};
                        `}
                        type="h3"
                    >
                        {translate('title')}
                    </Title>
                    <Box>{translate('description')}</Box>
                    <Box
                        css={css`
                            display: flex;
                            justify-content: right;
                            margin-top: 20px;
                        `}
                    >
                        <Button color="secondary" variant="outlined" onClick={onCancel}>
                            {translate('cancel-button-label')}
                        </Button>
                        <Button
                            css={css`
                                margin-left: 10px;
                            `}
                            variant="contained"
                            color="error"
                            onClick={onRemove}
                        >
                            {translate('confirm-button-label')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export { RemovePersonalization };
