import { NoSsr, NoSsrProps } from '@mui/material';

import { Skeleton } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface NoSsrWrapperProps extends NoSsrProps {}

const NoSsrWrapper: FC<NoSsrWrapperProps> = ({ children, fallback = <Skeleton />, ...rest }) => (
    <NoSsr fallback={fallback} {...rest}>
        {children}
    </NoSsr>
);

export { NoSsrWrapper as NoSsr };
