import styled from '@emotion/styled';
import { Slider as MuiSlider, SliderProps as MuiSliderProps } from '@mui/material';

import { FC } from 'shared/types';

interface SliderProps extends MuiSliderProps {}

const Slider: FC<SliderProps> = (props) => <MuiSlider {...props} />;

const StyledSlider = styled(Slider)``;

export { StyledSlider as Slider };
