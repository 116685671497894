import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { OrderBy, ProjectStatusEnum, UserProjectsQuery, UserProjectsQueryVariables } from 'codegen/graphql';
import { Box } from 'shared/components/containers/box';
import { Option, SelectProject } from 'shared/components/form';
import { LoadingButton, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { GET_USER_PROJECTS, UserProjectCollaboratorType } from 'shared/graphql/projects';
import { fontSizes } from 'shared/settings';
import { spacing } from 'shared/settings/spacings';
import { FC } from 'shared/types';

interface SelectProjectModalProps {
    open: boolean;
    onClose: () => void;
    projectsToExcludeFromDropdown?: string[];
    onConfirm: (project: { id: string; title: string }) => void;
    buttonLabel: string;
    loading: boolean;
}

const SelectProjectModal: FC<SelectProjectModalProps> = ({
    open,
    onClose,
    projectsToExcludeFromDropdown = [],
    buttonLabel,
    onConfirm,
    loading,
}) => {
    const theme = useTheme();
    const translate = useTranslations('shared.select-project-modal');

    const { data } = useQuery<UserProjectsQuery, UserProjectsQueryVariables>(GET_USER_PROJECTS, {
        variables: {
            where: {
                _and: [
                    {
                        collaboratorType: {
                            _in: [UserProjectCollaboratorType.Owner, UserProjectCollaboratorType.Editor],
                        },
                    },
                    { project: { id: { _nin: projectsToExcludeFromDropdown } } },
                    { project: { status: { _eq: ProjectStatusEnum.Active } } },
                ],
            },
            withDetails: true,
            orderBy: { project: { title: OrderBy.Asc } },
        },
    });
    const validationSchema = Yup.object().shape({
        project: Yup.object().shape({
            label: Yup.string().required(translate('input-error-no-projects-provided')),
            value: Yup.string().required(),
        }),
    });

    const formMethods = useForm<{ project: Option }>({
        defaultValues: { project: undefined },
        resolver: yupResolver(validationSchema),
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    };

    const onSubmit: SubmitHandler<{ project: Option }> = ({ project }) => {
        onConfirm({ id: project.value, title: project.label });
        formMethods.reset();
    };

    if (!data) {
        return null;
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    gap: ${spacing.space24px};
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    css={css`
                        margin-bottom: ${spacing.none};
                        font-size: ${fontSizes.f24};
                    `}
                    type="h3"
                >
                    {translate('title')}
                </Title>
                <Box>
                    <SelectProject
                        isMulti={false}
                        options={data.user_projects.map(({ project }) => ({
                            label: project!.title,
                            value: project!.id,
                        }))}
                        name="project"
                        placeholder={translate('input-label')}
                        formMethods={formMethods}
                        css={css`
                            z-index: 100;
                        `}
                    />
                    <ErrorMessage
                        name="project"
                        message={translate('input-error-no-projects-provided')}
                        errors={formMethods.formState.errors}
                        render={({ message }) => (
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f14};
                                    margin-left: ${spacing.space8px};
                                    color: ${theme.palette.error.main};
                                `}
                            >
                                {message}
                            </Box>
                        )}
                    />
                </Box>

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <LoadingButton variant="contained" onClick={formMethods.handleSubmit(onSubmit)} loading={loading}>
                        {buttonLabel}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

export { SelectProjectModal };
