import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Stars01 } from 'shared/components/svgs';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerMemberCardInsightsTitleProps {}

const SourcerMemberCardInsightsTitle: FC<SourcerMemberCardInsightsTitleProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('shared.prospect-card.insights');

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: 2px;
            `}
        >
            <Box
                css={css`
                    font-weight: ${fontWeights.bold};
                    white-space: nowrap;
                `}
            >
                <Box
                    css={css`
                        margin-right: 6px;
                    `}
                    component="span"
                >
                    {translate('title')}
                </Box>
                <Stars01 width={16} height={16} />
            </Box>

            <Box
                css={css`
                    color: ${theme.palette.grey[600]};
                    font-size: ${fontSizes.f12};
                `}
                component="span"
            >
                {translate('ai-generated')}
            </Box>
        </Box>
    );
};

export { SourcerMemberCardInsightsTitle };
