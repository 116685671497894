import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedUserCheck01: FC<SvgProps> = ({ stroke, theme, width, height, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '22'}
        height={height ?? '20'}
        viewBox={`0 0 ${width ?? '22'} ${height ?? '20'}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M11 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M15 16L17 18L21 14M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const UserCheck01 = withTheme(ThemedUserCheck01);

export { UserCheck01 };
