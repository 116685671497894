import styled from '@emotion/styled';
import { Container as MuiContainer, ContainerProps } from '@mui/material';

import { FC } from 'shared/types';

interface Props extends ContainerProps {}

const ContainerContainer: FC<Props> = (props) => <MuiContainer {...props} />;

const Container = styled(ContainerContainer)`
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
    border-radius: 4px;
`;

export { Container };
