import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useEffect, useState } from 'react';

import {
    AddOrUpdateProspectMutation,
    AshbyProspectApplicationsQuery,
    AshbyProspectApplicationsQueryVariables,
    AshbyUrlApplicationsQuery,
    AshbyUrlApplicationsQueryVariables,
    ContactChannelsEnum,
    CreateRecipientContactsMutation,
    GreenhouseProspectApplicationsQuery,
    GreenhouseProspectApplicationsQueryVariables,
    GreenhouseUrlApplicationsQuery,
    GreenhouseUrlApplicationsQueryVariables,
    LeverProspectApplicationsQuery,
    LeverProspectApplicationsQueryVariables,
    LeverUrlApplicationsQuery,
    LeverUrlApplicationsQueryVariables,
    OrderBy,
    ProjectStatusEnum,
    ProspectContactsPanelInfoQuery,
    ProspectPanelInfoQuery,
    RecipientStatusEnum,
    SendFromAccountStatusEnum,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SequenceStatusEnum,
    UpdateProspectContactsMutation,
    UpdateRecipientsMutation,
    UserProjectsQuery,
    UserProjectsQueryVariables,
    UserSequencesQuery,
    UserTeamQuery,
    UserProjectsForProspectPanelQuery,
    UserProjectsForProspectPanelQueryVariables,
    RecipientConversationsQuery,
    RecipientConversationsQueryVariables,
    CreateProspectCustomMessageMutation,
    CreateProspectCustomMessageMutationVariables,
} from 'codegen/graphql';
import { CREATE_RECIPIENT_CONTACTS, ContactUpdate, UPDATE_PROSPECT_CONTACTS } from 'shared/graphql/contacts';
import {
    GET_ASHBY_PROSPECT_APPLICATIONS,
    GET_ASHBY_URL_APPLICATIONS,
    GET_GREENHOUSE_PROSPECT_APPLICATIONS,
    GET_GREENHOUSE_URL_APPLICATIONS,
    GET_LEVER_PROSPECT_APPLICATIONS,
    GET_LEVER_URL_APPLICATIONS,
} from 'shared/graphql/integrations';
import {
    GET_USER_PROJECTS,
    GET_USER_PROJECTS_FOR_PROSPECT_PANEL,
    UserProjectCollaboratorType,
    userProjectsDefaultVariables,
} from 'shared/graphql/projects';
import { CREATE_PROSPECT_CUSTOM_MESSAGE } from 'shared/graphql/prospect-custom-message';
import {
    ADD_OR_UPDATE_PROSPECT,
    GET_PROSPECT_PANEL_INFO,
    PROSPECT_CONTACTS_PANEL_INFO,
} from 'shared/graphql/prospects';
import { GET_RECIPIENTS, GET_RECIPIENT_CONVERSATIONS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { GET_SEND_FROM_ACCOUNTS } from 'shared/graphql/send-from-accounts';
import { GET_USER_SEQUENCES } from 'shared/graphql/sequences';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { useExtensionPanel, useSession } from 'shared/hooks';
import { useProfile } from 'shared/hooks/use-profile';
import { ProspectPanelContextData, ProspectPanelContextProvider } from 'shared/hooks/use-prospect-panel';
import { extractName } from 'shared/services';
import { FC } from 'shared/types';
import { ParsedProfileWithUrl, ProspectProfile } from 'shared/types/prospect';

export type PanelContext = {
    url: string;
    prospectId?: string;
    sequenceId?: string;
    flow: 'search' | 'extension' | 'recipient' | 'project';
    magicSentence?: string | null;
};

interface ProspectPanelProviderProps {
    context: PanelContext;
}

const pollInterval = 500; // 0.5 seconds

const ProspectPanelProvider: FC<ProspectPanelProviderProps> = ({ children, context }) => {
    const { session } = useSession();
    const { profile } = useProfile();
    const { panelIsOpen } = useExtensionPanel();

    const [data, setData] = useState<Partial<ProspectPanelContextData>>();
    const [panelContext, setPanelContext] = useState<PanelContext>(context);
    const [parsedProfile, setParsedProfile] = useState<ParsedProfileWithUrl>();
    const [refreshProfile, setRefreshProfile] = useState<boolean>(false);

    const [adding, setAdding] = useState<boolean>(false);

    const [addOrUpdateProspectData] = useMutation<AddOrUpdateProspectMutation>(ADD_OR_UPDATE_PROSPECT, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    const [updateProspectContact] = useMutation<UpdateProspectContactsMutation>(UPDATE_PROSPECT_CONTACTS, {
        refetchQueries: [getOperationName(GET_PROSPECT_PANEL_INFO) as string],
    });

    const [updateRecipients] = useMutation<UpdateRecipientsMutation>(UPDATE_RECIPIENTS, {
        refetchQueries: [getOperationName(GET_RECIPIENTS) as string],
    });

    const [createRecipientContacts] = useMutation<CreateRecipientContactsMutation>(CREATE_RECIPIENT_CONTACTS, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    const [createProspectCustomMessage] = useMutation<
        CreateProspectCustomMessageMutation,
        CreateProspectCustomMessageMutationVariables
    >(CREATE_PROSPECT_CUSTOM_MESSAGE, {
        refetchQueries: [getOperationName(GET_PROSPECT_PANEL_INFO) as string],
    });

    const {
        data: prospectPanelInfo,
        loading: loadingProspectPanelInfo,
        refetch: refetchProspectPanelInfo,
    } = useQuery<ProspectPanelInfoQuery>(GET_PROSPECT_PANEL_INFO, {
        variables: { url: panelContext.url },
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: prospectContactsPanelInfo,
        loading: loadingProspectContactsPanelInfo,
        startPolling,
        stopPolling,
    } = useQuery<ProspectContactsPanelInfoQuery>(PROSPECT_CONTACTS_PANEL_INFO, {
        variables: { url: panelContext.url },
    });

    const { data: conversationsData } = useQuery<RecipientConversationsQuery, RecipientConversationsQueryVariables>(
        GET_RECIPIENT_CONVERSATIONS,
        {
            variables: {
                where: { id: { _in: prospectPanelInfo?.recipients?.map((r) => r.id) } },
                orderBy: { createdAt: OrderBy.Desc },
            },
            fetchPolicy: 'cache-and-network',
            skip: loadingProspectPanelInfo || !prospectPanelInfo || prospectPanelInfo?.prospects.length === 0,
        }
    );

    const { data: sendFromAccountsData, loading: loadingSendFromAccountsData } = useQuery<
        SendFromAccountsQuery,
        SendFromAccountsQueryVariables
    >(GET_SEND_FROM_ACCOUNTS, {
        variables: {
            where: {
                status: {
                    _eq: SendFromAccountStatusEnum.Approved,
                },
                userId: {
                    _eq: session?.user.id,
                },
            },
        },
    });

    const { data: sequences, loading: loadingSequencesData } = useQuery<UserSequencesQuery>(GET_USER_SEQUENCES, {
        variables: {
            where: {
                _and: {
                    userId: { _eq: session?.user.id },
                    status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                },
            },
        },
    });

    const { data: projects, loading: loadingProjectsData } = useQuery<
        UserProjectsForProspectPanelQuery,
        UserProjectsForProspectPanelQueryVariables
    >(GET_USER_PROJECTS_FOR_PROSPECT_PANEL, {
        variables: {
            where: {
                _and: [
                    { project: { status: { _in: [ProjectStatusEnum.Active] } } },
                    {
                        collaboratorType: {
                            _in: [UserProjectCollaboratorType.Owner, UserProjectCollaboratorType.Editor],
                        },
                    },
                ],
            },
            orderBy: { project: { title: OrderBy.Asc } },
        },
    });

    const { data: defaultUserProjects, loading: loadingDefaultUserProjectsData } = useQuery<
        UserProjectsQuery,
        UserProjectsQueryVariables
    >(GET_USER_PROJECTS, {
        variables: {
            ...userProjectsDefaultVariables,
            where: {
                _and: [
                    { project: { status: { _in: [ProjectStatusEnum.Active] }, defaultUserProject: { _eq: true } } },
                    {
                        collaboratorType: {
                            _in: [UserProjectCollaboratorType.Owner, UserProjectCollaboratorType.Editor],
                        },
                    },
                ],
            },
        },
    });

    const [getAshbyDataByProspect, { data: ashbyProspectData }] = useLazyQuery<
        AshbyProspectApplicationsQuery,
        AshbyProspectApplicationsQueryVariables
    >(GET_ASHBY_PROSPECT_APPLICATIONS);

    const [getAshbyDataByUrl, { data: ashbyUrlData }] = useLazyQuery<
        AshbyUrlApplicationsQuery,
        AshbyUrlApplicationsQueryVariables
    >(GET_ASHBY_URL_APPLICATIONS);

    const [getGreenhouseDataByProspect, { data: greenhouseProspectData }] = useLazyQuery<
        GreenhouseProspectApplicationsQuery,
        GreenhouseProspectApplicationsQueryVariables
    >(GET_GREENHOUSE_PROSPECT_APPLICATIONS);

    const [getGreenhouseDataByUrl, { data: greenhouseUrlData }] = useLazyQuery<
        GreenhouseUrlApplicationsQuery,
        GreenhouseUrlApplicationsQueryVariables
    >(GET_GREENHOUSE_URL_APPLICATIONS);

    const [getLeverDataByProspect, { data: leverProspectData }] = useLazyQuery<
        LeverProspectApplicationsQuery,
        LeverProspectApplicationsQueryVariables
    >(GET_LEVER_PROSPECT_APPLICATIONS);

    const [getLeverDataByUrl, { data: leverUrlData }] = useLazyQuery<
        LeverUrlApplicationsQuery,
        LeverUrlApplicationsQueryVariables
    >(GET_LEVER_URL_APPLICATIONS);

    const { data: userTeamData, loading: loadingUserTeamData } = useQuery<UserTeamQuery>(GET_USER_TEAM);

    useEffect(() => {
        if (panelContext.flow === 'extension') {
            let targetProspect;
            if (data && data.prospectInfo && !loadingProspectContactsPanelInfo && prospectContactsPanelInfo) {
                if (data.prospectInfo.id !== '') {
                    targetProspect = prospectContactsPanelInfo.prospects.find((p) => p.id === data.prospectInfo!.id);
                }
            }
            if (targetProspect && !targetProspect.personalFindingCompletedAt) {
                startPolling(pollInterval);
            } else {
                stopPolling();
            }

            if (
                data &&
                targetProspect &&
                targetProspect.personalFindingCompletedAt !== data.prospectInfo?.personalFindingCompletedAt
            ) {
                const newData = { ...data, contacts: targetProspect.contacts ?? [] };
                setData(newData);
            }
        }

        return () => stopPolling();
    }, [
        panelContext.flow,
        data,
        prospectContactsPanelInfo,
        loadingProspectContactsPanelInfo,
        startPolling,
        stopPolling,
    ]);

    useEffect(() => {
        if (panelContext.prospectId) {
            getAshbyDataByProspect({ variables: { prospectId: panelContext.prospectId } });
            getGreenhouseDataByProspect({ variables: { prospectId: panelContext.prospectId } });
            getLeverDataByProspect({ variables: { prospectId: panelContext.prospectId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelContext.prospectId]);

    useEffect(() => {
        if (panelContext.url) {
            getAshbyDataByUrl({ variables: { url: panelContext.url } });
            getGreenhouseDataByUrl({ variables: { url: panelContext.url } });
            getLeverDataByUrl({ variables: { url: panelContext.url } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelContext.url]);

    const loading =
        loadingProjectsData ||
        loadingProspectPanelInfo ||
        loadingSequencesData ||
        loadingSendFromAccountsData ||
        loadingDefaultUserProjectsData ||
        loadingUserTeamData;

    useEffect(() => {
        if (profile?.publicLink && panelContext.url !== profile.publicLink) {
            setRefreshProfile(false);
            // if the url is different, we need to update the panel context prospectId and url
            setPanelContext({ ...panelContext, url: profile.publicLink, prospectId: undefined });
            refetchProspectPanelInfo({
                url: profile.publicLink,
            });
            if (panelContext.prospectId) {
                getAshbyDataByProspect({ variables: { prospectId: panelContext.prospectId } });
                getGreenhouseDataByProspect({ variables: { prospectId: panelContext.prospectId } });
                getLeverDataByProspect({ variables: { prospectId: panelContext.prospectId } });
            } else if (panelContext.url) {
                getAshbyDataByUrl({ variables: { url: panelContext.url } });
                getGreenhouseDataByUrl({ variables: { url: panelContext.url } });
                getLeverDataByUrl({ variables: { url: panelContext.url } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.publicLink, panelContext]);

    useEffect(() => {
        // we don't want to add or update the prospect if the panel is not open
        if (!panelIsOpen) {
            return;
        }

        const prospectFromUrl = prospectPanelInfo?.prospects[0];
        if (!refreshProfile && parsedProfile && prospectFromUrl && prospectFromUrl.profile) {
            const updatedProfile = {
                ...prospectFromUrl.profile,
                firstName: parsedProfile.profile.firstName ?? prospectFromUrl.profile.firstName,
                lastName: parsedProfile.profile.lastName ?? prospectFromUrl.profile.lastName,
                fullName: parsedProfile.profile.fullName ?? prospectFromUrl.profile.fullName,
                latestSchool: parsedProfile.profile.latestSchool ?? prospectFromUrl.profile.latestSchool,
                latestCompany: parsedProfile.profile.latestCompany ?? prospectFromUrl.profile.latestCompany,
                title: parsedProfile.profile.title ?? prospectFromUrl.profile.title,
                location: parsedProfile.profile.location ?? prospectFromUrl.profile.location,
            };

            if (prospectFromUrl.urls?.map((u) => u.url).includes(parsedProfile.url)) {
                addOrUpdateProspect(updatedProfile, parsedProfile.url);
                setRefreshProfile(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedProfile, prospectPanelInfo, refreshProfile, panelIsOpen]);

    useEffect(() => {
        if (profile) {
            setRefreshProfile(false);
            const name = extractName(profile?.fullName ?? '');
            const linkedInParsedProfile: ProspectProfile = {
                id: '',
                prospectId: '',
                firstName: name ? name.first : '',
                lastName: name ? name.last : '',
                fullName: name ? name.full : '',
                latestCompany: profile?.currentPositions[0]?.companyName,
                latestSchool: profile?.currentEducation?.schoolName,
                title: profile?.currentPositions[0]?.title,
                notes: undefined,
                firstNameCustomized: undefined,
                lastNameCustomized: undefined,
                fullNameCustomized: undefined,
                latestCompanyCustomized: undefined,
                latestSchoolCustomized: undefined,
                titleCustomized: undefined,
                location: profile?.location,
            };

            setParsedProfile({ profile: linkedInParsedProfile, url: profile.publicLink });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, session]);

    useEffect(() => {
        if (loading || adding) return;

        if (panelContext.flow === 'extension' && prospectPanelInfo?.prospects.length === 0) {
            setData({
                sequencesData: sequences?.sequences,
                projectsData: projects?.user_projects.map(({ project }) => project!),
                defaultUserProjectsData: defaultUserProjects?.user_projects.map(({ project }) => project!),
                sendFromAccountsData: sendFromAccountsData?.send_from_accounts,
                contacts: [],
                recipients: [],
                recipientsConversations: [],
                members: [],
                sourcerMembers: prospectPanelInfo?.sourcer_members ?? [],
                prospectInfo: parsedProfile
                    ? {
                          id: '',
                          createdAt: 0,
                          profile: parsedProfile.profile!,
                          urls: parsedProfile.url ? [{ url: parsedProfile.url }] : [],
                          inMailSent: false,
                          inMailSentTime: 0,
                          customMessages: [],
                          contacts: [],
                      }
                    : undefined,
                context: panelContext,
                projectMemberActivities: [],
                recipientActivities: [],
                teamData: (userTeamData?.teams ?? []).length > 0 ? userTeamData?.teams[0] : undefined,
            });
        } else {
            const contextProspect = prospectPanelInfo?.prospects.find((f) => f.id === panelContext.prospectId);
            const prospect = contextProspect || prospectPanelInfo?.prospects![0];

            setData({
                sequencesData: sequences?.sequences,
                projectsData: projects?.user_projects.map(({ project }) => project!),
                defaultUserProjectsData: defaultUserProjects?.user_projects.map(({ project }) => project!),
                sendFromAccountsData: sendFromAccountsData?.send_from_accounts,
                contacts: prospect?.contacts!.filter((f) => f.channel === ContactChannelsEnum.Email),
                recipients: prospectPanelInfo?.recipients,
                recipientsConversations: conversationsData?.recipients,
                members: prospectPanelInfo?.project_members,
                sourcerMembers: prospectPanelInfo?.sourcer_members,
                prospectInfo: prospect || {
                    id: '',
                    createdAt: 0,
                    profile: prospectPanelInfo?.prospects![0].profile,
                    urls: prospectPanelInfo?.prospects![0].urls,
                    inMailSent: false,
                    inMailSentTime: 0,
                    customMessages: [],
                    contacts: prospectPanelInfo?.prospects![0].contacts ?? [],
                },
                context: panelContext,
                projectMemberActivities: prospectPanelInfo?.project_member_activities,
                recipientActivities: prospectPanelInfo?.recipient_activities,
                teamData: (userTeamData?.teams ?? []).length > 0 ? userTeamData?.teams[0] : undefined,
            });
        }
    }, [
        prospectPanelInfo,
        sequences,
        projects,
        defaultUserProjects,
        sendFromAccountsData,
        panelContext,
        profile,
        parsedProfile,
        loading,
        session,
        userTeamData,
        conversationsData,
        adding,
    ]);

    const addOrUpdateProspect = async (prospectProfile: ProspectProfile, url: string, noRefetchQueries = false) => {
        setAdding(true);
        let newProspectId;

        let educations: any[] = [];
        let experiences: any[] = [];

        if (panelContext.flow === 'extension') {
            if (prospectProfile && profile) {
                educations =
                    profile?.recruiterLink || !panelContext.prospectId
                        ? profile.educations.map(
                              ({ degree, fieldOfStudy, schoolName, startDateYear, endDateYear, pageUrl }) => ({
                                  degree,
                                  fieldOfStudy,
                                  schoolName,
                                  startDateYear,
                                  endDateYear,
                                  schoolUrl: pageUrl,
                              })
                          )
                        : [];

                experiences =
                    profile?.recruiterLink || !panelContext.prospectId
                        ? profile.positions.map(
                              ({
                                  companyName,
                                  title,
                                  current,
                                  startDateMonth,
                                  startDateYear,
                                  endDateMonth,
                                  endDateYear,
                                  companyLogo,
                                  companyUrl,
                              }) => ({
                                  companyName,
                                  title,
                                  current: current ?? false, // current cannot be null in the DB
                                  startDateMonth,
                                  startDateYear,
                                  endDateMonth,
                                  endDateYear,
                                  companyLogo,
                                  companyUrl,
                              })
                          )
                        : [];
            }
        }
        if (url && prospectProfile) {
            let profileDetails: any = { ...prospectProfile };
            profileDetails = educations.length > 0 ? { ...profileDetails, educations } : profileDetails;
            profileDetails = experiences.length > 0 ? { ...profileDetails, experiences } : profileDetails;
            const result = await addOrUpdateProspectData({
                variables: {
                    data: [
                        {
                            url,
                            profile: profileDetails,
                        },
                    ],
                },
                ...(noRefetchQueries && { refetchQueries: [] }),
            });
            if (result.data?.add_prospect?.[0]?.id) {
                newProspectId = result.data.add_prospect?.[0]?.id;
                setPanelContext({ ...panelContext, prospectId: result.data.add_prospect?.[0].id });
            }
            newProspectId = result.data?.add_prospect?.[0]?.id;
        }
        setAdding(false);
        return newProspectId;
    };

    const addProspectContact = async (contacts: ContactUpdate[], recipientIds: string[]) => {
        let newProspectId: string | undefined;
        if (panelContext.flow === 'extension' && !panelContext.prospectId && parsedProfile) {
            newProspectId = await addOrUpdateProspect(parsedProfile.profile, parsedProfile.url, true);
        } else if (data?.prospectInfo && data.prospectInfo.profile) {
            // we have to make sure that the prospectId that we are working with belongs to the user,
            // not to someone else
            newProspectId = await addOrUpdateProspect(data.prospectInfo.profile, panelContext.url, true);
        }

        if (newProspectId) {
            const contactUpdates = contacts.map((c) => ({ ...c, prospectId: newProspectId }));
            const result = await updateProspectContact({
                variables: {
                    prospectId: newProspectId,
                    objects: contactUpdates,
                },
            });

            // update recipient contacts as well for recipients in user's sequence
            if (result.data?.insert_prospect_contacts) {
                const prospectPrimaryContact = result.data.insert_prospect_contacts.returning.find((f) => f.primary);
                if (prospectPrimaryContact) {
                    const recipientContacts = recipientIds.map((r) => {
                        const newRecipientContact = {
                            recipientId: r,
                            contactId: prospectPrimaryContact.id,
                            channel: prospectPrimaryContact.channel,
                        };
                        return newRecipientContact;
                    });

                    if (recipientContacts && recipientContacts.length > 0) {
                        await updateRecipients({
                            variables: {
                                where: {
                                    id: { _in: recipientContacts.map((r) => r.recipientId) },
                                },
                                set: {
                                    status: RecipientStatusEnum.NotStarted,
                                    lastStageSent: 0,
                                    lastStageSentAt: null,
                                    nextMessageScheduledAt: null,
                                },
                            },
                        });

                        await createRecipientContacts({
                            variables: {
                                recipientContacts,
                                recipientIds: recipientContacts.map((r) => r.recipientId),
                            },
                        });
                    }
                }
            }
        }
    };

    const createCustomMessage = async (prospectCustomMessage: {
        prospectId: string;
        sequenceId: string;
        customMessage: string;
    }) => createProspectCustomMessage({ variables: { prospectCustomMessage } });

    if (panelContext.url !== data?.prospectInfo?.urls?.[0]?.url) {
        return null;
    }

    return (
        <ProspectPanelContextProvider
            contacts={data?.contacts ?? []}
            sequencesData={data?.sequencesData ?? []}
            projectsData={data?.projectsData ?? []}
            defaultUserProjectsData={data?.defaultUserProjectsData ?? []}
            sendFromAccountsData={data?.sendFromAccountsData ?? []}
            recipients={data?.recipients ?? []}
            recipientsConversations={data?.recipientsConversations ?? []}
            members={data?.members ?? []}
            sourcerMembers={data?.sourcerMembers ?? []}
            prospectInfo={data?.prospectInfo ?? undefined}
            context={panelContext}
            recipientActivities={data?.recipientActivities ?? []}
            projectMemberActivities={data?.projectMemberActivities ?? []}
            ashbyData={ashbyProspectData?.data ?? ashbyUrlData?.data ?? []}
            greenhouseData={greenhouseProspectData?.data ?? greenhouseUrlData?.data ?? []}
            leverData={leverProspectData?.data ?? leverUrlData?.data ?? []}
            updateContext={setPanelContext}
            addProspectContact={addProspectContact}
            createCustomMessage={createCustomMessage}
            addOrUpdateProspect={addOrUpdateProspect}
            teamData={data?.teamData ?? undefined}
        >
            {children}
        </ProspectPanelContextProvider>
    );
};

export { ProspectPanelProvider };
