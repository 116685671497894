import { gql } from '@apollo/client';

const RECIPIENT_CUSTOM_STAGES = gql`
    fragment RecipientCustomStagesDetails on recipient_custom_stages {
        id
        cc
        bcc
        recipientId
        stageIndex
        content
        subject
        numberOfDays
        replyToLastEmail
        sendTime
        sendTimeOfDay
        sendTypeOfDays
        sendFromAccountId
        sendFromAccount: send_from_account {
            id
            email
        }
    }
`;

export const CREATE_RECIPIENT_CUSTOM_STAGES = gql`
    mutation CreateRecipientCustomStages($objects: [recipient_custom_stages_insert_input!]!) {
        insert_recipient_custom_stages(objects: $objects) {
            returning {
                ...RecipientCustomStagesDetails
            }
        }
    }
    ${RECIPIENT_CUSTOM_STAGES}
`;

export const UPDATE_RECIPIENT_CUSTOM_STAGES = gql`
    mutation UpdateRecipientCustomStages($objects: [recipient_custom_stages_insert_input!]!) {
        insert_recipient_custom_stages(
            objects: $objects
            on_conflict: {
                constraint: recipient_custom_stages_recipientId_stageIndex_key
                update_columns: [content, subject]
            }
        ) {
            returning {
                ...RecipientCustomStagesDetails
            }
        }
    }
    ${RECIPIENT_CUSTOM_STAGES}
`;
