import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { SourcerMemberCardInsightsList, SourcerMemberCardInsightsTitle } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface Insight {
    id: string;
    sourcerMemberId: string;
    insight: string;
}

interface SourcerMemberCardInsightsProps {
    insights: Insight[];
}

const SourcerMemberCardInsights: FC<SourcerMemberCardInsightsProps> = ({ insights }) => {
    const theme = useTheme();

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: row nowrap;
                gap: 16px;
                padding: 16px 24px 16px 24px;
                background-color: ${theme.palette.primary.contrastText};
                border-radius: 8px;
            `}
        >
            <SourcerMemberCardInsightsTitle />
            <SourcerMemberCardInsightsList insights={insights} />
        </Box>
    );
};

export { SourcerMemberCardInsights };
