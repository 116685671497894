import Bugsnag, { Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

import { config } from 'config';
import { Template500 } from 'errors/components/templates';
import { Session } from 'shared/hooks/use-session';

const benignErrors: string[] = [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded.',
    `Unexpected token '<'`,
];

let bugsnagClient: Client;
let BugsnagErrorBoundary: React.ElementType;
let userSet = false;

export function setBugsnagUser(session: Session) {
    if (!userSet && bugsnagClient && !!session.user) {
        const { id, email, fullName } = session.user;
        bugsnagClient.setUser(id, email, fullName);
        userSet = true;
    }
}

function initialize() {
    if (!!config.BugsnagApiKey && !bugsnagClient) {
        bugsnagClient = Bugsnag.start({
            apiKey: config.BugsnagApiKey,
            appVersion: config.AppVersion,
            plugins: [new BugsnagPluginReact()],
            // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#discarding-events
            onError: (event) => {
                // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#errors
                if (benignErrors.includes(event.errors?.[0]?.errorMessage)) {
                    return false;
                }
                return true;
            },
        });
        BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)!;
    }
}

/**
 * ref: https://docs.bugsnag.com/platforms/react-native/expo/capturing-render-errors/
 */
const ErrorView = () => <Template500 />;

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
    if (!config.BugsnagApiKey) {
        return <div>{children}</div>;
    }
    return <BugsnagErrorBoundary FallbackComponent={ErrorView}>{children}</BugsnagErrorBoundary>;
};

function getErrorBoundary() {
    initialize();
    return ErrorBoundary;
}

export { getErrorBoundary };
