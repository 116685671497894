import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { EmptyState } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { CreditCardUpload } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoCreditsNoticeProps {}
const NoCreditsNotice: FC<NoCreditsNoticeProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.no-credits-notice');

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <CreditCardUpload
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                />
            }
            title={translate('title')}
            description={
                <Box
                    css={css`
                        display: flex;
                        flex-flow: column nowrap;
                        gap: ${spacing.space16px};
                    `}
                >
                    <Box>{translate('description-1')}</Box>
                    <Box>{translate('description-2')}</Box>
                </Box>
            }
        />
    );
};

export { NoCreditsNotice };
