import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { standardizeUrl } from 'profile-parser';

import {
    AddOrUpdateProspectMutation,
    AddOrUpdateProspectMutationVariables,
    ProjectMembersAndProspectsQuery,
    ProjectMembersAndProspectsQueryVariables,
    ProspectsUrlsQuery,
    ProspectsUrlsQueryVariables,
    SourcerMemberEducation,
    SourcerMemberExperience,
    UpdateSourcerMembersProspectIdMutation,
    UpdateSourcerMembersProspectIdMutationVariables,
} from 'codegen/graphql';
import { GET_PROJECT_MEMBERS_AND_PROSPECTS } from 'shared/graphql/project-members';
import { ADD_OR_UPDATE_PROSPECT, GET_PROSPECTS_URLS } from 'shared/graphql/prospects';
import { SourcerMemberData, UPDATE_SOURCER_MEMBERS_PROSPECT_ID } from 'shared/graphql/sourcer-members';

type PartialSourcerMemberExperience = Pick<
    SourcerMemberExperience,
    | 'title'
    | 'companyName'
    | 'companyLogo'
    | 'startDateMonth'
    | 'startDateYear'
    | 'endDateMonth'
    | 'endDateYear'
    | 'current'
>;

type PartialSourcerMemberEducation = Pick<
    SourcerMemberEducation,
    'degree' | 'fieldOfStudy' | 'schoolName' | 'startDateYear' | 'endDateYear'
>;

export const useProspectCreator = () => {
    const [addOrUpdateProspectData] = useMutation<AddOrUpdateProspectMutation, AddOrUpdateProspectMutationVariables>(
        ADD_OR_UPDATE_PROSPECT
    );
    const [getProspectsUrls] = useLazyQuery<ProspectsUrlsQuery, ProspectsUrlsQueryVariables>(GET_PROSPECTS_URLS);

    const [updateSourcerMemberProspectId] = useMutation<
        UpdateSourcerMembersProspectIdMutation,
        UpdateSourcerMembersProspectIdMutationVariables
    >(UPDATE_SOURCER_MEMBERS_PROSPECT_ID, { fetchPolicy: 'no-cache' });

    const [getProjectMembersQuery] = useLazyQuery<
        ProjectMembersAndProspectsQuery,
        ProjectMembersAndProspectsQueryVariables
    >(GET_PROJECT_MEMBERS_AND_PROSPECTS);

    const createProspectsFromSourcerMembers = async (sourcerMembers: SourcerMemberData[]) => {
        const addProspectInputObjects = sourcerMembers.map((sourcerMember) => {
            const profile = buildProspectProfileFromSourcerMember(sourcerMember);
            const url = standardizeUrl(sourcerMember.urls[0].url);
            return { url, profile };
        });

        const addProspectsResponse = await addOrUpdateProspectData({
            variables: { data: addProspectInputObjects },
        });
        const prospectIds = addProspectsResponse.data?.add_prospect.map((prospect) => prospect.id) ?? [];

        // We need to get the prospectUrls because the addProspectsResponse does not return the urls.
        const { data: prospectUrlsData } = await getProspectsUrls({ variables: { prospectIds } });
        const prospects = prospectUrlsData?.prospects ?? [];

        const updateProspectIdObjects = prospects.map((prospect) => {
            // Assumption: sourcer member will always be found.
            // This should be true because we created the prospect from the sourcer member
            const sourcerMember = findMatchingSourcerMemberByURLs(prospect, sourcerMembers)!;

            return createUpdateProspectIdUpsertObject({
                sourcerMember,
                prospectId: prospect.id,
            });
        });

        const updateSourcerMemberResponse = await updateSourcerMemberProspectId({
            variables: { sourcerMembers: updateProspectIdObjects },
        });

        return updateSourcerMemberResponse.data?.insert_sourcer_members?.returning;
    };

    const buildProspectProfileFromSourcerMember = (sourcerMember: SourcerMemberData) => {
        const latestExperience = findSourcerMemberLatestExperience([...sourcerMember.experience]);
        const latestEducation = findSourcerMemberLatestEducation([...sourcerMember.education]);
        return {
            firstName: sourcerMember.firstName,
            lastName: sourcerMember.lastName,
            fullName: sourcerMember.fullName,
            location: sourcerMember.location,
            latestCompany: latestExperience?.companyName,
            title: latestExperience?.title,
            latestSchool: latestEducation?.schoolName,
            experiences: sourcerMember.experience.map(
                ({
                    companyName,
                    title,
                    current,
                    startDateMonth,
                    startDateYear,
                    endDateMonth,
                    endDateYear,
                    companyLogo,
                }) => ({
                    companyName,
                    title,
                    current,
                    startDateMonth,
                    startDateYear,
                    endDateMonth,
                    endDateYear,
                    companyLogo,
                })
            ),

            educations: sourcerMember.education.map(
                ({ degree, fieldOfStudy, schoolName, startDateYear, endDateYear }) => ({
                    degree,
                    fieldOfStudy,
                    schoolName,
                    startDateYear,
                    endDateYear,
                })
            ),
        };
    };

    /**
     * Receives a prospect and an array of sourcer members and finds which sourcer member matches the prospect
     * by looking at their urls. We have to find which prospect corresponds to which sourcer member
     * because the order of the prospects returned from the API is not guaranteed.
     */

    const findMatchingSourcerMemberByURLs = (
        prospect: { id: string; urls?: { url: string }[] | null },
        sourcerMembers: SourcerMemberData[]
    ) => {
        const sourcerMember = sourcerMembers.find((sm) => {
            const sourcerMemberUrl = standardizeUrl(sm.urls[0].url);
            const prospectUrl = standardizeUrl(prospect.urls![0].url); // assumption: prospect always has a url
            return sourcerMemberUrl === prospectUrl;
        });
        return sourcerMember;
    };

    const findSourcerMemberLatestExperience = (
        experiences: PartialSourcerMemberExperience[]
    ): PartialSourcerMemberExperience | undefined => {
        experiences.sort((a, b) => {
            const endDateTimeA = a.current
                ? DateTime.now()
                : DateTime.fromObject({ month: a.endDateMonth ?? undefined, year: a.endDateYear ?? undefined });
            const endDateTimeB = b.current
                ? DateTime.now()
                : DateTime.fromObject({ month: b.endDateMonth ?? undefined, year: b.endDateYear ?? undefined });
            return endDateTimeB.valueOf() - endDateTimeA.valueOf();
        });
        const latestExperience = experiences[0]; // experiences[0] could be undefined;
        return latestExperience;
    };

    const findSourcerMemberLatestEducation = (
        educations: PartialSourcerMemberEducation[]
    ): PartialSourcerMemberEducation | undefined => {
        educations.sort((a, b) => Number(b.endDateYear) - Number(a.startDateYear));
        const latestEducation = educations[0] || {}; // educations[0] could be undefined;
        return latestEducation;
    };
    const createUpdateProspectIdUpsertObject = ({
        sourcerMember,
        prospectId,
    }: {
        sourcerMember: SourcerMemberData;
        prospectId: string;
    }) => ({
        id: sourcerMember.id,
        sourcerId: sourcerMember.sourcer.id,
        firstName: sourcerMember.firstName,
        lastName: sourcerMember.lastName,
        fullName: sourcerMember.fullName,
        prospectId,
    });

    const filterProjectDuplicates = async (
        prospects: { id: string; urls?: { url: string }[] | null }[],
        projectId: string
    ) => {
        const prospectUrls = prospects.map((prospect) => prospect.urls![0].url);

        const { data: projectMembersData } = await getProjectMembersQuery({
            variables: {
                where: { projectId: { _eq: projectId }, prospect: { urls: { url: { _in: prospectUrls } } } },
            },
        });

        const projectMembersUrls =
            projectMembersData?.project_members?.map((projectMember) => projectMember.prospect!.urls![0].url) ?? [];

        // filter out prospects that are already in the project
        const prospectsToAdd = prospects.filter((prospect) => !projectMembersUrls.includes(prospect.urls![0].url));
        return prospectsToAdd;
    };

    return { createProspectsFromSourcerMembers, buildProspectProfileFromSourcerMember, filterProjectDuplicates };
};
