import { useLazyQuery } from '@apollo/client';

import {
    HireflowEnumsTeamFeaturesEnum,
    TeamAiSourcingCreditsQuery,
    TeamFeatureCheckQuery,
    TeamFeatureCheckQueryVariables,
} from 'codegen/graphql';
import { GET_TEAM_AI_SOURCING_CREDITS, TEAM_FEATURE_CHECK } from 'shared/graphql/teams';

export const useAiSourcingCredits = () => {
    const [getAiSourcingCredits] = useLazyQuery<TeamAiSourcingCreditsQuery>(GET_TEAM_AI_SOURCING_CREDITS, {
        fetchPolicy: 'no-cache',
    });

    const [getTeamFeature] = useLazyQuery<TeamFeatureCheckQuery, TeamFeatureCheckQueryVariables>(TEAM_FEATURE_CHECK, {
        fetchPolicy: 'no-cache',
    });

    const getAiSourcingCreditsAvailable = async () => {
        const result = await getAiSourcingCredits();

        if (result && result.data && result.data.teams && result.data.teams.length > 0) {
            return result.data.teams[0].aiSourcerCredits;
        }
        return 0;
    };

    const isPayOnApproveEnabled = async () => {
        const { data } = await getTeamFeature({
            variables: {
                feature: HireflowEnumsTeamFeaturesEnum.AiSourcingPayPerApprovedCandidate,
            },
        });

        if (data && data.team_features.length > 0) {
            return true;
        }
        return false;
    };

    return {
        getAiSourcingCreditsAvailable,
        isPayOnApproveEnabled,
    };
};
