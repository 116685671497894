import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { trackLoadTab } from 'analytics';
import { RecipientsQuery } from 'codegen/graphql';
import { DetailsActivityLog } from 'sequences/components/composite';
import { SequenceAnalytics } from 'sequences/components/composite/sequence-analytics';
import { EditSettingsForm, EditStagesForm } from 'sequences/components/form';
import { EmptyRecipientsNotice } from 'sequences/components/presentational';
import { RecipientListTable } from 'sequences/components/tables';
import { Tab, TabPanel, Tabs, TabsContainer, TabWithCount } from 'shared/components/presentational';
import { SequenceData } from 'shared/graphql/sequences';
import { useListSubmenuTab } from 'shared/hooks';
import { FC } from 'shared/types';

type RecipientData = RecipientsQuery['recipients'];

interface DetailsTabsProps {
    sequence: SequenceData;
    recipients?: RecipientData;
    count?: number;
    statsFilter: string;
}

const DetailsTabs: FC<DetailsTabsProps> = (props) => {
    const { sequence, recipients, count, statsFilter } = props;
    const translate = useTranslations('sequence.details');

    const { currentTab, setCurrentTab } = useListSubmenuTab();

    useEffect(() => {
        trackLoadTab(
            currentTab === 0
                ? 'sequence_details_recipients_tab'
                : currentTab === 1
                ? 'sequence_details_edit_tab'
                : // eslint-disable-next-line no-magic-numbers
                currentTab === 2
                ? 'sequence_details_settings_tab'
                : // eslint-disable-next-line no-magic-numbers
                currentTab === 3
                ? 'sequence_details_activity_log_tab'
                : 'sequence_details_analytics_tab'
        );
    }, [currentTab]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <TabsContainer>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="sequence details tabs"
                >
                    <TabWithCount active={currentTab === 0} value={0} label={translate('recipients')} count={count} />
                    <Tab value={1} label={translate('edit')} />
                    <Tab value={2} label={translate('settings')} />
                    <Tab value={3} label={translate('activity-log')} />
                    <Tab value={4} label={translate('analytics')} />
                </Tabs>
            </TabsContainer>

            <TabPanel value={currentTab} index={0}>
                {recipients?.length ? (
                    <RecipientListTable sequence={sequence} statsFilter={statsFilter} />
                ) : (
                    <EmptyRecipientsNotice />
                )}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <EditStagesForm sequence={sequence} />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <EditSettingsForm sequence={sequence} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <DetailsActivityLog sequence={sequence} />
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
                <SequenceAnalytics sequence={sequence} />
            </TabPanel>
        </>
    );
};

export { DetailsTabs };
