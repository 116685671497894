/* eslint-disable max-len */
import { gql } from '@apollo/client';

import { OrderBy, ProspectPanelInfoQuery, RecipientsOrderBy } from 'codegen/graphql';
import { USER_DETAILS } from 'shared/graphql/users';

export type ProspectRecipientData = ProspectPanelInfoQuery['recipients'][number];

export const RECIPIENT_DETAILS = gql`
    fragment RecipientDetails on recipients {
        id
        prospectId
        status
        nextMessageScheduledAt
        lastStageSent
        lastStageSentAt
        completedAt
        manuallyExitedAt
        respondedAt
        meetingScheduledAt
        createdAt
        addedBy
        recipientMessageSends: recipient_message_sends {
            id
            stageId
            customStageId
            stageIndex
            sequence_stage {
                sequence {
                    title
                }
            }
            email_message {
                id
                account
                recipientId
                subject
                data
                provider
                bounceReport
                response
                internalDate
                threadId
                email_account {
                    userId
                }
            }
        }
        recipientResponses: recipient_responses(order_by: { createdAt: desc }) {
            recipient {
                sequence {
                    title
                }
            }
            email_message {
                id
                account
                recipientId
                subject
                data
                provider
                bounceReport
                response
                internalDate
                threadId
                email_account {
                    userId
                }
            }
            method
            sentiment
            createdAt
        }
        emailStats: email_stats {
            sent
            opens
            clicks
        }
        customStages: custom_stages {
            id
            cc
            bcc
            recipientId
            stageIndex
            content
            subject
            numberOfDays
            replyToLastEmail
            sendTime
            sendTimeOfDay
            sendTypeOfDays
            signature
            useSignature
            sendFromAccountId
            sendFromAccount: send_from_account {
                id
                email
                email_account {
                    user {
                        ...UserDetails
                    }
                }
            }
        }
        sequence {
            id
            title
            userId
            modifiedAt
            createdAt
            status
            stages(order_by: { stageIndex: asc }) {
                id
                bcc
                cc
                content
                numberOfDays
                replyToLastEmail
                sendTime
                sendTimeOfDay
                sendTypeOfDays
                signature
                useSignature
                subject
                stageIndex
                sendFromAccountId
                sendFromAccount: send_from_account {
                    id
                    email
                    email_account {
                        user {
                            ...UserDetails
                        }
                    }
                }
            }
            collaborators {
                id
                userId
            }
        }
        contacts {
            id
            recipientId
            contactId
            channel
            createdAt
            modifiedAt
        }
        prospect {
            id
            addedBy
            inMailSent
            profile {
                id
                firstName
                lastName
                fullName
                latestCompany
                latestSchool
                title
                notes
                firstNameCustomized
                lastNameCustomized
                fullNameCustomized
                latestCompanyCustomized
                latestSchoolCustomized
                titleCustomized
                location
            }
            urls {
                url
            }
            contacts {
                id
                kind
                value
                standardizedValue
                channel
                primary
                invalid
            }
            createdAt
            sourcer_members {
                id
            }
        }
        user {
            id
            fullName
        }
    }
    ${USER_DETAILS}
    ${USER_DETAILS}
`;

export const recipientsDefaultOrderBy: keyof RecipientsOrderBy = 'createdAt';
export const recipientsDefaultOrderByDirection = OrderBy.DescNullsLast;

export const recipientsDefaultVariables = {
    orderBy: { [recipientsDefaultOrderBy]: recipientsDefaultOrderByDirection },
    limit: 25,
    offset: 0,
    where: {},
};

export const GET_RECIPIENTS = gql`
    query Recipients(
        $orderBy: recipients_order_by!
        $limit: Int
        $offset: Int = 0
        $where: recipients_bool_exp!
        $distinct_on: [recipients_select_column!]
    ) {
        recipients_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        recipients(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where, distinct_on: $distinct_on) {
            ...RecipientDetails
        }
    }

    ${RECIPIENT_DETAILS}
`;

export const CREATE_RECIPIENTS = gql`
    mutation CreateRecipient($recipients: [recipients_insert_input!]!) {
        insert_recipients(
            objects: $recipients
            on_conflict: { constraint: recipients_prospectId_sequenceId_key, update_columns: [] }
        ) {
            affected_rows
            returning {
                ...RecipientDetails
            }
        }
    }
    ${RECIPIENT_DETAILS}
`;

export const UPDATE_RECIPIENTS = gql`
    mutation UpdateRecipients($where: recipients_bool_exp!, $set: recipients_set_input!) {
        update_recipients(where: $where, _set: $set) {
            returning {
                ...RecipientDetails
            }
        }
    }

    ${RECIPIENT_DETAILS}
`;

export const UPDATE_RECIPIENT_RESPONSE_STATUS = gql`
    mutation UpdateRecipientResponseStatus(
        $id: String!
        $updateData: recipients_set_input!
        $insertData: recipient_responses_insert_input!
    ) {
        recipient: update_recipients_by_pk(pk_columns: { id: $id }, _set: $updateData) {
            id
            status
        }
        insert_recipient_responses_one(object: $insertData) {
            id
        }
    }
`;

export const DELETE_RECIPIENT_RESPONSE = gql`
    mutation DeleteRecipientResponse($where: recipient_responses_bool_exp!) {
        delete_recipient_responses(where: $where) {
            affected_rows
        }
    }
`;

export const CREATE_RECIPIENT_RESPONSE = gql`
    mutation CreateRecipientResponse($insertData: recipient_responses_insert_input!) {
        insert_recipient_responses_one(object: $insertData) {
            id
        }
    }
`;

export const GET_RECIPIENT_CONVERSATIONS = gql`
    query RecipientConversations($where: recipients_bool_exp!, $orderBy: recipients_order_by!) {
        recipients(where: $where, order_by: [$orderBy]) {
            id
            prospectId
            status
            nextMessageScheduledAt
            lastStageSent
            lastStageSentAt
            completedAt
            manuallyExitedAt
            respondedAt
            meetingScheduledAt
            createdAt
            prospect {
                id
                profile {
                    id
                    firstName
                    firstNameCustomized
                }
            }
            sequence {
                id
                title
                userId
                modifiedAt
                createdAt
                status
                collaborators {
                    id
                    userId
                }
                stages(order_by: { stageIndex: asc }) {
                    id
                    bcc
                    cc
                    content
                    numberOfDays
                    replyToLastEmail
                    sendTime
                    sendTimeOfDay
                    sendTypeOfDays
                    signature
                    useSignature
                    subject
                    stageIndex
                    sendFromAccountId
                    sendFromAccount: send_from_account {
                        id
                        email
                        email_account {
                            user {
                                ...UserDetails
                            }
                        }
                    }
                }
            }
            customStages: custom_stages {
                id
                cc
                bcc
                recipientId
                stageIndex
                content
                subject
                numberOfDays
                replyToLastEmail
                sendTime
                sendTimeOfDay
                sendTypeOfDays
                signature
                useSignature
                sendFromAccountId
                sendFromAccount: send_from_account {
                    id
                    email
                    email_account {
                        user {
                            ...UserDetails
                        }
                    }
                }
            }
            recipientMessageSends: recipient_message_sends {
                id
                stageId
                customStageId
                stageIndex
                sequence_stage {
                    sequence {
                        title
                    }
                }
                email_message {
                    id
                    account
                    contactId
                    recipientId
                    subject
                    data
                    provider
                    bounceReport
                    response
                    internalDate
                    threadId
                    email_account {
                        userId
                    }
                }
            }
            recipientResponses: recipient_responses(order_by: { createdAt: desc }) {
                recipient {
                    sequence {
                        title
                    }
                }
                email_message {
                    id
                    account
                    contactId
                    recipientId
                    subject
                    data
                    provider
                    bounceReport
                    response
                    internalDate
                    threadId
                    email_account {
                        userId
                    }
                }
                method
                sentiment
                createdAt
            }
            contacts {
                id
                recipientId
                contactId
                channel
                createdAt
                modifiedAt
            }
        }
    }
    ${USER_DETAILS}
`;

export const GET_RECIPIENTS_COUNT = gql`
    query RecipientsCount($where: recipients_bool_exp!) {
        recipients_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
