import { withTheme } from '@emotion/react';

import { FC } from 'shared/types';

interface PinProps {
    stroke?: string;
}

const ThemedPin: FC<PinProps> = ({ stroke, theme, ...rest }) => (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M6.8791 4.19423C6.58506 3.45914 6.43804 3.09159 6.49935 2.79655C6.55297 2.53853 6.70627 2.31211 6.92591 2.1665C7.17709 2 7.57295 2 8.36466 2H15.6382C16.4299 2 16.8257 2 17.0769 2.1665C17.2965 2.31211 17.4498 2.53853 17.5035 2.79655C17.5648 3.09159 17.4178 3.45914 17.1237 4.19423L16.1158 6.71391C16.0731 6.8208 16.0517 6.87424 16.0367 6.92937C16.0233 6.97833 16.0137 7.02825 16.0079 7.07868C16.0014 7.13544 16.0014 7.19301 16.0014 7.30813V9.43875C16.0014 9.64677 16.0014 9.75078 16.0218 9.85026C16.0399 9.93852 16.0699 10.0239 16.1109 10.1042C16.1571 10.1946 16.2221 10.2758 16.352 10.4383L17.9218 12.4005C18.5875 13.2327 18.9204 13.6487 18.9208 13.9989C18.9211 14.3035 18.7826 14.5916 18.5446 14.7815C18.271 15 17.7381 15 16.6724 15H7.33041C6.26471 15 5.73186 15 5.45818 14.7815C5.22017 14.5916 5.0817 14.3035 5.08203 13.9989C5.08241 13.6487 5.41528 13.2327 6.08102 12.4005L7.6508 10.4383C7.78074 10.2758 7.84572 10.1946 7.89192 10.1042C7.93291 10.0239 7.96287 9.93852 7.98099 9.85026C8.00141 9.75078 8.00141 9.64677 8.00141 9.43875V7.30813C8.00141 7.19301 8.00141 7.13544 7.9949 7.07868C7.98912 7.02825 7.9795 6.97833 7.96614 6.92937C7.9511 6.87424 7.92973 6.8208 7.88697 6.71391L6.8791 4.19423Z"
            fill={theme?.palette?.primary?.main}
        />
        <path
            // eslint-disable-next-line max-len
            d="M12.0014 15L12.0014 22M8.00141 7.30813V9.43875C8.00141 9.64677 8.00141 9.75078 7.98099 9.85026C7.96287 9.93852 7.93291 10.0239 7.89192 10.1042C7.84572 10.1946 7.78074 10.2758 7.6508 10.4383L6.08102 12.4005C5.41528 13.2327 5.08241 13.6487 5.08203 13.9989C5.0817 14.3035 5.22017 14.5916 5.45818 14.7815C5.73186 15 6.26471 15 7.33041 15H16.6724C17.7381 15 18.271 15 18.5446 14.7815C18.7826 14.5916 18.9211 14.3035 18.9208 13.9989C18.9204 13.6487 18.5875 13.2327 17.9218 12.4005L16.352 10.4383C16.2221 10.2758 16.1571 10.1946 16.1109 10.1042C16.0699 10.0239 16.0399 9.93852 16.0218 9.85026C16.0014 9.75078 16.0014 9.64677 16.0014 9.43875V7.30813C16.0014 7.19301 16.0014 7.13544 16.0079 7.07868C16.0137 7.02825 16.0233 6.97833 16.0367 6.92937C16.0517 6.87424 16.0731 6.8208 16.1158 6.71391L17.1237 4.19423C17.4178 3.45914 17.5648 3.09159 17.5035 2.79655C17.4498 2.53853 17.2965 2.31211 17.0769 2.1665C16.8257 2 16.4299 2 15.6382 2H8.36466C7.57295 2 7.17709 2 6.92591 2.1665C6.70627 2.31211 6.55297 2.53853 6.49935 2.79655C6.43804 3.09159 6.58506 3.45914 6.8791 4.19423L7.88697 6.71391C7.92973 6.8208 7.9511 6.87424 7.96614 6.92937C7.9795 6.97833 7.98912 7.02825 7.9949 7.07868C8.00141 7.13544 8.00141 7.19301 8.00141 7.30813Z"
            stroke={stroke ?? theme?.palette?.primary?.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Pinned = withTheme(ThemedPin);

export { Pinned };
