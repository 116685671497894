import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface FormSectionProps {
    title: string;
    subTitle?: string | EmotionJSX.Element;
}

const FormSection: FC<FormSectionProps> = (props) => {
    const { title, subTitle, children } = props;
    const theme = useTheme();

    return (
        <Box>
            <Box
                className="form-section-title-and-subtitle"
                css={css`
                    margin-bottom: 12px;
                `}
            >
                <Title
                    className="form-section-title"
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                        margin-bottom: ${subTitle ? '4px' : '16px'};
                    `}
                >
                    {title}
                </Title>
                {subTitle && (
                    <p
                        css={css`
                            display: flex;

                            color: ${theme.palette.grey[200]};
                            margin: 0;
                            margin-bottom: 16px;
                        `}
                    >
                        {subTitle}
                    </p>
                )}
            </Box>

            {children}
        </Box>
    );
};

export { FormSection };
