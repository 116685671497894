import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectCardButtonsContainerProps {}

const ProspectCardButtonsContainer: FC<ProspectCardButtonsContainerProps> = ({ children, ...rest }) => (
    <Box
        css={css`
            display: flex;
            justify-content: flex-end;
            gap: ${spacing.space10px};
            align-items: center;
        `}
        {...rest}
    >
        {children}
    </Box>
);

export { ProspectCardButtonsContainer };
