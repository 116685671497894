import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLock: FC<SvgProps> = ({ stroke, width, height, fill, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '48'}
        height={height ?? '52'}
        viewBox="0 0 48 52"
        fill={fill ?? 'none'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M37.3334 23.3333V15.3333C37.3334 7.96954 31.3639 2 24.0001 2C16.6363 2 10.6667 7.96954 10.6667 15.3333V23.3333M15.4667 50H32.5334C37.0138 50 39.254 50 40.9653 49.1281C42.4706 48.3611 43.6945 47.1372 44.4615 45.6319C45.3334 43.9206 45.3334 41.6804 45.3334 37.2V36.1333C45.3334 31.6529 45.3334 29.4127 44.4615 27.7014C43.6945 26.1961 42.4706 24.9723 40.9653 24.2053C39.254 23.3333 37.0138 23.3333 32.5334 23.3333H15.4667C10.9863 23.3333 8.74612 23.3333 7.03482 24.2053C5.52953 24.9723 4.30568 26.1961 3.5387 27.7014C2.66675 29.4127 2.66675 31.6529 2.66675 36.1333V37.2C2.66675 41.6804 2.66675 43.9206 3.5387 45.6319C4.30568 47.1372 5.52953 48.3611 7.03482 49.1281C8.74612 50 10.9863 50 15.4667 50Z"
            stroke={stroke ?? 'black'}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Lock = withTheme(ThemedLock);

export { Lock };
