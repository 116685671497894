import { getDomain, isHighQualityPersonalEmail } from 'hireflow-shared/lib/emails/email-utils';
import { orderBy, toPairs } from 'lodash';
import validator from 'validator';

import { ContactChannelsEnum, ContactKindsEnum, OrderBy, ProfileDetailsFragment } from 'codegen/graphql';
import { ProspectContact } from 'shared/types/prospect';

function isEmailValid(email: string): boolean {
    return validator.isEmail(email);
}

function pickPersonalEmail(contacts: ProspectContact[]) {
    const gmailEmail = contacts.find((e) => !!e.value.match(/@gmail.com$/i));
    if (gmailEmail) return gmailEmail;

    const highQualityEmail = contacts.find((e) => isHighQualityPersonalEmail(e.value));
    if (highQualityEmail) return highQualityEmail;

    const personalEmail = contacts.find((c) => c.kind === ContactKindsEnum.Personal);
    if (personalEmail) return personalEmail;

    const educational = contacts.find((c) => c.value.endsWith('.edu'));
    if (educational) return educational;

    return undefined;
}

/**
 * IMPORTANT : THIS FUNCTION IS A COPY OF THE ONE IN server/src/lib/emails/select-email.ts
 * IF YOU ARE MAKING CHANGES TO THIS FUNCTION, MAKE SURE TO ALSO MAKE THE SAME CHANGES IN THE ORIGINAL FUNCTION
 *
 * Tries to find the best work email for the prospect. First, we try to find an email that matches the prospect's
 * current company. If we can't find one, return null. We don't want to return an email of a past company because
 * it's likely to bounce. If more than one email matches the prospect's current company, it returns the email that
 * matches the prospect's name the best.
 */
function pickCurrentWorkEmail(contacts: ProspectContact[], profile?: ProfileDetailsFragment | null) {
    if (contacts.length === 0) return undefined;
    if (!profile) return undefined;
    if (
        contacts.filter((c) => c.channel === ContactChannelsEnum.Email && c.kind === ContactKindsEnum.Personal)
            .length === contacts.length
    )
        return undefined;

    const unknownWorkEmails = contacts.filter(
        (c) => c.channel === ContactChannelsEnum.Email && c.kind !== ContactKindsEnum.Personal
    );

    const nameSplit = profile.fullName
        .replace(/[^a-z]/gi, ' ')
        .toLocaleLowerCase()
        .split(' ')
        .filter((v) => v.length > 0);

    const currentCompany = profile.latestCompanyCustomized ?? profile.latestCompany;
    const companyNameSplit = currentCompany
        ? currentCompany
              .replace(/[^a-z]/gi, ' ')
              .toLocaleLowerCase()
              .split(' ')
              .filter((v) => v.length > 0)
        : [];

    const nameScores: { [email: string]: number } = {};
    const companyScores: { [email: string]: number } = {};

    for (const e of unknownWorkEmails) {
        const domainValue = getDomain(e.value);
        if (domainValue) {
            const domain = domainValue.toLocaleLowerCase();
            for (const n of companyNameSplit) {
                const nMatch = domain.match(n);
                if (nMatch) {
                    if (companyScores[e.value]) {
                        companyScores[e.value] += 1;
                    } else {
                        companyScores[e.value] = 1;
                    }
                }
            }
        }

        const emailTarget = e.value.split('@')[0].toLocaleLowerCase();
        for (const n of nameSplit) {
            const nMatch = emailTarget.match(n);
            if (nMatch) {
                if (nameScores[e.value]) {
                    nameScores[e.value] += 1;
                } else {
                    nameScores[e.value] = 1;
                }
            }
        }
    }

    const highestEmailForCompany = orderBy(toPairs(companyScores), (v) => v[1], [OrderBy.Desc]);
    const highestEmailForName = orderBy(toPairs(nameScores), (v) => v[1], [OrderBy.Desc]);

    if (
        highestEmailForCompany.length === 1 ||
        (highestEmailForCompany.length > 1 && highestEmailForCompany[0][1] > highestEmailForCompany[1][1])
    ) {
        return unknownWorkEmails.find((u) => u.value === highestEmailForCompany[0][0]);
    }

    if (highestEmailForCompany.length > 1) {
        const topCompanies = highestEmailForCompany.filter((h) => h[1] === highestEmailForCompany[0][1]);
        if (highestEmailForName.length > 0) {
            for (const byName of highestEmailForName) {
                if (topCompanies.find((c) => c[0] === byName[0])) {
                    return unknownWorkEmails.find((u) => u.value === byName[0]);
                }
            }
        }
    }

    return undefined;
}

export { isEmailValid, pickPersonalEmail, pickCurrentWorkEmail };
