import { useTranslations } from 'next-intl';

import { PrimaryEmail } from 'settings/components/presentational/account-and-emails/primary-email';
import { UserName } from 'settings/components/presentational/account-and-emails/user-name';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';

const AccountInformation = () => {
    const translate = useTranslations('settings.account-settings');
    return (
        <Box>
            <Title type="h2">{translate('account-information')}</Title>
            <UserName />
            <PrimaryEmail />
        </Box>
    );
};

export { AccountInformation };
