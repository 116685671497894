import { ShareSequenceListRow } from 'sequences/components/presentational/sharing';
import { SequenceCollaboratorForSharing } from 'sequences/types';
import { List } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface ShareSequenceListProps {
    collaborators: SequenceCollaboratorForSharing[];
    onRemove: (collaboratorId: string) => void;
}
const ShareSequenceList: FC<ShareSequenceListProps> = ({ collaborators, onRemove }) => (
    <List type="unordered">
        {collaborators.map((collaborator, index) => (
            <ShareSequenceListRow key={index} collaborator={collaborator} onRemove={onRemove} />
        ))}
    </List>
);
export { ShareSequenceList };
