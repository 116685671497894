import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EmptyStateProps {
    icon: React.ReactNode;
    title: string;
    description: string | React.ReactNode;
    button?: React.ReactNode;
}

const EmptyState: FC<EmptyStateProps> = (props) => {
    const { icon, title, description, button, ...rest } = props;
    return (
        <Box className="root" {...rest}>
            {icon}
            <Box className="title">{title}</Box>
            <Box className="description">{description}</Box>
            {button}
        </Box>
    );
};

const StyledEmptyState = styled(EmptyState)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: ${spacing.space16px};

    background-color: ${(props) => props.theme.palette.common.white};
    padding: 120px 150px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.grey[500]};
    text-align: center;

    .title {
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.f32};
        font-family: ${fontFamilies.poppins};
    }

    .description {
        font-weight: ${fontWeights.normal};
        font-size: ${fontSizes.f16};
        max-width: 735px;
    }
`;

export { StyledEmptyState as EmptyState };
