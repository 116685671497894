import { personalizeMagicSentence } from 'hireflow-shared/utils/email-drafting-utils';

import { Stage } from 'sequences/types';

/**
 * check if the magic sentence personalization variable exists in any of the sequence' stages
 */
export function isMagicSentenceUsed(stages: readonly Stage[]) {
    let res: boolean = false;
    stages.forEach((s) => {
        if (s.content.includes(personalizeMagicSentence)) res = true;
    });
    return res;
}
