export const windowTarget: string = '_blank';
export const windowFeatures: string = 'noopener,noreferrer';
export const showPopupQueryParam: string = 'showPopup';
export const hireflowSupport: string = 'https://www.hireflow.ai/support';

// settings popover z-index is 1300
// the select container z-index is 99
export const layoutZIndex: string = '1299'; // settings popover z-index is 1300
export const maxZIndex: string = '1301'; // settings popover z-index is 1300

export const sideMenuWidth: string = '240px';
export const sideMenuSmallWidth: string = '72px';
export const appMaxWidth: string = `calc(100vw - ${sideMenuWidth} - 64px)`;
export const appMaxSmallWidth: string = `calc(100vw - ${sideMenuSmallWidth} - 64px)`;
