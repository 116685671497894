import styled from '@emotion/styled';
import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps, Slide } from '@mui/material';

import { FC } from 'shared/types';

interface SnackbarProps extends MuiSnackbarProps {}

const Snackbar: FC<SnackbarProps> = (props) => (
    <MuiSnackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Slide}
        {...props}
    />
);

const StyledSnackbar = styled(Snackbar)`
    // this ensures that selects are not hidden by the snackbar
    z-index: 99 !important;

    @media (min-width: 1000px) {
        bottom: 8px;
        left: 8px;
        right: 8px;
        transform: unset;
    }
`;

export { StyledSnackbar as Snackbar };
