export enum AppFlow {
    Extension = 'extension',
    AiSourcing = 'ai_sourcing',
    OtherPartOfWebapp = 'other_part_of_webapp',
}

export enum SourcerMemberApprovalAction {
    ManualApprove = 'manual_approve',
    KebabApprove = 'kebab_approve',
}
