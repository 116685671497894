/* eslint-disable no-magic-numbers */
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { debounce, escapeRegExp, isEmpty, isEqual, xor } from 'lodash';
import { useTranslations } from 'next-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { trackEvent } from 'analytics';
import {
    BigintComparisonExp,
    InputMaybe,
    OrderBy,
    RecipientResponseSentimentTypesEnum,
    RecipientStatusEnum,
    RecipientsBoolExp,
    RecipientsOrderBy,
    RecipientsQuery,
    RecipientsQueryVariables,
    SequenceByIdQuery,
} from 'codegen/graphql';
import { RecipientListEditBar, RecipientListRow } from 'sequences/components/tables';
import { Box } from 'shared/components/containers';
import {
    Button,
    Checkbox,
    CheckboxFilter,
    DateFilter,
    DateOptions,
    FilterBarDescription,
    FilterTextField,
    Snackbar,
    TableHeaderEllipsisText,
    getDateFrom,
} from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from 'shared/components/table';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import {
    GET_RECIPIENTS,
    recipientsDefaultOrderBy,
    recipientsDefaultOrderByDirection,
    recipientsDefaultVariables,
} from 'shared/graphql/recipients';
import { removeEmptyProperties } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC, NestedKeys, RecipientsQueryData, SourcedByFilterValue, sourcedByFilterValues } from 'shared/types';
import { StatusOption, StatusOptionLabel, buildLastStageSentFilter, buildStatusFilter } from 'shared/utils';

interface Header {
    label:
        | 'prospect-header'
        | 'status-header'
        | 'current-company-header'
        | 'opens-header'
        | 'clicked-header'
        | 'date-added-header';
    field: RecipientData;
    width: string;
}
type RecipientData = NestedKeys<RecipientsQuery['recipients'][number]>;
type SequenceData = NonNullable<SequenceByIdQuery['sequences_by_pk']>;
type OrderByColumn = Header['label'];

const openedAndClickedFilterValues = ['opened', 'clicked', 'not-opened', 'not-clicked'] as const;
type OpenedAndClickedFilterValue = typeof openedAndClickedFilterValues[number];

// currently orderBy won't work with jsonb paths
// https://github.com/hasura/graphql-engine/issues/2592
const headers: Header[] = [
    { label: 'prospect-header', field: 'prospect.profile.fullName', width: '30%' },
    { label: 'status-header', field: 'status', width: '20%' },
    { label: 'current-company-header', field: 'prospect.profile.latestCompany', width: '25%' },
    { label: 'opens-header', field: 'email_stats.opens', width: '2.5%' },
    { label: 'clicked-header', field: 'email_stats.clicks', width: '2.5%' },
    { label: 'date-added-header', field: 'createdAt', width: '20%' },
];
const defaultRowsPerPageOptions = [25, 50, 100];
const defaultOrderByDirection = OrderBy.Desc;
const statusOptions: ReadonlyArray<StatusOption> = [
    {
        label: 'responded',
        status: [RecipientStatusEnum.Responded],
        sentiments: [],
    },
    {
        label: 'responded-interested',
        status: [RecipientStatusEnum.Responded],
        sentiments: [RecipientResponseSentimentTypesEnum.Interested],
    },
    {
        label: 'responded-not-interested',
        status: [RecipientStatusEnum.Responded],
        sentiments: [RecipientResponseSentimentTypesEnum.NotInterested],
    },
    {
        label: 'meeting-booked',
        status: [RecipientStatusEnum.MeetingBooked],
        sentiments: [],
    },
    {
        label: 'completed-with-no-reply',
        status: [RecipientStatusEnum.CompletedWithNoReply],
        sentiments: [],
    },
    {
        label: 'unable-to-find-contact',
        status: [RecipientStatusEnum.UnableToFindContact],
        sentiments: [],
    },
    {
        label: 'unable-to-send-message',
        status: [RecipientStatusEnum.UnableToSendMessage],
        sentiments: [],
    },
    {
        label: 'missing-template-variables',
        status: [RecipientStatusEnum.MissingTemplateVariables],
        sentiments: [],
    },
    {
        label: 'manually-exited',
        status: [RecipientStatusEnum.ManuallyExited],
        sentiments: [],
    },
    {
        label: 'unsubscribed',
        status: [RecipientStatusEnum.Unsubscribed],
        sentiments: [],
    },
    {
        label: 'not-started',
        status: [RecipientStatusEnum.NotStarted],
        sentiments: [],
    },
    {
        label: 'finding-email',
        status: [RecipientStatusEnum.FindingEmail],
        sentiments: [],
    },
    {
        label: 'in-progress',
        status: [RecipientStatusEnum.InProgress],
        sentiments: [],
    },
];
const filterTextDebounceTime = 500; // ms

interface RecipientListTableProps {
    sequence: SequenceData;
    statsFilter: string;
}

const RecipientListTable: FC<RecipientListTableProps> = ({ sequence, statsFilter }) => {
    const translate = useTranslations('sequence.recipient-list-table');

    const [offset, setOffset] = useState(recipientsDefaultVariables.offset);
    const [limit, setLimit] = useState(recipientsDefaultVariables.limit);

    const [statusFilters, setStatusFilters] = useState<RecipientStatusEnum[]>([]);
    const [checkedStatus, setCheckedStatus] = useState<StatusOption['label'][]>([]); // checkbox
    const isStatusFilterApplied = statusFilters.length > 0;

    const [sentimentFilters, setSentimentFilters] = useState<RecipientResponseSentimentTypesEnum[]>([]);
    const isSentimentFilterApplied = sentimentFilters.length > 0;

    const [openedAndClickedFilters, setOpenedAndClickedFilters] = useState<OpenedAndClickedFilterValue[]>([]);
    const isOpenedAndClickedFilterApplied = openedAndClickedFilters.length > 0;

    const [dateAddedFilterOption, setDateAddedFilterOption] = useState<DateOptions>();
    const isDateAddedFilterOptionApplied = Boolean(dateAddedFilterOption);

    const [sourcedByFilters, setSourcedByFilters] = useState<SourcedByFilterValue[]>([]);
    const isSourcedByFilterApplied = sourcedByFilters.length > 0;

    const [keywordFilter, setKeywordFilter] = useState('');
    const isKeywordFilterApplied = keywordFilter !== '';

    const areFiltersApplied =
        isStatusFilterApplied ||
        isSentimentFilterApplied ||
        isOpenedAndClickedFilterApplied ||
        isDateAddedFilterOptionApplied ||
        isSourcedByFilterApplied ||
        isKeywordFilterApplied;

    const [textFilter, setTextFilter] = useState<RecipientsBoolExp[]>([{}]);
    const debouncedSetTextFilter = useMemo(() => debounce(setTextFilter, filterTextDebounceTime), []);

    useEffect(() => {
        const regexText = `%${escapeRegExp(keywordFilter)}%`;
        debouncedSetTextFilter(
            isKeywordFilterApplied
                ? [
                      {
                          prospect: {
                              _or: [
                                  {
                                      profile: {
                                          _or: [
                                              { fullName: { _ilike: regexText } },
                                              { fullNameCustomized: { _ilike: regexText } },
                                              { title: { _ilike: regexText } },
                                              { latestCompany: { _ilike: regexText } },
                                              { location: { _ilike: regexText } },
                                          ],
                                      },
                                  },
                                  {
                                      contacts: {
                                          value: { _ilike: regexText },
                                      },
                                  },
                              ],
                          },
                      },
                  ]
                : [{}]
        );
    }, [keywordFilter, isKeywordFilterApplied, debouncedSetTextFilter]);

    const [orderBy, setOrderBy] = useState<OrderByColumn>(
        headers.find((h) => h.field === recipientsDefaultOrderBy)?.label || 'date-added-header'
    );

    // treat nulls as 0/"" (first when asc, last when desc)
    const [orderByDirection, setOrderByDirection] = useState<OrderBy.AscNullsFirst | OrderBy.DescNullsLast>(
        recipientsDefaultOrderByDirection
    );

    const newStatusOptions: StatusOption[] = Array.from({ length: sequence.stages.length }, (_, index) => ({
        label: `in-progress-stage-${index + 1}`,
        status: [RecipientStatusEnum.InProgress],
        sentiments: [],
    }));
    const usableStatusOptions = [...statusOptions, ...newStatusOptions];

    const convertHeaderFieldToOrderByFilter = useCallback(
        (field: RecipientData): RecipientsOrderBy => {
            switch (field) {
                case 'prospect.profile.fullName':
                    return { prospect: { profile: { fullName: orderByDirection } } };
                case 'status':
                    return { status: orderByDirection };
                case 'prospect.profile.latestCompany':
                    return { prospect: { profile: { latestCompany: orderByDirection } } };
                case 'email_stats.opens':
                    return { email_stats_aggregate: { sum: { opens: orderByDirection } } };
                case 'email_stats.clicks':
                    return { email_stats_aggregate: { sum: { clicks: orderByDirection } } };
                default:
                    return { [recipientsDefaultOrderBy]: orderByDirection };
            }
        },
        [orderByDirection]
    );

    const getLastStageSentFilter = useCallback(
        (): InputMaybe<BigintComparisonExp> => buildLastStageSentFilter({ checkedStatus }),
        [checkedStatus]
    );

    const getStatusFilter = useCallback(
        (includeInProgress: boolean) =>
            buildStatusFilter({
                includeInProgress,
                isStatusFilterApplied,
                statusFilters,
                isSentimentFilterApplied,
                sentimentFilters,
            }),
        [isSentimentFilterApplied, isStatusFilterApplied, sentimentFilters, statusFilters]
    );

    const getRecipientsVariables = useMemo(() => {
        const sourcedByFilter =
            sourcedByFilters.includes('ai-sourced') && sourcedByFilters.includes('not-ai-sourced')
                ? {}
                : sourcedByFilters.includes('ai-sourced')
                ? { sourcer_members: {} }
                : sourcedByFilters.includes('not-ai-sourced')
                ? { _not: { sourcer_members: {} } }
                : {};

        const queryVariables: Partial<RecipientsQueryVariables['where']> = {
            sequenceId: { _eq: sequence.id },
            createdAt: isDateAddedFilterOptionApplied ? { _gte: getDateFrom(dateAddedFilterOption!).getTime() } : {},
            ...(isSourcedByFilterApplied && { prospect: sourcedByFilter }),
            _and: [
                isEqual(statusFilters, [RecipientStatusEnum.Responded])
                    ? {
                          email_messages: { recipientId: { _is_null: false } }, // are emailed
                          lastStageSentAt: { _is_null: false }, // exclude manually marked
                      }
                    : {},
                isStatusFilterApplied || isSentimentFilterApplied || isOpenedAndClickedFilterApplied
                    ? {
                          _or: [
                              isStatusFilterApplied
                                  ? removeEmptyProperties({
                                        status: getStatusFilter(false),
                                    })
                                  : {},
                              isStatusFilterApplied && statusFilters.includes(RecipientStatusEnum.InProgress)
                                  ? removeEmptyProperties({
                                        status: getStatusFilter(true),
                                        lastStageSent: getLastStageSentFilter(),
                                    })
                                  : {},
                              isOpenedAndClickedFilterApplied && openedAndClickedFilters.includes('opened')
                                  ? { email_stats: { opens: { _gt: 0 } } }
                                  : {},
                              isOpenedAndClickedFilterApplied && openedAndClickedFilters.includes('not-opened')
                                  ? { email_stats: { opens: { _eq: 0 } } }
                                  : {},
                              isOpenedAndClickedFilterApplied && openedAndClickedFilters.includes('clicked')
                                  ? { email_stats: { clicks: { _gt: 0 } } }
                                  : {},
                              isOpenedAndClickedFilterApplied && openedAndClickedFilters.includes('not-clicked')
                                  ? { email_stats: { clicks: { _eq: 0 } } }
                                  : {},
                              removeEmptyProperties({
                                  is_interested: isSentimentFilterApplied
                                      ? isEqual(sentimentFilters, [RecipientResponseSentimentTypesEnum.Interested])
                                          ? { _eq: true }
                                          : isEqual(sentimentFilters, [
                                                RecipientResponseSentimentTypesEnum.NotInterested,
                                            ])
                                          ? { _eq: false }
                                          : {}
                                      : {},
                                  recipient_responses: isSentimentFilterApplied
                                      ? isEqual(sentimentFilters, [RecipientResponseSentimentTypesEnum.Interested])
                                          ? {
                                                sentiment: {
                                                    _eq: RecipientResponseSentimentTypesEnum.Interested,
                                                },
                                            }
                                          : isEqual(sentimentFilters, [
                                                RecipientResponseSentimentTypesEnum.NotInterested,
                                            ])
                                          ? {
                                                sentiment: {
                                                    _eq: RecipientResponseSentimentTypesEnum.NotInterested,
                                                },
                                            }
                                          : {
                                                sentiment: {
                                                    _neq: RecipientResponseSentimentTypesEnum.Unknown,
                                                },
                                            }
                                      : {},
                              }),
                          ].filter((o) => !isEmpty(o)),
                      }
                    : {},
            ].filter((o) => !isEmpty(o)),
            _or: textFilter,
        };

        return {
            ...recipientsDefaultVariables,
            orderBy: {
                ...convertHeaderFieldToOrderByFilter(headers.find((h) => h.label === orderBy)?.field),
            },
            offset,
            limit,
            where: areFiltersApplied
                ? { _and: queryVariables }
                : {
                      sequenceId: { _eq: sequence.id },
                  },
        };
    }, [
        areFiltersApplied,
        dateAddedFilterOption,
        isDateAddedFilterOptionApplied,
        isOpenedAndClickedFilterApplied,
        isSourcedByFilterApplied,
        isStatusFilterApplied,
        isSentimentFilterApplied,
        limit,
        offset,
        openedAndClickedFilters,
        orderBy,
        sourcedByFilters,
        statusFilters,
        sentimentFilters,
        textFilter,
        sequence,
        convertHeaderFieldToOrderByFilter,
        getLastStageSentFilter,
        getStatusFilter,
    ]);

    const { previousData, data, loading } = useQuery<RecipientsQuery>(GET_RECIPIENTS, {
        variables: getRecipientsVariables,
        fetchPolicy: 'cache-and-network',
    });

    const count =
        (data ? data?.recipients_aggregate?.aggregate?.count : previousData?.recipients_aggregate?.aggregate?.count) ??
        0;

    const [selected, setSelected] = useState<Set<string>>(new Set());
    const [selectedRecipients, setSelectedRecipients] = useState<RecipientsQueryData[]>([]);

    useEffect(() => {
        if (data && data.recipients) {
            const recipients = data.recipients.filter((f) => selected.has(f.prospectId));
            setSelectedRecipients(recipients);
        }
    }, [data, selected]);

    useEffect(() => {
        setOffset(0);
        setSelected(new Set());
    }, [statusFilters, textFilter]);

    useEffect(() => {
        handleClearStatusFilter();
        handleClearSentimentFilter();
        handleClearOpenedAndClickedFilter();
        switch (statsFilter) {
            case 'opened':
                setOpenedAndClickedFilters(['opened']);
                trackEvent('filter_by_opens_and_clicks', { page_name: 'sequence_details', value: ['opened'] });
                break;
            case 'replied':
                setCheckedStatus(['responded', 'meeting-booked']);
                break;
            case 'interested':
                setCheckedStatus(['responded-interested', 'meeting-booked']);
                break;
            default:
                break;
        }
    }, [statsFilter]);

    const handleSelectAll = async () => {
        const subset = new Set(data?.recipients.map((r) => r.prospectId));
        const intersectionSet = new Set([...selected].filter((x) => subset.has(x)));
        if (intersectionSet.size !== subset.size) {
            setSelected((prev) => new Set([...prev, ...subset]));
        } else {
            const differenceSet = new Set([...selected].filter((x) => !subset.has(x)));
            setSelected(differenceSet);
        }
    };

    const handleToggleSelect = (checked: boolean, prospectId: string) => {
        if (checked) {
            setSelected((prev) => new Set([...prev, prospectId]));
        } else {
            setSelected((prev) => {
                const next = new Set(prev);
                next.delete(prospectId);
                return next;
            });
        }
    };

    const handleEditBarClose = () => {
        setSelected(new Set());
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setOffset(newPage * limit);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setOffset(0);
        setLimit(newRowsPerPage);
    };

    const createSortHandler = (col: OrderByColumn) => () => {
        const isDesc = orderBy === col && orderByDirection === OrderBy.DescNullsLast;
        const direction = isDesc ? OrderBy.AscNullsFirst : OrderBy.DescNullsLast;
        setOrderByDirection(direction);
        setOrderBy(col);
    };

    const handleStatusCheckboxClick = (checkboxValue: StatusOption['label']) => () => {
        let checked: boolean = false;
        if (checkedStatus.includes(checkboxValue)) checked = true;

        let newCheckedStatus: StatusOption['label'][] = [];
        if (!checked) {
            newCheckedStatus = [...checkedStatus, checkboxValue];
        } else {
            newCheckedStatus = checkedStatus.filter((s) => s !== checkboxValue);
        }
        setCheckedStatus(newCheckedStatus);
    };

    useEffect(() => {
        let newStatusFilters: StatusOption['status'] = [];
        let newSentimentFilters: StatusOption['sentiments'] = [];
        for (const status of checkedStatus) {
            const statusOption = usableStatusOptions.find((o) => o.label === status);
            newStatusFilters = [...newStatusFilters, ...statusOption!.status];
            newSentimentFilters = [...newSentimentFilters, ...statusOption!.sentiments];
        }
        setStatusFilters([...new Set([...newStatusFilters])]);
        setSentimentFilters([...new Set([...newSentimentFilters])]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedStatus]);

    const handleClearStatusFilter = () => {
        setCheckedStatus([]);
        setStatusFilters([]);
    };

    const handleClearSentimentFilter = () => setSentimentFilters([]);

    const getStatusOption = (label: StatusOption['label']): { label: string; value: StatusOption['label'] } => {
        if (label.includes('in-progress-stage-')) {
            return {
                label: translate('in-progress-stage', { stage: label.replace('in-progress-stage-', '') }),
                value: label,
            };
        }
        return { label: translate(label as StatusOptionLabel), value: label };
    };

    const getStatusTranslation = (label: StatusOption['label']) => getStatusOption(label).label;

    const statusFilter = (
        <CheckboxFilter
            showOptionFilter
            label={translate('status-filter-label')}
            allOptions={usableStatusOptions.map(({ label }) => getStatusOption(label))}
            checkedOptions={checkedStatus}
            onCheckboxClick={handleStatusCheckboxClick}
            onClearFilter={handleClearStatusFilter}
        />
    );

    const handleOpenedAndClickedCheckboxClick = (value: OpenedAndClickedFilterValue) => () => {
        const filters = xor(openedAndClickedFilters, [value]);
        setOpenedAndClickedFilters(filters);
        trackEvent('filter_by_opens_and_clicks', { page_name: 'sequence_details', value: filters });
    };

    const handleClearOpenedAndClickedFilter = () => setOpenedAndClickedFilters([]);

    const openedAndClickedFilter = (
        <CheckboxFilter
            showOptionFilter
            label={translate('opened-and-clicked-filter-label')}
            allOptions={openedAndClickedFilterValues.map((value) => ({ value, label: translate(value) }))}
            checkedOptions={openedAndClickedFilters}
            onCheckboxClick={handleOpenedAndClickedCheckboxClick}
            onClearFilter={handleClearOpenedAndClickedFilter}
        />
    );

    const handleDateAddedClick = (value: DateOptions) => () => {
        setDateAddedFilterOption(value);
    };

    const handleClearDateAddedFilterOption = () => setDateAddedFilterOption(undefined);

    const dateAddedFilter = (
        <DateFilter
            label={translate('date-added-filter-label')}
            onDateClick={handleDateAddedClick}
            onClearFilter={handleClearDateAddedFilterOption}
            current={dateAddedFilterOption}
        />
    );

    const handleSourcedByCheckboxClick = (value: SourcedByFilterValue) => () =>
        setSourcedByFilters(xor(sourcedByFilters, [value]));

    const handleClearSourcedByFilter = () => setSourcedByFilters([]);

    const sourcedByFilter = (
        <CheckboxFilter
            showOptionFilter
            label={translate('sourced-by-filter-label')}
            allOptions={sourcedByFilterValues.map((value) => ({ value, label: translate(value) }))}
            checkedOptions={sourcedByFilters}
            onCheckboxClick={handleSourcedByCheckboxClick}
            onClearFilter={handleClearSourcedByFilter}
        />
    );

    const handleKeywordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeywordFilter(newKeyword);
    };

    const handleClearAllFilters = () => {
        handleClearStatusFilter();
        handleClearSentimentFilter();
        handleClearOpenedAndClickedFilter();
        handleClearDateAddedFilterOption();
        handleClearSourcedByFilter();
        setKeywordFilter('');
    };

    const computeFilterDescription = () => {
        if (!areFiltersApplied) {
            // only show the count if not loading
            if (loading) return translate('no-filters-applied-description');
            return `${translate('no-filters-applied-description')}${translate('filter-description-count', {
                count,
            })}`;
        }

        // eslint-disable-next-line react/no-unstable-nested-components
        const BoldSpan: FC<unknown> = ({ children }) => (
            <Box
                component="span"
                css={css`
                    font-weight: ${fontWeights.bold};
                    text-overflow: ellipsis;
                `}
            >
                {children}
            </Box>
        );
        const bold = (children: React.ReactNode) => <BoldSpan>{children}</BoldSpan>;

        const filters = [];
        if (isStatusFilterApplied) {
            filters.push(
                translate.rich('status-filter-description', {
                    statuses: checkedStatus.map((cs) => getStatusTranslation(cs)).join(translate('or-connector')),
                    bold,
                })
            );
        }
        if (isOpenedAndClickedFilterApplied) {
            filters.push(
                translate.rich('opened-and-clicked-filter-description', {
                    filters: openedAndClickedFilters
                        .map((appliedValue) => translate(appliedValue))
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }
        if (isDateAddedFilterOptionApplied) {
            filters.push(
                translate.rich('date-added-filter-description', {
                    date: translate(dateAddedFilterOption!),
                    bold,
                })
            );
        }
        if (isSourcedByFilterApplied) {
            filters.push(
                translate.rich('sourced-by-filter-description', {
                    sources: sourcedByFilters
                        .map((value) => translate(`${value}-option-filter-description`))
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }
        if (isKeywordFilterApplied) {
            filters.push(
                translate.rich('keyword-filter-description', {
                    index: filters.length,
                    keyword: keywordFilter,
                    bold,
                })
            );
        }

        return (
            <Box
                css={css`
                    display: flex;
                `}
            >
                <FilterBarDescription>{filters}</FilterBarDescription>
                <Box
                    css={css`
                        white-space: pre;
                    `}
                >
                    {!loading ? translate('filter-description-count', { count }) : ''}
                </Box>
            </Box>
        );
    };

    const filterBar = (
        <Box>
            <Box
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: ${spacing.space8px};
                    gap: ${spacing.space8px};
                `}
            >
                {statusFilter}
                {openedAndClickedFilter}
                {dateAddedFilter}
                {sourcedByFilter}
                <FilterTextField value={keywordFilter} onChange={handleKeywordFilterChange} />
            </Box>
            <Box
                css={css`
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) min-content;
                    column-gap: ${spacing.space16px};
                    align-items: center;
                    width: 100%;
                    margin-bottom: ${spacing.space4px};
                `}
            >
                {computeFilterDescription()}
                <Button
                    css={css`
                        font-size: ${fontSizes.f16};
                        font-weight: ${fontWeights.bold};
                        white-space: nowrap;

                        margin-left: auto;
                        padding: 0;
                        opacity: 0;

                        transition: opacity 0.4s ease-in-out;
                        ${areFiltersApplied && `opacity: 1;`}
                    `}
                    variant="text"
                    startIcon={<XClose />}
                    onClick={handleClearAllFilters}
                    disableRipple
                >
                    {translate('clear-all-filters-label')}
                </Button>
            </Box>
        </Box>
    );

    const tableHeaders = (
        <TableRow>
            {headers.map(({ label, width }) => {
                let align: 'flex-start' | 'center' = 'flex-start';
                if (
                    [
                        'added-header',
                        'active-header',
                        'finished-header',
                        'opens-header',
                        'replies-header',
                        'interested-header',
                    ].includes(label)
                ) {
                    align = 'center';
                }
                return (
                    <TableCell key={label} width={width}>
                        <Box
                            css={css`
                                display: flex;
                                justify-content: ${align};
                            `}
                        >
                            {label === 'prospect-header' && (
                                <Checkbox
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                    indeterminate={selected.size > 0 && selected.size < count}
                                    checked={count > 0 && selected.size === count}
                                    onChange={handleSelectAll}
                                />
                            )}
                            <TableSortLabel
                                active={label === orderBy}
                                direction={
                                    label === orderBy
                                        ? orderByDirection === OrderBy.AscNullsFirst
                                            ? OrderBy.Asc
                                            : OrderBy.Desc
                                        : defaultOrderByDirection
                                }
                                onClick={createSortHandler(label)}
                                css={css`
                                    flex-grow: 1;
                                    justify-content: ${align};
                                    white-space: nowrap;
                                `}
                            >
                                <TableHeaderEllipsisText>{translate(label)}</TableHeaderEllipsisText>
                            </TableSortLabel>
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );

    const tableBody = data?.recipients.map((recipient) => (
        <RecipientListRow
            key={recipient.prospectId}
            recipient={recipient}
            isSelected={selected.has(recipient.prospectId)}
            onToggleSelect={handleToggleSelect}
            isSubmenuHidden={selected.size > 0}
        />
    ));

    const showTableBody: boolean | undefined = tableBody && tableBody.length > 0;

    return (
        <>
            {filterBar}
            <TableContainer
                css={css`
                    margin-bottom: 8px;
                    max-width: ${appMaxWidth};

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <Table>
                    <TableHead>{tableHeaders}</TableHead>
                    <TableBody
                        colSpan={headers.length}
                        hidden={!showTableBody}
                        loading={loading}
                        clearFilters={handleClearAllFilters}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                // @ts-expect-error
                component="div"
                page={offset / limit}
                rowsPerPage={limit}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar
                open={selected.size > 0}
                autoHideDuration={undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <RecipientListEditBar
                    sequence={sequence}
                    onClose={handleEditBarClose}
                    recipients={selectedRecipients}
                />
            </Snackbar>
        </>
    );
};

export { RecipientListTable };
export type { RecipientListTableProps };
