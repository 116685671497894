import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button, Title } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EditableAtsSectionProps {
    title?: string;
    isEditing: boolean;
    canEdit: boolean; // if there is no integration connected then showing "edit" is not desireable
    onToggleEdit: () => void;
}

const EditableAtsSection: FC<EditableAtsSectionProps> = ({ title, isEditing, canEdit, onToggleEdit, children }) => {
    const translate = useTranslations('settings');
    const theme = useTheme();
    return (
        <Box
            css={css`
                background-color: ${isEditing ? `${theme.palette.primary.contrastText}50` : 'transparent'};
                border-bottom: 1px solid ${theme.palette.grey[100]};
                padding: ${spacing.space24px} ${spacing.space16px};
                transition: all 0.5s ease-in-out;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: ${isEditing ? spacing.space16px : spacing.space4px};
                `}
            >
                <Title
                    type="h4"
                    css={css`
                        flex-grow: 1;
                        font-size: ${fontSizes.f20};
                        margin: ${spacing.none};
                    `}
                >
                    {title}
                </Title>
                {isEditing ? (
                    <Button
                        variant="text"
                        onClick={onToggleEdit}
                        startIcon={<XClose />}
                        disableRipple
                        css={css`
                            font-weight: ${fontWeights.semiBold};
                            padding: 0;
                        `}
                    >
                        {translate('close-button-label')}
                    </Button>
                ) : null}
                {!isEditing && canEdit ? (
                    <Button
                        variant="text"
                        onClick={onToggleEdit}
                        disableRipple
                        css={css`
                            font-weight: ${fontWeights.semiBold};
                            padding: 0;
                        `}
                    >
                        {translate('edit-button-label')}
                    </Button>
                ) : null}
            </Box>
            {children}
        </Box>
    );
};

export { EditableAtsSection };
