/* eslint-disable max-len */
import { gql } from '@apollo/client';

import {
    LeverAccountByIdQuery,
    LeverPostingsQuery,
    LeverProjectMappingByProjectIdQuery,
    LeverProspectApplicationsQuery,
    LeverSequenceMappingBySequenceIdQuery,
    LeverSourcesQuery,
    LeverStagesQuery,
    LeverUrlApplicationsQuery,
    LeverUsersQuery,
} from 'codegen/graphql';

export type LeverSourcesData = LeverSourcesQuery['data'];
export type LeverAccountByIdData = LeverAccountByIdQuery['data'];
export type LeverPostingsData = LeverPostingsQuery['data'];
export type LeverStagesData = LeverStagesQuery['data'];
export type LeverProjectMappingByProjectIdData = LeverProjectMappingByProjectIdQuery['data'];
export type LeverSequenceMappingBySequenceIdData = LeverSequenceMappingBySequenceIdQuery['data'];
export type LeverUsersData = LeverUsersQuery['data'];
export type LeverProspectApplicationsData = LeverProspectApplicationsQuery['data'];
export type LeverUrlApplicationsData = LeverUrlApplicationsQuery['data'];

export const LEVER_ACCOUNT_DETAILS = gql`
    fragment LeverAccountDetails on lever_accounts {
        id
        teamId
        ownerId
        apiToken
        useOauth
        accessToken
        refreshToken
        scope
        expiresIn
        expiresAt
        syncConfidential
        previousSyncConfidential
        defaultSourceId
        refreshTokenExpiresAt
        invalidApiTokenDetectedAt
    }
`;

export const GET_LEVER_ACCOUNT_BY_TEAM_ID = gql`
    query LeverAccountByTeamId($teamId: String!) {
        data: lever_accounts(where: { teamId: { _eq: $teamId } }) {
            ...LeverAccountDetails
        }
    }
    ${LEVER_ACCOUNT_DETAILS}
`;

export const GET_LEVER_ACCOUNT_BY_ID = gql`
    query LeverAccountById($id: String!) {
        data: lever_accounts_by_pk(id: $id) {
            ...LeverAccountDetails
        }
    }
    ${LEVER_ACCOUNT_DETAILS}
`;

export const CREATE_LEVER_PROJECT_MAPPING = gql`
    mutation CreateLeverProjectMapping($lever_project_mappings: lever_project_mappings_insert_input!) {
        inserted: insert_lever_project_mappings_one(object: $lever_project_mappings) {
            id
        }
    }
`;

export const GET_LEVER_PROJECT_MAPPING_BY_PROJECT_ID = gql`
    query LeverProjectMappingByProjectId($projectId: String!) {
        data: lever_project_mappings(where: { projectId: { _eq: $projectId } }) {
            id
            projectId
            postingId
            projectAddedStageId
            assigneeId
        }
    }
`;

export const UPDATE_LEVER_PROJECT_MAPPING_BY_ID = gql`
    mutation UpdateLeverProjectMappingById($id: String!, $set: lever_project_mappings_set_input!) {
        updated: update_lever_project_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_LEVER_PROJECT_MAPPING_BY_ID = gql`
    mutation DeleteLeverProjectMappingById($id: String!) {
        deleted: delete_lever_project_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID = gql`
    query LeverSequenceMappingBySequenceId($sequenceId: String!) {
        data: lever_sequence_mappings(where: { sequenceId: { _eq: $sequenceId } }) {
            id
            sequenceId
            postingId
            assigneeId
            syncProspectToggleActive
            createEventType
            repliedMoveStageActive
            repliedStageId
            interestedReplyMoveStageActive
            interestedReplyStageId
            meetingBookedMoveStageActive
            meetingBookedStageId
            defaultAddStageId
        }
    }
`;

export const CREATE_LEVER_SEQUENCE_MAPPING = gql`
    mutation CreateLeverSequenceMapping($lever_sequence_mappings: lever_sequence_mappings_insert_input!) {
        inserted: insert_lever_sequence_mappings_one(object: $lever_sequence_mappings) {
            id
        }
    }
`;

export const UPDATE_LEVER_SEQUENCE_MAPPING_BY_ID = gql`
    mutation UpdateLeverSequenceMappingById($id: String!, $set: lever_sequence_mappings_set_input!) {
        updated: update_lever_sequence_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_LEVER_SEQUENCE_MAPPING_BY_ID = gql`
    mutation DeleteLeverSequenceMappingById($id: String!) {
        deleted: delete_lever_sequence_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const LEVER_OPPORTUNITY_DETAILS = gql`
    fragment LeverOpportunityDetails on lever_opportunities {
        id
        remoteId
        accountId
        name
    }
`;

export const GET_LEVER_OPPORTUNITIES = gql`
    query LeverOpportunities {
        data: lever_opportunities {
            ...LeverOpportunityDetails
        }
    }
    ${LEVER_OPPORTUNITY_DETAILS}
`;

export const LEVER_SOURCE_DETAILS = gql`
    fragment LeverSourceDetails on lever_sources {
        id
        name
        accountId
    }
`;

export const GET_LEVER_SOURCES = gql`
    query LeverSources($order_by: [lever_sources_order_by!] = { name: asc }) {
        data: lever_sources(order_by: $order_by) {
            ...LeverSourceDetails
        }
    }
    ${LEVER_SOURCE_DETAILS}
`;

export const LEVER_STAGE_DETAILS = gql`
    fragment LeverStageDetails on lever_stages {
        id
        name
        accountId
    }
`;

export const GET_LEVER_STAGES = gql`
    query LeverStages {
        data: lever_stages {
            ...LeverStageDetails
        }
    }
    ${LEVER_STAGE_DETAILS}
`;

export const LEVER_POSTING_DETAILS = gql`
    fragment LeverPostingDetails on lever_postings {
        id
        remoteId
        accountId
        name
        state
        urls(order_by: { createdAt: desc }, limit: 1) {
            value
        }
    }
`;

export const GET_LEVER_POSTINGS = gql`
    query LeverPostings {
        data: lever_postings {
            ...LeverPostingDetails
        }
    }
    ${LEVER_POSTING_DETAILS}
`;

export const LEVER_USER_DETAILS = gql`
    fragment LeverUserDetails on lever_users {
        id
        name
        username
        email
    }
`;

export const GET_LEVER_USERS = gql`
    query LeverUsers {
        data: lever_users {
            ...LeverUserDetails
        }
    }
    ${LEVER_USER_DETAILS}
`;

export const GET_LEVER_PROSPECT_APPLICATIONS = gql`
    query LeverProspectApplications($prospectId: String!) {
        data: lever_find_prospect_applications(args: { prospectId: $prospectId }) {
            status: opportunity {
                stage {
                    name
                }
            }
            lastActivityAt
            posting {
                ...LeverPostingDetails
            }
            addedByUser: user {
                ...LeverUserDetails
            }
            opportunity {
                id
                remoteUrl
                addedAt: remoteCreatedAt
            }
        }
    }
    ${LEVER_POSTING_DETAILS}
    ${LEVER_USER_DETAILS}
`;

export const GET_LEVER_URL_APPLICATIONS = gql`
    query LeverUrlApplications($url: String!) {
        data: lever_find_applications_by_url(args: { url: $url }) {
            status: opportunity {
                stage {
                    name
                }
            }
            lastActivityAt
            posting {
                ...LeverPostingDetails
            }
            addedByUser: user {
                ...LeverUserDetails
            }
            opportunity {
                id
                remoteUrl
                addedAt: remoteCreatedAt
            }
        }
    }
    ${LEVER_POSTING_DETAILS}
    ${LEVER_USER_DETAILS}
`;
