import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Tooltip } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CoworkerConnectionTooltipProps {
    coworkerConnectionSentence?: string;
    disabledCoworkerConnectionSentence?: boolean;
}

const CoworkerConnectionTooltip: FC<CoworkerConnectionTooltipProps> = (props) => {
    const { coworkerConnectionSentence, disabledCoworkerConnectionSentence = false, children } = props;
    const translate = useTranslations('sequence.create.stages');
    return (
        <Tooltip
            placement="bottom-start"
            title={
                disabledCoworkerConnectionSentence
                    ? translate('coworker-connection-sentence-disabled')
                    : translate('coworker-connection-tooltip-title')
            }
            content={
                !disabledCoworkerConnectionSentence && (
                    <Box
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        `}
                    >
                        <Box>{translate('coworker-connection-sentence-tooltip')}</Box>
                        {coworkerConnectionSentence && (
                            <Box
                                css={css`
                                    margin-top: ${spacing.space12px};
                                `}
                            >
                                {translate('coworker-connection-sentence-tooltip-example', {
                                    coworkerConnectionSentence,
                                })}
                            </Box>
                        )}
                    </Box>
                )
            }
        >
            <Box>{children}</Box>
        </Tooltip>
    );
};

export { CoworkerConnectionTooltip };
