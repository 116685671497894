import styled from '@emotion/styled';
import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';
import React from 'react';

import { FC } from 'shared/types';

interface BoxProps extends MuiBoxProps {}

const Box: FC<BoxProps> = React.forwardRef((props, ref) => <MuiBox ref={ref} {...props} />);

const StyledBox = styled(Box)``;

export type { BoxProps };
export { StyledBox as Box };
