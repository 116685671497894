import { useEffect, useState } from 'react';

import { config } from 'config';
import { logger } from 'logger';

interface CodeClient {
    requestCode: () => void;
}

interface CodeResponse {
    code: string;
    scope: string;
    state: string;
    error: string;
    error_description: string;
    error_uri: string;
}

interface CodeClientParams {
    client_id: string;
    ux_mode: string;
    scope: string;
    callback: (response: CodeResponse) => void;
}

interface GoogleWindow extends Window {
    google?: {
        accounts: {
            oauth2: {
                initCodeClient: (params: CodeClientParams) => CodeClient;
            };
        };
    };
}

const googleJsApiSrcUrl = 'https://accounts.google.com/gsi/client';
const { clientId } = config.GoogleOAuthAppConfig;
const defaultScopes = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/gmail.modify',
];
const defaultUxMode = 'popup';

const defaultParams = {
    client_id: clientId,
    ux_mode: defaultUxMode,
    scope: defaultScopes.join(' '),
};

async function initializeGoogleAuth(): Promise<void> {
    return new Promise((resolve) => {
        ((d: Document, id: string, onload: () => void) => {
            const element = d.getElementsByTagName('script')[0];
            const fjs = element;
            let js = element;
            js = d.createElement('script');
            js.id = id;
            js.src = googleJsApiSrcUrl;
            if (fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
                js.onload = onload;
            } else {
                logger.error('No script tag found');
            }
        })(document, 'google-login', () => {
            resolve();
        });
    });
}

export const useGoogleAuth = () => {
    const [libInit, setLibInit] = useState(false);
    const [cookiesDisabled, setCookiesDisabled] = useState(false);

    useEffect(() => {
        initializeGoogleAuth()
            .then(() => setLibInit(true))
            .catch((err) => {
                if (err.details && err.details === 'Cookies are not enabled in current environment.') {
                    setCookiesDisabled(true);
                } else {
                    logger.error('Could not load google object');
                }
            });
    }, []);

    const requestGoogleAuthCode = (
        params: Partial<CodeClientParams> & {
            callback: CodeClientParams['callback'];
        }
    ) => {
        if (!libInit) {
            throw Error('Google auth library not initialized yet');
        }

        const client = (window as GoogleWindow).google!.accounts.oauth2.initCodeClient({
            ...defaultParams,
            ...params,
            scope: params.scope ? `${defaultParams.scope} ${params.scope}` : defaultParams.scope,
        });
        client.requestCode();
    };

    return { libInit, requestGoogleAuthCode, cookiesDisabled };
};
