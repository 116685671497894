// cspell:ignore CvoQLNZeMdJ3JoVEaTXZY5ND
/**
 * Custom logger - Uses console log for development and sends logs to server for production
 */
import { Logtail } from '@logtail/browser';
import { ILogtailLog } from '@logtail/types';

import { config } from 'config';

const logtail = new Logtail('CvoQLNZeMdJ3JoVEaTXZY5ND');

logtail.use(async (log: ILogtailLog) => ({
    ...log,
    appVersion: config.AppVersion,
}));

/* eslint-disable no-console */
const consoleLogger = {
    assert: console.assert,
    debug: console.debug,
    error: console.error,
    info: console.info,
    trace: console.trace,
    warn: console.warn,
};
/* eslint-enable no-console */

const getContextWithUpdatedKeys = (context: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (context && context.hasOwnProperty('message')) {
        // eslint-disable-next-line no-underscore-dangle
        context.__message__ = context.message;
        delete context.message;
    }
    return context;
};

const logtailLogger = {
    assert: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    debug: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    error: (message: string | Error, context: any) => {
        logtail.error(message, getContextWithUpdatedKeys(context));
    },
    info: (message: string | Error, context: any) => logtail.info(message, getContextWithUpdatedKeys(context)),
    trace: (_1: string | Error, _2: any) => {
        /* no-op */
    },
    warn: (message: string | Error, context: any) => logtail.warn(message, getContextWithUpdatedKeys(context)),
};

type Logger = (message: string | Error, context?: any) => void;

export const logger: {
    assert: Logger;
    debug: Logger;
    error: Logger;
    info: Logger;
    trace: Logger;
    warn: Logger;
} = config.Env === 'production' ? logtailLogger : consoleLogger;
