import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { PlanTypesEnum } from 'codegen/graphql';
import { BasePrivileges } from 'settings/components/presentational/plans-and-billing/plan-privileges';
import { Box } from 'shared/components/containers';
import { NoSsr } from 'shared/components/presentational';
import { Title } from 'shared/components/presentational/title';
import { ExpiredPlan, StarterPlan } from 'shared/components/svgs';
import { ProLevel } from 'shared/components/svgs/pro-level';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NonEnterprisePlanProps {
    planType: PlanTypesEnum;
    activeProjects: number;
    activeSequences: number;
    aiSourcerCredits: number;
    message?: string;
    showAISourcerCredits?: boolean;
}

const NonEnterprisePlan: FC<NonEnterprisePlanProps> = (props) => {
    const theme = useTheme();
    const translate = useTranslations('settings.plans-and-billing-settings');
    const { activeProjects, activeSequences, aiSourcerCredits, planType, message, showAISourcerCredits = true } = props;

    const title =
        planType === PlanTypesEnum.Expired ? (
            <>
                <ExpiredPlan />
                {translate('expired-plan')}
            </>
        ) : planType === PlanTypesEnum.FreeTrial ? (
            <>
                <StarterPlan />
                {translate('trial-plan')}
            </>
        ) : (
            <>
                <ProLevel />
                {translate('pro-plan')}
            </>
        );

    return [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) ? null : (
        <Box
            css={css`
                background: ${theme?.palette.background.default};
                border: 1px solid ${theme?.palette.grey[500]};
                border-radius: ${spacing.space8px};
                padding: ${spacing.space24px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                `}
            >
                <Title
                    type="h3"
                    css={css`
                        display: flex;
                        font-size: ${fontSizes.f24};
                        line-height: 28px;
                        color: ${theme.palette.common.black};
                        margin: ${spacing.none};
                        margin-bottom: ${spacing.space16px};
                        svg {
                            margin-right: ${spacing.space16px};
                        }
                    `}
                >
                    {title}
                </Title>
                {message && (
                    <NoSsr>
                        <Box
                            component="p"
                            css={css`
                                font-family: ${fontFamilies.inter};
                                font-style: normal;
                                font-weight: ${fontWeights.normal};
                                font-size: ${fontSizes.f12};
                                line-height: 15px;
                                text-align: right;
                                margin: 0;
                                color: ${theme.palette.common.black};
                            `}
                        >
                            {message}
                        </Box>
                    </NoSsr>
                )}
            </Box>
            <BasePrivileges
                planType={planType}
                activeProjectCount={activeProjects}
                activeSequenceCount={activeSequences}
                aiSourcerCredits={aiSourcerCredits}
                showAISourcerCredits={showAISourcerCredits}
            />
        </Box>
    );
};

export { NonEnterprisePlan };
