import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { escapeRegExp } from 'lodash';
import xor from 'lodash/xor';
import { useTranslations } from 'next-intl';
import { ReactNode, useMemo, useState } from 'react';

import {
    OrderBy,
    SequenceActivitiesQuery,
    SequenceActivitiesQueryVariables,
    SequenceActivityTypesEnum,
    SequenceCollaboratorActivitiesQuery,
    SequenceCollaboratorActivitiesQueryVariables,
    SequenceCollaboratorActivityTypesEnum,
} from 'codegen/graphql';
import { SequenceActivity, SequenceCollaboratorActivity } from 'sequences/types';
import { Box } from 'shared/components/containers';
import {
    CheckboxFilter,
    DateFilter,
    DateOptions,
    FilterTextField,
    NoSsr,
    getDateFrom,
} from 'shared/components/presentational';
import { TablePagination } from 'shared/components/table';
import { GET_SEQUENCE_ACTIVITIES } from 'shared/graphql/sequence-activities';
import { GET_SEQUENCE_COLLABORATOR_ACTIVITIES } from 'shared/graphql/sequence-collaborators-activities';
import { SequenceData } from 'shared/graphql/sequences';
import { dateFormatter, timeFormatter } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface DetailsActivityLogProps {
    sequence: SequenceData;
}

const DetailsActivityLog: FC<DetailsActivityLogProps> = ({ sequence }) => {
    const theme = useTheme();

    const [contributorFilters, setContributorsFilters] = useState<string[]>([]);
    const translate = useTranslations('sequence');
    const [keywordFilter, setKeywordFilter] = useState('');
    const [dateAddedFilterOption, setDateAddedFilterOption] = useState<DateOptions>();

    const [page, setPage] = useState<number>(0);
    // eslint-disable-next-line no-magic-numbers
    const defaultRowsPerPageOptions = [50, 100, 150, 200];
    // eslint-disable-next-line no-magic-numbers
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const isDateAddedFilterOptionApplied = Boolean(dateAddedFilterOption);
    const isContributorFilterApplied = contributorFilters.length > 0;

    const areFiltersApplied = isDateAddedFilterOptionApplied || isContributorFilterApplied;

    const getActivityLogsVariables = useMemo(
        () => ({
            orderBy: { createdAt: OrderBy.Desc },
            where: areFiltersApplied
                ? {
                      _and: [
                          { sequenceId: { _eq: sequence.id } },
                          {
                              createdAt: isDateAddedFilterOptionApplied
                                  ? { _gte: getDateFrom(dateAddedFilterOption!).getTime() }
                                  : {},
                          },
                          { userId: isContributorFilterApplied ? { _in: contributorFilters } : {} },
                      ],
                  }
                : {
                      sequenceId: { _eq: sequence.id },
                  },
        }),
        [
            areFiltersApplied,
            contributorFilters,
            dateAddedFilterOption,
            isContributorFilterApplied,
            isDateAddedFilterOptionApplied,
            sequence.id,
        ]
    );

    const { data: activitiesData } = useQuery<SequenceActivitiesQuery, SequenceActivitiesQueryVariables>(
        GET_SEQUENCE_ACTIVITIES,
        {
            variables: getActivityLogsVariables,
        }
    );

    const { data: collaboratorActivitiesData } = useQuery<
        SequenceCollaboratorActivitiesQuery,
        SequenceCollaboratorActivitiesQueryVariables
    >(GET_SEQUENCE_COLLABORATOR_ACTIVITIES, {
        variables: getActivityLogsVariables,
    });

    const handleClearContributorFilter = () => {
        setContributorsFilters([]);
        setPage(0);
    };

    const handleContributorCheckboxClick = (value: string) => () => {
        setContributorsFilters(xor(contributorFilters, [value]));
        setPage(0);
    };

    const contributors = [{ label: sequence.user.fullName, value: sequence.user.id }].concat(
        sequence.collaborators.map((collaborator) => ({
            label: collaborator.collaborator.fullName,
            value: collaborator.userId,
        }))
    );

    const handleKeywordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeywordFilter(newKeyword);
        setPage(0);
    };

    const handleDateAddedClick = (value: DateOptions) => () => {
        setDateAddedFilterOption(value);
        setPage(0);
    };

    const handleClearDateAddedFilterOption = () => {
        setDateAddedFilterOption(undefined);
        setPage(0);
    };

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getActivityMessage = (activity: SequenceActivity | SequenceCollaboratorActivity) => {
        let message = '';
        const contributor = activity.user?.fullName ?? translate('hireflow');

        switch (activity.type) {
            case SequenceActivityTypesEnum.Created:
                message = translate('activity-logs.contributor-created-sequence', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.Renamed:
                message = translate('activity-logs.contributor-renamed-sequence', {
                    contributor,
                    oldValue: activity.oldValue,
                    newValue: activity.newValue,
                });
                break;
            case SequenceActivityTypesEnum.Duplicated:
                message = translate('activity-logs.contributor-duplicated-sequence', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.Archived:
                message = translate('activity-logs.contributor-archived-sequence', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.Paused:
                message = translate('activity-logs.contributor-paused-sequence', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.Unpaused:
                message = translate('activity-logs.contributor-un-paused-sequence', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.ContentEdited:
                message = translate('activity-logs.contributor-content-edited', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.DetailsEdited:
                message = translate('activity-logs.contributor-edited-sequence-stages', {
                    contributor,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToGreenhouseJob:
                message = translate('activity-logs.contributor-linked-ats-job', {
                    contributor,
                    ats: 'Greenhouse',
                    job: activity.newValue,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToAshbyJob:
                message = translate('activity-logs.contributor-linked-ats-job', {
                    contributor: activity.user?.fullName ?? translate('hireflow'),
                    ats: 'Ashby',
                    job: activity.newValue,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToLeverPosting:
                message = translate('activity-logs.contributor-linked-ats-job', {
                    contributor: activity.user?.fullName ?? translate('hireflow'),
                    ats: 'Lever',
                    job: activity.newValue,
                });
                break;
            case SequenceCollaboratorActivityTypesEnum.Added:
                message = translate('activity-logs.contributor-shared-sequence', {
                    contributor,
                    target: activity.targetUser.fullName,
                });
                break;
            default:
                break;
        }

        return message;
    };
    const getActivityRichMessage = (activity: SequenceActivity | SequenceCollaboratorActivity) => {
        let message;
        const contributor = activity.user?.fullName ?? translate('hireflow');

        switch (activity.type) {
            case SequenceActivityTypesEnum.Created:
                message = translate.rich('activity-logs.contributor-created-sequence-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.Renamed:
                message = translate.rich('activity-logs.contributor-renamed-sequence-rich', {
                    contributor,
                    oldValue: activity.oldValue,
                    newValue: activity.newValue,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.Duplicated:
                message = translate.rich('activity-logs.contributor-duplicated-sequence-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.Archived:
                message = translate.rich('activity-logs.contributor-archived-sequence-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.Paused:
                message = translate.rich('activity-logs.contributor-paused-sequence-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.Unpaused:
                message = translate.rich('activity-logs.contributor-un-paused-sequence-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.ContentEdited:
                message = translate.rich('activity-logs.contributor-content-edited-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.DetailsEdited:
                message = translate.rich('activity-logs.contributor-edited-sequence-stages-rich', {
                    contributor,
                    bold: Bold,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToGreenhouseJob:
                message = translate.rich('activity-logs.contributor-linked-ats-job-rich', {
                    contributor,
                    bold: Bold,
                    ats: 'Greenhouse',
                    job: activity.newValue,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToAshbyJob:
                message = translate.rich('activity-logs.contributor-linked-ats-job-rich', {
                    contributor: activity.user?.fullName ?? translate('hireflow'),
                    bold: Bold,
                    ats: 'Ashby',
                    job: activity.newValue,
                });
                break;
            case SequenceActivityTypesEnum.LinkedToLeverPosting:
                message = translate.rich('activity-logs.contributor-linked-ats-job-rich', {
                    contributor: activity.user?.fullName ?? translate('hireflow'),
                    bold: Bold,
                    ats: 'Lever',
                    job: activity.newValue,
                });
                break;
            case SequenceCollaboratorActivityTypesEnum.Added:
                message = translate.rich('activity-logs.contributor-shared-sequence-rich', {
                    contributor,
                    target: activity.targetUser.fullName,
                    bold: Bold,
                });
                break;
            default:
                break;
        }

        return message;
    };

    const dateFilter = (
        <DateFilter
            label={translate('details.date')}
            onDateClick={handleDateAddedClick}
            onClearFilter={handleClearDateAddedFilterOption}
            current={dateAddedFilterOption}
        />
    );

    const contributorsFilter = (
        <CheckboxFilter
            label={translate('details.contributors')}
            allOptions={contributors}
            checkedOptions={contributorFilters}
            onCheckboxClick={handleContributorCheckboxClick}
            onClearFilter={handleClearContributorFilter}
            showOptionFilter
        />
    );

    const combinedActivities = [
        ...(activitiesData?.sequence_activities ?? []),
        ...(collaboratorActivitiesData?.sequence_collaborator_activities ?? []),
    ].sort((a, b) => b.createdAt - a.createdAt);

    const filteredActivities = combinedActivities.filter((activity) => {
        const keywordFilterRegex = new RegExp(escapeRegExp(keywordFilter), 'i');
        return getActivityMessage(activity).match(keywordFilterRegex);
    });

    const count = filteredActivities?.length ?? 0;
    const start = rowsPerPage * page;
    const end = rowsPerPage * (page + 1);
    const paginatedSequenceActivities = filteredActivities?.slice(start, end);

    const activitiesWithDateAndTime =
        paginatedSequenceActivities?.map((activity) => {
            const date = dateFormatter(activity.createdAt);
            const time = timeFormatter(activity.createdAt);
            return { ...activity, date, time };
        }) ?? [];

    const activitiesGroupedByDate = activitiesWithDateAndTime.reduce<Record<string, typeof activitiesWithDateAndTime>>(
        (groups, activity) => {
            const group = groups[activity.date];
            if (group) {
                group.push(activity);
            } else {
                // eslint-disable-next-line no-param-reassign
                groups[activity.date] = [activity];
            }
            return groups;
        },
        {}
    );

    const sequenceActivities = Object.keys(activitiesGroupedByDate).map((date, index) => {
        const activities = activitiesGroupedByDate?.[date];

        const activitiesByDate = (
            <Box
                key={date}
                css={css`
                    padding: ${spacing.space16px} 0;
                    border-top: ${index !== 0 && `1px solid rgba(0, 0, 0, 0.12)`};
                    padding-top: ${index === 0 && 0};
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f12};
                        font-weight: ${fontWeights.bold};
                        text-transform: uppercase;
                        padding: 6px 0;
                    `}
                >
                    {date}
                </Box>

                {activities?.map((activity, i) => (
                    <Box
                        key={i}
                        css={css`
                            padding: 6px 0;
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <Box>{getActivityRichMessage(activity)}</Box>
                        <NoSsr>
                            <span>{activity.time}</span>
                        </NoSsr>
                    </Box>
                ))}
            </Box>
        );
        return activitiesByDate;
    });

    return (
        <Box>
            <Box
                css={css`
                    padding-bottom: ${spacing.space16px};
                    display: flex;
                    column-gap: 8px;
                `}
            >
                {dateFilter}
                {contributorsFilter}

                <FilterTextField
                    placeholder={translate('details.keyword-filter-placeholder')}
                    value={keywordFilter}
                    onChange={handleKeywordFilterChange}
                />
            </Box>
            {activitiesData ? (
                <>
                    <Box
                        css={css`
                            padding: 24px;
                            background-color: ${theme.palette.common.white};
                        `}
                    >
                        {sequenceActivities}
                    </Box>
                    <Box>
                        <TablePagination
                            // Issue: https://mui.com/material-ui/guides/typescript/#usage-of-component-prop
                            // @ts-expect-error
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={defaultRowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </>
            ) : null}
        </Box>
    );
};

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);

export { DetailsActivityLog };
