import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedFilter: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg {...rest} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 5H11M1 1H13M5 9H9"
            stroke={stroke ?? theme?.palette.primary.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Filter = withTheme(ThemedFilter);

export { Filter };
