import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { trackEvent } from 'analytics';
import { CreateUnsubscribeRequestMutation } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { GET_RECIPIENT_ACTIVITIES } from 'shared/graphql/recipient-activities';
import { GET_RECIPIENTS } from 'shared/graphql/recipients';
import { CREATE_UNSUBSCRIBE_REQUEST } from 'shared/graphql/unsubscribe-requests';
import { useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ReportWrongEmailOneContactProps {
    recipientId: string;
    sequenceId: string;
    name: string;
    open: boolean;
    onClose: () => void;
}

const AddToUnsubscribeListModal: FC<ReportWrongEmailOneContactProps> = (props) => {
    const { onClose, open, recipientId, sequenceId, name } = props;
    const translate = useTranslations('sequence.recipient-list-table.unsubscribe-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [unsubscribeRecipient] = useMutation<CreateUnsubscribeRequestMutation>(CREATE_UNSUBSCRIBE_REQUEST, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_RECIPIENT_ACTIVITIES) as string,
        ],
    });

    const handleClickUnsubscribe = async () => {
        const res = await unsubscribeRecipient({
            variables: {
                recipientId,
            },
        });

        if (res.data?.insert_unsubscribe_requests_one) {
            showSnackbarAlert({
                message: translate('success-alert', { name }),
                severity: 'success',
            });
        } else {
            showSnackbarAlert({
                message: translate('error-alert', { name }),
                severity: 'error',
            });
        }
        onClose();
        trackEvent('click_add_to_unsubscribe_list', { sequence_id: sequenceId, recipient_id: recipientId });
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('header', { name })}
                </Title>
                <Box>{translate.rich('description')}</Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space24px};
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleClickUnsubscribe}>
                        {translate('unsubscribe-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { AddToUnsubscribeListModal };
