import { useTranslations } from 'next-intl';

import { LinkedInCompanyUrl } from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';

const Personalization = () => {
    const translate = useTranslations('settings.shared-settings');

    return (
        <Box>
            <Title type="h2">{translate('personalization')}</Title>
            <LinkedInCompanyUrl />
        </Box>
    );
};

export { Personalization };
