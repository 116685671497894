import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import { SequencesQuery } from 'codegen/graphql';
import { SequenceListSubmenu, formatPercentageStat } from 'sequences/components/tables';
import { SEQUENCE_STATUS_DRAFT } from 'sequences/constants';
import { Box } from 'shared/components/containers';
import { IconButton, NoSsr } from 'shared/components/presentational';
import { DotsVertical, AshbyLogo, GreenhouseLogo, LeverLogo } from 'shared/components/svgs';
import { TableRow, TableCell } from 'shared/components/table';
import { CheckboxColorTitle } from 'shared/components/table/cells';
import { windowFeatures, windowTarget } from 'shared/constants';
import { useAccessControl } from 'shared/hooks';
import { dateFormatter } from 'shared/services';
import { appRoutes, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { getAshbyJobLink, getGreenhouseJobLink, getLeverPostingLink } from 'shared/utils';

type SequenceData = SequencesQuery['sequences'][number];

interface SequenceListRowProps {
    sequence: SequenceData;
    isSelected: boolean;
    onToggleSelect: (checked: boolean, sequenceId: string) => void;
}

const SequenceListRow: FC<SequenceListRowProps> = ({ sequence, isSelected, onToggleSelect }) => {
    const translate = useTranslations('sequence');
    const { accessControlLoaded, canViewAndAddProspectsToSequence, canViewSequence } = useAccessControl();
    const theme = useTheme();
    const router = useRouter();

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onToggleSelect(!isSelected, sequence.id);
    };

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (event.defaultPrevented) return;

        if (sequence.status === SEQUENCE_STATUS_DRAFT) {
            // if the sequence is a draft we redirect the user to the special "drafts" page
            // which is similar to the "create" page, except that we use the db sequence id
            // to populate the fields
            router.push(appRoutes.sequences.draft.index(sequence.id));
        } else {
            // not a draft, redirect to sequences [id]
            router.push(appRoutes.sequences.index(sequence.id));
        }
    };

    const getAtsJobLink = useCallback(
        () => {
            let logo: EmotionJSX.Element | undefined;
            let link: string | undefined;

            if (sequence.ashby_sequence_mapping?.id) {
                logo = <AshbyLogo width={16} height={16} fill={theme.palette.primary.light} />;
                if (sequence.ashby_sequence_mapping.job?.remoteId) {
                    link = getAshbyJobLink(sequence.ashby_sequence_mapping.job.remoteId);
                }
            } else if (sequence.greenhouse_sequence_mapping?.id) {
                logo = <GreenhouseLogo width={16} height={16} fill={theme.palette.primary.light} />;
                if (sequence.greenhouse_sequence_mapping.job?.remoteId) {
                    link = getGreenhouseJobLink(sequence.greenhouse_sequence_mapping.job.remoteId);
                }
            } else if (sequence.lever_sequence_mapping?.id) {
                logo = <LeverLogo width={16} height={16} fill={theme.palette.primary.light} />;
                if (sequence.lever_sequence_mapping.posting?.remoteId) {
                    link = getLeverPostingLink(sequence.lever_sequence_mapping.posting.remoteId);
                }
            }

            if (!logo) {
                return null;
            }

            return (
                <IconButton
                    css={css`
                        opacity: 0.5;
                        &:hover {
                            opacity: 1;
                        }
                    `}
                    onClick={() => {
                        if (link) {
                            window.open(link, windowTarget, windowFeatures);
                        }
                    }}
                >
                    {logo}
                </IconButton>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sequence]
    );

    return (
        <>
            <TableRow
                key={sequence.id}
                css={css`
                    ${submenuAnchor &&
                    // simulate hover when submenu is opened
                    `
                    background-color: ${theme.palette.grey[300]};
                    .MuiTableCell-root {
                        background-color: ${theme.palette.grey[300]};
                    }
                    path {
                        stroke: ${theme.palette.primary.light};
                    }
                    `}
                `}
                selected={isSelected}
                onClick={handleRowClick}
            >
                <CheckboxColorTitle
                    color={sequence.color ?? '#fff'}
                    title={sequence.title}
                    isChecked={isSelected}
                    handleChecked={handleSelectChange}
                    atsJobLink={getAtsJobLink()}
                >
                    {`${sequence.user.fullName}${
                        !accessControlLoaded || canViewAndAddProspectsToSequence(sequence)
                            ? ''
                            : canViewSequence(sequence)
                            ? translate('sequence-list-table.view-only')
                            : translate('sequence-list-table.not-shared')
                    }`}
                </CheckboxColorTitle>
                <TableCell align="center">{sequence.stats![0].added}</TableCell>
                <TableCell align="center">{sequence.stats![0].active}</TableCell>
                <TableCell align="center">{sequence.stats![0].finished}</TableCell>
                <TableCell align="center">{formatPercentageStat(sequence.stats![0].opensPercentage)}</TableCell>
                <TableCell align="center">{formatPercentageStat(sequence.stats![0].repliesPercentage)}</TableCell>
                <TableCell align="center">{formatPercentageStat(sequence.stats![0].interestedPercentage)}</TableCell>
                <TableCell>
                    <Box
                        css={css`
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) min-content;
                            column-gap: ${spacing.space16px};
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <Box>
                            <NoSsr>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                    title={dateFormatter(sequence.createdAt)}
                                >
                                    {dateFormatter(sequence.createdAt)}
                                </Box>
                            </NoSsr>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                                title={translate('sequence-list-table.created-by', {
                                    fullName: sequence.user.fullName,
                                })}
                            >
                                {translate('sequence-list-table.created-by', { fullName: sequence.user.fullName })}
                            </Box>
                        </Box>
                        <IconButton
                            css={css`
                                margin-left: auto;
                                ${submenuAnchor &&
                                `
                                padding: 0;
                                margin-right: 8px;
                                `}

                                &:hover path {
                                    stroke: ${theme.palette.primary.light};
                                }
                            `}
                            onClick={handleSubmenuButtonClick}
                            disableRipple
                        >
                            <DotsVertical />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <SequenceListSubmenu sequence={sequence} anchor={submenuAnchor} setAnchor={setSubmenuAnchor} />
        </>
    );
};

export { SequenceListRow };
