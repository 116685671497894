import { useLazyQuery, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import {
    ProspectsQuery,
    ProspectsQueryVariables,
    CreateProjectMemberActivitiesMutation,
    ProjectMemberActivityTypesEnum,
    CreateRecipientMutationResult,
    AddOrUpdateProspectMutation,
    AddOrUpdateProspectMutationVariables,
} from 'codegen/graphql';
import { AddToSequenceModal } from 'shared/components/modals';
import { NoProspectsModal } from 'shared/components/presentational';
import { GET_PROJECT_ACTIVITIES } from 'shared/graphql/project-activities';
import {
    CREATE_PROJECT_MEMBER_ACTIVITIES,
    GET_PROJECT_MEMBER_ACTIVITIES,
} from 'shared/graphql/project-members-activities';
import { ADD_OR_UPDATE_PROSPECT, GET_PROSPECTS } from 'shared/graphql/prospects';
import { FC } from 'shared/types';

interface AddMultipleRecipientsToSequenceProps {
    sourceSequenceId?: string;
    sourceProjectId?: string;
    prospectIds: string[];
    open: boolean;
    onClose: () => void;
    refetchQueries?: string[];
}

type Recipient = DeepExtractTypeSkipArrays<CreateRecipientMutationResult, ['data', 'insert_recipients', 'returning']>;

const AddMultipleRecipientsToSequence: FC<AddMultipleRecipientsToSequenceProps> = ({
    sourceSequenceId,
    sourceProjectId,
    prospectIds,
    onClose,
    open,
    refetchQueries = [],
}) => {
    const [getProspectsQuery] = useLazyQuery<ProspectsQuery, ProspectsQueryVariables>(GET_PROSPECTS);

    const [addOrUpdateProspectData] = useMutation<AddOrUpdateProspectMutation, AddOrUpdateProspectMutationVariables>(
        ADD_OR_UPDATE_PROSPECT
    );

    const [createProjectMembersActivities] = useMutation<CreateProjectMemberActivitiesMutation>(
        CREATE_PROJECT_MEMBER_ACTIVITIES,
        {
            refetchQueries: [
                getOperationName(GET_PROJECT_ACTIVITIES) as string,
                getOperationName(GET_PROJECT_MEMBER_ACTIVITIES) as string,
            ],
        }
    );

    const onSuccess = (recipients: Recipient[], sequenceId: string) => {
        createProjectMembersActivities({
            variables: {
                projectMembersActivities: recipients?.map((r) => ({
                    prospectId: r?.prospect.id,
                    projectId: sourceProjectId,
                    sequenceId,
                    type: ProjectMemberActivityTypesEnum.AddedToSequence,
                })),
            },
        });
    };

    const getProspects = async () => {
        // find or create prospects for this user
        const { data: prospectsData } = await getProspectsQuery({
            variables: { where: { id: { _in: prospectIds } }, withProfile: true, limit: prospectIds.length },
        });
        const addProspectInputObjects = prospectsData?.prospects.map((prospect) => {
            const url = prospect.urls?.[0].url;
            const { profile } = prospect;
            return { url, profile };
        });
        const addProspectRes = await addOrUpdateProspectData({ variables: { data: addProspectInputObjects } });
        const userProspectIds = addProspectRes.data?.add_prospect.map((prospect) => prospect.id) ?? [];

        const { data: userProspectsData } = await getProspectsQuery({
            variables: {
                where: { id: { _in: userProspectIds } },
                withProfile: true,
                withCustomMessages: true,
                limit: userProspectIds.length,
            },
        });
        return userProspectsData?.prospects ?? [];
    };

    return prospectIds.length > 0 ? (
        <AddToSequenceModal
            open={open}
            onClose={onClose}
            getProspects={getProspects}
            count={prospectIds.length}
            {...(sourceSequenceId && { sequencesToExcludeFromDropdown: [sourceSequenceId] })}
            {...(sourceProjectId && { onSuccess })}
            refetchQueries={refetchQueries}
        />
    ) : (
        <NoProspectsModal open={open} onClose={onClose} />
    );
};

export { AddMultipleRecipientsToSequence };
