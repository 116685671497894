import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedNil: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <circle cx="8" cy="8" r="7.5" stroke={stroke ?? theme?.palette?.grey?.[200]} />
        <line
            x1="4.5"
            y1="8.5"
            x2="11.5"
            y2="8.5"
            stroke={stroke ?? theme?.palette?.grey?.[200]}
            strokeLinecap="round"
        />
    </svg>
);

const Nil = withTheme(ThemedNil);

export { Nil };
