/* eslint-disable max-len */
import { gql } from '@apollo/client';

import {
    GreenhouseAccountByIdQuery,
    GreenhouseJobsQuery,
    GreenhouseJobStagesQuery,
    GreenhouseProjectMappingByProjectIdQuery,
    GreenhouseProspectApplicationsQuery,
    GreenhouseSequenceMappingBySequenceIdQuery,
    GreenhouseSourcesQuery,
    GreenhouseUrlApplicationsQuery,
    GreenhouseUsersQuery,
} from 'codegen/graphql';

export type GreenhouseSourcesData = GreenhouseSourcesQuery['data'];
export type GreenhouseAccountByIdData = GreenhouseAccountByIdQuery['data'];
export type GreenhouseJobsData = GreenhouseJobsQuery['data'];
export type GreenhouseJobStagesData = GreenhouseJobStagesQuery['data'];
export type GreenhouseProjectMappingByProjectIdData = GreenhouseProjectMappingByProjectIdQuery['data'];
export type GreenhouseSequenceMappingBySequenceIdData = GreenhouseSequenceMappingBySequenceIdQuery['data'];
export type GreenhouseUsersData = GreenhouseUsersQuery['data'];
export type GreenhouseProspectApplicationsData = GreenhouseProspectApplicationsQuery['data'];
export type GreenhouseUrlApplicationsData = GreenhouseUrlApplicationsQuery['data'];

export const GREENHOUSE_ACCOUNT_DETAILS = gql`
    fragment GreenhouseAccountDetails on greenhouse_accounts {
        id
        teamId
        ownerId
        apiToken
        defaultSourceId
        defaultCreateAsApplicationType
    }
`;

export const GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID = gql`
    query GreenhouseAccountByTeamId($teamId: String!) {
        data: greenhouse_accounts(where: { teamId: { _eq: $teamId } }) {
            ...GreenhouseAccountDetails
        }
    }
    ${GREENHOUSE_ACCOUNT_DETAILS}
`;

export const GET_GREENHOUSE_ACCOUNT_BY_ID = gql`
    query GreenhouseAccountById($id: String!) {
        data: greenhouse_accounts_by_pk(id: $id) {
            ...GreenhouseAccountDetails
        }
    }
    ${GREENHOUSE_ACCOUNT_DETAILS}
`;

export const CREATE_GREENHOUSE_PROJECT_MAPPING = gql`
    mutation CreateGreenhouseProjectMapping($data: greenhouse_project_mappings_insert_input!) {
        inserted: insert_greenhouse_project_mappings_one(object: $data) {
            id
        }
    }
`;

export const GET_GREENHOUSE_PROJECT_MAPPING_BY_PROJECT_ID = gql`
    query GreenhouseProjectMappingByProjectId($projectId: String!) {
        data: greenhouse_project_mappings(where: { projectId: { _eq: $projectId } }) {
            id
            projectId
            jobId
            projectAddedStageId
            assigneeId
            createAsApplicationTypeOverride
        }
    }
`;

export const UPDATE_GREENHOUSE_PROJECT_MAPPING_BY_ID = gql`
    mutation UpdateGreenhouseProjectMappingById($id: String!, $set: greenhouse_project_mappings_set_input!) {
        updated: update_greenhouse_project_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_GREENHOUSE_PROJECT_MAPPING_BY_ID = gql`
    mutation DeleteGreenhouseProjectMappingById($id: String!) {
        deleted: delete_greenhouse_project_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID = gql`
    query GreenhouseSequenceMappingBySequenceId($sequenceId: String!) {
        data: greenhouse_sequence_mappings(where: { sequenceId: { _eq: $sequenceId } }) {
            id
            sequenceId
            jobId
            assigneeId
            createAsApplicationTypeOverride
            syncProspectToggleActive
            createEventType
            interestedReplyConvertActive
            interestedReplyMoveStageActive
            interestedReplyStageId
            meetingBookedConvertActive
            meetingBookedMoveStageActive
            meetingBookedStageId
        }
    }
`;

export const CREATE_GREENHOUSE_SEQUENCE_MAPPING = gql`
    mutation CreateGreenhouseSequenceMapping(
        $greenhouse_sequence_mappings: greenhouse_sequence_mappings_insert_input!
    ) {
        inserted: insert_greenhouse_sequence_mappings_one(object: $greenhouse_sequence_mappings) {
            id
        }
    }
`;

export const UPDATE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID = gql`
    mutation UpdateGreenhouseSequenceMappingById($id: String!, $set: greenhouse_sequence_mappings_set_input!) {
        updated: update_greenhouse_sequence_mappings_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const DELETE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID = gql`
    mutation DeleteGreenhouseSequenceMappingById($id: String!) {
        deleted: delete_greenhouse_sequence_mappings_by_pk(id: $id) {
            id
        }
    }
`;

export const GREENHOUSE_JOB_DETAILS = gql`
    fragment GreenhouseJobDetails on greenhouse_jobs {
        id
        remoteId
        accountId
        name
        status
    }
`;

export const GET_GREENHOUSE_JOBS = gql`
    query GreenhouseJobs {
        data: greenhouse_jobs(where: { status: { _neq: closed } }) {
            ...GreenhouseJobDetails
        }
    }
    ${GREENHOUSE_JOB_DETAILS}
`;

export const GREENHOUSE_SOURCE_DETAILS = gql`
    fragment GreenhouseSourceDetails on greenhouse_sources {
        id
        name
        accountId
    }
`;

export const GET_GREENHOUSE_SOURCES = gql`
    query GreenhouseSources($order_by: [greenhouse_sources_order_by!] = { name: asc }) {
        data: greenhouse_sources(order_by: $order_by) {
            ...GreenhouseSourceDetails
        }
    }
    ${GREENHOUSE_SOURCE_DETAILS}
`;

export const GREENHOUSE_JOB_STAGE_DETAILS = gql`
    fragment GreenhouseJobStageDetails on greenhouse_job_stages {
        id
        name
        accountId
        jobId
    }
`;

export const GET_GREENHOUSE_JOB_STAGES = gql`
    query GreenhouseJobStages($jobId: String!) {
        data: greenhouse_job_stages(where: { jobId: { _eq: $jobId } }, order_by: { priority: desc }) {
            ...GreenhouseJobStageDetails
        }
    }
    ${GREENHOUSE_JOB_STAGE_DETAILS}
`;

export const GREENHOUSE_USER_DETAILS = gql`
    fragment GreenhouseUserDetails on greenhouse_users {
        id
        firstName
        lastName
        primaryEmailAddress
        deactivated
    }
`;

export const GET_GREENHOUSE_USERS = gql`
    query GreenhouseUsers {
        data: greenhouse_users {
            ...GreenhouseUserDetails
        }
    }
    ${GREENHOUSE_USER_DETAILS}
`;

export const GET_GREENHOUSE_PROSPECT_APPLICATIONS = gql`
    query GreenhouseProspectApplications($prospectId: String!) {
        data: greenhouse_find_prospect_applications(args: { prospectId: $prospectId }) {
            id
            remoteId
            candidate {
                remoteId
            }
            status
            lastActivityAt
            addedAt: appliedAt
            jobs {
                job {
                    ...GreenhouseJobDetails
                }
            }
            assigneeFullName
        }
    }
    ${GREENHOUSE_JOB_DETAILS}
    ${GREENHOUSE_USER_DETAILS}
`;

export const GET_GREENHOUSE_URL_APPLICATIONS = gql`
    query GreenhouseUrlApplications($url: String!) {
        data: greenhouse_find_applications_by_url(args: { url: $url }) {
            id
            remoteId
            candidate {
                remoteId
            }
            status
            lastActivityAt
            addedAt: appliedAt
            jobs {
                job {
                    ...GreenhouseJobDetails
                }
            }
            assigneeFullName
        }
    }
    ${GREENHOUSE_JOB_DETAILS}
`;

export const GET_GREENHOUSE_PERMISSIONED_JOB_USERS = gql`
    query GreenhousePermissionedJobUsers($jobId: String) {
        data: greenhouse_users(
            where: {
                _or: [
                    { isSiteAdmin: { _eq: true } }
                    { hiringTeamJobUsers: { jobId: { _eq: $jobId } } }
                    { jobUserPermissions: { jobId: { _eq: $jobId } } }
                ]
            }
        ) {
            ...GreenhouseUserDetails
        }
    }
    ${GREENHOUSE_USER_DETAILS}
`;
