import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLogout: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '20'}
        height={height ?? '20'}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M15 5.66667L18.3333 9M18.3333 9L15 12.3333M18.3333 9H7.49996M12.5 2.50337C11.4377 1.86523 10.2043 1.5 8.88885 1.5C4.90012 1.5 1.66663 4.85786 1.66663 9C1.66663 13.1421 4.90012 16.5 8.88885 16.5C10.2043 16.5 11.4377 16.1348 12.5 15.4966"
            stroke={stroke ?? theme?.palette.secondary.light}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Logout = withTheme(ThemedLogout);

export { Logout };
