import { Variables, substituteMagicSentence } from 'hireflow-shared/utils/email-drafting-utils';
import { useEffect, useState } from 'react';

import { MessagePreviewPersonalize } from 'prospects/message-preview-personalize';
import { CustomStage, Stage } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { useProspectPanel } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { getCombinedStages } from 'shared/services/custom-stage-utils';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface SequenceMessagesInQueueProps {
    anchor: HTMLElement;
    recipient: RecipientConversationsData;
    variables: Variables[];
}

const SequenceMessagesInQueue: FC<SequenceMessagesInQueueProps> = (props) => {
    const { anchor, recipient, variables } = props;

    const { context, updateContext } = useProspectPanel();
    const [magicSentence, setMagicSentence] = useState<string | null>(null);
    /**
     * attempt to retrieve the magic sentence from the backend and set
     */
    const fetchMagicSentence = async () => {
        if (!context?.prospectId) return;
        const restApiClient = createRestApiClient();
        const res = await restApiClient.getMagicSentence({ prospectId: context.prospectId });
        const magicSentenceResponse = res?.data?.magicSentence || null;
        setMagicSentence(magicSentenceResponse);
        updateContext({ ...context, magicSentence: magicSentenceResponse });
    };

    useEffect(() => {
        fetchMagicSentence();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.prospectId]);

    const sequenceStageMessage = getCombinedStages(
        recipient.sequence.stages.filter((f) => f.stageIndex >= recipient.lastStageSent) as Stage[],
        recipient.customStages.filter((f) => f.stageIndex >= recipient.lastStageSent) as CustomStage[],
        variables.filter((_, index) => index >= recipient.lastStageSent)
    ).map((stage, index) => {
        let updatedStage = stage;
        let { content } = updatedStage;
        content = substituteMagicSentence(content, magicSentence);
        updatedStage = { ...updatedStage, content };
        return (
            <Box key={`recipient-sequence-${index}`}>
                <MessagePreviewPersonalize anchor={anchor} recipient={recipient} stage={updatedStage} />
            </Box>
        );
    });

    return <Box>{sequenceStageMessage}</Box>;
};

export { SequenceMessagesInQueue };
