import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';

import { Box } from 'shared/components/containers';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface TabProps extends MuiTabProps {}

interface TabWithCountProps extends MuiTabProps {
    active?: boolean;
    count?: number | string;
}

const Tab: FC<TabProps> = (props) => {
    const { children: _children, ...rest } = props;

    return <MuiTab {...rest} />;
};

const StyledTab = styled(Tab)`
    font-size: ${fontSizes.f20};
    text-transform: none;
    font-family: ${fontFamilies.poppins};
    font-weight: ${fontWeights.semiBold};
`;

const TabWithCount: FC<TabWithCountProps> = (props) => {
    const { count, label, active, ...rest } = props;

    return (
        <StyledTab
            label={
                <Box
                    css={css`
                    display: flex
                    align-items: center
                `}
                >
                    <Box component="span">{label}</Box>
                    {count ? (
                        <Box
                            component="span"
                            className="count"
                            css={css`
                                background-color: ${active ? colors.greens.narvik : colors.grays.gallery};
                                border-radius: 8px;
                                padding: 8px 10px;
                                gap: 10px;
                                margin-left: ${spacing.space12px};
                                font-family: ${fontFamilies.inter};
                                font-size: ${fontSizes.f16};
                                font-weight: ${fontWeights.bold};
                            `}
                        >
                            {count}
                        </Box>
                    ) : null}
                </Box>
            }
            {...rest}
        />
    );
};

export { StyledTab as Tab, TabWithCount };
