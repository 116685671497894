import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { Bell02Circle, CheckCircle, XCircle } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerMemberCardModalSummaryProps {
    snoozedCount: number;
    rejectedCount: number;
    approvedCount: number;
    projectTitle: string;
}

const SourcerMemberCardModalSummary: FC<SourcerMemberCardModalSummaryProps> = ({
    snoozedCount,
    rejectedCount,
    approvedCount,
    projectTitle,
}) => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.sourcer-member-card.modal');

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: ${spacing.space8px};
            `}
        >
            {snoozedCount > 0 && (
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space10px};
                    `}
                >
                    <Bell02Circle width={24} height={24} stroke={theme.palette.grey[600]} />
                    <Box>{translate('snooze-notice', { snoozedCount })}</Box>
                </Box>
            )}
            {rejectedCount > 0 && (
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space10px};
                    `}
                >
                    <XCircle width={24} height={24} stroke={theme.palette.error.main} />
                    <Box>{translate('reject-notice', { rejectedCount })}</Box>
                </Box>
            )}
            {approvedCount > 0 && (
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space10px};
                    `}
                >
                    <CheckCircle
                        css={css`
                            path {
                                stroke-width: 1.5px;
                            }
                        `}
                        width={24}
                        height={24}
                        stroke={theme.palette.primary.main}
                    />
                    <Box>
                        {translate.rich('approve-notice', {
                            approvedCount,
                            project: projectTitle,
                            bold: Bold,
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export { SourcerMemberCardModalSummary };

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);
