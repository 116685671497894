const spacing = {
    none: '0',
    space4px: '4px',
    space6px: '6px',
    space8px: '8px',
    space10px: '10px',
    space12px: '12px',
    space14px: '14px',
    space16px: '16px',
    space20px: '20px',
    space24px: '24px',
    space28px: '28px',
    space32px: '32px',
    space36px: '36px',
    space44px: '44px',
    space48px: '48px',
    space64px: '64px',
};

export { spacing };
