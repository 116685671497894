import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NonEditableSettingsSectionProps {
    title: string;
}

const NonEditableSettingsSection: FC<NonEditableSettingsSectionProps> = ({ title, children }) => {
    const theme = useTheme();
    return (
        <Box
            css={css`
                border-bottom: 1px solid ${theme.palette.grey[100]};
                padding: ${spacing.space24px} ${spacing.space16px};
            `}
        >
            <Title
                type="h4"
                css={css`
                    flex-grow: 1;
                    font-size: ${fontSizes.f20};
                    margin: ${spacing.none};
                    margin-bottom: ${spacing.space4px};
                `}
            >
                {title}
            </Title>
            {children}
        </Box>
    );
};

export { NonEditableSettingsSection };
