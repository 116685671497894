import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Chevron } from 'shared/components/svgs';
import { fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CollapsibleSectionProps {
    title: string;
    open?: boolean;
}

const CollapsibleSection: FC<CollapsibleSectionProps> = ({ title, open = false, children }) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(open);
    const handleToggle = () => setIsOpen(!isOpen);
    return (
        <Box
            className="collapsible-section"
            css={css`
                &.collapsible-section {
                    .collapsible-header {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        padding: ${spacing.space12px} ${spacing.space12px};
                        background: ${theme.palette.common.white};
                        border: 1px solid ${theme.palette.grey[500]};
                        border-radius: 4px;
                        margin-bottom: ${spacing.space16px};
                        &:hover {
                            border-color: ${theme.palette.grey[100]};
                        }
                    }

                    .collapsible-header-icon {
                        &.open {
                            transform: rotate(180deg);
                        }
                    }
                }
            `}
        >
            <Box className={isOpen ? 'collapsible-header open' : 'collapsible-header'} onClick={handleToggle}>
                <Box
                    css={css`
                        font-weight: ${fontWeights.bold};
                    `}
                >
                    {title}
                </Box>
                <Chevron className={isOpen ? 'collapsible-header-icon open' : 'collapsible-header-icon'} />
            </Box>
            {isOpen && children}
        </Box>
    );
};

export { CollapsibleSection };
