import styled from '@emotion/styled';
import { TableContainer as MuiTableContainer, TableContainerProps as MuiTableContainerProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableContainerProps extends MuiTableContainerProps {}

const TableContainer: FC<TableContainerProps> = (props) => <MuiTableContainer {...props} />;

const StyledTableContainer = styled(TableContainer)``;

export { StyledTableContainer as TableContainer };
