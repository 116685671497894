import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { VectorBack, VectorFrontTopHalf } from 'shared/components/svgs/errors';
import { ProfileErrorEnum } from 'shared/hooks/use-profile';
import { FC } from 'shared/types';
import { ContactUs } from 'support/components/templates';

interface ErrorTemplateProps {
    errorCode: '404' | '500' | ProfileErrorEnum;
    children: ReactNode;
}

/**
 * ===== NOTE =====
 * using <div> instead of <Box> here as BugsnagErrorBoundary does not build with <Box> components when using codebuild
 * the same goes for spacing, font and font sizing imports, so these have been manually set
 * extensionPanelWidth is also used here with the media query, but causes problems if interpolated so it is hard coded
 * ===== END =====
 */
const ErrorTemplate: FC<ErrorTemplateProps> = (props) => {
    const { errorCode, children } = props;
    const translate = useTranslations('errors');

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 38px;

                max-width: 1050px;

                @media (max-width: 521px) {
                    flex-direction: column;
                    align-items: left;
                    justify-content: left;

                    padding: 50px;
                }
            `}
        >
            <div
                css={css`
                    display: grid;
                    place-items: center;
                `}
            >
                <div
                    css={css`
                        grid-area: left;
                        z-index: 1;

                        margin-top: 65px;
                        margin-left: 25px;

                        filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
                    `}
                >
                    <VectorBack />
                </div>
                <div
                    css={css`
                        grid-area: left;
                        z-index: 2;

                        margin-top: -10px;

                        filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
                    `}
                >
                    {children}
                </div>
                <div
                    css={css`
                        grid-area: left;
                        z-index: 3;

                        margin-top: -54px;

                        mix-blend-mode: multiply;
                        filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
                    `}
                >
                    <VectorFrontTopHalf />
                </div>
            </div>
            <div>
                <div
                    css={css`
                        text-transform: uppercase;
                        font-family: Inter, sans-serif;
                        font-size: 14px;
                        line-height: 17px;
                        color: #999999;
                    `}
                >
                    {translate(`${errorCode}.snippet`)}
                </div>
                <div
                    css={css`
                        margin-top: 8px;

                        font-family: Poppins, sans-serif;
                        font-size: 32px;
                        line-height: 34px;
                    `}
                >
                    {translate(`${errorCode}.title`)}
                </div>
                <div
                    css={css`
                        margin-top: 8px;

                        font-family: Inter, sans-serif;
                        font-size: 16px;
                        line-height: 19px;
                    `}
                >
                    {translate(`${errorCode}.description`)}
                </div>
                <div
                    css={css`
                        margin-top: 24px;

                        font-family: Inter, sans-serif;
                        font-size: 14px;
                        line-height: 17px;
                        color: #999999;
                    `}
                >
                    <ContactUs />
                </div>
            </div>
        </div>
    );
};

export { ErrorTemplate };
