import { css } from '@emotion/react';
import { TableCell, TableRow, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';

import { Experience } from 'shared/components/composite/prospect-card-experience';
import { Box } from 'shared/components/containers';
import { NoSsr } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';

interface ProspectCardExperienceRowProps {
    exp: Experience;
    disableBorder?: boolean;
}

const ProspectCardExperienceRow: React.FC<ProspectCardExperienceRowProps> = ({ exp, disableBorder = false }) => {
    const translate = useTranslations('shared.prospect-card.work-table');
    const theme = useTheme();
    let durationString = '';
    let dateRange = '';
    if (exp.startDateMonth && exp.startDateYear && ((exp.endDateMonth && exp.endDateYear) || exp.current)) {
        const startDateTime = DateTime.fromObject({
            month: exp.startDateMonth ?? undefined,
            year: exp.startDateYear ?? undefined,
        });
        const endDateTime = exp.current
            ? DateTime.now()
            : DateTime.fromObject({
                  month: exp.endDateMonth ?? undefined,
                  year: exp.endDateYear ?? undefined,
              });
        const duration = endDateTime.diff(startDateTime, ['years', 'months']);
        durationString =
            duration.years === 0 && Math.floor(duration.months) === 0
                ? translate('duration-less-than-one-month')
                : translate('duration', {
                      years: duration.years,
                      months: Math.floor(duration.months),
                  });
        dateRange = `${startDateTime.toFormat('MMM yyyy')} - ${
            exp.current ? 'Present' : endDateTime.toFormat('MMM yyyy')
        }`;
    }

    const companyLogo = exp.companyLogo ? (
        <picture
            css={css`
                width: 20px;
                height: 20px;
            `}
        >
            <img src={exp.companyLogo} alt={translate('company-logo-alt')} width={20} height={20} />
        </picture>
    ) : null;

    const position = (
        <span
            css={css`
                display: inline-block;
                max-width: 380px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            `}
        >
            {exp.companyName
                ? translate('title-and-company', {
                      title: exp.title,
                      company: exp.companyName,
                  })
                : translate('title', { title: exp.title })}
        </span>
    );

    return (
        <TableRow
            css={css`
                .MuiTableCell-root {
                    font-size: ${fontSizes.f14};
                    padding: 6px 0px;
                    border-bottom: ${disableBorder ? 'none' : `1px solid ${theme.palette.grey[100]}`};
                    color: ${theme.palette.grey[200]};
                }
                .experience-duration.MuiTableCell-root {
                    color: ${theme.palette.grey[600]};
                    min-width: 75px;
                    white-space: nowrap;
                }
                .experience-date.MuiTableCell-root {
                    min-width: 159px;
                    white-space: nowrap;
                }
            `}
        >
            <TableCell width="534px">
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: ${spacing.space8px};
                    `}
                >
                    {companyLogo}
                    {position}
                </Box>
            </TableCell>
            <TableCell className="experience-duration">{durationString}</TableCell>
            <TableCell className="experience-date" align="right">
                <NoSsr>{dateRange}</NoSsr>
            </TableCell>
        </TableRow>
    );
};

export { ProspectCardExperienceRow };
