import styled from '@emotion/styled';
import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material';

import { FC } from 'shared/types';

interface SkeletonProps extends MuiSkeletonProps {}

const Skeleton: FC<SkeletonProps> = (props) => <MuiSkeleton {...props} />;

const StyledSkeleton = styled(Skeleton)``;

export { StyledSkeleton as Skeleton };
