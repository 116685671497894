import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, TextField, Title } from 'shared/components/presentational';
import { Minus, Plus } from 'shared/components/svgs';
import { isEmailValid } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ReportWrongEmailOneContactProps {
    reportedEmail: string;
    open: boolean;
    onClose: () => void;
    onReport: (email: string) => void;
}

const ReportWrongEmailOneContact: FC<ReportWrongEmailOneContactProps> = (props) => {
    const { onClose, open, reportedEmail, onReport } = props;
    const translate = useTranslations('sequence.recipient-list-table.report-wrong-email-modal');

    const [addManual, setAddManual] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const toggleAddManual = () => setAddManual(!addManual);

    const reset = () => {
        setAddManual(false);
        setIsValid(true);
        setEmail('');
    };

    const handleClickReport = () => {
        if (addManual && !isEmailValid(email)) {
            setIsValid(false);
            return;
        }

        onReport(email);
        reset();
        onClose();
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
        if (!isValid) {
            setIsValid(true);
        }
    };

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('header')}
                </Title>
                <Box>
                    {translate.rich('description', {
                        email: reportedEmail,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        bold: (children) => (
                            <Box
                                component="span"
                                css={css`
                                    font-weight: ${fontWeights.bold};
                                    text-overflow: ellipsis;
                                `}
                            >
                                {children}
                            </Box>
                        ),
                    })}
                    <Box
                        css={css`
                            padding-top: ${spacing.space24px};
                        `}
                    >
                        {translate('no-email-extra-description')}
                    </Box>
                    <Box>
                        <Button
                            css={css`
                                padding-left: 0px;
                            `}
                            variant="text"
                            onClick={toggleAddManual}
                            startIcon={addManual ? <Minus /> : <Plus />}
                        >
                            {translate('manually-enter-email')}
                        </Button>
                    </Box>
                    <Box
                        css={css`
                            padding-bottom: ${spacing.space24px};
                        `}
                        hidden={!addManual}
                    >
                        <TextField
                            css={css`
                                width: 100%;
                            `}
                            error={!isValid}
                            helperText={isValid ? null : translate('invalid-email')}
                            focused
                            value={email}
                            onChange={handleEmailChange}
                            label={translate('new-email-optional-label')}
                            inputRef={(input) => input && input.focus()}
                        />
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleClickReport}>
                        {translate('report-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ReportWrongEmailOneContact };
