import { css } from '@emotion/react';
import DOMPurify from 'dompurify';

import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface Insight {
    id: string;
    sourcerMemberId: string;
    insight: string;
}

interface SourcerMemberCardInsightsListProps {
    deletable?: boolean;
    insights: Insight[];
    onInsightDelete?: (insight: Insight) => () => void;
}

const SourcerMemberCardInsightsList: FC<SourcerMemberCardInsightsListProps> = ({
    insights,
    deletable = false,
    onInsightDelete,
}) => {
    /**
     * The insights text contain words that should be bolded. Those are between double asterisks (**).
     * For example: Candidate has **1.92 years** of experience at **Capital One** as a Software Engineer.
     * This function will replace the double asterisks with <strong> tags.
     * @param rawInsightText
     * @returns
     */
    const formatBoldText = (rawInsightText: string): string => {
        // Use a regular expression to find and replace text between double asterisks with <strong> tags
        const formattedText = rawInsightText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Sanitize the formatted HTML content
        const sanitizedHtml = DOMPurify.sanitize(formattedText);
        return sanitizedHtml;
    };

    // We only want to keep the first 5 insights
    // eslint-disable-next-line no-magic-numbers
    const firstFiveInsights = insights.slice(0, 5);

    return (
        <Box
            css={css`
                flex: 1;
            `}
        >
            <Box
                css={css`
                    margin: unset;
                    padding: unset;
                    list-style-type: none;
                    li:not(:last-child) {
                        padding-bottom: 8px;
                    }
                `}
                component="ul"
            >
                {firstFiveInsights.map((insight) => (
                    <Box component="li" key={insight.id}>
                        {deletable ? (
                            <Box
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: ${fontSizes.f14};
                                `}
                            >
                                <Box
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: formatBoldText(insight.insight) }}
                                />
                                <IconButton
                                    css={css`
                                        padding: 0;
                                    `}
                                    disableRipple
                                    onClick={onInsightDelete && onInsightDelete(insight)}
                                >
                                    <XClose />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f14};
                                `}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: formatBoldText(insight.insight) }}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export { SourcerMemberCardInsightsList };
