import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import { DEFAULT_SEND_EMAILS_HOUR_END, DEFAULT_SEND_EMAILS_HOUR_START } from 'hireflow-shared/models/user';
import range from 'lodash/range';
import { DateTimeFormatOptions, useIntl, useTranslations } from 'next-intl';
import { useState } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { Box } from 'shared/components/containers';
import { Select, SelectOption } from 'shared/components/form';
import { NoSsr } from 'shared/components/presentational';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { FC } from 'shared/types';

const hourRangeStart = 1;
const hourRangeEnd = 24;

const hoursSelectionOptionsDefaultOptions = {
    start: hourRangeStart,
    end: hourRangeEnd,
};

const timeFormatOptions: DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
};

const hoursSelectionOptions = (
    intl: ReturnType<typeof useIntl>,
    options: Partial<typeof hoursSelectionOptionsDefaultOptions> = {}
): SelectOption[] => {
    const { start, end } = { ...hoursSelectionOptionsDefaultOptions, ...options };
    return range(start, end + 1).map((n) => ({
        value: String(n),
        label: intl.formatDateTime(new Date(0, 0, 0, n), timeFormatOptions),
    }));
};

interface EmailSendingHoursProps {
    editing: boolean;
}

const EmailSendingHours: FC<EmailSendingHoursProps> = (props) => {
    const translate = useTranslations('settings.email-settings.email-sending-hours');
    const intl = useIntl();
    const { editing } = props;
    const { session, setSession } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);
    const [isEditing, setIsEditing] = useState<boolean>(editing);
    const [hourStart, setHourStart] = useState<number>(
        session!.user.sendEmailsHourStart || DEFAULT_SEND_EMAILS_HOUR_START
    );
    const [hourEnd, setHourEnd] = useState<number>(session!.user.sendEmailsHourEnd || DEFAULT_SEND_EMAILS_HOUR_END);

    const handleChangeHourStart = (event: SelectChangeEvent<unknown>) =>
        setHourStart(parseInt(event.target.value as string, 10));

    const handleChangeHourEnd = (event: SelectChangeEvent<unknown>) =>
        setHourEnd(parseInt(event.target.value as string, 10));

    const handleSave = async () => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: { sendEmailsHourStart: hourStart, sendEmailsHourEnd: hourEnd },
            },
        });

        setSession({
            user: {
                ...session!.user,
                sendEmailsHourEnd: hourEnd,
                sendEmailsHourStart: hourStart,
            },
        });

        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const handleToggleEdit = () => {
        setHourStart(session!.user.sendEmailsHourStart || DEFAULT_SEND_EMAILS_HOUR_START);
        setHourEnd(session!.user.sendEmailsHourEnd || DEFAULT_SEND_EMAILS_HOUR_END);
        setIsEditing(!isEditing);
    };

    const editableContent = (
        <Box
            css={css`
                display: flex;
                align-items: center;
            `}
        >
            <Box
                component="span"
                css={css`
                    white-space: pre;
                `}
            >
                {translate('between')}
            </Box>
            <Box component="span">
                <NoSsr>
                    <Select
                        isDefault
                        disableUnderline
                        parentBackgroundColor="green"
                        options={hoursSelectionOptions(intl, { end: hourEnd - 1 })}
                        name="hourStart"
                        value={String(hourStart)}
                        width="min-content"
                        onChange={handleChangeHourStart}
                        variant="filled"
                    />
                </NoSsr>
            </Box>
            <Box
                component="span"
                css={css`
                    white-space: pre;
                `}
            >
                {translate('and')}
            </Box>
            <Box component="span">
                <Select
                    isDefault
                    disableUnderline
                    parentBackgroundColor="green"
                    options={hoursSelectionOptions(intl, { start: hourStart + 1 })}
                    name="hourEnd"
                    value={String(hourEnd)}
                    width="min-content"
                    onChange={handleChangeHourEnd}
                    variant="filled"
                />
            </Box>
        </Box>
    );

    const nonEditableContent = (
        <Box>
            <NoSsr>
                {translate('between-start-and-end', {
                    start: intl.formatDateTime(new Date(0, 0, 0, hourStart), timeFormatOptions),
                    end: intl.formatDateTime(new Date(0, 0, 0, hourEnd), timeFormatOptions),
                })}
            </NoSsr>
        </Box>
    );

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {isEditing ? editableContent : nonEditableContent}
        </EditableSettingsSection>
    );
};

export { EmailSendingHours };
