import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLeverLogo: FC<SvgProps> = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M16.0388 17.2429C16.2956 17.0035 16.7114 17.0026 16.9692 17.2429L20.5253 20.5541C20.7821 20.7935 20.6971 20.9875 20.338 20.9875H12.6702C12.3098 20.9875 12.2249 20.7944 12.4829 20.5541L16.0388 17.2429Z"
            fill={fill ?? '#4D4D4D'}
        />
        <path
            // eslint-disable-next-line max-len
            d="M3.0708 20.8293L19.9616 3.91235C20.1494 4.10278 20.3041 4.30322 20.3389 4.47547L20.9893 7.68407C21.0626 8.04558 20.9075 8.55017 20.6427 8.8113L8.78286 20.515C8.51807 20.7758 8.00083 20.9875 7.62722 20.9875L3.3497 21.0426C3.16332 21.0426 3.17007 20.9875 3.0708 20.8293Z"
            fill={fill ?? '#999999'}
        />
        <path
            // eslint-disable-next-line max-len
            d="M2.99219 20.5258V16.3838C2.99219 16.0106 3.20393 15.4928 3.46486 15.228L15.1668 3.36463C15.4279 3.09994 15.9324 2.94494 16.2939 3.01813L19.5021 3.66871C19.6843 3.70573 19.8639 3.81458 20.0064 3.96028L3.17192 20.9876C3.06055 20.8667 2.99219 20.7044 2.99219 20.5258Z"
            fill={fill ?? '#4D4D4D'}
        />
    </svg>
);

const LeverLogo = withTheme(ThemedLeverLogo);

export { LeverLogo };
