import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { Chevron } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

const TablePaginationActions: FC<TablePaginationActionsProps> = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const buttonCss = css`
        background-color: ${theme.palette.common.white};
    `;

    return (
        <Box
            css={css`
                display: flex;
                column-gap: 4px;
            `}
        >
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} css={buttonCss}>
                {theme.direction === 'rtl' ? <Chevron direction="right" /> : <Chevron direction="left" />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                css={buttonCss}
            >
                {theme.direction === 'rtl' ? <Chevron direction="left" /> : <Chevron direction="right" />}
            </IconButton>
        </Box>
    );
};

export { TablePaginationActions };
