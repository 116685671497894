import { css } from '@mui/material';

import { Box } from 'shared/components/containers';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import { FC } from 'shared/types';

interface TabsContainerProps {}

const TabsContainer: FC<TabsContainerProps> = (props) => {
    const { children } = props;

    return (
        <Box
            css={css`
                display: flex;
                align-items: stretch;
                justify-content: space-between;

                width: 100%;
                max-width: ${appMaxWidth};
                overflow-x: scroll;

                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                margin-bottom: 24px;

                @media (max-width: 1000px) {
                    max-width: ${appMaxSmallWidth};
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            `}
        >
            {children}
        </Box>
    );
};

export { TabsContainer };
