import styled from '@emotion/styled';
import { TableHead as MuiTableHead, TableHeadProps as MuiTableHeadProps } from '@mui/material';

import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface TableHeadProps extends MuiTableHeadProps {}

const TableHead: FC<TableHeadProps> = (props) => <MuiTableHead {...props} />;

const StyledTableHead = styled(TableHead)`
    .MuiTableRow-root th {
        border-top: 1px solid ${({ theme }) => theme.palette.grey[500]};
        border-bottom: 1px solid ${({ theme }) => theme.palette.grey[500]};
        border-right: 1px solid ${({ theme }) => theme.palette.grey[500]};
        & > * {
            height: ${spacing.space14px};
        }
        &:first-of-type {
            border-left: 1px solid ${({ theme }) => theme.palette.grey[500]};
            border-top-left-radius: ${spacing.space4px};
            border-bottom-left-radius: ${spacing.space4px};
        }
        &:last-of-type {
            border-top-right-radius: ${spacing.space4px};
            border-bottom-right-radius: ${spacing.space4px};
        }
    }

    .MuiTableCell-root {
        font-size: ${fontSizes.f12};
        font-weight: ${fontWeights.bold};

        ${({ theme }) => `
        background-color: ${theme.palette.common.white};
    `}
    }
`;

export { StyledTableHead as TableHead };
