import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { MessageViaIntercom } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Button, Hyperlink, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { colors, fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface NoSeatsModalProps {
    totalSeats: number;
    open: boolean;
    onClose: () => void;
}

const NoSeatsModal: FC<NoSeatsModalProps> = (props) => {
    const { onClose, open, totalSeats } = props;

    const translate = useTranslations('settings.team-settings.member-list-submenu.no-seats-modal');

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    padding: 24px;
                `}
            >
                <ModalCloseButton onClose={onClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title')}
                </Title>
                <Box>
                    {
                        translate.rich('description', {
                            totalSeats,
                            link: Link,
                            intercom: MessageViaIntercom,
                        }) as EmotionJSX.Element
                    }
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 16px;
                    `}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClose}
                        css={css`
                            margin-right: 16px;
                        `}
                    >
                        {translate('ok-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: ${colors.greens.eucalyptus};
        `}
        href="mailto:daniel@hireflow.ai"
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

export { NoSeatsModal };
