import { css } from '@emotion/react';

import { Popover, PopoverProps } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SettingsPopoverProps extends PopoverProps {}

const SettingsPopover: FC<SettingsPopoverProps> = ({ ...rest }) => (
    <Popover
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        css={css`
            .MuiPaper-root {
                height: 100%;
                border-radius: 8px;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                width: 800px;
                padding: ${spacing.space48px};
                display: flex;
                flex-direction: column;
                row-gap: ${spacing.space48px};
            }

            h2 {
                font-size: ${fontSizes.f32};
                margin-bottom: ${spacing.space16px};
            }
        `}
        {...rest}
    />
);

export type { SettingsPopoverProps };
export { SettingsPopover };
