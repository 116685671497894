import styled from '@emotion/styled';
import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableCellProps extends MuiTableCellProps {}

const TableCell: FC<TableCellProps> = (props) => <MuiTableCell {...props} />;

const StyledTableCell = styled(TableCell)``;

export { StyledTableCell as TableCell };
