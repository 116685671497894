import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { kebabCase } from 'lodash';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import { ArchiveSourcerByIdMutation, ArchiveSourcerByIdMutationVariables } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import {
    ARCHIVE_SOURCER_BY_ID,
    GET_SOURCERS,
    GET_SOURCERS_COUNT_BY_STATUS,
    GET_SOURCER_BY_ID,
} from 'shared/graphql/sourcers';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC, SnakeToKebabCase } from 'shared/types';
import { PauseSourcerModalReasons } from 'sourcing/components/modals';
import { SourcerPauseReasonsEnum } from 'sourcing/types';

interface ArchiveSourcerModalProps {
    open: boolean;
    onClose: () => void;
    sourcer: { id: string; title: string };
}

const ArchiveSourcerModal: FC<ArchiveSourcerModalProps> = ({ open, onClose, sourcer }) => {
    const translate = useTranslations('sourcing.modals.pause-archive-modal');
    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [pauseReasons, setPauseReasons] = useState<SourcerPauseReasonsEnum[]>([]);
    const [notes, setNotes] = useState<string>('');

    const [archiveSourcer] = useMutation<ArchiveSourcerByIdMutation, ArchiveSourcerByIdMutationVariables>(
        ARCHIVE_SOURCER_BY_ID,
        {
            refetchQueries: [
                getOperationName(GET_SOURCER_BY_ID) as string,
                getOperationName(GET_SOURCERS) as string,
                getOperationName(GET_SOURCERS_COUNT_BY_STATUS) as string,
            ],
        }
    );

    const handlePauseReasonsChange = (newPauseReasons: SourcerPauseReasonsEnum[]) => {
        setPauseReasons(newPauseReasons);
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    };

    const handleArchiveSourcer = async () => {
        const logLikeNote = `${session?.user.email} marked this sourcer as archived`;
        const feedback = `"${pauseReasons
            .map((reason) =>
                translate(`pause-modal-reasons-${kebabCase(reason) as SnakeToKebabCase<SourcerPauseReasonsEnum>}`)
            )
            .join('; ')}; ${notes}"`;
        const note =
            pauseReasons.length > 0 || notes
                ? `${logLikeNote} and attached the following feedback: ${feedback}`
                : `${logLikeNote}.`;
        await archiveSourcer({
            variables: {
                id: sourcer.id,
                note,
            },
        });
        showSnackbarAlert({
            severity: 'success',
            message: translate('sourcer-is-now-paused', { title: sourcer.title }),
        });
        handleClose();
        trackEvent('click_pause', { sourcer_id: sourcer.id, value: 'sourcer' });
    };

    const handleClose = () => {
        setPauseReasons([]);
        setNotes('');
        onClose();
    };

    return (
        <Modal width={560} open={open} onClose={handleClose}>
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    gap: ${spacing.space12px};
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    css={css`
                        margin-bottom: ${spacing.none};
                        font-size: ${fontSizes.f24};
                        max-width: 420px;
                    `}
                    type="h3"
                >
                    {translate('archive-modal-header', { title: sourcer.title })}
                </Title>
                <Box>{translate('archive-modal-description')}</Box>
                <PauseSourcerModalReasons
                    title={translate('archive-modal-reasons-title')}
                    pauseReasons={pauseReasons}
                    onPauseReasonsChange={handlePauseReasonsChange}
                    notes={notes}
                    onNotesChange={handleNotesChange}
                />

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        padding: ${spacing.space16px} 0px 0px;
                        gap: ${spacing.space16px};
                    `}
                >
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                        {translate('modal-cancel-button-label')}
                    </Button>
                    <Button variant="contained" onClick={handleArchiveSourcer}>
                        {translate('archive-modal-archive-now-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ArchiveSourcerModal };
