import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { IconButton } from 'shared/components/presentational';
import { Bell02, Check, XClose } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ProspectCardButtonProps {
    type: 'snooze' | 'approve' | 'reject';
    isFilled?: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const ProspectCardButton: FC<ProspectCardButtonProps> = ({ type, isFilled = false, onClick, disabled }) => {
    const theme = useTheme();

    const color =
        type === 'snooze'
            ? theme.palette.grey[600]
            : type === 'reject'
            ? theme.palette.error.main
            : theme.palette.primary.main;

    const icon =
        type === 'snooze' ? (
            <Bell02 width={24} height={24} stroke={theme.palette.grey[600]} />
        ) : type === 'reject' ? (
            <XClose width={24} height={24} stroke={theme.palette.error.main} />
        ) : (
            <Check
                css={css`
                    path {
                        stroke-width: 1.5px;
                    }
                `}
                width={24}
                height={24}
                stroke={theme.palette.primary.main}
            />
        );

    return (
        <IconButton
            className={isFilled ? 'filled' : ''}
            onClick={onClick}
            disabled={disabled}
            css={css`
                border: 1px solid ${color};
                &.filled {
                    background-color: ${color};
                    path {
                        stroke: ${theme.palette.common.white};
                    }
                }
            `}
        >
            {icon}
        </IconButton>
    );
};

export { ProspectCardButton };
