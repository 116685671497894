import { css, useTheme } from '@mui/material';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';

import { PrimaryEmailChange } from 'prospects';
import { Box } from 'shared/components/containers';
import { IconButton, Popover } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ProspectPrimaryChangedProps {
    emails: string[];
    sequences: string[];
    anchor: HTMLElement;
    open: boolean;
    onCancel: () => void;
    onSave: (decision: boolean) => void;
}

const ProspectPrimaryChangeModal: FC<ProspectPrimaryChangedProps> = (props) => {
    const { emails, anchor, open, onCancel, onSave, sequences } = props;
    const theme = useTheme();

    const onClose = () => {
        onCancel();
    };

    return (
        <Popover
            anchorReference="anchorEl"
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    width: extensionPanelWidth,
                    height: '100%',
                },
            }}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    position: relative;
                    padding: 8px;
                `}
            >
                <IconButton onClick={onClose}>
                    <XClose stroke={theme.palette.primary.main} />
                </IconButton>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    min-height: calc(100% - 100px);
                `}
            >
                <PrimaryEmailChange onSave={onSave} onCancel={onClose} sequences={sequences} emails={emails} />
            </Box>
        </Popover>
    );
};

export { ProspectPrimaryChangeModal };
