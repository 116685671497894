import { css } from '@emotion/react';

import { Box, BoxProps } from 'shared/components/containers';
import { FC } from 'shared/types';

interface EllipsisTextProps extends BoxProps {}

const EllipsisText: FC<EllipsisTextProps> = (props) => (
    <Box
        css={css`
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            align-items: center;
        `}
    >
        <Box
            css={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            `}
            {...props}
        />
    </Box>
);

export { EllipsisText };
