import { SourcerCollaboratorTypeEnum, SourcerMemberStatusEnum } from 'codegen/graphql';
import { useSourcerAccessControl } from 'shared/hooks';
import { FC } from 'shared/types';
import { NoProspectsNotice } from 'sourcing/components/presentational';
import { SourcerMembersListTable } from 'sourcing/components/tables';

interface SnoozedProps {
    creditsPerApproval: number;
    sourcer: {
        id: string;
        userId: string;
        project: { id: string };
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    };
    count: number;
}

const Snoozed: FC<SnoozedProps> = ({ creditsPerApproval, sourcer, count }) => {
    const { canManageSourcerMembers } = useSourcerAccessControl();

    if (count > 0) {
        return (
            <SourcerMembersListTable
                status={SourcerMemberStatusEnum.Snoozed}
                sourcer={sourcer}
                creditsPerApproval={creditsPerApproval}
                readOnly={!canManageSourcerMembers(sourcer)}
            />
        );
    }
    return <NoProspectsNotice />;
};

export { Snoozed };
