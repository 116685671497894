import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import {
    CreateRecipientMutation,
    CreateRecipientMutationResult,
    OrderBy,
    ProspectsQueryResult,
    SequencesQuery,
    SequenceStatusEnum,
} from 'codegen/graphql';
import {
    ChooseSequenceForm,
    ConfirmAddToSequence,
    MissingVariablesAlert,
    MissingVariablesForm,
} from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { ModalCloseButton, Title } from 'shared/components/presentational';
import { Modal } from 'shared/components/presentational/modal';
import { CREATE_RECIPIENTS } from 'shared/graphql/recipients';
import { GET_SEQUENCES } from 'shared/graphql/sequences';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { findProspectMissingVariables, ProspectVariables } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectProfile } from 'shared/types/prospect';

export interface ValidProspect {
    id: string;
    profile: ProspectProfile;
    url: string;
}
export interface InvalidProspect extends ValidProspect {
    missingFields: ProspectVariables[];
    isResolved: boolean;
}
type Prospect = DeepExtractTypeSkipArrays<ProspectsQueryResult, ['data', 'prospects']>;
type Recipient = DeepExtractTypeSkipArrays<CreateRecipientMutationResult, ['data', 'insert_recipients', 'returning']>;
interface AddToSequenceModalProps {
    open: boolean;
    onClose: () => void;
    getProspects: () => Promise<Prospect[]>;
    sequencesToExcludeFromDropdown?: string[];
    onSuccess?: (recipients: Recipient[], sequenceId: string) => void;
    count: number;
    refetchQueries?: string[];
}

const AddToSequenceModal: FC<AddToSequenceModalProps> = ({
    open,
    onClose,
    getProspects,
    sequencesToExcludeFromDropdown = [],
    onSuccess,
    count,
    refetchQueries = [],
}) => {
    const { session } = useSession();
    const translate = useTranslations('project.prospect-list-table.edit-bar.add-to-sequence-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [modalState, setModalState] = useState<
        'choose-sequence' | 'missing-variables-alert' | 'missing-variables-form' | 'confirm-add-to-sequence'
    >('choose-sequence');

    const [selected, setSelected] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // Ignoring user settings variables for now
    const [invalidProspects, setInvalidProspects] = useState<InvalidProspect[]>([]);

    const [validProspects, setValidProspects] = useState<ValidProspect[]>([]);

    const { data: sequencesData } = useQuery<SequencesQuery>(GET_SEQUENCES, {
        variables: {
            where: {
                _and: {
                    userId: { _eq: session?.user.id },
                    status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                    id: { _nin: sequencesToExcludeFromDropdown },
                },
            },
            orderBy: {
                title: OrderBy.Asc,
            },
            withDetails: true,
        },
    });

    const [createRecipients] = useMutation<CreateRecipientMutation>(CREATE_RECIPIENTS, {
        refetchQueries,
    });

    const selectedSequence = sequencesData?.sequences.find((sequence) => sequence.id === selected);
    const remainingInvalidProspects = invalidProspects.filter((prospect) => !prospect.isResolved);

    useEffect(() => {
        const sequenceOptions = sequencesData?.sequences.map((sequence) => ({
            value: sequence.id,
            label: sequence.title,
        }));
        if (sequenceOptions && !selected) {
            const defaultOption = sequenceOptions[0]?.value ?? '';
            setSelected(defaultOption);
        }
    }, [sequencesData, selected]);

    const handleClose = () => {
        onClose();
        // reset state
        setInvalidProspects([]);
        setValidProspects([]);
        setModalState('choose-sequence');
    };

    const handleSequenceSend = async () => {
        if (selectedSequence) {
            if (!loading) setLoading(true);
            const prospects = await getProspects();
            const prospectsWithMissingVariables: InvalidProspect[] = [];
            const goodProspects: ValidProspect[] = [];

            prospects?.forEach((prospect) => {
                if (prospect.profile) {
                    const { profile } = prospect;
                    const SelectedSequenceCustomMessage = prospect.customMessages?.find(
                        (customMessageObject) => customMessageObject.sequenceId === selectedSequence.id
                    );
                    const customMessage = SelectedSequenceCustomMessage?.customMessage;
                    const missingVariables = findProspectMissingVariables(profile, selectedSequence, customMessage);
                    if (missingVariables.length > 0) {
                        prospectsWithMissingVariables.push({
                            id: prospect.id,
                            profile,
                            missingFields: missingVariables,
                            isResolved: false,
                            url: getPrimaryProfileUrl(prospect.urls!),
                        });
                    } else {
                        goodProspects.push({
                            id: prospect.id,
                            profile,
                            url: getPrimaryProfileUrl(prospect?.urls!),
                        });
                    }
                }
            });

            setInvalidProspects(prospectsWithMissingVariables);
            setValidProspects(goodProspects);
            if (prospectsWithMissingVariables.length > 0) {
                setLoading(false);
                setModalState('missing-variables-alert');
            } else {
                await handleCreateRecipients(goodProspects);
                setLoading(false);
            }
        }
    };

    const handleCreateRecipients = async (prospects: ValidProspect[]) => {
        if (selectedSequence) {
            const res = await createRecipients({
                variables: {
                    recipients: prospects.map((prospect) => ({
                        prospectId: prospect.id,
                        sequenceId: selectedSequence.id,
                    })),
                },
            });
            if (res.data) {
                if (onSuccess && res.data.insert_recipients) {
                    onSuccess(res.data.insert_recipients.returning, selectedSequence.id);
                }

                const newRecipients = Number(res.data.insert_recipients?.affected_rows);
                const duplicatesCount = prospects.length - newRecipients;
                showSnackbarAlert({
                    message: translate('success-alert', {
                        count: newRecipients,
                        duplicatesCount,
                        sequence: selectedSequence.title,
                    }),
                });
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('error-alert'),
                });
            }
            handleClose();
        }
    };

    const handleCreateRecipientsFromValidProspects = async () => {
        handleCreateRecipients(validProspects);
    };

    const handleSequenceChange = (value: string) => {
        setSelected(value);
    };

    const GoToConfirmAddToSequence = () => {
        setModalState('confirm-add-to-sequence');
    };

    const setMissingVariables = () => {
        setModalState('missing-variables-form');
    };

    const addValidProspect = (prospect: ValidProspect) => {
        setValidProspects([...validProspects, prospect]);
    };

    const markProspectAsResolved = (index: number) => {
        const newInvalidProspects = invalidProspects.map((prospect, i) => {
            if (index === i) {
                return { ...prospect, isResolved: true };
            }
            return prospect;
        });
        setInvalidProspects(newInvalidProspects);
    };

    if (!sequencesData) return null;

    const modalTitle =
        modalState === 'confirm-add-to-sequence'
            ? translate('confirm-add-to-sequence.title')
            : modalState === 'missing-variables-form'
            ? translate('missing-variables-form.title')
            : modalState === 'missing-variables-alert' && validProspects.length > 0
            ? translate('missing-variables-alert.title', { count: invalidProspects.length })
            : modalState === 'missing-variables-alert' && validProspects.length === 0
            ? translate('missing-variables-alert.title-disabled-skip', { count: invalidProspects.length })
            : translate('choose-sequence-form.title');

    const modalContent =
        modalState === 'confirm-add-to-sequence' ? (
            <ConfirmAddToSequence
                sequenceTitle={selectedSequence!.title} // at this point, we have a selected sequence for sure
                validProspectsCount={validProspects.length}
                invalidProspectsCount={remainingInvalidProspects.length}
                onCreateRecipients={handleCreateRecipientsFromValidProspects}
                loading={loading}
            />
        ) : modalState === 'missing-variables-form' ? (
            <MissingVariablesForm
                sequenceId={selectedSequence!.id} // at this point, we have a selected sequence for sure
                invalidProspects={invalidProspects}
                validProspects={validProspects}
                addValidProspect={addValidProspect}
                onMissingVariablesComplete={GoToConfirmAddToSequence}
                markProspectAsResolved={markProspectAsResolved}
            />
        ) : modalState === 'missing-variables-alert' ? (
            <MissingVariablesAlert
                onSkipMissingVariables={GoToConfirmAddToSequence}
                onSetMissingVariables={setMissingVariables}
                onCancel={handleClose}
                shouldDisableSkip={validProspects.length === 0}
            />
        ) : (
            <ChooseSequenceForm
                sequenceOptions={sequencesData.sequences.map((sequence) => ({
                    value: sequence.id,
                    label: sequence.title,
                }))}
                selectedProspectsCount={count}
                onSequenceSend={handleSequenceSend}
                selected={selected}
                handleSequenceChange={handleSequenceChange}
                loading={loading}
            />
        );

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    {modalTitle}
                </Title>
                {modalContent}
            </Box>
        </Modal>
    );
};

export { AddToSequenceModal };
