import { css, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { trackLoadPage } from 'analytics';
import { IconButton } from 'shared/components/presentational';
import { DotsVertical } from 'shared/components/svgs';
import { FC } from 'shared/types';
import { SourcingListTopSubmenu } from 'sourcing/components/presentational';

interface SourcingListMenuProps {}

const SourcingListMenu: FC<SourcingListMenuProps> = () => {
    const theme = useTheme();

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();

    useEffect(() => {
        trackLoadPage('sourcer_parent');
    }, []);

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    return (
        <>
            <IconButton
                css={css`
                    margin-right: 16px;
                    &:hover path {
                        stroke: ${theme.palette.primary.light};
                    }
                `}
                onClick={handleSubmenuButtonClick}
                disableRipple
            >
                <DotsVertical />
            </IconButton>
            <SourcingListTopSubmenu anchor={submenuAnchor} setAnchor={setSubmenuAnchor} />
        </>
    );
};

export { SourcingListMenu };
