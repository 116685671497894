import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
    AshbyJobInterviewPlansByJobIdQuery,
    AshbyUsersQuery,
    GreenhouseApplicationTypeEnum,
    GreenhouseJobStagesQuery,
    GreenhouseUsersQuery,
    HireflowEnumsAtsMappingEventsEnum,
    LeverStagesQuery,
    LeverUsersQuery,
} from 'codegen/graphql';
import { AtsSyncSettingsAdvancedSpacer } from 'integrations/components/presentational';
import { atsTypeGreenhouse } from 'integrations/constants';
import { AtsType } from 'integrations/types';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { Bold } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { AutoComplete, MenuItem, Select, SelectOption, Switch } from 'shared/components/form';
import { FormSection } from 'shared/components/presentational';
import { ArrowNarrowRight, Circle } from 'shared/components/svgs';
import {
    AshbyInterviewPlanStagesData,
    AshbyJobsData,
    AshbyUsersData,
    GreenhouseJobStagesData,
    GreenhouseJobsData,
    GreenhouseUsersData,
    LeverPostingsData,
    LeverStagesData,
    LeverUsersData,
} from 'shared/graphql/integrations';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { filterUndesiredJobs, getAtsJobOptions, getAtsUserOptions } from 'shared/utils';

const createEventTypeSelectOptions = (): SelectOption[] => [
    {
        value: HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        label: 'When added to this sequence',
    },
    {
        value: HireflowEnumsAtsMappingEventsEnum.EmailSent,
        label: 'When an email is sent',
    },
    {
        value: HireflowEnumsAtsMappingEventsEnum.ResponseReceived,
        label: 'When a response comes back',
    },
];

const applicationTypeSelectOptions = (): SelectOption[] => [
    {
        value: GreenhouseApplicationTypeEnum.Candidate,
        label: 'Candidate',
    },
    {
        value: GreenhouseApplicationTypeEnum.Prospect,
        label: 'Prospect',
    },
];

type AtsJobStageOptionsSelectedValue =
    | 'ashbyDefaultAddStageId'
    | 'leverDefaultAddStageId'
    | 'ashbyRepliedStageId'
    | 'leverRepliedStageId'
    | 'ashbyInterestedReplyStageId'
    | 'greenhouseInterestedReplyStageId'
    | 'leverInterestedReplyStageId'
    | 'ashbyMeetingBookedStageId'
    | 'greenhouseMeetingBookedStageId'
    | 'leverMeetingBookedStageId';

interface AtsSyncSettingsTemplateProps {
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    atsType: AtsType;
    jobsData: GreenhouseJobsData | LeverPostingsData | AshbyJobsData;
    stagesData: GreenhouseJobStagesData | LeverStagesData | AshbyInterviewPlanStagesData;
    getStagesData:
        | LazyQueryExecFunction<GreenhouseJobStagesQuery, OperationVariables>
        | LazyQueryExecFunction<LeverStagesQuery, OperationVariables>
        | LazyQueryExecFunction<AshbyJobInterviewPlansByJobIdQuery, OperationVariables>;
    usersData: GreenhouseUsersData | LeverUsersData | AshbyUsersData;
    getUsersData:
        | LazyQueryExecFunction<GreenhouseUsersQuery, OperationVariables>
        | LazyQueryExecFunction<LeverUsersQuery, OperationVariables>
        | LazyQueryExecFunction<AshbyUsersQuery, OperationVariables>;
}

const AtsSyncSettingsSequenceTemplate: FC<AtsSyncSettingsTemplateProps> = (props) => {
    const { formMethods, atsType, jobsData, stagesData, getStagesData, usersData, getUsersData } = props;

    const theme = useTheme();
    const translate = useTranslations('integrations');

    const jobIdFieldName: 'greenhouseJobId' | 'leverJobId' | 'ashbyJobId' = `${atsType}JobId`;
    const userIdFieldName: 'greenhouseUserId' | 'leverUserId' | 'ashbyUserId' = `${atsType}UserId`;
    const createEventTypeFieldName:
        | 'greenhouseCreateEventType'
        | 'leverCreateEventType'
        | 'ashbyCreateEventType' = `${atsType}CreateEventType`;

    let repliedMoveStageActiveFieldName: 'leverRepliedMoveStageActive' | 'ashbyRepliedMoveStageActive' | undefined;
    if (atsType !== atsTypeGreenhouse) {
        repliedMoveStageActiveFieldName = `${atsType}RepliedMoveStageActive`;
    }

    let repliedStageIdFieldName: 'leverRepliedStageId' | 'ashbyRepliedStageId' | undefined;
    if (atsType !== atsTypeGreenhouse) {
        repliedStageIdFieldName = `${atsType}RepliedStageId`;
    }

    let defaultAddStageIdFieldName: 'leverDefaultAddStageId' | 'ashbyDefaultAddStageId' | undefined;
    if (atsType !== atsTypeGreenhouse) {
        defaultAddStageIdFieldName = `${atsType}DefaultAddStageId`;
    }

    const interestedReplyMoveStageActiveFieldName:
        | 'greenhouseInterestedReplyMoveStageActive'
        | 'leverInterestedReplyMoveStageActive'
        | 'ashbyInterestedReplyMoveStageActive' = `${atsType}InterestedReplyMoveStageActive`;
    const interestedReplyStageIdFieldName:
        | 'greenhouseInterestedReplyStageId'
        | 'leverInterestedReplyStageId'
        | 'ashbyInterestedReplyStageId' = `${atsType}InterestedReplyStageId`;
    const meetingBookedMoveStageActiveFieldName:
        | 'greenhouseMeetingBookedMoveStageActive'
        | 'leverMeetingBookedMoveStageActive'
        | 'ashbyMeetingBookedMoveStageActive' = `${atsType}MeetingBookedMoveStageActive`;
    const meetingBookedStageIdFieldName:
        | 'greenhouseMeetingBookedStageId'
        | 'leverMeetingBookedStageId'
        | 'ashbyMeetingBookedStageId' = `${atsType}MeetingBookedStageId`;

    useEffect(() => {
        async function fetch() {
            if (!formMethods.getValues(jobIdFieldName)) return;
            await getStagesData();
            await getUsersData();
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.getValues(jobIdFieldName)]);

    /**
     * fired when the selected ats job is changed
     * this method will trigger the useEffect above so that the job stages data will be reloaded
     */
    const handleJobChange = async (value: string) => {
        const register = formMethods?.register(jobIdFieldName);
        if (register) {
            const eventObject = {
                target: {
                    value,
                    name: jobIdFieldName,
                },
                type: 'change',
            };

            await register.onChange(eventObject);
        }

        // unset other fields when job is changed
        formMethods.setValue(userIdFieldName, '');
        formMethods.setValue(createEventTypeFieldName, HireflowEnumsAtsMappingEventsEnum.AddedToSequence);

        // the following fields are not required by greenhouse
        if (atsType !== atsTypeGreenhouse) {
            if (repliedMoveStageActiveFieldName) {
                formMethods.setValue(repliedMoveStageActiveFieldName, false);
            }
            if (repliedStageIdFieldName) {
                formMethods.setValue(repliedStageIdFieldName, '');
            }
            if (defaultAddStageIdFieldName) {
                formMethods.setValue(defaultAddStageIdFieldName, '');
            }
        }

        formMethods.setValue(interestedReplyMoveStageActiveFieldName, false);
        formMethods.setValue(interestedReplyStageIdFieldName, '');
        formMethods.setValue(meetingBookedMoveStageActiveFieldName, false);
        formMethods.setValue(meetingBookedStageIdFieldName, '');

        // handle special requirements for greenhouse
        if (atsType === atsTypeGreenhouse) {
            formMethods.setValue('greenhouseAddAsApplicationType', GreenhouseApplicationTypeEnum.Candidate);
            formMethods.setValue('greenhouseInterestedReplyConvertActive', false);
            formMethods.setValue('greenhouseMeetingBookedConvertActive', false);
        }
    };

    const handleUserChange = (value: string) => {
        formMethods.setValue(userIdFieldName, value, { shouldDirty: true });
    };

    const buildAtsJobOptions = () => {
        const filteredJobsData = filterUndesiredJobs({
            jobsData,
            selectedOption: formMethods.getValues(jobIdFieldName),
        });
        return getAtsJobOptions(filteredJobsData);
    };

    const getAtsJobStageOptions = (selectedValue: AtsJobStageOptionsSelectedValue) =>
        stagesData?.map((option) => (
            <MenuItem value={option.id} key={option.id}>
                {option.id.toString() === formMethods.getValues(selectedValue) && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {`${translate('move-to')} ${option.name}`}
            </MenuItem>
        ));

    const createEventTypeMenuOptions = createEventTypeSelectOptions().map((option) => (
        <MenuItem value={option.value} key={option.value}>
            {option.value === formMethods.getValues(createEventTypeFieldName) && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            {option.label}
        </MenuItem>
    ));

    const applicationTypeMenuOptions = applicationTypeSelectOptions().map((option) => (
        <MenuItem value={option.value} key={option.value}>
            {option.value === formMethods.getValues('greenhouseAddAsApplicationType') && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            {option.label}
        </MenuItem>
    ));

    const buildAtsUserOptions = useCallback(() => getAtsUserOptions(usersData), [usersData]);

    const getDefaultAddStageOptions = () => {
        if (atsType === atsTypeGreenhouse || !defaultAddStageIdFieldName) return [];
        return getAtsJobStageOptions(defaultAddStageIdFieldName);
    };
    const getRepliedStageOptions = () => {
        if (atsType === atsTypeGreenhouse || !repliedStageIdFieldName) return [];
        return getAtsJobStageOptions(repliedStageIdFieldName);
    };
    const getInterestedRepliedStageOptions = () => getAtsJobStageOptions(interestedReplyStageIdFieldName);
    const getMeetingBookedStageOptions = () => getAtsJobStageOptions(meetingBookedStageIdFieldName);

    return (
        <Box
            css={css`
                margin-top: ${spacing.space48px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: flex-start;
                `}
            >
                <Switch formMethods={formMethods} name={`${atsType}SyncProspectToggleActive`} />
                <FormSection title={translate(`${atsType}.is-project-linked`)}>
                    <Box
                        css={css`
                            visibility: ${formMethods.getValues(`${atsType}SyncProspectToggleActive`)
                                ? 'visible'
                                : 'hidden'};
                            height: ${formMethods.getValues(`${atsType}SyncProspectToggleActive`) ? '100%' : '0'};
                        `}
                    >
                        <Box>
                            <AutoComplete
                                grouped
                                css={css`
                                    flex: 1;
                                `}
                                formMethods={formMethods}
                                name={jobIdFieldName}
                                value={formMethods.getValues(jobIdFieldName)}
                                label={translate('ats-job-select')}
                                options={buildAtsJobOptions()}
                                onChange={handleJobChange}
                                noOptionsText={translate('ats-dropdown-no-option-label')}
                                parentBackgroundColor="white"
                            />
                        </Box>
                        <Box>
                            <AutoComplete
                                css={css`
                                    flex: 1;
                                `}
                                disabled={!formMethods.getValues(jobIdFieldName)}
                                formMethods={formMethods}
                                name={userIdFieldName}
                                value={formMethods.getValues(userIdFieldName)}
                                label={translate('ats-user-select')}
                                options={buildAtsUserOptions()}
                                onChange={handleUserChange}
                                noOptionsText={translate('ats-dropdown-no-option-label')}
                                parentBackgroundColor="white"
                            />
                        </Box>

                        <Box
                            css={css`
                                display: flex;
                                align-items: center;
                                gap: 16px;
                            `}
                        >
                            <Box>
                                <Select
                                    css={css`
                                        flex: 1;
                                    `}
                                    variant="outlined"
                                    formMethods={formMethods}
                                    name={createEventTypeFieldName}
                                    value={formMethods.getValues(createEventTypeFieldName)}
                                    label={translate('ats-create-event-type-select')}
                                >
                                    {createEventTypeMenuOptions}
                                </Select>
                            </Box>
                            {atsType === atsTypeGreenhouse ? (
                                <>
                                    <Box>
                                        <ArrowNarrowRight stroke={theme.palette.common.black} />
                                    </Box>
                                    <Box>
                                        <Select
                                            css={css`
                                                flex: 1;
                                            `}
                                            variant="outlined"
                                            formMethods={formMethods}
                                            name="greenhouseAddAsApplicationType"
                                            value={formMethods.getValues('greenhouseAddAsApplicationType')}
                                            label={translate('greenhouse.add-as-application-type')}
                                        >
                                            {applicationTypeMenuOptions}
                                        </Select>
                                    </Box>
                                </>
                            ) : (
                                defaultAddStageIdFieldName && (
                                    <>
                                        <Box>
                                            <ArrowNarrowRight stroke={theme.palette.common.black} />
                                        </Box>
                                        <Box>
                                            <Select
                                                css={css`
                                                    flex: 1;
                                                `}
                                                variant="outlined"
                                                formMethods={formMethods}
                                                name={defaultAddStageIdFieldName}
                                                value={formMethods.getValues(defaultAddStageIdFieldName)}
                                                label={translate('ats-default-stage-select')}
                                            >
                                                {getDefaultAddStageOptions()}
                                            </Select>
                                        </Box>
                                    </>
                                )
                            )}
                        </Box>
                        <Box
                            css={css`
                                margin-top: ${spacing.space8px};
                                background-color: ${theme.palette.primary.contrastText};
                                border-radius: ${spacing.space4px};
                                padding: ${spacing.space24px};
                            `}
                        >
                            <AtsSyncSettingsAdvancedSpacer />

                            {atsType === atsTypeGreenhouse ? (
                                <Box>
                                    <Switch
                                        label={
                                            translate.rich('ats-sync-setting-switch-labels.interested-reply-convert', {
                                                bold: Bold,
                                            }) as string
                                        }
                                        formMethods={formMethods}
                                        name="greenhouseInterestedReplyConvertActive"
                                    />
                                </Box>
                            ) : (
                                repliedMoveStageActiveFieldName &&
                                repliedStageIdFieldName && (
                                    <Box
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            gap: ${spacing.space16px};
                                        `}
                                    >
                                        <Switch
                                            label={translate('ats-sync-setting-switch-labels.reply-stage')}
                                            formMethods={formMethods}
                                            name={repliedMoveStageActiveFieldName}
                                        />
                                        <Select
                                            parentBackgroundColor="green"
                                            formMethods={formMethods}
                                            name={repliedStageIdFieldName}
                                            value={formMethods.getValues(repliedStageIdFieldName)}
                                            label={translate('ats-sync-setting-select-labels.reply-stage')}
                                        >
                                            {getRepliedStageOptions()}
                                        </Select>
                                    </Box>
                                )
                            )}

                            <Box
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    gap: ${spacing.space16px};
                                `}
                            >
                                <Switch
                                    label={translate('ats-sync-setting-switch-labels.interested-reply-stage')}
                                    formMethods={formMethods}
                                    name={interestedReplyMoveStageActiveFieldName}
                                />
                                <Select
                                    parentBackgroundColor="green"
                                    formMethods={formMethods}
                                    name={interestedReplyStageIdFieldName}
                                    value={formMethods.getValues(interestedReplyStageIdFieldName)}
                                    label={translate('ats-sync-setting-select-labels.interested-reply-stage')}
                                >
                                    {getInterestedRepliedStageOptions()}
                                </Select>
                            </Box>

                            {atsType === atsTypeGreenhouse && (
                                <Box>
                                    <Switch
                                        label={
                                            translate.rich('ats-sync-setting-switch-labels.meeting-booked-convert', {
                                                bold: Bold,
                                            }) as string
                                        }
                                        formMethods={formMethods}
                                        name="greenhouseMeetingBookedConvertActive"
                                    />
                                </Box>
                            )}

                            <Box
                                css={css`
                                    display: flex;
                                    align-items: center;
                                    gap: ${spacing.space16px};
                                `}
                            >
                                <Switch
                                    label={translate('ats-sync-setting-switch-labels.meeting-booked-stage')}
                                    formMethods={formMethods}
                                    name={meetingBookedMoveStageActiveFieldName}
                                />
                                <Select
                                    parentBackgroundColor="green"
                                    formMethods={formMethods}
                                    name={meetingBookedStageIdFieldName}
                                    value={formMethods.getValues(meetingBookedStageIdFieldName)}
                                    label={translate('ats-sync-setting-select-labels.meeting-booked-stage')}
                                >
                                    {getMeetingBookedStageOptions()}
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                </FormSection>
            </Box>
        </Box>
    );
};

export { AtsSyncSettingsSequenceTemplate };
