import { gql } from '@apollo/client';

export const GET_PROJECT_ACTIVITIES = gql`
    query ProjectActivities(
        $where: project_activities_bool_exp = {}
        $limit: Int = 2000
        $offset: Int = 0
        $order_by: [project_activities_order_by!] = { createdAt: desc }
    ) {
        project_activities(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
            projectId
            type
            oldValue
            newValue
            createdAt
            user {
                id
                fullName
            }
        }
    }
`;
