import { RecipientStatusEnum } from 'codegen/graphql';

export const PreviewConversationsForStatus: RecipientStatusEnum[] = [
    RecipientStatusEnum.FindingEmail,
    RecipientStatusEnum.NotStarted,
    RecipientStatusEnum.InProgress,
    RecipientStatusEnum.UnableToFindContact,
    RecipientStatusEnum.UnableToSendMessage,
    RecipientStatusEnum.MissingTemplateVariables,
];

export const StatusEligibleForContactUpdate: RecipientStatusEnum[] = [
    RecipientStatusEnum.FindingEmail,
    RecipientStatusEnum.UnableToFindContact,
];

export const RecipientSequenceStopped: RecipientStatusEnum[] = [
    RecipientStatusEnum.CompletedWithNoReply,
    RecipientStatusEnum.ManuallyExited,
    RecipientStatusEnum.MeetingBooked,
    RecipientStatusEnum.Responded,
    RecipientStatusEnum.Unsubscribed,
];

export const MissingTemplateVariablesOrUnableToSend: RecipientStatusEnum[] = [
    RecipientStatusEnum.MissingTemplateVariables,
    RecipientStatusEnum.UnableToSendMessage,
];
