import { css } from '@emotion/react';
import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface Education {
    degree?: string | null;
    fieldOfStudy: string;
    schoolName?: string | null;
    startDateYear?: number | null;
    endDateYear?: number | null;
}

interface ProspectCardEducationProps {
    educations: Education[];
}

const ProspectCardEducation: FC<ProspectCardEducationProps> = ({ educations }) => {
    const translate = useTranslations('shared.prospect-card.school-table');
    const theme = useTheme();
    return (
        <TableContainer
            css={css`
                padding: 0px 24px 0px 24px;
            `}
        >
            <Table>
                <TableBody>
                    {educations.map((edu, index) => {
                        const degreeAndFieldOfStudy = edu.degree
                            ? `${edu.degree}, ${edu.fieldOfStudy}`
                            : `${edu.fieldOfStudy}`;
                        const dateRange =
                            edu.startDateYear && edu.endDateYear ? `${edu.startDateYear} - ${edu.endDateYear}` : '';
                        return (
                            <TableRow
                                key={index}
                                css={css`
                                    &:last-child .MuiTableCell-root {
                                        border: 0;
                                    }
                                    .MuiTableCell-root {
                                        font-size: ${fontSizes.f14};
                                        padding: 6px 0px;
                                        border-bottom: 1px solid ${theme.palette.grey[100]};
                                        color: ${theme.palette.grey[200]};
                                        vertical-align: top;
                                    }
                                `}
                            >
                                {index === 0 ? (
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        width="130px"
                                        css={css`
                                            &.MuiTableCell-root {
                                                font-size: ${fontSizes.f16};
                                                color: ${theme.palette.common.black};
                                                border: 0;
                                            }
                                        `}
                                        rowSpan={educations.length}
                                    >
                                        {translate.rich('header', { bold: Bold })}
                                    </TableCell>
                                ) : null}
                                <TableCell>
                                    <Box>{degreeAndFieldOfStudy}</Box>
                                    <Box
                                        css={css`
                                            color: ${theme.palette.grey[600]};
                                        `}
                                    >
                                        {edu.schoolName}
                                    </Box>
                                </TableCell>
                                <TableCell align="right">{dateRange}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export { ProspectCardEducation };

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);
