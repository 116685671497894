import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
    AshbyAccountByTeamIdQuery,
    AshbySequenceMappingBySequenceIdQuery,
    AshbySequenceMappingsInsertInput,
    CreateAshbySequenceMappingMutation,
    CreateGreenhouseSequenceMappingMutation,
    CreateLeverSequenceMappingMutation,
    DeleteAshbySequenceMappingByIdMutation,
    DeleteGreenhouseSequenceMappingByIdMutation,
    DeleteLeverSequenceMappingByIdMutation,
    DeleteProjectSequenceMappingsMutation,
    GreenhouseAccountByTeamIdQuery,
    GreenhouseApplicationTypeEnum,
    GreenhouseSequenceMappingBySequenceIdQuery,
    GreenhouseSequenceMappingsInsertInput,
    HireflowEnumsAtsMappingEventsEnum,
    InsertProjectSequenceMappingsMutation,
    LeverAccountByTeamIdQuery,
    LeverSequenceMappingBySequenceIdQuery,
    LeverSequenceMappingsInsertInput,
    ProjectStatusEnum,
    SequencesQuery,
    SequenceStatusEnum,
    UpdateAshbySequenceMappingByIdMutation,
    UpdateGreenhouseSequenceMappingByIdMutation,
    UpdateLeverSequenceMappingByIdMutation,
    UpdateSequenceByIdMutation,
    UserProjectsQuery,
    UserProjectsQueryVariables,
} from 'codegen/graphql';
import { SequenceEditBar } from 'sequences/components/presentational';
import { CreateEditSettingsTemplate } from 'sequences/components/templates';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { SequenceFormATSValidationBuilder, SequenceFormATSValidationBuilderSchemaShape } from 'sequences/utils';
import { Form } from 'shared/components/form';
import { Snackbar } from 'shared/components/presentational';
import {
    AshbySequenceMappingBySequenceIdData,
    CREATE_ASHBY_SEQUENCE_MAPPING,
    DELETE_ASHBY_SEQUENCE_MAPPING_BY_ID,
    GET_ASHBY_ACCOUNT_BY_TEAM_ID,
    GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    UPDATE_ASHBY_SEQUENCE_MAPPING_BY_ID,
} from 'shared/graphql/integrations/ashby';
import {
    CREATE_GREENHOUSE_SEQUENCE_MAPPING,
    DELETE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
    GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
    GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    GreenhouseSequenceMappingBySequenceIdData,
    UPDATE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
} from 'shared/graphql/integrations/greenhouse';
import {
    CREATE_LEVER_SEQUENCE_MAPPING,
    DELETE_LEVER_SEQUENCE_MAPPING_BY_ID,
    GET_LEVER_ACCOUNT_BY_TEAM_ID,
    GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    LeverSequenceMappingBySequenceIdData,
    UPDATE_LEVER_SEQUENCE_MAPPING_BY_ID,
} from 'shared/graphql/integrations/lever';
import {
    DELETE_PROJECT_SEQUENCE_MAPPINGS,
    GET_USER_PROJECTS,
    INSERT_PROJECT_SEQUENCE_MAPPINGS,
    UserProjectCollaboratorType,
    userProjectsDefaultVariables,
} from 'shared/graphql/projects';
import { GET_SEQUENCE_BY_ID, UPDATE_SEQUENCE_BY_ID } from 'shared/graphql/sequences';
import { useAccessControl, useSession, useSnackbarAlert, useDynamicSchemaForm } from 'shared/hooks';
import { FC } from 'shared/types';

type SequenceData = SequencesQuery['sequences'][number];

interface EditSettingsFormProps {
    sequence: SequenceData;
}

const EditSettingsForm: FC<EditSettingsFormProps> = (props) => {
    const { sequence } = props;
    const translate = useTranslations('sequence');
    const { session, loaded } = useSession();
    const { canEditSequence } = useAccessControl();
    const [isEditSnackBarOpen, setIsEditSnackBarOpen] = useState<boolean>(false);
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateSequence] = useMutation<UpdateSequenceByIdMutation>(UPDATE_SEQUENCE_BY_ID, {
        refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
    });
    const [deleteProjectSequenceMappings] = useMutation<DeleteProjectSequenceMappingsMutation>(
        DELETE_PROJECT_SEQUENCE_MAPPINGS,
        {
            refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
        }
    );
    const [insertProjectSequenceMappings] = useMutation<InsertProjectSequenceMappingsMutation>(
        INSERT_PROJECT_SEQUENCE_MAPPINGS,
        {
            refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
        }
    );

    // ashby
    const { data: ashbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(GET_ASHBY_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: ashbySequenceMappingData } = useQuery<AshbySequenceMappingBySequenceIdQuery>(
        GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createAshbySequenceMapping] = useMutation<CreateAshbySequenceMappingMutation>(
        CREATE_ASHBY_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [updateAshbySequenceMapping] = useMutation<UpdateAshbySequenceMappingByIdMutation>(
        UPDATE_ASHBY_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [deleteAshbySequenceMapping] = useMutation<DeleteAshbySequenceMappingByIdMutation>(
        DELETE_ASHBY_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    // greenhouse
    const { data: greenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: greenhouseSequenceMappingData } = useQuery<GreenhouseSequenceMappingBySequenceIdQuery>(
        GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createGreenhouseSequenceMapping] = useMutation<CreateGreenhouseSequenceMappingMutation>(
        CREATE_GREENHOUSE_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [updateGreenhouseSequenceMapping] = useMutation<UpdateGreenhouseSequenceMappingByIdMutation>(
        UPDATE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [deleteGreenhouseSequenceMapping] = useMutation<DeleteGreenhouseSequenceMappingByIdMutation>(
        DELETE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    // lever
    const { data: leverAccount } = useQuery<LeverAccountByTeamIdQuery>(GET_LEVER_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: leverSequenceMappingData } = useQuery<LeverSequenceMappingBySequenceIdQuery>(
        GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createLeverSequenceMapping] = useMutation<CreateLeverSequenceMappingMutation>(
        CREATE_LEVER_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [updateLeverSequenceMapping] = useMutation<UpdateLeverSequenceMappingByIdMutation>(
        UPDATE_LEVER_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [deleteLeverSequenceMapping] = useMutation<DeleteLeverSequenceMappingByIdMutation>(
        DELETE_LEVER_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [
                { query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const validationSchemaShape: SequenceFormATSValidationBuilderSchemaShape = {
        ashbyUserId: Yup.string().notRequired(),
        ashbyRepliedStageId: Yup.string().notRequired(),
        ashbyInterestedReplyStageId: Yup.string().notRequired(),
        ashbyMeetingBookedStageId: Yup.string().notRequired(),
        greenhouseUserId: Yup.string().notRequired(),
        greenhouseInterestedReplyStageId: Yup.string().notRequired(),
        greenhouseMeetingBookedStageId: Yup.string().notRequired(),
        leverUserId: Yup.string().notRequired(),
        leverRepliedStageId: Yup.string().notRequired(),
        leverInterestedReplyStageId: Yup.string().notRequired(),
        leverMeetingBookedStageId: Yup.string().notRequired(),
    };
    const validationSchema = Yup.object().shape({ ...validationSchemaShape });

    const initialValues: Omit<CreateEditSequenceFormValues, 'title' | 'color' | 'stages'> = {
        emailPreference: sequence.emailPreference,
        projects:
            sequence?.projects.map((p) => ({
                label: p.project.title,
                value: p.project.id,
            })) || [],
        // ashby
        ashbySyncProspectToggleActive: ashbySequenceMappingData?.data[0]?.syncProspectToggleActive ?? true,
        ashbyJobId: ashbySequenceMappingData?.data[0]?.jobId ?? '',
        ashbyUserId: ashbySequenceMappingData?.data[0]?.assigneeId ?? '',
        ashbyCreateEventType:
            ashbySequenceMappingData?.data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        ashbyDefaultAddStageId: ashbySequenceMappingData?.data[0]?.defaultAddStageId ?? '',
        ashbyRepliedMoveStageActive: ashbySequenceMappingData?.data[0]?.repliedMoveStageActive ?? false,
        ashbyRepliedStageId: ashbySequenceMappingData?.data[0]?.repliedStageId ?? '',
        ashbyInterestedReplyMoveStageActive: ashbySequenceMappingData?.data[0]?.interestedReplyMoveStageActive ?? false,
        ashbyInterestedReplyStageId: ashbySequenceMappingData?.data[0]?.interestedReplyStageId ?? '',
        ashbyMeetingBookedMoveStageActive: ashbySequenceMappingData?.data[0]?.meetingBookedMoveStageActive ?? false,
        ashbyMeetingBookedStageId: ashbySequenceMappingData?.data[0]?.meetingBookedStageId ?? '',
        // greenhouse
        greenhouseSyncProspectToggleActive: greenhouseSequenceMappingData?.data[0]?.syncProspectToggleActive ?? true,
        greenhouseJobId: greenhouseSequenceMappingData?.data[0]?.jobId ?? '',
        greenhouseUserId: greenhouseSequenceMappingData?.data[0]?.assigneeId ?? '',
        greenhouseCreateEventType:
            greenhouseSequenceMappingData?.data[0]?.createEventType ??
            HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        greenhouseInterestedReplyMoveStageActive:
            greenhouseSequenceMappingData?.data[0]?.interestedReplyMoveStageActive ?? false,
        greenhouseInterestedReplyStageId: greenhouseSequenceMappingData?.data[0]?.interestedReplyStageId ?? '',
        greenhouseMeetingBookedMoveStageActive:
            greenhouseSequenceMappingData?.data[0]?.meetingBookedMoveStageActive ?? false,
        greenhouseMeetingBookedStageId: greenhouseSequenceMappingData?.data[0]?.meetingBookedStageId ?? '',
        greenhouseAddAsApplicationType:
            greenhouseSequenceMappingData?.data[0]?.createAsApplicationTypeOverride ??
            greenhouseAccount?.data[0]?.defaultCreateAsApplicationType ??
            GreenhouseApplicationTypeEnum.Candidate,
        greenhouseInterestedReplyConvertActive:
            greenhouseSequenceMappingData?.data[0]?.interestedReplyConvertActive ?? false,
        greenhouseMeetingBookedConvertActive:
            greenhouseSequenceMappingData?.data[0]?.meetingBookedConvertActive ?? false,
        // lever
        leverSyncProspectToggleActive: leverSequenceMappingData?.data[0]?.syncProspectToggleActive ?? true,
        leverJobId: leverSequenceMappingData?.data[0]?.postingId ?? '',
        leverUserId: leverSequenceMappingData?.data[0]?.assigneeId ?? '',
        leverCreateEventType:
            leverSequenceMappingData?.data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        leverDefaultAddStageId: leverSequenceMappingData?.data[0]?.defaultAddStageId ?? '',
        leverRepliedMoveStageActive: leverSequenceMappingData?.data[0]?.repliedMoveStageActive ?? false,
        leverRepliedStageId: leverSequenceMappingData?.data[0]?.repliedStageId ?? '',
        leverInterestedReplyMoveStageActive: leverSequenceMappingData?.data[0]?.interestedReplyMoveStageActive ?? false,
        leverInterestedReplyStageId: leverSequenceMappingData?.data[0]?.interestedReplyStageId ?? '',
        leverMeetingBookedMoveStageActive: leverSequenceMappingData?.data[0]?.meetingBookedMoveStageActive ?? false,
        leverMeetingBookedStageId: leverSequenceMappingData?.data[0]?.meetingBookedStageId ?? '',
    };

    const formMethods = useDynamicSchemaForm<CreateEditSequenceFormValues>({
        mode: 'onTouched',
        defaultValues: initialValues,
        schema: validationSchema,
    });

    const emailPreference = formMethods.getValues('emailPreference');
    const projects = formMethods.getValues('projects');
    const { data: projectData } = useQuery<UserProjectsQuery, UserProjectsQueryVariables>(GET_USER_PROJECTS, {
        variables: {
            orderBy: userProjectsDefaultVariables.orderBy,
            where: {
                _and: [
                    { project: { status: { _eq: ProjectStatusEnum.Active } } },
                    { collaboratorType: { _eq: UserProjectCollaboratorType.Owner } },
                ],
            },
            withDetails: false,
        },
    });

    useEffect(() => {
        const builder = new SequenceFormATSValidationBuilder({ formMethods, translate });
        formMethods.setSchema(Yup.object().shape({ ...validationSchemaShape, ...builder.getSchemaShape() }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyRepliedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyMeetingBookedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseMeetingBookedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverRepliedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverMeetingBookedMoveStageActive'),
    ]);

    const userProjects =
        projectData?.user_projects.map(({ project }) => ({ label: project!.title, value: project!.id })) ?? [];

    useEffect(() => {
        if (emailPreference !== sequence?.emailPreference) {
            formMethods.setValue('emailPreference', sequence?.emailPreference);
        }
        if (!_.isEqual(projects, sequence?.projects)) {
            formMethods.setValue(
                'projects',
                sequence?.projects.map((p) => ({
                    label: p.project.title,
                    value: p.project.id,
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequence]);

    // subscribe to form state changes
    const { formState } = formMethods;

    useEffect(() => {
        if (formState.isDirty) {
            setIsEditSnackBarOpen(true);
        } else {
            setIsEditSnackBarOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        const data: AshbySequenceMappingBySequenceIdData = ashbySequenceMappingData?.data ?? [];
        if (data.length > 0) {
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    ashbySyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    ashbyJobId: data[0]?.jobId ?? '',
                    ashbyUserId: data[0]?.assigneeId ?? '',
                    ashbyCreateEventType: data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    ashbyDefaultAddStageId: data[0]?.defaultAddStageId ?? '',
                    ashbyRepliedMoveStageActive: data[0]?.repliedMoveStageActive ?? false,
                    ashbyRepliedStageId: data[0]?.repliedStageId ?? '',
                    ashbyInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    ashbyInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    ashbyMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    ashbyMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ashbySequenceMappingData]);

    useEffect(() => {
        const data: GreenhouseSequenceMappingBySequenceIdData = greenhouseSequenceMappingData?.data ?? [];
        if (data.length > 0) {
            const greenhouseAddAsApplicationType =
                data[0]?.createAsApplicationTypeOverride ??
                greenhouseAccount?.data[0]?.defaultCreateAsApplicationType ??
                GreenhouseApplicationTypeEnum.Candidate;
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    greenhouseSyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    greenhouseJobId: data[0]?.jobId ?? '',
                    greenhouseUserId: data[0]?.assigneeId ?? '',
                    greenhouseCreateEventType:
                        data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    greenhouseInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    greenhouseInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    greenhouseMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    greenhouseMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                    greenhouseAddAsApplicationType,
                    greenhouseInterestedReplyConvertActive: data[0]?.interestedReplyConvertActive ?? false,
                    greenhouseMeetingBookedConvertActive: data[0]?.meetingBookedConvertActive ?? false,
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [greenhouseSequenceMappingData]);

    useEffect(() => {
        const data: LeverSequenceMappingBySequenceIdData = leverSequenceMappingData?.data ?? [];
        if (data.length > 0) {
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    leverSyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    leverJobId: data[0]?.postingId ?? '',
                    leverUserId: data[0]?.assigneeId ?? '',
                    leverCreateEventType: data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    leverDefaultAddStageId: data[0]?.defaultAddStageId ?? '',
                    leverRepliedMoveStageActive: data[0]?.repliedMoveStageActive ?? false,
                    leverRepliedStageId: data[0]?.repliedStageId ?? '',
                    leverInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    leverInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    leverMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    leverMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leverSequenceMappingData]);

    const handleCreateOrUpdateAshbySequenceMapping = async () => {
        const values = formMethods.getValues();

        // ashby not connected
        if (!ashbyAccount?.data.length) return;

        // handle case where no job selected (delete mapping if one exists)
        if (!values.ashbyJobId) {
            if (ashbySequenceMappingData?.data && ashbySequenceMappingData.data.length > 0) {
                await deleteAshbySequenceMapping({
                    variables: {
                        id: ashbySequenceMappingData.data[0].id,
                    },
                });
            }
            return;
        }

        const ashbyPostData: Partial<AshbySequenceMappingsInsertInput> = {
            syncProspectToggleActive: values.ashbySyncProspectToggleActive,
            jobId: values.ashbyJobId,
            assigneeId: values.ashbyUserId || null,
            createEventType: values.ashbyCreateEventType,
            defaultAddStageId: values.ashbyDefaultAddStageId || null,
            repliedMoveStageActive: values.ashbyRepliedMoveStageActive || false,
            repliedStageId: values.ashbyRepliedStageId || null,
            interestedReplyMoveStageActive: values.ashbyInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.ashbyInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.ashbyMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.ashbyMeetingBookedStageId || null,
        };

        if (ashbySequenceMappingData?.data && ashbySequenceMappingData.data.length > 0) {
            // update
            await updateAshbySequenceMapping({
                variables: {
                    id: ashbySequenceMappingData.data[0].id,
                    set: ashbyPostData,
                },
            });
        } else {
            // create
            await createAshbySequenceMapping({
                variables: {
                    ashby_sequence_mappings: {
                        sequenceId: sequence.id,
                        ...ashbyPostData,
                    },
                },
            });
        }
    };

    const handleCreateOrUpdateGreenhouseSequenceMapping = async () => {
        const values = formMethods.getValues();

        // greenhouse not connected
        if (!greenhouseAccount?.data.length) return;

        // handle case where no job selected (delete mapping if one exists)
        if (!values.greenhouseJobId) {
            if (greenhouseSequenceMappingData?.data && greenhouseSequenceMappingData.data.length > 0) {
                await deleteGreenhouseSequenceMapping({
                    variables: {
                        id: greenhouseSequenceMappingData.data[0].id,
                    },
                });
            }
            return;
        }

        const greenhousePostData: Partial<GreenhouseSequenceMappingsInsertInput> = {
            syncProspectToggleActive: values.greenhouseSyncProspectToggleActive,
            jobId: values.greenhouseJobId,
            assigneeId: values.greenhouseUserId || null,
            createEventType: values.greenhouseCreateEventType,
            interestedReplyMoveStageActive: values.greenhouseInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.greenhouseInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.greenhouseMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.greenhouseMeetingBookedStageId || null,
            createAsApplicationTypeOverride: values.greenhouseAddAsApplicationType,
            interestedReplyConvertActive: values.greenhouseInterestedReplyConvertActive,
            meetingBookedConvertActive: values.greenhouseMeetingBookedConvertActive,
        };

        if (greenhouseSequenceMappingData?.data && greenhouseSequenceMappingData.data.length > 0) {
            // update
            await updateGreenhouseSequenceMapping({
                variables: {
                    id: greenhouseSequenceMappingData.data[0].id,
                    set: greenhousePostData,
                },
            });
        } else {
            // create
            await createGreenhouseSequenceMapping({
                variables: {
                    greenhouse_sequence_mappings: {
                        sequenceId: sequence.id,
                        ...greenhousePostData,
                    },
                },
            });
        }
    };

    const handleCreateOrUpdateLeverSequenceMapping = async () => {
        const values = formMethods.getValues();

        // lever not connected
        if (!leverAccount?.data.length) return;

        // handle case where no job selected (delete mapping if one exists)
        if (!values.leverJobId) {
            if (leverSequenceMappingData?.data && leverSequenceMappingData.data.length > 0) {
                await deleteLeverSequenceMapping({
                    variables: {
                        id: leverSequenceMappingData.data[0].id,
                    },
                });
            }
            return;
        }

        const leverPostData: Partial<LeverSequenceMappingsInsertInput> = {
            syncProspectToggleActive: values.leverSyncProspectToggleActive,
            postingId: values.leverJobId,
            assigneeId: values.leverUserId || null,
            createEventType: values.leverCreateEventType,
            defaultAddStageId: values.leverDefaultAddStageId || null,
            repliedMoveStageActive: values.leverRepliedMoveStageActive || false,
            repliedStageId: values.leverRepliedStageId || null,
            interestedReplyMoveStageActive: values.leverInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.leverInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.leverMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.leverMeetingBookedStageId || null,
        };

        if (leverSequenceMappingData?.data && leverSequenceMappingData.data.length > 0) {
            // update
            await updateLeverSequenceMapping({
                variables: {
                    id: leverSequenceMappingData.data[0].id,
                    set: leverPostData,
                },
            });
        } else {
            // create
            await createLeverSequenceMapping({
                variables: {
                    lever_sequence_mappings: {
                        sequenceId: sequence.id,
                        ...leverPostData,
                    },
                },
            });
        }
    };

    const handleSaveChangesConfirm = async () => {
        const values = formMethods.getValues();
        const variables = {
            id: sequence.id,
            set: {
                emailPreference: values.emailPreference,
            },
        };
        await deleteProjectSequenceMappings({
            variables: {
                where: {
                    sequenceId: { _eq: sequence.id },
                },
            },
        });
        await insertProjectSequenceMappings({
            variables: {
                objects:
                    values.projects?.map((p) => ({
                        sequenceId: sequence.id,
                        projectId: p.value,
                    })) || [],
            },
        });
        await handleCreateOrUpdateAshbySequenceMapping();
        await handleCreateOrUpdateGreenhouseSequenceMapping();
        await handleCreateOrUpdateLeverSequenceMapping();

        const res = await updateSequence({
            variables,
        });
        if (res.data?.update_sequences_by_pk) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('details.edit-success-message', { title: sequence.title }),
            });
            formMethods.reset(values);
            setIsEditSnackBarOpen(false);
        }
    };

    const handleDiscardChangesClick = () => {
        formMethods.reset();
        setIsEditSnackBarOpen(false);
    };

    const handleSaveChangesClick = () => {
        formMethods.handleSubmit(handleSaveChangesConfirm)();
    };

    return (
        <>
            <Form onSubmit={formMethods.handleSubmit(handleSaveChangesConfirm)}>
                <CreateEditSettingsTemplate
                    formMethods={formMethods}
                    projects={userProjects}
                    greenhouseConnected={!!greenhouseAccount?.data.length}
                    leverConnected={!!leverAccount?.data.length}
                    ashbyConnected={!!ashbyAccount?.data.length}
                    readOnly={sequence.status === SequenceStatusEnum.Archived || !canEditSequence(sequence)}
                    isEdit
                />
            </Form>
            <Snackbar
                open={isEditSnackBarOpen}
                autoHideDuration={undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SequenceEditBar onDiscardChanges={handleDiscardChangesClick} onSaveChanges={handleSaveChangesClick} />
            </Snackbar>
        </>
    );
};

export { EditSettingsForm };
