import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { CreateTeamProfileMutation, TeamProfileByTeamIdQuery, UpdateTeamProfileMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { TextField } from 'shared/components/presentational';
import { CREATE_TEAM_PROFILE, GET_TEAM_PROFILE_BY_TEAM_ID, UPDATE_TEAM_PROFILE } from 'shared/graphql/team-profiles';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { FC } from 'shared/types';

interface LinkedInCompanyUrlProps {}

export const LinkedInCompanyUrl: FC<LinkedInCompanyUrlProps> = () => {
    const translate = useTranslations('settings.shared-settings.company-info');
    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');

    const { data: teamProfileData } = useQuery<TeamProfileByTeamIdQuery>(GET_TEAM_PROFILE_BY_TEAM_ID, {
        variables: { teamId: session?.user.teamId },
    });
    const [createTeamProfile] = useMutation<CreateTeamProfileMutation>(CREATE_TEAM_PROFILE, {
        refetchQueries: [{ query: GET_TEAM_PROFILE_BY_TEAM_ID, variables: { teamId: session?.user.teamId } }],
    });
    const [updateTeamProfile] = useMutation<UpdateTeamProfileMutation>(UPDATE_TEAM_PROFILE, {
        refetchQueries: [{ query: GET_TEAM_PROFILE_BY_TEAM_ID, variables: { teamId: session?.user.teamId } }],
    });

    const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(event?.target.value);
    };

    useEffect(() => {
        if (!teamProfileData?.data) return;
        const [teamProfile] = teamProfileData.data;
        setUrl(teamProfile?.externalUrl ? teamProfile.externalUrl : '');
    }, [teamProfileData]);

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        if (teamProfileData?.data && teamProfileData.data.length > 0) {
            await updateTeamProfile({
                variables: {
                    teamId: session!.user.teamId,
                    set: { externalUrl: url },
                },
            });
        } else {
            await createTeamProfile({
                variables: {
                    team_profile: {
                        teamId: session!.user.teamId,
                        externalUrl: url,
                    },
                },
            });
        }
        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const editableContent = (
        <TextField
            type="url"
            value={url}
            onChange={handleChangeUrl}
            label={translate('url-title')}
            parentBackgroundColor="green"
            css={css`
                width: 100%;
            `}
        />
    );

    const nonEditableContent = url;

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {isEditing ? editableContent : nonEditableContent}
        </EditableSettingsSection>
    );
};
