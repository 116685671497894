import { FieldValues, UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { atsTypeGreenhouse } from 'integrations/constants';
import { AtsType } from 'integrations/types';
import { Translate } from 'shared/utils';

export type AtsTypeExcludeGreenhouse = Exclude<AtsType, typeof atsTypeGreenhouse>;

interface BaseSchemaShape {
    ashbyUserId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyRepliedStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyInterestedReplyStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyMeetingBookedStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseUserId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseInterestedReplyStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseMeetingBookedStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverUserId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverRepliedStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverInterestedReplyStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverMeetingBookedStageId?: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
}

type SchemaField = keyof BaseSchemaShape;

export abstract class FormATSValidationBuilder<T extends FieldValues, S extends BaseSchemaShape> {
    protected readonly formMethods!: UseFormReturn<T>;

    protected readonly translate!: Translate;

    protected abstract schemaShape: S;

    public getSchemaShape = () => this.schemaShape;

    protected setSchemaShape = (newSchemaShape: S) => {
        this.schemaShape = newSchemaShape;
    };

    constructor({ formMethods, translate }: { formMethods: UseFormReturn<T>; translate: Translate }) {
        this.formMethods = formMethods;
        this.translate = translate;
    }

    protected abstract getJobIdFormValue: (_: AtsType) => string;

    protected abstract getUserIdField: (_: AtsType) => SchemaField;

    protected abstract getRepliedMoveStageActiveFormValue: (_: AtsTypeExcludeGreenhouse) => boolean;

    protected abstract getRepliedStageIdField: (_: AtsTypeExcludeGreenhouse) => SchemaField;

    protected abstract getInterestedReplyMoveStageActiveFormValue: (_: AtsType) => boolean;

    protected abstract getInterestedReplyStageIdField: (_: AtsType) => SchemaField;

    protected abstract getMeetingBookedMoveStageActiveFormValue: (_: AtsType) => boolean;

    protected abstract getMeetingBookedStageIdField: (_: AtsType) => SchemaField;

    protected abstract buildValidationSchema: () => void;

    protected addUserRequirement = (atsType: AtsType) => {
        if (this.getJobIdFormValue(atsType)) {
            return {
                [this.getUserIdField(atsType)]: Yup.string().required(this.translate('error-ats-user')),
            };
        }
        return {};
    };

    protected addReplyStageRequirement = (atsType: AtsTypeExcludeGreenhouse) => {
        if (this.getRepliedMoveStageActiveFormValue(atsType)) {
            return {
                [this.getRepliedStageIdField(atsType)]: Yup.string().required(
                    this.translate('error-replied-stage-active')
                ),
            };
        }
        return {};
    };

    protected addInterestedReplyStageRequirement = (atsType: AtsType) => {
        if (this.getInterestedReplyMoveStageActiveFormValue(atsType)) {
            return {
                [this.getInterestedReplyStageIdField(atsType)]: Yup.string().required(
                    this.translate('error-interested-replied-stage-active')
                ),
            };
        }
        return {};
    };

    protected addMeetingBookedStageRequirement = (atsType: AtsType) => {
        if (this.getMeetingBookedMoveStageActiveFormValue(atsType)) {
            return {
                [this.getMeetingBookedStageIdField(atsType)]: Yup.string().required(
                    this.translate('error-meeting-booked-stage-active')
                ),
            };
        }
        return {};
    };
}
