import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { standardizeUrl } from 'profile-parser';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
    AddOrUpdateProspectMutation,
    CreateProspectCustomMessageMutation,
    CreateProspectCustomMessageMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { TextField } from 'shared/components/form';
import { InvalidProspect, ValidProspect } from 'shared/components/modals';
import { Button, Hyperlink } from 'shared/components/presentational';
import { ArrowNarrowUpRight, ArrowRight } from 'shared/components/svgs';
import { CREATE_PROSPECT_CUSTOM_MESSAGE } from 'shared/graphql/prospect-custom-message';
import { ADD_OR_UPDATE_PROSPECT, GET_PROSPECTS } from 'shared/graphql/prospects';
import { useSnackbarAlert } from 'shared/hooks';
import { ProspectVariables } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface MissingVariablesFormProps {
    onMissingVariablesComplete: () => void;
    validProspects: ValidProspect[];
    invalidProspects: InvalidProspect[];
    addValidProspect: (prospect: ValidProspect) => void;
    markProspectAsResolved: (index: number) => void;
    sequenceId: string;
}

const MissingVariablesForm: FC<MissingVariablesFormProps> = ({
    onMissingVariablesComplete,
    invalidProspects,
    validProspects,
    addValidProspect,
    markProspectAsResolved,
    sequenceId,
}) => {
    const theme = useTheme();
    const translate = useTranslations(
        'project.prospect-list-table.edit-bar.add-to-sequence-modal.missing-variables-form'
    );
    const { showSnackbarAlert } = useSnackbarAlert();
    const [updateProspect] = useMutation<AddOrUpdateProspectMutation>(ADD_OR_UPDATE_PROSPECT);
    const [createProspectCustomMessage] = useMutation<
        CreateProspectCustomMessageMutation,
        CreateProspectCustomMessageMutationVariables
    >(CREATE_PROSPECT_CUSTOM_MESSAGE, { refetchQueries: [getOperationName(GET_PROSPECTS) as string] });
    const [invalidProspectsIndex, setInvalidProspectsIndex] = useState<number>(0);

    const validationSchema = Yup.object().shape({
        missingVariables: Yup.array().of(Yup.string().required(translate('error-required'))),
    });

    const formMethods = useForm<{ missingVariables: string[] }>({
        defaultValues: { missingVariables: [] },
        resolver: yupResolver(validationSchema),
    });

    const handleSkipAll = () => {
        onMissingVariablesComplete();
    };

    const handleSkipProspect = () => {
        if (invalidProspectsIndex < invalidProspects.length - 1) {
            setInvalidProspectsIndex(invalidProspectsIndex + 1);
        } else {
            onMissingVariablesComplete();
        }
        formMethods.reset();
    };

    const handleSaveMissingVariables = async () => {
        const invalidProspect = invalidProspects[invalidProspectsIndex];
        const prospectUpdates = invalidProspect.missingFields.reduce<Partial<Record<ProspectVariables, string>>>(
            (updates, missingField, index) => {
                // eslint-disable-next-line no-param-reassign
                updates[missingField] = formMethods.getValues('missingVariables')[index];
                return updates;
            },
            {} as Partial<Record<ProspectVariables, string>>
        );

        const { customMessage, ...prospectProfileUpdates } = prospectUpdates;

        const res = await updateProspect({
            variables: {
                data: [
                    {
                        profile: { ...invalidProspect.profile, ...prospectProfileUpdates },
                        url: standardizeUrl(invalidProspect.url),
                    },
                ],
            },
        });

        // update missing custom message
        if (customMessage) {
            createProspectCustomMessage({
                variables: { prospectCustomMessage: { prospectId: invalidProspect.id, sequenceId, customMessage } },
            });
        }

        if (res.data) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('prospect-profile-update-success', {
                    prospectFullName: invalidProspect.profile.fullNameCustomized ?? invalidProspect.profile.fullName,
                }),
            });
            markProspectAsResolved(invalidProspectsIndex);
            addValidProspect({ id: invalidProspect.id, profile: invalidProspect.profile, url: invalidProspect.url });
            if (invalidProspectsIndex < invalidProspects.length - 1) {
                setInvalidProspectsIndex(invalidProspectsIndex + 1);
            } else {
                onMissingVariablesComplete();
            }
            formMethods.reset();
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('prospect-profile-update-error'),
            });
        }
    };

    const getMissingVariableTextFieldLabel = (missingField: ProspectVariables) => {
        switch (missingField) {
            case 'firstNameCustomized':
                return 'first name';
            case 'fullNameCustomized':
                return 'full name';
            case 'latestCompanyCustomized':
                return 'latest company';
            case 'latestSchoolCustomized':
                return 'latest school';
            case 'customMessage':
                return 'custom message';
            case 'titleCustomized':
                return 'title';
            default:
                return 'unknown variable';
        }
    };
    const missingVariablesInputs = invalidProspects[invalidProspectsIndex].missingFields.map((missingField, index) => (
        <TextField
            css={css`
                width: 100%;
            `}
            control={formMethods.control}
            name={`missingVariables.${index}`}
            key={`${invalidProspectsIndex}-${missingField}`}
            label={getMissingVariableTextFieldLabel(missingField)}
        />
    ));

    return (
        <Box>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                    background-color: ${theme.palette.grey[300]};
                    margin-bottom: ${spacing.space12px};
                `}
            >
                <Box
                    css={css`
                        margin-bottom: 2px;
                        font-size: ${fontSizes.f12};
                        font-weight: ${fontWeights.medium};
                        text-transform: uppercase;
                    `}
                >
                    {translate('invalid-prospects-count', {
                        current: invalidProspectsIndex + 1,
                        total: invalidProspects.length,
                    })}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: ${spacing.space14px};
                    `}
                >
                    <Box
                        css={css`
                            font-weight: ${fontWeights.bold};
                        `}
                        component="span"
                    >
                        {invalidProspects[invalidProspectsIndex].profile.fullNameCustomized ??
                            invalidProspects[invalidProspectsIndex].profile.fullName}
                    </Box>
                    <Hyperlink
                        css={css`
                            display: flex;
                            gap: ${spacing.space8px};
                            align-items: center;
                            &:hover {
                                .link-icon {
                                    path {
                                        stroke: ${theme.palette.primary.light};
                                    }
                                }
                            }
                        `}
                        href="#!"
                    >
                        {translate('linkedin')}
                        <ArrowNarrowUpRight className="link-icon" stroke={theme.palette.primary.main} />
                    </Hyperlink>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        flex-flow: column nowrap;
                        gap: ${spacing.space12px};
                        margin-bottom: ${spacing.space10px};
                    `}
                >
                    {missingVariablesInputs}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space10px};
                        padding-top: ${spacing.space12px};
                    `}
                >
                    <Button variant="contained" onClick={formMethods.handleSubmit(handleSaveMissingVariables)}>
                        {translate('button-save')}
                    </Button>
                    <Button variant="outlined" onClick={handleSkipProspect}>
                        {translate('button-skip')}
                    </Button>
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                        color: ${theme.palette.grey[200]};
                    `}
                    component="span"
                >
                    {translate('valid-prospects-count', { count: validProspects.length })}
                </Box>
                <Hyperlink
                    css={css`
                        display: flex;
                        gap: ${spacing.space8px};
                        align-items: center;
                        &:hover {
                            .link-icon {
                                path {
                                    stroke: ${theme.palette.primary.light};
                                }
                            }
                        }
                    `}
                    href="#!"
                    onClick={handleSkipAll}
                >
                    {translate('button-skip-all')}
                    <ArrowRight className="link-icon" stroke={theme.palette.primary.main} />
                </Hyperlink>
            </Box>
        </Box>
    );
};

export { MissingVariablesForm };
