import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import { Button, Radio, RadioGroup } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface MissingVariablesAlertProps {
    onSkipMissingVariables: () => void;
    onSetMissingVariables: () => void;
    onCancel: () => void;
    shouldDisableSkip: boolean;
}

type MissingVariableDecision = 'set-missing-variables' | 'skip-missing-variables';

const MissingVariablesAlert: FC<MissingVariablesAlertProps> = ({
    onSkipMissingVariables,
    onSetMissingVariables,
    onCancel,
    shouldDisableSkip,
}) => {
    const theme = useTheme();
    const translate = useTranslations(
        'project.prospect-list-table.edit-bar.add-to-sequence-modal.missing-variables-alert'
    );
    const [decision, setDecision] = useState<MissingVariableDecision>('set-missing-variables');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDecision(event.target.value as MissingVariableDecision);
    };

    const handleSubmitDecision = () => {
        if (decision === 'set-missing-variables') {
            onSetMissingVariables();
        } else {
            onSkipMissingVariables();
        }
    };

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: ${spacing.space28px};
            `}
        >
            <RadioGroup onChange={handleChange} value={decision}>
                <FormControlLabel
                    value="set-missing-variables"
                    control={<Radio />}
                    label={translate('input-options.fill-in-missing-variables-now')}
                />
                <FormControlLabel
                    disabled={shouldDisableSkip}
                    value="skip-missing-variables"
                    control={<Radio />}
                    label={
                        <div>
                            <div>{translate('input-options.only-send-to-valid-prospects')}</div>
                            <div
                                css={css`
                                    font-size: ${spacing.space12px};
                                    color: ${theme.palette.grey[200]};
                                `}
                            >
                                {translate('input-options.and-skip-all-prospects-with-missing-variables')}
                            </div>
                        </div>
                    }
                />
            </RadioGroup>
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    gap: ${spacing.space10px};
                `}
            >
                <Button onClick={onCancel} variant="outlined" color="inherit">
                    {translate('button-cancel')}
                </Button>
                <Button onClick={handleSubmitDecision} variant="contained">
                    {translate('button-next')}
                </Button>
            </Box>
        </Box>
    );
};

export { MissingVariablesAlert };
