import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { NoTeamSupportIcon } from 'shared/components/svgs/no-team-support';
import { CONTACT_US_URL, windowFeatures, windowTarget } from 'shared/constants';
import { useSettings } from 'shared/hooks';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoTeamSupportProps {}
const NoTeamSupport: FC<NoTeamSupportProps> = () => {
    const translate = useTranslations('settings.team-settings.no-team-support');
    const theme = useTheme();

    const { closeAll } = useSettings();

    const handleContactUs = () => {
        window.open(CONTACT_US_URL, windowTarget, windowFeatures);
    };

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100vh;
                padding: 120px 60px;
            `}
        >
            <NoTeamSupportIcon css={css``} />
            <Box
                component="h2"
                css={css`
                    font-family: ${fontFamilies.poppins};
                    font-style: normal;
                    font-size: ${fontSizes.f32};
                    line-height: 34px;
                    text-align: center;
                    color: ${theme.palette.common.black};
                    margin: ${spacing.space16px};
                `}
            >
                {translate('title')}
            </Box>
            <Box
                component="p"
                css={css`
                    line-height: 19px;
                    text-align: center;
                    color: ${theme.palette.common.black};
                    margin-bottom: ${spacing.space32px};
                `}
            >
                {translate('description')}
            </Box>
            <Button
                css={css`
                    font-weight: ${fontWeights.semiBold};
                    font-size: ${fontSizes.f16};
                    line-height: 19px;
                    color: ${theme.palette.primary.main};
                    margin-bottom: ${spacing.space16px};
                `}
                onClick={closeAll}
            >
                {translate('link')}
            </Button>
            <Button
                onClick={handleContactUs}
                variant="contained"
                css={css`
                    padding: 10px 16px;
                    height: 44px;
                    border-radius: 4px;
                    font-family: 'Inter';
                    border: 1px solid #239558;
                    background: #239558;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #ffffff;
                `}
            >
                {translate('button')}
            </Button>
        </Box>
    );
};

export { NoTeamSupport };
