import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedCheckCircleBroken: FC<SvgProps> = ({ stroke, theme, width, height, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '83'}
        height={height ?? '83'}
        viewBox="0 0 83 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M76.0847 38.3382V41.5198C76.0804 48.9774 73.6655 56.2339 69.2002 62.207C64.735 68.18 58.4585 72.5496 51.3069 74.6641C44.1553 76.7786 36.5118 76.5247 29.5163 73.9403C22.5209 71.3558 16.5482 66.5792 12.4892 60.323C8.43018 54.0668 6.50225 46.666 6.99293 39.2246C7.48362 31.7831 10.3666 24.6996 15.212 19.0305C20.0574 13.3614 26.6055 9.41051 33.8798 7.76699C41.1541 6.12346 48.7647 6.8754 55.5767 9.91065M76.0846 13.8334L41.5013 48.4513L31.1263 38.0763"
            stroke={stroke ?? theme?.palette?.secondary?.contrastText}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const CheckCircleBroken = withTheme(ThemedCheckCircleBroken);

export { CheckCircleBroken };
