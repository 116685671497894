import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateStagesFormButtonsProps {
    onAddAdditionalStage: () => void;
    onSaveAsDraft: () => void;
}

const CreateStagesFormButtons: FC<CreateStagesFormButtonsProps> = (props) => {
    const { onAddAdditionalStage, onSaveAsDraft } = props;
    const translate = useTranslations('sequence');
    const theme = useTheme();

    return (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;

                padding-bottom: ${spacing.space48px};

                @media (max-width: 1200px) {
                    padding-bottom: ${spacing.space16px};
                }
            `}
        >
            <Button
                onClick={onAddAdditionalStage}
                variant="outlined"
                startIcon={<Plus stroke={theme.palette.primary.main} />}
            >
                {translate('add-additional-stage')}
            </Button>

            <Box>
                <Button type="submit" variant="outlined" onClick={onSaveAsDraft}>
                    {translate('save-as-draft')}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    css={css`
                        margin-left: ${spacing.space16px};
                    `}
                >
                    {translate('next')}
                </Button>
            </Box>
        </Box>
    );
};

export { CreateStagesFormButtons };
