import { css, useTheme } from '@mui/material';
import { personalizeMagicSentence } from 'hireflow-shared/utils/email-drafting-utils';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { TextField } from 'shared/components/form';
import { insertTextWithinString } from 'shared/services';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';

interface SubjectProps {
    selectedVariable: string;
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    onClearVariable: () => void;
    onSubjectTouched: (touched: boolean) => void;
    index: number;
    isEditorTouched?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    singleStageEdit?: boolean;
    personalize?: boolean;
    isEdit?: boolean;
}

const Subject: FC<SubjectProps> = (props) => {
    const {
        selectedVariable,
        onClearVariable,
        formMethods,
        index,
        isEditorTouched,
        onSubjectTouched,
        disabled = false,
        fullWidth,
        singleStageEdit = false,
        personalize,
        isEdit,
    } = props;
    const translate = useTranslations('sequence.create.stages');
    const [selectionStart, setSelectionStart] = useState<number | null>(null);
    // const [caretPosition, setCaretPosition] = useState<number | null>(null);
    const name = `stages.${index}.subject` as const;
    const currentStage = formMethods.getValues('stages')[index];
    const replyToLastEmailState = formMethods.getFieldState(`stages.${index}.replyToLastEmail`, formMethods.formState);

    const theme = useTheme();

    // update cursor position when changing field value
    const subjectText = useWatch({ control: formMethods.control, name });
    useEffect(() => {
        if (selectionStart !== null) {
            setSelectionStart(subjectText ? subjectText.length : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectText]);

    useEffect(() => {
        // magic sentence should not be available in the subject
        if (selectedVariable === personalizeMagicSentence) {
            onClearVariable();
            return;
        }

        if (selectedVariable && selectionStart !== null) {
            const selectedTextLength = window.getSelection()?.toString()?.length ?? 0;
            const textWithVariable = insertTextWithinString(
                currentStage.subject,
                selectionStart,
                selectionStart + selectedTextLength,
                selectedVariable
            );
            formMethods.setValue(name, textWithVariable, { shouldDirty: true });
            formMethods.setFocus(name);
            onClearVariable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVariable, selectionStart]);

    // enable/disable subject field when reply to last email is toggled
    const replyToLastEmail = useWatch({
        control: formMethods.control,
        name: `stages.${index}.replyToLastEmail`,
        disabled: index === 0,
    });

    // update value of subject field if reply to last email is checked
    const lastStageSubject = useWatch({
        control: formMethods.control,
        name: `stages.${index - 1}.subject`,
        disabled: index === 0,
    });

    useEffect(() => {
        if (replyToLastEmail && !singleStageEdit) {
            formMethods.setValue(name, lastStageSubject);
        }
    }, [replyToLastEmail, formMethods, name, lastStageSubject, singleStageEdit]);

    useEffect(() => {
        if (replyToLastEmailState.isDirty && !replyToLastEmail) {
            trackEvent('select_new_thread', { page_name: isEdit ? 'sequence_details' : 'sequence_creation' });
        }
    }, [replyToLastEmail, replyToLastEmailState.isDirty, isEdit]);

    // blur subject when editor is touched
    useEffect(() => {
        if (isEditorTouched) {
            setSelectionStart(null);
            // setCaretPosition(null);
        }
    }, [isEditorTouched]);

    return (
        <TextField
            name={name}
            value={formMethods.getValues(name)}
            label={translate('subject')}
            control={formMethods.control}
            onClick={(event: React.SyntheticEvent<any>) => {
                const target = event.target as HTMLInputElement;
                setSelectionStart(target.selectionStart);
                onSubjectTouched(true);
            }}
            onBlur={() => {
                onSubjectTouched(false);
                // since this will override the form's native onBlur handler,
                // trigger the validation manually
                formMethods.trigger(name);
            }}
            onSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
                // setCaretPosition(event.target.selectionStart);
                setSelectionStart(event.target.selectionStart);
            }}
            css={css`
                width: ${index >= 1 && !fullWidth ? '585px' : '100%'};

                .MuiFilledInput-root.Mui-disabled {
                    background-color: ${personalize || disabled ? theme.palette.common.white : theme.palette.grey[300]};
                    border: 1px solid ${personalize || disabled ? colors.greens.narvik : theme.palette.grey[500]}};
                }
            `}
            disabled={disabled || replyToLastEmail}
        />
    );
};

export { Subject };
