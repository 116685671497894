import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface LoaderProps {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const LoaderContext = createContext<LoaderProps>({} as LoaderProps);

const LoaderProvider: FC<LoaderProps> = ({ isLoading, setIsLoading, children }) => {
    const value = useMemo(
        () => ({
            isLoading,
            setIsLoading,
        }),
        [isLoading, setIsLoading]
    );

    return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

const useLoader = () => useContext(LoaderContext);

export { LoaderProvider, LoaderContext, useLoader };
