import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import {
    SourcerMemberStatusEnum,
    UpdateSourcerMembersMutation,
    UpdateSourcerMembersMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { BellMinus, CheckCircle, FolderPlus, SequenceIcon, XClose } from 'shared/components/svgs';
import { AppFlow, SourcerMemberApprovalAction } from 'shared/enums';
import {
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    SourcerMembersData,
    UPDATE_SOURCER_MEMBERS,
} from 'shared/graphql/sourcer-members';
import {
    useConfirmationModal,
    useCreateProjectMembers,
    useProspectCreator,
    useSession,
    useSnackbarAlert,
} from 'shared/hooks';
import { useAiSourcingCredits } from 'shared/hooks/use-ai-sourcing-credits';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import {
    AddMultipleSourcerMembersToProject,
    AddMultipleSourcerMembersToSequence,
    NoAISourcingCreditsModal,
} from 'sourcing/components/modals';

interface SourcerMembersListEditBarProps {
    creditsPerApproval: number;
    members: SourcerMembersData;
    onClose(): void;
    status: string;
}

const SourcerMembersListEditBar: FC<SourcerMembersListEditBarProps> = React.forwardRef(
    ({ creditsPerApproval, members, status, onClose }, ref) => {
        const translate = useTranslations('sourcing.members-list-table.edit-bar');
        const theme = useTheme();
        const { session } = useSession();
        const { showModal, hideModal } = useConfirmationModal();
        const { showSnackbarAlert } = useSnackbarAlert();
        const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();
        const [openAddSourcerMembersToProject, setOpenAddSourcerMembersToProject] = useState<boolean>(false);
        const [openAddSourcerMembersToSequence, setOpenAddSourcerMembersToSequence] = useState<boolean>(false);
        const [showNoAiSourcingNotice, setShowNoAiSourcingNotice] = useState<boolean>(false);

        const { createProspectsFromSourcerMembers, filterProjectDuplicates } = useProspectCreator();

        const { approveAndTrackSourcerMembers } = useApproveSourcerMembers([
            getOperationName(GET_SOURCER_MEMBERS) as string,
            getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
        ]);

        const [updateSourcerMembers] = useMutation<UpdateSourcerMembersMutation, UpdateSourcerMembersMutationVariables>(
            UPDATE_SOURCER_MEMBERS,
            {
                refetchQueries: [
                    getOperationName(GET_SOURCER_MEMBERS) as string,
                    getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
                ],
            }
        );

        const { createAndTrackProjectMembers } = useCreateProjectMembers([]);

        const membersIds = members.map((m) => m.id) ?? [];

        const handleCloseNoAiSourcingNotice = () => {
            setShowNoAiSourcingNotice(false);
        };

        const handleApprove = async () => {
            const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
                getAiSourcingCreditsAvailable(),
                isPayOnApproveEnabled(),
            ]);
            const { sourcer } = members[0];

            let membersToBeAdded = members;

            if (shouldDeductCreditOnApprove) {
                const countOfMemberCanBeApproved = Math.floor(aiSourcingCreditsAvailable / creditsPerApproval);
                membersToBeAdded = [...members].splice(0, countOfMemberCanBeApproved);
            }

            if (membersToBeAdded.length > 0) {
                try {
                    const membersToBeAddedIds = membersToBeAdded.map((m) => m.id) ?? [];
                    const sourcerMembersWithProspect = await createProspectsFromSourcerMembers(membersToBeAdded);
                    const prospectsToAddToProject = await filterProjectDuplicates(
                        sourcerMembersWithProspect!.map((sourcerMember) => sourcerMember.prospect!),
                        sourcer.project.id
                    );

                    await createAndTrackProjectMembers(
                        prospectsToAddToProject?.map((prospect) => ({
                            projectId: sourcer.project.id,
                            prospectId: prospect.id,
                        })),
                        AppFlow.AiSourcing
                    );

                    await approveAndTrackSourcerMembers({
                        sourcerMemberIds: membersToBeAddedIds,
                        flow: SourcerMemberApprovalAction.ManualApprove,
                        reviewerId: session?.user.id,
                    });

                    showSnackbarAlert({
                        message: translate('approve-sourcing-member-modal.success-alert', {
                            count: membersToBeAdded.length,
                        }),
                    });
                } catch (error) {
                    showSnackbarAlert({
                        severity: 'error',
                        message: translate('approve-sourcing-member-modal.error-alert'),
                    });
                } finally {
                    hideModal();
                    onClose();
                }
            }
        };

        const handleUnSnooze = async () => {
            const res = await updateSourcerMembers({
                variables: {
                    where: {
                        id: { _in: membersIds },
                    },
                    set: {
                        status: SourcerMemberStatusEnum.ToReview,
                        snoozedUntil: null,
                    },
                },
            });

            if (res.data) {
                showSnackbarAlert({
                    message: translate('un-snooze-success-alert', { count: membersIds.length }),
                });
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('un-snooze-error-alert'),
                });
            }
            hideModal();
            onClose();
        };

        const handleClickApprove = async () => {
            const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
                getAiSourcingCreditsAvailable(),
                isPayOnApproveEnabled(),
            ]);

            if (!shouldDeductCreditOnApprove || aiSourcingCreditsAvailable >= creditsPerApproval) {
                showModal({
                    title: translate('approve-sourcing-member-modal.header', {
                        name: 'Name',
                    }),
                    description: translate('approve-sourcing-member-modal.description', { count: membersIds.length }),
                    confirmButton: {
                        text: translate('approve-sourcing-member-modal.confirm-button-label'),
                        onClick: handleApprove,
                    },
                    cancelButton: {
                        text: translate('approve-sourcing-member-modal.cancel-button-label'),
                        onClick: hideModal,
                    },
                });
            } else {
                setShowNoAiSourcingNotice(true);
            }
        };

        const handleEditBarClose = () => {
            onClose();
        };

        const handleAddToProjectOpen = () => {
            setOpenAddSourcerMembersToProject(true);
        };
        const handleAddToProjectClose = () => {
            setOpenAddSourcerMembersToProject(false);
        };

        const handleAddToSequenceOpen = () => {
            setOpenAddSourcerMembersToSequence(true);
        };

        const handleAddToSequenceClose = () => {
            setOpenAddSourcerMembersToSequence(false);
        };

        return (
            <Box
                css={css`
                    display: flex;
                    align-items: center;

                    width: 1035px;
                    background-color: ${theme.palette.grey[200]};
                    padding: 20px 25px;
                    border-radius: 4px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                    color: ${theme.palette.common.white};
                    font-weight: ${fontWeights.bold};
                `}
                ref={ref}
            >
                <Box
                    css={css`
                        margin-right: auto;
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('item-selected', { count: members.length })}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        font-size: ${fontSizes.f16};
                    `}
                >
                    {status === SourcerMemberStatusEnum.Snoozed ? (
                        <Button
                            startIcon={<BellMinus stroke={theme.palette.common.white} />}
                            css={css`
                                color: ${theme.palette.common.white};
                            `}
                            onClick={handleUnSnooze}
                            disableRipple
                        >
                            {translate('un-snooze-label')}
                        </Button>
                    ) : null}
                    {status !== SourcerMemberStatusEnum.Accepted ? (
                        <Button
                            startIcon={<CheckCircle stroke={theme.palette.common.white} />}
                            css={css`
                                color: ${theme.palette.common.white};
                            `}
                            onClick={handleClickApprove}
                            disableRipple
                        >
                            {translate('approve-label')}
                        </Button>
                    ) : null}
                    <Button
                        startIcon={<SequenceIcon stroke={theme.palette.common.white} />}
                        css={css`
                            color: ${theme.palette.common.white};
                        `}
                        onClick={handleAddToSequenceOpen}
                        disableRipple
                    >
                        {translate('add-to-sequence-label')}
                    </Button>
                    <Button
                        startIcon={<FolderPlus />}
                        css={css`
                            color: ${theme.palette.common.white};
                        `}
                        onClick={handleAddToProjectOpen}
                        disableRipple
                    >
                        {translate('add-to-project-label')}
                    </Button>

                    <Button
                        startIcon={<XClose stroke={theme.palette.common.white} />}
                        onClick={handleEditBarClose}
                        disableRipple
                    />
                    <AddMultipleSourcerMembersToProject
                        creditsPerApproval={creditsPerApproval}
                        open={openAddSourcerMembersToProject}
                        onClose={handleAddToProjectClose}
                        sourcerMembers={members}
                        flow={SourcerMemberApprovalAction.ManualApprove}
                    />
                    <AddMultipleSourcerMembersToSequence
                        creditsPerApproval={creditsPerApproval}
                        open={openAddSourcerMembersToSequence}
                        sourcerMembers={members}
                        onClose={handleAddToSequenceClose}
                        flow={SourcerMemberApprovalAction.ManualApprove}
                    />
                    <NoAISourcingCreditsModal open={showNoAiSourcingNotice} onClose={handleCloseNoAiSourcingNotice} />
                </Box>
            </Box>
        );
    }
);

export { SourcerMembersListEditBar };
