import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import {
    RecipientStatusEnum,
    SourcerCollaboratorTypeEnum,
    SourcerMembersQuery,
    SourcerMemberStatusEnum,
} from 'codegen/graphql';
import { ProspectPanel } from 'prospects';
import { PanelContext } from 'prospects/prospect-panel-provider';
import { PanelTabEnum } from 'prospects/utils';
import {
    ChangeRecipientStatusModal,
    ReportWrongEmailModal,
    ResendSequenceNewEmailModal,
    StopSequenceModal,
} from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { Hyperlink, Popover, SubmenuButton } from 'shared/components/presentational';
import { CopyLink } from 'shared/components/svgs';
import { OUT_OF_AI_SOURCING_CREDITS } from 'shared/constants';
import { AppFlow, SourcerMemberApprovalAction } from 'shared/enums';
import { GET_SOURCER_MEMBERS, GET_SOURCER_MEMBERS_COUNT_BY_STATUS } from 'shared/graphql/sourcer-members';
import {
    useCreateProjectMembers,
    useProspectCreator,
    useSession,
    useSnackbarAlert,
    useSourcerAccessControl,
} from 'shared/hooks';
import { useAiSourcingCredits } from 'shared/hooks/use-ai-sourcing-credits';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { RecipientSequenceStopped } from 'shared/services';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { AddMultipleSourcerMembersToProject } from 'sourcing/components/modals';

type SourcerMemberData = SourcerMembersQuery['sourcer_members'][number];

interface SourcerMembersListSubmenuProps {
    creditsPerApproval: number;
    member: SourcerMemberData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
    prospectPanelContext: PanelContext;
    sourcer: {
        id: string;
        userId: string;
        project: { id: string };
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    };
}

const SourcerMembersListSubmenu: FC<SourcerMembersListSubmenuProps> = ({
    member,
    anchor,
    setAnchor,
    prospectPanelContext,
    creditsPerApproval,
    sourcer,
}) => {
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('sourcing.members-list-table.submenu');
    const { showSnackbarAlert } = useSnackbarAlert();
    const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();
    const { canManageSourcerMembers } = useSourcerAccessControl();

    const { createProspectsFromSourcerMembers, filterProjectDuplicates } = useProspectCreator();

    const { createAndTrackProjectMembers } = useCreateProjectMembers([]);

    const { approveAndTrackSourcerMembers } = useApproveSourcerMembers([
        getOperationName(GET_SOURCER_MEMBERS) as string,
        getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
    ]);

    const latestUserRecipient = member.prospect?.recipients
        .filter((recipient) => recipient.addedBy === session?.user.id)
        ?.slice()
        .sort((a, b) => b.createdAt - a.createdAt)[0];

    const [showPanel, setShowPanel] = useState(false);

    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const [showReportWrongEmailModal, setShowReportWrongEmailModal] = useState(false);
    const [showResendNewEmailModal, setShowResendNewEmailModal] = useState(false);
    const [showStopSequenceModal, setShowStopSequenceModal] = useState(false);
    const [showAddToADifferentProjectModal, setShowAddToADifferentProjectModal] = useState(false);

    const handleSubmenuPopoverClose = () => setAnchor(undefined);

    const onPanelClose = () => {
        handleCloseSubmenuPopover();
        setShowPanel(false);
    };

    const handleAddToADifferentProject = () => {
        setShowAddToADifferentProjectModal(true);
    };

    const handleAddToAProjectOrSequence = () => {
        setAnchor(undefined);
        setShowPanel(true);
    };

    const handleShare = () => {
        navigator.clipboard.writeText(getPrimaryProfileUrl(member.urls));
        showSnackbarAlert({
            backgroundColor: theme.palette.grey[200],
            message: translate('link-to-prospect-copied-to-clipboard'),
            startIcon: <CopyLink />,
        });
        setAnchor(undefined);
    };

    const handleApprove = async () => {
        const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
            getAiSourcingCreditsAvailable(),
            isPayOnApproveEnabled(),
        ]);

        try {
            if (!shouldDeductCreditOnApprove || aiSourcingCreditsAvailable >= creditsPerApproval) {
                const sourcerMembersWithProspect = await createProspectsFromSourcerMembers([member]);

                if (
                    sourcerMembersWithProspect &&
                    sourcerMembersWithProspect[0] &&
                    sourcerMembersWithProspect[0].prospect
                ) {
                    const { prospect } = sourcerMembersWithProspect[0];

                    const prospectsToAddToProject = await filterProjectDuplicates([prospect], sourcer.project.id);
                    // if the prospect is already in the project, we don't have to add it
                    if (prospectsToAddToProject.length > 0) {
                        await createAndTrackProjectMembers(
                            [{ projectId: sourcer.project.id, prospectId: prospect.id }],
                            AppFlow.AiSourcing
                        );
                    }

                    await approveAndTrackSourcerMembers({
                        sourcerMemberIds: [member.id],
                        flow: SourcerMemberApprovalAction.KebabApprove,
                        reviewerId: session?.user.id,
                    });

                    showSnackbarAlert({ severity: 'success', message: translate('approve-success-alert') });
                }
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate.rich('no-ai-sourcing-credits-left', { link: Link }),
                });
            }
        } catch (error) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('approve-error-alert'),
            });
        } finally {
            handleSubmenuPopoverClose();
        }
    };

    const handleChangeStatus = () => setShowChangeStatusModal(true);
    const handleResendSequenceToNewEmail = () => setShowResendNewEmailModal(true);
    const handleReportAWrongEmail = () => setShowReportWrongEmailModal(true);
    const handleStopSequenceForThisProject = () => setShowStopSequenceModal(true);

    const handleCloseSubmenuPopover = () => setAnchor(undefined);

    const handleAddToADifferentProjectModalClose = () => {
        setShowAddToADifferentProjectModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseChangeStatusModal = () => {
        setShowChangeStatusModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseResendNewEmailModal = () => {
        setShowResendNewEmailModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseReportWrongEmailModal = () => {
        setShowReportWrongEmailModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseStopSequenceModal = () => {
        setShowStopSequenceModal(false);
        handleCloseSubmenuPopover();
    };

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleSubmenuPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    {member.status !== SourcerMemberStatusEnum.Accepted ? (
                        <SubmenuButton onClick={handleApprove} disabled={!canManageSourcerMembers(sourcer)}>
                            {translate('approve-label')}
                        </SubmenuButton>
                    ) : null}

                    {[SourcerMemberStatusEnum.Rejected, SourcerMemberStatusEnum.Snoozed].includes(member.status) && (
                        <SubmenuButton
                            onClick={handleAddToADifferentProject}
                            disabled={!canManageSourcerMembers(sourcer)}
                        >
                            {translate('add-to-a-different-project')}
                        </SubmenuButton>
                    )}
                    {member.status === SourcerMemberStatusEnum.Accepted && (
                        <SubmenuButton onClick={handleAddToAProjectOrSequence}>
                            {translate('add-to-a-project-or-sequence-label')}
                        </SubmenuButton>
                    )}
                    {member.status === SourcerMemberStatusEnum.Accepted && latestUserRecipient ? (
                        <>
                            <SubmenuButton
                                onClick={handleChangeStatus}
                                disabled={
                                    latestUserRecipient.status === RecipientStatusEnum.Unsubscribed ||
                                    latestUserRecipient.status === RecipientStatusEnum.MeetingBooked
                                }
                            >
                                {translate('change-status-label')}
                            </SubmenuButton>
                            <SubmenuButton
                                onClick={handleStopSequenceForThisProject}
                                disabled={RecipientSequenceStopped.includes(latestUserRecipient.status)}
                            >
                                {translate('stop-sequence-for-this-project-label')}
                            </SubmenuButton>
                            <SubmenuButton onClick={handleResendSequenceToNewEmail}>
                                {translate('resend-sequence-to-new-email-label')}
                            </SubmenuButton>
                            <SubmenuButton
                                onClick={handleReportAWrongEmail}
                                disabled={latestUserRecipient.contacts.length === 0}
                            >
                                {translate('report-wrong-email-label')}
                            </SubmenuButton>
                        </>
                    ) : null}

                    <SubmenuButton onClick={handleShare}>{translate('copy-linked-in-link')}</SubmenuButton>
                </Box>
            </Popover>
            <AddMultipleSourcerMembersToProject
                creditsPerApproval={creditsPerApproval}
                open={showAddToADifferentProjectModal}
                onClose={handleAddToADifferentProjectModalClose}
                sourcerMembers={[member]}
                sourcerProjectId={member.sourcer.project.id}
                flow={SourcerMemberApprovalAction.KebabApprove}
            />
            {latestUserRecipient && (
                <>
                    <ChangeRecipientStatusModal
                        open={showChangeStatusModal}
                        onClose={handleCloseChangeStatusModal}
                        recipients={[latestUserRecipient]}
                    />

                    <ResendSequenceNewEmailModal
                        open={showResendNewEmailModal}
                        onClose={handleCloseResendNewEmailModal}
                        recipients={[latestUserRecipient]}
                    />
                    <ReportWrongEmailModal
                        open={showReportWrongEmailModal}
                        recipients={[latestUserRecipient]}
                        onClose={handleCloseReportWrongEmailModal}
                    />
                    <StopSequenceModal
                        open={showStopSequenceModal}
                        recipients={[latestUserRecipient]}
                        onClose={handleCloseStopSequenceModal}
                    />
                </>
            )}
            <ProspectPanel
                isPanelOpen={showPanel}
                hideProspectPanel={onPanelClose}
                prospectContext={prospectPanelContext}
                tab={PanelTabEnum.ADD}
            />
        </>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink href={OUT_OF_AI_SOURCING_CREDITS} newTab>
        <u>{children}</u>
    </Hyperlink>
);

export { SourcerMembersListSubmenu };
