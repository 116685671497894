import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { LicenseTypesEnum, PlanTypesEnum } from 'codegen/graphql';
import { EmptyState } from 'shared/components/composite';
import { Button } from 'shared/components/presentational';
import { Plus, Target } from 'shared/components/svgs';
import { useAccessControl } from 'shared/hooks';
import { appRoutes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EmptySourcingNoticeProps {
    planType: PlanTypesEnum;
    licenseType: LicenseTypesEnum;
}
const EmptySourcingNotice: FC<EmptySourcingNoticeProps> = (props) => {
    const theme = useTheme();
    const translate = useTranslations('sourcing');
    const { planType, licenseType } = props;
    const { canAccessAISourcing } = useAccessControl();

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <Target
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                />
            }
            title={translate('empty-notice.title')}
            description={translate('empty-notice.description')}
            button={
                <Button
                    disabled={!canAccessAISourcing(planType, licenseType)}
                    href={appRoutes.sourcing.create.index()}
                    css={css`
                        height: ${spacing.space44px};
                    `}
                    variant="contained"
                    startIcon={<Plus stroke="white" />}
                >
                    {translate('empty-notice.button')}
                </Button>
            }
        />
    );
};

export { EmptySourcingNotice };
