import { css } from '@mui/material';

import { Tooltip } from 'shared/components/presentational';
import { Target } from 'shared/components/svgs';
import { colors } from 'shared/settings';

interface AiSourcedTargetProps {
    title: string;
    width: number;
    height: number;
    isRowItem?: boolean;
}

const AiSourcedTarget = (props: AiSourcedTargetProps) => {
    const { title, width, height, isRowItem = true } = props;

    return (
        <Tooltip title={title}>
            <Target
                width={width}
                height={height}
                stroke={colors.purples.veronica}
                css={css`
                    ${isRowItem ? 'margin-top: 4px' : 'margin-bottom: 4px'};
                    cursor: pointer;
                    path {
                        opacity: 0.4;
                    }

                    &:hover {
                        path {
                            opacity: 1;
                        }
                    }
                `}
            />
        </Tooltip>
    );
};

export { AiSourcedTarget };
