import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    SourcerCollaboratorTypeEnum,
    SourcerStatusEnum,
    UpdateSourcersMutation,
    UpdateSourcersMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { Archive, Pause, PlayCircle, XClose } from 'shared/components/svgs';
import { GET_SOURCERS, GET_SOURCERS_COUNT_BY_STATUS, UPDATE_SOURCERS } from 'shared/graphql/sourcers';
import { useConfirmationModal, useSnackbarAlert } from 'shared/hooks';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import { ArchiveSourcersModal, PauseSourcersModal } from 'sourcing/components/modals';
import { SourcerTab } from 'sourcing/types';

interface SourcerListEditBarProps {
    sourcers: {
        id: string;
        userId: string;
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    }[];
    onClose(): void;
    tab: SourcerTab;
}

const SourcerListEditBar: FC<SourcerListEditBarProps> = React.forwardRef(({ sourcers, onClose, tab }, ref) => {
    const sourcerIds = sourcers.map((sourcer) => sourcer.id);
    const translate = useTranslations('sourcing.sourcing-list-table.edit-bar');
    const theme = useTheme();
    const { showModal, hideModal } = useConfirmationModal();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [openPauseSourcerModal, setOpenPauseSourcerModal] = useState(false);
    const [openArchiveSourcerModal, setOpenArchiveSourcerModal] = useState(false);

    const [updateSourcer] = useMutation<UpdateSourcersMutation, UpdateSourcersMutationVariables>(UPDATE_SOURCERS, {
        refetchQueries: [
            getOperationName(GET_SOURCERS) as string,
            getOperationName(GET_SOURCERS_COUNT_BY_STATUS) as string,
        ],
    });

    const handleOpenPauseSourcerModal = () => {
        setOpenPauseSourcerModal(true);
    };

    const handleOpenArchiveSourcerModal = () => {
        setOpenArchiveSourcerModal(true);
    };

    const handleResumeSourcer = async () => {
        const res = await updateSourcer({
            variables: { where: { id: { _in: sourcerIds } }, set: { status: SourcerStatusEnum.Active } },
        });
        const affectedSourcersIds = res?.data?.update_sourcers?.returning.map((a) => a.id) ?? [];
        const affectedCount = affectedSourcersIds.length;
        const notAffectedCount = sourcerIds.length - affectedCount;
        showSnackbarAlert({
            severity: 'success',
            message:
                affectedCount === 0
                    ? translate('resume-success-0-affected')
                    : translate('resume-success', { affectedCount, notAffectedCount }),
        });

        hideModal();
        onClose();
        for (const sourcerId of affectedSourcersIds) {
            trackEvent('click_resume', { sourcer_id: sourcerId, value: 'sourcer' });
        }
    };
    const handleOpenResumeSourcerModal = () => {
        showModal({
            title: translate('resume-modal-header'),
            description: translate('resume-modal-description'),
            confirmButton: {
                text: translate('resume-modal-resume-now-button-label'),
                onClick: handleResumeSourcer,
            },
            cancelButton: {
                text: translate('modal-cancel-button-label'),
                onClick: hideModal,
            },
        });
    };

    const handleUnarchiveSourcers = async () => {
        const res = await updateSourcer({
            variables: { where: { id: { _in: sourcerIds } }, set: { status: SourcerStatusEnum.Active } },
        });
        const affectedSourcersIds = res?.data?.update_sourcers?.returning.map((a) => a.id) ?? [];
        const affectedCount = affectedSourcersIds.length;
        // Caution: the not affected sourcers are the ones that are not accessible in this case
        const notAffectedCount = sourcerIds.length - affectedCount;
        showSnackbarAlert({
            severity: 'success',
            message:
                affectedCount === 0
                    ? translate('unarchive-success-0-affected')
                    : translate('unarchive-success', { affectedCount, notAffectedCount }),
        });
        onClose();
    };

    const getActionButtons = () => {
        const pauseButton = (
            <Button
                key="pause-button"
                startIcon={<Pause />}
                css={css`
                    color: ${theme.palette.common.white};
                `}
                onClick={handleOpenPauseSourcerModal}
                disableRipple
            >
                {translate('pause-sourcers')}
            </Button>
        );

        const resumeButton = (
            <Button
                key="resume-button"
                startIcon={<PlayCircle />}
                css={css`
                    color: ${theme.palette.common.white};
                `}
                onClick={handleOpenResumeSourcerModal}
                disableRipple
            >
                {translate('resume-sourcers')}
            </Button>
        );

        const archiveButton = (
            <Button
                key="archive-button"
                startIcon={<Archive />}
                css={css`
                    color: ${theme.palette.common.white};
                `}
                onClick={handleOpenArchiveSourcerModal}
                disableRipple
            >
                {translate('archive-sourcers')}
            </Button>
        );

        const unArchiveButton = (
            <Button
                key="unarchive-button"
                startIcon={<Archive />}
                css={css`
                    color: ${theme.palette.common.white};
                `}
                onClick={handleUnarchiveSourcers}
                disableRipple
            >
                {translate('unarchive-sourcers')}
            </Button>
        );

        switch (tab) {
            case SourcerStatusEnum.Active:
                return [pauseButton, archiveButton];
            case SourcerStatusEnum.Draft:
                return [archiveButton];
            case SourcerStatusEnum.Paused:
                return [resumeButton, archiveButton];
            case SourcerStatusEnum.Archived:
                return [unArchiveButton];
            case 'all':
                return [pauseButton, archiveButton];
            default:
                return [];
        }
    };

    const handleClosePauseSourcerModal = () => {
        setOpenPauseSourcerModal(false);
        onClose();
    };

    const handleCloseArchiveSourcerModal = () => {
        setOpenArchiveSourcerModal(false);
        onClose();
    };

    return (
        <>
            <Box
                css={css`
                    display: flex;
                    align-items: center;

                    width: 960px;
                    background-color: ${theme.palette.grey[200]};
                    padding: 20px 25px;
                    border-radius: 4px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                    color: ${theme.palette.common.white};
                    font-weight: ${fontWeights.bold};
                `}
                ref={ref}
            >
                <Box
                    css={css`
                        margin-right: auto;
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('items-selected', { count: sourcerIds.length })}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        font-size: ${fontSizes.f16};
                    `}
                >
                    {getActionButtons()}
                </Box>
                <Button startIcon={<XClose stroke={theme.palette.common.white} />} onClick={onClose} disableRipple />
            </Box>
            <PauseSourcersModal
                open={openPauseSourcerModal}
                onClose={handleClosePauseSourcerModal}
                sourcerIds={sourcerIds}
            />
            <ArchiveSourcersModal
                open={openArchiveSourcerModal}
                onClose={handleCloseArchiveSourcerModal}
                sourcerIds={sourcerIds}
            />
        </>
    );
});

export { SourcerListEditBar };
