import styled from '@emotion/styled';
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';

import { FC } from 'shared/types';

interface RadioProps extends MuiRadioProps {}

const Radio: FC<RadioProps> = (props) => <MuiRadio {...props} />;

const StyledRadio = styled(Radio)``;

export { StyledRadio as Radio };
