import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { partition } from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import { SendFromAccountsQuery } from 'codegen/graphql';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { Select, ListSubheader, MenuItem } from 'shared/components/form';
import { AddEmailModal, Button, Tooltip } from 'shared/components/presentational';
import { Circle, InfoCircle } from 'shared/components/svgs';
import { useSettings } from 'shared/hooks';
import { AddEmailAccountResponse } from 'shared/services';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SendFromSelectProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    index: number;
    disabled?: boolean;
    isEdit?: boolean;
    sendFromAccounts: NonNullable<SendFromAccountsQuery['send_from_accounts']>;
    showNewCollaboratorDisclaimer: boolean;
    onNewEmailAdd: (email: string) => void;
    showTooltip?: boolean;
}

const SendFromSelect: FC<SendFromSelectProps> = (props) => {
    const {
        formMethods,
        index,
        disabled = false,
        isEdit,
        sendFromAccounts,
        showNewCollaboratorDisclaimer,
        onNewEmailAdd,
        showTooltip = false,
    } = props;
    const translate = useTranslations('sequence.create.stages');
    const theme = useTheme();

    const { openHireflowAccount } = useSettings();
    const [showAddEmailModal, setShowAddEmailModal] = useState<boolean>(false);

    const sendFromAccountIdState = formMethods.getFieldState(
        `stages.${index}.sendFromAccountId`,
        formMethods.formState
    );

    const selectedAccountId = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendFromAccountId`,
    });

    const [userOwnedAccounts, sendOnBehalfAccounts] = partition(
        sendFromAccounts,
        (account) => account.email_account.user.id === account.user.id
    );

    const isUserOwnedAccount = userOwnedAccounts.some((a) => a.id === selectedAccountId);

    useEffect(() => {
        if (sendFromAccountIdState.isDirty) {
            trackEvent('select_sobo', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: isUserOwnedAccount ? 'user_email' : 'sobo_email',
            });
        }
    }, [sendFromAccountIdState.isDirty, isEdit, isUserOwnedAccount]);

    const getOptions = (options: { id: string; email: string }[]) =>
        options.map((option) => (
            <MenuItem value={option.id} key={option.id}>
                {option.id === selectedAccountId && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {option.email}
            </MenuItem>
        ));

    const handleEmailAdd = async ({ data: { emailAccount } }: AddEmailAccountResponse) => {
        if (!emailAccount) {
            return;
        }
        onNewEmailAdd(emailAccount!.email);
        setShowAddEmailModal(false);
    };

    const sendFromSelectComponent = (
        <Box
            css={css`
                min-width: 354px;
                display: flex;
                flex-direction: column;
                gap: ${spacing.space8px};
            `}
        >
            {(sendFromAccounts ?? []).length === 0 && !isEdit ? (
                <>
                    <Button
                        name={`stages.${index}.sendFromAccountId`}
                        css={css`
                            width: 354px;
                        `}
                        variant="outlined"
                        color="error"
                        onClick={() => setShowAddEmailModal(true)}
                    >
                        {translate('connect-an-email')}
                    </Button>
                    <Box
                        css={css`
                            display: flex;
                            gap: ${spacing.space8px};
                        `}
                    >
                        <InfoCircle width={12} height={12} stroke={colors.grays.tundora} />
                        <Box
                            css={css`
                                font-size: 12px;
                                color: ${colors.grays.tundora};
                            `}
                        >
                            {translate('connect-an-email-description')}
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <Select
                        disabled={disabled}
                        label={translate('send-from')}
                        formMethods={formMethods}
                        value={sendFromAccounts.find((a) => a.id === selectedAccountId)?.id || ''}
                        name={`stages.${index}.sendFromAccountId`}
                        css={css`
                            margin-right: ${spacing.space16px};
                            margin-bottom: 0;
                        `}
                    >
                        <ListSubheader>{translate('my-email-accounts')}</ListSubheader>
                        {getOptions(userOwnedAccounts.map(({ id, email }) => ({ id, email })))}

                        {sendOnBehalfAccounts?.length > 0 && (
                            <ListSubheader>{translate('send-on-behalf-accounts')}</ListSubheader>
                        )}
                        {getOptions(sendOnBehalfAccounts.map(({ id, email }) => ({ id, email })))}
                        <Box
                            css={css`
                                padding: 12px;
                            `}
                        >
                            <Button
                                css={css`
                                    width: 100%;
                                `}
                                variant="outlined"
                                onClick={openHireflowAccount}
                            >
                                {translate('manage-email-accounts')}
                            </Button>
                        </Box>
                    </Select>
                    {showNewCollaboratorDisclaimer && (
                        <Box
                            css={css`
                                display: flex;
                                gap: ${spacing.space8px};
                            `}
                        >
                            <InfoCircle width={12} height={12} stroke={colors.grays.tundora} />
                            <Box
                                css={css`
                                    font-size: 12px;
                                    color: ${colors.grays.tundora};
                                `}
                            >
                                {translate('new-collaborator-disclaimer')}
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );

    return (
        <>
            {showTooltip ? (
                <Tooltip
                    css={css`
                        &.tooltip-root {
                            // this is needed to make the tooltip only
                            // appear when hovering over the select
                            display: inline-block;
                        }
                    `}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: `${theme.palette.common.white}`,
                                color: `${theme.palette.common.black}`,
                                borderRadius: '8px',
                                boxShadow: `0px 10px 30px 0px rgba(0, 0, 0, 0.10)`,
                                maxWidth: '802px',
                            },
                        },
                    }}
                    placement="bottom-start"
                    title={translate('send-from-tooltip')}
                >
                    {sendFromSelectComponent}
                </Tooltip>
            ) : (
                sendFromSelectComponent
            )}

            <AddEmailModal
                open={showAddEmailModal}
                onClose={() => setShowAddEmailModal(false)}
                onAddEmail={handleEmailAdd}
            />
        </>
    );
};

export { SendFromSelect };
