import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { showIntercom } from 'external/intercom';
import { Box } from 'shared/components/containers';
import { colors } from 'shared/settings';

const MessageViaIntercom = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            color: ${colors.greens.eucalyptus};

            &:hover {
                cursor: pointer;
                color: ${colors.greens.emerald};
            }
        `}
        onClick={showIntercom}
    >
        &nbsp;<u>{children}</u>
    </Box>
);

export { MessageViaIntercom };
