import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { ArrowRight } from 'shared/components/svgs';
import { colors, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface EmailToAndFromProps {
    to?: string;
    from?: string;
}

const EmailToAndFrom: FC<EmailToAndFromProps> = (props) => {
    const { to, from } = props;
    const translate = useTranslations('prospects.tabs.conversations-tab');

    return to && from ? (
        <Box
            css={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                margin: 6px 0 12px;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    font-size: 12px;
                    column-gap: 12px;
                `}
            >
                <Box
                    css={css`
                        text-transform: uppercase;
                        color: ${colors.grays.dusty};
                        font-weight: ${fontWeights.medium};
                    `}
                >
                    {translate('email-to-label')}
                </Box>
                <ArrowRight height={12} width={12} stroke={colors.grays.dusty} />
                <Box
                    css={css`
                        color: ${colors.grays.tundora};
                    `}
                >
                    {to}
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    font-size: 12px;
                    column-gap: 12px;
                `}
            >
                <Box
                    css={css`
                        text-transform: uppercase;
                        color: ${colors.grays.dusty};
                        font-weight: ${fontWeights.medium};
                    `}
                >
                    {translate('email-from-label')}
                </Box>
                <ArrowRight height={12} width={12} stroke={colors.grays.dusty} />
                <Box
                    css={css`
                        color: ${colors.grays.tundora};
                    `}
                >
                    {from}
                </Box>
            </Box>
        </Box>
    ) : null;
};

export { EmailToAndFrom };
