import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { PersonalizeOptions } from 'sequences/components/presentational';
import { Box } from 'shared/components/containers';
import { Editor } from 'shared/components/editor';
import { FC } from 'shared/types';

interface ComposeCompactEmailProps {
    formMethods: UseFormReturn<any>;
    index: number;
    onRemoveEmail?: (selectedEmailIndex: number) => void;
    numVariablesToShow?: number;
    disabled?: boolean;
}

const ComposeCompactEmail: FC<ComposeCompactEmailProps> = (props) => {
    const { formMethods, index, numVariablesToShow, disabled = false } = props;
    const [selectedVariable, setSelectedVariable] = useState<string>('');

    const handleSelectedVariableClick = (variable: string) => {
        setSelectedVariable(variable);
    };

    const handleClearVariable = () => {
        setSelectedVariable('');
    };

    return (
        <Box>
            {!disabled && (
                <PersonalizeOptions
                    onClick={handleSelectedVariableClick}
                    numOptionsToShow={numVariablesToShow}
                    disabled={disabled}
                />
            )}
            <Editor
                formMethods={formMethods}
                index={index}
                isSubjectTouched={false} // no subject
                name={`stages.${index}.content`}
                onEditorTouched={(_touched: boolean) => {
                    // this is used in conjunction with a subject, but
                    // since we don't have a subject, this becomes a no-op
                }}
                onClearVariable={handleClearVariable}
                selectedVariable={selectedVariable}
                disabled={disabled}
                compact
                showFooter={false}
            />
        </Box>
    );
};

export { ComposeCompactEmail };
