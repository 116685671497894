/* eslint-disable class-methods-use-this */
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { atsTypeAshby, atsTypeGreenhouse, atsTypeLever } from 'integrations/constants';
import { AtsType } from 'integrations/types';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { Translate, AtsTypeExcludeGreenhouse, FormATSValidationBuilder } from 'shared/utils';

export interface SequenceFormATSValidationBuilderSchemaShape {
    ashbyUserId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyRepliedStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyInterestedReplyStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    ashbyMeetingBookedStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseUserId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseInterestedReplyStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    greenhouseMeetingBookedStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverUserId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverRepliedStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverInterestedReplyStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    leverMeetingBookedStageId: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
}

type SchemaField = keyof SequenceFormATSValidationBuilderSchemaShape;

export interface SequenceFormATSValidationBuilderProps {
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    translate: Translate;
}

export class SequenceFormATSValidationBuilder extends FormATSValidationBuilder<
    CreateEditSequenceFormValues,
    SequenceFormATSValidationBuilderSchemaShape
> {
    protected schemaShape: SequenceFormATSValidationBuilderSchemaShape = {
        ashbyUserId: Yup.string().notRequired(),
        ashbyRepliedStageId: Yup.string().notRequired(),
        ashbyInterestedReplyStageId: Yup.string().notRequired(),
        ashbyMeetingBookedStageId: Yup.string().notRequired(),
        greenhouseUserId: Yup.string().notRequired(),
        greenhouseInterestedReplyStageId: Yup.string().notRequired(),
        greenhouseMeetingBookedStageId: Yup.string().notRequired(),
        leverUserId: Yup.string().notRequired(),
        leverRepliedStageId: Yup.string().notRequired(),
        leverInterestedReplyStageId: Yup.string().notRequired(),
        leverMeetingBookedStageId: Yup.string().notRequired(),
    };

    constructor({ formMethods, translate }: SequenceFormATSValidationBuilderProps) {
        super({ formMethods, translate });
        this.buildValidationSchema();
    }

    protected getJobIdFormValue = (atsType: AtsType) => this.formMethods.getValues(`${atsType}JobId`);

    protected getUserIdField = (atsType: AtsType): SchemaField => `${atsType}UserId`;

    protected getRepliedMoveStageActiveFormValue = (atsType: AtsTypeExcludeGreenhouse) =>
        this.formMethods.getValues(`${atsType}RepliedMoveStageActive`);

    protected getRepliedStageIdField = (atsType: AtsTypeExcludeGreenhouse): SchemaField => `${atsType}RepliedStageId`;

    protected getInterestedReplyMoveStageActiveFormValue = (atsType: AtsType) =>
        this.formMethods.getValues(`${atsType}InterestedReplyMoveStageActive`);

    protected getInterestedReplyStageIdField = (atsType: AtsType): SchemaField => `${atsType}InterestedReplyStageId`;

    protected getMeetingBookedMoveStageActiveFormValue = (atsType: AtsType) =>
        this.formMethods.getValues(`${atsType}MeetingBookedMoveStageActive`);

    protected getMeetingBookedStageIdField = (atsType: AtsType): SchemaField => `${atsType}MeetingBookedStageId`;

    protected buildValidationSchema = () => {
        let newSchemaShape = this.getSchemaShape();

        // ashby
        newSchemaShape = { ...newSchemaShape, ...this.addUserRequirement(atsTypeAshby) };
        newSchemaShape = { ...newSchemaShape, ...this.addReplyStageRequirement(atsTypeAshby) };
        newSchemaShape = { ...newSchemaShape, ...this.addInterestedReplyStageRequirement(atsTypeAshby) };
        newSchemaShape = { ...newSchemaShape, ...this.addMeetingBookedStageRequirement(atsTypeAshby) };

        // greenhouse
        newSchemaShape = { ...newSchemaShape, ...this.addUserRequirement(atsTypeGreenhouse) };
        newSchemaShape = { ...newSchemaShape, ...this.addInterestedReplyStageRequirement(atsTypeGreenhouse) };
        newSchemaShape = { ...newSchemaShape, ...this.addMeetingBookedStageRequirement(atsTypeGreenhouse) };

        // lever
        newSchemaShape = { ...newSchemaShape, ...this.addUserRequirement(atsTypeLever) };
        newSchemaShape = { ...newSchemaShape, ...this.addReplyStageRequirement(atsTypeLever) };
        newSchemaShape = { ...newSchemaShape, ...this.addInterestedReplyStageRequirement(atsTypeLever) };
        newSchemaShape = { ...newSchemaShape, ...this.addMeetingBookedStageRequirement(atsTypeLever) };

        this.setSchemaShape(newSchemaShape);
    };
}
