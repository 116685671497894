import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedAshbyLogo: FC<SvgProps> = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g clipPath="url(#clip0_12747_50785)">
            <path
                // eslint-disable-next-line max-len
                d="M9.79097 6.61967C9.00129 8.7195 7.93376 11.5596 7.40731 12.9343C5.63785 17.6627 5.09677 18.7202 4.36559 18.9165C4.16086 18.9619 4 19.0978 4 19.2187C4 19.3849 4.45333 19.4302 6.4129 19.4302C8.35785 19.4302 8.82581 19.3849 8.82581 19.2187C8.82581 19.0827 8.59183 18.9921 8.15312 18.9317C7.37806 18.841 6.82237 18.3878 6.70538 17.708C6.58839 17.1188 6.80774 16.0916 7.37806 14.3996L7.8314 13.0853H10.1419C12.2039 13.0853 12.4671 13.1156 12.5548 13.3422C12.6133 13.4932 12.9789 14.5205 13.3738 15.6233C14.3535 18.3878 14.3243 18.7957 13.1544 18.9317C12.7157 18.9921 12.4817 19.0827 12.4817 19.2187C12.4817 19.4 13.1983 19.4302 16.5032 19.4302C19.9837 19.4302 20.5247 19.4 20.5247 19.2036C20.5247 19.0827 20.437 18.977 20.32 18.977C19.9398 18.977 19.3841 18.0857 18.7991 16.5297C18.4774 15.6686 17.9071 14.1881 17.5561 13.2364C16.5763 10.6381 13.7686 3.22065 13.6955 2.99405C13.637 2.8732 13.2421 2.81277 12.4232 2.81277H11.2241L9.79097 6.61967ZM11.3265 9.80719C11.8675 11.3179 12.277 12.6019 12.2331 12.6624C12.1308 12.8436 8.09462 12.8134 8.09462 12.6321C8.09462 12.5415 8.43097 11.5747 8.84043 10.487C9.24989 9.39931 9.71785 8.14545 9.87871 7.69224C10.0396 7.23904 10.2151 6.9218 10.2589 6.96712C10.3174 7.02755 10.8 8.29651 11.3265 9.80719Z"
                fill={fill ?? 'black'}
            />
        </g>
        <defs>
            <clipPath id="clip0_12747_50785">
                <rect width="17" height="23" fill="white" transform="translate(4 1)" />
            </clipPath>
        </defs>
    </svg>
);

const AshbyLogo = withTheme(ThemedAshbyLogo);

export { AshbyLogo };
