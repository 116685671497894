import { css } from '@emotion/react';
import { Breadcrumbs, styled, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

import { ProspectSearchBar } from 'prospects';
import { Breadcrumb } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import { spacing } from 'shared/settings';
import { FC, PageHeaderBreadcrumb } from 'shared/types';

interface PageHeaderProps {
    breadcrumbs: PageHeaderBreadcrumb[];
    icon?: React.ReactNode;
    hideSearchBar?: boolean;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
    const { breadcrumbs, icon, hideSearchBar } = props;
    const theme = useTheme();
    const router = useRouter();

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
            `}
        >
            <Breadcrumbs
                css={css`
                    flex: 1;

                    .MuiBreadcrumbs-ol {
                        white-space: nowrap;
                        flex-wrap: nowrap;
                        max-width: calc(${appMaxWidth} - 48px);
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media (max-width: 1000px) {
                            max-width: calc(${appMaxSmallWidth} - 48px);
                        }
                    }

                    .MuiBreadcrumbs-li {
                        white-space: nowrap;

                        &:last-child {
                            flex: 1;
                        }
                    }
                `}
                separator="/"
                aria-label="page-header-breadcrumb"
            >
                {breadcrumbs.map((b, index) => {
                    const breadcrumbElement = (
                        <Breadcrumb title={b.text} icon={icon} isLastItem={index === breadcrumbs.length - 1} />
                    );
                    if (b.url) {
                        return (
                            <Box
                                key={index}
                                css={css`
                                    &:hover {
                                        cursor: pointer;
                                        color: ${theme.palette.primary.light};
                                    }
                                `}
                                component="span"
                                onClick={() => router.push(b.url!)}
                            >
                                {breadcrumbElement}
                            </Box>
                        );
                    }
                    return { ...breadcrumbElement, key: index };
                })}
            </Breadcrumbs>
            <Box
                css={css`
                    display: flex;
                    gap: ${spacing.space8px};
                    align-items: center;
                `}
            >
                {!hideSearchBar && <ProspectSearchBar />}
            </Box>
        </Box>
    );
};

const StyledPageHeader = styled(PageHeader)``;

export { StyledPageHeader as PageHeader };
