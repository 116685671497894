import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SelectProps as MuiSelectProps, Select as MuiSelect, MenuItem } from '@mui/material';
import Image from 'next/image';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { Box } from 'shared/components/containers';
import { Chevron } from 'shared/components/svgs';
import { colorPickerOptions } from 'shared/settings';
import { FCProps } from 'shared/types';

interface SelectColorProps<Values extends FieldValues> extends MuiSelectProps<Values> {
    name: Path<Values>;
    hideChevron?: boolean;
    formMethods: UseFormReturn<Values>;
}

const SelectContainer = <Values extends FieldValues>(props: SelectColorProps<Values> & FCProps) => {
    const { className, formMethods, name, hideChevron, disabled, ...rest } = props;

    return (
        <Controller
            name={name}
            control={formMethods.control}
            render={({ field: { onBlur, onChange, ref, value }, fieldState: { error } }) => (
                <MuiSelect
                    css={css`
                        svg {
                            transition: opacity 0.4s ease-in-out;
                            backface-visibility: hidden; // prevents "blurry"/"pop" into existence issue
                        }

                        ${hideChevron &&
                        `& svg {
                            visibility: hidden;
                            opacity: 0;
                        }
                    
                        &:hover:not(.Mui-disabled) svg {
                            visibility: visible;
                            opacity: 1;
                        }
                    `}
                    `}
                    MenuProps={{
                        classes: { list: className },
                    }}
                    className={className}
                    name={name}
                    id={name}
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!error}
                    disabled={disabled}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    IconComponent={(iconProps) => (
                        <Chevron
                            {...iconProps}
                            css={css`
                                top: 32% !important;
                            `}
                        />
                    )}
                    {...rest}
                >
                    {colorPickerOptions.map((color: string) => (
                        <MenuItem value={color} key={color}>
                            <Box
                                css={css`
                                    background-color: ${color};
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 50%;
                                    padding: 6px;
                                `}
                            >
                                {color === value && <Image src="/check.svg" alt="checkmark" width={16} height={16} />}
                            </Box>
                        </MenuItem>
                    ))}
                </MuiSelect>
            )}
        />
    );
};

const SelectColor = styled(SelectContainer)`
    width: 75px;

    .MuiOutlinedInput-input {
        padding: 14px 14px !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    svg path {
        stroke: ${({ theme }) => theme.palette?.common.black};
    }

    .MuiSelect-select img {
        display: none !important;
    }

    ul& {
        width: 300px;
        display: flex;
        flex-wrap: wrap;
    }
` as typeof SelectContainer;

export { SelectColor };
