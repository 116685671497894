import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import {
    ContactKindsEnum,
    ContactChannelsEnum,
    RecipientResponseMethodTypesEnum,
    RecipientResponseSentimentSourceEnum,
    RecipientResponseSentimentTypesEnum,
    RecipientStatusEnum,
    UpdateRecipientResponseStatusMutation,
    UpdateRecipientResponseStatusMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Select, SelectOption, SelectSequence } from 'shared/components/form';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { GET_PROJECT_MEMBERS_BY_PROJECT } from 'shared/graphql/project-members';
import { GET_RECIPIENT_ACTIVITIES } from 'shared/graphql/recipient-activities';
import { GET_RECIPIENTS, UPDATE_RECIPIENT_RESPONSE_STATUS } from 'shared/graphql/recipients';
import { NO_SEQUENCE } from 'shared/graphql/sequences';
import { GET_SOURCER_MEMBERS } from 'shared/graphql/sourcer-members';
import { useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RecipientDetailsForStatusChange {
    id: string;
    sequence: { id: string; title: string };
    status: RecipientStatusEnum;
    prospect: {
        id: string;
        contacts?:
            | {
                  id: string;
                  kind: ContactKindsEnum | string;
                  value: string;
                  standardizedValue: string;
                  channel: ContactChannelsEnum | string;
                  primary?: boolean | null | undefined;
              }[]
            | undefined
            | null;
        profile?: { fullName: string } | undefined | null;
    };
    recipientResponses?: { sentiment?: RecipientResponseSentimentTypesEnum | null; createdAt: number }[];
    contacts: { id: string; contactId: string; channel: string }[];
}

interface ChangeRecipientStatusModalProps {
    recipients: RecipientDetailsForStatusChange[];
    open: boolean;
    onClose: () => void;
}

type AllowedStatusSelectOption =
    | RecipientStatusEnum.MeetingBooked
    | RecipientResponseSentimentTypesEnum.Interested
    | RecipientResponseSentimentTypesEnum.NotInterested;

const ChangeRecipientStatusModal: FC<ChangeRecipientStatusModalProps> = ({ recipients, onClose, ...rest }) => {
    const translate = useTranslations('sequence.recipient-list-table');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateRecipientStatus] = useMutation<
        UpdateRecipientResponseStatusMutation,
        UpdateRecipientResponseStatusMutationVariables
    >(UPDATE_RECIPIENT_RESPONSE_STATUS, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_RECIPIENT_ACTIVITIES) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
            getOperationName(GET_SOURCER_MEMBERS) as string,
        ],
    });

    const [selectedRecipient, setSelectedRecipient] = useState<RecipientDetailsForStatusChange | undefined>(
        recipients.length === 1 ? recipients[0] : undefined
    );

    const [selectedSequence, setSelectedSequence] = useState<string | undefined>();

    const defaultValidOptions = (
        [
            RecipientStatusEnum.MeetingBooked,
            RecipientResponseSentimentTypesEnum.Interested,
            RecipientResponseSentimentTypesEnum.NotInterested,
        ] as const
    ).map((option) => ({
        value: option,
        label: translate(`change-status-modal.${option}`),
    }));

    const [validOptions, setValidOptions] = useState<SelectOption[]>(defaultValidOptions);

    const [selectedOption, setSelectedOption] = useState<AllowedStatusSelectOption>(
        RecipientResponseSentimentTypesEnum.Interested
    );

    useEffect(() => {
        if (!selectedRecipient) return;

        let filteredOptions = defaultValidOptions;
        const responses = selectedRecipient?.recipientResponses ?? [];
        const latestResponse = responses[0]; // careful this can be undefined

        switch (selectedRecipient?.status) {
            case RecipientStatusEnum.Responded: {
                filteredOptions = filteredOptions.filter((option) => option.value !== latestResponse?.sentiment);
                break;
            }

            case RecipientStatusEnum.MeetingBooked: {
                filteredOptions = [];
                break;
            }

            case RecipientStatusEnum.Unsubscribed: {
                filteredOptions = [];
                break;
            }

            default:
                break;
        }

        setValidOptions(filteredOptions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecipient?.status]);

    const handleClose = () => {
        setSelectedRecipient(recipients.length === 1 ? recipients[0] : undefined);
        setSelectedOption(RecipientResponseSentimentTypesEnum.Interested);
        setSelectedSequence(undefined);
        onClose();
    };

    const handleSequenceChange = (value: string) => {
        setSelectedSequence(value);
    };

    const handleChangeStatusInSelect = (status: AllowedStatusSelectOption) => {
        setSelectedOption(status);
    };

    const handleChangeStatus = async () => {
        if (!selectedRecipient || !selectedOption) return;

        const isReplyOptionSelected =
            selectedOption === RecipientResponseSentimentTypesEnum.Interested ||
            selectedOption === RecipientResponseSentimentTypesEnum.NotInterested;

        const selectedStatus = isReplyOptionSelected
            ? RecipientStatusEnum.Responded
            : RecipientStatusEnum.MeetingBooked;

        const selectedSentiment = isReplyOptionSelected
            ? selectedOption
            : RecipientResponseSentimentTypesEnum.Interested;

        const responseMethod = isReplyOptionSelected
            ? RecipientResponseMethodTypesEnum.UserMarkedResponded
            : RecipientResponseMethodTypesEnum.UserMarkedCalendarBooking;

        const respondedAt = isReplyOptionSelected ? { respondedAt: Date.now() } : {};

        const meetingScheduledAt = isReplyOptionSelected ? {} : { meetingScheduledAt: Date.now() };

        await updateRecipientStatus({
            variables: {
                id: selectedRecipient.id,
                updateData: {
                    status: selectedStatus,
                    ...respondedAt,
                    ...meetingScheduledAt,
                },
                insertData: {
                    recipientId: selectedRecipient?.id,
                    method: responseMethod,
                    sentiment: selectedSentiment,
                    sentimentSource: RecipientResponseSentimentSourceEnum.User,
                },
            },
        });

        setValidOptions(defaultValidOptions.filter((option) => option.value !== selectedOption));
        setSelectedOption(RecipientResponseSentimentTypesEnum.Interested);
        setSelectedSequence(undefined);
        setSelectedRecipient(recipients.length === 1 ? recipients[0] : undefined);
        onClose();
        showSnackbarAlert({
            severity: 'success',
            message: translate('change-status-modal.status-updated-alert', {
                name: selectedRecipient?.prospect.profile?.fullName,
            }),
        });
        trackEvent('click_change_status', {
            recipient_id: selectedRecipient.id,
            old_status: selectedRecipient.status,
            new_status: selectedStatus,
        });
    };

    const handleConfirmSequence = () => {
        setSelectedRecipient(recipients.find((recipient) => recipient.sequence.id === selectedSequence));
    };

    return (
        <Modal onClose={handleClose} {...rest}>
            <Box
                css={css`
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    {selectedRecipient
                        ? translate('change-status-modal.header')
                        : translate('choose-recipient-form.title')}
                </Title>
                {selectedRecipient ? (
                    <>
                        <Select
                            isDefault
                            options={validOptions}
                            label={translate('change-status-modal.status-select-label')}
                            name="status"
                            value={selectedOption || ''}
                            width="100%"
                            onChange={(e) => handleChangeStatusInSelect(e.target.value as AllowedStatusSelectOption)}
                            variant="filled"
                        />
                        <Box
                            css={css`
                                display: flex;
                                margin-top: ${spacing.space16px};
                            `}
                        >
                            <Button
                                variant="contained"
                                css={css`
                                    margin-left: auto;
                                `}
                                onClick={handleChangeStatus}
                            >
                                {translate('change-status-modal.change-status-button-label')}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box
                        css={css`
                            display: flex;
                            flex-flow: column nowrap;
                            gap: ${spacing.space12px};
                        `}
                    >
                        <Box>{translate('choose-recipient-form.description')}</Box>
                        <SelectSequence
                            width="100%"
                            label={translate('choose-recipient-form.input-label')}
                            name="seqSelect"
                            options={recipients
                                .filter(
                                    (recipient) =>
                                        recipient.status !== RecipientStatusEnum.MeetingBooked &&
                                        recipient.status !== RecipientStatusEnum.Unsubscribed
                                )
                                .map((recipient) => ({
                                    value: recipient.sequence.id,
                                    label: recipient.sequence.title,
                                }))}
                            value={selectedSequence ?? NO_SEQUENCE}
                            onChange={handleSequenceChange}
                            noOptionsText={translate('choose-recipient-form.no-sequence-found')}
                        />
                        <Box
                            css={css`
                                display: flex;
                                margin-top: ${spacing.space16px};
                                justify-content: flex-end;
                                gap: ${spacing.space10px};
                            `}
                        >
                            <Button onClick={handleClose} variant="outlined" color="inherit">
                                {translate('choose-recipient-form.button-cancel')}
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!selectedSequence || selectedSequence === NO_SEQUENCE}
                                onClick={handleConfirmSequence}
                            >
                                {translate('choose-recipient-form.button-next')}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export { ChangeRecipientStatusModal };
