import styled from '@emotion/styled';
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import React from 'react';

import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface AlertProps extends MuiAlertProps {}

const Alert: FC<AlertProps> = React.forwardRef((props, ref) => <MuiAlert variant="filled" ref={ref} {...props} />);

const StyledAlert = styled(Alert)`
    font-size: ${fontSizes.f16};
    font-weight: ${fontWeights.semiBold};

    .MuiAlert-action {
        padding-top: 0px;
        align-self: center;
    }
`;

export { StyledAlert as Alert };
