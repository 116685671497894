import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { CreateStagesFormButtons } from 'sequences/components/composite';
import { CreateEditStagesTemplate } from 'sequences/components/templates';
import { CreateEditSequenceFormValues, CreateEditSequenceStagesFormValues } from 'sequences/types';
import { isMagicSentenceUsed } from 'sequences/utils';
import { Container } from 'shared/components/containers';
import { Form, TextField, SelectColor } from 'shared/components/form';
import { FormSection } from 'shared/components/presentational';
import { MagicSentenceContextProvider, SequenceStageOperationsContextProvider } from 'shared/hooks';
import { FC } from 'shared/types';

interface CreateStagesFormProps {
    onSaveAsDraft: () => void;
    onAddAdditionalStage: () => void;
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    stages: UseFieldArrayReturn<CreateEditSequenceFormValues, 'stages', 'id'>;
    onSubmit: () => void;
}

const CreateStagesForm: FC<CreateStagesFormProps> = (props) => {
    const { formMethods, stages, onAddAdditionalStage, onSaveAsDraft, onSubmit } = props;

    const translate = useTranslations('sequence.create');

    const [magicSentenceUsed, setMagicSentenceUsed] = useState<boolean>(false);

    const checkMagicSentenceUsed = () => {
        setMagicSentenceUsed(isMagicSentenceUsed(formMethods.getValues('stages')));
    };

    const handleRemoveStage = (selectedStageIndex: number) => {
        formMethods.setValue(
            'stages',
            [
                ...formMethods.getValues('stages').slice(0, selectedStageIndex),
                ...formMethods
                    .getValues('stages')
                    .slice(selectedStageIndex + 1, formMethods.getValues('stages').length),
            ],
            { shouldDirty: true }
        );
        checkMagicSentenceUsed();
    };

    return (
        <MagicSentenceContextProvider
            magicSentenceUsed={magicSentenceUsed}
            setMagicSentenceUsed={setMagicSentenceUsed}
            checkMagicSentenceUsed={checkMagicSentenceUsed}
        >
            <SequenceStageOperationsContextProvider handleRemoveStage={handleRemoveStage}>
                <Form onSubmit={onSubmit}>
                    <Container
                        css={css`
                            margin-bottom: 32px;
                            padding: 32px 32px;
                        `}
                    >
                        <FormSection title={translate('sequence-title')}>
                            <TextField
                                autoFocus
                                control={formMethods.control}
                                name="title"
                                label={translate('title')}
                                css={css`
                                    margin-right: 16px;
                                `}
                            />
                            <SelectColor name="color" formMethods={formMethods} />
                        </FormSection>
                    </Container>
                    <CreateEditStagesTemplate
                        formMethods={formMethods as unknown as UseFormReturn<CreateEditSequenceStagesFormValues>}
                        stages={
                            stages as unknown as UseFieldArrayReturn<CreateEditSequenceStagesFormValues, 'stages', 'id'>
                        }
                    />
                    <CreateStagesFormButtons
                        onAddAdditionalStage={onAddAdditionalStage}
                        onSaveAsDraft={onSaveAsDraft}
                    />
                </Form>
            </SequenceStageOperationsContextProvider>
        </MagicSentenceContextProvider>
    );
};

export { CreateStagesForm };
