/* eslint-disable no-magic-numbers */
import { NetworkStatus, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { escapeRegExp, isEqual, uniqBy, xor } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslations } from 'next-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    OrderBy,
    SequenceStatusEnum,
    SequencesBoolExp,
    SequencesOrderBy,
    SequencesOwnersQuery,
    SequencesOwnersQueryVariables,
    SequencesQuery,
    SequencesQueryVariables,
} from 'codegen/graphql';
import { SequenceListEditBar, SequenceListRow, SequenceListTabs } from 'sequences/components/tables';
import { SequenceTab } from 'sequences/types';
import { Box } from 'shared/components/containers';
import {
    Button,
    Checkbox,
    CheckboxFilter,
    FilterBarDescription,
    FilterTextField,
    PercentageRangeFilter,
    Snackbar,
    percentageSliderRange,
} from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from 'shared/components/table';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import {
    GET_SEQUENCES,
    GET_USER_SEQUENCES_OWNERS,
    SequencesProps,
    sequencesDefaultOrderBy,
    sequencesDefaultOrderByDirection,
    sequencesDefaultVariables,
} from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC, NestedKeys } from 'shared/types';

interface Header {
    label:
        | 'title-header'
        | 'added-header'
        | 'active-header'
        | 'finished-header'
        | 'opens-header'
        | 'replies-header'
        | 'interested-header'
        | 'date-created-header';
    field: SequenceData;
    width: string;
    twoLines?: boolean;
}
type SequenceData = NestedKeys<SequencesQuery['sequences'][number]>;
type OrderByColumn = Header['label'];

const headers: Header[] = [
    { label: 'title-header', field: 'title', width: '33%' },
    { label: 'added-header', field: 'stats.added', width: '7%', twoLines: true },
    { label: 'active-header', field: 'stats.active', width: '7%', twoLines: true },
    { label: 'finished-header', field: 'stats.finished', width: '7%', twoLines: true },
    { label: 'opens-header', field: 'stats.opensPercentage', width: '7%' },
    { label: 'replies-header', field: 'stats.repliesPercentage', width: '7%' },
    { label: 'interested-header', field: 'stats.interestedPercentage', width: '7%' },
    { label: 'date-created-header', field: 'createdAt', width: '25%' },
];
// eslint-disable-next-line no-magic-numbers
const defaultRowsPerPageOptions = [10, 25, 50, 100];
const defaultOrderByDirection = OrderBy.Desc;
const filterTextDebounceTime = 500; // ms

const formatPercentageStat = (value?: number | null) => `${value != null ? Math.round(value * 100) : 0}%`;

interface SequenceListTableProps extends SequencesProps {}

const SequenceListTable: FC<SequenceListTableProps> = ({ initialData }) => {
    const { session, loaded } = useSession();
    const translate = useTranslations('sequence.sequence-list-table');

    const [offset, setOffset] = useState(sequencesDefaultVariables.offset);
    const [limit, setLimit] = useState(sequencesDefaultVariables.limit);

    const defaultStatusFilter = [
        SequenceStatusEnum.Active,
        SequenceStatusEnum.EmailDisconnected,
        SequenceStatusEnum.Ready,
        SequenceStatusEnum.Draft,
    ];
    const [statusFilters, setStatusFilters] = useState<SequenceStatusEnum[]>(defaultStatusFilter);

    const [opensRange, setOpensRange] = useState(percentageSliderRange);
    const isOpensFilterApplied =
        opensRange[0] !== percentageSliderRange[0] || opensRange[1] !== percentageSliderRange[1];

    const [repliesRange, setRepliesRange] = useState(percentageSliderRange);
    const isRepliesFilterApplied =
        repliesRange[0] !== percentageSliderRange[0] || repliesRange[1] !== percentageSliderRange[1];

    const [interestedRange, setInterestedRange] = useState(percentageSliderRange);
    const isInterestedFilterApplied =
        interestedRange[0] !== percentageSliderRange[0] || interestedRange[1] !== percentageSliderRange[1];

    const [ownerFilters, setOwnerFilters] = useState<string[]>([]);
    const isOwnerFilterApplied = ownerFilters.length > 0;

    const [keywordFilter, setKeywordFilter] = useState('');
    const isKeywordFilterApplied = keywordFilter !== '';

    const areFiltersApplied =
        isOpensFilterApplied ||
        isRepliesFilterApplied ||
        isInterestedFilterApplied ||
        isOwnerFilterApplied ||
        isKeywordFilterApplied;

    const [textFilter, setTextFilter] = useState<SequencesBoolExp[]>([{}]);
    const debouncedSetTextFilter = useMemo(() => debounce(setTextFilter, filterTextDebounceTime), []);
    useEffect(() => {
        const regexText = `%${escapeRegExp(keywordFilter)}%`;
        debouncedSetTextFilter(
            isKeywordFilterApplied
                ? [
                      {
                          user: {
                              fullName: {
                                  _ilike: regexText,
                              },
                          },
                      },
                      {
                          title: {
                              _ilike: regexText,
                          },
                      },
                  ]
                : [{}]
        );
    }, [keywordFilter, isKeywordFilterApplied, debouncedSetTextFilter]);

    const [orderBy, setOrderBy] = useState<OrderByColumn>(
        headers.find((h) => h.field === sequencesDefaultOrderBy)?.label || 'date-created-header'
    );
    const [orderByDirection, setOrderByDirection] = useState<OrderBy.Asc | OrderBy.Desc>(
        sequencesDefaultOrderByDirection
    );

    const convertHeaderFieldToOrderByFilter = useCallback(
        (field: SequenceData): SequencesOrderBy => {
            switch (field) {
                case 'stats.added':
                    return { stats_aggregate: { sum: { added: orderByDirection } } };
                case 'stats.active':
                    return { stats_aggregate: { sum: { active: orderByDirection } } };
                case 'stats.finished':
                    return { stats_aggregate: { sum: { finished: orderByDirection } } };
                case 'stats.opensPercentage':
                    return { stats_aggregate: { sum: { opensPercentage: orderByDirection } } };
                case 'stats.repliesPercentage':
                    return { stats_aggregate: { sum: { repliesPercentage: orderByDirection } } };
                case 'stats.interestedPercentage':
                    return { stats_aggregate: { sum: { interestedPercentage: orderByDirection } } };
                default:
                    return { [sequencesDefaultOrderBy]: orderByDirection };
            }
        },
        [orderByDirection]
    );

    const getSequencesVariables = useMemo(() => {
        const filters: SequencesBoolExp[] = [
            {
                _or: [{ userId: { _eq: session?.user.id } }, { collaborators: { userId: { _eq: session?.user.id } } }],
            },
            { status: { _in: statusFilters } },
        ];

        if (isOwnerFilterApplied) {
            filters.push({ userId: { _in: ownerFilters } });
        }

        if (isKeywordFilterApplied) {
            filters.push({ _or: textFilter });
        }

        const statsFilter = [];

        if (isOpensFilterApplied) {
            statsFilter.push({ opensPercentage: { _gte: opensRange[0] / 100, _lte: opensRange[1] / 100 } });
        }

        if (isRepliesFilterApplied) {
            statsFilter.push({ repliesPercentage: { _gte: repliesRange[0] / 100, _lte: repliesRange[1] / 100 } });
        }

        if (isInterestedFilterApplied) {
            statsFilter.push({
                interestedPercentage: { _gte: interestedRange[0] / 100, _lte: interestedRange[1] / 100 },
            });
        }

        if (statsFilter.length > 0) {
            filters.push({ stats: { _and: statsFilter } });
        }

        return {
            ...sequencesDefaultVariables,
            orderBy: {
                ...convertHeaderFieldToOrderByFilter(headers.find((h) => h.label === orderBy)?.field),
            },
            offset,
            limit,
            where: {
                _and: filters,
            },
        };
    }, [
        convertHeaderFieldToOrderByFilter,
        interestedRange,
        isInterestedFilterApplied,
        isKeywordFilterApplied,
        isOpensFilterApplied,
        isOwnerFilterApplied,
        isRepliesFilterApplied,
        limit,
        offset,
        opensRange,
        orderBy,
        ownerFilters,
        repliesRange,
        session?.user.id,
        statusFilters,
        textFilter,
    ]);

    const { data, loading, networkStatus } = useQuery<SequencesQuery, SequencesQueryVariables>(GET_SEQUENCES, {
        skip: !loaded || !session,
        variables: getSequencesVariables,
        fetchPolicy: 'cache-and-network',
    });

    const count =
        networkStatus === NetworkStatus.loading
            ? initialData.count
            : data?.sequences_aggregate.aggregate?.count
            ? data.sequences_aggregate.aggregate.count
            : 0;

    const { data: ownersData } = useQuery<SequencesOwnersQuery, SequencesOwnersQueryVariables>(
        GET_USER_SEQUENCES_OWNERS,
        {
            skip: !loaded || !session,
            variables: { userId: session?.user.id },
        }
    );

    const [selected, setSelected] = useState<Set<string>>(new Set());

    useEffect(() => {
        setOffset(0);
        setSelected(new Set());
    }, [statusFilters, opensRange, repliesRange, interestedRange, textFilter]);

    const handleSelectAll = async () => {
        const subset = new Set(data?.sequences.map((s) => s.id));
        const intersectionSet = new Set([...selected].filter((x) => subset.has(x)));
        if (intersectionSet.size !== subset.size) {
            setSelected((prev) => new Set([...prev, ...subset]));
        } else {
            const differenceSet = new Set([...selected].filter((x) => !subset.has(x)));
            setSelected(differenceSet);
        }
    };

    const handleEditBarClose = () => {
        setSelected(new Set());
    };

    const handleToggleSelect = (checked: boolean, sequenceId: string) => {
        if (checked) {
            setSelected((prev) => new Set([...prev, sequenceId]));
        } else {
            setSelected((prev) => {
                const next = new Set(prev);
                next.delete(sequenceId);
                return next;
            });
        }
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setOffset(newPage * limit);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setOffset(0);
        setLimit(newRowsPerPage);
    };

    const createSortHandler = (col: OrderByColumn) => () => {
        const isDesc = orderBy === col && orderByDirection === OrderBy.Desc;
        const direction = isDesc ? OrderBy.Asc : OrderBy.Desc;
        setOrderByDirection(direction);
        setOrderBy(col);
    };

    const handleOpensRangeChange = (newOpensRange: [number, number]) => {
        setOpensRange(newOpensRange);
    };

    const handleRepliesRangeChange = (newRepliesRange: [number, number]) => {
        setRepliesRange(newRepliesRange);
    };

    const handleInterestedRangeChange = (newInterestedRange: [number, number]) => {
        setInterestedRange(newInterestedRange);
    };

    const handleKeywordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeywordFilter(newKeyword);
    };

    const handleClearAllFilters = () => {
        setOpensRange(percentageSliderRange);
        setRepliesRange(percentageSliderRange);
        setInterestedRange(percentageSliderRange);
        setOwnerFilters([]);
        setKeywordFilter('');
    };

    const computeFilterDescription = () => {
        if (!areFiltersApplied) {
            // only show the count if not loading
            if (loading) return translate('no-filters-applied-description');
            return `${translate('no-filters-applied-description')}${translate('filter-description-count', { count })}`;
        }

        // eslint-disable-next-line react/no-unstable-nested-components
        const BoldSpan: FC<unknown> = ({ children }) => (
            <Box
                component="span"
                css={css`
                    font-weight: ${fontWeights.bold};
                    text-overflow: ellipsis;
                `}
            >
                {children}
            </Box>
        );
        const bold = (children: React.ReactNode) => <BoldSpan>{children}</BoldSpan>;

        const filters = [];
        if (isOpensFilterApplied) {
            filters.push(
                translate.rich('opens-filter-description', {
                    start: formatPercentageStat(opensRange[0] / 100),
                    end: formatPercentageStat(opensRange[1] / 100),
                    bold,
                })
            );
        }
        if (isRepliesFilterApplied) {
            filters.push(
                translate.rich('replies-filter-description', {
                    start: formatPercentageStat(repliesRange[0] / 100),
                    end: formatPercentageStat(repliesRange[1] / 100),
                    bold,
                })
            );
        }
        if (isInterestedFilterApplied) {
            filters.push(
                translate.rich('interested-filter-description', {
                    start: formatPercentageStat(interestedRange[0] / 100),
                    end: formatPercentageStat(interestedRange[1] / 100),
                    bold,
                })
            );
        }

        if (isOwnerFilterApplied) {
            filters.push(
                translate.rich('owner-filter-description', {
                    owners: ownerFilters
                        .map((ownerId) => ownerOptions.find((ownerOption) => ownerOption.value === ownerId)?.label)
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }

        if (isKeywordFilterApplied) {
            filters.push(
                translate.rich('keyword-filter-description', {
                    index: filters.length,
                    keyword: keywordFilter,
                    bold,
                })
            );
        }

        return (
            <Box
                css={css`
                    display: flex;
                `}
            >
                <FilterBarDescription>{filters}</FilterBarDescription>
                <Box
                    css={css`
                        white-space: pre;
                    `}
                >
                    {!loading ? translate('filter-description-count', { count }) : ''}
                </Box>
            </Box>
        );
    };

    const handleOwnerCheckboxClick = (value: string) => () => setOwnerFilters(xor(ownerFilters, [value]));
    const handleClearOwnerFilter = () => setOwnerFilters([]);
    const ownerOptions = useMemo(
        () =>
            uniqBy(
                ownersData?.sequences.map((sequence) => ({
                    label: sequence.user.fullName,
                    value: sequence.user.id,
                })) ?? [],
                (option) => option.value
            ),
        [ownersData]
    );

    const filterBar = (
        <Box>
            <Box
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: ${spacing.space8px};
                    gap: ${spacing.space8px};
                `}
            >
                <PercentageRangeFilter
                    label={translate('opens-filter-label')}
                    range={opensRange}
                    onRangeChange={handleOpensRangeChange}
                />
                <PercentageRangeFilter
                    label={translate('replies-filter-label')}
                    range={repliesRange}
                    onRangeChange={handleRepliesRangeChange}
                />
                <PercentageRangeFilter
                    label={translate('interested-filter-label')}
                    range={interestedRange}
                    onRangeChange={handleInterestedRangeChange}
                />
                <CheckboxFilter
                    label={translate('owner-filter-label')}
                    allOptions={ownerOptions}
                    checkedOptions={ownerFilters}
                    onCheckboxClick={handleOwnerCheckboxClick}
                    onClearFilter={handleClearOwnerFilter}
                    showOptionFilter
                />
                <FilterTextField value={keywordFilter} onChange={handleKeywordFilterChange} />
            </Box>
            <Box
                css={css`
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) min-content;
                    column-gap: ${spacing.space16px};
                    align-items: center;
                    width: 100%;
                    margin-bottom: 4px;
                `}
            >
                {computeFilterDescription()}
                <Button
                    css={css`
                        font-size: ${fontSizes.f16};
                        font-weight: ${fontWeights.bold};
                        white-space: nowrap;

                        margin-left: auto;
                        padding: 0;
                        opacity: 0;

                        transition: opacity 0.4s ease-in-out;
                        ${areFiltersApplied && `opacity: 1;`}
                    `}
                    variant="text"
                    startIcon={<XClose />}
                    onClick={handleClearAllFilters}
                    disableRipple
                >
                    {translate('clear-all-filters-label')}
                </Button>
            </Box>
        </Box>
    );

    const tableHeaders = (
        <TableRow>
            {headers.map(({ label, width, twoLines }) => {
                let align: 'flex-start' | 'center' = 'flex-start';
                let left: string = 'inherit';
                if (
                    [
                        'added-header',
                        'active-header',
                        'finished-header',
                        'opens-header',
                        'replies-header',
                        'interested-header',
                    ].includes(label)
                ) {
                    align = 'center';
                    left = '1em';
                }
                return (
                    <TableCell
                        key={label}
                        width={width}
                        css={css`
                            ${twoLines && `min-width: 100px; padding: 9px;`}
                        `}
                    >
                        <Box
                            css={css`
                                display: flex;
                                justify-content: ${align};
                            `}
                        >
                            {label === 'title-header' && (
                                <Checkbox
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                    indeterminate={selected.size > 0 && selected.size < count}
                                    checked={count > 0 && selected.size === count}
                                    onChange={handleSelectAll}
                                />
                            )}

                            <TableSortLabel
                                active={label === orderBy}
                                direction={label === orderBy ? orderByDirection : defaultOrderByDirection}
                                onClick={createSortHandler(label)}
                                css={css`
                                    flex-grow: 1;
                                    left: ${left};
                                    justify-content: ${align};
                                    white-space: nowrap;
                                    ${twoLines &&
                                    ` 
                                       text-align: center;
                                        white-space: normal;
                                    `}
                                `}
                            >
                                {translate(label)}
                            </TableSortLabel>
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );

    const tableBody = (networkStatus === NetworkStatus.loading ? initialData.data : data?.sequences)?.map(
        (sequence) => (
            <SequenceListRow
                key={sequence.id}
                sequence={sequence}
                isSelected={selected.has(sequence.id)}
                onToggleSelect={handleToggleSelect}
            />
        )
    );

    const showTableBody: boolean | undefined = tableBody && tableBody.length > 0;

    const activeFilter = [SequenceStatusEnum.Active];
    const emailDisconnectedFilter = [SequenceStatusEnum.EmailDisconnected];
    const readyFilter = [SequenceStatusEnum.Ready];
    const draftFilter = [SequenceStatusEnum.Draft];
    const pausedFilter = [SequenceStatusEnum.Paused];
    const archivedFilter = [SequenceStatusEnum.Archived];
    const allStatusFilter = [
        SequenceStatusEnum.Active,
        SequenceStatusEnum.EmailDisconnected,
        SequenceStatusEnum.Ready,
        SequenceStatusEnum.Draft,
        SequenceStatusEnum.Paused,
        SequenceStatusEnum.Archived,
    ];

    const handleTabChange = (_: any, newValue: string) => {
        switch (newValue) {
            case SequenceStatusEnum.Active:
                // active, email disconnected, ready or draft
                setStatusFilters([...activeFilter, ...emailDisconnectedFilter, ...readyFilter, ...draftFilter]);
                break;
            case SequenceStatusEnum.Draft:
                setStatusFilters(draftFilter);
                break;
            case SequenceStatusEnum.Paused:
                setStatusFilters(pausedFilter);
                break;
            case SequenceStatusEnum.Archived:
                setStatusFilters(archivedFilter);
                break;
            case 'all':
                setStatusFilters(allStatusFilter);
                break;
            default:
                break;
        }
        setSelected(new Set()); // Unselecting toggled rows on tab change
    };

    let selectedTab: SequenceTab;
    if (isEqual(statusFilters, [...activeFilter, ...emailDisconnectedFilter, ...readyFilter, ...draftFilter]))
        selectedTab = SequenceStatusEnum.Active;
    else if (isEqual(statusFilters, draftFilter)) selectedTab = SequenceStatusEnum.Draft;
    else if (isEqual(statusFilters, pausedFilter)) selectedTab = SequenceStatusEnum.Paused;
    else if (isEqual(statusFilters, archivedFilter)) selectedTab = SequenceStatusEnum.Archived;
    else selectedTab = 'all';

    return (
        <>
            <SequenceListTabs selectedTab={selectedTab} onTabChange={handleTabChange} />
            {filterBar}
            <TableContainer
                css={css`
                    margin-bottom: 8px;
                    max-width: ${appMaxWidth};

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <Table>
                    <TableHead>{tableHeaders}</TableHead>
                    <TableBody
                        colSpan={headers.length}
                        hidden={!showTableBody}
                        loading={loading}
                        clearFilters={handleClearAllFilters}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                // @ts-expect-error
                component="div"
                page={offset / limit}
                rowsPerPage={limit}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {!(statusFilters.includes(SequenceStatusEnum.Archived) && statusFilters.length === 1) ? (
                <Snackbar
                    open={selected.size > 0}
                    autoHideDuration={undefined}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <SequenceListEditBar sequenceIds={[...selected]} tab={selectedTab} onClose={handleEditBarClose} />
                </Snackbar>
            ) : null}
        </>
    );
};

export { SequenceListTable, formatPercentageStat };
export type { SequenceListTableProps };
