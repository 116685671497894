import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { SequenceByIdQuery, SequenceByIdQueryVariables } from 'codegen/graphql';
import { SequenceStagesPreview } from 'sequences/components/composite';
import { CreateEditSequenceStagesFormValues, Stage } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { SelectOption, SelectSequence } from 'shared/components/form';
import { LoadingButton } from 'shared/components/presentational';
import { GET_SEQUENCE_BY_ID, NO_SEQUENCE } from 'shared/graphql/sequences';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ChooseSequenceFormProps {
    sequenceOptions: SelectOption[];
    selectedProspectsCount: number;
    onSequenceSend: () => void;
    selected: string;
    handleSequenceChange: (value: string) => void;
    loading: boolean;
}

const ChooseSequenceForm: FC<ChooseSequenceFormProps> = ({
    sequenceOptions,
    selectedProspectsCount,
    onSequenceSend,
    selected,
    handleSequenceChange: handleSequenceSelect,
    loading,
}) => {
    const translate = useTranslations(
        'project.prospect-list-table.edit-bar.add-to-sequence-modal.choose-sequence-form'
    );

    const [getSequenceById] = useLazyQuery<SequenceByIdQuery, SequenceByIdQueryVariables>(GET_SEQUENCE_BY_ID);

    const validationSchema = Yup.object().shape({
        stages: Yup.array().of(Yup.object().shape({})),
    });

    const formMethods = useForm<CreateEditSequenceStagesFormValues>({
        mode: 'onTouched',
        defaultValues: { stages: [] },
        resolver: yupResolver(validationSchema),
    });

    const stages = useFieldArray({
        control: formMethods.control,
        name: 'stages',
    });

    const fetchStagesData = async () => {
        const { data } = await getSequenceById({
            variables: {
                id: selected,
            },
        });
        if (!data?.sequences_by_pk?.stages) return;
        formMethods.setValue('stages', data.sequences_by_pk.stages as Stage[]);
    };

    useEffect(() => {
        if (!selected || selected === NO_SEQUENCE) {
            formMethods.setValue('stages', []);
            return;
        }
        fetchStagesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <>
            <SelectSequence
                width="100%"
                label={translate('input-label')}
                name="seqSelect"
                options={sequenceOptions}
                value={selected}
                onChange={handleSequenceSelect}
                noOptionsText={translate('no-sequence-found')}
            />
            <SequenceStagesPreview formMethods={formMethods} stages={stages} />
            <Box
                css={css`
                    display: flex;
                    margin-top: ${spacing.space16px};
                `}
            >
                <LoadingButton
                    variant="contained"
                    css={css`
                        margin-left: auto;
                    `}
                    disabled={!selected || selected === NO_SEQUENCE}
                    onClick={onSequenceSend}
                    loading={loading}
                >
                    {translate('button-label', {
                        count: selectedProspectsCount,
                    })}
                </LoadingButton>
            </Box>
        </>
    );
};

export { ChooseSequenceForm };
