import { Box, css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { EditForm } from 'integrations/components/forms/ashby';
import { ConnectedStatus } from 'integrations/components/presentational';
import { EditableAtsSection } from 'settings/components/containers';
import { AshbyAccountByIdData } from 'shared/graphql/integrations';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AshbySettingsProps {
    integration?: AshbyAccountByIdData;
    refetch: () => void;
}

const AshbySettings: FC<AshbySettingsProps> = ({ integration, refetch }) => {
    const translate = useTranslations('settings');
    const theme = useTheme();

    const [isEditing, setIsEditing] = useState(false);

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleClose = () => {
        setIsEditing(false);
    };

    return (
        <EditableAtsSection
            title={translate('account-settings.ashby-settings.title')}
            isEditing={isEditing}
            canEdit={!!integration}
            onToggleEdit={handleToggleEdit}
        >
            <Box
                css={css`
                    color: ${theme.palette.grey[200]};
                    display: flex;
                    align-items: center;
                    column-gap: ${spacing.space8px};
                `}
            >
                {!isEditing ? <ConnectedStatus connected={!!integration} /> : null}
                {isEditing && integration ? (
                    <EditForm ashbyAccount={integration} onClose={handleClose} refetch={refetch} />
                ) : null}
            </Box>
        </EditableAtsSection>
    );
};

export { AshbySettings };
