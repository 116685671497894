import { useQuery } from '@apollo/client';
import { SubscriptionInfo } from 'hireflow-shared/types/stripe/subscription-details';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import {
    HireflowEnumsTeamFeaturesEnum,
    PlanTypesEnum,
    SequencesOwnedByStatusQuery,
    SequencesOwnedByStatusQueryVariables,
    TeamFeaturesByTeamIdFeatureQuery,
    TeamFeaturesByTeamIdFeatureQueryVariables,
    UserOwnedProjectsByStatusQuery,
    UserOwnedProjectsByStatusQueryVariables,
} from 'codegen/graphql';
import {
    EnterprisePlan,
    NonEnterprisePlan,
} from 'settings/components/presentational/plans-and-billing/plan-privileges';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { GET_USER_OWNED_PROJECTS_COUNT_BY_STATUS } from 'shared/graphql/projects';
import { GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS } from 'shared/graphql/sequences';
import { GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE } from 'shared/graphql/teams';
import { useSession } from 'shared/hooks';
import { createRestApiClient, stripeDateTimeFormatter } from 'shared/services';
import { FC, UserTeam } from 'shared/types';

interface PlansAndBillingProps {
    team: UserTeam;
}

const PlansAndBilling: FC<PlansAndBillingProps> = (props) => {
    const { team } = props;
    const { session, loaded } = useSession();
    const translate = useTranslations('settings.plans-and-billing-settings');

    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>();

    const [activeProjects, setActiveProjects] = useState<number>(0);
    const [activeSequences, setActiveSequences] = useState<number>(0);

    const { planType, aiSourcerCredits } = team!;

    const restApiClient = createRestApiClient();

    useEffect(() => {
        const fetchSubscriptionInfo = async (subscriptionId: string) => {
            const subsData = await restApiClient.stripeSubscriptionDetails({
                subscriptionId,
            });
            setSubscriptionInfo(subsData?.data?.subscriptionInfo);
        };
        if (team?.subscriptionId) {
            fetchSubscriptionInfo(team.subscriptionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.subscriptionId]);

    const { data: projectsData } = useQuery<UserOwnedProjectsByStatusQuery, UserOwnedProjectsByStatusQueryVariables>(
        GET_USER_OWNED_PROJECTS_COUNT_BY_STATUS,
        {
            skip: !loaded,
            fetchPolicy: 'cache-and-network',
            variables: { currentUserId: session?.user.id },
        }
    );

    const { data: sequenceData } = useQuery<SequencesOwnedByStatusQuery, SequencesOwnedByStatusQueryVariables>(
        GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
        {
            skip: !loaded,
            variables: { userId: session?.user.id },
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: teamFeaturesData } = useQuery<
        TeamFeaturesByTeamIdFeatureQuery,
        TeamFeaturesByTeamIdFeatureQueryVariables
    >(GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE, {
        variables: {
            teamId: session?.user.teamId ?? '',
            feature: HireflowEnumsTeamFeaturesEnum.AiSourcingPayPerSourcer,
        },
        skip: !loaded,
    });

    const isPayPerSourcer = !!teamFeaturesData?.data.length;

    useEffect(() => {
        if (projectsData) {
            setActiveProjects(projectsData.active.aggregate!.count + projectsData.draft.aggregate!.count);
        }
    }, [projectsData]);

    useEffect(() => {
        if (sequenceData) {
            setActiveSequences(
                sequenceData.active.aggregate!.count +
                    sequenceData.draft.aggregate!.count +
                    sequenceData.emailDisconnected.aggregate!.count +
                    sequenceData.paused.aggregate!.count +
                    sequenceData.ready.aggregate!.count
            );
        }
    }, [sequenceData]);

    const refreshMessage = subscriptionInfo
        ? subscriptionInfo.trial
            ? translate('trial-ends-on', { date: stripeDateTimeFormatter(subscriptionInfo.currentEndDate) })
            : subscriptionInfo.interval === 'year'
            ? translate('refresh-occurs-yearly', {
                  // cSpell:disable-next-line
                  date: DateTime.fromSeconds(subscriptionInfo.currentEndDate).toFormat("MMMM d'th'"),
              })
            : translate('refresh-occurs-monthly', {
                  // cSpell:disable-next-line
                  date: DateTime.fromSeconds(subscriptionInfo.currentEndDate).toFormat("d'th'"),
              })
        : undefined;

    return projectsData && sequenceData && teamFeaturesData ? (
        <Box>
            <Title type="h2">{translate('plans-and-billing')}</Title>
            {[PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) ? (
                <EnterprisePlan
                    planType={planType}
                    message={refreshMessage}
                    aiSourcerCredits={aiSourcerCredits}
                    showAISourcerCredits={!isPayPerSourcer}
                />
            ) : (
                <NonEnterprisePlan
                    planType={planType}
                    activeProjects={activeProjects}
                    activeSequences={activeSequences}
                    aiSourcerCredits={aiSourcerCredits}
                    message={refreshMessage}
                    showAISourcerCredits={!isPayPerSourcer}
                />
            )}
        </Box>
    ) : null;
};

export { PlansAndBilling };
