import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedMinus: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg {...rest} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 12H19"
            stroke={stroke ?? theme?.palette.secondary.contrastText}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Minus = withTheme(ThemedMinus);

export { Minus };
