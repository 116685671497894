import styled from '@emotion/styled';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';

import { FC } from 'shared/types';

interface IconButtonProps extends MuiIconButtonProps {}

const IconButton: FC<IconButtonProps> = (props) => <MuiIconButton {...props} />;

const StyledIconButton = styled(IconButton)``;

export { StyledIconButton as IconButton };
