import { gql } from '@apollo/client';

import { PROFILE_DETAILS } from 'shared/graphql/profile';
import { PROJECT_MEMBER_DETAILS } from 'shared/graphql/project-members';
import { PROJECT_MEMBER_ACTIVITY } from 'shared/graphql/project-members-activities';
import { RECIPIENT_ACTIVITY } from 'shared/graphql/recipient-activities';
import { SOURCER_MEMBER_DETAILS } from 'shared/graphql/sourcer-members';

export const RECIPIENT_MAIN_DETAILS = gql`
    fragment RecipientMainDetails on recipients {
        id
        prospectId
        status
        nextMessageScheduledAt
        lastStageSent
        lastStageSentAt
        completedAt
        manuallyExitedAt
        respondedAt
        meetingScheduledAt
        createdAt
        recipientResponses: recipient_responses(order_by: { createdAt: desc }) {
            id
            sentiment
            createdAt
        }
        sequence {
            id
            title
            userId
            modifiedAt
            createdAt
            status
            collaborators {
                id
                userId
            }
        }
        contacts {
            id
            recipientId
            contactId
            channel
            createdAt
            modifiedAt
        }
        prospect {
            id
            profile {
                ...ProfileDetails
            }
            urls {
                url
            }
            contacts {
                id
                kind
                value
                standardizedValue
                channel
                primary
            }
            createdAt
        }
        user {
            id
            fullName
        }
    }
    ${PROFILE_DETAILS}
`;

export const PROSPECT_PROFILE_INFO = gql`
    fragment ProspectProfileInfo on prospects {
        id
        inMailSent
        inMailSentTime
        profile {
            ...ProfileDetails
        }
        urls {
            url
        }
        createdAt
        personalFindingCompletedAt
    }
    ${PROFILE_DETAILS}
`;

export const GET_PROSPECTS = gql`
    query Prospects(
        $where: prospects_bool_exp = {}
        $limit: Int = 100
        $offset: Int = 0
        $order_by: [prospects_order_by!] = { createdAt: desc }
        $withProfile: Boolean = false
        $withCustomMessages: Boolean = false
    ) {
        prospects(order_by: $order_by, limit: $limit, offset: $offset, where: $where) {
            id
            createdAt
            urls {
                url
            }
            profile @include(if: $withProfile) {
                ...ProfileDetails
                experiences: experience {
                    companyName
                    title
                    current
                    startDateMonth
                    startDateYear
                    endDateMonth
                    endDateYear
                    companyLogo
                    companyId
                    companyUrl
                }
                educations: education {
                    id
                    degree
                    fieldOfStudy
                    schoolName
                    startDateYear
                    endDateYear
                    schoolId
                    schoolUrl
                }
            }
            customMessages @include(if: $withCustomMessages) {
                id
                prospectId
                sequenceId
                customMessage
            }
        }
    }
    ${PROFILE_DETAILS}
`;

export const GET_PROSPECTS_URLS = gql`
    query ProspectsUrls($prospectIds: [String!]!) {
        prospects(where: { id: { _in: $prospectIds } }) {
            id
            urls {
                url
            }
        }
    }
    ${PROFILE_DETAILS}
`;

export const CREATE_PROSPECT = gql`
    mutation CreateProspect($prospect: prospects_insert_input!) {
        insert_prospects(objects: [$prospect]) {
            affected_rows
            returning {
                id
                profile {
                    id
                }
                createdAt
            }
        }
    }
`;

export const UPDATE_PROSPECT = gql`
    mutation UpdateProspect($id: String!, $set: prospects_set_input!) {
        update_prospects_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
            profile {
                id
            }
        }
    }
`;

export const ADD_OR_UPDATE_PROSPECT = gql`
    mutation AddOrUpdateProspect($data: jsonb!) {
        add_prospect(args: { data: $data }) {
            id
        }
    }
`;

export const IMPORT_PROSPECTS = gql`
    mutation ImportProspect($data: jsonb!, $project: String!) {
        import_prospect(args: { data: $data, project: $project }) {
            added
            updated
        }
    }
`;

export const GET_PROSPECT_PANEL_INFO = gql`
    query ProspectPanelInfo($url: String!) {
        prospects(where: { profile: { urls: { url: { _eq: $url } } } }) {
            ...ProspectProfileInfo
            contacts {
                id
                prospectId
                kind
                channel
                value
                standardizedValue
                primary
                invalid
                userMarkedInvalid
            }
            customMessages {
                id
                prospectId
                sequenceId
                customMessage
            }
            customMessages {
                id
                prospectId
                sequenceId
                customMessage
            }
        }
        project_members(
            where: { prospect: { profile: { urls: { url: { _eq: $url } } } } }
            order_by: { createdAt: desc }
        ) {
            ...ProjectMemberDetails
        }
        recipients(where: { prospect: { profile: { urls: { url: { _eq: $url } } } } }, order_by: { createdAt: desc }) {
            ...RecipientMainDetails
        }
        sourcer_members(where: { urls: { url: { _eq: $url } } }, order_by: { addedAt: desc }) {
            sourcer {
                id
                userId
                title
                status
                creditsPerApproval
                deductCustomCredits
                collaborators {
                    id
                    userId
                    type
                }
            }
            ...SourcerMemberDetails
        }
        recipient_activities(
            where: { recipient: { prospect: { profile: { urls: { url: { _eq: $url } } } } } }
            limit: 2000
            offset: 0
            order_by: { createdAt: desc }
        ) {
            ...RecipientActivity
        }

        project_member_activities(
            where: { prospect: { profile: { urls: { url: { _eq: $url } } } } }
            limit: 2000
            offset: 0
            order_by: { createdAt: desc }
        ) {
            ...ProjectMemberActivity
        }
    }
    ${PROSPECT_PROFILE_INFO}
    ${PROJECT_MEMBER_DETAILS}
    ${RECIPIENT_ACTIVITY}
    ${PROJECT_MEMBER_ACTIVITY}
    ${RECIPIENT_MAIN_DETAILS}
    ${SOURCER_MEMBER_DETAILS}
`;

export const PROSPECT_CONTACTS_PANEL_INFO = gql`
    query ProspectContactsPanelInfo($url: String!) {
        prospects(where: { profile: { urls: { url: { _eq: $url } } } }) {
            id
            personalFindingCompletedAt
            contacts {
                id
                prospectId
                kind
                channel
                value
                standardizedValue
                primary
                invalid
                userMarkedInvalid
            }
        }
    }
`;

export const SEARCH_PROSPECTS = gql`
    query SearchProspects($query: search_prospects_args!) {
        prospects: search_prospects(args: $query) {
            prospectId
            profileUrl
            fullName
            primaryEmailAddress
            company
            title
            location
            profileUrl
            latestSequenceId
            latestSequenceTitle
            latestSequenceStatus
            latestStageSent
            nextStageScheduledAt
            createdAt
            userId
            userFullName
        }
    }
`;

export const GET_SEARCH_PROSPECTS_RESULTS_COUNT = gql`
    query SearchProspectsResultsCount($query: search_prospects_args!) {
        prospects: search_prospects_aggregate(args: $query) {
            aggregate {
                count
            }
        }
    }
`;
