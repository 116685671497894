import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import partition from 'lodash/partition';
import uniqBy from 'lodash/uniqBy';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import {
    AddSendFromAccountsMutation,
    LicenseTypesEnum,
    PlanTypesEnum,
    RemoveEmailAccountMutation,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SendFromAccountStatusEnum,
} from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { MvpUpgradeFlow } from 'settings/components/presentational/account-and-emails';
import { Box } from 'shared/components/containers';
import { Option, SelectChip } from 'shared/components/form';
import {
    Button,
    Chip,
    Hyperlink,
    LearnMoreMessage,
    Modal,
    ModalCloseButton,
    Title,
} from 'shared/components/presentational';
import { AlertTriangle, Mail02, Minus, Nil, Plus } from 'shared/components/svgs';
import { SOBO_SUPPORT_ARTICLE_URL } from 'shared/constants';
import {
    ADD_SEND_FROM_ACCOUNTS,
    GET_SEND_FROM_ACCOUNTS,
    REMOVE_SEND_FROM_ACCOUNT,
} from 'shared/graphql/send-from-accounts';
import { useAccessControl, useConfirmationModal, useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SendFromAccountsProps {
    planType: PlanTypesEnum;
    licenseType: LicenseTypesEnum;
}

const SendFromAccounts: FC<SendFromAccountsProps> = (props) => {
    const { planType, licenseType } = props;
    const translate = useTranslations('settings.email-settings.send-from-accounts');
    const { session } = useSession();
    const theme = useTheme();
    const { showSnackbarAlert } = useSnackbarAlert();
    const { showModal, hideModal } = useConfirmationModal();
    const { canAccessSobo } = useAccessControl();

    const [isEditing, setIsEditing] = useState(false);
    const [showNewRequestModal, setShowNewRequestModal] = useState(false);
    const [requestEmail, setRequestEmail] = useState('');

    const [addSendFromAccounts] = useMutation<AddSendFromAccountsMutation>(ADD_SEND_FROM_ACCOUNTS);
    const [removeSendFromAccount] = useMutation<RemoveEmailAccountMutation>(REMOVE_SEND_FROM_ACCOUNT);

    const { previousData, data, refetch } = useQuery<SendFromAccountsQuery, SendFromAccountsQueryVariables>(
        GET_SEND_FROM_ACCOUNTS,
        {
            variables: {
                where: {
                    email_account: {
                        user: {
                            _and: [
                                { id: { _neq: session?.user.id } },
                                { licenseType: { _neq: LicenseTypesEnum.Reviewer } },
                            ],
                        },
                    },
                },
            },
        }
    );

    const canEditSoboSettings = canAccessSobo(planType, licenseType);

    const handleOpenNewRequestModal = () => {
        setShowNewRequestModal(true);
    };
    const handleCloseNewRequestModal = () => {
        setShowNewRequestModal(false);
        setRequestEmail('');
    };

    const handleToggleEdit = () => setIsEditing(!isEditing);

    const handleRequestEmailChange = (selectedOption: Option) => {
        setRequestEmail(selectedOption.value);
    };

    const handleRequestNewEmail = async () => {
        try {
            await addSendFromAccounts({ variables: { sendFromAccounts: { email: requestEmail } } });
            await refetch();
            handleCloseNewRequestModal();
            showSnackbarAlert({
                severity: 'success',
                message: translate('request-has-been-sent-alert', { email: requestEmail }),
            });
        } catch (e) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('request-unsuccessful-alert', { email: requestEmail }),
            });
        }
    };

    const createRemoveSendFromAccountConfirmationHandler = (accountId: string, email: string) => () => {
        const handleRemoveSendFromAccount = async () => {
            await removeSendFromAccount({ variables: { id: accountId } });
            await refetch();
            showSnackbarAlert({
                severity: 'success',
                message: translate('remove-success-alert', {
                    email,
                }),
            });
            hideModal();
        };

        showModal({
            title: translate('remove-send-from-account-modal.header'),
            description: translate('remove-send-from-account-modal.description'),
            confirmButton: {
                text: translate('remove-send-from-account-modal.remove-account-button-label'),
                onClick: handleRemoveSendFromAccount,
                variant: 'contained',
                color: 'error',
            },
            cancelButton: {
                text: translate('remove-send-from-account-modal.cancel-button-label'),
                onClick: hideModal,
                color: 'secondary',
            },
        });
    };

    const [requestedAccounts, availableAccounts] = partition(
        (data || previousData)?.send_from_accounts,
        (account) => account.user.id === session?.user.id && account.status !== SendFromAccountStatusEnum.Removed
    );

    const validRequestOptions = uniqBy(
        availableAccounts
            .filter(
                (account) =>
                    !requestedAccounts.find((ra) => ra.email === account.email) ||
                    account.status === SendFromAccountStatusEnum.Removed
            )
            .map(({ email, email_account }) => ({
                value: email,
                label: `${email} • ${email_account.user.fullName}`,
            })),
        'value'
    );

    const isValidRequestEmail = validRequestOptions.find((option) => option.value === requestEmail);
    const isGrowthPlan = planType === PlanTypesEnum.Enterprise || planType === PlanTypesEnum.EnterpriseFreeTrial;

    const nonEditableContent = (
        <Box>
            {requestedAccounts.length > 0
                ? requestedAccounts.map(({ email, email_account: { user }, status }) => (
                      <Box key={email}>
                          <Box
                              css={css`
                                  display: flex;
                                  align-items: center;
                              `}
                          >
                              <Box>{`${email} • ${user.fullName}`}</Box>
                              {status === SendFromAccountStatusEnum.PendingApproval && (
                                  <Chip
                                      label={translate('request-pending-chip')}
                                      css={css`
                                          margin-left: ${spacing.space8px};
                                          padding: 0 ${spacing.space8px};
                                          font-size: ${fontSizes.f12};
                                          .MuiChip-label {
                                              padding: 0;
                                              color: ${theme.palette.common.black};
                                          }
                                      `}
                                      size="small"
                                      color="info"
                                  />
                              )}
                          </Box>
                          {status === SendFromAccountStatusEnum.Disconnected && (
                              <Box
                                  css={css`
                                      margin-top: ${spacing.space14px};

                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;

                                      min-width: 580px;
                                      min-height: 76px;
                                      background-color: ${theme.palette.error.main};
                                      border-radius: ${spacing.space4px};
                                      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                                      padding: ${spacing.space16px} ${spacing.space24px};

                                      color: ${theme.palette.common.white};
                                      font-size: ${fontSizes.f16};
                                  `}
                              >
                                  <AlertTriangle height={20} width={20} stroke={theme.palette.common.white} />
                                  <Box
                                      css={css`
                                          margin-left: ${spacing.space12px};
                                      `}
                                  >
                                      {translate('account-disconnected-alert')}
                                  </Box>
                              </Box>
                          )}
                      </Box>
                  ))
                : null}
            <LearnMoreMessage
                icon={<Mail02 />}
                message={translate.rich('sobo-support', { link: SoboSupportArticleLink })}
            />
        </Box>
    );

    const editableContent = (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                row-gap: ${spacing.space32px};
            `}
        >
            <Box>
                {requestedAccounts.length > 0 ? (
                    requestedAccounts.map(({ id, email, email_account: { user }, status }) => (
                        <Box
                            key={email}
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <Box>{`${email} • ${user.fullName}`}</Box>
                            {status === SendFromAccountStatusEnum.PendingApproval && (
                                <Chip
                                    label={translate('request-pending-chip')}
                                    css={css`
                                        margin-left: ${spacing.space8px};
                                        padding: 0 ${spacing.space8px};
                                        font-size: ${fontSizes.f12};
                                        .MuiChip-label {
                                            padding: 0;
                                            color: ${theme.palette.common.black};
                                        }
                                    `}
                                    size="small"
                                    color="info"
                                />
                            )}
                            <Button
                                variant="text"
                                color="error"
                                css={css`
                                    margin-left: auto;
                                    padding: 0;
                                `}
                                disableRipple
                                onClick={createRemoveSendFromAccountConfirmationHandler(id, email)}
                                startIcon={<Minus stroke={theme.palette.error.main} />}
                            >
                                {translate('remove-button-label')}
                            </Button>
                        </Box>
                    ))
                ) : (
                    <Box
                        css={css`
                            color: ${theme.palette.grey[200]};
                            display: flex;
                            align-items: center;
                            column-gap: ${spacing.space8px};
                        `}
                    >
                        <Nil />
                        {translate('none-available')}
                    </Box>
                )}
            </Box>
            <Box
                css={css`
                    display: flex;
                `}
            >
                <Button
                    disabled={!canEditSoboSettings}
                    variant="outlined"
                    onClick={handleOpenNewRequestModal}
                    startIcon={<Plus />}
                    css={css`
                        margin-right: auto;
                    `}
                >
                    {translate('send-new-request-button-label')}
                </Button>
                <Button variant="contained" onClick={handleToggleEdit}>
                    {translate('done-button-label')}
                </Button>
            </Box>
        </Box>
    );

    return (
        <>
            {!isGrowthPlan ? (
                <Box
                    css={css`
                        padding: 24px 16px;
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            flex-grow: 1;
                            font-size: ${fontSizes.f20};
                            margin: ${spacing.none};
                        `}
                    >
                        {translate('title')}
                    </Title>
                    <MvpUpgradeFlow planType={planType} type="sobo" />
                </Box>
            ) : (
                <EditableSettingsSection
                    title={translate('title')}
                    isEditing={isEditing}
                    onToggleEdit={handleToggleEdit}
                    editButtonLabel={translate('edit-or-add-button-label')}
                >
                    {isEditing ? editableContent : nonEditableContent}
                </EditableSettingsSection>
            )}
            <Modal open={showNewRequestModal} onClose={handleCloseNewRequestModal}>
                <Box
                    css={css`
                        padding: ${spacing.space32px};
                    `}
                >
                    <ModalCloseButton onClose={handleCloseNewRequestModal} />
                    <Title
                        type="h3"
                        css={css`
                            font-size: ${fontSizes.f24};
                        `}
                    >
                        {translate('send-new-request-modal.header')}
                    </Title>
                    <SelectChip
                        css={css`
                            display: block;
                            margin-bottom: ${spacing.space32px};
                        `}
                        isMulti={false}
                        options={validRequestOptions}
                        name="send-from-account"
                        placeholder={translate('send-new-request-modal.email-text-field-label')}
                        onChange={handleRequestEmailChange}
                        noOptionsMessage={translate('no-accounts-available')}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && isValidRequestEmail) handleRequestNewEmail();
                        }}
                    />

                    <Box
                        css={css`
                            display: flex;
                        `}
                    >
                        <Button
                            variant="contained"
                            css={css`
                                margin-left: auto;
                            `}
                            disabled={!isValidRequestEmail}
                            onClick={handleRequestNewEmail}
                        >
                            {translate('send-new-request-modal.send-request-button-label')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export { SendFromAccounts };

const SoboSupportArticleLink = (children: ReactNode) => (
    <Hyperlink href={SOBO_SUPPORT_ARTICLE_URL} newTab>
        {children}
    </Hyperlink>
);
