import { useTranslations } from 'next-intl';
import { useEffect, useRef } from 'react';

import {
    EmailSendingHours,
    HireflowSignature,
    PrimaryTimezone,
} from 'settings/components/presentational/account-and-emails';
import { CalendarLink } from 'settings/components/presentational/account-and-emails/calendar-link';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { useSettings } from 'shared/hooks';
import { FC } from 'shared/types';

interface CommunicationPreferencesProps {}

const CommunicationPreferences: FC<CommunicationPreferencesProps> = () => {
    const translate = useTranslations('settings.email-settings');
    const hireflowSignatureContainerRef = useRef<HTMLDivElement>(null);
    const emailHoursContainerRef = useRef<HTMLDivElement>(null);

    const { scrollToHireflowSignature, scrollToEmailHours } = useSettings();

    useEffect(() => {
        if (scrollToHireflowSignature) {
            hireflowSignatureContainerRef.current?.scrollIntoView();
        }
    }, [scrollToHireflowSignature]);

    useEffect(() => {
        if (scrollToEmailHours) {
            emailHoursContainerRef.current?.scrollIntoView();
        }
    }, [scrollToEmailHours]);

    return (
        <Box>
            <Title type="h2">{translate('communication-preferences')}</Title>
            <div ref={emailHoursContainerRef}>
                <EmailSendingHours editing={scrollToEmailHours} />
            </div>
            <PrimaryTimezone />
            <CalendarLink />
            <div ref={hireflowSignatureContainerRef}>
                <HireflowSignature />
            </div>
        </Box>
    );
};

export { CommunicationPreferences };
