import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { InfoCircle, XClose } from 'shared/components/svgs';
import { hireflowSupport } from 'shared/constants';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerEditContactUsNoticeProps {
    handleDismissContactNotice(): void;
}

const SourcerEditContactUsNotice: FC<SourcerEditContactUsNoticeProps> = ({ handleDismissContactNotice }) => {
    const theme = useTheme();
    const translate = useTranslations('sourcing');

    return (
        <Box
            css={css`
                display: flex;
                padding: 16px 24px;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                border-radius: 4px;
                background: ${theme.palette.grey[200]};
                color: ${theme.palette.common.white};
            `}
        >
            <Box
                css={css`
                    padding-right: ${spacing.space10px};
                `}
            >
                <InfoCircle stroke={theme.palette.common.white} />
            </Box>
            <Box>{translate.rich('details.contact-us-notice', { link: Link })}</Box>
            <Button
                css={css`
                    padding: unset;
                    margin: unset;
                    height: unset;
                    min-width: unset;
                `}
                startIcon={<XClose stroke={theme.palette.common.white} />}
                onClick={handleDismissContactNotice}
                disableRipple
            />
        </Box>
    );
};

export { SourcerEditContactUsNotice };

const Link = (children: ReactNode) => (
    <a
        css={css`
            text-decoration: underline;
        `}
        href={hireflowSupport}
        target="_blank"
        rel="noreferrer"
    >
        {children}
    </a>
);
