import { useLazyQuery, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { partition } from 'lodash';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    MoveProjectMembersMutation,
    MoveProjectMembersMutationVariables,
    ProjectMembersAndProspectsQuery,
    ProjectMembersAndProspectsQueryVariables,
} from 'codegen/graphql';
import { ProjectMemberWithProspect } from 'projects/types';
import { SelectProjectModal } from 'shared/components/modals';
import { NoProspectsModal } from 'shared/components/presentational';
import {
    GET_PROJECT_MEMBERS_AND_PROSPECTS,
    GET_PROJECT_MEMBERS_BY_PROJECT,
    MOVE_PROJECT_MEMBERS,
} from 'shared/graphql/project-members';
import { GET_PROJECT_MEMBER_ACTIVITIES } from 'shared/graphql/project-members-activities';
import { useSnackbarAlert } from 'shared/hooks';
import { FC } from 'shared/types';

interface MoveMultipleProspectsToProjectProps {
    projectMembers: ProjectMemberWithProspect[];
    sourceProjectId?: string;
    open: boolean;
    onClose: () => void;
}

const MoveMultipleProspectsToProject: FC<MoveMultipleProspectsToProjectProps> = ({
    open,
    onClose,
    projectMembers,
    sourceProjectId,
}) => {
    const translate = useTranslations('project.prospect-list-table.edit-bar.add-to-project-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [moveProjectMembers] = useMutation<MoveProjectMembersMutation, MoveProjectMembersMutationVariables>(
        MOVE_PROJECT_MEMBERS,
        {
            refetchQueries: [
                getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
                getOperationName(GET_PROJECT_MEMBER_ACTIVITIES) as string,
            ],
        }
    );

    const [getProjectMembersQuery] = useLazyQuery<
        ProjectMembersAndProspectsQuery,
        ProjectMembersAndProspectsQueryVariables
    >(GET_PROJECT_MEMBERS_AND_PROSPECTS);

    const [loading, setLoading] = useState(false);

    const handleMoveToProject = async (project: { id: string; title: string }) => {
        // A user can only move prospects that they own
        // So we don't need to check if they own the project member's prospects
        // However, they could be moving prospects that are already in the target project
        // So we need to check for that and remove the prospect from the move list if it's already in the project
        // And finally remove the prospect from the source project
        if (!loading) setLoading(true);

        const movingProspectsUrls = projectMembers.map((projectMember) => projectMember.prospect!.urls![0].url);
        const { data: existingProjectMembersData } = await getProjectMembersQuery({
            variables: {
                where: { projectId: { _eq: project.id }, prospect: { urls: { url: { _in: movingProspectsUrls } } } },
            },
        });

        const existingMembersUrls =
            existingProjectMembersData?.project_members?.map((projectMember) => projectMember.prospect!.urls![0].url) ??
            [];

        // separate project members that need to be moved from the ones that need to be deleted
        const [projectMembersToDelete, projectMembersToMove] = partition(projectMembers, (projectMember) =>
            existingMembersUrls.includes(projectMember.prospect!.urls![0].url)
        );

        const res = await moveProjectMembers({
            variables: {
                targetProject: project.id,
                projectMembersToMove: projectMembersToMove.map((projectMember) => projectMember.id),
                projectMembersToDelete: projectMembersToDelete.map((projectMember) => projectMember.id),
            },
        });

        if (res.data) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('move-success-alert', {
                    numberOfProspects: projectMembers.length,
                    projectTitle: project.title,
                }),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('move-error-alert'),
            });
        }
        onClose();
        setLoading(false);

        for (const projectMember of projectMembers) {
            trackEvent('click_move_to_another_project', {
                prospect_id: projectMember.prospect.id,
            });
        }
    };

    return projectMembers.length > 0 ? (
        <SelectProjectModal
            open={open}
            onClose={onClose}
            onConfirm={handleMoveToProject}
            buttonLabel={translate('move-button-label', { count: projectMembers.length })}
            loading={loading}
            {...(sourceProjectId && { projectsToExcludeFromDropdown: [sourceProjectId] })}
        />
    ) : (
        <NoProspectsModal open={open} onClose={onClose} />
    );
};

export { MoveMultipleProspectsToProject };
