import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import { Button, Radio, RadioGroup, Title } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface PrimaryEmailChangeProps {
    sequences: string[];
    emails: string[];
    onCancel: () => void;
    onSave: (decision: boolean) => void;
}

const PrimaryEmailChange: FC<PrimaryEmailChangeProps> = (props) => {
    const { sequences, emails, onCancel, onSave } = props;
    const translate = useTranslations('prospects.tabs.profile-tab.primary-email-change-modal');
    const [decision, setDecision] = useState<boolean>(false);

    const handleChange = () => {
        setDecision(!decision);
    };

    const handleNext = () => {
        onSave(decision);
    };

    return (
        <Box
            css={css`
                width: 100%;
                margin: ${spacing.none} ${spacing.space48px};
            `}
        >
            <Title
                css={css`
                    font-size: ${fontSizes.f24};
                `}
                type="h3"
            >
                {translate('title')}
            </Title>
            {translate.rich(emails.length > 1 ? 'description-multiple-emails' : 'description', {
                email: emails.join(', '),
                bold: Bold,
                sequences: sequences.join(', '),
            })}
            <Box
                css={css`
                    margin-top: 20px;
                `}
            >
                <RadioGroup onChange={handleChange} value={decision}>
                    <FormControlLabel value={false} control={<Radio />} label={translate('keep-different-emails')} />
                    <FormControlLabel value control={<Radio />} label={translate('restart-sequence-new-primary')} />
                </RadioGroup>
            </Box>
            <Box
                css={css`
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                    {translate('cancel-button')}
                </Button>
                <Button
                    css={css`
                        margin-left: 10px;
                    `}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    {translate('apply-button')}
                </Button>
            </Box>
        </Box>
    );
};

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);
export { PrimaryEmailChange };
