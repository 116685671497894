import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { Bulb } from 'shared/components/svgs';
import { hireflowSupport } from 'shared/constants';
import { useSettings } from 'shared/hooks';
import { FC } from 'shared/types';

interface ConnectAtsPromptProps {}

const ConnectAtsPrompt: FC<ConnectAtsPromptProps> = () => {
    const translate = useTranslations('integrations.connect-ats-prompt');

    const theme = useTheme();

    const { openAtsIntegrations } = useSettings();

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                gap: 18px;

                // typography
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;

                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
            `}
        >
            <Box>
                <Bulb />
            </Box>
            <Box>
                <Box component="span">{translate('first')}</Box>{' '}
                <Box
                    css={css`
                        color: ${theme.palette.primary.main};
                        cursor: pointer;

                        &:hover {
                            color: ${theme.palette.primary.light};
                        }
                    `}
                    component="span"
                    onClick={openAtsIntegrations}
                >
                    {translate('first-link')}
                </Box>{' '}
                <Box component="span">{translate('second')}</Box> <Box component="span">{translate('third')}</Box>{' '}
                <Hyperlink newTab href={hireflowSupport}>
                    {translate('third-link')}
                </Hyperlink>
                <Box component="span">{translate('fourth')}</Box>
            </Box>
        </Box>
    );
};

export { ConnectAtsPrompt };
