import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useState } from 'react';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import {
    CreateRecipientMutation,
    OrderBy,
    ReviewSourcerMembersMutation,
    ReviewSourcerMembersMutationResult,
    ReviewSourcerMembersMutationVariables,
    SequencesQuery,
    SequenceStatusEnum,
    SourcerMembersQuery,
    SourcerMembersQueryVariables,
    SourcerMemberStatusEnum,
} from 'codegen/graphql';
import { logger } from 'logger';
import { ConfirmAddToSequence, MissingVariablesAlert, MissingVariablesForm } from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { InvalidProspect, ValidProspect } from 'shared/components/modals';
import { Button, Hyperlink, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { OUT_OF_AI_SOURCING_CREDITS } from 'shared/constants';
import { AppFlow, SourcerMemberApprovalAction } from 'shared/enums';
import { CREATE_RECIPIENTS } from 'shared/graphql/recipients';
import { GET_SEQUENCES, SequenceData } from 'shared/graphql/sequences';
import {
    createApproveUpsertObject,
    createRejectUpsertObject,
    createSnoozeUpsertObject,
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    REVIEW_SOURCER_MEMBERS,
    sourcerMembersDefaultOrderBy,
    sourcerMembersDefaultOrderByDirection,
} from 'shared/graphql/sourcer-members';
import {
    useCreateProjectMembers,
    useProspectCreator,
    useScrollToTop,
    useSession,
    useSnackbarAlert,
} from 'shared/hooks';
import { useAiSourcingCredits } from 'shared/hooks/use-ai-sourcing-credits';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { checkForProspectMissingVariables, findProspectMissingVariables } from 'shared/services';
import { fontSizes } from 'shared/settings';
import { spacing } from 'shared/settings/spacings';
import { FC, SourcerData } from 'shared/types';
import { SourcerMemberCardModalChooseSequence, SourcerMemberCardReview } from 'sourcing/components/composite';

type Prospect = DeepExtractTypeSkipArrays<
    ReviewSourcerMembersMutationResult,
    ['data', 'insert_sourcer_members', 'returning', 'prospect']
>;

type SourcerMembersInReview = SourcerMembersQuery['sourcer_members'];
interface SourcerMemberCardModalProps {
    open: boolean;
    onClose: () => void;
    reviews: Map<string, SourcerMemberCardReview>;
    sourcerMembers: SourcerMembersInReview;
    sourcer: SourcerData;
}

const SourcerMemberCardModal: FC<SourcerMemberCardModalProps> = ({
    onClose,
    open,
    reviews,
    sourcerMembers,
    sourcer,
}) => {
    const noCreditsAvailableTranslate = useTranslations('sourcing.no-ai-sourcing-credits-notice');
    const sourcerMemberCardTranslate = useTranslations('sourcing.sourcer-member-card.modal');
    const addToSequenceTranslate = useTranslations('project.prospect-list-table.edit-bar.add-to-sequence-modal');
    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const scrollToTop = useScrollToTop();
    const apolloClient = useApolloClient();

    const { trackApprovedSourcerMembers } = useApproveSourcerMembers([]);

    const [selected, setSelected] = useState<string>('no-sequence');
    const [modalState, setModalState] = useState<
        | 'choose-sequence'
        | 'missing-variables-alert'
        | 'missing-variables-form'
        | 'confirm-add-to-sequence'
        | 'no-ai-sourcing-credits'
        | 'prospects-already-reviewed'
        | 'all-prospects-already-reviewed'
    >('choose-sequence');
    const [invalidProspects, setInvalidProspects] = useState<InvalidProspect[]>([]);

    const [validProspects, setValidProspects] = useState<ValidProspect[]>([]);
    const [sourcerMembersToBeReviewed, setSourcerMembersToBeReviewed] =
        useState<SourcerMembersInReview>(sourcerMembers);
    const [alreadyReviewedSourcerMembers, setAlreadyReviewedSourcerMembers] = useState<SourcerMembersInReview>([]);

    const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();

    const { data: sequencesData } = useQuery<SequencesQuery>(GET_SEQUENCES, {
        variables: {
            withDetails: true,
            where: {
                _and: {
                    userId: { _eq: session?.user.id },
                    status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                },
            },
            orderBy: { title: OrderBy.Asc },
        },
    });

    const [getSourcerMembersStillInReview] = useLazyQuery<SourcerMembersQuery, SourcerMembersQueryVariables>(
        GET_SOURCER_MEMBERS,
        {
            variables: {
                orderBy: { [sourcerMembersDefaultOrderBy]: sourcerMembersDefaultOrderByDirection },
                where: {
                    id: {
                        _in: sourcerMembersToBeReviewed.map((sourcerMember) => sourcerMember.id),
                    },
                    status: { _eq: SourcerMemberStatusEnum.ToReview },
                },
                withDetails: true,
            },
            fetchPolicy: 'network-only',
        }
    );

    const { createProspectsFromSourcerMembers, buildProspectProfileFromSourcerMember, filterProjectDuplicates } =
        useProspectCreator();

    const [reviewSourcerMembers] = useMutation<ReviewSourcerMembersMutation, ReviewSourcerMembersMutationVariables>(
        REVIEW_SOURCER_MEMBERS
    );

    const { createAndTrackProjectMembers } = useCreateProjectMembers([]);

    const [createRecipients] = useMutation<CreateRecipientMutation>(CREATE_RECIPIENTS);

    const [loading, setLoading] = useState(false);

    const refetchQueries = [
        getOperationName(GET_SOURCER_MEMBERS) as string,
        getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
    ];

    const toBeRejectedSourcerMembers = sourcerMembersToBeReviewed.filter((sourcerMember) => {
        const review = reviews.get(sourcerMember.id);
        return review?.status === SourcerMemberStatusEnum.Rejected;
    });
    const toBeSnoozedSourcerMembers = sourcerMembersToBeReviewed.filter((sourcerMember) => {
        const review = reviews.get(sourcerMember.id);
        return review?.status === SourcerMemberStatusEnum.Snoozed;
    });
    const toBeApprovedSourcerMembers = sourcerMembersToBeReviewed.filter((sourcerMember) => {
        const review = reviews.get(sourcerMember.id);
        return review?.status === SourcerMemberStatusEnum.Accepted;
    });

    const handleSequenceChange = (value: string) => {
        setSelected(value);
    };

    const handleReviewSourcerMembers = async () => {
        try {
            if (!loading) setLoading(true);

            const { data } = await getSourcerMembersStillInReview();
            const sourcerMembersStillInReview = data?.sourcer_members ?? [];
            if (sourcerMembersStillInReview.length === 0) {
                // show no sourcer members to review modal
                setModalState('all-prospects-already-reviewed');
                setLoading(false);
                return;
            }
            if (sourcerMembersStillInReview.length !== sourcerMembersToBeReviewed.length) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const alreadyReviewedSourcerMembers = sourcerMembersToBeReviewed.filter(
                    (sm) => !sourcerMembersStillInReview.some((sm2) => sm2.id === sm.id)
                );
                setAlreadyReviewedSourcerMembers(alreadyReviewedSourcerMembers);
                setSourcerMembersToBeReviewed(sourcerMembersStillInReview);
                setModalState('prospects-already-reviewed');
                setLoading(false);
                return;
            }

            const rejectedSourcerMembers =
                data?.sourcer_members.filter((sourcerMember) => {
                    const review = reviews.get(sourcerMember.id);
                    return review?.status === SourcerMemberStatusEnum.Rejected;
                }) ?? [];
            const snoozedSourcerMembers =
                data?.sourcer_members.filter((sourcerMember) => {
                    const review = reviews.get(sourcerMember.id);
                    return review?.status === SourcerMemberStatusEnum.Snoozed;
                }) ?? [];
            const approvedSourcerMembers =
                data?.sourcer_members.filter((sourcerMember) => {
                    const review = reviews.get(sourcerMember.id);
                    return review?.status === SourcerMemberStatusEnum.Accepted;
                }) ?? [];

            const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
                getAiSourcingCreditsAvailable(),
                isPayOnApproveEnabled(),
            ]);

            let approvedSourcerMembersToBeAdded = approvedSourcerMembers;
            if (shouldDeductCreditOnApprove) {
                const { creditsPerApproval } = sourcer;
                const countOfMemberCanBeApproved = Math.floor(aiSourcingCreditsAvailable / creditsPerApproval);
                approvedSourcerMembersToBeAdded =
                    countOfMemberCanBeApproved < approvedSourcerMembers.length
                        ? [...approvedSourcerMembers].splice(0, countOfMemberCanBeApproved)
                        : approvedSourcerMembers;
            }

            if (
                approvedSourcerMembers.length > 0 &&
                approvedSourcerMembersToBeAdded.length === 0 &&
                rejectedSourcerMembers.length === 0 &&
                snoozedSourcerMembers.length === 0
            ) {
                setModalState('no-ai-sourcing-credits');

                return;
            }

            const selectedSequence = sequencesData?.sequences.find((sequence) => sequence.id === selected);
            const areThereMissingVariables = selectedSequence
                ? approvedSourcerMembersToBeAdded.some((sourcerMember) => {
                      const profile =
                          sourcerMember.prospect?.profile || buildProspectProfileFromSourcerMember(sourcerMember);
                      return checkForProspectMissingVariables(profile, selectedSequence);
                  })
                : false;

            const sourcerMembersWithProspects = await createProspectsFromSourcerMembers(
                approvedSourcerMembersToBeAdded
            );

            const prospectsToAddToProject = await filterProjectDuplicates(
                sourcerMembersWithProspects!.map((sourcerMember) => sourcerMember.prospect!),
                sourcer.project.id
            );

            await createAndTrackProjectMembers(
                prospectsToAddToProject?.map((prospect) => ({
                    projectId: sourcer.project.id,
                    prospectId: prospect.id,
                })) ?? [],
                AppFlow.AiSourcing
            );

            if (!areThereMissingVariables && selectedSequence) {
                await createRecipients({
                    variables: {
                        recipients: sourcerMembersWithProspects?.map((sourcerMemberWithProspect) => ({
                            prospectId: sourcerMemberWithProspect.prospect?.id,
                            sequenceId: selectedSequence.id,
                        })),
                    },
                });
            }

            const approveUpdateObjects = approvedSourcerMembersToBeAdded.map((sourcerMember) =>
                createApproveUpsertObject({
                    sourcerMember,
                    reviewerId: session!.user.id,
                })
            );
            const rejectUpdateObjects = rejectedSourcerMembers.map((sourcerMember) => {
                const review = reviews.get(sourcerMember.id);
                return createRejectUpsertObject(
                    sourcerMember,
                    session!.user.id,
                    review?.notes,
                    review?.rejectionReasons
                );
            });
            const snoozeUpdateObjects = snoozedSourcerMembers.map((sourcerMember) =>
                createSnoozeUpsertObject(sourcerMember, session!.user.id)
            );
            /**
             * The reviewSourcerMembers mutation uses the upsert method to update all sourcer members at once
             */
            const reviewSourcerMembersResponse = await reviewSourcerMembers({
                variables: {
                    sourcer_members: [...approveUpdateObjects, ...rejectUpdateObjects, ...snoozeUpdateObjects],
                },
                // only refetch queries if there are no missing variables flow after this
                refetchQueries: areThereMissingVariables ? [] : refetchQueries,
            });

            if (reviewSourcerMembersResponse.data) {
                if (selectedSequence && areThereMissingVariables) {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    const prospects = reviewSourcerMembersResponse.data.insert_sourcer_members?.returning
                        .filter((sourcerMember) => sourcerMember.status === SourcerMemberStatusEnum.Accepted)
                        .map((sourcerMember) => sourcerMember.prospect)
                        .filter((prospect): prospect is Prospect => Boolean(prospect?.profile));

                    showSnackbarAlert({
                        severity: 'success',
                        message: sourcerMemberCardTranslate('save-reviews-success-alert', {
                            approvedCount: approvedSourcerMembersToBeAdded.length,
                            rejectedCount: rejectedSourcerMembers.length,
                            snoozedCount: snoozedSourcerMembers.length,
                            project: sourcer.project.title,
                        }),
                    });

                    // if there are missing variables,
                    // there are prospects and we can use the non - null assertion operator
                    handleProspectsWithMissingVariables(prospects!, selectedSequence);
                } else if (selectedSequence && !areThereMissingVariables) {
                    showSnackbarAlert({
                        severity: 'success',
                        message: sourcerMemberCardTranslate('save-reviews-and-create-recipients-success-alert', {
                            approvedCount: approvedSourcerMembersToBeAdded.length,
                            rejectedCount: rejectedSourcerMembers.length,
                            snoozedCount: snoozedSourcerMembers.length,
                            project: sourcer.project.title,
                            sequence: selectedSequence.title,
                        }),
                    });
                    handleCloseAndScrollToTop();
                    setLoading(false);
                } else {
                    showSnackbarAlert({
                        severity: 'success',
                        message: sourcerMemberCardTranslate('save-reviews-success-alert', {
                            approvedCount: approvedSourcerMembersToBeAdded.length,
                            rejectedCount: rejectedSourcerMembers.length,
                            snoozedCount: snoozedSourcerMembers.length,
                            project: sourcer.project.title,
                        }),
                    });
                    handleCloseAndScrollToTop();
                    setLoading(false);
                }
            }

            trackApprovedSourcerMembers({
                sourcerMemberIds: approvedSourcerMembers.map((a) => a.id),
                flow: SourcerMemberApprovalAction.ManualApprove,
                reviewerId: session?.user.id,
            });
        } catch (error) {
            logger.error('Failed to review sourcer members', { error });
            showSnackbarAlert({
                severity: 'error',
                message: sourcerMemberCardTranslate('save-reviews-error-alert'),
            });
            handleClose();
            setLoading(false);
        }
    };

    const handleProspectsWithMissingVariables = (prospects: Prospect[], selectedSequence: SequenceData) => {
        const prospectsWithMissingVariables: InvalidProspect[] = [];
        const goodProspects: ValidProspect[] = [];
        prospects.forEach((prospect) => {
            const SelectedSequenceCustomMessage = prospect.customMessages?.find(
                (customMessageObject) => customMessageObject.sequenceId === selectedSequence.id
            );
            const customMessage = SelectedSequenceCustomMessage?.customMessage;
            if (prospect.profile && prospect.urls) {
                const { profile } = prospect;
                const missingVariables = findProspectMissingVariables(profile, selectedSequence, customMessage);
                if (missingVariables.length > 0 && profile) {
                    prospectsWithMissingVariables.push({
                        id: prospect.id,
                        profile,
                        missingFields: missingVariables,
                        isResolved: false,
                        url: getPrimaryProfileUrl(prospect.urls),
                    });
                } else {
                    goodProspects.push({ id: prospect.id, profile, url: getPrimaryProfileUrl(prospect.urls) });
                }
            }
        });
        setInvalidProspects(prospectsWithMissingVariables);
        setValidProspects(goodProspects);
        setModalState('missing-variables-alert');
    };

    const handleResetModalState = () => {
        setModalState('choose-sequence');
    };

    const handleClose = async () => {
        if (modalState === 'all-prospects-already-reviewed' || modalState === 'prospects-already-reviewed') {
            // if we have detected that some prospects have already been reviewed,
            // we should refetch the queries on close to make sure the UI is updated
            apolloClient.refetchQueries({ include: refetchQueries });
        }
        onClose();
        handleResetModalState();
    };

    const handleCloseAndScrollToTop = () => {
        handleClose();
        scrollToTop();
    };

    useEffect(() => {
        // This useEffect is needed to reset the modal state when it is closed.
        // Without it the modal state would be the same as the last time it was opened
        // and the UI would be in a weird state, i.e., the all prospects already reviewed modal
        // would show up even if there are still prospects to review
        // or the prospects to review would be the ones previously reviewed
        if (open) {
            handleResetModalState();
            setSourcerMembersToBeReviewed(sourcerMembers);
        }
    }, [open, sourcerMembers]);

    if (!sequencesData) return null;

    const sequenceOptions = [
        { value: 'no-sequence', label: sourcerMemberCardTranslate('no-sequence') },
        ...sequencesData.sequences.map((sequence) => ({
            value: sequence.id,
            label: sequence.title,
        })),
    ];

    const GoToConfirmAddToSequence = () => {
        setModalState('confirm-add-to-sequence');
    };
    const setMissingVariables = () => {
        setModalState('missing-variables-form');
    };
    const addValidProspect = (prospect: ValidProspect) => {
        setValidProspects([...validProspects, prospect]);
    };

    const markProspectAsResolved = (index: number) => {
        const newInvalidProspects = invalidProspects.map((prospect, i) => {
            if (index === i) {
                return { ...prospect, isResolved: true };
            }
            return prospect;
        });
        setInvalidProspects(newInvalidProspects);
    };
    const handleCreateRecipients = async () => {
        if (!loading) setLoading(true);
        if (selectedSequence) {
            const res = await createRecipients({
                variables: {
                    recipients: validProspects.map((prospect) => ({
                        prospectId: prospect.id,
                        sequenceId: selectedSequence.id,
                    })),
                },
                refetchQueries,
            });
            if (res.data) {
                const newRecipients = Number(res.data.insert_recipients?.affected_rows);
                const duplicatesCount = validProspects.length - newRecipients;
                showSnackbarAlert({
                    message: addToSequenceTranslate('success-alert', {
                        count: newRecipients,
                        duplicatesCount,
                        sequence: selectedSequence.title,
                    }),
                });
                handleCloseAndScrollToTop();
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: addToSequenceTranslate('error-alert'),
                });
                handleClose();
            }
            setLoading(false);
        }
    };

    const selectedSequence = sequencesData?.sequences.find((sequence) => sequence.id === selected);
    const remainingInvalidProspects = invalidProspects.filter((prospect) => !prospect.isResolved);

    const toBeReviewedCount = sourcerMembersToBeReviewed.length;
    const alreadyReviewedCount = alreadyReviewedSourcerMembers.length;
    const modalTitle =
        modalState === 'confirm-add-to-sequence'
            ? addToSequenceTranslate('confirm-add-to-sequence.title')
            : modalState === 'missing-variables-form'
            ? addToSequenceTranslate('missing-variables-form.title')
            : modalState === 'missing-variables-alert' && validProspects.length > 0
            ? addToSequenceTranslate('missing-variables-alert.title', { count: invalidProspects.length })
            : modalState === 'missing-variables-alert' && validProspects.length === 0
            ? addToSequenceTranslate('missing-variables-alert.title-disabled-skip', { count: invalidProspects.length })
            : modalState === 'no-ai-sourcing-credits'
            ? noCreditsAvailableTranslate('title')
            : modalState === 'prospects-already-reviewed'
            ? sourcerMemberCardTranslate('prospects-already-reviewed.title', { count: alreadyReviewedCount })
            : modalState === 'all-prospects-already-reviewed'
            ? sourcerMemberCardTranslate('all-prospects-already-reviewed.title')
            : sourcerMemberCardTranslate('title');

    const modalContent =
        modalState === 'confirm-add-to-sequence' ? (
            <ConfirmAddToSequence
                sequenceTitle={selectedSequence!.title} // at this point, we have a selected sequence for sure
                validProspectsCount={validProspects.length}
                invalidProspectsCount={remainingInvalidProspects.length}
                onCreateRecipients={handleCreateRecipients}
                loading={loading}
            />
        ) : modalState === 'missing-variables-form' ? (
            <MissingVariablesForm
                sequenceId={selectedSequence!.id} // at this point, we have a selected sequence for sure
                invalidProspects={invalidProspects}
                validProspects={validProspects}
                addValidProspect={addValidProspect}
                onMissingVariablesComplete={GoToConfirmAddToSequence}
                markProspectAsResolved={markProspectAsResolved}
            />
        ) : modalState === 'missing-variables-alert' ? (
            <MissingVariablesAlert
                onSkipMissingVariables={GoToConfirmAddToSequence}
                onSetMissingVariables={setMissingVariables}
                onCancel={handleClose}
                shouldDisableSkip={validProspects.length === 0}
            />
        ) : modalState === 'no-ai-sourcing-credits' ? (
            <>
                <p>{noCreditsAvailableTranslate.rich('description', { link: Link })}</p>
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space32px};
                    `}
                >
                    <Button variant="contained" onClick={handleClose}>
                        {noCreditsAvailableTranslate('confirm-button-label')}
                    </Button>
                </Box>
            </>
        ) : modalState === 'all-prospects-already-reviewed' ? (
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    margin-top: ${spacing.space32px};
                `}
            >
                <Button variant="contained" onClick={handleClose}>
                    {sourcerMemberCardTranslate('all-prospects-already-reviewed.button')}
                </Button>
            </Box>
        ) : (
            <SourcerMemberCardModalChooseSequence
                sourcer={sourcer}
                rejectedCount={toBeRejectedSourcerMembers.length}
                snoozedCount={toBeSnoozedSourcerMembers.length}
                approvedCount={toBeApprovedSourcerMembers.length}
                sequenceOptions={sequenceOptions}
                selected={selected}
                handleSequenceChange={handleSequenceChange}
                handleReviewSourcerMembers={handleReviewSourcerMembers}
                loading={loading}
                description={
                    modalState === 'prospects-already-reviewed'
                        ? sourcerMemberCardTranslate('prospects-already-reviewed.description', {
                              count: toBeReviewedCount,
                          })
                        : undefined
                }
            />
        );

    return (
        <Modal open={Boolean(open)} onClose={handleClose}>
            <Box
                css={css`
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    css={css`
                        margin-bottom: ${spacing.space16px};
                        font-size: ${fontSizes.f24};
                    `}
                    type="h3"
                >
                    {modalTitle}
                </Title>
                {modalContent}
            </Box>
        </Modal>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink href={OUT_OF_AI_SOURCING_CREDITS} newTab>
        <u>{children}</u>
    </Hyperlink>
);

export { SourcerMemberCardModal };
