import { gql } from '@apollo/client';

import { GetSequenceCollaboratorsQuery } from 'codegen/graphql';

export type SequenceCollaboratorData = GetSequenceCollaboratorsQuery['sequence_collaborators'][number];

const SEQUENCE_COLLABORATOR_DETAILS = gql`
    fragment SequenceCollaboratorDetails on sequence_collaborators {
        id
        sequenceId
        userId
        createdAt
        modifiedAt
    }
`;

export const REMOVE_SEQUENCE_COLLABORATOR = gql`
    mutation RemoveSequenceCollaborator($id: String!) {
        deleted: delete_sequence_collaborators_by_pk(id: $id) {
            id
        }
    }
`;

export const INVITE_SEQUENCE_COLLABORATORS = gql`
    mutation InviteSequenceCollaborators($collaborators: [sequence_collaborators_insert_input!]!) {
        insert_sequence_collaborators(
            objects: $collaborators
            on_conflict: { constraint: sequence_collaborators_pkey, update_columns: [] }
        ) {
            affected_rows
            newCollaborators: returning {
                id
                userId
                user: collaborator {
                    id
                    fullName
                    email
                }
            }
        }
    }
`;

export const GET_SEQUENCE_COLLABORATORS = gql`
    query GetSequenceCollaborators(
        $where: sequence_collaborators_bool_exp!
        $orderBy: sequence_collaborators_order_by!
    ) {
        sequence_collaborators(where: $where, order_by: [$orderBy]) {
            ...SequenceCollaboratorDetails
        }
    }
    ${SEQUENCE_COLLABORATOR_DETAILS}
`;
