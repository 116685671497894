import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { trackEvent } from 'analytics';
import { ProspectConversationTab, ProspectOrSourcerAddTab } from 'prospects';
import { ProspectHistoryTab } from 'prospects/prospect-history-tab';
import { ProfileTab } from 'prospects/prospect-profile-tab';
import { ScrollSectionEnum } from 'prospects/prospect-project-sequence-ats-overview';
import { PanelTabEnum } from 'prospects/utils';
import { Box } from 'shared/components/containers';
import { Tab, TabPanel, Tabs } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectTabsProps {
    anchor: HTMLElement;
    value: PanelTabEnum;
    onChange: (value: PanelTabEnum) => void;
    scrollToSection?: ScrollSectionEnum;
    onScroll: () => void;
    hideProspectPanel: () => void;
}

const ProspectTabs: FC<ProspectTabsProps> = (props) => {
    const { value, onChange, scrollToSection, onScroll, anchor, hideProspectPanel } = props;
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs');

    useEffect(() => {
        trackEvent('load_extension', { extension_tab_name: 'add_tab' });
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onChange(newValue);
        const tab =
            newValue === PanelTabEnum.ADD
                ? 'add_tab'
                : newValue === PanelTabEnum.PROFILE
                ? 'profile_tab'
                : newValue === PanelTabEnum.CONVERSATION
                ? 'conversation_tab'
                : 'history_tab';
        trackEvent('load_extension', { extension_tab_name: tab });
    };

    return (
        <Box
            css={css`
                width: 100%;
            `}
        >
            <Box
                css={css`
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    margin: ${spacing.none} ${spacing.space24px};
                `}
            >
                <Tabs disableScroll value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                    <Tab
                        value={PanelTabEnum.ADD}
                        label={translate('add-label')}
                        css={css`
                            color: ${theme.palette.grey[600]};
                        `}
                    />
                    <Tab
                        value={PanelTabEnum.PROFILE}
                        label={translate('profile-label')}
                        css={css`
                            color: ${theme.palette.grey[600]};
                        `}
                    />
                    <Tab
                        value={PanelTabEnum.CONVERSATION}
                        label={translate('conversation-label')}
                        css={css`
                            color: ${theme.palette.grey[600]};
                        `}
                    />
                    <Tab
                        value={PanelTabEnum.HISTORY}
                        label={translate('history-label')}
                        css={css`
                            color: ${theme.palette.grey[600]};
                        `}
                    />
                </Tabs>
            </Box>
            <TabPanel
                value={value}
                index={PanelTabEnum.ADD}
                css={css`
                    padding: ${spacing.none};
                `}
            >
                <ProspectOrSourcerAddTab anchor={anchor} hideProspectPanel={hideProspectPanel} />
            </TabPanel>
            <TabPanel
                value={value}
                index={PanelTabEnum.PROFILE}
                css={css`
                    padding-top: ${spacing.none};
                `}
            >
                <ProfileTab anchor={anchor} scrollToSection={scrollToSection} onScroll={onScroll} />
            </TabPanel>
            <TabPanel
                value={value}
                index={PanelTabEnum.CONVERSATION}
                css={css`
                    padding-top: ${spacing.none};
                `}
            >
                <ProspectConversationTab anchor={anchor} />
            </TabPanel>
            <TabPanel
                value={value}
                index={PanelTabEnum.HISTORY}
                css={css`
                    padding-top: ${spacing.none};
                `}
            >
                <ProspectHistoryTab />
            </TabPanel>
        </Box>
    );
};

export { ProspectTabs };
