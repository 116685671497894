import styled from '@emotion/styled';
import { FormControlLabel, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { fontSizes } from 'shared/settings';
import { FCProps } from 'shared/types';

interface LargeSwitchProps<Values extends FieldValues> extends MuiSwitchProps {
    name?: Path<Values>;
    formMethods?: UseFormReturn<Values>;
    label?: string;
}

const LargeSwitchContainer = <Values extends FieldValues>(props: LargeSwitchProps<Values> & FCProps) => {
    const { className, name, formMethods, label, disabled = false, checked, onChange } = props;

    let register = name ? formMethods?.register(name) : undefined;

    // checked, disabled switches result in component de-registration
    // set register to be undefined if disabled is set
    if (disabled) register = undefined;

    // custom hook to re-render the select component if field is being controlled by a form
    if (name) formMethods?.watch(name);

    return (
        <StyledFormControlLabel
            control={
                <MuiSwitch
                    disabled={disabled}
                    inputRef={register?.ref}
                    className={className}
                    name={name}
                    checked={checked ?? (name && formMethods?.getValues(name))}
                    onBlur={register?.onBlur}
                    onChange={onChange || register?.onChange}
                />
            }
            label={label}
        />
    );
};

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-left: 0;
    .MuiTypography-root {
        font-size: ${fontSizes.f16};
    }
    ${({ label }) => !label && `margin-right: 0; `}
`;

const LargeSwitch = styled(LargeSwitchContainer)`
    width: 54px;
    height: 30px;
    padding: 0px;

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 2px;
        transition-duration: 300ms;
        &.Mui-checked {
            transform: translateX(24px);
            color: #fff;
            & + .MuiSwitch-track {
                background-color: ${({ theme }) => theme.palette.primary.main};
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }

        &.Mui-focusVisible .MuiSwitch-thumb {
            color: ${({ theme }) => theme.palette.primary.main};
            border: 6px solid #fff;
            opacity: 0.5;
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 26px;
        height: 26px;
    }

    & .MuiSwitch-track {
        border-radius: 15px;
        background-color: #e9e9ea;
        opacity: 1;
    }
` as typeof LargeSwitchContainer;

export { LargeSwitch };
