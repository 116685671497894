import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, css, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';

import { Box } from 'shared/components/containers';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface ToolTipProps extends MuiTooltipProps {
    content?: string | ReactNode;
}

const Tooltip: FC<ToolTipProps> = (props) => {
    const theme = useTheme();
    const { open, title, content, placement = 'bottom', children, onClose, PopperProps, ...rest } = props;

    const [isHovering, setIsHovering] = useState(false);

    return (
        <MuiTooltip
            open={open || isHovering}
            onClose={onClose}
            PopperProps={
                PopperProps || {
                    sx: {
                        maxWidth: '280px',
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: `${theme.palette.common.white}`,
                            color: `${theme.palette.common.black}`,
                            borderRadius: '8px',
                            boxShadow: `0px 10px 30px 0px rgba(0, 0, 0, 0.10)`,
                        },
                    },
                }
            }
            title={
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 12px;
                        gap: 4px;
                        font-size: ${fontSizes.f14};
                    `}
                >
                    <Box>{title}</Box>
                    {content && <Box>{content}</Box>}
                </Box>
            }
            placement={placement}
            {...rest}
        >
            <div
                className="tooltip-root"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {children}
            </div>
        </MuiTooltip>
    );
};

export { Tooltip };
