import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import CsvDownloader from 'react-csv-downloader';

import { trackEvent } from 'analytics';
import {
    AshbySequenceMappingBySequenceIdQuery,
    CreateAshbySequenceMappingMutation,
    CreateGreenhouseSequenceMappingMutation,
    CreateLeverSequenceMappingMutation,
    DuplicateSequenceMutation,
    GetUserRoleQuery,
    GreenhouseSequenceMappingBySequenceIdQuery,
    LeverSequenceMappingBySequenceIdQuery,
    LicenseTypesEnum,
    PlanTypesEnum,
    RecipientsQuery,
    RecipientsQueryVariables,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SendFromAccountStatusEnum,
    SequenceActivityTypesEnum,
    SequencesByStatusQuery,
    SequencesByStatusQueryVariables,
    SequencesQuery,
    SequenceStatusEnum,
    SignatureOptionsEnum,
    UpdateSequenceByIdMutation,
    UserTeamQuery,
} from 'codegen/graphql';
import { logger } from 'logger';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton, Hyperlink } from 'shared/components/presentational';
import { SUPPORT_HIREFLOW_EMAIL } from 'shared/constants';
import {
    CREATE_ASHBY_SEQUENCE_MAPPING,
    CREATE_GREENHOUSE_SEQUENCE_MAPPING,
    CREATE_LEVER_SEQUENCE_MAPPING,
    GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
} from 'shared/graphql/integrations';
import { GET_RECIPIENTS, recipientsDefaultOrderBy, recipientsDefaultOrderByDirection } from 'shared/graphql/recipients';
import { GET_SEND_FROM_ACCOUNTS } from 'shared/graphql/send-from-accounts';
import {
    DUPLICATE_SEQUENCE,
    GET_SEQUENCES,
    GET_SEQUENCES_COUNT_BY_STATUS,
    GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
    UPDATE_SEQUENCE_BY_ID,
} from 'shared/graphql/sequences';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import {
    useAccessControl,
    useConfirmationModal,
    useListSubmenuTab,
    useRecipientStatus,
    useSnackbarAlert,
} from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { dateFormatter } from 'shared/services';
import { appRoutes, colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

type SequenceData = SequencesQuery['sequences'][number];

interface SequenceListSubmenuProps {
    sequence: SequenceData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
}

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: ${colors.greens.eucalyptus};
        `}
        href={`mailto:${SUPPORT_HIREFLOW_EMAIL}`}
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

const SequenceListSubmenu: FC<SequenceListSubmenuProps> = ({ sequence, anchor, setAnchor }) => {
    const translate = useTranslations('sequence');
    const router = useRouter();
    const { session, loaded } = useSession();
    const { canEditSequence, canFullyDuplicateSequence } = useAccessControl();
    const { setCurrentTab } = useListSubmenuTab();

    const { canCreateSequences } = useAccessControl();

    const { data: userRoleData, refetch: refetchUserRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user.id },
        fetchPolicy: 'no-cache',
    });

    const { data: sequenceData } = useQuery<SequencesByStatusQuery, SequencesByStatusQueryVariables>(
        GET_SEQUENCES_COUNT_BY_STATUS,
        {
            skip: !loaded || !session,
            variables: { userId: session?.user.id },
            fetchPolicy: 'no-cache',
        }
    );

    const { data: teamData, refetch: refetchUserTeamData } = useQuery<UserTeamQuery>(GET_USER_TEAM, {
        fetchPolicy: 'no-cache',
    });

    const [getSendFromAccounts] = useLazyQuery<SendFromAccountsQuery, SendFromAccountsQueryVariables>(
        GET_SEND_FROM_ACCOUNTS,
        {
            variables: {
                where: {
                    status: {
                        _eq: SendFromAccountStatusEnum.Approved,
                    },
                    userId: {
                        _eq: session?.user.id,
                    },
                },
            },
        }
    );

    const [duplicateSequence] = useMutation<DuplicateSequenceMutation>(DUPLICATE_SEQUENCE, {
        refetchQueries: [
            getOperationName(GET_SEQUENCES) as string,
            getOperationName(GET_SEQUENCES_COUNT_BY_STATUS) as string,
            getOperationName(GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS) as string,
        ],
    });

    const [updateSequence] = useMutation<UpdateSequenceByIdMutation>(UPDATE_SEQUENCE_BY_ID, {
        refetchQueries: [
            getOperationName(GET_SEQUENCES) as string,
            getOperationName(GET_SEQUENCES_COUNT_BY_STATUS) as string,
            getOperationName(GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS) as string,
        ],
    });

    const [getAllRecipients] = useLazyQuery<RecipientsQuery, RecipientsQueryVariables>(GET_RECIPIENTS, {
        variables: {
            orderBy: { [recipientsDefaultOrderBy]: recipientsDefaultOrderByDirection },
            where: { sequenceId: { _eq: sequence.id } },
        },
    });

    const [getAshbySequenceMappingData] = useLazyQuery<AshbySequenceMappingBySequenceIdQuery>(
        GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createAshbySequenceMapping] = useMutation<CreateAshbySequenceMappingMutation>(
        CREATE_ASHBY_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [getGreenhouseSequenceMappingData] = useLazyQuery<GreenhouseSequenceMappingBySequenceIdQuery>(
        GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createGreenhouseSequenceMapping] = useMutation<CreateGreenhouseSequenceMappingMutation>(
        CREATE_GREENHOUSE_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const [getLeverSequenceMappingData] = useLazyQuery<LeverSequenceMappingBySequenceIdQuery>(
        GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            variables: { sequenceId: sequence.id },
        }
    );

    const [createLeverSequenceMapping] = useMutation<CreateLeverSequenceMappingMutation>(
        CREATE_LEVER_SEQUENCE_MAPPING,
        {
            refetchQueries: [
                { query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId: sequence.id } },
            ],
        }
    );

    const { showModal, hideModal } = useConfirmationModal();
    const { showSnackbarAlert } = useSnackbarAlert();
    const { getStatusTranslation } = useRecipientStatus();

    useEffect(() => {
        if (anchor) {
            refetchUserRoleData();
            refetchUserTeamData();
        }
    }, [anchor, refetchUserRoleData, refetchUserTeamData]);

    const planType = teamData && teamData.teams.length > 0 ? teamData.teams[0].planType : undefined;
    const licenseType =
        userRoleData && userRoleData.user_roles_by_pk ? userRoleData.user_roles_by_pk.licenseType : undefined;

    const owner = teamData && teamData.teams.length > 0 ? teamData.teams[0].owner[0]?.fullName : undefined;

    const nonArchivedCount = sequenceData
        ? sequenceData.active.aggregate!.count +
          sequenceData.emailDisconnected.aggregate!.count +
          sequenceData.ready.aggregate!.count +
          sequenceData.draft.aggregate!.count +
          sequenceData.paused.aggregate!.count
        : 0;

    const canCreateNewSequence =
        planType && licenseType ? canCreateSequences(planType, licenseType, nonArchivedCount) : false;

    const handleSubmenuPopoverClose = () => setAnchor(undefined);

    const handlePauseSequence = async () => {
        await updateSequence({ variables: { id: sequence.id, set: { status: SequenceStatusEnum.Paused } } });
        showSnackbarAlert({
            severity: 'success',
            message: translate('sequence-is-now-paused', { title: sequence.title }),
        });
        hideModal();
        trackEvent('click_pause', { sequence_id: sequence.id, value: 'sequence' });
    };
    const handlePauseSequenceConfirm = () => {
        handleSubmenuPopoverClose();
        showModal({
            title: translate('sequence-list-table.submenu.pause-modal-header', { title: sequence.title }),
            description: translate('sequence-list-table.submenu.pause-modal-description'),
            confirmButton: {
                text: translate('sequence-list-table.submenu.pause-modal-pause-now-button-label'),
                onClick: handlePauseSequence,
            },
            cancelButton: {
                text: translate('sequence-list-table.submenu.modal-cancel-button-label'),
                onClick: hideModal,
            },
        });
    };

    const handleResumeSequence = async () => {
        await updateSequence({ variables: { id: sequence.id, set: { status: SequenceStatusEnum.Active } } });
        showSnackbarAlert({
            severity: 'success',
            message: translate('sequence-is-now-active', { title: sequence.title }),
        });
        hideModal();
        trackEvent('click_resume', { sequence_id: sequence.id, value: 'sequence' });
    };
    const handleResumeSequenceConfirm = () => {
        handleSubmenuPopoverClose();

        if (licenseType === LicenseTypesEnum.Reviewer) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('sequence-cannot-be-resumed-license-type-reviewer', { title: sequence.title }),
            });
            hideModal();
        } else if (planType === PlanTypesEnum.Expired) {
            showSnackbarAlert({
                severity: 'error',
                message: translate.rich('sequence-cannot-be-resumed-plan-type-expired', {
                    owner,
                    link: Link,
                    email: SUPPORT_HIREFLOW_EMAIL,
                }),
            });
            hideModal();
        } else {
            showModal({
                title: translate('sequence-list-table.submenu.resume-modal-header', { title: sequence.title }),
                description: translate('sequence-list-table.submenu.resume-modal-description'),
                confirmButton: {
                    text: translate('sequence-list-table.submenu.resume-modal-resume-now-button-label'),
                    onClick: handleResumeSequence,
                },
                cancelButton: {
                    text: translate('sequence-list-table.submenu.modal-cancel-button-label'),
                    onClick: hideModal,
                },
            });
        }
    };

    const handleArchiveSequence = async () => {
        await updateSequence({ variables: { id: sequence.id, set: { status: SequenceStatusEnum.Archived } } });
        showSnackbarAlert({
            severity: 'success',
            message: translate('sequence-is-now-archived', { title: sequence.title }),
        });
        hideModal();
        trackEvent('click_archive', { sequence_id: sequence.id, value: 'sequence' });
    };
    const handleArchiveSequenceConfirm = () => {
        handleSubmenuPopoverClose();
        showModal({
            title: translate('sequence-list-table.submenu.archive-modal-header', { title: sequence.title }),
            description: translate('sequence-list-table.submenu.archive-modal-description'),
            confirmButton: {
                text: translate('sequence-list-table.submenu.archive-modal-archive-now-button-label'),
                onClick: handleArchiveSequence,
            },
            cancelButton: {
                text: translate('sequence-list-table.submenu.modal-cancel-button-label'),
                onClick: hideModal,
            },
        });
    };

    const handleEditSequence = () => {
        if (sequence.status === SequenceStatusEnum.Draft) {
            router.push(appRoutes.sequences.draft.index(sequence.id));
        } else {
            const pathname = appRoutes.sequences.index(sequence.id);
            if (pathname === router.asPath) {
                setCurrentTab(1);
                handleSubmenuPopoverClose();
            } else {
                router.push({ pathname, query: { tab: 'edit' } });
            }
        }
    };

    const handleDuplicateSequence = async () => {
        trackEvent('click_duplicate', { value: 'sequence' });

        const { data } = await getSendFromAccounts();

        let defaultSendFromEmailAccount: string | undefined;
        if (data?.send_from_accounts) {
            const sendFromAccounts = data.send_from_accounts;
            const userSendFromAccount = sendFromAccounts.find(
                (emailAccount) => emailAccount.email === session?.user.email
            );

            if (sendFromAccounts.length === 1) {
                defaultSendFromEmailAccount = sendFromAccounts[0].id;
            } else if (userSendFromAccount) {
                defaultSendFromEmailAccount = userSendFromAccount.id;
            }
        }

        const res = await duplicateSequence({
            variables: {
                sequence: {
                    title: translate('duplicate-sequence-title', { title: sequence.title }),
                    color: sequence.color,
                    emailPreference: sequence.emailPreference,
                    // new sequence will be in ready status, this allows stages to be removed from the new sequence
                    status: SequenceStatusEnum.Ready,
                    stages: {
                        data: sequence.stages.map((stage) => ({
                            content: stage.content,
                            numberOfDays: stage.numberOfDays,
                            replyToLastEmail: stage.replyToLastEmail,
                            sendFromAccountId: canFullyDuplicateSequence(sequence)
                                ? stage.sendFromAccountId
                                : defaultSendFromEmailAccount,
                            sendTime: stage.sendTime,
                            subject: stage.subject,
                            sendTimeOfDay: stage.sendTimeOfDay,
                            sendTypeOfDays: stage.sendTypeOfDays,
                            stageIndex: stage.stageIndex,
                            signature: canFullyDuplicateSequence(sequence)
                                ? stage.signature
                                : SignatureOptionsEnum.HireflowSettings,
                            ...(canFullyDuplicateSequence(sequence) && {
                                bcc: stage.bcc,
                                cc: stage.cc,
                                useSignature: stage.useSignature,
                            }),
                        })),
                    },
                    ...(canFullyDuplicateSequence(sequence) && {
                        projects: {
                            data: sequence.projects.map((mapping) => ({
                                projectId: mapping.project.id,
                            })),
                        },
                    }),
                },
                sequenceActivity: {
                    sequenceId: sequence.id,
                    userId: session?.user.id,
                    oldValue: '',
                    newValue: '',
                    type: SequenceActivityTypesEnum.Duplicated,
                },
            },
        });

        const duplicatedSequenceId = res?.data?.insert_sequences?.returning?.[0]?.id;
        if (!duplicatedSequenceId) {
            logger.error(`Failed to duplicate sequence ${sequence.id}`);
            return;
        }

        // clone the sequence ats mappings

        const { data: ashbySequenceMappingData } = await getAshbySequenceMappingData();
        if (ashbySequenceMappingData?.data && ashbySequenceMappingData.data.length > 0) {
            const mappingData = ashbySequenceMappingData.data[0];
            await createAshbySequenceMapping({
                variables: {
                    ashby_sequence_mappings: {
                        sequenceId: duplicatedSequenceId,
                        syncProspectToggleActive: mappingData.syncProspectToggleActive,
                        jobId: mappingData.jobId,
                        assigneeId: mappingData.assigneeId,
                        createEventType: mappingData.createEventType,
                        defaultAddStageId: mappingData.defaultAddStageId,
                        repliedMoveStageActive: mappingData.repliedMoveStageActive,
                        repliedStageId: mappingData.repliedStageId,
                        interestedReplyMoveStageActive: mappingData.interestedReplyMoveStageActive,
                        interestedReplyStageId: mappingData.interestedReplyStageId,
                        meetingBookedMoveStageActive: mappingData.meetingBookedMoveStageActive,
                        meetingBookedStageId: mappingData.meetingBookedStageId,
                    },
                },
            });
        }

        const { data: greenhouseSequenceMappingData } = await getGreenhouseSequenceMappingData();
        if (greenhouseSequenceMappingData?.data && greenhouseSequenceMappingData.data.length > 0) {
            const mappingData = greenhouseSequenceMappingData.data[0];
            await createGreenhouseSequenceMapping({
                variables: {
                    greenhouse_sequence_mappings: {
                        sequenceId: duplicatedSequenceId,
                        syncProspectToggleActive: mappingData.syncProspectToggleActive,
                        jobId: mappingData.jobId,
                        assigneeId: mappingData.assigneeId,
                        createEventType: mappingData.createEventType,
                        interestedReplyMoveStageActive: mappingData.interestedReplyMoveStageActive,
                        interestedReplyStageId: mappingData.interestedReplyStageId,
                        meetingBookedMoveStageActive: mappingData.meetingBookedMoveStageActive,
                        meetingBookedStageId: mappingData.meetingBookedStageId,
                        createAsApplicationTypeOverride: mappingData.createAsApplicationTypeOverride,
                        interestedReplyConvertActive: mappingData.interestedReplyConvertActive,
                        meetingBookedConvertActive: mappingData.meetingBookedConvertActive,
                    },
                },
            });
        }

        const { data: leverSequenceMappingData } = await getLeverSequenceMappingData();
        if (leverSequenceMappingData?.data && leverSequenceMappingData.data.length > 0) {
            const mappingData = leverSequenceMappingData.data[0];
            await createLeverSequenceMapping({
                variables: {
                    lever_sequence_mappings: {
                        sequenceId: duplicatedSequenceId,
                        syncProspectToggleActive: mappingData.syncProspectToggleActive ?? true,
                        postingId: mappingData.postingId,
                        assigneeId: mappingData.assigneeId,
                        createEventType: mappingData.createEventType,
                        defaultAddStageId: mappingData.defaultAddStageId,
                        repliedMoveStageActive: mappingData.repliedMoveStageActive,
                        repliedStageId: mappingData.repliedStageId,
                        interestedReplyMoveStageActive: mappingData.interestedReplyMoveStageActive,
                        interestedReplyStageId: mappingData.interestedReplyStageId,
                        meetingBookedMoveStageActive: mappingData.meetingBookedMoveStageActive,
                        meetingBookedStageId: mappingData.meetingBookedStageId,
                    },
                },
            });
        }

        // slight delay so that the redirect is not jarring for the user
        // eslint-disable-next-line no-promise-executor-return, no-magic-numbers
        await new Promise((resolve) => setTimeout(resolve, 100));

        handleSubmenuPopoverClose();
        showSnackbarAlert({ severity: 'success', message: translate('sequence-has-been-duplicated') });
        router.push({
            pathname: appRoutes.sequences.index(duplicatedSequenceId),
            query: { tab: 'settings' },
        });
    };

    const handleDownloadCsv = async () => {
        trackEvent('click_download_CSV', { page_name: 'sequence' });
        const { data } = await getAllRecipients();
        const formattedData =
            data?.recipients.map((recipient) => {
                const { profile } = recipient.prospect;

                const firstName = profile?.firstNameCustomized ?? profile?.firstName;
                const lastName = profile?.lastNameCustomized ?? profile?.lastName;
                const company = profile?.latestCompanyCustomized ?? profile?.latestCompany;
                const jobTitle = profile?.titleCustomized ?? profile?.title;
                const emailAddress =
                    recipient.contacts.length > 0
                        ? recipient.prospect.contacts!.find((f) => f.id === recipient.contacts[0].contactId)?.value
                        : '';
                const linkedinUrl = getPrimaryProfileUrl(recipient.prospect.urls!);
                const dateAdded = dateFormatter(recipient.createdAt);
                const { status, statusDate } = getStatusTranslation(recipient);
                const numberOfOpens = String(recipient.emailStats?.[0]?.opens || 0);
                const numberOfClicks = String(recipient.emailStats?.[0]?.clicks || 0);

                handleSubmenuPopoverClose();
                return {
                    'First Name': firstName,
                    'Last Name': lastName,
                    'Email Address': emailAddress,
                    'LinkedIn URL': linkedinUrl,
                    Company: company,
                    'Job Title': jobTitle,
                    'Date Added': dateAdded,
                    Status: status,
                    'Date of Most Recent Status': statusDate,
                    'Number of Opens': numberOfOpens,
                    'Number of Clicks': numberOfClicks,
                };
            }) ?? [];

        return formattedData;
    };

    const handleDownloadCsvError = () => {
        showSnackbarAlert({
            severity: 'error',
            message: translate('sequence-download-csv-error'),
        });
    };

    const getActionButtons = () => {
        const editButton = (
            <SubmenuButton key="edit" disabled={!canEditSequence(sequence)} onClick={handleEditSequence}>
                {translate('sequence-list-table.submenu.edit-sequence-label')}
            </SubmenuButton>
        );
        const duplicateButton = (
            <SubmenuButton
                key="duplicate"
                disabled={!canCreateNewSequence || sequence.status === SequenceStatusEnum.EmailDisconnected}
                onClick={handleDuplicateSequence}
            >
                {translate('sequence-list-table.submenu.duplicate-label')}
            </SubmenuButton>
        );
        const pauseButton = (
            <SubmenuButton key="pause" disabled={!canEditSequence(sequence)} onClick={handlePauseSequenceConfirm}>
                {translate('sequence-list-table.submenu.pause-label')}
            </SubmenuButton>
        );
        const resumeButton = (
            <SubmenuButton key="resume" disabled={!canEditSequence(sequence)} onClick={handleResumeSequenceConfirm}>
                {translate('sequence-list-table.submenu.resume-label')}
            </SubmenuButton>
        );
        const archiveButton = (
            <SubmenuButton key="archive" disabled={!canEditSequence(sequence)} onClick={handleArchiveSequenceConfirm}>
                {translate('sequence-list-table.submenu.archive-label')}
            </SubmenuButton>
        );
        const downloadButton = (
            <CsvDownloader
                key="download"
                datas={handleDownloadCsv}
                filename={sequence.title}
                wrapColumnChar='"'
                handleError={handleDownloadCsvError}
            >
                <SubmenuButton>{translate('sequence-list-table.submenu.download-csv')}</SubmenuButton>
            </CsvDownloader>
        );

        switch (sequence.status) {
            case SequenceStatusEnum.Active:
                return [editButton, duplicateButton, pauseButton, archiveButton, downloadButton];
            case SequenceStatusEnum.Ready:
                return [editButton, duplicateButton, pauseButton, archiveButton, downloadButton];
            case SequenceStatusEnum.EmailDisconnected:
                return [editButton, duplicateButton, pauseButton, archiveButton, downloadButton];
            case SequenceStatusEnum.Draft:
                return [editButton, duplicateButton, archiveButton, downloadButton];
            case SequenceStatusEnum.Paused:
                return [editButton, duplicateButton, resumeButton, archiveButton, downloadButton];
            case SequenceStatusEnum.Archived:
                return [duplicateButton, downloadButton];
            default:
                return [];
        }
    };

    return (
        <Popover
            css={css`
                .MuiPaper-root {
                    // !important is needed to make the transform stick.
                    transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                }
            `}
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={handleSubmenuPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box
                css={css`
                    border-radius: 4px;
                `}
            >
                {getActionButtons()}
            </Box>
        </Popover>
    );
};

export { SequenceListSubmenu };
