import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedMail05: FC<SvgProps> = ({ theme, stroke, width, height, ...rest }) => (
    <svg
        width={width || '64'}
        height={height || '64'}
        viewBox="0 0 64 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            // eslint-disable-next-line max-len
            d="M36.6505 7.52304L56.7252 20.5716C57.4345 21.0327 57.7892 21.2632 58.0462 21.5707C58.2737 21.8429 58.4445 22.1578 58.5488 22.4969C58.6666 22.8799 58.6666 23.3029 58.6666 24.149V43.7002C58.6666 48.1806 58.6666 50.4208 57.7946 52.1321C57.0277 53.6374 55.8038 54.8612 54.2985 55.6282C52.5872 56.5002 50.347 56.5002 45.8666 56.5002H18.1333C13.6528 56.5002 11.4126 56.5002 9.70133 55.6282C8.19603 54.8612 6.97219 53.6374 6.2052 52.1321C5.33325 50.4208 5.33325 48.1806 5.33325 43.7002V24.149C5.33325 23.3029 5.33325 22.8799 5.45104 22.4969C5.55531 22.1578 5.72618 21.8429 5.95365 21.5707C6.21061 21.2632 6.56529 21.0327 7.27464 20.5716L27.3493 7.52304M36.6505 7.52304C34.9672 6.42889 34.1255 5.88181 33.2187 5.66895C32.4171 5.48079 31.5828 5.48079 30.7812 5.66895C29.8743 5.88181 29.0327 6.42889 27.3493 7.52304M36.6505 7.52304L53.163 18.2561C54.9972 19.4484 55.9143 20.0446 56.232 20.8005C56.5096 21.4612 56.5096 22.2058 56.232 22.8665C55.9143 23.6225 54.9972 24.2186 53.163 25.4109L36.6505 36.144C34.9672 37.2381 34.1255 37.7852 33.2187 37.9981C32.4171 38.1862 31.5828 38.1862 30.7812 37.9981C29.8743 37.7852 29.0327 37.2381 27.3493 36.144L10.8369 25.4109C9.00262 24.2186 8.08549 23.6225 7.76785 22.8665C7.49026 22.2058 7.49026 21.4612 7.76785 20.8005C8.08549 20.0446 9.00262 19.4484 10.8369 18.2561L27.3493 7.52304M57.3333 51.1668L39.6191 35.1668M24.3808 35.1668L6.66659 51.1668"
            stroke={stroke || theme?.palette.primary.main}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Mail05 = withTheme(ThemedMail05);

export { Mail05 };
