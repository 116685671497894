import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface RequestSourcerSpecificationsFormButtonsProps {
    onGoToPreviousStep: () => void;
}

const RequestSourcerSpecificationsFormButtons: FC<RequestSourcerSpecificationsFormButtonsProps> = (props) => {
    const { onGoToPreviousStep } = props;
    const translate = useTranslations('sourcing');

    return (
        <Box>
            <Button onClick={onGoToPreviousStep} variant="outlined">
                {translate('request-a-sourcer-form.back-button')}
            </Button>
        </Box>
    );
};

export { RequestSourcerSpecificationsFormButtons };
