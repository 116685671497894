import { css, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { AlertTriangle } from 'shared/components/svgs';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';

interface AlertBarProps {
    message: string | ReactNode;
    buttonLabel: string;
    onClick(): void;
    showAlert?: boolean;
}

const AlertBar: FC<AlertBarProps> = (props) => {
    const theme = useTheme();
    const { buttonLabel, message, onClick, showAlert } = props;
    return showAlert ? (
        <Box
            css={css`
                display: flex;
                margin: 16px 0;
                padding: 16px 24px;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                border-radius: 4px;
                justify-content: space-between;
                background: ${colors.reds.persianRed};
                color: ${theme.palette.common.white};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Box
                    css={css`
                        margin-right: 10px;
                    `}
                >
                    <AlertTriangle stroke={theme.palette.common.white} />
                </Box>

                <Box>{message}</Box>
            </Box>

            <Button
                css={css`
                    color: ${theme.palette.common.white};
                    border: 1px solid ${theme.palette.common.white};
                    min-width: unset;
                `}
                onClick={onClick}
                disableRipple
            >
                {buttonLabel}
            </Button>
        </Box>
    ) : null;
};

export { AlertBar };
