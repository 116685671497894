import { gql } from '@apollo/client';

import { OrderBy } from 'codegen/graphql';

export const emailAccountsDefaultOrderBy = 'createdAt';
export const emailAccountsDefaultOrderByDirection = OrderBy.Asc;

export const emailAccountsDefaultVariables = {
    orderBy: { [emailAccountsDefaultOrderBy]: emailAccountsDefaultOrderByDirection },
    where: {},
};

export const EMAIL_ACCOUNTS = gql`
    query EmailAccounts($orderBy: email_accounts_order_by!, $where: email_accounts_bool_exp!) {
        email_accounts(order_by: [$orderBy], where: $where) {
            email
            status
            dailySendingLimit
            send_from_accounts {
                id
                status
                user {
                    id
                    fullName
                }
                sequenceStages: sequence_stages {
                    id
                    isDeleted
                }
                customStages: recipient_custom_stages {
                    id
                }
            }
        }
    }
`;

export const UPDATE_EMAIL_ACCOUNT = gql`
    mutation UpdateEmailAccount($email: String!, $set: email_accounts_set_input!) {
        update_email_accounts_by_pk(pk_columns: { email: $email }, _set: $set) {
            email
            status
            dailySendingLimit
            signature
            signatureLastSynced
            user {
                id
                fullName
            }
        }
    }
`;

export const REMOVE_EMAIL_ACCOUNT = gql`
    mutation RemoveEmailAccount($email: String!) {
        update_email_accounts_by_pk(pk_columns: { email: $email }, _set: { status: disconnected }) {
            email
            status
            user {
                id
                fullName
            }
        }
    }
`;

export const GET_TEAM_MEMBERS_EMAIL_ACCOUNTS = gql`
    query TeamMemberEmailAccounts($teamId: String!) {
        email_accounts(where: { user: { teamId: { _eq: $teamId } } }) {
            email
            user {
                id
                fullName
                licenseType
            }
        }
    }
`;
