import { gql } from '@apollo/client';

export const GET_USER_ROLE = gql`
    query GetUserRole($userId: String!) {
        user_roles_by_pk(userId: $userId) {
            userId
            licenseType
            role
        }
    }
`;
