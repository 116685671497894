import styled from '@emotion/styled';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

import { FC } from 'shared/types';

interface TabsProps extends MuiTabsProps {
    disableScroll?: boolean | undefined;
}

const Tabs: FC<TabsProps> = (props) => {
    const { disableScroll = false, children, ...rest } = props;

    return (
        <MuiTabs
            {...rest}
            variant={disableScroll ? 'standard' : 'scrollable'}
            scrollButtons="auto"
            allowScrollButtonsMobile={!disableScroll}
        >
            {children}
        </MuiTabs>
    );
};

const StyledTabs = styled(Tabs)`
    min-width: 256px;
`;

export { StyledTabs as Tabs };
