import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Button, Title } from 'shared/components/presentational';
import { Filter } from 'shared/components/svgs';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface IconStatsCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    onStatsFilterChange: () => void;
}

const IconStatsCard: FC<IconStatsCardProps> = (props) => {
    const { title, description, icon, onStatsFilterChange } = props;
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Box
            component={Button}
            onMouseEnter={() => {
                setIsOpen(true);
            }}
            onMouseLeave={() => {
                setIsOpen(false);
            }}
            onClick={onStatsFilterChange}
            css={css`
                display: flex;
                flex-direction: column;
                padding: ${spacing.none};
                color: ${theme.palette.common.black};
                border-radius: ${spacing.none};
                transition: all 0.1s ease-out;
                position: relative;
                background-color: ${theme.palette.common.white};
                border: 1px solid ${theme.palette.grey[500]};
                border-radius: 4px;
                height: auto;

                &:hover {
                    border: 1px solid ${theme.palette.primary.main};
                }
            `}
        >
            <Box
                css={css`
                    padding: ${spacing.space16px};
                    width: 215px;
                    display: flex;
                    align-items: center;
                `}
            >
                <Box
                    css={css`
                        background-color: ${theme.palette.grey[300]};
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-left: ${spacing.space16px};
                        margin-right: ${spacing.space16px};

                        @media (max-width: 1000px) {
                            width: 33px;
                            height: 33px;
                        }
                    `}
                >
                    {icon}
                </Box>

                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <Box
                        component="p"
                        css={css`
                            font-size: ${fontSizes.f12};
                            margin: ${spacing.none};
                            white-space: nowrap;
                        `}
                    >
                        {description}
                    </Box>
                    <Title
                        type="h3"
                        css={css`
                            font-size: ${fontSizes.f24};
                            margin: ${spacing.none};
                            text-align: start;
                        `}
                    >
                        {title}
                    </Title>
                </Box>
                {isOpen && (
                    <Filter
                        css={css`
                            position: absolute;
                            right: ${spacing.space16px};
                        `}
                    />
                )}
            </Box>
        </Box>
    );
};

export { IconStatsCard };
