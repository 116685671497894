import { css } from '@emotion/react';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';

import { ProspectPanelComponent } from 'prospects/prospect-panel-component';
import { PanelContext, ProspectPanelProvider } from 'prospects/prospect-panel-provider';
import { PanelTabEnum } from 'prospects/utils';
import { Popover } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface ProspectPanelProps {
    isPanelOpen: boolean;
    tab?: PanelTabEnum;
    hideProspectPanel: () => void;
    prospectContext: PanelContext;
}

const ProspectPanel: FC<ProspectPanelProps> = (props) => {
    const { isPanelOpen, hideProspectPanel, prospectContext, tab } = props;

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={{ top: 0, left: 0 }}
            open={isPanelOpen}
            onClose={hideProspectPanel}
            PaperProps={{
                style: {
                    width: extensionPanelWidth,
                    height: '100%',
                },
            }}
            css={css`
                left: calc(100vw - 580px);
            `}
        >
            <ProspectPanelProvider context={prospectContext}>
                <ProspectPanelComponent hideProspectPanel={hideProspectPanel} defaultTab={tab} />
            </ProspectPanelProvider>
        </Popover>
    );
};

export { ProspectPanel };
