import { css, useTheme } from '@mui/material';
import { getOutlookMessageWebLink } from 'hireflow-shared/lib/microsoft/outlook/message-parser';
import { useTranslations } from 'next-intl';

import { trackEvent } from 'analytics';
import { EmailAccountProviderEnum } from 'codegen/graphql';
import { CustomInfoMessage, MessageExchange } from 'prospects';
import { EmailToAndFrom } from 'prospects/email-to-and-from';
import { getMessageViewDetails } from 'prospects/utils/message-view';
import { StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { EmailSignaturePreview } from 'shared/components/editor';
import { Button, Chip, NoSsr } from 'shared/components/presentational';
import { ArrowNarrowUpRight } from 'shared/components/svgs';
import { windowFeatures, windowTarget } from 'shared/constants';
import { useProspectPanel, useSession } from 'shared/hooks';
import { dateFormatter } from 'shared/services';
import { colors, fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface MessageViewProps {
    message: MessageExchange;
}

const MessageView: FC<MessageViewProps> = (props) => {
    const theme = useTheme();
    const { message } = props;
    const { session, loaded } = useSession();
    const { prospectInfo } = useProspectPanel();
    const translate = useTranslations('prospects.tabs.conversations-tab');

    const handleRedirectToGmail = () => {
        // eslint-disable-next-line max-len
        const url = `https://mail.google.com/mail/?authuser=${message.emailMessage.account}#all/${message.emailMessage.threadId}`;
        window.open(url, windowTarget, windowFeatures);
        trackEvent('click_gmail_link', {
            prospect_id: prospectInfo?.id,
            recipientId: message.emailMessage.recipientId,
        });
    };

    const handleRedirectToOutlook = () => {
        const url = getOutlookMessageWebLink(message.emailMessage.data);
        window.open(url, windowTarget, windowFeatures);
        trackEvent('click_outlook_link', {
            prospect_id: prospectInfo?.id,
            recipientId: message.emailMessage.recipientId,
        });
    };

    const handleRedirect = () => {
        if (message.emailMessage.provider === EmailAccountProviderEnum.Gmail) {
            handleRedirectToGmail();
        } else {
            handleRedirectToOutlook();
        }
    };

    const emailMessageDetails =
        !message.inMail && message.emailMessage?.data
            ? getMessageViewDetails(message.emailMessage.data, message.emailMessage.provider)
            : undefined;

    const toEmail = emailMessageDetails ? (emailMessageDetails.to ? emailMessageDetails.to[0] : undefined) : undefined;
    const fromEmail = emailMessageDetails ? emailMessageDetails.from : undefined;
    const content = emailMessageDetails?.content?.trim() ?? '';

    const title = message.fromProspect
        ? translate('prospect-reply-label', { sequenceName: message.sequenceTitle })
        : message.customStage
        ? translate('stage-personalized-title-label', {
              index: message.stageIndex + 1,
              sequenceName: message.sequenceTitle,
          })
        : translate('stage-title-label', {
              index: message.stageIndex + 1,
              sequenceName: message.sequenceTitle,
          });

    const timeStamp = (
        <Box
            component="span"
            css={css`
                margin-left: 4px;
                color: ${theme.palette.grey[600]};
                font-size: ${fontSizes.f12};
                text-transform: uppercase;
            `}
        >
            <NoSsr>{dateFormatter(message.internalTime)}</NoSsr>
        </Box>
    );

    const statusChip = message.fromProspect ? (
        <Box
            css={css`
                display: flex;
                align-items: center;
            `}
        >
            <Box
                css={css`
                    .status-ellipsis-text {
                        font-size: ${fontSizes.f12};
                        padding: 4px;
                    }
                `}
            >
                <StatusChip status={message.status} />
            </Box>
            {timeStamp}
        </Box>
    ) : (
        <Box>
            <Chip
                css={css`
                    padding: 2px;
                    font-size: ${fontSizes.f12};
                    background-color: ${colors.greens.narvik};
                `}
                size="small"
                label={translate('sent-status')}
            />
            {timeStamp}
        </Box>
    );

    if (!loaded) return null;

    return message.inMail && prospectInfo?.inMailSentTime ? (
        <Box key="in-mail-message">
            <CustomInfoMessage message={translate('mark-inmail-sent')} time={prospectInfo.inMailSentTime} />
        </Box>
    ) : (
        <Box
            key={`sequence-message-${message.emailMessage.id}`}
            css={css`
                margin-top: 24px;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                        font-weight: ${fontWeights.bold};
                        padding-top: 4px;
                    `}
                >
                    {title}
                </Box>
                {statusChip}
            </Box>
            <EmailToAndFrom to={toEmail} from={fromEmail} />
            <Box
                css={css`
                    margin-top: 12px;
                    padding: 16px;
                    background-color: ${message.fromProspect ? colors.greens.narvik30 : theme.palette.common.white};
                    border: 1px solid ${colors.grays.gallery};
                    border-radius: 4px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <Box
                        css={css`
                            font-size: ${fontSizes.f12};
                            color: ${colors.grays.tundora};
                            padding: 6px 0px;
                        `}
                    >
                        {translate('email-preview-label')}
                    </Box>

                    {message.emailMessage.email_account?.userId === session!.user.id && (
                        <Box>
                            <Button
                                endIcon={<ArrowNarrowUpRight stroke={theme.palette.primary.main} />}
                                css={css`
                                    padding: 0;
                                    font-size: ${fontSizes.f14};
                                `}
                                onClick={handleRedirect}
                            >
                                {message.emailMessage.provider === EmailAccountProviderEnum.Gmail
                                    ? translate('gmail-button-label')
                                    : translate('outlook-button-label')}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    css={css`
                        font-size: ${fontSizes.f16};
                        color: ${theme.palette.grey[200]};
                    `}
                >
                    <EmailSignaturePreview signatureHtml={content} />
                </Box>
            </Box>
        </Box>
    );
};

export { MessageView };
