import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { CreateNewSequenceButton } from 'sequences/components/presentational/create-new-sequence-button';
import { EmptyState } from 'shared/components/composite';
import { SequenceIcon } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface EmptySequencesNoticeProps {}

const EmptySequencesNotice: FC<EmptySequencesNoticeProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('sequence');

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <SequenceIcon
                    css={css`
                        width: 64px;
                        height: 64px;
                    `}
                    stroke={theme.palette.common.black}
                />
            }
            title={translate('no-sequences-yet')}
            description={translate('sequence-description')}
            button={<CreateNewSequenceButton />}
        />
    );
};

export { EmptySequencesNotice };
