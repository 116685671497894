import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import {
    personalizeCalendarLinkVar,
    personalizeMagicSentence,
    personalizeOptions,
    personalizeSenderFirstNameVar,
} from 'hireflow-shared/utils/email-drafting-utils';
import { useEffect, useState } from 'react';

import { CoworkerConnectionTooltip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Select, SelectOption } from 'shared/components/form';
import { Chip } from 'shared/components/presentational';
import { useSession } from 'shared/hooks/use-session';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

const defaultNumOptionsToShow = 4;

interface PersonalizeOptionsProps {
    onClick: (variable: string) => void;
    numOptionsToShow?: number;
    disabled?: boolean;
    showMagicSentence?: boolean;
}

const PersonalizeOptions: FC<PersonalizeOptionsProps> = (props) => {
    const { onClick, numOptionsToShow = defaultNumOptionsToShow, disabled = false, showMagicSentence = true } = props;
    const theme = useTheme();
    const { session } = useSession();

    const [selectOptions, setSelectOptions] = useState<SelectOption[]>(
        personalizeOptions.map((p) => ({ label: p, value: p }))
    );

    const availableSelectOptions: SelectOption[] = selectOptions.map((option) => {
        if (option.value === personalizeMagicSentence && !showMagicSentence) return { ...option, disabled: true };

        return option;
    });

    const handleClick = (variable: string) => {
        onClick(variable);
    };

    useEffect(() => {
        if (session) {
            let updated = selectOptions;
            const { calendarLink, firstName } = session.user;

            if (!calendarLink || calendarLink.length === 0) {
                updated = updated.map((u) => (u.value === personalizeCalendarLinkVar ? { ...u, disabled: true } : u));
            }
            if (firstName.length === 0) {
                updated = updated.map((u) =>
                    u.value === personalizeSenderFirstNameVar ? { ...u, disabled: true } : u
                );
            }

            setSelectOptions(updated);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <Box
            css={css`
                display: flex;
                margin-bottom: ${spacing.space16px};
                justify-content: space-between;
            `}
        >
            {availableSelectOptions.slice(0, numOptionsToShow).map((option) =>
                option.value === personalizeMagicSentence ? (
                    <CoworkerConnectionTooltip
                        key={option.value}
                        disabledCoworkerConnectionSentence={!showMagicSentence}
                    >
                        <Chip
                            clickable
                            onClick={() => handleClick(option.value)}
                            variant="outlined"
                            label={option.label}
                            css={css`
                                margin-right: 10px;
                            `}
                            disabled={disabled || option.disabled}
                        />
                    </CoworkerConnectionTooltip>
                ) : (
                    <Chip
                        clickable
                        key={option.value}
                        onClick={() => handleClick(option.value)}
                        variant="outlined"
                        label={option.label}
                        css={css`
                            margin-right: 10px;
                        `}
                        disabled={disabled || option.disabled}
                    />
                )
            )}

            <Select
                isDefault
                options={availableSelectOptions.slice(numOptionsToShow)}
                name="personalizeOptions"
                width="40px"
                value=""
                onChange={(e) => handleClick(e.target.value as string)}
                css={css`
                    margin-left: 3px;

                    .MuiSelect-select {
                        padding: 10px 32px 0 10px;
                    }

                    .MuiSelect-icon {
                        top: 5px !important;
                        left: 5px !important;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid ${theme.palette.primary.main};
                    }
                `}
                disabled={disabled}
            />
        </Box>
    );
};

export { PersonalizeOptions };
