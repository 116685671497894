import { css, useTheme } from '@mui/material';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';
import { useEffect, useRef, useState } from 'react';

import { ProspectTabs, ProspectTitleBar, ScrollSectionEnum } from 'prospects';
import { PanelTabEnum } from 'prospects/utils';
import { Box } from 'shared/components/containers';
import { useProspectPanel } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectPanelComponentProps {
    hideProspectPanel: () => void;
    defaultTab?: PanelTabEnum;
}

const ProspectPanelComponent: FC<ProspectPanelComponentProps> = (props) => {
    const theme = useTheme();
    const { hideProspectPanel, defaultTab } = props;
    const { recipients } = useProspectPanel();

    const [tab, setTab] = useState<PanelTabEnum>(PanelTabEnum.ADD);
    const [scrollTo, setScrollTo] = useState<ScrollSectionEnum>();
    const [panelAnchor, setPanelAnchor] = useState<HTMLElement>();
    const panelRef = useRef<HTMLElement>();

    useEffect(() => {
        if (panelRef && panelRef.current) {
            setPanelAnchor(panelRef.current);
        }
    }, [panelRef]);

    useEffect(() => {
        const recipientEmailed = recipients?.find((r) => r.lastStageSentAt !== null);
        setTab(defaultTab !== undefined ? defaultTab : recipientEmailed ? PanelTabEnum.CONVERSATION : PanelTabEnum.ADD);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openProfileTabAndScroll = (section: ScrollSectionEnum) => {
        setTab(PanelTabEnum.PROFILE);
        setScrollTo(section);
    };

    const openAddTab = () => {
        setTab(PanelTabEnum.ADD);
    };

    const onScroll = () => {
        setScrollTo(undefined);
    };

    const handleTabChange = (newTab: PanelTabEnum) => {
        setTab(newTab);
    };

    return (
        <Box
            css={css`
                background-color: ${theme.palette.common.white};
                height: 100%;

                overflow-y: scroll;
                overflow-x: hidden;
            `}
        >
            <Box
                ref={panelRef}
                css={css`
                    width: ${extensionPanelWidth};
                    padding: ${spacing.space24px};
                `}
            >
                <ProspectTitleBar
                    openAddTab={openAddTab}
                    onClose={hideProspectPanel}
                    redirectToSection={openProfileTabAndScroll}
                />
            </Box>
            {panelAnchor && (
                <ProspectTabs
                    anchor={panelAnchor}
                    value={tab}
                    onChange={handleTabChange}
                    scrollToSection={scrollTo}
                    onScroll={onScroll}
                    hideProspectPanel={hideProspectPanel}
                />
            )}
        </Box>
    );
};

export { ProspectPanelComponent };
