import { ReactNode, createContext, useCallback, useContext, useRef } from 'react';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

type ScrollToTopProps = {
    children: ReactNode;
};

const ScrollToTopContext = createContext<() => void>(() => {});

export const ScrollToTopProvider: FC<ScrollToTopProps> = ({ children }) => {
    const headerRef = useRef<HTMLDivElement>(null);

    const scrollToTop = useCallback(() => {
        headerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <ScrollToTopContext.Provider value={scrollToTop}>
            <Box ref={headerRef}>{children}</Box>
        </ScrollToTopContext.Provider>
    );
};

export const useScrollToTop = () => useContext(ScrollToTopContext);
