import { gql } from '@apollo/client';

export const GET_SEQUENCE_COLLABORATOR_ACTIVITIES = gql`
    query SequenceCollaboratorActivities(
        $where: sequence_collaborator_activities_bool_exp = {}
        $limit: Int = 2000
        $offset: Int = 0
        $order_by: [sequence_collaborator_activities_order_by!] = { createdAt: desc }
    ) {
        sequence_collaborator_activities(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
            id
            user {
                id
                fullName
            }
            targetUser {
                id
                fullName
            }
            type
            createdAt
        }
    }
`;

export const CREATE_SEQUENCE_COLLABORATOR_ACTIVITIES = gql`
    mutation CreateSequenceCollaboratorActivities($activities: [sequence_collaborator_activities_insert_input!]!) {
        insert_sequence_collaborator_activities(objects: $activities) {
            affected_rows
        }
    }
`;
