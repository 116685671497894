import { useMutation } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material';
import { DEFAULT_PRIMARY_TIMEZONE } from 'hireflow-shared/models/user';
import { Timezone, timezones } from 'hireflow-shared/types/timezone';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { Select } from 'shared/components/form';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';

const PrimaryTimezone = () => {
    const translate = useTranslations('settings.account-settings.primary-timezone');
    const { session, setSession } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);

    const [isEditing, setIsEditing] = useState(false);
    const [primaryTimezone, setPrimaryTimezone] = useState<Timezone>(DEFAULT_PRIMARY_TIMEZONE);

    useEffect(() => {
        if (session) {
            setPrimaryTimezone(session.user.primaryTimezone || DEFAULT_PRIMARY_TIMEZONE);
        }
    }, [session]);

    const handleToggleEdit = () => {
        setPrimaryTimezone(session?.user.primaryTimezone || DEFAULT_PRIMARY_TIMEZONE);
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: { primaryTimezone },
            },
        });

        setSession({
            user: {
                ...session!.user,
                primaryTimezone,
            },
        });

        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const handleChangePrimaryTimezone = (event: SelectChangeEvent<unknown>) => {
        setPrimaryTimezone(event.target.value as Timezone);
    };

    const editableContent = (
        <Select
            isDefault
            parentBackgroundColor="green"
            options={timezones.map((timezone) => ({ label: translate(timezone), value: timezone }))}
            name="primaryTimezone"
            value={String(primaryTimezone)}
            width="75%"
            onChange={handleChangePrimaryTimezone}
        />
    );

    const nonEditableContent = translate(primaryTimezone);

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {isEditing ? editableContent : nonEditableContent}
        </EditableSettingsSection>
    );
};

export { PrimaryTimezone };
