import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ThemedGoogleChrome: FC<SvgProps> = ({ width, height, stroke, theme, fill, ...rest }) => (
    <svg
        width={width ?? '24'}
        height={height ?? '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            // eslint-disable-next-line max-len
            d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM12 8H21.17M3.95 6.06L8.54 14M10.88 21.94L15.46 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const GoogleChrome = withTheme(ThemedGoogleChrome);

export { GoogleChrome };
