import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { FC } from 'shared/types';
import { Translate } from 'shared/utils';

interface DetailsHeaderAtsProps {
    translate: Translate;
    link: string | undefined;
    name: string | undefined;
}

const DetailsHeaderAts: FC<DetailsHeaderAtsProps> = (props) => {
    const { translate, link, name } = props;

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                gap: 8px;

                margin-top: 2px;
                font-size: 12px;
            `}
        >
            <Box>{translate('ats-job-linked')}</Box>
            <Box component="div">{translate('circle-connector')}</Box>
            {link ? (
                <Hyperlink newTab href={link}>
                    {name ?? link}
                </Hyperlink>
            ) : (
                <Box
                    css={css`
                        opacity: 0.5;
                    `}
                >
                    {translate('no-ats-job-linked')}
                </Box>
            )}
        </Box>
    );
};

export { DetailsHeaderAts };
