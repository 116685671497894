import { useMutation } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { TextField } from 'shared/components/presentational';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';

const CalendarLink = () => {
    const translate = useTranslations('settings.email-settings.calendar-link');
    const { session, setSession } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);

    const [isEditing, setIsEditing] = useState(false);
    const [calendarLink, setCalendarLink] = useState('');

    useEffect(() => {
        if (session) {
            setCalendarLink(session.user.calendarLink || '');
        }
    }, [session]);

    const handleSave = async () => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: { calendarLink },
            },
        });

        setSession({
            user: {
                ...session!.user,
                calendarLink,
            },
        });

        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const handleChangeCalendarLink = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCalendarLink(event.target.value);
    };

    const handleToggleEdit = () => {
        setCalendarLink(session!.user.calendarLink || '');
        setIsEditing(!isEditing);
    };

    const editableContent = (
        <TextField
            value={calendarLink}
            onChange={handleChangeCalendarLink}
            label={translate('calendar-link-text-field-label')}
            fullWidth
            parentBackgroundColor="green"
        />
    );

    const nonEditableContent = calendarLink;

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {isEditing ? editableContent : nonEditableContent}
        </EditableSettingsSection>
    );
};

export { CalendarLink };
