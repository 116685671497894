import styled from '@emotion/styled';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface TabPanelProps {
    value: number;
    index: number;
}

const TabPanel: FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {children}
        </Box>
    );
};

const StyledTabPanel = styled(TabPanel)`
    padding-bottom: 16px;
`;

export { StyledTabPanel as TabPanel };
