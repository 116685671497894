import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedExpand01: FC<SvgProps> = ({ stroke, theme, width, height, fill, ...rest }) => (
    <svg
        {...rest}
        width={width || '20'}
        height={height || '20'}
        viewBox={`0 0 ${width || '20'} ${height || '20'}`}
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 8L19 1M19 1H13M19 1V7M8 12L1 19M1 19H7M1 19L1 13"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Expand01 = withTheme(ThemedExpand01);

export { Expand01 };
