import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { isEmpty, partition } from 'lodash';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

import { trackEvent } from 'analytics';
import {
    AshbyAccountByTeamIdQuery,
    AshbySequenceMappingBySequenceIdQuery,
    AshbySequenceMappingsInsertInput,
    CreateAshbySequenceMappingMutation,
    CreateGreenhouseSequenceMappingMutation,
    CreateLeverSequenceMappingMutation,
    CreateSequenceMutation,
    DeleteAshbySequenceMappingByIdMutation,
    DeleteGreenhouseSequenceMappingByIdMutation,
    DeleteLeverSequenceMappingByIdMutation,
    DeleteProjectSequenceMappingsMutation,
    GreenhouseAccountByTeamIdQuery,
    GreenhouseApplicationTypeEnum,
    GreenhouseSequenceMappingBySequenceIdQuery,
    GreenhouseSequenceMappingsInsertInput,
    HireflowEnumsAtsMappingEventsEnum,
    InsertProjectSequenceMappingsMutation,
    LeverAccountByTeamIdQuery,
    LeverSequenceMappingBySequenceIdQuery,
    LeverSequenceMappingsInsertInput,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SendFromAccountStatusEnum,
    SequenceEmailPreferenceEnum,
    SequencesQuery,
    SequenceStageSendTimeEnum,
    SequenceStageSendTimeOfDayEnum,
    SequenceStageSendTypeOfDayEnum,
    SequenceStatusEnum,
    SignatureOptionsEnum,
    UpdateAshbySequenceMappingByIdMutation,
    UpdateGreenhouseSequenceMappingByIdMutation,
    UpdateLeverSequenceMappingByIdMutation,
    UpdateSequenceAndStagesMutation,
} from 'codegen/graphql';
import { CreateSettingsForm, CreateStagesForm } from 'sequences/components/form';
import { CreateSequenceTemplate } from 'sequences/components/templates';
import { emailTemplates } from 'sequences/settings';
import { CreateEditSequenceFormValues, Stage } from 'sequences/types';
import { SequenceFormATSValidationBuilder, SequenceFormATSValidationBuilderSchemaShape } from 'sequences/utils';
import { getUnknownVariablesError, validVariables } from 'sequences/utils/unknown-variables';
import { SettingsMenu } from 'settings/components/presentational';
import { Box } from 'shared/components/containers';
import {
    CREATE_ASHBY_SEQUENCE_MAPPING,
    GET_ASHBY_ACCOUNT_BY_TEAM_ID,
    GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    CREATE_GREENHOUSE_SEQUENCE_MAPPING,
    GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
    CREATE_LEVER_SEQUENCE_MAPPING,
    GET_LEVER_ACCOUNT_BY_TEAM_ID,
    GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    UPDATE_LEVER_SEQUENCE_MAPPING_BY_ID,
    LeverSequenceMappingBySequenceIdData,
    UPDATE_ASHBY_SEQUENCE_MAPPING_BY_ID,
    GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
    UPDATE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
    AshbySequenceMappingBySequenceIdData,
    GreenhouseSequenceMappingBySequenceIdData,
    DELETE_ASHBY_SEQUENCE_MAPPING_BY_ID,
    DELETE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
    DELETE_LEVER_SEQUENCE_MAPPING_BY_ID,
} from 'shared/graphql/integrations';
import { DELETE_PROJECT_SEQUENCE_MAPPINGS, INSERT_PROJECT_SEQUENCE_MAPPINGS } from 'shared/graphql/projects';
import { GET_SEND_FROM_ACCOUNTS } from 'shared/graphql/send-from-accounts';
import {
    CREATE_SEQUENCE,
    GET_SEQUENCE_BY_ID,
    GET_SEQUENCES,
    UPDATE_SEQUENCE_AND_STAGES,
    sequencesDefaultOrderByDirection,
    sequencesDefaultOrderBy,
} from 'shared/graphql/sequences';
import { useDynamicSchemaForm, useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { appRoutes, colorPickerOptions } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateSequenceFormProps {
    storedSequenceForm: CreateEditSequenceFormValues | undefined;
    setStoredSequenceForm: (sequenceForm: CreateEditSequenceFormValues | undefined) => void;
    sequenceId?: string; // handles existing draft sequences
}

interface ValidationSchemaShape extends SequenceFormATSValidationBuilderSchemaShape {
    title: RequiredStringSchema<string | undefined, AnyObject>;
    stages: Yup.ArraySchema<any, AnyObject, any[] | undefined, any[] | undefined>;
}

const CreateSequenceForm: FC<CreateSequenceFormProps> = ({ storedSequenceForm, setStoredSequenceForm, sequenceId }) => {
    const [defaultSequenceColor, setDefaultSequenceColor] = useState(
        colorPickerOptions[Math.floor(Math.random() * colorPickerOptions.length)]
    );
    const [activeStep, setActiveStep] = useState<0 | 1>(0);

    const { session, loaded } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const translate = useTranslations('sequence');
    const router = useRouter();

    const [createSequence] = useMutation<CreateSequenceMutation>(CREATE_SEQUENCE);
    const [updateSequenceAndStages] = useMutation<UpdateSequenceAndStagesMutation>(UPDATE_SEQUENCE_AND_STAGES, {
        refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
    });

    const [deleteProjectSequenceMappings] = useMutation<DeleteProjectSequenceMappingsMutation>(
        DELETE_PROJECT_SEQUENCE_MAPPINGS,
        {
            refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
        }
    );

    const [insertProjectSequenceMappings] = useMutation<InsertProjectSequenceMappingsMutation>(
        INSERT_PROJECT_SEQUENCE_MAPPINGS,
        {
            refetchQueries: [getOperationName(GET_SEQUENCE_BY_ID) as string],
        }
    );

    const { data: lastCreatedSequence } = useQuery<SequencesQuery>(GET_SEQUENCES, {
        variables: {
            orderBy: { [sequencesDefaultOrderBy]: sequencesDefaultOrderByDirection },
            limit: 1,
            offset: 0,
            where: { userId: { _eq: session?.user.id } },
            withDetails: true,
        },
    });

    const { data: sendFromAccountsData } = useQuery<SendFromAccountsQuery, SendFromAccountsQueryVariables>(
        GET_SEND_FROM_ACCOUNTS,
        {
            variables: {
                where: {
                    status: {
                        _eq: SendFromAccountStatusEnum.Approved,
                    },
                    userId: {
                        _eq: session?.user.id,
                    },
                },
            },
        }
    );

    const [_userOwnedAccounts, sendOnBehalfAccounts] = partition(
        sendFromAccountsData?.send_from_accounts || [],
        (account) => account.email_account.user.id === account.user.id
    );

    // ashby
    const { data: ashbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(GET_ASHBY_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: ashbySequenceMappingData } = useQuery<AshbySequenceMappingBySequenceIdQuery>(
        GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            skip: !sequenceId,
            variables: { sequenceId },
        }
    );

    const [createAshbySequenceMapping] = useMutation<CreateAshbySequenceMappingMutation>(CREATE_ASHBY_SEQUENCE_MAPPING);

    const [createRefetchAshbySequenceMapping] = useMutation<CreateAshbySequenceMappingMutation>(
        CREATE_ASHBY_SEQUENCE_MAPPING,
        {
            refetchQueries: [{ query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [updateAshbySequenceMapping] = useMutation<UpdateAshbySequenceMappingByIdMutation>(
        UPDATE_ASHBY_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [deleteAshbySequenceMapping] = useMutation<DeleteAshbySequenceMappingByIdMutation>(
        DELETE_ASHBY_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_ASHBY_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    // greenhouse
    const { data: greenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: greenhouseSequenceMappingData } = useQuery<GreenhouseSequenceMappingBySequenceIdQuery>(
        GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            skip: !sequenceId,
            variables: { sequenceId },
        }
    );

    const [createGreenhouseSequenceMapping] = useMutation<CreateGreenhouseSequenceMappingMutation>(
        CREATE_GREENHOUSE_SEQUENCE_MAPPING
    );

    const [createRefetchGreenhouseSequenceMapping] = useMutation<CreateGreenhouseSequenceMappingMutation>(
        CREATE_GREENHOUSE_SEQUENCE_MAPPING,
        {
            refetchQueries: [{ query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [updateGreenhouseSequenceMapping] = useMutation<UpdateGreenhouseSequenceMappingByIdMutation>(
        UPDATE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [deleteGreenhouseSequenceMapping] = useMutation<DeleteGreenhouseSequenceMappingByIdMutation>(
        DELETE_GREENHOUSE_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_GREENHOUSE_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    // lever
    const { data: leverAccount } = useQuery<LeverAccountByTeamIdQuery>(GET_LEVER_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const { data: leverSequenceMappingData } = useQuery<LeverSequenceMappingBySequenceIdQuery>(
        GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID,
        {
            skip: !sequenceId,
            variables: { sequenceId },
        }
    );

    const [createLeverSequenceMapping] = useMutation<CreateLeverSequenceMappingMutation>(CREATE_LEVER_SEQUENCE_MAPPING);

    const [createRefetchLeverSequenceMapping] = useMutation<CreateLeverSequenceMappingMutation>(
        CREATE_LEVER_SEQUENCE_MAPPING,
        {
            refetchQueries: [{ query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [updateLeverSequenceMapping] = useMutation<UpdateLeverSequenceMappingByIdMutation>(
        UPDATE_LEVER_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const [deleteLeverSequenceMapping] = useMutation<DeleteLeverSequenceMappingByIdMutation>(
        DELETE_LEVER_SEQUENCE_MAPPING_BY_ID,
        {
            refetchQueries: [{ query: GET_LEVER_SEQUENCE_MAPPING_BY_SEQUENCE_ID, variables: { sequenceId } }],
        }
    );

    const getErrorString = (value: string) => {
        const errorMessage = getUnknownVariablesError(value);
        if (errorMessage) {
            return errorMessage.count
                ? translate('create.unknown-variables-alert-with-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                      count: errorMessage.count,
                  })
                : translate('create.unknown-variables-alert-without-count', {
                      unknownVariables: errorMessage.unknownVariablesString,
                  });
        }
        return '';
    };

    const sequenceNameMaxLength = 50;
    const validationSchemaShape: ValidationSchemaShape = {
        title: Yup.string()
            .required(translate('create.stages.error-sequence-name'))
            .max(sequenceNameMaxLength, translate('create.error-sequence-name-length')),
        stages: Yup.array().of(
            Yup.object().shape({
                sendTypeOfDays: Yup.string().required(translate('create.stages.error-generic-option')),
                subject: Yup.string()
                    .required(translate('create.stages.error-subject'))
                    .test(
                        'unknown-variables-in-subject',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
                content: Yup.string()
                    .required(translate('create.stages.error-email-content'))
                    .test(
                        'unknown-variables-in-content',
                        (message) => getErrorString(message.value ?? message.originalValue),
                        (value) => validVariables(value)
                    ),
            })
        ),
        ashbyUserId: Yup.string().notRequired(),
        ashbyRepliedStageId: Yup.string().notRequired(),
        ashbyInterestedReplyStageId: Yup.string().notRequired(),
        ashbyMeetingBookedStageId: Yup.string().notRequired(),
        greenhouseUserId: Yup.string().notRequired(),
        greenhouseInterestedReplyStageId: Yup.string().notRequired(),
        greenhouseMeetingBookedStageId: Yup.string().notRequired(),
        leverUserId: Yup.string().notRequired(),
        leverRepliedStageId: Yup.string().notRequired(),
        leverInterestedReplyStageId: Yup.string().notRequired(),
        leverMeetingBookedStageId: Yup.string().notRequired(),
    };
    const validationSchema = Yup.object().shape({ ...validationSchemaShape });

    let defaultSendFromEmailAccount = '';
    if (sendFromAccountsData?.send_from_accounts) {
        const sendFromAccounts = sendFromAccountsData.send_from_accounts;

        const userSendFromAccount = sendFromAccounts.find((emailAccount) => emailAccount.email === session?.user.email);

        if (sendFromAccounts.length === 1) {
            defaultSendFromEmailAccount = sendFromAccounts[0].id;
        } else if (userSendFromAccount) {
            defaultSendFromEmailAccount = userSendFromAccount.id;
        }
    }

    const defaultInitialValues: Omit<Stage, 'content'> = {
        bcc: [],
        cc: [],
        numberOfDays: 5,
        replyToLastEmail: true,
        sendFromAccountId: defaultSendFromEmailAccount,
        sendTime: SequenceStageSendTimeEnum.AsSoonAsPossible,
        subject: "{{First Name}}, let's connect?",
        sendTimeOfDay: SequenceStageSendTimeOfDayEnum.AutoOptimizeTime,
        sendTypeOfDays: SequenceStageSendTypeOfDayEnum.WeekDays,
        signature: SignatureOptionsEnum.ImportFromGmail,
        useSignature: false,
    };

    const initialValues: CreateEditSequenceFormValues = storedSequenceForm ?? {
        title: '',
        color: defaultSequenceColor,
        stages: [
            {
                bcc: [],
                cc: [],
                content: emailTemplates.defaultStage1,
                sendFromAccountId: defaultSendFromEmailAccount,
                replyToLastEmail: false,
                sendTime: SequenceStageSendTimeEnum.AsSoonAsPossible,
                sendTypeOfDays: SequenceStageSendTypeOfDayEnum.WeekDays,
                subject: "{{First Name}}, let's connect?",
                signature: SignatureOptionsEnum.ImportFromGmail,
                useSignature: false,
            },
            {
                ...defaultInitialValues,
                content: emailTemplates.defaultStage2,
                numberOfDays: 3,
            },
            {
                ...defaultInitialValues,
                content: emailTemplates.defaultStage3,
                numberOfDays: 6,
            },
        ],
        projects: [],
        emailPreference: SequenceEmailPreferenceEnum.PersonalEmailOnly,
        // ashby
        ashbySyncProspectToggleActive: true,
        ashbyJobId: '',
        ashbyUserId: '',
        ashbyCreateEventType: HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        ashbyDefaultAddStageId: '',
        ashbyRepliedMoveStageActive: false,
        ashbyRepliedStageId: '',
        ashbyInterestedReplyMoveStageActive: false,
        ashbyInterestedReplyStageId: '',
        ashbyMeetingBookedMoveStageActive: false,
        ashbyMeetingBookedStageId: '',
        // greenhouse
        greenhouseSyncProspectToggleActive: true,
        greenhouseJobId: '',
        greenhouseUserId: '',
        greenhouseCreateEventType: HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        greenhouseInterestedReplyMoveStageActive: false,
        greenhouseInterestedReplyStageId: '',
        greenhouseMeetingBookedMoveStageActive: false,
        greenhouseMeetingBookedStageId: '',
        greenhouseAddAsApplicationType: GreenhouseApplicationTypeEnum.Candidate,
        greenhouseInterestedReplyConvertActive: false,
        greenhouseMeetingBookedConvertActive: false,
        // lever
        leverSyncProspectToggleActive: true,
        leverJobId: '',
        leverUserId: '',
        leverCreateEventType: HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
        leverDefaultAddStageId: '',
        leverRepliedMoveStageActive: false,
        leverRepliedStageId: '',
        leverInterestedReplyMoveStageActive: false,
        leverInterestedReplyStageId: '',
        leverMeetingBookedMoveStageActive: false,
        leverMeetingBookedStageId: '',
    };

    const formMethods = useDynamicSchemaForm<CreateEditSequenceFormValues>({
        mode: 'onBlur',
        defaultValues: initialValues,
        schema: validationSchema,
    });

    // "stages" contain methods used to manipulate the stages array
    const stages = useFieldArray({
        control: formMethods.control,
        name: 'stages',
    });

    const { getValues, handleSubmit } = formMethods;

    /**
     * if the select for the first sendFromAccountId is modified we want to check the values of the
     * other selects. do nothing if the value is an empty string, but alter the sendFromAccountId value
     * for subsequent stages if they are empty strings, and the first value is not empty
     */
    const populateEmptySendFromAccountFields = useCallback(
        () => {
            const firstStageSendFromAccountId = formMethods.getValues('stages.0.sendFromAccountId');
            if (!firstStageSendFromAccountId) return;

            // we don't want to populate the remaining fields for sobo emails
            const soboIds: string[] = (sendOnBehalfAccounts ?? []).map((o) => o.id);
            if (soboIds.includes(firstStageSendFromAccountId)) return;

            const newStages: Stage[] = formMethods.getValues('stages').map((stage, index) => {
                if (index === 0) {
                    // do nothing for the first stage
                    return stage;
                }
                return stage.sendFromAccountId ? stage : { ...stage, sendFromAccountId: firstStageSendFromAccountId };
            });

            formMethods.setValue('stages', newStages);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sendOnBehalfAccounts]
    );

    useEffect(() => {
        populateEmptySendFromAccountFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.getValues('stages.0.sendFromAccountId')]);

    useEffect(() => {
        const builder = new SequenceFormATSValidationBuilder({ formMethods, translate });
        formMethods.setSchema(Yup.object().shape({ ...validationSchemaShape, ...builder.getSchemaShape() }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyRepliedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('ashbyMeetingBookedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('greenhouseMeetingBookedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverJobId'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverRepliedMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverInterestedReplyMoveStageActive'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        formMethods.getValues('leverMeetingBookedMoveStageActive'),
    ]);

    useEffect(() => {
        const recentSequenceFirstStage =
            lastCreatedSequence?.sequences.length === 1 ? lastCreatedSequence.sequences[0].stages[0] : null;
        if (recentSequenceFirstStage) {
            const signatureDefault: Pick<Stage, 'signature' | 'useSignature'> = {
                signature: recentSequenceFirstStage.signature ?? SignatureOptionsEnum.ImportFromGmail,
                useSignature: recentSequenceFirstStage.useSignature,
            };
            const formValues = formMethods.getValues();

            const newStages = formValues.stages.map((s) => ({
                ...s,
                ...signatureDefault,
            }));
            const newFormValues = Object.assign(formValues, { stages: newStages });
            formMethods.reset(newFormValues);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastCreatedSequence]);

    useEffect(() => {
        if (defaultSequenceColor === '') {
            setDefaultSequenceColor(colorPickerOptions[Math.floor(Math.random() * colorPickerOptions.length)]);
        }
    }, [defaultSequenceColor]);

    useEffect(() => {
        const data: AshbySequenceMappingBySequenceIdData = ashbySequenceMappingData?.data ?? [];
        if (data.length > 0) {
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    ashbySyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    ashbyJobId: data[0]?.jobId ?? '',
                    ashbyUserId: data[0]?.assigneeId ?? '',
                    ashbyCreateEventType: data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    ashbyDefaultAddStageId: data[0]?.defaultAddStageId ?? '',
                    ashbyRepliedMoveStageActive: data[0]?.repliedMoveStageActive ?? false,
                    ashbyRepliedStageId: data[0]?.repliedStageId ?? '',
                    ashbyInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    ashbyInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    ashbyMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    ashbyMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ashbySequenceMappingData]);

    useEffect(() => {
        const data: GreenhouseSequenceMappingBySequenceIdData = greenhouseSequenceMappingData?.data ?? [];
        if (data.length > 0) {
            const greenhouseAddAsApplicationType =
                data[0]?.createAsApplicationTypeOverride ??
                greenhouseAccount?.data[0]?.defaultCreateAsApplicationType ??
                GreenhouseApplicationTypeEnum.Candidate;
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    greenhouseSyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    greenhouseJobId: data[0]?.jobId ?? '',
                    greenhouseUserId: data[0]?.assigneeId ?? '',
                    greenhouseCreateEventType:
                        data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    greenhouseInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    greenhouseInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    greenhouseMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    greenhouseMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                    greenhouseAddAsApplicationType,
                    greenhouseInterestedReplyConvertActive: data[0]?.interestedReplyConvertActive ?? false,
                    greenhouseMeetingBookedConvertActive: data[0]?.meetingBookedConvertActive ?? false,
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [greenhouseSequenceMappingData]);

    useEffect(() => {
        const data: LeverSequenceMappingBySequenceIdData = leverSequenceMappingData?.data ?? [];
        if (data.length > 0) {
            formMethods.reset(
                {
                    ...formMethods.getValues(),
                    leverSyncProspectToggleActive: data[0]?.syncProspectToggleActive ?? true,
                    leverJobId: data[0]?.postingId ?? '',
                    leverUserId: data[0]?.assigneeId ?? '',
                    leverCreateEventType: data[0]?.createEventType ?? HireflowEnumsAtsMappingEventsEnum.AddedToSequence,
                    leverDefaultAddStageId: data[0]?.defaultAddStageId ?? '',
                    leverRepliedMoveStageActive: data[0]?.repliedMoveStageActive ?? false,
                    leverRepliedStageId: data[0]?.repliedStageId ?? '',
                    leverInterestedReplyMoveStageActive: data[0]?.interestedReplyMoveStageActive ?? false,
                    leverInterestedReplyStageId: data[0]?.interestedReplyStageId ?? '',
                    leverMeetingBookedMoveStageActive: data[0]?.meetingBookedMoveStageActive ?? false,
                    leverMeetingBookedStageId: data[0]?.meetingBookedStageId ?? '',
                },
                { keepDefaultValues: false }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leverSequenceMappingData]);

    const saveToLocalStorage = useCallback(
        () => {
            const ashbyJobSetUserNotSet: boolean =
                !isEmpty(getValues('ashbyJobId')) && isEmpty(getValues('ashbyUserId'));
            const ashbyRepliedToggleSetRepliedStageNotSet: boolean =
                getValues('ashbyRepliedMoveStageActive') && isEmpty(getValues('ashbyRepliedStageId'));
            const ashbyInterestedRepliedToggleSetInterestedRepliedStageNotSet: boolean =
                getValues('ashbyInterestedReplyMoveStageActive') && isEmpty(getValues('ashbyInterestedReplyStageId'));
            const ashbyMeetingBookedToggleSetMeetingBookedStageNotSet: boolean =
                getValues('ashbyMeetingBookedMoveStageActive') && isEmpty(getValues('ashbyMeetingBookedStageId'));

            const greenhouseJobSetUserNotSet: boolean =
                !isEmpty(getValues('greenhouseJobId')) && isEmpty(getValues('greenhouseUserId'));
            const greenhouseInterestedRepliedToggleSetInterestedRepliedStageNotSet: boolean =
                getValues('greenhouseInterestedReplyMoveStageActive') &&
                isEmpty(getValues('greenhouseInterestedReplyStageId'));
            const greenhouseMeetingBookedToggleSetMeetingBookedStageNotSet: boolean =
                getValues('greenhouseMeetingBookedMoveStageActive') &&
                isEmpty(getValues('greenhouseMeetingBookedStageId'));

            const leverJobSetUserNotSet: boolean =
                !isEmpty(getValues('leverJobId')) && isEmpty(getValues('leverUserId'));
            const leverRepliedToggleSetRepliedStageNotSet: boolean =
                getValues('leverRepliedMoveStageActive') && isEmpty(getValues('leverRepliedStageId'));
            const leverInterestedRepliedToggleSetInterestedRepliedStageNotSet: boolean =
                getValues('leverInterestedReplyMoveStageActive') && isEmpty(getValues('leverInterestedReplyStageId'));
            const leverMeetingBookedToggleSetMeetingBookedStageNotSet: boolean =
                getValues('leverMeetingBookedMoveStageActive') && isEmpty(getValues('leverMeetingBookedStageId'));

            setStoredSequenceForm({
                title: getValues('title'),
                stages: getValues('stages'),
                color: getValues('color'),
                emailPreference: getValues('emailPreference'),
                projects: getValues('projects'),
                // ashby
                ashbySyncProspectToggleActive: getValues('ashbySyncProspectToggleActive'),
                ashbyJobId: ashbyJobSetUserNotSet ? '' : getValues('ashbyJobId'),
                ashbyUserId: getValues('ashbyUserId'),
                ashbyCreateEventType: getValues('ashbyCreateEventType'),
                ashbyDefaultAddStageId: getValues('ashbyDefaultAddStageId'),
                ashbyRepliedMoveStageActive:
                    ashbyJobSetUserNotSet || ashbyRepliedToggleSetRepliedStageNotSet
                        ? false
                        : getValues('ashbyRepliedMoveStageActive'),
                ashbyRepliedStageId: getValues('ashbyRepliedStageId'),
                ashbyInterestedReplyMoveStageActive:
                    ashbyJobSetUserNotSet || ashbyInterestedRepliedToggleSetInterestedRepliedStageNotSet
                        ? false
                        : getValues('ashbyInterestedReplyMoveStageActive'),
                ashbyInterestedReplyStageId: getValues('ashbyInterestedReplyStageId'),
                ashbyMeetingBookedMoveStageActive:
                    ashbyJobSetUserNotSet || ashbyMeetingBookedToggleSetMeetingBookedStageNotSet
                        ? false
                        : getValues('ashbyMeetingBookedMoveStageActive'),
                ashbyMeetingBookedStageId: getValues('ashbyMeetingBookedStageId'),
                // greenhouse
                greenhouseSyncProspectToggleActive: getValues('greenhouseSyncProspectToggleActive'),
                greenhouseJobId: greenhouseJobSetUserNotSet ? '' : getValues('greenhouseJobId'),
                greenhouseUserId: getValues('greenhouseUserId'),
                greenhouseCreateEventType: getValues('greenhouseCreateEventType'),
                greenhouseInterestedReplyMoveStageActive:
                    greenhouseJobSetUserNotSet || greenhouseInterestedRepliedToggleSetInterestedRepliedStageNotSet
                        ? false
                        : getValues('greenhouseInterestedReplyMoveStageActive'),
                greenhouseInterestedReplyStageId: getValues('greenhouseInterestedReplyStageId'),
                greenhouseMeetingBookedMoveStageActive:
                    greenhouseJobSetUserNotSet || greenhouseMeetingBookedToggleSetMeetingBookedStageNotSet
                        ? false
                        : getValues('greenhouseMeetingBookedMoveStageActive'),
                greenhouseMeetingBookedStageId: getValues('greenhouseMeetingBookedStageId'),
                greenhouseAddAsApplicationType: getValues('greenhouseAddAsApplicationType'),
                greenhouseInterestedReplyConvertActive: getValues('greenhouseInterestedReplyConvertActive'),
                greenhouseMeetingBookedConvertActive: getValues('greenhouseMeetingBookedConvertActive'),
                // lever
                leverSyncProspectToggleActive: getValues('leverSyncProspectToggleActive'),
                leverJobId: leverJobSetUserNotSet ? '' : getValues('leverJobId'),
                leverUserId: getValues('leverUserId'),
                leverCreateEventType: getValues('leverCreateEventType'),
                leverDefaultAddStageId: getValues('leverDefaultAddStageId'),
                leverRepliedMoveStageActive:
                    leverJobSetUserNotSet || leverRepliedToggleSetRepliedStageNotSet
                        ? false
                        : getValues('leverRepliedMoveStageActive'),
                leverRepliedStageId: getValues('leverRepliedStageId'),
                leverInterestedReplyMoveStageActive:
                    leverJobSetUserNotSet || leverInterestedRepliedToggleSetInterestedRepliedStageNotSet
                        ? false
                        : getValues('leverInterestedReplyMoveStageActive'),
                leverInterestedReplyStageId: getValues('leverInterestedReplyStageId'),
                leverMeetingBookedMoveStageActive:
                    leverJobSetUserNotSet || leverMeetingBookedToggleSetMeetingBookedStageNotSet
                        ? false
                        : getValues('leverMeetingBookedMoveStageActive'),
                leverMeetingBookedStageId: getValues('leverMeetingBookedStageId'),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * saves to localStorage on page reload
     */
    useEffect(() => {
        window.addEventListener('beforeunload', saveToLocalStorage);
        return () => {
            window.removeEventListener('beforeunload', saveToLocalStorage);
        };
    }, [saveToLocalStorage]);

    const onError = async () => {
        const result = await formMethods.trigger(undefined, { shouldFocus: true });
        if (!result) {
            const combinedStageContent = formMethods
                .getValues('stages')
                .map((stage) => stage.subject.concat(stage.content))
                .join(' ');

            if (!validVariables(combinedStageContent)) {
                const message = getErrorString(combinedStageContent);
                showSnackbarAlert({
                    severity: 'error',
                    message,
                });
            }
        }
    };

    const handleAddAdditionalStage = () => {
        const firstStage = getValues('stages')[0];

        formMethods.setValue('stages', [
            ...formMethods.getValues('stages'),
            {
                ...defaultInitialValues,
                content: emailTemplates.blank,
                signature: firstStage.signature,
                useSignature: firstStage.useSignature,
            },
        ]);

        trackEvent('click_add_stage', {
            page_name: 'sequence_creation',
        });
    };

    const onFirstStepComplete = () => {
        setActiveStep(1);
    };

    const onSaveAsDraft = async () => {
        if (!sequenceId) {
            handleCreate(SequenceStatusEnum.Draft);
        } else {
            handleUpdate(SequenceStatusEnum.Draft);
        }
    };

    const onSubmit = async () => {
        if (!sequenceId) {
            handleCreate(SequenceStatusEnum.Ready);
        } else {
            handleUpdate(SequenceStatusEnum.Ready);
        }
    };

    const handleCreate = async (status: SequenceStatusEnum) => {
        const res = await createSequence({
            variables: {
                sequence: {
                    title: getValues('title'),
                    color: getValues('color'),
                    emailPreference: getValues('emailPreference'),
                    status,
                    stages: {
                        data: getValues('stages').map((stage, index) => ({
                            ...stage,
                            stageIndex: index,
                            sendFromAccountId: stage.sendFromAccountId || null, // handle overrides
                        })),
                    },
                },
            },
        });

        if (res.data?.insert_sequences?.affected_rows) {
            const createdSequenceId: string = res.data?.insert_sequences?.returning[0].id;

            updateSequenceProjects(getValues('projects') || [], createdSequenceId);
            handleCreateAshbySequenceMapping(createdSequenceId);
            handleCreateGreenhouseSequenceMapping(createdSequenceId);
            handleCreateLeverSequenceMapping(createdSequenceId);

            setStoredSequenceForm(undefined);
            router.push(appRoutes.sequences.index());

            showSnackbarAlert({
                severity: 'success',
                message: translate('create.sequence-was-created'),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('create.settings.error-failed-to-create-sequence'),
            });
        }
    };

    const handleUpdate = async (status: SequenceStatusEnum) => {
        if (!sequenceId) return;

        const res = await updateSequenceAndStages({
            variables: {
                sequenceId,
                sequence: {
                    title: getValues('title'),
                    color: getValues('color'),
                    emailPreference: getValues('emailPreference'),
                    status,
                },
                stages: getValues('stages').map((stage, index) => ({
                    ...stage,
                    sequenceId,
                    stageIndex: index,
                    sendFromAccountId: stage.sendFromAccountId || null, // handle overrides
                })),
            },
        });

        if (res.data?.update_sequences_by_pk) {
            updateSequenceProjects(getValues('projects') || [], sequenceId);
            handleUpdateAshbySequenceMapping();
            handleUpdateGreenhouseSequenceMapping();
            handleUpdateLeverSequenceMapping();

            if (status === SequenceStatusEnum.Ready) {
                setStoredSequenceForm(undefined);
                router.push(appRoutes.sequences.index(sequenceId));
            }

            showSnackbarAlert({
                severity: 'success',
                message: translate('create.settings.success-update-draft', { title: getValues('title') }),
            });
        } else {
            showSnackbarAlert({ severity: 'error', message: translate('create.settings.error-update-draft') });
        }
    };

    const updateSequenceProjects = async (projects: Array<{ label: string; value: string }>, sid: string) => {
        // TODO: deleting the mappings and then re-creating may cause issues in the future
        await deleteProjectSequenceMappings({
            variables: {
                where: {
                    sequenceId: { _eq: sid },
                },
            },
        });
        await insertProjectSequenceMappings({
            variables: {
                objects:
                    projects?.map((p) => ({
                        sequenceId: sid,
                        projectId: p.value,
                    })) || [],
            },
        });
    };

    const getAshbySequenceMappingInputData = (): Partial<AshbySequenceMappingsInsertInput> => {
        const values = formMethods.getValues();

        return {
            syncProspectToggleActive: values.ashbySyncProspectToggleActive,
            jobId: values.ashbyJobId,
            assigneeId: values.ashbyUserId || null,
            createEventType: values.ashbyCreateEventType,
            defaultAddStageId: values.ashbyDefaultAddStageId || null,
            repliedMoveStageActive: values.ashbyRepliedMoveStageActive || false,
            repliedStageId: values.ashbyRepliedStageId || null,
            interestedReplyMoveStageActive: values.ashbyInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.ashbyInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.ashbyMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.ashbyMeetingBookedStageId || null,
        };
    };

    const handleCreateAshbySequenceMapping = async (createdSequenceId: string) => {
        const values = formMethods.getValues();

        // ashby not connected or no ashby job selected
        if (!ashbyAccount?.data.length || !values.ashbyJobId) return;

        await createAshbySequenceMapping({
            variables: {
                ashby_sequence_mappings: {
                    sequenceId: createdSequenceId,
                    ...getAshbySequenceMappingInputData(),
                },
            },
        });
    };

    const handleUpdateAshbySequenceMapping = async () => {
        const values = formMethods.getValues();

        if (ashbySequenceMappingData?.data && ashbySequenceMappingData.data.length > 0) {
            if (values.ashbyJobId) {
                await updateAshbySequenceMapping({
                    variables: {
                        id: ashbySequenceMappingData.data[0].id,
                        set: getAshbySequenceMappingInputData(),
                    },
                });
            } else {
                await deleteAshbySequenceMapping({
                    variables: {
                        id: ashbySequenceMappingData.data[0].id,
                    },
                });
            }
        } else {
            if (!ashbyAccount?.data.length || !values.ashbyJobId) return;

            await createRefetchAshbySequenceMapping({
                variables: {
                    ashby_sequence_mappings: {
                        sequenceId: sequenceId!,
                        ...getAshbySequenceMappingInputData(),
                    },
                },
            });
        }
    };

    const getGreenhouseSequenceMappingInputData = (): Partial<GreenhouseSequenceMappingsInsertInput> => {
        const values = formMethods.getValues();

        return {
            syncProspectToggleActive: values.greenhouseSyncProspectToggleActive,
            jobId: values.greenhouseJobId,
            assigneeId: values.greenhouseUserId || null,
            createEventType: values.greenhouseCreateEventType,
            interestedReplyMoveStageActive: values.greenhouseInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.greenhouseInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.greenhouseMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.greenhouseMeetingBookedStageId || null,
            createAsApplicationTypeOverride: values.greenhouseAddAsApplicationType,
            interestedReplyConvertActive: values.greenhouseInterestedReplyConvertActive,
            meetingBookedConvertActive: values.greenhouseMeetingBookedConvertActive,
        };
    };

    const handleCreateGreenhouseSequenceMapping = async (createdSequenceId: string) => {
        const values = formMethods.getValues();

        // greenhouse not connected or no greenhouse job selected
        if (!greenhouseAccount?.data.length || !values.greenhouseJobId) return;

        await createGreenhouseSequenceMapping({
            variables: {
                greenhouse_sequence_mappings: {
                    sequenceId: createdSequenceId,
                    ...getGreenhouseSequenceMappingInputData(),
                },
            },
        });
    };

    const handleUpdateGreenhouseSequenceMapping = async () => {
        const values = formMethods.getValues();

        if (greenhouseSequenceMappingData?.data && greenhouseSequenceMappingData.data.length > 0) {
            if (values.greenhouseJobId) {
                await updateGreenhouseSequenceMapping({
                    variables: {
                        id: greenhouseSequenceMappingData.data[0].id,
                        set: getGreenhouseSequenceMappingInputData(),
                    },
                });
            } else {
                await deleteGreenhouseSequenceMapping({
                    variables: {
                        id: greenhouseSequenceMappingData.data[0].id,
                    },
                });
            }
        } else {
            if (!greenhouseAccount?.data.length || !values.greenhouseJobId) return;

            await createRefetchGreenhouseSequenceMapping({
                variables: {
                    greenhouse_sequence_mappings: {
                        sequenceId: sequenceId!,
                        ...getGreenhouseSequenceMappingInputData(),
                    },
                },
            });
        }
    };

    const getLeverSequenceMappingInputData = (): Partial<LeverSequenceMappingsInsertInput> => {
        const values = formMethods.getValues();

        return {
            syncProspectToggleActive: values.leverSyncProspectToggleActive,
            postingId: values.leverJobId,
            assigneeId: values.leverUserId || null,
            createEventType: values.leverCreateEventType,
            defaultAddStageId: values.leverDefaultAddStageId || null,
            repliedMoveStageActive: values.leverRepliedMoveStageActive || false,
            repliedStageId: values.leverRepliedStageId || null,
            interestedReplyMoveStageActive: values.leverInterestedReplyMoveStageActive || false,
            interestedReplyStageId: values.leverInterestedReplyStageId || null,
            meetingBookedMoveStageActive: values.leverMeetingBookedMoveStageActive || false,
            meetingBookedStageId: values.leverMeetingBookedStageId || null,
        };
    };

    const handleCreateLeverSequenceMapping = async (createdSequenceId: string) => {
        const values = formMethods.getValues();

        // lever not connected or no lever posting selected
        if (!leverAccount?.data.length || !values.leverJobId) return;

        await createLeverSequenceMapping({
            variables: {
                lever_sequence_mappings: {
                    sequenceId: createdSequenceId,
                    ...getLeverSequenceMappingInputData(),
                },
            },
        });
    };

    const handleUpdateLeverSequenceMapping = async () => {
        const values = formMethods.getValues();

        if (leverSequenceMappingData?.data && leverSequenceMappingData.data.length > 0) {
            if (values.leverJobId) {
                await updateLeverSequenceMapping({
                    variables: {
                        id: leverSequenceMappingData.data[0].id,
                        set: getLeverSequenceMappingInputData(),
                    },
                });
            } else {
                await deleteLeverSequenceMapping({
                    variables: {
                        id: leverSequenceMappingData.data[0].id,
                    },
                });
            }
        } else {
            if (!leverAccount?.data.length || !values.leverJobId) return;

            await createRefetchLeverSequenceMapping({
                variables: {
                    lever_sequence_mappings: {
                        sequenceId: sequenceId!,
                        ...getLeverSequenceMappingInputData(),
                    },
                },
            });
        }
    };

    const handleExit = () => {
        setStoredSequenceForm(undefined);
        router.push(appRoutes.sequences.index());
    };

    return (
        <Box
            css={css`
                background-image: url('/create-flow.svg');
                background-size: cover;
            `}
        >
            <CreateSequenceTemplate
                activeStep={activeStep}
                onSaveAsDraft={handleSubmit(onSaveAsDraft)}
                onExit={handleExit}
                draftExists={!!sequenceId}
            >
                {activeStep === 0 ? (
                    <CreateStagesForm
                        formMethods={formMethods}
                        stages={stages}
                        onAddAdditionalStage={handleAddAdditionalStage}
                        onSaveAsDraft={handleSubmit(onSaveAsDraft, onError)}
                        onSubmit={handleSubmit(onFirstStepComplete, onError)}
                    />
                ) : (
                    <CreateSettingsForm
                        formMethods={formMethods}
                        onGoToPreviousStep={() => setActiveStep(0)}
                        onSaveAsDraft={handleSubmit(onSaveAsDraft)}
                        onSubmit={handleSubmit(onSubmit)}
                    />
                )}
                <SettingsMenu type="button" />
            </CreateSequenceTemplate>
        </Box>
    );
};

export { CreateSequenceForm };
