import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface TeamSettingsSpacerProps {
    description: string | React.ReactNode;
}

const TeamSettingsSpacer: FC<TeamSettingsSpacerProps> = ({ description }) => {
    const translate = useTranslations('integrations');

    return (
        <Box
            css={css`
                padding-top: ${spacing.space48px};
            `}
        >
            <Title
                type="h4"
                css={css`
                    flex-grow: 1;
                    font-size: ${fontSizes.f20};
                    margin: ${spacing.none};
                `}
            >
                {translate('team-settings')}
            </Title>
            <Box
                css={css`
                    padding-top: ${spacing.space8px};
                    padding-bottom: ${spacing.space8px};
                `}
            >
                {description}
            </Box>
        </Box>
    );
};

export { TeamSettingsSpacer };
