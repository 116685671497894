import { gql } from '@apollo/client';

import { OrderBy, SourcersQuery, TeamFeaturesByTeamIdFeatureQuery } from 'codegen/graphql';
import { UserTeam } from 'shared/types';

export type SourcersData = SourcersQuery['sourcers'][number];
export type SourcersProps = {
    initialData: {
        data: NonNullable<SourcersData>[];
        count: number;
        team: NonNullable<UserTeam>;
        teamFeaturesData: TeamFeaturesByTeamIdFeatureQuery['data'];
    };
};

export const sourcersDefaultOrderBy = 'modifiedAt';
export const sourcersDefaultOrderByDirection = OrderBy.Desc;

export const sourcersDefaultVariables = {
    orderBy: { [sourcersDefaultOrderBy]: sourcersDefaultOrderByDirection },
    limit: 10,
    offset: 0,
    where: {},
    withDetails: true,
};

export const SOURCER_DETAILS = gql`
    fragment SourcerDetails on sourcers {
        title
        status
        modifiedAt
        createdAt
        specialty
        jobDescriptionUrl
        profession
        color
        speed
        amountPerDay
        autoAdd
        autoAddSequence
        deductCustomCredits
        creditsPerApproval
        insightsVisibleAt
        userId
        user {
            id
            fullName
            email
        }
        project {
            id
            title
            status
            userId
            collaborators {
                type
                userId
            }
        }
        sequence {
            id
            title
            status
        }
        memberStats: member_stats {
            added
            toReview
        }
        collaborators {
            id
            userId
            type
            user {
                id
                fullName
                email
            }
        }
    }
`;

export const GET_SOURCERS = gql`
    query Sourcers(
        $orderBy: sourcers_order_by! = { modifiedAt: desc }
        $limit: Int
        $offset: Int! = 0
        $where: sourcers_bool_exp!
        $withDetails: Boolean!
    ) {
        sourcers_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        sourcers(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where) {
            id
            ...SourcerDetails @include(if: $withDetails)
        }
    }
    ${SOURCER_DETAILS}
`;

export const GET_SOURCERS_IDS = gql`
    query SourcersIds($orderBy: [sourcers_order_by!], $limit: Int, $offset: Int = 0, $where: sourcers_bool_exp!) {
        sourcers(order_by: $orderBy, limit: $limit, offset: $offset, where: $where) {
            id
        }
    }
`;

export const GET_SOURCER_BY_ID = gql`
    query SourcerById($id: String!) {
        sourcers_by_pk(id: $id) {
            id
            showContactUsNotice
            ...SourcerDetails
        }
    }
    ${SOURCER_DETAILS}
`;

export const UPDATE_SOURCER_BY_ID = gql`
    mutation UpdateSourcerById($id: String!, $set: sourcers_set_input!) {
        update_sourcers_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
            showContactUsNotice
            ...SourcerDetails
        }
    }
    ${SOURCER_DETAILS}
`;

export const UPDATE_SOURCERS = gql`
    mutation UpdateSourcers($where: sourcers_bool_exp!, $set: sourcers_set_input!) {
        update_sourcers(where: $where, _set: $set) {
            returning {
                id
                ...SourcerDetails
            }
        }
    }
    ${SOURCER_DETAILS}
`;

export const CREATE_SOURCER = gql`
    mutation CreateSourcer($sourcer: sourcers_insert_input!) {
        insert_sourcers(objects: [$sourcer]) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const GET_SOURCERS_COUNT_BY_STATUS = gql`
    query SourcersByStatus {
        all: sourcers_aggregate {
            aggregate {
                count
            }
        }
        active: sourcers_aggregate(where: { status: { _eq: active } }) {
            aggregate {
                count
            }
        }
        draft: sourcers_aggregate(where: { status: { _eq: draft } }) {
            aggregate {
                count
            }
        }
        paused: sourcers_aggregate(where: { status: { _eq: paused } }) {
            aggregate {
                count
            }
        }
        archived: sourcers_aggregate(where: { status: { _eq: archived } }) {
            aggregate {
                count
            }
        }
    }
`;

export const PAUSE_SOURCER_BY_ID = gql`
    mutation PauseSourcerById($id: String!, $note: String) {
        update_sourcers_by_pk(pk_columns: { id: $id }, _set: { status: paused }) {
            id
        }
        insert_sourcer_notes(objects: [{ sourcerId: $id, note: $note }]) {
            affected_rows
        }
    }
`;

export const ARCHIVE_SOURCER_BY_ID = gql`
    mutation ArchiveSourcerById($id: String!, $note: String) {
        update_sourcers_by_pk(pk_columns: { id: $id }, _set: { status: archived }) {
            id
        }
        insert_sourcer_notes(objects: [{ sourcerId: $id, note: $note }]) {
            affected_rows
        }
    }
`;

export const SOURCER_OVERVIEW_STATS = gql`
    query SourcerOverviewStats {
        sourcers_aggregate(where: { status: { _eq: active } }) {
            aggregate {
                sum {
                    amountPerDay
                }
                count
            }
        }
    }
`;

export const CREATE_SOURCER_NOTES = gql`
    mutation CreateSourcerNotes($notes: [sourcer_notes_insert_input!]! = []) {
        insert_sourcer_notes(objects: $notes) {
            affected_rows
        }
    }
`;
