import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { SetValueConfig, UseFormReturn, useWatch } from 'react-hook-form';

import { emailTemplates } from 'sequences/settings';
import { Box } from 'shared/components/containers';
import { EditorFooter, EmailSignaturePreview, EmailTemplatesModal } from 'shared/components/editor';
import ReactQuill from 'shared/components/editor/react-quill-wrapper';
import { Button } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import { useConfirmationModal } from 'shared/hooks';
import { colors, fontFamilies, fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface EditorProps {
    formMethods: UseFormReturn<any>;
    index: number;
    modules?: Record<string, any>;
    name: string;
    onClearVariable?: () => void;
    onEditorTouched: (touched: boolean) => void;
    selectedVariable: string;
    isSubjectTouched?: boolean;
    compact?: boolean;
    disabled?: boolean;
    showFooter?: boolean;
    onKeyUp?: () => void;
    signatureHtml?: string;
    isEdit?: boolean;
}

const Editor: FC<EditorProps> = (props) => {
    const {
        className,
        formMethods,
        name,
        index,
        selectedVariable,
        onClearVariable,
        onEditorTouched,
        isSubjectTouched,
        compact,
        disabled = false,
        showFooter = true,
        modules,
        onKeyUp,
        signatureHtml,
        isEdit,
    } = props;
    const translate = useTranslations('sequence.create.stages');
    const [isEdited, setIsEdited] = useState<boolean>(false); // When editor is edited
    const [isEmailTemplatesModalOpen, setIsEmailTemplatesModalOpen] = useState<boolean>(false);
    const { showModal, hideModal } = useConfirmationModal();
    const theme = useTheme();

    const fieldState = formMethods.getFieldState(name);
    const errorMessage = fieldState.error?.message;
    const isInvalid: boolean = fieldState.isTouched && !!fieldState.error;

    // register quill editor as form input
    const { register } = formMethods;

    useEffect(() => {
        register(name);
    }, [register, name]);

    // re-render editor when text value changes
    const content = useWatch({ control: formMethods.control, name });

    // watch for changes to "reply to last email" checkbox, to display appropriate email templates
    const isReplyToLastEmailChecked = useWatch({
        control: formMethods.control,
        name: `stages.${index}.replyToLastEmail`,
    });
    // Open email template modal
    const handleStartWithATemplateClick = (): void => {
        setIsEmailTemplatesModalOpen(true);
    };

    // Close email template modal
    const handleCloseEmailTemplateModal = (): void => {
        setIsEmailTemplatesModalOpen(false);
    };

    // Close confirmation modal
    const handleCloseConfirmationModal = (): void => {
        hideModal();
    };

    const handleEdited = (edited: boolean) => {
        setIsEdited(edited);
    };

    const handleEditorBlur = () => {
        onEditorTouched(false);
        formMethods.trigger(name);
    };

    // Add email template to editor
    const handleAddEmailTemplate = (selectedOptionId: string): void => {
        if (isEdited) {
            showModal({
                title: translate('you-previously-made-changes-to-your-message'),
                description: translate('would-you-like-to-replace-your-existing-message'),
                cancelButton: {
                    text: translate('cancel'),
                    onClick: handleCloseConfirmationModal,
                },
                confirmButton: {
                    text: translate('replace'),
                    onClick: () => {
                        setFormValue((emailTemplates as any)[selectedOptionId], { shouldDirty: true });
                        hideModal();
                        handleCloseEmailTemplateModal();
                        setIsEdited(false);
                    },
                },
            });
        } else {
            setFormValue((emailTemplates as any)[selectedOptionId], { shouldDirty: true });
            setIsEmailTemplatesModalOpen(false);
        }
    };

    const setFormValue = (v: string, opts?: SetValueConfig) => {
        formMethods.setValue(name, v, opts);
    };

    const signaturePreviewBgColor = compact ? theme.palette.common.white : theme.palette.grey[300];

    return (
        <div className={className}>
            <div className="editor">
                {/* hiding this button if disabled to prevent the button being stacked on top of the stage contents */}
                {!compact && !disabled && (
                    <Button
                        onClick={handleStartWithATemplateClick}
                        variant="text"
                        startIcon={<Plus />}
                        css={css`
                            position: absolute;
                            z-index: 1;
                            font-weight: ${fontWeights.bold};
                            padding: 16px;
                            margin-top: 8px;
                        `}
                        disabled={disabled}
                    >
                        {translate('start-with-a-template')}
                    </Button>
                )}

                <ReactQuill
                    bounds=".editor"
                    value={content}
                    onBlur={handleEditorBlur}
                    onClearVariable={onClearVariable}
                    onEditorTouched={onEditorTouched}
                    onEdited={handleEdited}
                    setFormValue={setFormValue}
                    isSubjectTouched={isSubjectTouched}
                    selectedVariable={selectedVariable}
                    modules={modules}
                    readOnly={disabled}
                    onKeyUp={onKeyUp}
                />

                {signatureHtml && (
                    <Box
                        css={css`
                            background-color: ${signaturePreviewBgColor};
                            padding: 1px 15px 8px 15px; // 1px ensures that the background color is uniform
                        `}
                    >
                        <EmailSignaturePreview signatureHtml={signatureHtml} />
                    </Box>
                )}

                {showFooter && (
                    <EditorFooter
                        formMethods={formMethods}
                        index={index}
                        disabled={disabled}
                        signatureHtml={signatureHtml || ''}
                        isEdit={isEdit}
                    />
                )}

                <EmailTemplatesModal
                    index={index}
                    open={isEmailTemplatesModalOpen}
                    onClose={handleCloseEmailTemplateModal}
                    onConfirm={handleAddEmailTemplate}
                    isEdit={isEdit}
                    isReplyToLastEmailChecked={isReplyToLastEmailChecked}
                />
            </div>
            {isInvalid && <Error>{errorMessage}</Error>}
        </div>
    );
};

const StyledEditor = styled(Editor)`
    .editor {
        margin-bottom: 2px;
        position: relative;
        border: 1px solid ${(props) => props.theme.palette.grey[500]};
        border-radius: 4px;
    }

    .ql-toolbar,
    .ql-container {
        border: none !important;
        background-color: ${(props) => props.theme.palette.grey[300]};
    }

    .ql-toolbar {
        padding: 20px 8px !important;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]} !important;
        display: ${(props) => (props.disabled ? 'none' : 'flex')};
        justify-content: flex-end;
    }

    .ql-container {
        padding: 5px 0;
        // border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]} !important;
        font-family: ${fontFamilies.inter};
        height: 100%;
    }

    .ql-editor p,
    div {
        font-size: ${fontSizes.f16};
        color: ${(props) => props.theme.palette.common.black};
        opacity: ${(props) => (props.disabled ? '0.8' : '1')};
    }

    .ql-snow .ql-picker.ql-size {
        .ql-picker-label,
        .ql-picker-item {
            &:before {
                content: attr(data-value) !important;
            }
        }
    }

    .ql-tooltip {
        border-radius: 4px;
    }

    .ql-editor ol,
    .ql-editor ul {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    ${({ name, formMethods }) => {
        const isInvalid: boolean = !!formMethods.getFieldState(name).error;

        return isInvalid
            ? `
                .quill {
                    border: 1px solid ${colors.reds.persianRed} !important;
                    box-shadow: none;
                }
            `
            : `
                .quill {
                    border: none;
                }
            `;
    }}
`;

const Error = styled.p`
    color: ${colors.reds.persianRed};
    font-size: ${fontSizes.f14};
    margin-top: 4px;
    margin-left: 15px;
`;

export { StyledEditor as Editor };
