import { Stage } from 'sequences/types';

export function isAnySubjectContentChange(oldStages: readonly Stage[], newStages: readonly Stage[]) {
    let subjectChanged = false;
    let contentChanged = false;

    // handle case where a stage has been deleted
    if (oldStages.length !== newStages.length) {
        subjectChanged = true;
        contentChanged = true;
        return { subjectChanged, contentChanged };
    }

    for (let i = 0; i < oldStages.length; i += 1) {
        const oldStage = oldStages[i];
        const newStage = newStages[i];
        if (oldStage.subject !== newStage.subject) {
            subjectChanged = true;
        }
        if (oldStage.content !== newStage.content) {
            contentChanged = true;
        }
    }

    return {
        contentChanged,
        subjectChanged,
    };
}

/**
 * Removes trailing occurrences of '<div><br></div>' from the given content string.
 * This is important as Quill automatically removes these lines from the end of the
 * editor when they are loaded into the editor from the db using the handleChange method.
 * This causes problems for us as the editor is then put into an "edited" state where
 * the snackbar is shown, even though the user has not yet made any changes.
 *
 * @param c - The content string to process.
 * @returns - The altered content string without trailing occurrences of '<div><br></div>'.
 */
export const handleStageContentTrailingNewLine = (c: string): string => c.replace(/(<div><br><\/div>)+$/, '');

export const handleStagesTrailingNewLines = (stages: Stage[]) =>
    stages.map((stage) => ({
        ...stage,
        content: handleStageContentTrailingNewLine(stage.content),
    }));
