import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Button, List, Title } from 'shared/components/presentational';
import { Filter, HappyFace, NeutralFace } from 'shared/components/svgs';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface StatsCardProps {
    status: 'green' | 'yellow' | 'red' | 'neutral';
    title: number;
    description: string;
    isStatsOpen: boolean;
    stages: number[];
    parentIndex: number;
    type: string;
    onStatsFilterChange: (values: string) => void;
}

const StatsCard: FC<StatsCardProps> = (props) => {
    const { status, title, description, isStatsOpen, stages, parentIndex, onStatsFilterChange, type } = props;
    const theme = useTheme();
    const translate = useTranslations('sequence.details');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    /**
     * Returns a string representation of a given number converted to a percentage,
     * always rounded up to 1 decimal place.
     *
     * @param {number} value - The number to be converted to a percentage.
     * @returns {string} The percentage representation of the given number rounded up to 1 decimal place.
     *
     * @example
     * getDisplayPercentage(0.4567);  // Returns "45.7%"
     * getDisplayPercentage(0.0001);  // Returns "0.1%"
     */
    const getDisplayPercentage = (value: number): string =>
        // eslint-disable-next-line no-magic-numbers
        (Math.ceil(value * 100 * 10) / 10).toFixed(1);
    return (
        <Box
            component={Button}
            onMouseEnter={() => {
                setIsOpen(true);
            }}
            onMouseLeave={() => {
                setIsOpen(false);
            }}
            css={css`
                display: flex;
                flex-direction: column;
                padding: ${spacing.none};
                color: ${theme.palette.common.black};
                border-radius: ${spacing.none};
                transition: all 0.1s ease-out;
                position: relative;
                background-color: ${theme.palette.common.white};
                border: 1px solid ${theme.palette.grey[500]};
                border-radius: 4px;
                height: auto;

                &:hover {
                    border: 1px solid ${theme.palette.primary.main};
                }
            `}
            onClick={() => onStatsFilterChange(type)}
        >
            <Box
                css={css`
                    padding: ${spacing.space16px};
                    width: 215px;
                    display: flex;
                    align-items: center;
                `}
            >
                <Box
                    css={css`
                        background-color: ${status === 'green'
                            ? theme.palette.primary.contrastText
                            : status === 'neutral'
                            ? 'rgba(250, 250, 250, 1)'
                            : status === 'yellow'
                            ? 'rgba(255, 246, 162, 0.3)'
                            : 'rgba(203, 41, 41, 0.1)'};
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-left: ${spacing.space16px};
                        margin-right: ${spacing.space16px};

                        @media (max-width: 1000px) {
                            width: 33px;
                            height: 33px;
                        }
                    `}
                >
                    {status === 'green' || status === 'neutral' ? (
                        <HappyFace
                            stroke={status === 'neutral' ? theme.palette.grey[600] : theme.palette.primary.main}
                        />
                    ) : (
                        <NeutralFace
                            stroke={status === 'yellow' ? theme.palette.info.light : theme.palette.error.main}
                        />
                    )}
                </Box>

                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    `}
                >
                    <Box
                        component="p"
                        css={css`
                            font-size: ${fontSizes.f12};
                            margin: ${spacing.none};
                        `}
                    >
                        {description}
                    </Box>
                    <Title
                        type="h3"
                        css={css`
                            font-size: ${fontSizes.f24};
                            margin: ${spacing.none};
                            text-align: start;
                        `}
                    >
                        {getDisplayPercentage(title)}
                        <Box
                            component="span"
                            css={css`
                                font-size: ${fontSizes.f16};
                            `}
                        >
                            {translate('percentage-sign')}
                        </Box>
                    </Title>
                </Box>
                {isOpen && (
                    <Filter
                        css={css`
                            position: absolute;
                            right: ${spacing.space16px};
                        `}
                    />
                )}
            </Box>

            <List
                type="unordered"
                css={css`
                    width: 100%;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.2s ease-in-out;

                    > li:nth-of-type(2) {
                        border-top: 1px solid ${theme.palette.grey[100]};
                        border-bottom: 1px solid ${theme.palette.grey[100]};
                    }

                    ${isStatsOpen &&
                    `
                      overflow: visible;
                      max-height: 300px;
                    `}
                `}
            >
                {stages.map((stage, index) => (
                    <Box
                        component="li"
                        key={index}
                        css={css`
                            padding: ${spacing.space16px};
                            background-color: ${theme.palette.common.white};
                            position: relative;

                            ${parentIndex === 0 &&
                            `
                               &:before {
                                content: 'Stage ${index + 1}';
                                position: absolute;
                                left: -70px;
                                font-weight: ${fontWeights.bold};
                              }
                            `}
                        `}
                    >
                        <Title
                            type="h3"
                            css={css`
                                font-size: ${fontSizes.f18};
                                font-weight: ${fontWeights.normal};
                                display: inline;
                                margin: ${spacing.none};
                                color: ${theme.palette.grey[200]} !important;
                                padding-right: 50px;
                            `}
                        >
                            {getDisplayPercentage(stage)}
                            <Box
                                component="span"
                                css={css`
                                    font-size: ${fontSizes.f14};
                                `}
                            >
                                {translate('percentage-sign')}
                            </Box>
                        </Title>
                    </Box>
                ))}
            </List>
        </Box>
    );
};

export { StatsCard };
