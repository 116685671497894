import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedUsersCheck: FC<SvgProps> = ({ stroke, theme, strokeWidth = '2', ...rest }) => (
    <svg {...rest} width="59" height="52" viewBox="0 0 59 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M40.1654 42L45.4987 47.3333L56.1654 36.6667M29.4987 34H18.832C13.862 34 11.377 34 9.41674 34.812C6.80311 35.8946 4.72659 37.9711 3.64398 40.5847C2.83203 42.5449 2.83203 45.03 2.83203 50M38.832 2.77536C42.7411 4.35772 45.4987 8.19016 45.4987 12.6667C45.4987 17.1432 42.7411 20.9756 38.832 22.558M33.4987 12.6667C33.4987 18.5577 28.7231 23.3333 22.832 23.3333C16.941 23.3333 12.1654 18.5577 12.1654 12.6667C12.1654 6.77563 16.941 2 22.832 2C28.7231 2 33.4987 6.77563 33.4987 12.6667Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const UsersCheck = withTheme(ThemedUsersCheck);

export { UsersCheck };
