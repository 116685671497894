import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useEffect, useState } from 'react';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { TeamAiSourcingCreditsQuery, UpdateSourcerMembersProspectIdMutationResult } from 'codegen/graphql';
import { AddToSequenceModal } from 'shared/components/modals';
import { SourcerMemberApprovalAction } from 'shared/enums';
import {
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    SourcerMemberData,
} from 'shared/graphql/sourcer-members';
import { GET_TEAM_AI_SOURCING_CREDITS } from 'shared/graphql/teams';
import { useProspectCreator, useSession } from 'shared/hooks';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { FC } from 'shared/types';
import { NoAISourcingCreditsModal } from 'sourcing/components/modals/no-ai-sourcing-credits';

interface AddMultipleSourcerMembersToSequenceProps {
    creditsPerApproval: number;
    sourcerMembers: SourcerMemberData[];
    open: boolean;
    onClose: () => void;
    flow: SourcerMemberApprovalAction;
}

type Prospect = DeepExtractTypeSkipArrays<
    UpdateSourcerMembersProspectIdMutationResult,
    ['data', 'insert_sourcer_members', 'returning', 'prospect']
>;

const AddMultipleSourcerMembersToSequence: FC<AddMultipleSourcerMembersToSequenceProps> = ({
    open,
    onClose,
    sourcerMembers,
    creditsPerApproval,
    flow,
}) => {
    const { session } = useSession();
    const { createProspectsFromSourcerMembers } = useProspectCreator();
    const [membersToAdd, setMembersToAdd] = useState<SourcerMemberData[]>();

    const { approveAndTrackSourcerMembers } = useApproveSourcerMembers([
        getOperationName(GET_SOURCER_MEMBERS) as string,
        getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
    ]);

    const { data, loading } = useQuery<TeamAiSourcingCreditsQuery>(GET_TEAM_AI_SOURCING_CREDITS, {
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (sourcerMembers && data && (data.teams ?? []).length === 1) {
            const aiSourcingCreditsAvailable = data.teams[0].aiSourcerCredits;
            const countSourcerMembersCanBeAdded = Math.floor(aiSourcingCreditsAvailable / creditsPerApproval);
            setMembersToAdd([...sourcerMembers].splice(0, countSourcerMembersCanBeAdded));
        }
    }, [data, sourcerMembers, creditsPerApproval]);

    const getProspects = async () => {
        const sourcerMembersWithProspect = await createProspectsFromSourcerMembers(membersToAdd ?? []);

        const prospectsWithProfile =
            sourcerMembersWithProspect
                ?.map((sourcerMember) => sourcerMember.prospect)
                .filter((prospect): prospect is Prospect => Boolean(prospect)) ?? [];

        return prospectsWithProfile;
    };

    const handleMembersAdded = async () => {
        if (membersToAdd) {
            await approveAndTrackSourcerMembers({
                sourcerMemberIds: membersToAdd.map((sourcerMember) => sourcerMember.id),
                flow,
                reviewerId: session?.user.id,
            });
        }
    };

    if (!membersToAdd || loading) return null;

    return membersToAdd.length === 0 ? (
        <NoAISourcingCreditsModal open={open} onClose={onClose} />
    ) : membersToAdd.length > 0 ? (
        <AddToSequenceModal
            open={open}
            onClose={onClose}
            getProspects={getProspects}
            count={membersToAdd.length}
            onSuccess={handleMembersAdded}
        />
    ) : null;
};

export { AddMultipleSourcerMembersToSequence };
