import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { Box } from 'shared/components/containers';
import ReactQuill from 'shared/components/editor/react-quill-wrapper';
import { Nil } from 'shared/components/svgs';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { fontFamilies, fontSizes, spacing } from 'shared/settings';

const HireflowSignature = () => {
    const translate = useTranslations('settings.email-settings.personalized-hireflow-signature');
    const theme = useTheme();
    const { showSnackbarAlert } = useSnackbarAlert();
    const { session, setSession } = useSession();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);

    const [hireflowSignature, setHireflowSignature] = useState<string>(session!.user.hireflowSignature || '');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const handleToggleEdit = () => {
        setHireflowSignature(session?.user.hireflowSignature || '');
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: { hireflowSignature },
            },
        });

        setSession({
            user: {
                ...session!.user,
                hireflowSignature,
            },
        });

        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const setFormValue = (v: string): void => setHireflowSignature(v);

    const signatureEditor = (
        <Box
            css={css`
                display: ${isEditing ? 'block' : 'none'};
            `}
        >
            <ReactQuill
                css={css`
                    .ql-toolbar {
                        background-color: ${theme.palette.common.white};
                    }
                    .ql-editor {
                        font-size: ${fontSizes.f16};
                        font-family: ${fontFamilies.inter};
                        background-color: ${theme.palette.common.white};
                    }
                    .ql-editor ol,
                    .ql-editor ul {
                        display: block;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                    }
                `}
                value={hireflowSignature}
                onClearVariable={() => {}}
                onEditorTouched={() => {}}
                onEdited={() => {}}
                setFormValue={setFormValue}
                isSubjectTouched={false}
                selectedVariable=""
            />
        </Box>
    );

    const signatureViewer = (
        <Box
            css={css`
                display: ${isEditing ? 'none' : 'block'};
            `}
        >
            <ReactQuill
                readOnly
                modules={{
                    toolbar: false,
                    clipboard: {
                        matchVisual: false,
                    },
                }}
                css={css`
                    .ql-container.ql-snow {
                        border: none;
                    }
                    .ql-editor {
                        font-size: ${fontSizes.f16};
                        font-family: ${fontFamilies.inter};
                        padding: 0;
                        background-color: ${theme.palette.common.white};
                    }
                    .ql-editor ol,
                    .ql-editor ul {
                        display: block;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                    }
                `}
                value={hireflowSignature}
                // silence missing props
                onClearVariable={() => {}}
                onEditorTouched={() => {}}
                onEdited={() => {}}
                setFormValue={() => {}}
                isSubjectTouched={false}
                selectedVariable=""
            />
        </Box>
    );

    const noSignatureState = (
        <Box
            css={css`
                color: ${theme.palette.grey[200]};
                display: flex;
                align-items: center;
                column-gap: ${spacing.space8px};
            `}
        >
            <Nil />
            {translate('no-signature')}
        </Box>
    );

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {signatureEditor}
            {hireflowSignature ? signatureViewer : isEditing ? null : noSignatureState}
        </EditableSettingsSection>
    );
};

export { HireflowSignature };
