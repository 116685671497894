import {
    LicenseTypesEnum,
    PlanTypesEnum,
    ProjectCollaboratorTypeEnum,
    RecipientStatusEnum,
    TeamRolesEnum,
} from 'codegen/graphql';
import { MAX_ACTIVE_PROJECTS } from 'projects/constants';
import { MAX_ACTIVE_SEQUENCES } from 'sequences/constants';
import { useSession } from 'shared/hooks/use-session';
import { RecipientSequenceStopped } from 'shared/services';
import { EmailAccounts } from 'shared/types';

export const useAccessControl = () => {
    const { session, loaded } = useSession();

    // Recipient permissions
    const canEditRecipient = (recipient: { user: { id: string } }): boolean => recipient.user.id === session?.user.id;

    const canChangeRecipientStatus = (recipient: { user: { id: string }; status: RecipientStatusEnum }): boolean =>
        recipient.user.id === session?.user.id &&
        recipient.status !== RecipientStatusEnum.Unsubscribed &&
        recipient.status !== RecipientStatusEnum.MeetingBooked;

    const canReportWrongRecipientEmail = (recipient: { user: { id: string }; status: RecipientStatusEnum }): boolean =>
        recipient.user.id === session?.user.id && recipient.status !== RecipientStatusEnum.Unsubscribed;

    const canStopSequenceForRecipient = (recipient: { user: { id: string }; status: RecipientStatusEnum }): boolean =>
        recipient.user.id === session?.user.id && !RecipientSequenceStopped.includes(recipient.status);

    const canUnsubscribeRecipient = (recipient: { user: { id: string }; status: RecipientStatusEnum }): boolean =>
        recipient.user.id === session?.user.id && recipient.status !== RecipientStatusEnum.Unsubscribed;

    const canResendSequenceToNewEmail = (recipient: { user: { id: string }; status: RecipientStatusEnum }): boolean =>
        recipient.user.id === session?.user.id && recipient.status !== RecipientStatusEnum.Unsubscribed;

    const canEditSequence = (sequence: { userId: string }): boolean => sequence.userId === session?.user.id;

    const canViewSequence = (sequence: { userId: string; collaborators: { userId: string }[] }): boolean => {
        const isOwner = sequence.userId === session?.user.id;
        const isCollaborator = sequence.collaborators.some((collaborator) => collaborator.userId === session?.user.id);
        return isOwner || isCollaborator;
    };

    const canFullyDuplicateSequence = (sequence: { userId: string }): boolean => sequence.userId === session?.user.id;

    const canViewProject = (project: { userId: string; collaborators: { userId: string }[] }): boolean => {
        const isOwner = project.userId === session?.user.id;
        const isCollaborator = project.collaborators.some((collaborator) => collaborator.userId === session?.user.id);
        return isOwner || isCollaborator;
    };

    const isUserProjectReviewer = (project: { reviewerId?: string | null }): boolean =>
        project.reviewerId === session?.user.id;

    const canEditProject = (project: { userId: string }): boolean => project.userId === session?.user.id;

    const canChangeProjectMemberStatus = (projectMember: { addedBy: { id: string } }): boolean => {
        const hasAddedProjectMember = projectMember.addedBy.id === session?.user.id;
        return hasAddedProjectMember;
    };

    const canMoveProjectMember = (project: { userId: string }, projectMember: { addedBy: { id: string } }): boolean => {
        const isOwner = project.userId === session?.user.id;
        // this condition is equivalent to owning the prospect
        const hasAddedProjectMember = projectMember.addedBy.id === session?.user.id;
        return isOwner && hasAddedProjectMember;
    };

    const canRemoveProjectMember = (
        project: { userId: string; collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[] },
        projectMember: { addedBy: { id: string } }
    ): boolean => {
        const isOwner = project.userId === session?.user.id;
        const isEditor = project.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === ProjectCollaboratorTypeEnum.Editor
        );

        return isOwner || (isEditor && projectMember.addedBy.id === session?.user.id);
    };

    const canBulkAddRecipientsFromProject = (project: {
        userId: string;
        collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[];
    }): boolean => {
        const isOwner = project.userId === session?.user.id;
        const isEditor = project.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === ProjectCollaboratorTypeEnum.Editor
        );

        return isOwner || isEditor;
    };

    const canBulkMoveProjectMembers = (
        project: { userId: string; collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[] },
        projectMember: { addedBy: { id: string } }[]
    ): boolean => {
        if (projectMember.length === 0) {
            return false;
        }
        const canMoveAllProjectMembers = projectMember.every((member) => canMoveProjectMember(project, member));
        return canMoveAllProjectMembers;
    };

    const canBulkRemoveProjectMember = (project: {
        userId: string;
        collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[];
    }): boolean => {
        const isOwner = project.userId === session?.user.id;
        return isOwner;
    };

    const canViewAndAddProspectsToSequence = (sequence: { userId: string }): boolean =>
        sequence.userId === session?.user.id;

    const canViewAndAddProspectsToProject = (project: {
        userId: string;
        collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[];
    }): boolean => {
        const isOwner = project.userId === session?.user.id;
        const isEditor = project.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === ProjectCollaboratorTypeEnum.Editor
        );

        return isOwner || isEditor;
    };

    const canShareProject = (project: { userId: string }): boolean => project.userId === session?.user.id;

    const canAddToSequenceTroughSmartFlow = (project: {
        userId: string;
        collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[];
    }) => {
        const isOwner = project.userId === session?.user.id;
        const isEditor = project.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === ProjectCollaboratorTypeEnum.Editor
        );

        return isOwner || isEditor;
    };

    const canRemoveTroughSmartFlow = (project: {
        userId: string;
        collaborators: { userId: string; type: ProjectCollaboratorTypeEnum }[];
    }) => {
        const isOwner = project.userId === session?.user.id;

        return isOwner;
    };

    const canTransferProjectOwnership = (project: { userId: string }) => {
        const isOwner = project.userId === session?.user.id;
        return isOwner;
    };

    /**
     * using the licenseType for a user from the db instead of the session
     * in case of role update by the team admin / owner the session is not updated
     * the affected user. the user will have to logout and login again to get the updated session
     * 
     * /
     
    
    /**
     * for starter / free trial plan the user can add only 1 email account
     * for growth and growth trial plan the user can add unlimited emails if the license type is not reviewer / archived
     * starter is referred as solo in code
     * growth / growth trial are referred as enterprise / enterprise free trial in code 
     * @param licenseType
     * @param planType
     * @param emailAccounts
     * @returns {boolean}
     */
    const canAddEmailAccounts = (
        licenseType: LicenseTypesEnum,
        planType: PlanTypesEnum,
        emailAccounts?: EmailAccounts
    ): boolean => {
        if (emailAccounts) {
            if (planType === PlanTypesEnum.Expired) {
                return false;
            }
            if ([LicenseTypesEnum.Archived, LicenseTypesEnum.Reviewer].includes(licenseType)) {
                return false;
            }
            if ([PlanTypesEnum.FreeTrial, PlanTypesEnum.Solo].includes(planType) && emailAccounts.length >= 1) {
                return false;
            }
        }
        return true;
    };

    /**
     * sobo exists only for enterprise and enterprise free trial plans
     * also sobo is not available for reviewer / archived license type
     * @param planType
     * @param licenseType
     * @returns {boolean}
     */
    const canAccessSobo = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean => {
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            licenseType === LicenseTypesEnum.Full
        ) {
            return true;
        }
        return false;
    };

    /**
     * email send limits can be updated if plan is not expired and license type is not reviewer / archived
     * @param planType
     * @param licenseType
     * @returns {boolean}
     */
    const canUpdateSendLimit = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean => {
        if (planType === PlanTypesEnum.Expired) {
            return false;
        }
        if ([LicenseTypesEnum.Reviewer, LicenseTypesEnum.Archived].includes(licenseType)) {
            return false;
        }
        return true;
    };

    /**
     * can access ai sourcing if plan is not expired and license type is not archived
     * @param planType
     * @param licenseType
     * @returns {boolean}
     */
    const canAccessAISourcing = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean =>
        planType !== PlanTypesEnum.Expired && licenseType !== LicenseTypesEnum.Archived;

    /**
     * for free trial and solo / starter - only 20 active project are allowed
     * for enterprise / enterprise free trial - unlimited sequences are allowed for all full / reviewer license types
     */
    const canCreateProjects = (
        planType: PlanTypesEnum,
        activeProjects: number,
        licenseType: LicenseTypesEnum
    ): boolean => {
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            [LicenseTypesEnum.Full, LicenseTypesEnum.Reviewer].includes(licenseType)
        ) {
            return true;
        }
        if ([PlanTypesEnum.FreeTrial, PlanTypesEnum.Solo].includes(planType) && activeProjects < MAX_ACTIVE_PROJECTS) {
            return true;
        }

        return false;
    };

    /**
     * for free trial and solo / starter - only 20 active sequences are allowed
     * for enterprise / enterprise free trial - unlimited sequences are allowed for all full license types
     */
    const canCreateSequences = (
        planType: PlanTypesEnum,
        licenseType: LicenseTypesEnum,
        activeSequences: number
    ): boolean => {
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            licenseType === LicenseTypesEnum.Full
        ) {
            return true;
        }
        if (
            [PlanTypesEnum.FreeTrial, PlanTypesEnum.Solo].includes(planType) &&
            activeSequences < MAX_ACTIVE_SEQUENCES
        ) {
            return true;
        }
        return false;
    };

    /**
     * for enterprise / enterprise free trial - can add to project all full license types
     * for free trial and solo / starter - can add to project
     */
    const canAddToProjectFromExtension = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean => {
        if (licenseType === LicenseTypesEnum.Archived) {
            return false;
        }
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            licenseType === LicenseTypesEnum.Full
        ) {
            return true;
        }
        if ([PlanTypesEnum.FreeTrial, PlanTypesEnum.Solo].includes(planType)) {
            return true;
        }
        return false;
    };

    /**
     * for enterprise / enterprise free trial - can add to sequence all full license types
     * for free trial and solo / starter - can add to sequence
     */
    const canAddToSequenceFromExtension = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean => {
        if (licenseType === LicenseTypesEnum.Archived) {
            return false;
        }
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            licenseType === LicenseTypesEnum.Full
        ) {
            return true;
        }
        if ([PlanTypesEnum.FreeTrial, PlanTypesEnum.Solo].includes(planType)) {
            return true;
        }
        return false;
    };

    /**
     * only the team admins and the owner can view the billing information
     * @param role
     * @returns {boolean}
     */
    const canViewBillingInformation = (role?: TeamRolesEnum): boolean => {
        if (role && [TeamRolesEnum.Admin, TeamRolesEnum.Owner].includes(role)) {
            return true;
        }
        return false;
    };

    /**
     * only the owner of the team can edit the billing information
     * @param role
     * @returns {boolean}
     */
    const canEditBillingInformation = (role: TeamRolesEnum): boolean => {
        if ([TeamRolesEnum.Owner].includes(role)) {
            return true;
        }
        return false;
    };

    /**
     * if the plan type is enterprise / enterprise free trial / solo then the user can access the teams page
     * if licenseType is archived then the user cannot access the teams page
     * @param planType
     * @param userStatus
     * @returns {boolean}
     */
    const canAccessTeamsPage = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean =>
        // eslint-disable-next-line max-len
        [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial, PlanTypesEnum.Solo].includes(planType) &&
        licenseType !== LicenseTypesEnum.Archived;

    /**
     * if the plan type is enterprise / enterprise free trial
     *    and user role is admin or owner then the user can add team members
     * if plan type is solo and seats are available then the user can add team members
     * @param planType
     * @param role
     * @param seatsAvailable
     * @returns {boolean}
     */
    const canAddTeamMembers = (planType: PlanTypesEnum, role: TeamRolesEnum, seatsAvailable: boolean): boolean => {
        if (
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
            [TeamRolesEnum.Admin, TeamRolesEnum.Owner].includes(role)
        ) {
            return true;
        }
        if (PlanTypesEnum.Solo === planType && seatsAvailable) {
            return true;
        }
        return false;
    };

    /**
     * if plan type is not expired then can review prospects
     * if licenseType is archived then user cannot review prospects
     * @param planType
     * @param licenseType
     * @returns {boolean}
     */
    const canReviewProspects = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): boolean =>
        planType !== PlanTypesEnum.Expired && licenseType !== LicenseTypesEnum.Archived;

    return {
        accessControlLoaded: loaded,
        canViewSequence,
        canViewProject,
        canEditSequence,
        canEditRecipient,
        canEditProject,
        canChangeProjectMemberStatus,
        canMoveProjectMember,
        canRemoveProjectMember,
        canBulkAddRecipientsFromProject,
        canBulkRemoveProjectMember,
        canBulkMoveProjectMembers,
        canViewAndAddProspectsToSequence,
        canViewAndAddProspectsToProject,
        canShareProject,
        canAddToSequenceTroughSmartFlow,
        canRemoveTroughSmartFlow,
        canTransferProjectOwnership,
        canFullyDuplicateSequence,
        canAddEmailAccounts,
        canAccessSobo,
        canUpdateSendLimit,
        canAccessAISourcing,
        canCreateProjects,
        canCreateSequences,
        canAddToProjectFromExtension,
        canAddToSequenceFromExtension,
        canViewBillingInformation,
        canEditBillingInformation,
        canAccessTeamsPage,
        canAddTeamMembers,
        canReviewProspects,
        isUserProjectReviewer,
        canChangeRecipientStatus,
        canReportWrongRecipientEmail,
        canStopSequenceForRecipient,
        canUnsubscribeRecipient,
        canResendSequenceToNewEmail,
    };
};
