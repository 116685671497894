import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { SourcerMemberRejectionReasonsEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Chip } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerMemberCardRejectionReasonsProps {
    sourcerMemberId: string;
    rejectionReasons?: SourcerMemberRejectionReasonsEnum[];
    onRejectionReasonsChange: (
        sourcerMemberId: string,
        newRejectionReasons: SourcerMemberRejectionReasonsEnum[]
    ) => void;
}

const SourcerMemberCardRejectionReasons: FC<SourcerMemberCardRejectionReasonsProps> = ({
    sourcerMemberId,
    rejectionReasons = [],
    onRejectionReasonsChange,
}) => {
    const theme = useTheme();
    const translate = useTranslations('shared.prospect-card.rejection-reasons');

    const rejectionReasonsOptions: { value: SourcerMemberRejectionReasonsEnum; label: string }[] = [
        { value: SourcerMemberRejectionReasonsEnum.TooSenior, label: translate('too-senior') },
        { value: SourcerMemberRejectionReasonsEnum.TooJunior, label: translate('too-junior') },
        { value: SourcerMemberRejectionReasonsEnum.WrongSkills, label: translate('wrong-skills') },
        { value: SourcerMemberRejectionReasonsEnum.WrongIndustry, label: translate('wrong-industry') },
        { value: SourcerMemberRejectionReasonsEnum.AlreadyInPipeline, label: translate('already-in-pipeline') },
        { value: SourcerMemberRejectionReasonsEnum.WrongLocation, label: translate('wrong-location') },
    ];

    const handleToggleReason = (rejectionReason: SourcerMemberRejectionReasonsEnum) => () => {
        if (rejectionReasons.includes(rejectionReason)) {
            const newRejectionReasons = rejectionReasons.filter((reason) => reason !== rejectionReason);
            onRejectionReasonsChange(sourcerMemberId, newRejectionReasons);
        } else {
            onRejectionReasonsChange(sourcerMemberId, [...rejectionReasons, rejectionReason]);
        }
    };
    return (
        <Box
            css={css`
                display: flex;
                flex-flow: row wrap;
                gap: ${spacing.space8px};
            `}
        >
            {rejectionReasonsOptions.map((rejectionReasonOption) => (
                <Chip
                    css={css`
                        background: ${theme.palette.common.white};
                    `}
                    selected={rejectionReasons?.includes(rejectionReasonOption.value)}
                    clickable
                    key={rejectionReasonOption.value}
                    onClick={handleToggleReason(rejectionReasonOption.value)}
                    variant="outlined"
                    label={rejectionReasonOption.label}
                />
            ))}
        </Box>
    );
};

export { SourcerMemberCardRejectionReasons };
