import { css, SelectChangeEvent, useTheme } from '@mui/material';
import { extensionPanelWidth } from 'hireflow-shared/extension/messaging';
import { getCustomizeVariableLabel, personalizeHireflowSignature } from 'hireflow-shared/utils/email-drafting-utils';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { trackEvent } from 'analytics';
import { LicenseTypesEnum, SequenceStageSendTimeEnum } from 'codegen/graphql';
import { DraftPersonalizedSequenceStages, StageViewFormValues } from 'prospects/types/form-types';
import { sendTimeOptions } from 'sequences/settings';
import { Box } from 'shared/components/containers';
import { MenuItem, Select, SelectOption } from 'shared/components/form';
import { Button, LoadingButton, TextField } from 'shared/components/presentational';
import { AlertCircle, Circle, MinusCircle, PlusCircle } from 'shared/components/svgs';
import { useProspectPanel, useSession } from 'shared/hooks';
import { colors, fontFamilies, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const ReactQuill = dynamic(() => import('react-quill'), {
    ssr: false,
});

interface AddTabBottomExtensionProps {
    personalizedSequenceStages?: DraftPersonalizedSequenceStages;
    formMethods: UseFormReturn<StageViewFormValues>;
    sequenceId?: string;
    selectedProjects?: string[];
    missingFields: string[];
    onSaveAndApply: (updates: Map<string, string>) => void;
    onAdd: () => void;
    onAddLoading: boolean;
    setHeight: (value: number) => void;
    hasAddToProjectAndSequencePermission: boolean;
}

const AddTabBottomExtension: FC<AddTabBottomExtensionProps> = (props) => {
    const {
        formMethods,
        sequenceId,
        missingFields,
        selectedProjects,
        onSaveAndApply,
        onAdd,
        onAddLoading,
        setHeight,
        personalizedSequenceStages,
        hasAddToProjectAndSequencePermission,
    } = props;

    const theme = useTheme();
    const { session, loaded } = useSession();
    const { prospectInfo, sequencesData } = useProspectPanel();
    const translate = useTranslations('prospects.tabs.add-tab');

    const [showTimeCustomization, setShowTimeCustomization] = useState<boolean>(false);
    const [timeOptions, setTimeOptions] = useState<SelectOption[]>([]);
    const [fieldInfo, setFieldInfo] = useState(new Map<string, string>());

    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (ref && ref.current) {
            // eslint-disable-next-line no-magic-numbers
            setHeight(ref.current.clientHeight - 20);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current?.clientHeight]);

    useEffect(() => {
        const fields = new Map();
        for (const field of missingFields) {
            fields.set(field, '');
        }
        setFieldInfo(fields);
    }, [missingFields]);

    useEffect(() => {
        if (missingFields.length > 0) {
            trackEvent('load_missing_variable_dialogue', {
                prospect_id: prospectInfo?.id,
                sequence_id: sequenceId,
            });
        }
    }, [missingFields, prospectInfo, sequenceId]);

    useEffect(() => {
        if (personalizedSequenceStages && sequencesData && session) {
            const timePersonalized =
                personalizedSequenceStages.sequenceId === sequenceId &&
                personalizedSequenceStages.userId === session?.user.id &&
                personalizedSequenceStages.time !== sequencesData.find((f) => f.id === sequenceId)?.stages[0]?.sendTime;
            setShowTimeCustomization(timePersonalized);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalizedSequenceStages, sequencesData, session]);

    useEffect(() => {
        if (sequenceId && sequencesData) {
            const stages = sequencesData.find((f) => f.id === sequenceId)?.stages;
            const typeOfDay = stages?.[0].sendTypeOfDays ?? undefined;
            setTimeOptions(sendTimeOptions(translate, typeOfDay));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequenceId]);

    const handleTimeCustomization = () => {
        setShowTimeCustomization(!showTimeCustomization);
    };

    const handleTimeChange = (event: SelectChangeEvent<unknown>) => {
        formMethods.setValue('time', event.target.value as SequenceStageSendTimeEnum);
        trackEvent('select_custom_sending_time', {
            tab_name: 'add_tab',
            value: event.target.value,
        });
    };

    const handleSave = () => {
        onSaveAndApply(fieldInfo);
        trackEvent('edit_missing_variable', {
            prospect_id: prospectInfo?.id,
            sequence_id: sequenceId,
        });
    };

    const handleValueChange = (variable: string) => (event: any) => {
        const updatedFields = new Map(fieldInfo);
        updatedFields.set(
            variable,
            // TODO: check if this is relevant as editor block tags have been changed to use 'DIV'
            variable === personalizeHireflowSignature ? event.replace('<p><br></p>', '') : event.target.value
        );
        setFieldInfo(updatedFields);
    };

    const disabled =
        (sequenceId === undefined && selectedProjects === undefined) || !hasAddToProjectAndSequencePermission;

    const disableSaveAndApply = Array.from(fieldInfo.values()).some((value) => !value?.trim());

    const timeMenuOptions = timeOptions.map((option) => (
        <MenuItem value={option.value} key={option.value}>
            {option.value === formMethods.getValues('time') && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            {option.label}
        </MenuItem>
    ));

    const missingSection = Array.from(fieldInfo.keys()).map((field) => {
        if (field === personalizeHireflowSignature) {
            return (
                <Box
                    key={`react-quill-${field}`}
                    css={css`
                        padding: 4px;
                        margin-top: ${spacing.space24px};
                        background-color: ${theme.palette.common.white};
                        border-radius: ${spacing.space4px};
                        background-color: ${theme.palette.common.white};
                    `}
                >
                    <Box
                        css={css`
                            padding: ${spacing.space8px};
                            font-size: ${fontSizes.f12};
                            color: ${theme.palette.grey[600]};
                        `}
                    >
                        {translate(getCustomizeVariableLabel(field) || 'missing-info')}
                    </Box>
                    <ReactQuill
                        css={css`
                            .ql-toolbar {
                                background-color: ${theme.palette.common.white};
                            }
                            .ql-editor {
                                font-size: ${fontSizes.f16};
                                font-family: ${fontFamilies.inter};
                                background-color: ${theme.palette.common.white};
                            }
                            .ql-editor ol,
                            .ql-editor ul {
                                display: block;
                                margin-block-start: 1em;
                                margin-block-end: 1em;
                            }
                        `}
                        theme="snow"
                        preserveWhitespace
                        onChange={handleValueChange(field)}
                    />
                </Box>
            );
        }
        return (
            <Box
                key={`box-textfield-${field}`}
                css={css`
                    margin-top: ${spacing.space24px};
                    background-color: ${theme.palette.common.white};
                    border-radius: ${spacing.space4px};
                `}
            >
                <TextField
                    key={`textfield-${field}`}
                    parentBackgroundColor="gray"
                    onChange={handleValueChange(field)}
                    label={translate(getCustomizeVariableLabel(field) || 'missing-info')}
                    css={css`
                        width: 100%;
                        .MuiFilledInput-root {
                            border: unset;
                        }
                    `}
                />
            </Box>
        );
    });

    const missingVariables = (
        <>
            <Box
                css={css`
                    display: flex;
                    justify-content: top;
                `}
            >
                <Box>
                    <AlertCircle
                        stroke={theme.palette.common.white}
                        css={css`
                            margin-right: ${spacing.space12px};
                        `}
                    />
                </Box>
                <Box
                    css={css`
                        color: ${theme.palette.common.white};
                        font-size: ${fontSizes.f14};
                    `}
                >
                    {translate('missing-variables-label')}
                </Box>
            </Box>
            <Box>{missingSection}</Box>
            <Box>
                <Button
                    disabled={disableSaveAndApply}
                    variant="contained"
                    onClick={handleSave}
                    css={css`
                        width: 100%;
                        margin-top: ${spacing.space24px};
                        height: ${spacing.space44px};
                        background-color: ${disableSaveAndApply
                            ? colors.grays.dusty
                            : theme.palette.primary.main} !important;
                    `}
                >
                    {translate('save-and-apply-label')}
                </Button>
            </Box>
        </>
    );

    const variablesFilled = (
        <>
            {sequenceId && (
                <Box>
                    <Button
                        onClick={handleTimeCustomization}
                        startIcon={
                            showTimeCustomization ? <MinusCircle stroke={theme.palette.primary.main} /> : <PlusCircle />
                        }
                    >
                        {translate('customize-time-label')}
                    </Button>
                </Box>
            )}
            {formMethods.getValues('time') && showTimeCustomization && (
                <Box>
                    <Select
                        label={translate('time-dropdown-label')}
                        variant="outlined"
                        options={timeOptions}
                        value={formMethods.getValues('time')}
                        name="select-initial-email-time"
                        onChange={handleTimeChange}
                        css={css`
                            width: 100%;
                        `}
                    >
                        {timeMenuOptions}
                    </Select>
                </Box>
            )}
            <Box
                css={css`
                    padding-top: ${spacing.space16px};
                `}
            >
                <LoadingButton
                    variant="contained"
                    css={css`
                        width: 100%;
                        height: ${spacing.space44px};
                    `}
                    onClick={onAdd}
                    disabled={disabled}
                    loading={onAddLoading}
                >
                    {sequenceId && (selectedProjects || []).length > 0
                        ? translate('add-button-projects-sequence-label')
                        : sequenceId
                        ? translate('add-button-sequence-only-label')
                        : translate('add-button-projects-only-label')}
                </LoadingButton>
            </Box>
        </>
    );

    if (!loaded || session?.user.licenseType === LicenseTypesEnum.Reviewer) {
        return null;
    }

    return (
        <Box
            ref={ref}
            css={css`
                position: fixed;
                bottom: 0;
                width: ${extensionPanelWidth};
                margin-top: ${spacing.space36px};
                padding: ${spacing.space24px} ${spacing.space36px};
                box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.1);
                background-color: ${missingFields.length > 0 ? theme.palette.grey[200] : theme.palette.common.white};
                z-index: 100;
            `}
        >
            {missingFields.length > 0 ? missingVariables : variablesFilled}
        </Box>
    );
};

export { AddTabBottomExtension };
