import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface LearnMoreMessageProps {
    icon: ReactNode;
    message: ReactNode;
}

const LearnMoreMessage: FC<LearnMoreMessageProps> = ({ icon, message }) => {
    const theme = useTheme();

    return (
        <Box
            css={css`
                padding: 16px 24px 16px 24px;
                margin-top: 16px;

                border-radius: 8px;

                font-family: Inter, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;

                background-color: ${theme.palette.primary.dark};

                display: flex;
                align-items: center;
                gap: 16px;
            `}
        >
            <Box>{icon}</Box>
            <Box>{message}</Box>
        </Box>
    );
};

export { LearnMoreMessage };
