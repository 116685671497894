import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { PlanTypesEnum } from 'codegen/graphql';
import { MAX_ACTIVE_PROJECTS } from 'projects/constants';
import { MAX_ACTIVE_SEQUENCES } from 'sequences/constants';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface BasePrivilegesProps {
    planType: PlanTypesEnum;
    activeProjectCount?: number;
    activeSequenceCount?: number;
    aiSourcerCredits: number;
    showAISourcerCredits?: boolean;
}

const BasePrivileges: FC<BasePrivilegesProps> = (props) => {
    const theme = useTheme();
    const translate = useTranslations('settings.plans-and-billing-settings');
    const { planType, activeProjectCount, activeSequenceCount, aiSourcerCredits, showAISourcerCredits = true } = props;

    const isEnterprisePlan = [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType);
    const isExpiredPlan = planType === PlanTypesEnum.Expired;

    const activeProjects = isEnterprisePlan
        ? translate('unlimited')
        : isExpiredPlan
        ? translate('none')
        : MAX_ACTIVE_PROJECTS - (activeProjectCount ?? 0);

    const activeSequences = isEnterprisePlan
        ? translate('unlimited')
        : isExpiredPlan
        ? translate('none')
        : MAX_ACTIVE_SEQUENCES - (activeSequenceCount ?? 0);

    const privilegesInfo: {
        title: string;
        available: string | number;
        total?: number;
        isAISCredits?: boolean;
    }[] = [
        { title: translate('projects-credits'), available: activeProjects, total: MAX_ACTIVE_PROJECTS },
        { title: translate('sequence-credits'), available: activeSequences, total: MAX_ACTIVE_SEQUENCES },
    ];

    if (showAISourcerCredits) {
        privilegesInfo.push({
            title: translate('ai-sourcing-credits'),
            available: aiSourcerCredits ?? 0,
            isAISCredits: true,
        });
    }

    const privileges = privilegesInfo.map((i, key) => (
        <Box
            key={key}
            css={css`
                display: flex;
                flex-direction: column;
                padding: ${spacing.space16px};
                gap: 6px;
                background: ${theme.palette.common.white};
                border-radius: ${spacing.space8px};
                flex-grow: 1;
                &:nth-of-type(2) {
                    margin: 0 16px;
                }
            `}
        >
            <p
                css={css`
                    font-weight: ${fontWeights.medium};
                    font-size: ${fontSizes.f12};
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    color: ${theme.palette.common.black};
                    margin: 0;
                `}
            >
                {i.title}
            </p>
            <Title
                type="h3"
                css={css`
                    color: ${theme.palette.primary.main};
                    font-size: ${spacing.space24px};
                    margin: 0;
                    span {
                        color: black;
                        font-size: ${fontSizes.f14};
                        margin-left: 2px;
                    }
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        ${i.isAISCredits ? 'color: grey' : ''}
                    `}
                >
                    {i.available}
                    {i.total && !i.isAISCredits && !isEnterprisePlan && !isExpiredPlan ? (
                        <Box component="span"> {` / ${i.total}`}</Box>
                    ) : (
                        ''
                    )}
                </Box>
            </Title>
        </Box>
    ));

    return (
        <Box
            css={css`
                display: flex;
                width: 100%;
                justify-content: space-between;
            `}
        >
            {privileges}
        </Box>
    );
};

export { BasePrivileges };
