import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { ChangeEvent } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Switch } from 'shared/components/form';
import { Title } from 'shared/components/presentational';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSession } from 'shared/hooks/use-session';
import { spacing } from 'shared/settings';

const AdvancedOptions = () => {
    const translate = useTranslations('settings.email-settings.advanced-options');
    const { session, setSession } = useSession();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);

    const autoArchiveOutOfOfficeEmails: boolean | undefined = session!.user.autoArchiveOutOfOfficeEmails ?? true;
    const autoArchiveBouncedEmails: boolean | undefined = session!.user.autoArchiveBouncedEmails ?? true;
    const findAlternateEmailIfInitialBounces: boolean | undefined =
        session!.user.findAlternateEmailIfInitialBounces ?? true;
    const addLabelToHiresequenceMessages: boolean | undefined = session!.user.addLabelToHiresequenceMessages ?? true;
    const includeUnsubscribeLinkToEmailOutreaches: boolean | undefined =
        session!.user.includeUnsubscribeLinkToEmailOutreaches ?? false;
    const handleToggle = (field: string) => async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: { [field]: checked },
            },
        });

        setSession({
            user: {
                ...session!.user,
                [field]: checked,
            },
        });
    };

    const toggleSettings = (
        [
            {
                field: 'autoArchiveOutOfOfficeEmails',
                label: 'auto-archive-out-of-office-emails',
                value: autoArchiveOutOfOfficeEmails,
            },
            {
                field: 'autoArchiveBouncedEmails',
                label: 'auto-archive-bounced-emails',
                value: autoArchiveBouncedEmails,
            },
            {
                field: 'findAlternateEmailIfInitialBounces',
                label: 'find-alternate-email-if-initial-bounces',
                value: findAlternateEmailIfInitialBounces,
            },
            {
                field: 'addLabelToHiresequenceMessages',
                label: 'add-label-to-hiresequence-messages',
                value: addLabelToHiresequenceMessages,
            },
            {
                field: 'includeUnsubscribeLinkToEmailOutreaches',
                label: 'include-unsubscribed-link-to-email-outreaches',
                value: includeUnsubscribeLinkToEmailOutreaches,
            },
        ] as const
    ).map(({ field, label, value }) => (
        <Switch
            key={label}
            checked={value}
            onChange={handleToggle(field)}
            label={translate(label)}
            css={css`
                margin-left: 0;
            `}
        />
    ));

    return (
        <Box>
            <Title type="h2">{translate('title')}</Title>
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    row-gap: ${spacing.space16px};
                `}
            >
                {toggleSettings}
            </Box>
        </Box>
    );
};

export { AdvancedOptions };
