import styled from '@emotion/styled';
import {
    FormControlLabel as MuiFormControlLabel,
    FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';

import { FC } from 'shared/types';

interface FormControlLabelProps extends MuiFormControlLabelProps {}

const FormControlLabel: FC<FormControlLabelProps> = (props) => <MuiFormControlLabel {...props} />;

const StyledFormControlLabel = styled(FormControlLabel)``;

export { StyledFormControlLabel as FormControlLabel };
