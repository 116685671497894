import { useLazyQuery, useQuery } from '@apollo/client';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { AutocompleteRenderInputParams, css, useTheme } from '@mui/material';
import { truncate } from 'lodash';
import { useTranslations } from 'next-intl';
import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
    AshbyAccountByTeamIdQuery,
    GreenhouseAccountByTeamIdQuery,
    LeverAccountByTeamIdQuery,
    SequenceStatusEnum,
    SequencesWithAtsMappingQuery,
    SequencesWithAtsMappingQueryVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { SelectOption, AutoCompleteProps, AutoComplete, GroupedSelectOption, MenuItem } from 'shared/components/form';
import { TextField } from 'shared/components/presentational';
import { AshbyLogo, GreenhouseLogo, LeverLogo } from 'shared/components/svgs';
import {
    GET_ASHBY_ACCOUNT_BY_TEAM_ID,
    GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
    GET_LEVER_ACCOUNT_BY_TEAM_ID,
} from 'shared/graphql/integrations';
import { GET_SEQUENCES_WITH_ATS_MAPPING, NO_SEQUENCE, sequencesDefaultVariables } from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { colors } from 'shared/settings';
import { FCProps } from 'shared/types';
import { getOwner, maxNameLength, maxNameLengthShort } from 'shared/utils';

interface SequenceOption extends SelectOption {
    user: SequencesWithAtsMappingQuery['sequences'][number]['user'] | undefined;
    ashby_sequence_mapping: SequencesWithAtsMappingQuery['sequences'][number]['ashby_sequence_mapping'];
    greenhouse_sequence_mapping: SequencesWithAtsMappingQuery['sequences'][number]['greenhouse_sequence_mapping'];
    lever_sequence_mapping: SequencesWithAtsMappingQuery['sequences'][number]['lever_sequence_mapping'];
}

interface SelectSequenceProps<Values extends FieldValues> extends AutoCompleteProps<Values> {}

const SelectSequence = <Values extends FieldValues>(props: SelectSequenceProps<Values> & FCProps) => {
    const { options: sequenceOptions, ...rest } = props;

    const theme = useTheme();
    const { session, loaded } = useSession();
    const [options, setOptions] = useState<SequenceOption[]>([]);
    const translate = useTranslations('prospects.tabs.add-tab');

    // ashby
    const { data: ashbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(GET_ASHBY_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // greenhouse
    const { data: greenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // lever
    const { data: leverAccount } = useQuery<LeverAccountByTeamIdQuery>(GET_LEVER_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const isAshbyConnected: boolean = !!ashbyAccount?.data.length;
    const isGreenhouseConnected: boolean = !!greenhouseAccount?.data.length;
    const isLeverConnected: boolean = !!leverAccount?.data.length;
    const isAtsConnected: boolean = isAshbyConnected || isGreenhouseConnected || isLeverConnected;

    const [getSequencesData] = useLazyQuery<SequencesWithAtsMappingQuery, SequencesWithAtsMappingQueryVariables>(
        GET_SEQUENCES_WITH_ATS_MAPPING
    );

    const fetchSequencesData = useCallback(
        async () => {
            if (!sequenceOptions) {
                return;
            }

            const { data } = await getSequencesData({
                variables: {
                    ...sequencesDefaultVariables,
                    where: {
                        id: { _in: sequenceOptions.map((p) => p.value) },
                        status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                    },
                },
            });

            if (!data || !data.sequences) {
                return;
            }

            setOptions([
                {
                    label: translate('no-sequence-label'),
                    value: NO_SEQUENCE,
                    user: undefined,
                    ashby_sequence_mapping: undefined,
                    greenhouse_sequence_mapping: undefined,
                    lever_sequence_mapping: undefined,
                },
                ...data.sequences.map((sequence) => {
                    const matchedOption = sequenceOptions.find((option) => option.value === sequence.id);
                    return {
                        label: sequence.title,
                        value: sequence.id,
                        disabled: matchedOption && matchedOption.disabled,
                        user: sequence.user,
                        ashby_sequence_mapping: sequence.ashby_sequence_mapping,
                        greenhouse_sequence_mapping: sequence.greenhouse_sequence_mapping,
                        lever_sequence_mapping: sequence.lever_sequence_mapping,
                    };
                }),
            ]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sequenceOptions]
    );

    useEffect(() => {
        fetchSequencesData();
    }, [sequenceOptions, fetchSequencesData]);

    const getAtsIcon = useCallback(
        (option: SequenceOption) => {
            let mappingExists: boolean = false;
            let res: EmotionJSX.Element = (
                <Box
                    css={css`
                        width: 16px;
                    `}
                />
            );

            if (isAshbyConnected && option.ashby_sequence_mapping?.id) {
                mappingExists = true;
                res = <AshbyLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }
            if (isGreenhouseConnected && option.greenhouse_sequence_mapping?.id) {
                mappingExists = true;
                res = <GreenhouseLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }
            if (isLeverConnected && option.lever_sequence_mapping?.id) {
                mappingExists = true;
                res = <LeverLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }

            return {
                mappingExists,
                icon: res,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAshbyConnected, isGreenhouseConnected, isLeverConnected]
    );

    const getOptionChild = useCallback(
        (sequenceOption: SequenceOption, hideTransparentIcon: boolean, useShortName: boolean) => {
            const iconResponse = getAtsIcon(sequenceOption);
            const hideIcon = !iconResponse.mappingExists && hideTransparentIcon;

            return (
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        width: 100%;

                        padding: 8px 0px;
                    `}
                >
                    {isAtsConnected && !hideIcon && iconResponse.icon}
                    <Box>
                        {truncate(sequenceOption.label, { length: useShortName ? maxNameLengthShort : maxNameLength })}
                    </Box>
                    <Box
                        css={css`
                            flex: 1;

                            display: flex;
                            justify-content: flex-end;

                            color: ${colors.grays.dusty};
                            font-size: 11px;
                            white-space: nowrap;
                        `}
                    >
                        {getOwner({ option: sequenceOption })}
                    </Box>
                </Box>
            );
        },
        [getAtsIcon, isAtsConnected]
    );

    const formatOptionLabel = useCallback(
        (renderOptionProps: HTMLAttributes<HTMLLIElement>, option: GroupedSelectOption) => {
            const sequenceOption = option as SequenceOption;

            return (
                <MenuItem
                    key={sequenceOption.value}
                    {...renderOptionProps}
                    value={sequenceOption.value}
                    disabled={sequenceOption.disabled}
                >
                    {getOptionChild(sequenceOption, false, false)}
                </MenuItem>
            );
        },
        [getOptionChild]
    );

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams, label?: string, value?: string) => {
            const matchedOption = options.find((option) => option.value === value);

            if (!matchedOption) {
                return <TextField {...params} label={label} value={value} theme={rest.theme} />;
            }

            // this is a hack, but cannot think of a faster way to achieve this
            // basically, the customized html option is placed over the standard selected input value
            // to give the illusion that the value in the autocomplete is enriched html
            // to address this we would need to use a react-select component instead of the autocomplete
            // or rework the way that we render the input component for the autocomplete component
            return (
                <Box>
                    <TextField {...params} label={label} value={value} theme={rest.theme} />
                    <Box
                        // simulates event handlers as if using the regular textfield component
                        onBlur={params.inputProps.onBlur}
                        onChange={params.inputProps.onChange}
                        onFocus={params.inputProps.onFocus}
                        onMouseDown={params.inputProps.onMouseDown}
                        // opacity is 0 if the user is actively searching to hide the overlay
                        css={css`
                            opacity: ${params.inputProps.value === matchedOption.label ? '1' : '0'};
                            margin: 0px 12px;
                            position: absolute;
                            z-index: 1;
                            top: 18px;
                            width: calc(100% - 60px);
                            background: ${colors.grays.alabaster};
                            cursor: pointer;
                        `}
                    >
                        {getOptionChild(matchedOption, true, true)}
                    </Box>
                </Box>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getOptionChild, options]
    );

    if (!options) {
        return null;
    }

    return (
        <AutoComplete
            {...rest}
            options={options.sort((a, b) => Number(a.disabled) - Number(b.disabled))}
            renderOption={formatOptionLabel}
            renderInput={renderInput}
        />
    );
};

export { SelectSequence };
export type { SequenceOption };
