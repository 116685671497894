import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ThemedVectorBack: FC<SvgProps> = ({ width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '315'}
        height={height ?? '315'}
        viewBox="0 0 315 315"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M157.474 0.500366L314.5 0.500366L314.5 157.474C314.5 178.095 310.438 198.514 302.547 217.566C294.656 236.617 283.089 253.927 268.508 268.509C253.927 283.09 236.617 294.656 217.565 302.547C198.514 310.439 178.095 314.5 157.474 314.5C115.837 314.487 75.9104 297.937 46.4735 268.49C17.0366 239.044 0.499998 199.111 0.5 157.474C0.513732 115.847 17.0564 75.9276 46.4918 46.4922C75.9272 17.0568 115.846 0.514098 157.474 0.500366Z"
            fill="#EDB742"
        />
    </svg>
);

const VectorBack = withTheme(ThemedVectorBack);

export { VectorBack };
