import { useQuery } from '@apollo/client';
import { SubscriptionInfo } from 'hireflow-shared/types/stripe/subscription-details';
import { getProductInfoByName, ProductName } from 'hireflow-shared/utils/stripe/stripe-products';
import { useEffect, useState } from 'react';

import { GetUserRoleQuery, TeamsQuery } from 'codegen/graphql';
import { config } from 'config';
import { GET_TEAM_BY_ID } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useSession } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';
import { EmptySourcingNotice } from 'sourcing/components/presentational/empty-sourcers-notice';
import { NoCreditsPackage } from 'sourcing/components/presentational/no-credits-package';

interface NoSourcersAvailableProps {}

const NoSourcersAvailable: FC<NoSourcersAvailableProps> = () => {
    const { session, loaded } = useSession();
    const restApiClient = createRestApiClient();

    const useProd = config.Env === 'production';
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>();

    const { data, loading } = useQuery<TeamsQuery>(GET_TEAM_BY_ID, {
        skip: !loaded,
        variables: { id: session?.user.teamId },
        fetchPolicy: 'network-only',
    });

    const { data: userRoleData, loading: loadingUserRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        const fetchSubscriptionData = async (subscriptionId: string) => {
            const subsData = await restApiClient.stripeSubscriptionDetails({
                subscriptionId,
            });
            setSubscriptionInfo(subsData?.data?.subscriptionInfo);
        };
        if (data?.teams_by_pk?.subscriptionId) {
            fetchSubscriptionData(data?.teams_by_pk?.subscriptionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.teams_by_pk?.subscriptionId]);

    if (loading || loadingUserRoleData || !data || !data.teams_by_pk || !userRoleData || !userRoleData.user_roles_by_pk)
        return null;

    const subscriptionIncludesAiSourcing = subscriptionInfo
        ? subscriptionInfo.items.some(
              (item) =>
                  item.productId === getProductInfoByName(ProductName.AISourcingCredit, useProd)?.productId ||
                  item.productId === getProductInfoByName(ProductName.AISourcingOneCredit, useProd)?.productId
          ) || data.teams_by_pk.aiSourcerCredits > 0
        : false;

    return subscriptionIncludesAiSourcing ? (
        <EmptySourcingNotice
            planType={data.teams_by_pk!.planType}
            licenseType={userRoleData.user_roles_by_pk.licenseType}
        />
    ) : (
        <NoCreditsPackage />
    );
};

export { NoSourcersAvailable };
