import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import { Button } from 'shared/components/presentational/button';
import { Checkbox } from 'shared/components/presentational/checkbox';
import { FilterButton } from 'shared/components/presentational/filter-button';
import { FilterTextField } from 'shared/components/presentational/filter-text-field';
import { Popover } from 'shared/components/presentational/popover';
import { XClose } from 'shared/components/svgs';
import { fontSizes, spacing } from 'shared/settings';
import { FCProps } from 'shared/types';

interface Option<T> {
    label: string;
    value: T;
}

interface CheckboxFilterProps<T> extends FCProps {
    label: string;
    allOptions: Option<T>[];
    checkedOptions: T[];
    onCheckboxClick: (value: T) => () => void;
    onClearFilter: () => void;
    showOptionFilter?: boolean;
}

const CheckboxFilter = <T,>({
    label,
    allOptions,
    checkedOptions,
    onCheckboxClick,
    onClearFilter,
    showOptionFilter = false,
}: CheckboxFilterProps<T>) => {
    const translate = useTranslations('checkbox-filter');

    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement>();
    const [optionFilter, setOptionFilter] = useState('');

    const isActive = checkedOptions.length > 0;

    const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    const handleOptionFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setOptionFilter(newKeyword);
    };

    const optionFilterTextField = (
        <FilterTextField
            parentBackgroundColor="white"
            css={css`
                margin: 0;
                margin-bottom: ${spacing.space16px};
            `}
            placeholder={translate('quickfind')}
            value={optionFilter}
            onChange={handleOptionFilterChange}
        />
    );

    const options = optionFilter
        ? allOptions.filter(({ label: optionLabel }) => optionLabel.toLowerCase().includes(optionFilter.toLowerCase()))
        : allOptions;

    const optionCheckboxes = options.map(({ label: optionLabel, value }) => (
        <FormControlLabel
            key={optionLabel}
            checked={checkedOptions.includes(value)}
            onChange={onCheckboxClick(value)}
            label={optionLabel}
            control={
                <Checkbox
                    css={css`
                        padding-left: 11px;
                    `}
                />
            }
            css={css`
                width: calc(100% - 16px);

                .MuiFormControlLabel-label {
                    font-size: ${fontSizes.f16};
                    margin-left: 7px;
                }
            `}
        />
    ));

    return (
        <>
            <FilterButton
                state={popoverAnchor ? 'hover' : isActive ? 'active' : 'default'}
                onClick={handleFilterButtonClick}
            >
                {label}
            </FilterButton>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(undefined)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space24px};
                        width: 337px;
                        max-height: 483px;
                    `}
                >
                    <Box>{showOptionFilter ? optionFilterTextField : null}</Box>
                    <Box
                        css={css`
                            overflow-y: scroll;
                        `}
                    >
                        {optionCheckboxes}
                    </Box>
                    <Divider
                        variant="fullWidth"
                        css={css`
                            margin-top: 16px;
                            margin-bottom: 16px;
                        `}
                    />
                    <Button
                        variant="text"
                        css={css`
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={onClearFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export { CheckboxFilter };
