import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import {
    sendTimeOptions,
    sendTypeOfDaysOptions,
    sendTimeOfDayOptions,
    sendTypeOfDaysStageOneOptions,
} from 'sequences/settings';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { Counter, MenuItem, Select, SelectOption } from 'shared/components/form';
import { Button } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { useSettings } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SendTimeProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    index: number;
    disabled?: boolean;
    isEdit?: boolean;
    isUserOwnedAccount: boolean;
}

const SendTime: FC<SendTimeProps> = (props) => {
    const { formMethods, index, disabled = false, isEdit, isUserOwnedAccount } = props;
    const { openEmailHours } = useSettings();
    const translate = useTranslations('sequence.create.stages');
    const theme = useTheme();

    const sendTimeState = formMethods.getFieldState(`stages.${index}.sendTime`, formMethods.formState);
    const sendTime = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendTime`,
    });

    useEffect(() => {
        if (sendTimeState.isDirty) {
            trackEvent('select_custom_sending_time', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: sendTime,
            });
        }
    }, [sendTime, sendTimeState.isDirty, isEdit]);

    const numberOfDaysState = formMethods.getFieldState(`stages.${index}.numberOfDays`, formMethods.formState);
    const numberOfDays = useWatch({
        control: formMethods.control,
        name: `stages.${index}.numberOfDays`,
    });

    useEffect(() => {
        if (numberOfDaysState.isDirty) {
            trackEvent('select_days_between_stage', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: numberOfDays,
            });
        }
    }, [numberOfDays, numberOfDaysState.isDirty, isEdit]);

    const sendTypeOfDaysState = formMethods.getFieldState(`stages.${index}.sendTypeOfDays`, formMethods.formState);
    const sendTypeOfDays = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendTypeOfDays`,
    });

    useEffect(() => {
        if (sendTypeOfDaysState.isDirty) {
            trackEvent('select_days_optimization', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: sendTypeOfDays,
            });
        }
    }, [sendTypeOfDays, sendTypeOfDaysState.isDirty, isEdit]);

    const sendTimeOfDayState = formMethods.getFieldState(`stages.${index}.sendTimeOfDay`, formMethods.formState);
    const sendTimeOfDay = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendTimeOfDay`,
    });

    useEffect(() => {
        if (sendTimeOfDayState.isDirty) {
            trackEvent('select_time_optimization', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: sendTimeOfDay,
            });
        }
    }, [sendTimeOfDay, sendTimeOfDayState.isDirty, isEdit]);

    const getOptions = (options: SelectOption[]) =>
        options.map((option) => (
            <MenuItem value={option.value} key={option.label}>
                {option.value === formMethods.getValues('stages')[index].sendTime && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {option.label}
            </MenuItem>
        ));

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
            `}
        >
            {index === 0 ? (
                <>
                    {translate('send')}

                    <Select
                        isDefault
                        disabled={disabled}
                        options={sendTimeOptions(translate, formMethods.getValues(`stages.${index}.sendTypeOfDays`))}
                        formMethods={formMethods}
                        value={formMethods.getValues(`stages.${index}.sendTime`) || ''}
                        name={`stages.${index}.sendTime`}
                        width="200px"
                        css={css`
                            margin: 0 ${spacing.space16px};
                        `}
                    >
                        {getOptions(
                            sendTimeOptions(translate, formMethods.getValues(`stages.${index}.sendTypeOfDays`))
                        )}
                    </Select>
                    <Circle fill={theme.palette.common.black} />

                    <Select
                        isDefault
                        disabled={disabled}
                        options={sendTypeOfDaysStageOneOptions(translate)}
                        formMethods={formMethods}
                        value={formMethods.getValues(`stages.${index}.sendTypeOfDays`) || ''}
                        name={`stages.${index}.sendTypeOfDays`}
                        width="200px"
                        css={css`
                            margin: 0 ${spacing.space16px};
                        `}
                    >
                        {getOptions(sendTypeOfDaysStageOneOptions(translate))}
                    </Select>
                    {isUserOwnedAccount ? (
                        <>
                            {translate('within-your')}

                            <Button
                                disabled={disabled}
                                css={css`
                                    padding: 2px 8px;
                                `}
                                onClick={openEmailHours}
                            >
                                {translate('sending-hours')}
                            </Button>
                        </>
                    ) : (
                        translate('within-teammates-sending-hours')
                    )}
                </>
            ) : (
                <>
                    {translate('send-message-after')}

                    <Counter
                        disabled={disabled}
                        name={`stages.${index}.numberOfDays`}
                        formMethods={formMethods}
                        css={css`
                            margin-left: ${spacing.space16px};
                            opacity: ${disabled ? '0.5' : '1'};
                        `}
                    />

                    <Select
                        isDefault
                        disabled={disabled}
                        options={sendTypeOfDaysOptions(translate)}
                        formMethods={formMethods}
                        value={formMethods.getValues(`stages.${index}.sendTypeOfDays`) || ''}
                        name={`stages.${index}.sendTypeOfDays`}
                        width="200px"
                        css={css`
                            margin: 0 ${spacing.space16px};
                        `}
                    >
                        {getOptions(sendTypeOfDaysOptions(translate))}
                    </Select>

                    <Circle fill={theme.palette.common.black} />

                    <Select
                        isDefault
                        disabled={disabled}
                        options={sendTimeOfDayOptions(translate, isUserOwnedAccount)}
                        formMethods={formMethods}
                        value={formMethods.getValues(`stages.${index}.sendTimeOfDay`) || ''}
                        name={`stages.${index}.sendTimeOfDay`}
                        width="234px"
                        css={css`
                            margin: 0 ${spacing.space16px};
                        `}
                    >
                        {getOptions(sendTimeOfDayOptions(translate, isUserOwnedAccount))}
                    </Select>
                </>
            )}
        </Box>
    );
};

export { SendTime };
