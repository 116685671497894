import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { RecipientResponseSentimentTypesEnum, RecipientStatusEnum, SourcerMemberStatusEnum } from 'codegen/graphql';
import { EllipsisText } from 'shared/components/presentational';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface StatusChipProps {
    status?: RecipientStatusEnum | SourcerMemberStatusEnum.ToReview;
    lastStageSent?: number;
    sentiment?: RecipientResponseSentimentTypesEnum | null;
}

const StatusChip: FC<StatusChipProps> = (props) => {
    const { status, lastStageSent, sentiment } = props;
    const translate = useTranslations('shared.recipient-status');

    const getStatusChipProperties = () => {
        if (!status) {
            return {
                text: translate('not-in-a-sequence'),
                bgColor: colors.grays.titanWhite,
                color: colors.neutrals.black,
            };
        }

        switch (status) {
            case RecipientStatusEnum.FindingEmail:
                return {
                    text: translate('finding-email'),
                    bgColor: colors.grays.titanWhite,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.NotStarted:
                return {
                    text: translate('not-started'),
                    bgColor: colors.grays.titanWhite,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.InProgress:
                return {
                    text: translate('in-progress-stage', { stage: lastStageSent }),
                    bgColor: colors.greens.narvik,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.Responded: {
                if (sentiment === RecipientResponseSentimentTypesEnum.Interested) {
                    return {
                        text: translate('responded-sentiment', {
                            sentiment: translate(sentiment),
                        }),
                        bgColor: colors.greens.eucalyptus,
                        color: colors.neutrals.white,
                    };
                }
                if (sentiment === RecipientResponseSentimentTypesEnum.NotInterested) {
                    return {
                        text: translate('responded-sentiment', {
                            sentiment: translate(sentiment),
                        }),
                        bgColor: colors.reds.persianRed10,
                        color: colors.neutrals.black,
                    };
                }
                return {
                    text: translate('responded'),
                    bgColor: colors.greens.eucalyptus,
                    color: colors.neutrals.white,
                };
            }

            case RecipientStatusEnum.MeetingBooked:
                return {
                    text: translate('meeting-booked'),
                    bgColor: colors.greens.narvik,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.CompletedWithNoReply:
                return {
                    text: translate('completed-with-no-reply'),
                    bgColor: colors.grays.titanWhite,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.UnableToFindContact:
                return {
                    text: translate('unable-to-find-contact'),
                    bgColor: colors.yellows.picasso100,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.UnableToSendMessage:
                return {
                    text: translate('unable-to-send-message'),
                    bgColor: colors.yellows.picasso100,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.MissingTemplateVariables:
                return {
                    text: translate('missing-template-variables'),
                    bgColor: colors.yellows.picasso100,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.ManuallyExited:
                return {
                    text: translate('manually-exited'),
                    bgColor: colors.grays.titanWhite,
                    color: colors.neutrals.black,
                };

            case RecipientStatusEnum.Unsubscribed:
                return {
                    text: translate('unsubscribed'),
                    bgColor: colors.reds.persianRed10,
                    color: colors.neutrals.black,
                };
            case SourcerMemberStatusEnum.ToReview: {
                return {
                    text: translate('in-ai-sourcer-review'),
                    bgColor: colors.grays.alabaster,
                    color: colors.neutrals.black,
                };
            }
            default:
                return {
                    text: '',
                    bgColor: '',
                    color: '',
                };
        }
    };

    const { text, bgColor, color } = getStatusChipProperties();
    return (
        <EllipsisText
            className="status-ellipsis-text"
            css={css`
                text-align: left;
                padding: ${spacing.space4px} ${spacing.space8px};
                background-color: ${bgColor};
                color: ${color};
                border-radius: ${spacing.space4px};
                max-width: min-content;
            `}
        >
            {text}
        </EllipsisText>
    );
};
export { StatusChip };
