import styled from '@emotion/styled';
import { FilledTextFieldProps, TextField as MuiTextField } from '@mui/material';
import { Ref } from 'react';

import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface TextFieldProps extends Omit<FilledTextFieldProps, 'variant'> {
    parentBackgroundColor?: 'white' | 'gray' | 'green';
}

interface DefaultInputProps {
    inputRef: Ref<any> | undefined;
    disableUnderline: boolean;
    id?: string | undefined;
}

interface DefaultInputLabelProps {
    htmlFor?: string | undefined;
}

const TextField: FC<TextFieldProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, inputRef, InputProps, InputLabelProps, parentBackgroundColor, ...rest } = props;
    const defaultInputProps: DefaultInputProps = { inputRef, disableUnderline: true };
    if (name) defaultInputProps.id = `${name}-input`;
    const defaultInputLabelProps: DefaultInputLabelProps = {};
    if (name) defaultInputLabelProps.htmlFor = `${name}-input`;
    return (
        <MuiTextField
            variant="filled"
            autoComplete="off"
            InputProps={{ ...defaultInputProps, ...InputProps }}
            InputLabelProps={{ ...defaultInputLabelProps, ...InputLabelProps }}
            {...rest}
        />
    );
};

const StyledTextField = styled(TextField)`
    .MuiFormHelperText-root {
        font-size: ${fontSizes.f14};
        color: ${(props) => props.theme.palette.error.main};
    }

    .MuiInputLabel-root {
        text-transform: uppercase;
        font-weight: ${fontWeights.medium};
        font-size: ${fontSizes.f12};
        color: ${(props) => props.theme.palette.grey[200]};
        &.Mui-focused {
            color: ${(props) => props.theme.palette.grey[200]};
        }
        &.Mui-disabled {
            color: ${(props) => props.theme.palette.grey[600]};
        }
    }

    .MuiFilledInput-root {
        font-size: ${fontSizes.f16};
        background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
            parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
        border: 1px solid
            ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'green' ? theme.palette.primary.contrastText : theme.palette.grey[500]};
        border-radius: 4px;

        &:hover:not(.Mui-focused, .Mui-disabled) {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            border-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'green' ? theme.palette.primary.dark : theme.palette.grey[100]};
        }
        &.Mui-focused {
            background-color: ${({ theme, parentBackgroundColor = 'white' }) =>
                parentBackgroundColor === 'white' ? theme.palette.grey[300] : theme.palette.common.white};
            border-color: ${({ theme }) => theme.palette.primary.main};
        }

        &.Mui-disabled {
            color: ${(props) => props.theme.palette.grey[600]};
            border-color: ${({ theme }) => theme.palette.grey[500]};
        }
    }
`;

export type { TextFieldProps };
export { StyledTextField as TextField };
