import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { map, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';

import {
    SequenceAnalyticsRepliesByTimePeriodQuery,
    SequenceAnalyticsSendsByStageByTimePeriodQuery,
} from 'codegen/graphql';
import { DateSelectorGroupByOption, DateSelectorWithGrouping } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import {
    GET_SEQUENCE_ANALYTICS_REPLIES_BY_TIME_PERIOD,
    GET_SEQUENCE_ANALYTICS_SENDS_BY_STAGE_BY_TIME_PERIOD,
} from 'shared/graphql/sequence-analytics';
import { SequenceData } from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const stagesColors = [
    '#4F71BE',
    '#C88259',
    '#72A9B6',
    '#BE5D52',
    '#8211A9',
    '#8dADDD',
    '#A2A080',
    '#EE3F11',
    '#F5A662',
    '#E5E9B7',
];

interface SequenceAnalyticsProps {
    sequence: SequenceData;
}
export const SequenceAnalytics: FC<SequenceAnalyticsProps> = ({ sequence }) => {
    const theme = useTheme();
    const translate = useTranslations('sequence.analytics');
    const { session } = useSession();

    const [startTime, setStartTime] = useState(DateTime.now().valueOf());
    const [endTime, setEndTime] = useState(DateTime.now().valueOf());
    const [timeUnit, setTimeUnit] = useState<'day' | 'week' | 'month' | 'quarter' | 'century'>('day');

    const { data: sendsByStageByTimeData } = useQuery<SequenceAnalyticsSendsByStageByTimePeriodQuery>(
        GET_SEQUENCE_ANALYTICS_SENDS_BY_STAGE_BY_TIME_PERIOD,
        {
            variables: {
                sequence: sequence.id,
                start_time: startTime,
                end_time: endTime,
                time_unit: timeUnit,
                time_zone: session?.user.primaryTimezone,
            },
        }
    );

    const { data: repliesByTimeData } = useQuery<SequenceAnalyticsRepliesByTimePeriodQuery>(
        GET_SEQUENCE_ANALYTICS_REPLIES_BY_TIME_PERIOD,
        {
            variables: {
                sequence: sequence.id,
                start_time: startTime,
                end_time: endTime,
                time_unit: timeUnit,
                time_zone: session?.user.primaryTimezone,
            },
        }
    );

    const getDaysBetweenDates = (start: number, end: number, tu: string) => {
        const dates = [];
        let s = DateTime.fromMillis(start);
        const e = DateTime.fromMillis(end);

        switch (tu) {
            case 'day':
                while (e.diff(s, 'days').as('days') >= 0) {
                    dates.push(s.toFormat('yyyy-MM-dd'));
                    s = s.plus({ days: 1 });
                }
                break;
            case 'week':
                s = s.startOf('week');
                while (e.diff(s, 'weeks').as('weeks') >= 0) {
                    dates.push(s.toFormat('yyyy-MM-dd'));
                    s = s.plus({ weeks: 1 });
                }
                break;
            case 'month':
                s = s.startOf('month');
                while (e.diff(s, 'months').as('months') >= 0) {
                    dates.push(s.toFormat('yyyy-MM-dd'));
                    s = s.plus({ months: 1 });
                }
                break;
            case 'quarter':
                s = s.startOf('quarter');
                while (e.diff(s, 'quarters').as('quarters') >= 0) {
                    dates.push(s.toFormat('yyyy-MM-dd'));
                    s = s.plus({ quarters: 1 });
                }
                break;
            default:
                break;
        }

        return dates;
    };

    const timeUnitTranslation = (date: string) =>
        timeUnit === 'day'
            ? DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MMM, dd')
            : timeUnit === 'week'
            ? `Week ${DateTime.fromFormat(date, 'yyyy-MM-dd').weekNumber}`
            : timeUnit === 'month'
            ? DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MMMM')
            : timeUnit === 'quarter'
            ? `Q${DateTime.fromFormat(date, 'yyyy-MM-dd').quarter}`
            : translate('all-time');

    const handleFromDateChange = (date: DateTime) => {
        const newDate = date.setZone(session?.user.primaryTimezone);
        setStartTime(newDate.valueOf());
    };

    const handleToDateChange = (date: DateTime) => {
        const newDate = date.setZone(session?.user.primaryTimezone);
        setEndTime(newDate.valueOf());
    };

    const handleTimeUnitChange = (unit: DateSelectorGroupByOption) => {
        if (unit === 'all-time') setTimeUnit('century');
        else setTimeUnit(unit);
    };
    const allDatesForSendsByStageMetrics: string[] =
        timeUnit === 'century'
            ? uniq(sendsByStageByTimeData ? sendsByStageByTimeData.stats!.map((e) => e.date) : [])
            : getDaysBetweenDates(startTime, endTime, timeUnit);

    const uniqueStages = uniq(sendsByStageByTimeData ? sendsByStageByTimeData.stats!.map((s) => s.stageIndex) : []);

    const sendsByStageData: { [stageIndex: number]: number[] } = {};

    for (const d of allDatesForSendsByStageMetrics) {
        for (const stageIndex of uniqueStages) {
            const e = sendsByStageData[stageIndex] || [];

            const data = sendsByStageByTimeData?.stats.find((b) => b.date === d && b.stageIndex === stageIndex);
            if (data) {
                e.push(data.sends);
            } else {
                e.push(0);
            }
            sendsByStageData[stageIndex] = e;
        }
    }

    const sendsByStageChartDataSets = map(sendsByStageData, (v, k) => ({
        label: translate('stage', { index: Number(k) + 1 }),
        data: v,
        stack: 'Stack 0',
        backgroundColor: stagesColors[Number(k)],
    }));

    const sendsByStageChartOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Sends by stage of Sequence recipients',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const sendsByStageChartData = {
        labels: allDatesForSendsByStageMetrics.map(timeUnitTranslation),
        datasets: sendsByStageChartDataSets,
    };

    const allDatesForRepliesMetrics: string[] =
        timeUnit === 'century'
            ? uniq(repliesByTimeData ? repliesByTimeData.stats!.map((e) => e.date) : [])
            : getDaysBetweenDates(startTime, endTime, timeUnit);

    const processedRepliesMetricsByTimeData = allDatesForRepliesMetrics.map((d) => {
        const data = repliesByTimeData?.stats.find((e) => e.date === d);

        if (data) return data;

        return {
            date: d,
            replies: 0,
            interested: 0,
        };
    });

    const allOtherRepliesData = processedRepliesMetricsByTimeData.map((e) => e.replies);
    const interestedRepliesData = processedRepliesMetricsByTimeData.map((e) => e.interested);

    const repliesChartOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Replies for the sequence',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    const repliesChartData = {
        labels: allDatesForRepliesMetrics.map(timeUnitTranslation),
        datasets: [
            {
                label: translate('all-other-replies'),
                data: allOtherRepliesData,
                stack: 'Stack 0',
                backgroundColor: '#4F71BE',
            },
            {
                label: translate('interested-replies'),
                data: interestedRepliesData,
                stack: 'Stack 0',
                backgroundColor: '#C88259',
            },
        ],
    };

    return (
        <Box
            css={css`
                max-width: ${appMaxWidth};

                @media (max-width: 1000px) {
                    max-width: ${appMaxSmallWidth};
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            `}
        >
            <Box
                css={css`
                    background-color: ${colors.greens.narvik30};
                    border: 1px solid ${colors.grays.gallery};
                    border-radius: ${spacing.space8px};
                    padding: ${spacing.space4px};
                    gap: ${spacing.space16px};
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    overflow-x: scroll;
                `}
            >
                <DateSelectorWithGrouping
                    allTimeStartDate={DateTime.fromMillis(sequence!.createdAt).setZone(session!.user.primaryTimezone)}
                    onChangeFromDate={handleFromDateChange}
                    onChangeToDate={handleToDateChange}
                    onChangeGroupBy={handleTimeUnitChange}
                />
            </Box>

            {/* charts below */}
            <Box
                css={css`
                    margin-top: ${spacing.space24px};
                    margin-bottom: ${spacing.space24px};

                    background-color: ${theme.palette.common.white};
                    border-radius: 4px;
                    height: 717px;
                    width: 100%;
                    padding: ${spacing.space24px};
                    row-gap: ${spacing.space16px};
                `}
            >
                <Bar options={sendsByStageChartOptions} data={sendsByStageChartData} />
            </Box>
            <Box
                css={css`
                    background-color: ${theme.palette.common.white};
                    border-radius: 4px;
                    height: 717px;
                    width: 100%;
                    padding: ${spacing.space24px};
                    row-gap: ${spacing.space16px};
                `}
            >
                <Bar options={repliesChartOptions} data={repliesChartData} />
            </Box>
        </Box>
    );
};
