import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { LoadingButton } from 'shared/components/presentational';
import { CheckCircle, MinusCircle } from 'shared/components/svgs';
import { spacing } from 'shared/settings/spacings';
import { FC } from 'shared/types';

interface ConfirmAddToSequenceProps {
    validProspectsCount: number;
    invalidProspectsCount: number;
    sequenceTitle: string;
    onCreateRecipients: () => void;
    loading: boolean;
}

const ConfirmAddToSequence: FC<ConfirmAddToSequenceProps> = ({
    validProspectsCount,
    invalidProspectsCount,
    sequenceTitle,
    onCreateRecipients,
    loading,
}) => {
    const theme = useTheme();
    const translate = useTranslations(
        'project.prospect-list-table.edit-bar.add-to-sequence-modal.confirm-add-to-sequence'
    );
    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                gap: ${spacing.space12px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    padding: ${spacing.space8px} ${spacing.none};
                    gap: ${spacing.space16px};
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space12px};
                    `}
                >
                    <CheckCircle width={20} height={20} />
                    <Box component="span">
                        {translate.rich('valid-prospects-message', {
                            count: validProspectsCount,
                            sequenceTitle,
                            bold: Bold,
                        })}
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        gap: ${spacing.space12px};
                    `}
                >
                    <MinusCircle width={20} height={20} stroke={theme.palette.grey[600]} />
                    <Box component="span">
                        {translate('invalid-prospects-message', { count: invalidProspectsCount })}
                    </Box>
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <LoadingButton
                    css={css`
                        padding: ${spacing.space10px} ${spacing.space16px};
                    `}
                    variant="contained"
                    onClick={onCreateRecipients}
                    loading={loading}
                >
                    {translate('button-label', { count: validProspectsCount })}
                </LoadingButton>
            </Box>
        </Box>
    );
};

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);

export { ConfirmAddToSequence };
