import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ChangeApiTokenSupport } from 'integrations/components/templates';
import { atsTypeAshby } from 'integrations/constants';
import { CreateEditFormValues } from 'integrations/types/ashby';
import { Box } from 'shared/components/containers';
import { TextField } from 'shared/components/form';
import { SliderObfuscation, Title } from 'shared/components/presentational';
import { createRestApiClient } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ConnectEditTemplateProps {
    formMethods: UseFormReturn<CreateEditFormValues>;
    isEditing?: boolean;
}

const ConnectEditTemplate: FC<ConnectEditTemplateProps & any> = (props) => {
    const { formMethods, isEditing = false } = props;

    const translate = useTranslations('integrations');

    const [decryptedToken, setDecryptedToken] = useState<string>('');

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') event.preventDefault();
    };

    const decryptToken = async () => {
        const restApiClient = createRestApiClient();
        const response = await restApiClient.decryptApiToken({ atsType: atsTypeAshby });
        setDecryptedToken(response?.data?.decryptedToken ?? '');
    };

    useEffect(() => {
        decryptToken();
    }, []);

    return (
        <>
            <TextField
                type={isEditing ? 'password' : 'text'}
                disabled={isEditing}
                css={css`
                    width: 100%;
                `}
                parentBackgroundColor="green"
                control={formMethods.control}
                name="apiToken"
                label={translate('ashby.api-token')}
                onKeyDown={keyDownHandler}
            />
            {isEditing ? <ChangeApiTokenSupport /> : null}
            {isEditing ? (
                <Box
                    css={css`
                        flex-grow: 1;
                        margin: ${spacing.space48px} 0px;
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            font-size: ${fontSizes.f20};
                        `}
                    >
                        {translate('slide-to-view-token')}
                    </Title>
                    <SliderObfuscation text={decryptedToken} />
                </Box>
            ) : null}
        </>
    );
};

export { ConnectEditTemplate };
