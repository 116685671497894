import { useApolloClient, useLazyQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import {
    SourcerMemberByIdQuery,
    SourcerMemberByIdQueryVariables,
    SourcerMemberStatusEnum,
    UserProjectsQuery,
    UserProjectsQueryVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Hyperlink, Popover, SubmenuButton } from 'shared/components/presentational';
import { OUT_OF_AI_SOURCING_CREDITS } from 'shared/constants';
import { AppFlow, SourcerMemberApprovalAction } from 'shared/enums';
import { GET_USER_PROJECTS, UserProjectCollaboratorType, userProjectsDefaultVariables } from 'shared/graphql/projects';
import {
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    GET_SOURCER_MEMBER_BY_ID,
    SourcerMemberData,
} from 'shared/graphql/sourcer-members';
import { useCreateProjectMembers, useProspectCreator, useSession, useSnackbarAlert } from 'shared/hooks';
import { useAiSourcingCredits } from 'shared/hooks/use-ai-sourcing-credits';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { AddMultipleSourcerMembersToProject, ProspectAlreadyReviewedModal } from 'sourcing/components/modals';

interface SourcerMemberCardSubmenuProps {
    creditsPerApproval: number;
    sourcerMember: SourcerMemberData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
}

const SourcerMemberCardSubmenu: FC<SourcerMemberCardSubmenuProps> = ({
    sourcerMember,
    anchor,
    setAnchor,
    creditsPerApproval,
}) => {
    const translate = useTranslations('sourcing');
    const { showSnackbarAlert } = useSnackbarAlert();
    const { session } = useSession();
    const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();
    const apolloClient = useApolloClient();

    const [getProjects] = useLazyQuery<UserProjectsQuery, UserProjectsQueryVariables>(GET_USER_PROJECTS);
    const [getSourcerMemberById, { data: sourcerMemberByIdData }] = useLazyQuery<
        SourcerMemberByIdQuery,
        SourcerMemberByIdQueryVariables
    >(GET_SOURCER_MEMBER_BY_ID, {
        variables: {
            id: sourcerMember.id,
        },
        fetchPolicy: 'network-only',
    });
    const { createProspectsFromSourcerMembers } = useProspectCreator();

    const { createAndTrackProjectMembers } = useCreateProjectMembers([]);

    const refetchQueries = [
        getOperationName(GET_SOURCER_MEMBERS) as string,
        getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
    ];
    const { approveAndTrackSourcerMembers } = useApproveSourcerMembers(refetchQueries);

    const handleSubmenuPopoverClose = () => setAnchor(undefined);
    const [openAddSourcerMembersToProject, setOpenAddSourcerMembersToProject] = useState<boolean>(false);
    const [openProspectAlreadyReviewedModal, setOpenProspectAlreadyReviewedModal] = useState<boolean>(false);

    const checkCreditsAvailable = async () => {
        const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
            getAiSourcingCreditsAvailable(),
            isPayOnApproveEnabled(),
        ]);

        if (shouldDeductCreditOnApprove) {
            return aiSourcingCreditsAvailable >= creditsPerApproval;
        }
        return true;
    };

    const handleAddToProjectClose = () => {
        setOpenAddSourcerMembersToProject(false);
        handleSubmenuPopoverClose();
    };

    const handleSendToDifferentProject = async () => {
        if (await checkCreditsAvailable()) {
            setOpenAddSourcerMembersToProject(true);
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate.rich('add-multiple-sourcer-members-to-project-modal.no-ai-sourcing-credits-left', {
                    link: Link,
                }),
            });
        }
    };
    const handleSaveToWaitingRoom = async () => {
        const isSourcerMemberStillInReview = await checkIfSourcerMemberIsStillInReview();

        if (!isSourcerMemberStillInReview) {
            setOpenProspectAlreadyReviewedModal(true);
            return;
        }

        const { data: projectsData } = await getProjects({
            variables: {
                ...userProjectsDefaultVariables,
                where: {
                    _and: [
                        { collaboratorType: { _eq: UserProjectCollaboratorType.Owner } },
                        { project: { defaultUserProject: { _eq: true } } },
                    ],
                },
            },
        });
        const waitingRoom = projectsData?.user_projects[0]?.project;

        if (waitingRoom) await addSourcerMemberToProject(waitingRoom);
    };
    const addSourcerMemberToProject = async (project: { id: string; title: string }) => {
        if (await checkCreditsAvailable()) {
            try {
                const sourcerMembersWithProspects = await createProspectsFromSourcerMembers([sourcerMember]);

                if (
                    sourcerMembersWithProspects &&
                    sourcerMembersWithProspects[0] &&
                    sourcerMembersWithProspects[0].prospect
                ) {
                    const { prospect } = sourcerMembersWithProspects[0];
                    await createAndTrackProjectMembers(
                        [{ projectId: project.id, prospectId: prospect.id }],
                        AppFlow.AiSourcing
                    );

                    showSnackbarAlert({
                        severity: 'success',
                        message: translate('add-multiple-sourcer-members-to-project-modal.add-success-alert', {
                            numberOfProspects: 1,
                            projectTitle: project.title,
                        }),
                    });

                    await approveAndTrackSourcerMembers({
                        sourcerMemberIds: [sourcerMember.id],
                        flow: SourcerMemberApprovalAction.KebabApprove,
                        reviewerId: session?.user.id,
                    });

                    handleSubmenuPopoverClose();
                }
            } catch (error) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('add-multiple-sourcer-members-to-project-modal.add-error-alert'),
                });
            }
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate.rich('add-multiple-sourcer-members-to-project-modal.no-ai-sourcing-credits-left', {
                    link: Link,
                }),
            });
        }
    };

    const checkIfSourcerMemberIsStillInReview = async () => {
        const { data } = await getSourcerMemberById();
        return data?.sourcerMember?.status === SourcerMemberStatusEnum.ToReview;
    };

    const handleProspectAlreadyAddedModalClose = () => {
        apolloClient.refetchQueries({ include: refetchQueries });
        setOpenProspectAlreadyReviewedModal(false);
        handleSubmenuPopoverClose();
    };
    const upToDateSourcerMember = sourcerMemberByIdData?.sourcerMember;
    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleSubmenuPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    <SubmenuButton onClick={handleSendToDifferentProject}>
                        {translate('sourcer-member-card.submenu.send-to-a-different-project')}
                    </SubmenuButton>
                    <SubmenuButton onClick={handleSaveToWaitingRoom}>
                        {translate('sourcer-member-card.submenu.save-prospect-to-waiting-room')}
                    </SubmenuButton>
                </Box>
            </Popover>
            <AddMultipleSourcerMembersToProject
                creditsPerApproval={creditsPerApproval}
                open={openAddSourcerMembersToProject}
                onClose={handleAddToProjectClose}
                sourcerMembers={[sourcerMember]}
                sourcerProjectId={sourcerMember.sourcer.project.id}
                flow={SourcerMemberApprovalAction.KebabApprove}
                checkIfSourcerMemberIsStillInReview
            />
            {upToDateSourcerMember && (
                <ProspectAlreadyReviewedModal
                    open={openProspectAlreadyReviewedModal}
                    onClose={handleProspectAlreadyAddedModalClose}
                    sourcerMember={upToDateSourcerMember}
                />
            )}
        </>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink href={OUT_OF_AI_SOURCING_CREDITS} newTab>
        <u>{children}</u>
    </Hyperlink>
);

export { SourcerMemberCardSubmenu };
