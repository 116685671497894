import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import { SourcersQuery, SourcerStatusEnum, UpdateSourcerByIdMutation } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import {
    GET_SOURCER_BY_ID,
    GET_SOURCERS,
    GET_SOURCERS_COUNT_BY_STATUS,
    UPDATE_SOURCER_BY_ID,
} from 'shared/graphql/sourcers';
import { useConfirmationModal, useListSubmenuTab, useSnackbarAlert, useSourcerAccessControl } from 'shared/hooks';
import { appRoutes, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ArchiveSourcerModal, PauseSourcerModal } from 'sourcing/components/modals';

type SourcerData = SourcersQuery['sourcers'][number];
interface SourcerListSubmenuProps {
    sourcer: SourcerData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
}
const SourcerListSubmenu: FC<SourcerListSubmenuProps> = ({ sourcer, anchor, setAnchor }) => {
    const translate = useTranslations('sourcing.sourcing-list-table.submenu');
    const router = useRouter();
    const { setCurrentTab } = useListSubmenuTab();
    const { canArchiveSourcer, canPauseSourcer } = useSourcerAccessControl();
    const [openPauseSourcerModal, setOpenPauseSourcerModal] = useState(false);
    const [openArchiveSourcerModal, setOpenArchiveSourcerModal] = useState(false);

    const { showModal, hideModal } = useConfirmationModal();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateSourcer] = useMutation<UpdateSourcerByIdMutation>(UPDATE_SOURCER_BY_ID, {
        refetchQueries: [
            getOperationName(GET_SOURCER_BY_ID) as string,
            getOperationName(GET_SOURCERS) as string,
            getOperationName(GET_SOURCERS_COUNT_BY_STATUS) as string,
        ],
    });

    const handleSubmenuPopoverClose = () => setAnchor(undefined);

    const handleOpenPauseSourcerModal = () => {
        setOpenPauseSourcerModal(true);
    };

    const handleClosePauseSourcerModal = () => {
        setOpenPauseSourcerModal(false);
        handleSubmenuPopoverClose();
    };

    const handleOpenArchiveSourcerModal = () => {
        setOpenArchiveSourcerModal(true);
    };

    const handleCloseArchiveSourcerModal = () => {
        setOpenArchiveSourcerModal(false);
        handleSubmenuPopoverClose();
    };

    const handleResumeSourcer = async () => {
        await updateSourcer({ variables: { id: sourcer.id, set: { status: SourcerStatusEnum.Active } } });
        showSnackbarAlert({
            severity: 'success',
            message: translate('sourcer-is-now-active', { title: sourcer.title }),
        });

        hideModal();
        trackEvent('click_resume', { sourcer_id: sourcer.id, value: 'sourcer' });
    };
    const handleResumeSourcerConfirm = () => {
        handleSubmenuPopoverClose();
        showModal({
            title: translate('resume-modal-header', { title: sourcer.title }),
            description: translate('resume-modal-description'),
            confirmButton: {
                text: translate('resume-modal-resume-now-button-label'),
                onClick: handleResumeSourcer,
            },
            cancelButton: {
                text: translate('modal-cancel-button-label'),
                onClick: hideModal,
            },
        });
    };

    const handleEditSourcer = () => {
        const pathname = appRoutes.sourcing.index(sourcer.id);
        if (pathname === router.asPath) {
            // eslint-disable-next-line no-magic-numbers
            setCurrentTab(4);
            handleSubmenuPopoverClose();
        } else {
            router.push({ pathname: appRoutes.sourcing.index(sourcer.id), query: { tab: 'edit' } });
        }
    };

    const handleUnArchiveSourcer = async () => {
        handleSubmenuPopoverClose();
        try {
            await updateSourcer({
                variables: { id: sourcer.id, set: { status: SourcerStatusEnum.Active } },
            });

            showSnackbarAlert({
                severity: 'success',
                message: translate('sourcer-is-now-active', { title: sourcer.title }),
            });
        } catch (error) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('sourcer-un-archive-error', { title: sourcer.title }),
            });
        }
    };

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleSubmenuPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    {sourcer.status === SourcerStatusEnum.Draft ? (
                        <>
                            <SubmenuButton onClick={handleEditSourcer}>{translate('edit-sourcer-label')}</SubmenuButton>
                            <SubmenuButton
                                disabled={!canArchiveSourcer(sourcer)}
                                onClick={handleOpenArchiveSourcerModal}
                            >
                                {translate('archive-label')}
                            </SubmenuButton>
                        </>
                    ) : sourcer.status === SourcerStatusEnum.Active ? (
                        <>
                            <SubmenuButton onClick={handleEditSourcer}>{translate('edit-sourcer-label')}</SubmenuButton>
                            <SubmenuButton disabled={!canPauseSourcer(sourcer)} onClick={handleOpenPauseSourcerModal}>
                                {translate('pause-label')}
                            </SubmenuButton>
                            <SubmenuButton
                                disabled={!canArchiveSourcer(sourcer)}
                                onClick={handleOpenArchiveSourcerModal}
                            >
                                {translate('archive-label')}
                            </SubmenuButton>
                        </>
                    ) : sourcer.status === SourcerStatusEnum.Paused ? (
                        <>
                            <SubmenuButton onClick={handleEditSourcer}>{translate('edit-sourcer-label')}</SubmenuButton>
                            <SubmenuButton disabled={!canPauseSourcer(sourcer)} onClick={handleResumeSourcerConfirm}>
                                {translate('resume-label')}
                            </SubmenuButton>
                            <SubmenuButton
                                disabled={!canArchiveSourcer(sourcer)}
                                onClick={handleOpenArchiveSourcerModal}
                            >
                                {translate('archive-label')}
                            </SubmenuButton>
                        </>
                    ) : sourcer.status === SourcerStatusEnum.Archived ? (
                        <SubmenuButton disabled={!canArchiveSourcer(sourcer)} onClick={handleUnArchiveSourcer}>
                            {translate('un-archive-label')}
                        </SubmenuButton>
                    ) : null}
                </Box>
            </Popover>
            <PauseSourcerModal open={openPauseSourcerModal} onClose={handleClosePauseSourcerModal} sourcer={sourcer} />
            <ArchiveSourcerModal
                open={openArchiveSourcerModal}
                onClose={handleCloseArchiveSourcerModal}
                sourcer={sourcer}
            />
        </>
    );
};

export { SourcerListSubmenu };
