import { getDraftingVariables } from 'hireflow-shared/utils/email-drafting-utils';

import { SequenceMessagesInQueue } from 'prospects/prospect-sequence-messages-in-queue';
import { Box } from 'shared/components/containers';
import { useProspectPanel } from 'shared/hooks';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface MessagesInQueueProps {
    anchor: HTMLElement;
    recipientsInQueue: RecipientConversationsData[];
}

const MessagesInQueue: FC<MessagesInQueueProps> = (props) => {
    const { anchor, recipientsInQueue } = props;
    const { prospectInfo, sequencesData } = useProspectPanel();
    const profile = prospectInfo?.profile;

    const sequenceStageView = profile
        ? recipientsInQueue.map((r) => {
              const stages = sequencesData?.filter((f) => f.id === r.sequence.id)[0]?.stages ?? [];
              const SelectedSequenceCustomMessage = prospectInfo?.customMessages?.find(
                  (customMessageObject) => customMessageObject.sequenceId === r.sequence.id
              );
              const customMessage = SelectedSequenceCustomMessage?.customMessage;
              const variables = stages.map((s) =>
                  getDraftingVariables(profile, s.sendFromAccount?.email_account.user, customMessage)
              );

              return (
                  <Box key={`recipient-${r.id}`}>
                      <SequenceMessagesInQueue anchor={anchor} recipient={r} variables={variables} />
                  </Box>
              );
          })
        : null;

    return <Box>{sequenceStageView}</Box>;
};

export { MessagesInQueue };
