import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { showPopupQueryParam } from 'shared/constants';
import { FC } from 'shared/types';

interface SettingsContextProps {
    showMenu: boolean;
    openMenu: () => void;
    closeMenu: () => void;
    toggleMenu: () => void;
    activeSetting: MenuItem | undefined;
    showPopover: boolean;
    openPopover: (menuItem: MenuItem) => void;
    closePopover: () => void;
    closeAll: () => void;
    scrollToHireflowSignature: boolean;
    openHireflowAccount: () => void;
    openAtsIntegrations: () => void;
    openHireflowSignature: () => void;
    scrollToEmailHours: boolean;
    openEmailHours: () => void;
}

const menuItems = ['account-and-emails', 'ats-integrations', 'plans-and-billing', 'team'] as const;
type MenuItem = typeof menuItems[number];

const SettingsContext = createContext<SettingsContextProps>({} as SettingsContextProps);

const SettingsProvider: FC<unknown> = (props) => {
    const { children } = props;
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const [activeSetting, setActiveSetting] = useState<MenuItem>();
    const [showPopover, setShowPopover] = useState(false);
    const [scrollToHireflowSignature, setScrollToHireflowSignature] = useState(false);
    const [scrollToEmailHours, setScrollToEmailHours] = useState(false);
    /**
     * if the showPopupQueryParam exists in the uri query params,
     * then open the settings popover
     * the query param is then removed from the uri to prevent re-occurrences
     */
    useEffect(() => {
        if (!router.query[showPopupQueryParam]) return;

        openHireflowAccount();

        if (showPopupQueryParam in router.query) {
            // eslint-disable-next-line no-param-reassign
            delete router.query[showPopupQueryParam];
            router.replace({ pathname: router.pathname, query: router.query }, undefined, { shallow: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    const openMenu = () => {
        setShowMenu(true);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const toggleMenu = useCallback(() => {
        setShowMenu(!showMenu);
    }, [showMenu]);

    const openPopover = useCallback(
        (menuItem: MenuItem, shouldScrollToHireflowSignature = false, shouldScrollToEmailHours = false) => {
            setShowMenu(true);
            setActiveSetting(menuItem);
            setShowPopover(true);
            setScrollToHireflowSignature(shouldScrollToHireflowSignature);
            setScrollToEmailHours(shouldScrollToEmailHours);
        },
        []
    );

    const closePopover = () => {
        setShowPopover(false);
        setActiveSetting(undefined);
    };

    const closeAll = useCallback(() => {
        closePopover();
        closeMenu();
    }, []);

    const openHireflowAccount = useCallback(() => {
        openPopover('account-and-emails', false, false);
    }, [openPopover]);

    const openAtsIntegrations = useCallback(() => {
        openPopover('ats-integrations', true, false);
    }, [openPopover]);

    const openHireflowSignature = useCallback(() => {
        openPopover('account-and-emails', true, false);
    }, [openPopover]);

    const openEmailHours = useCallback(() => {
        openPopover('account-and-emails', false, true);
    }, [openPopover]);

    const value = useMemo(
        () => ({
            showMenu,
            openMenu,
            closeMenu,
            toggleMenu,
            activeSetting,
            showPopover,
            openPopover,
            closePopover,
            closeAll,
            scrollToHireflowSignature,
            setScrollToHireflowSignature,
            openHireflowAccount,
            openAtsIntegrations,
            openHireflowSignature,
            scrollToEmailHours,
            setScrollToEmailHours,
            openEmailHours,
        }),
        [
            showMenu,
            toggleMenu,
            activeSetting,
            showPopover,
            openPopover,
            closeAll,
            scrollToHireflowSignature,
            openHireflowAccount,
            openAtsIntegrations,
            openHireflowSignature,
            scrollToEmailHours,
            openEmailHours,
        ]
    );
    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

const useSettings = () => {
    const context = useContext(SettingsContext);
    return { ...context };
};

export { useSettings, SettingsProvider };
