import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse } from '@mui/material';
import { DraftResponses } from 'hireflow-shared/types/user';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { SignatureOptionsEnum, UpdateUserMutation } from 'codegen/graphql';
import { ComposeCompactEmail } from 'sequences/components/form';
import { emailTemplates } from 'sequences/settings';
import { Stage } from 'sequences/types';
import { EditableSettingsSection } from 'settings/components/containers';
import { Box } from 'shared/components/containers';
import { Switch } from 'shared/components/form';
import { Title } from 'shared/components/presentational';
import { GET_USER_BY_ID, UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const defaultNumVariablesToShow = 3;

interface ComposeEmailForm {
    stages?: Stage[];
}

interface ComposeDraftResponseProps {
    title: string;
    draftResponsesSettings?: DraftResponses;
    response: keyof Required<DraftResponses>;
}

const ComposeDraftResponse: FC<ComposeDraftResponseProps> = ({ title, draftResponsesSettings, response }) => {
    const translate = useTranslations('settings.email-settings.queue-draft-folder-response');
    const { session, setSession } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [isEditing, setIsEditing] = useState(false);

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER, {
        refetchQueries: [getOperationName(GET_USER_BY_ID) as string],
    });
    const responseEnabled = Boolean(draftResponsesSettings?.[response]?.enabled);

    const validationSchema = Yup.object().shape({
        stages: Yup.array().of(
            Yup.object().shape({
                sendFromAccountId: Yup.string().required(translate('error-send-from')),
                subject: Yup.string().required(translate('error-subject')),
                content: Yup.string().required(translate('error-email-content')),
            })
        ),
    });

    const formInitialValues = draftResponsesSettings?.[response]?.message ?? {
        stages: [
            {
                bcc: [],
                cc: [],
                sendFromAccountId: undefined,
                subject: "{{First Name}}, let's connect?",
                signature: SignatureOptionsEnum.ImportFromGmail,
                content: emailTemplates.blank,
            },
        ],
    };

    const formMethods = useForm<ComposeEmailForm>({
        defaultValues: useMemo(() => {
            if (draftResponsesSettings?.[response]?.message) {
                return draftResponsesSettings?.[response]?.message;
            }
            return formInitialValues;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [draftResponsesSettings]),
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),
    });

    const setToDatabaseDefault = (): void => {
        if (draftResponsesSettings?.[response]?.message) {
            formMethods.reset(draftResponsesSettings?.[response]?.message);
        }
    };

    useEffect(() => {
        setToDatabaseDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftResponsesSettings]);

    const handleSave = async () => {
        const values = formMethods.getValues();

        await updateUser({
            variables: {
                id: session!.user.id,
                set: {
                    draftResponses: {
                        ...(draftResponsesSettings ?? {}),
                        [response]: {
                            ...(draftResponsesSettings?.[response] ?? {}),
                            message: values,
                        },
                    },
                },
            },
        });

        setSession({
            user: {
                ...session!.user,
                draftResponses: {
                    ...(draftResponsesSettings ?? {}),
                    [response]: {
                        ...(draftResponsesSettings?.[response] ?? {}),
                        message: values,
                    },
                },
            },
        });

        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
        setToDatabaseDefault();
    };

    const handleToggleResponseEnabled = async () => {
        await updateUser({
            variables: {
                id: session!.user.id,
                set: {
                    draftResponses: {
                        ...(draftResponsesSettings ?? {}),
                        [response]: {
                            ...(draftResponsesSettings?.[response] ?? {}),
                            enabled: !responseEnabled,
                        },
                    },
                },
            },
        });

        setSession({
            user: {
                ...session!.user,
                draftResponses: {
                    ...(draftResponsesSettings ?? {}),
                    [response]: {
                        ...(draftResponsesSettings?.[response] ?? {}),
                        enabled: !responseEnabled,
                    },
                },
            },
        });

        setIsEditing(false);
        setToDatabaseDefault();
    };

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                row-gap: ${spacing.space16px};
            `}
        >
            <Box
                css={css`
                    margin-top: ${spacing.space16px};
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            flex-grow: 1;
                            font-size: ${fontSizes.f20};
                            margin: ${spacing.none};
                        `}
                    >
                        {title}
                    </Title>
                    <Switch
                        checked={responseEnabled}
                        onChange={handleToggleResponseEnabled}
                        label={translate(responseEnabled ? 'switch-label-on' : 'switch-label-off')}
                        css={css`
                            margin-left: 0;
                        `}
                    />
                </Box>
                <Collapse in={responseEnabled}>
                    <EditableSettingsSection
                        isEditing={isEditing}
                        onToggleEdit={handleToggleEdit}
                        editButtonLabel={translate('edit-button-label')}
                        onSave={handleSave}
                    >
                        {formMethods.getValues('stages') && (
                            <ComposeCompactEmail
                                formMethods={formMethods}
                                index={0}
                                numVariablesToShow={defaultNumVariablesToShow}
                                disabled={!isEditing}
                            />
                        )}
                    </EditableSettingsSection>
                </Collapse>
            </Box>
        </Box>
    );
};

export { ComposeDraftResponse };
