import { SourcerCollaboratorTypeEnum, SourcerMemberStatusEnum } from 'codegen/graphql';
import { FC } from 'shared/types';
import { NoProspectsNotice } from 'sourcing/components/presentational';
import { SourcerMembersListTable } from 'sourcing/components/tables';

interface AddedToProjectProps {
    creditsPerApproval: number;
    sourcer: {
        id: string;
        userId: string;
        project: { id: string };
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    };
    count: number;
}

const AddedToProject: FC<AddedToProjectProps> = ({ creditsPerApproval, sourcer, count }) =>
    count > 0 ? (
        <SourcerMembersListTable
            status={SourcerMemberStatusEnum.Accepted}
            sourcer={sourcer}
            creditsPerApproval={creditsPerApproval}
        />
    ) : (
        <NoProspectsNotice />
    );

export { AddedToProject };
