import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface IntegrationInfoCardProps {
    name: string; // the name of the integration
    translation: string; // button label
    href: string; // button href
}

/**
 * this component will be used to display the existing/possible integrations
 * available to the user.
 */
const IntegrationInfoCard: FC<IntegrationInfoCardProps> = (props) => {
    const { name, translation, href } = props;
    const theme = useTheme();

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: ${spacing.space16px};
                background-color: ${theme.palette.common.white};
                border-radius: 4px;
                border: 1px solid ${theme.palette.grey[500]};
                width: 300px;
                height: 200px;
            `}
        >
            {name}
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <Button
                    variant="outlined"
                    href={href}
                    css={css`
                        display: block;
                        margin: 0 auto;
                    `}
                >
                    {translation}
                </Button>
            </Box>
        </Box>
    );
};

export { IntegrationInfoCard };
