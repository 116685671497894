import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { formatEmail } from 'hireflow-shared/lib/emails/email-utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import {
    ContactChannelsEnum,
    ContactKindsEnum,
    RecipientContactUpdateForResendSequenceMutation,
    RecipientStatusEnum,
    UpdateRecipientsMutation,
} from 'codegen/graphql';
import { ChooseSequenceModal, ResendEmailNoEmail, ResendSequenceMultipleEmails } from 'sequences/components/modals/';
import { Contacts, RECIPIENT_CONTACT_UPDATE_FOR_RESEND_SEQUENCE } from 'shared/graphql/contacts';
import { GET_PROJECT_MEMBERS_BY_PROJECT } from 'shared/graphql/project-members';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { GET_RECIPIENTS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { useSnackbarAlert } from 'shared/hooks';
import { isEmailValid } from 'shared/services';
import { FC } from 'shared/types';

interface RecipientDetailsForResendEmailModal {
    id: string;
    status: RecipientStatusEnum;
    sequence: { id: string; title: string };
    contacts: Array<{
        contactId: string;
    }>;
    prospect: {
        id: string;
        profile?: {
            fullName: string;
        } | null;
        contacts?: Array<{
            id: string;
            kind: string;
            channel: string;
            value: string;
            invalid?: boolean | null;
            primary?: boolean | null;
        }> | null;
    };
}

interface ResendSequenceNewEmailModalProps {
    recipients: RecipientDetailsForResendEmailModal[];
    open: boolean;
    onClose: () => void;
}

const ResendSequenceNewEmailModal: FC<ResendSequenceNewEmailModalProps> = (props) => {
    const { recipients, onClose, open } = props;
    const translate = useTranslations('sequence.recipient-list-table.resend-sequence-new-email-modal');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [selectedRecipient, setSelectedRecipient] = useState<RecipientDetailsForResendEmailModal | undefined>(
        recipients.length === 1 ? recipients[0] : undefined
    );

    const [selectedSequence, setSelectedSequence] = useState<string | undefined>();
    const [updateRecipientEmail] = useMutation<RecipientContactUpdateForResendSequenceMutation>(
        RECIPIENT_CONTACT_UPDATE_FOR_RESEND_SEQUENCE,
        {
            refetchQueries: [
                getOperationName(GET_RECIPIENTS) as string,
                getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
                getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            ],
        }
    );

    const [updateRecipients] = useMutation<UpdateRecipientsMutation>(UPDATE_RECIPIENTS, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_PROJECT_MEMBERS_BY_PROJECT) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    const availableEmails = selectedRecipient?.prospect.contacts!.filter(
        (f) => f.id !== selectedRecipient.contacts[0]?.contactId && f.channel !== ContactChannelsEnum.Phone
    );

    const handleClose = () => {
        setSelectedRecipient(recipients.length === 1 ? recipients[0] : undefined);
        setSelectedSequence(undefined);
        onClose();
    };

    const handleConfirmSequence = () => {
        setSelectedRecipient(recipients.find((recipient) => recipient.sequence.id === selectedSequence));
    };

    const handleSequenceChange = (value: string) => {
        setSelectedSequence(value);
    };

    const handleSendSequence = async (email: string) => {
        if (!selectedRecipient) {
            return;
        }

        if (email.length === 0 || !isEmailValid(email)) {
            return;
        }

        await updateRecipients({
            variables: {
                where: {
                    prospectId: { _eq: selectedRecipient.prospect.id },
                    sequenceId: { _eq: selectedRecipient.sequence.id },
                },
                set: {
                    status: RecipientStatusEnum.NotStarted,
                    lastStageSent: 0,
                    lastStageSentAt: null,
                    nextMessageScheduledAt: null,
                },
            },
        });

        await updateRecipientEmail({
            variables: {
                recipientId: selectedRecipient.id,
                prospectId: selectedRecipient.prospect.id,
                contactId: selectedRecipient.contacts[0]?.contactId ?? '',
                value: formatEmail(email),
                kind:
                    selectedRecipient.prospect.contacts!.find((f) => f.value === email)?.kind ??
                    ContactKindsEnum.Unknown,
            },
        });

        handleClose();
        showSnackbarAlert({
            message: translate('success-alert', {
                sequenceName: selectedRecipient.sequence.title,
                prospectName: selectedRecipient.prospect.profile?.fullName,
                email,
            }),
        });
    };

    return selectedRecipient ? (
        availableEmails && availableEmails.length > 0 ? (
            <ResendSequenceMultipleEmails
                open={open}
                contacts={availableEmails as Contacts[]}
                handleSendSequence={handleSendSequence}
                onClose={handleClose}
            />
        ) : (
            <ResendEmailNoEmail open={open} handleSendSequence={handleSendSequence} onClose={handleClose} />
        )
    ) : (
        <ChooseSequenceModal
            open={open}
            handleClose={handleClose}
            recipients={recipients}
            selectedSequence={selectedSequence}
            handleSequenceChange={handleSequenceChange}
            handleConfirmSequence={handleConfirmSequence}
        />
    );
};

export { ResendSequenceNewEmailModal };
