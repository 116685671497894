import styled from '@emotion/styled';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';

import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface ChipProps extends MuiChipProps {
    selected?: boolean;
}

const Chip: FC<ChipProps> = (props) => <MuiChip {...props} />;

const StyledChip = styled(Chip)`
    border-radius: 8px;

    ${({ size }) =>
        (size == null || size === 'medium') &&
        `
          font-size: ${fontSizes.f14};
          height: 36px;

          .MuiChip-label {
              padding: 12px;
          }
    `}

    ${({ theme, color, selected }) =>
        (color == null || color === 'default') &&
        `
          &.MuiChip-clickable {
            background-color: ${selected ? theme.palette.primary.dark : null};
            &:hover {
                background-color: ${selected ? theme.palette.primary.dark : theme.palette.primary.contrastText};
            }
          }

    `}
      
    ${({ theme, variant }) =>
        variant === 'outlined' &&
        `
          border: 1px solid ${theme.palette.primary.main};
    `}
`;

export { StyledChip as Chip };
