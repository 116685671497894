/* eslint-disable max-len */
const blank = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const prospectFocusedFollowUp = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Your experience at {{Recipient Company}} makes me think you’re hungry,
    highly-skilled, and capable of making a big impact here at &lt;&lt; type your company &gt;&gt;.</div>
  <div><br></div>
  <div>I'd love to dive deeper into your strengths,
    share more about our team and some of the innovative ways we’re &lt;&lt;
      describe how your company is innovative &gt;&gt;.</div>
  <div><br></div>
  <div>Do you have 15 minutes for a call?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const prospectFocusedInitial = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>{{Sender First Name}} here, &lt;&lt; type your position &gt;&gt; at &lt;&lt; type your company &gt;&gt;, a
    &lt;&lt; describe your company briefly &gt;&gt;.</div>
  <div><br></div>
  <div>Your experience at {{Recipient Company}} caught my eye and I’d love to see if you would be interested in joining
    our team as a &lt;&lt; type role title &gt;&gt;.</div>
  <div><br></div>
  <div>A little more about us:</div>
  <ul>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; describe your mission &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; fill in any funding, notable investors, leadership team, etc. &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; describe milestones or progress in your product or service &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; include statistics on growth. Could be headcount, revenue, user base, etc. &gt;&gt;
      Would love to see if you might be interested! &gt;&gt;</div>
    </li>
  </ul>
  <div><br></div>
  <div>Could you spare 15 minutes to chat?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const careerGoals = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>If this looks interesting, when is a good time where I can answer your questions?</div>
  <div><br></div>
  <div>I'd love to learn more about your career goals,
    share about our team, and stay in touch (even if you're not looking for a new gig).
  </div>
  <div><br></div>
  <div>If a call doesn’t work, is there a good number where I can text more details?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const careerNextSteps = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Have you thought about the next step in your career?</div>
  <div><br></div>
  <div>I’m asking because &lt;&lt; type your company &gt;&gt;
    is hiring a &lt;&lt; type role title &gt;&gt;, and I wanted to
    see if you might be interested.</div>
  <div><br></div>
  <div>We are &lt;&lt; describe your business in 1-2 sentences &gt;&gt;.
    As a &lt;&lt; type role title &gt;&gt;, you will
    &lt;&lt; briefly describe core job functions and impact &gt;&gt;.</div>
  <div><br></div>
  <div>Even if you’re not actively looking, it would be great to learn about your career aspirations and see if
    &lt;&lt; type your company &gt;&gt; could be a good fit.</div>
  <div><br></div>
  <div>Could I ask for 15 minutes of your time?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const companyFocused = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>I’m {{Sender First Name}}, &lt;&lt;  enter your title  &gt;&gt; at &lt;&lt;  type your company  &gt;&gt;,
    a &lt;&lt;  describe your company briefly  &gt;&gt;. Our &lt;&lt;  fill in engineering/sales/etc.  &gt;&gt;
    team is hiring for a &lt;&lt;  type role title  &gt;&gt;, and I think you might be a great fit.
    To be respectful of your time, here are a few things about us and why it’s a great time to join:</div>
  <div><br></div>
  <ul>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt;  describe who you are in 1 sentence  &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt;  type in any funding, notable investors, leadership team, etc.  &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt;  describe milestones or progress in your product or service  &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt;  include statistics on growth. Could be headcount, revenue, user base, etc.  &gt;&gt;</div>
    </li>
  </ul>
  <div><br></div>
  <div>If anything above resonates with you or piques your interest,
    I’d love to share more about what life is like here at &lt;&lt;  type your company  &gt;&gt; over a quick call
  Do you have 15 minutes for a quick call?</div>
  <div><br></div>
  <div>Do you have 15 minutes for a quick call?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const coreResponsibilities = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Appreciate if you took the time to read my message.</div>
  <div><br></div>
  <div>At &lt;&lt; ype company name&gt;&gt;, you’ll be able to:</div>
  <ul>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; describe core responsibility or impact &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; describe core responsibility or impact &gt;&gt;</div>
    </li>
    <li aria-level="1" dir="ltr">
      <div>&lt;&lt; describe core responsibility or impact &gt;&gt;</div>
    </li>
  </ul>
  <div><br></div>
  <div>If any of these are interesting as you think about building your career,
    I’d love to share more about the opportunity.</div>
  <div><br></div>
  <div>Do any times on my calendar work for a short chat?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const jobDescription = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Just wanted to bump this up to the top of your inbox.</div>
  <div><br></div>
  <div>I’d love to better introduce you to our team,
    and see if our role might be a good fit for you at this point in your career.</div>
  <div><br></div>
  <div>Here’s a link to our job description: &lt;&lt; type your job description link &gt;&gt;</div>
  <div><br></div>
  <div>If this looks interesting, when is a good time where I can answer your questions?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const inboxBump = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Hope you are doing well; I know you're busy!</div>
  <div><br></div>
  <div>I’m looking for a strong &lt;&lt; type role title &gt;&gt;,
    and believe you would be a great fit here at &lt;&lt; type your company &gt;&gt;.</div>
  <div><br></div>
  <div>I'd love to learn more about your career aspirations
    and share how you can &lt;&lt; describe the impact they will make &gt;&gt;.</div>
  <div><br></div>
  <div>Would you be interested in connecting over a call?
    If so, you can find some time on my calendar for next week!</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const longForm = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>{{Sender First Name}} here, &lt;&lt; type your position &gt;&gt; at &lt;&lt; type your company &gt;&gt;, a
    &lt;&lt; DESCRIBE YOUR COMPANY BRIEFLY &gt;&gt;.</div>
  <div><br></div>
  <div>Your experience at {{Recipient Company}} caught my eye and I’d love to see if you would be interested in joining
    our team as a &lt;&lt; enter role title &gt;&gt;.</div>
  <div><br></div>
  <div>What is &lt;&lt; type your company &gt;&gt;?</div>
  <div>&lt;&lt; type your company &gt;&gt; is a &lt;&lt; describe your company in 1 sentence &gt;&gt;.
    We &lt;&lt;  DESCRIBE
    your company’s unique product/service or value proposition in 1 sentence &gt;&gt;.</div>
  <div><br></div>
  <div>Why &lt;&lt; type your company &gt;&gt;?</div>
  <ul>
    <li dir="ltr" aria-level="1">
      <div>&lt;&lt; describe your mission &gt;&gt;</div>
    </li>
    <li dir="ltr" aria-level="1">
      <div>&lt;&lt; fill in any funding, notable investors, leadership team, etc. &gt;&gt;</div>
    </li>
    <li dir="ltr" aria-level="1">
      <div>&lt;&lt; describe milestones or progress in your product or service &gt;&gt;</div>
    </li>
    <li dir="ltr" aria-level="1">
      <div>&lt;&lt; include statistics on growth. could be headcount, revenue, user base, etc. &gt;&gt;</div>
    </li>
    <li dir="ltr" aria-level="1">
      <div>And lastly, we hire smart, funny, and courageous people (like you!)</div>
    </li>
  </ul>
  <div><br></div>
  <div>Would love to continue the conversation and learn more about you.</div>
  <div><br></div>
  <div>Would you be interested in connecting for a quick call this week?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const short = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>I’m sure you’re swamped, so I’ll keep it short.</div>
  <div><br></div>
  <div>We're looking for a &lt;&lt; type role title &gt;&gt; who &lt;&lt; fill in with brief job description &gt;&gt;.
    Does this sound like you?</div>
  <div><br></div>
  <div>If this sounds exciting, I'd love to tell you more - do you have 10 minutes for a call?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const simple = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>Thanks for taking the time to read this - I know you’re busy so I’ll keep it short!
    Your experience at {{Recipient Company}} caught my attention.</div>
  <div><br></div>
  <div>Here at &lt;&lt;  Company  &gt;&gt;, we’re &lt;&lt;  describe your company's unique value proposition  &gt;&gt;.
    We’re looking to hire a strong &lt;&lt;  role title  &gt;&gt;, who would &lt;&lt; 
    core job responsibilities and impact  &gt;&gt;.</div>
  <div><br></div>
  <div>Any chance you’d be interested in learning more?</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const defaultStage1 = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>I’m excited I found your profile. We’re looking for someone with your background to join an amazing team of people working on really interesting problems. We’re growing fast and now is a great time to help us make an impact.</div>
  <div><br></div>
  <div>We think you’d be a great fit and I’d love to set up a time to chat. Do you have some time this week to connect over the phone?</div>
  <div><br></div>
  <div>All the best,</div>
  <div>{{Sender First Name}}</div>
`;

const defaultStage2 = `
  <div>Hey {{First Name}},</div>
  <div><br></div>
  <div>Hope you are doing well. Just wanted to bump this up to the top of your inbox. I think you'd be a great fit.</div>
  <div><br></div>
  <div>Let's plan to chat soon.</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const defaultStage3 = `
  <div>Hi {{First Name}},</div>
  <div><br></div>
  <div>I'm just following up on a message I sent last week about joining our team.</div>
  <div><br></div>
  <div>I hope you may find the opportunity interesting. But even if you're not looking for a new gig, I'd love to learn more about your career goals, share about our team, and stay in touch for the future.</div>
  <div><br></div>
  <div>Best,</div>
  <div>{{Sender First Name}}</div>
`;

const emailTemplates = {
    blank,
    prospectFocusedFollowUp,
    prospectFocusedInitial,
    careerGoals,
    careerNextSteps,
    companyFocused,
    coreResponsibilities,
    inboxBump,
    jobDescription,
    longForm,
    short,
    simple,
    defaultStage1,
    defaultStage2,
    defaultStage3,
};

export { emailTemplates };
