import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { Option, SelectProject } from 'shared/components/form';
import { Folder } from 'shared/components/svgs';
import { useProspectPanel, useSession } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddToProjectsSelectProps {
    hidden: boolean;
    onChange: (selectedProjects: string[]) => void;
    disabled: boolean;
}

const AddToProjectsSelect: FC<AddToProjectsSelectProps> = (props) => {
    const { disabled, hidden, onChange } = props;
    const { projectsData, members } = useProspectPanel();
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('prospects.tabs.add-tab');
    const [options, setOptions] = useState<Option[]>();
    const [selectedProjects, setSelectedProjects] = useState<Option[]>();

    const handleChange = useCallback(
        (selected: Option[]) => {
            if (!hidden) {
                setSelectedProjects(selected);
                onChange(selected.map((s) => s.value));
            }
        },
        [setSelectedProjects, onChange, hidden]
    );

    useEffect(() => {
        if (hidden) {
            setSelectedProjects([]);
            handleChange([]);
        }
    }, [hidden, handleChange]);

    useEffect(() => {
        if (options && session && session.user && !disabled) {
            const presets = options.filter(
                (f) => !f.isDisabled && session.user.lastUsedProjects?.indexOf(f.value) > -1
            );
            if (!selectedProjects) {
                handleChange(presets);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, options, disabled]);

    useEffect(() => {
        if (projectsData && members) {
            const prospectProjects = members.map((p) => p.project.id);
            setOptions(
                projectsData.map((option) => ({
                    label: option.title,
                    value: option.id,
                    isDisabled: prospectProjects.indexOf(option.id) > -1,
                }))
            );
        }
    }, [projectsData, members, session]);

    return (
        <Box
            css={css`
                padding-top: ${spacing.space16px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Box>
                    <Folder width={24} height={24} stroke={theme.palette.common.black} />
                </Box>
                <Box
                    css={css`
                        margin-left: ${spacing.space12px};
                        width: 445px;
                    `}
                >
                    {options && members && session && session.user && (
                        <SelectProject
                            css={css`
                                .select__placeholder {
                                    left: 1px;
                                }
                                .select__value-container--has-value {
                                    .select__placeholder {
                                        top: -2px;
                                        font-size: 9px;
                                        font-weight: 500;
                                    }
                                }
                                .select__value-container {
                                    padding: 0px;
                                }
                            `}
                            options={options}
                            name="projectsTitle"
                            value={selectedProjects}
                            placeholder={translate('project-select-label')}
                            onChange={handleChange}
                            isDisabled={disabled}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export { AddToProjectsSelect };
