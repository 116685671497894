import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    TablePagination as MuiTablePagination,
    TablePaginationProps as MuiTablePaginationProps,
    useTheme,
} from '@mui/material';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

import { Chevron } from 'shared/components/svgs';
import { TablePaginationActions } from 'shared/components/table/table-pagination-actions';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

type TablePaginationProps = MuiTablePaginationProps;

const TablePagination: FC<TablePaginationProps> = ({ className, page, ...rest }) => {
    const translate = useTranslations('table');
    const theme = useTheme();
    const chevronIconComponent = useCallback(
        (iconProps: object) => (
            <Chevron
                {...iconProps}
                css={css`
                    &.MuiTablePagination-selectIcon {
                        top: 12%;
                        width: 40px;
                    }
                `}
            />
        ),
        []
    );

    return (
        <MuiTablePagination
            className={className}
            labelRowsPerPage={translate('pagination-rows-per-page')}
            labelDisplayedRows={({ from, to, count }) => translate('pagination-displayed-rows', { from, to, count })}
            SelectProps={{
                MenuProps: {
                    classes: {
                        list: className,
                    },
                },
                IconComponent: chevronIconComponent,
            }}
            ActionsComponent={TablePaginationActions}
            css={css`
                .MuiTablePagination-select {
                    border: 1px solid ${theme.palette.grey[500]};
                }
            `}
            page={rest.count === 0 ? 0 : page} // suppress warnings whereby count is still 0 (loading) but page is > 0
            {...rest}
        />
    );
};

const StyledTablePagination = styled(TablePagination)`
    border: none;
    & .MuiTablePagination-selectLabel {
        display: flex;
        align-items: center;
        margin-block: 0px;
        font-size: ${fontSizes.f16};
    }

    & .MuiTablePagination-toolbar {
        display: flex;
        padding-left: 3px;
    }

    & .MuiTablePagination-spacer {
        flex: none;
    }

    & .MuiInputBase-root {
        margin-right: auto;
    }

    & .MuiMenuItem-root {
        font-size: ${fontSizes.f16};
    }

    & .MuiTablePagination-displayedRows {
        display: flex;
        align-items: center;
        margin-block: 0px;
        margin-right: ${spacing.space32px};
        font-size: ${fontSizes.f14};
    }

    ${({ theme }) => `
        // for some reason, the MUI Select duplicates its class selector 3 times.
        // the triple & is to increase specificity to match.
        &&& .MuiTablePagination-select {
            display: flex;
            align-items: center;
            padding-left: 12px;
            padding-right: 40px;
            font-size: ${fontSizes.f16};
            background-color: ${theme.palette.common.white};
            border-radius: 4px;
        }
    `}
`;

export type { TablePaginationProps };
export { StyledTablePagination as TablePagination };
