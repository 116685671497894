import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { useVersion } from 'shared/hooks';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface VersionUpdateBarProps {}

const VersionUpdateBar: FC<VersionUpdateBarProps> = () => {
    const theme = useTheme();
    const { isVisible, content } = useVersion();

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;

                position: fixed;
                left: 50%;
                transform: translateX(-50%);
                bottom: 16px;
                width: 960px;
                height: 96px;

                background-color: ${theme.palette.grey[200]};
                padding: 20px 25px;
                border-radius: 4px;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                color: ${theme.palette.common.white};
                font-weight: ${fontWeights.bold};

                @media (max-width: 1200px) and (min-width: 1000px) {
                    width: 800px;
                    height: 80px;
                }

                @media (max-width: 1000px) and (min-width: 800px) {
                    width: 600px;
                    height: 60px;
                }

                @media (max-width: 800px) and (min-width: 600px) {
                    width: 500px;
                    height: 60px;
                }

                @media (max-width: 600px) {
                    width: 360px;
                    height: 60px;
                }
            `}
        >
            <Box
                css={css`
                    margin-right: auto;
                    font-size: 2.4rem;

                    @media (max-width: 1200px) and (min-width: 1000px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 1000px) {
                        font-size: 1.1rem;
                    }
                `}
            >
                {content?.message}
            </Box>
            <Box
                css={css`
                    display: flex;
                    font-size: ${fontSizes.f16};
                `}
            >
                <Button
                    variant="contained"
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={() => window.location.reload()}
                    disableRipple
                >
                    {content?.buttonText}
                </Button>
            </Box>
        </Box>
    );
};

export { VersionUpdateBar };
