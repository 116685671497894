import { DateTime, Duration, Interval } from 'luxon';

import { Experience } from 'shared/components/composite/prospect-card-experience';

/**
 * ensures that simultaneous experience does not stack in the calculation
 * (if working at 2 jobs simultaneously for 2 years, then we only count 2 and not 4 years experience)
 *
 * @param experiences
 * @returns
 */
export const calculateSumOfWorkExperiences = (experiences: Experience[]): Duration => {
    let mergedIntervals: Interval[] = [];

    for (const exp of experiences) {
        const startDateTime = DateTime.fromObject({
            month: exp.startDateMonth ?? undefined,
            year: exp.startDateYear ?? undefined,
        });
        const endDateTime = exp.current
            ? DateTime.now()
            : DateTime.fromObject({
                  month: exp.endDateMonth ?? undefined,
                  year: exp.endDateYear ?? undefined,
              });

        const newInterval = Interval.fromDateTimes(startDateTime, endDateTime);

        if (newInterval.isValid) {
            let intervalAdded = false;

            // merge intervals if overlapping
            mergedIntervals = mergedIntervals.map((interval) => {
                if (interval.overlaps(newInterval)) {
                    intervalAdded = true;
                    return interval.union(newInterval);
                }
                return interval;
            });

            // if the interval does not overlap, add it to the list
            if (!intervalAdded) {
                mergedIntervals.push(newInterval);
            }
        } else {
            // no-op the interval is not valid
        }
    }

    const sumOfWorkExperiences = mergedIntervals.reduce((sum, interval) => {
        const duration = interval.toDuration(['years', 'months']);
        return sum.plus(duration);
    }, Duration.fromMillis(0));

    return sumOfWorkExperiences;
};

/**
 * Checks if the experience end date is before the start date
 * @param experience
 * @returns
 */
export const isExperienceValid = (experience: Experience): boolean => {
    const startDateTime = DateTime.fromObject({
        month: experience.startDateMonth ?? undefined,
        year: experience.startDateYear ?? undefined,
    });
    const endDateTime = experience.current
        ? DateTime.now()
        : DateTime.fromObject({
              month: experience.endDateMonth ?? undefined,
              year: experience.endDateYear ?? undefined,
          });

    const newInterval = Interval.fromDateTimes(startDateTime, endDateTime);
    return newInterval.isValid;
};
