import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedBell02Circle: FC<SvgProps> = ({ stroke, theme, strokeWidth = '2', ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M13.1668 17.25H10.8334M15.5001 9.66666C15.5001 8.7384 15.1314 7.84816 14.475 7.19178C13.8186 6.53541 12.9284 6.16666 12.0001 6.16666C11.0719 6.16666 10.1816 6.53541 9.52524 7.19178C8.86886 7.84816 8.50011 8.7384 8.50011 9.66666C8.50011 11.4693 8.04539 12.7035 7.53742 13.5198C7.10894 14.2084 6.8947 14.5527 6.90255 14.6488C6.91125 14.7551 6.93378 14.7957 7.01948 14.8592C7.09688 14.9167 7.44579 14.9167 8.14361 14.9167H15.8566C16.5544 14.9167 16.9033 14.9167 16.9807 14.8592C17.0664 14.7957 17.089 14.7551 17.0977 14.6488C17.1055 14.5527 16.8913 14.2084 16.4628 13.5198C15.9548 12.7035 15.5001 11.4693 15.5001 9.66666Z"
            stroke={stroke ?? theme?.palette.primary.main}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect x="1" y="1" width="22" height="22" rx="11" stroke="#999999" strokeWidth="2" />
    </svg>
);

const Bell02Circle = withTheme(ThemedBell02Circle);

export { Bell02Circle };
