import styled from '@emotion/styled';
import { ButtonGroup as MuiButtonGroup, ButtonGroupProps as MuiButtonGroupProps } from '@mui/material';

import { FC } from 'shared/types';

interface ButtonGroupProps extends MuiButtonGroupProps {}

const ButtonGroup: FC<ButtonGroupProps> = (props) => <MuiButtonGroup {...props} />;

const StyledButtonGroup = styled(ButtonGroup)``;

export { StyledButtonGroup as ButtonGroup };
