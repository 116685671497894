import { FilterButton } from 'shared/components/presentational/filter-button';
import { FC } from 'shared/types';

interface ToggleButtonFilterProps {
    checked: boolean;
    onClick?: () => void;
    onClearFilter?: () => void;
}

const ToggleButtonFilter: FC<ToggleButtonFilterProps> = ({ children, onClick, checked, ...rest }) => {
    const state = checked ? 'active' : 'default';
    return (
        <FilterButton state={state} noChevron onClick={onClick} {...rest}>
            {children}
        </FilterButton>
    );
};

export { ToggleButtonFilter };
