import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ButtonProps, LoadingButtonProps } from 'shared/components/presentational';
import { FC } from 'shared/types';

// Using two separate abstractions for the buttons because a confirm button usually implies a loading state
// whereas a cancel button does not. We can always add a loading state to the cancel button too if needed.
interface ConfirmModalButton extends LoadingButtonProps {
    text: string;
}

interface CancelModalButton extends ButtonProps {
    text: string;
}

interface ModalContent {
    title?: string;
    description?: string;
    confirmButton?: ConfirmModalButton;
    cancelButton?: CancelModalButton;
    children?: React.ReactNode;
    dimensions?: {
        width?: number;
        height?: number;
    };
}

interface ModalContextProps {
    modalContent: ModalContent;
    isModalOpen: boolean;
    hideModal: () => void;
    showModal: (content: ModalContent) => void;
    isModalLoading: boolean;
    startModalLoading: () => void;
    stopModalLoading: () => void;
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

const ConfirmationModalProvider: FC<unknown> = (props) => {
    const { children } = props;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ModalContent>();
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    const showModal = useCallback(
        (content: ModalContent) => {
            setIsModalOpen(true);

            if (content && content !== null) {
                setModalContent(content);
            }
        },
        [setIsModalOpen, setModalContent]
    );

    const startModalLoading = useCallback(() => {
        setIsModalLoading(true);
    }, [setIsModalLoading]);

    const stopModalLoading = useCallback(() => {
        setIsModalLoading(false);
    }, [setIsModalLoading]);

    const value = useMemo(
        () =>
            ({
                modalContent,
                isModalOpen,
                hideModal,
                showModal,
                isModalLoading,
                startModalLoading,
                stopModalLoading,
            } as ModalContextProps),
        [modalContent, isModalOpen, hideModal, showModal, isModalLoading, startModalLoading, stopModalLoading]
    );

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const useConfirmationModal = (): ModalContextProps => {
    const context = useContext(ModalContext);
    return { ...context };
};

export { useConfirmationModal, ConfirmationModalProvider };
