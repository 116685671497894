import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, TextField, Title } from 'shared/components/presentational';
import { isEmailValid } from 'shared/services';
import { fontSizes, fontWeights } from 'shared/settings';
import { spacing } from 'shared/settings/spacings';
import { FC } from 'shared/types';

interface ResendSequenceNoEmailProps {
    open: boolean;
    onClose: () => void;
    handleSendSequence: (email: string) => void;
}

const ResendEmailNoEmail: FC<ResendSequenceNoEmailProps> = (props) => {
    const { onClose, handleSendSequence, ...rest } = props;
    const translate = useTranslations('sequence.recipient-list-table.resend-sequence-new-email-modal');
    const [email, setEmail] = useState<string>('');
    const [isValid, setIsValid] = useState(true);

    const reset = () => {
        setEmail('');
        setIsValid(true);
    };

    const handleClickSendSequence = async () => {
        if (!isEmailValid(email)) {
            setIsValid(false);
            return;
        }

        handleSendSequence(email);
        onClose();
        reset();
    };

    const handleClose = () => {
        onClose();
        reset();
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
        if (!isValid) {
            setIsValid(true);
        }
    };

    return (
        <Modal onClose={handleClose} {...rest}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('manual-email.header')}
                </Title>
                <Box>
                    <p>{translate('manual-email.description-part1')}</p>
                    <Box>
                        {translate.rich('manual-email.description-part2', {
                            // eslint-disable-next-line react/no-unstable-nested-components
                            bold: (children) => (
                                <Box
                                    component="span"
                                    css={css`
                                        font-weight: ${fontWeights.bold};
                                        text-overflow: ellipsis;
                                    `}
                                >
                                    {children}
                                </Box>
                            ),
                        })}
                    </Box>
                    <Box
                        css={css`
                            padding-top: ${spacing.space12px};
                        `}
                    >
                        <TextField
                            focused
                            inputRef={(input) => input && input.focus()}
                            error={!isValid}
                            helperText={isValid ? null : translate('manual-email.invalid-email')}
                            value={email}
                            onChange={handleEmailChange}
                            label={translate('manual-email.select-email-label')}
                            css={css`
                                width: 100%;
                            `}
                        />
                    </Box>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        padding-top: ${spacing.space32px};
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleClose}
                        css={css`
                            margin-right: ${spacing.space16px};
                        `}
                    >
                        {translate('cancel-button-label')}
                    </Button>
                    <Button variant="contained" onClick={handleClickSendSequence}>
                        {translate('send-sequence-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ResendEmailNoEmail };
