import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface ExtensionPanelProps {
    panelIsOpen: boolean;
    setPanelIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ExtensionPanelContext = createContext<ExtensionPanelProps>({} as ExtensionPanelProps);

const ExtensionPanelProvider: FC<ExtensionPanelProps> = ({ panelIsOpen, setPanelIsOpen, children }) => {
    const value = useMemo(
        () => ({
            panelIsOpen,
            setPanelIsOpen,
        }),
        [panelIsOpen, setPanelIsOpen]
    );

    return <ExtensionPanelContext.Provider value={value}>{children}</ExtensionPanelContext.Provider>;
};

const useExtensionPanel = () => useContext(ExtensionPanelContext);

export { ExtensionPanelProvider, ExtensionPanelContext, useExtensionPanel };
