import { css } from '@emotion/react';
import { OutlinedInput, Popover } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';
import { DayPicker, useInput } from 'react-day-picker';

import { Box } from 'shared/components/containers';
import { Select, MenuItem } from 'shared/components/form';
import { NoSsr } from 'shared/components/presentational';
import { colors, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

export interface PresetDateRange {
    label:
        | 'last-week'
        | 'last-14-days'
        | 'last-month'
        | 'last-quarter'
        | 'last-year'
        | 'month-to-date'
        | 'quarter-to-date'
        | 'year-to-date'
        | 'all-time'
        | 'custom';
    value:
        | 'lastWeek'
        | 'last14Days'
        | 'lastMonth'
        | 'lastQuarter'
        | 'lastYear'
        | 'monthToDate'
        | 'quarterToDate'
        | 'yearToDate'
        | 'allTime'
        | 'custom';
    getDateRange?: (start?: DateTime) => { start: DateTime; end: DateTime };
}

const presetDateRanges: PresetDateRange[] = [
    {
        label: 'last-week',
        value: 'lastWeek',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('week').minus({ weeks: 1 });
            const end = now.endOf('week').minus({ weeks: 1 });
            return { start, end };
        },
    },
    {
        label: 'last-14-days',
        value: 'last14Days',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('week').minus({ weeks: 2 });
            const end = now.endOf('week').minus({ weeks: 1 });
            return { start, end };
        },
    },
    {
        label: 'last-month',
        value: 'lastMonth',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('month').minus({ months: 1 });
            const end = now.endOf('month').minus({ months: 1 });
            return { start, end };
        },
    },
    {
        label: 'last-quarter',
        value: 'lastQuarter',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('quarter').minus({ quarters: 1 });
            const end = now.endOf('quarter').minus({ quarters: 1 });
            return { start, end };
        },
    },
    {
        label: 'last-year',
        value: 'lastYear',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('year').minus({ years: 1 });
            const end = now.endOf('year').minus({ years: 1 });
            return { start, end };
        },
    },
    {
        label: 'month-to-date',
        value: 'monthToDate',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('month');
            const end = now.endOf('day');
            return { start, end };
        },
    },
    {
        label: 'quarter-to-date',
        value: 'quarterToDate',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('quarter');
            const end = now.endOf('day');
            return { start, end };
        },
    },
    {
        label: 'year-to-date',
        value: 'yearToDate',
        getDateRange: () => {
            const now = DateTime.local();
            const start = now.startOf('year');
            const end = now.endOf('day');
            return { start, end };
        },
    },
    {
        label: 'custom',
        value: 'custom',
    },
];

const presetDateRangesWithAllTime: PresetDateRange[] = [
    ...presetDateRanges,
    {
        label: 'all-time',
        value: 'allTime',
        getDateRange: (start: DateTime = DateTime.fromISO('1970-01-01T00:00:00')) => {
            const end = DateTime.local().endOf('day');
            return { start, end };
        },
    },
];

interface DateSelectorWithPresetsProps {
    allTimeStartDate?: DateTime;
    onChangeFromDate?: (date: DateTime) => void;
    onChangeToDate?: (date: DateTime) => void;
    onChangeDatePreset?: (preset: PresetDateRange['value']) => void;
    defaultPreset?: PresetDateRange['value'];
    disableAllTimePreset?: boolean;
}

const DateSelectorWithPresets: FC<DateSelectorWithPresetsProps> = ({
    allTimeStartDate,
    onChangeFromDate,
    onChangeToDate,
    onChangeDatePreset,
    defaultPreset = 'lastMonth',
    disableAllTimePreset = false,
}) => {
    const translate = useTranslations('shared.preset-date-selector');

    const [selectedPreset, setSelectedPreset] = useState<PresetDateRange['value']>(defaultPreset);
    const [fromDatePickerOpen, setFromDatePickerOpen] = useState<boolean>(false);
    const [toDatePickerOpen, setToDatePickerOpen] = useState<boolean>(false);
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLInputElement>();

    const usablePresetDateRanges = disableAllTimePreset ? presetDateRanges : presetDateRangesWithAllTime;
    const defaultPresetRange = usablePresetDateRanges
        .find((preset) => preset.value === defaultPreset)
        ?.getDateRange?.();

    const fromDateHook = useInput({
        defaultSelected: defaultPresetRange?.start.toJSDate(),
        format: 'MMM dd yyyy',
        toDate: DateTime.local().toJSDate(),
    });

    const toDateHook = useInput({
        defaultSelected: defaultPresetRange?.end.toJSDate(),
        format: 'MMM dd yyyy',
        toDate: DateTime.local().toJSDate(),
    });

    fromDateHook.dayPickerProps.onDayClick = (date: Date) => {
        const newFromDate = DateTime.fromJSDate(date).startOf('day');
        const currentToDate = toDateHook.dayPickerProps.selected;

        // if selected FROM date is after selected TO date, set TO date to FROM date + 1 day
        if (currentToDate && newFromDate > DateTime.fromJSDate(currentToDate)) {
            const newToDate = newFromDate.plus({ days: 1 }).endOf('day');
            toDateHook.setSelected?.(newToDate.toJSDate());
            onChangeToDate?.(newToDate);
        }

        fromDateHook.setSelected?.(newFromDate.toJSDate());
        onChangeFromDate?.(newFromDate);
        setSelectedPreset('custom');
    };

    toDateHook.dayPickerProps.onDayClick = (date: Date) => {
        const newToDate = DateTime.fromJSDate(date).endOf('day');
        const currentFromDate = fromDateHook.dayPickerProps.selected;

        // if selected TO date is before selected FROM date, set FROM date to TO date - 1 day
        if (currentFromDate && newToDate < DateTime.fromJSDate(currentFromDate)) {
            const newFromDate = newToDate.minus({ days: 1 }).startOf('day');
            fromDateHook.setSelected?.(newFromDate.toJSDate());
            onChangeFromDate?.(newFromDate);
        }

        toDateHook.setSelected?.(newToDate.toJSDate());
        onChangeToDate?.(newToDate);
        setSelectedPreset('custom');
    };

    // keep day picker hooks in sync with selected preset
    useEffect(() => {
        if (selectedPreset !== 'custom') {
            const preset = usablePresetDateRanges.find((p) => p.value === selectedPreset);
            const { start, end } = preset?.getDateRange?.(allTimeStartDate) || {};
            fromDateHook.setSelected?.(start?.toJSDate());
            toDateHook.setSelected?.(end?.toJSDate());

            if (start) onChangeFromDate?.(start);
            if (end) onChangeToDate?.(end);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPreset, allTimeStartDate]);

    const handlePresetChange = (event: any) => {
        const { value } = event.target;
        setSelectedPreset(value);

        onChangeDatePreset?.(value);
    };

    const handleFromDatePickerOpen = (event: React.MouseEvent<HTMLInputElement>) => {
        setPopoverAnchor(event.currentTarget);
        setFromDatePickerOpen(true);
    };

    const handleFromDatePickerClose = () => {
        setPopoverAnchor(undefined);
        setFromDatePickerOpen(false);
    };

    const handleToDatePickerOpen = (event: React.MouseEvent<HTMLInputElement>) => {
        setPopoverAnchor(event.currentTarget);
        setToDatePickerOpen(true);
    };

    const handleToDatePickerClose = () => {
        setToDatePickerOpen(false);
    };

    // if selected preset is 'allTime' and no start date is provided, show 'beginning of time' instead of Jan 1, 1970
    const showBeginningOfTime: boolean = selectedPreset === 'allTime' && !allTimeStartDate;

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                gap: ${spacing.space16px};

                width: 100%;
            `}
        >
            {/* container for date range presets */}
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Select
                    css={css`
                        width: 200px;
                        margin-bottom: 0;

                        .MuiOutlinedInput-root {
                            background-color: ${colors.neutrals.white};
                        }

                        .MuiOutlinedInput-input {
                            padding-top: 21px;
                            padding-bottom: 12px;
                        }
                    `}
                    name="date-range-selector"
                    value={selectedPreset}
                    onChange={handlePresetChange}
                >
                    {usablePresetDateRanges
                        .filter((p) => p.value !== 'custom')
                        .map((p) => (
                            <MenuItem key={p.value} value={p.value}>
                                {translate(p.label)}
                            </MenuItem>
                        ))}
                    {selectedPreset === 'custom' && (
                        <MenuItem key="customDateRange" value="custom">
                            {translate('custom')}
                        </MenuItem>
                    )}
                </Select>
            </Box>
            {/* container for custom date selects */}
            <Box
                css={css`
                    display: flex;
                    align-items: center;

                    .MuiOutlinedInput-root {
                        width: 160px;
                        font-size: ${fontSizes.f16};
                        background-color: ${colors.neutrals.white};
                        color: ${colors.neutrals.black};
                        border-color: ${colors.grays.gallery};
                    }
                `}
            >
                <Box>
                    <Popover
                        anchorEl={popoverAnchor}
                        open={fromDatePickerOpen}
                        onClose={handleFromDatePickerClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <DayPicker {...fromDateHook.dayPickerProps} />
                    </Popover>

                    <NoSsr>
                        <OutlinedInput
                            {...fromDateHook.inputProps}
                            readOnly
                            onClick={handleFromDatePickerOpen}
                            value={showBeginningOfTime ? translate('beginning-of-time') : fromDateHook.inputProps.value}
                        />
                    </NoSsr>
                </Box>
                <Box
                    css={css`
                        margin-left: ${spacing.space8px};
                    `}
                >
                    <Popover
                        anchorEl={popoverAnchor}
                        open={toDatePickerOpen}
                        onClose={handleToDatePickerClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <DayPicker {...toDateHook.dayPickerProps} />
                    </Popover>
                    <NoSsr>
                        <OutlinedInput
                            {...toDateHook.inputProps}
                            inputProps={{ readOnly: true }}
                            onClick={handleToDatePickerOpen}
                        />
                    </NoSsr>
                </Box>
            </Box>
        </Box>
    );
};

export { DateSelectorWithPresets };
