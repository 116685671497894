import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { escapeRegExp, uniqBy } from 'lodash';
import debounce from 'lodash/debounce';
import xor from 'lodash/xor';
import { useTranslations } from 'next-intl';
import React, { useEffect, useMemo, useState } from 'react';

import {
    GetTeammatesOfUserByIdQuery,
    OrderBy,
    RecipientStatusEnum,
    SourcerCollaboratorTypeEnum,
    SourcerMembersBoolExp,
    SourcerMembersQuery,
    SourcerMemberStatusEnum,
    UserStatusEnum,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import {
    Button,
    Checkbox,
    DateFilter,
    DateOptions,
    FilterBarDescription,
    FilterButton,
    FilterTextField,
    getDateFrom,
    Popover,
    Snackbar,
    TableHeaderEllipsisText,
} from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from 'shared/components/table';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import {
    GET_SOURCER_MEMBERS,
    SourcerMemberData,
    sourcerMembersDefaultOrderBy,
    sourcerMembersDefaultOrderByDirection,
    sourcerMembersDefaultVariables,
} from 'shared/graphql/sourcer-members';
import { GET_TEAMMATES_OF_USER } from 'shared/graphql/teams';
import { useQueryRefresh, useSession } from 'shared/hooks';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { SourcerMembersListEditBar, SourcerMembersListRow } from 'sourcing/components/tables';

type OrderByColumn = Header['label'];

interface Header {
    label:
        | 'name-header'
        | 'status-header'
        | 'company-role-header'
        | 'location-header'
        | 'date-added-header'
        | 'date-rejected-header'
        | 'date-snoozed-header';
    field: keyof SourcerMemberData;
    width: string;
}

const sortableColumns: string[] = [
    'name-header',
    'location-header',
    'date-added-header',
    'date-rejected-header',
    'date-snoozed-header',
];

// eslint-disable-next-line no-magic-numbers
const defaultRowsPerPageOptions = [10, 25, 50, 100];
const defaultOrderByDirection = OrderBy.Desc;
const statusOptions = [
    {
        label: 'completed-with-no-reply',
        value: RecipientStatusEnum.CompletedWithNoReply,
    },
    {
        label: 'finding-email',
        value: RecipientStatusEnum.FindingEmail,
    },
    {
        label: 'in-progress',
        value: RecipientStatusEnum.InProgress,
    },
    {
        label: 'manually-exited',
        value: RecipientStatusEnum.ManuallyExited,
    },
    {
        label: 'meeting-booked',
        value: RecipientStatusEnum.MeetingBooked,
    },
    {
        label: 'not-started',
        value: RecipientStatusEnum.NotStarted,
    },
    {
        label: 'responded',
        value: RecipientStatusEnum.Responded,
    },
    {
        label: 'unable-to-find-contact',
        value: RecipientStatusEnum.UnableToFindContact,
    },
    {
        label: 'unable-to-send-message',
        value: RecipientStatusEnum.UnableToSendMessage,
    },
    {
        label: 'missing-template-variables',
        value: RecipientStatusEnum.MissingTemplateVariables,
    },
    {
        label: 'unsubscribed',
        value: RecipientStatusEnum.Unsubscribed,
    },
] as const;

const filterTextDebounceTime = 500; // ms

interface SourcerMembersListTableProps {
    sourcer: {
        id: string;
        userId: string;
        project: { id: string };
        collaborators: { id: string; userId: string; type: SourcerCollaboratorTypeEnum }[];
    };
    creditsPerApproval: number;
    status: SourcerMemberStatusEnum;
    readOnly?: boolean;
}
const SourcerMembersListTable: FC<SourcerMembersListTableProps> = ({
    creditsPerApproval,
    sourcer,
    status,
    readOnly = false,
}) => {
    const headers: Header[] = useMemo(() => {
        // The percentage widths of the table columns don't need to add up to 100%.
        // The table will automatically distribute the remaining space.
        if (status === SourcerMemberStatusEnum.Rejected) {
            return [
                { label: 'name-header', field: 'fullName', width: '25%' },
                { label: 'company-role-header', field: 'experience', width: '15%' },
                { label: 'location-header', field: 'location', width: '30%' },
                { label: 'date-rejected-header', field: 'rejectedAt', width: '15%' },
            ];
        }
        if (status === SourcerMemberStatusEnum.Snoozed) {
            return [
                { label: 'name-header', field: 'fullName', width: '25%' },
                { label: 'company-role-header', field: 'experience', width: '15%' },
                { label: 'location-header', field: 'location', width: '30%' },
                { label: 'date-snoozed-header', field: 'snoozedAt', width: '15%' },
            ];
        }
        return [
            { label: 'name-header', field: 'fullName', width: '25%' },
            { label: 'status-header', field: 'status', width: '15%' },
            { label: 'company-role-header', field: 'experience', width: '15%' },
            { label: 'location-header', field: 'location', width: '30%' },
            { label: 'date-added-header', field: 'addedAt', width: '15%' },
        ];
    }, [status]);

    const translate = useTranslations('sourcing.members-list-table');
    const theme = useTheme();
    const { session, loaded } = useSession();

    const [offset, setOffset] = useState(sourcerMembersDefaultVariables.offset);
    const [limit, setLimit] = useState(sourcerMembersDefaultVariables.limit);

    const [statusPopoverAnchor, setStatusPopoverAnchor] = useState<HTMLButtonElement>();
    const [statusFilters, setStatusFilters] = useState<RecipientStatusEnum[]>([]);
    const isStatusFilterApplied = statusFilters.length > 0;

    const [sequencePopoverAnchor, setSequencePopoverAnchor] = useState<HTMLButtonElement>();
    const [sequenceFilters, setSequenceFilters] = useState<string[]>([]);
    const isSequenceFilterApplied = sequenceFilters.length > 0;

    const [dateAddedFilterOption, setDateAddedFilterOption] = useState<DateOptions>();
    const isDateAddedFilterOptionApplied = Boolean(dateAddedFilterOption);

    const [dateRejectedFilterOption, setDateRejectedFilterOption] = useState<DateOptions>();
    const isDateRejectedFilterOptionApplied = Boolean(dateRejectedFilterOption);

    const [contributorPopoverAnchor, setContributorPopoverAnchor] = useState<HTMLButtonElement>();
    const [contributorFilters, setContributorFilters] = useState<string[]>([]);
    const isContributorFilterApplied = contributorFilters.length > 0;

    const [keywordFilter, setKeywordFilter] = useState('');
    const isKeywordFilterApplied = keywordFilter !== '';

    const areFiltersApplied =
        isStatusFilterApplied ||
        isSequenceFilterApplied ||
        isDateAddedFilterOptionApplied ||
        isDateRejectedFilterOptionApplied ||
        isContributorFilterApplied ||
        isKeywordFilterApplied;

    const [textFilter, setTextFilter] = useState<SourcerMembersBoolExp[]>([{}]);
    const debouncedSetTextFilter = useMemo(() => debounce(setTextFilter, filterTextDebounceTime), []);
    useEffect(() => {
        const regexText = `%${escapeRegExp(keywordFilter)}%`;
        debouncedSetTextFilter(
            isKeywordFilterApplied
                ? [
                      {
                          experience: {
                              _or: [
                                  { title: { _ilike: regexText }, current: { _eq: true } },
                                  { companyName: { _ilike: regexText }, current: { _eq: true } },
                              ],
                          },
                      },
                      { reviewer: { fullName: { _ilike: regexText } } },
                      { location: { _ilike: regexText } },
                      {
                          fullName: {
                              _ilike: regexText,
                          },
                      },
                  ]
                : [{}]
        );
    }, [keywordFilter, isKeywordFilterApplied, debouncedSetTextFilter, status]);

    const [orderBy, setOrderBy] = useState<OrderByColumn>(
        headers.find((h) => h.field === sourcerMembersDefaultOrderBy)?.label || 'date-added-header'
    );
    const [orderByDirection, setOrderByDirection] = useState<OrderBy.Asc | OrderBy.Desc>(
        sourcerMembersDefaultOrderByDirection
    );

    const getSourcerMembersVariables = useMemo(
        () => ({
            ...sourcerMembersDefaultVariables,
            orderBy: {
                [headers.find((h) => h.label === orderBy)?.field || sourcerMembersDefaultOrderBy]: orderByDirection,
            },
            offset,
            limit,
            where: areFiltersApplied
                ? {
                      _and: {
                          status: { _eq: status },
                          sourcerId: { _eq: sourcer.id },
                          ...(status === SourcerMemberStatusEnum.Accepted && {
                              // only apply for accepted sourcer members
                              prospect:
                                  isStatusFilterApplied || isSequenceFilterApplied
                                      ? {
                                            recipients: {
                                                status: isStatusFilterApplied ? { _in: statusFilters } : {},
                                                sequence: {
                                                    id: isSequenceFilterApplied ? { _in: sequenceFilters } : {},
                                                },
                                            },
                                        }
                                      : {},
                          }),
                          addedAt: isDateAddedFilterOptionApplied
                              ? { _gte: getDateFrom(dateAddedFilterOption!).getTime() }
                              : {},
                          rejectedAt: isDateRejectedFilterOptionApplied
                              ? { _gte: getDateFrom(dateRejectedFilterOption!).getTime() }
                              : {},

                          reviewerId: isContributorFilterApplied ? { _in: contributorFilters } : {},
                          _or: textFilter,
                      },
                  }
                : {
                      _and: {
                          status: { _eq: status },
                          sourcerId: { _eq: sourcer.id },
                      },
                  },
        }),
        [
            headers,
            orderByDirection,
            offset,
            limit,
            areFiltersApplied,
            status,
            sourcer.id,
            isStatusFilterApplied,
            isSequenceFilterApplied,
            statusFilters,
            sequenceFilters,
            isDateAddedFilterOptionApplied,
            dateAddedFilterOption,
            isDateRejectedFilterOptionApplied,
            dateRejectedFilterOption,
            isContributorFilterApplied,
            contributorFilters,
            textFilter,
            orderBy,
        ]
    );

    const { previousData, data, loading } = useQueryRefresh<SourcerMembersQuery>(GET_SOURCER_MEMBERS, {
        variables: getSourcerMembersVariables,
        fetchPolicy: 'cache-and-network',
    });

    const count = data
        ? data.sourcer_members_aggregate.aggregate!.count
        : previousData?.sourcer_members_aggregate?.aggregate?.count ?? 0;

    const [contributorOptions, setContributorOptions] = useState<Array<{ label: string; value: string }>>([]);

    const { data: contributorData } = useQueryRefresh<GetTeammatesOfUserByIdQuery>(GET_TEAMMATES_OF_USER, {
        skip: !loaded,
        variables: {
            id: session?.user.id,
        },
    });

    useEffect(() => {
        setContributorOptions(
            contributorData?.currentUser?.team.users
                .filter((f) => f.status !== UserStatusEnum.Archived)
                .map((u) => ({
                    label: u.fullName,
                    value: u.id,
                })) ?? []
        );
    }, [contributorData]);

    const [selected, setSelected] = useState<Set<string>>(new Set());

    const selectedSourcerMembers = data?.sourcer_members.filter((m) => Array.from(selected).includes(m.id)) ?? [];

    useEffect(() => {
        setOffset(0);
        setSelected(new Set());
    }, [statusFilters, sequenceFilters, contributorFilters, textFilter]);

    const handleSelectAll = async () => {
        const subset = new Set(data?.sourcer_members.map((sm) => sm.id));
        const intersectionSet = new Set([...selected].filter((x) => subset.has(x)));
        if (intersectionSet.size !== subset.size) {
            setSelected((prev) => new Set([...prev, ...subset]));
        } else {
            const differenceSet = new Set([...selected].filter((x) => !subset.has(x)));
            setSelected(differenceSet);
        }
    };

    const handleToggleSelect = (checked: boolean, memberId: string) => {
        if (checked) {
            setSelected((prev) => new Set([...prev, memberId]));
        } else {
            setSelected((prev) => {
                const next = new Set(prev);
                next.delete(memberId);
                return next;
            });
        }
    };

    const handleEditBarClose = () => {
        setSelected(new Set());
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setOffset(newPage * limit);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setOffset(0);
        setLimit(newRowsPerPage);
    };

    const createSortHandler = (col: OrderByColumn) => () => {
        const isDesc = orderBy === col && orderByDirection === OrderBy.Desc;
        const direction = isDesc ? OrderBy.Asc : OrderBy.Desc;
        setOrderByDirection(direction);
        setOrderBy(col);
    };

    const handleStatusFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setStatusFilters(statusFilters);
        setStatusPopoverAnchor(event.currentTarget);
    };

    const handleStatusPopoverClose = () => setStatusPopoverAnchor(undefined);

    const handleStatusCheckboxClick = (value: RecipientStatusEnum) => () =>
        setStatusFilters(xor(statusFilters, [value]));

    const handleClearStatusFilter = () => setStatusFilters([]);

    const handleSequenceFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSequenceFilters(sequenceFilters);
        setSequencePopoverAnchor(event.currentTarget);
    };

    const handleSequencePopoverClose = () => setSequencePopoverAnchor(undefined);

    const handleSequenceCheckboxClick = (value: string) => () => setSequenceFilters(xor(sequenceFilters, [value]));

    const handleClearSequenceFilter = () => setSequenceFilters([]);

    const sequenceOptions = useMemo(
        () =>
            uniqBy(
                data?.sourcer_members
                    .flatMap((member) => member.prospect?.recipients)
                    .filter((recipient) => Boolean(recipient))
                    .map((recipient) => recipient?.sequence)
                    .map((sequence) => ({ label: sequence!.title, value: sequence!.id })) ?? [],
                (option) => option.value
            ),
        [data]
    );

    const handleContributorFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setContributorFilters(contributorFilters);
        setContributorPopoverAnchor(event.currentTarget);
    };

    const handleContributorPopoverClose = () => setContributorPopoverAnchor(undefined);

    const handleContributorCheckboxClick = (value: string) => () =>
        setContributorFilters(xor(contributorFilters, [value]));

    const handleClearContributorFilter = () => setContributorFilters([]);

    const statusOptionCheckboxes = statusOptions.map(({ label, value }) => (
        <FormControlLabel
            key={label}
            checked={statusFilters.includes(value)}
            onChange={handleStatusCheckboxClick(value)}
            label={translate(label)}
            control={<Checkbox />}
            css={css`
                .MuiFormControlLabel-label {
                    font-size: ${fontSizes.f16};
                    margin-left: 16px;
                }
            `}
        />
    ));

    const statusFilter = (
        <>
            <FilterButton
                state={statusPopoverAnchor ? 'hover' : isStatusFilterApplied ? 'active' : 'default'}
                onClick={handleStatusFilterButtonClick}
            >
                {translate('status-filter-label')}
            </FilterButton>
            <Popover
                open={Boolean(statusPopoverAnchor)}
                anchorEl={statusPopoverAnchor}
                onClose={handleStatusPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                        width: 337px;
                    `}
                >
                    {statusOptionCheckboxes}
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={handleClearStatusFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );

    const sequenceOptionCheckboxes = sequenceOptions.map(({ label, value }) => (
        <FormControlLabel
            key={label}
            checked={sequenceFilters.includes(value)}
            onChange={handleSequenceCheckboxClick(value)}
            label={label}
            control={<Checkbox />}
            css={css`
                .MuiFormControlLabel-label {
                    font-size: ${fontSizes.f16};
                    margin-left: 16px;
                }
            `}
        />
    ));

    const sequenceFilter = (
        <>
            <FilterButton
                state={sequencePopoverAnchor ? 'hover' : isSequenceFilterApplied ? 'active' : 'default'}
                onClick={handleSequenceFilterButtonClick}
            >
                {translate('sequence-filter-label')}
            </FilterButton>
            <Popover
                open={Boolean(sequencePopoverAnchor)}
                anchorEl={sequencePopoverAnchor}
                onClose={handleSequencePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                        width: 337px;
                    `}
                >
                    {sequenceOptionCheckboxes}
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={handleClearSequenceFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );

    const handleDateAddedClick = (value: DateOptions) => () => {
        setDateAddedFilterOption(value);
    };

    const handleClearDateAddedFilterOption = () => setDateAddedFilterOption(undefined);

    const dateAddedFilter = (
        <DateFilter
            label={translate('date-added-filter-label')}
            onDateClick={handleDateAddedClick}
            onClearFilter={handleClearDateAddedFilterOption}
            current={dateAddedFilterOption}
        />
    );

    const handleDateRejectedClick = (value: DateOptions) => () => {
        setDateRejectedFilterOption(value);
    };

    const handleClearDateRejectedFilterOption = () => setDateRejectedFilterOption(undefined);

    const dateRejectedFilter = (
        <DateFilter
            label={translate('date-rejected-filter-label')}
            onDateClick={handleDateRejectedClick}
            onClearFilter={handleClearDateRejectedFilterOption}
            current={dateRejectedFilterOption}
        />
    );

    const contributorOptionCheckboxes = contributorOptions.map(({ label, value }) => (
        <FormControlLabel
            key={label}
            checked={contributorFilters.includes(value)}
            onChange={handleContributorCheckboxClick(value)}
            label={label}
            control={<Checkbox />}
            css={css`
                .MuiFormControlLabel-label {
                    font-size: ${fontSizes.f16};
                    margin-left: 16px;
                }
            `}
        />
    ));

    const contributorFilter = (
        <>
            <FilterButton
                state={contributorPopoverAnchor ? 'hover' : isContributorFilterApplied ? 'active' : 'default'}
                onClick={handleContributorFilterButtonClick}
            >
                {translate('contributor-filter-label')}
            </FilterButton>
            <Popover
                open={Boolean(contributorPopoverAnchor)}
                anchorEl={contributorPopoverAnchor}
                onClose={handleContributorPopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                        width: 337px;
                    `}
                >
                    {contributorOptionCheckboxes}
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={handleClearContributorFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );

    const handleKeywordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKeyword = event.target.value;
        setKeywordFilter(newKeyword);
    };

    const handleClearAllFilters = () => {
        setStatusFilters([]);
        setSequenceFilters([]);
        handleClearDateAddedFilterOption();
        handleClearDateRejectedFilterOption();
        setContributorFilters([]);
        setKeywordFilter('');
    };

    const computeFilterDescription = () => {
        if (!areFiltersApplied) {
            let msg: string = '';
            // only show the count if not loading
            if (loading) {
                msg = translate('no-filters-applied-description');
            } else {
                msg = `${translate('no-filters-applied-description')}${translate('filter-description-count', {
                    count,
                })}`;
            }
            return (
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                    `}
                >
                    {msg}
                </Box>
            );
        }

        // eslint-disable-next-line react/no-unstable-nested-components
        const BoldSpan: FC<unknown> = ({ children }) => (
            <Box
                component="span"
                css={css`
                    font-weight: ${fontWeights.bold};
                    text-overflow: ellipsis;
                `}
            >
                {children}
            </Box>
        );
        const bold = (children: React.ReactNode) => <BoldSpan>{children}</BoldSpan>;

        const filters = [];
        if (isStatusFilterApplied) {
            filters.push(
                translate.rich('status-filter-description', {
                    statuses: statusFilters
                        .map((statusFilterEnum) =>
                            translate(
                                statusOptions.find((statusOption) => statusOption.value === statusFilterEnum)!.label
                            )
                        )
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }

        if (isSequenceFilterApplied) {
            filters.push(
                translate.rich('sequence-filter-description', {
                    sequences: sequenceFilters
                        .map(
                            (sequenceFilterEnum) =>
                                sequenceOptions.find((sequenceOption) => sequenceOption.value === sequenceFilterEnum)
                                    ?.label
                        )
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }

        if (isDateAddedFilterOptionApplied) {
            filters.push(
                translate.rich('date-added-filter-description', {
                    date: translate(dateAddedFilterOption!),
                    bold,
                })
            );
        }

        if (isDateRejectedFilterOptionApplied) {
            filters.push(
                translate.rich('date-rejected-filter-description', {
                    date: translate(dateRejectedFilterOption!),
                    bold,
                })
            );
        }

        if (isContributorFilterApplied) {
            filters.push(
                translate.rich('contributor-filter-description', {
                    contributors: contributorFilters
                        .map(
                            (contributorFilterEnum) =>
                                contributorOptions.find(
                                    (contributorOption) => contributorOption.value === contributorFilterEnum
                                )?.label
                        )
                        .join(translate('or-connector')),
                    bold,
                })
            );
        }

        if (isKeywordFilterApplied) {
            filters.push(
                translate.rich('keyword-filter-description', {
                    index: filters.length,
                    keyword: keywordFilter,
                    bold,
                })
            );
        }

        return (
            <Box
                css={css`
                    font-size: ${fontSizes.f14};
                    display: flex;
                `}
            >
                <FilterBarDescription>{filters}</FilterBarDescription>
                <Box
                    css={css`
                        white-space: pre;
                    `}
                >
                    {!loading ? translate('filter-description-count', { count }) : ''}
                </Box>
            </Box>
        );
    };

    const filterBar = (
        <Box>
            <Box
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: ${spacing.space8px};
                    gap: ${spacing.space8px};
                `}
            >
                {status === SourcerMemberStatusEnum.Accepted ? statusFilter : null}
                {status === SourcerMemberStatusEnum.Accepted ? sequenceFilter : null}
                {status === SourcerMemberStatusEnum.Accepted ? dateAddedFilter : null}
                {status === SourcerMemberStatusEnum.Rejected ? dateRejectedFilter : null}
                {contributorFilter}
                <FilterTextField value={keywordFilter} onChange={handleKeywordFilterChange} />
            </Box>
            <Box
                css={css`
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) min-content;
                    column-gap: ${spacing.space16px};
                    align-items: center;
                    width: 100%;
                    margin-bottom: 4px;
                `}
            >
                {computeFilterDescription()}
                <Button
                    css={css`
                        font-size: ${fontSizes.f14};
                        font-weight: ${fontWeights.bold};
                        white-space: nowrap;

                        margin-left: auto;
                        padding: 0;
                        opacity: 0;

                        transition: opacity 0.4s ease-in-out;
                        ${areFiltersApplied && `opacity: 1;`}
                    `}
                    variant="text"
                    startIcon={<XClose />}
                    onClick={handleClearAllFilters}
                    disableRipple
                >
                    {translate('clear-all-filters-label')}
                </Button>
            </Box>
        </Box>
    );

    const tableHeaders = (
        <TableRow>
            {headers.map(({ label, width }) => {
                const align: 'flex-start' | 'center' = 'flex-start';
                const left: string = 'inherit';
                return (
                    <TableCell key={label} width={width}>
                        <Box
                            css={css`
                                display: flex;
                                justify-content: ${align};
                            `}
                        >
                            {label === 'name-header' && !readOnly && (
                                <Checkbox
                                    css={css`
                                        margin-right: 8px;
                                    `}
                                    indeterminate={selected.size > 0 && selected.size < count}
                                    checked={count > 0 && selected.size === count}
                                    onChange={handleSelectAll}
                                />
                            )}

                            {sortableColumns.includes(label) ? (
                                <TableSortLabel
                                    active={label === orderBy}
                                    direction={label === orderBy ? orderByDirection : defaultOrderByDirection}
                                    onClick={createSortHandler(label)}
                                    css={css`
                                        flex-grow: 1;
                                        left: ${left};
                                        justify-content: ${align};
                                        white-space: nowrap;
                                    `}
                                >
                                    <TableHeaderEllipsisText>{translate(label)}</TableHeaderEllipsisText>
                                </TableSortLabel>
                            ) : (
                                <TableHeaderEllipsisText>{translate(label)}</TableHeaderEllipsisText>
                            )}
                        </Box>
                    </TableCell>
                );
            })}
        </TableRow>
    );

    const tableBody = data?.sourcer_members.map((member) => (
        <SourcerMembersListRow
            key={member.id}
            member={member}
            status={status}
            isSelected={selected.has(member.id)}
            onToggleSelect={handleToggleSelect}
            creditsPerApproval={creditsPerApproval}
            sourcer={sourcer}
            readOnly={readOnly}
        />
    ));

    const showTableBody: boolean | undefined = tableBody && tableBody.length > 0;

    return (
        <>
            {filterBar}
            <TableContainer
                css={css`
                    margin-bottom: 8px;
                    max-width: ${appMaxWidth};

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }

                    ${readOnly &&
                    // This removes the on hover behavior for the table rows
                    `.MuiTableBody-root {
                        .MuiTableRow-root {
                            cursor: unset;
                        }

                        .MuiTableRow-root:hover:not(.Mui-selected) {
                            background-color: ${theme.palette.common.white};

                            .MuiTableCell-root {
                                background-color: ${theme.palette.common.white};
                            }
                        }
                    }`}
                `}
            >
                <Table>
                    <TableHead>{tableHeaders}</TableHead>
                    <TableBody
                        colSpan={headers.length}
                        hidden={!showTableBody}
                        loading={loading}
                        clearFilters={handleClearAllFilters}
                    >
                        {tableBody}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                // @ts-expect-error
                component="div"
                page={offset / limit}
                rowsPerPage={limit}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar
                open={selected.size > 0}
                autoHideDuration={undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SourcerMembersListEditBar
                    creditsPerApproval={creditsPerApproval}
                    status={status}
                    members={selectedSourcerMembers}
                    onClose={handleEditBarClose}
                />
            </Snackbar>
        </>
    );
};

export { SourcerMembersListTable };
