import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { EmailAccountStatusEnum, SendFromAccountStatusEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { AlertTriangle } from 'shared/components/svgs';
import { SequenceData } from 'shared/graphql/sequences';
import { useSession, useSettings } from 'shared/hooks';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EmailDisconnectedAlertProps {
    sequence: SequenceData;
}

const EmailDisconnectedAlert: FC<EmailDisconnectedAlertProps> = ({ sequence }) => {
    const translate = useTranslations('sequence');
    const { session } = useSession();
    const theme = useTheme();
    const { openPopover } = useSettings();

    let alertMsg;
    let buttonText;

    const disconnectedAccount = sequence.stages.find(
        (stage) =>
            stage.sendFromAccount?.status === SendFromAccountStatusEnum.Disconnected ||
            stage.sendFromAccount?.status === SendFromAccountStatusEnum.Removed ||
            stage.sendFromAccount?.email_account?.status === EmailAccountStatusEnum.AuthError
    )?.sendFromAccount;

    if (disconnectedAccount?.email_account.user.id === session?.user.id) {
        alertMsg = translate('details.sequence-disconnected-alert-to-owner');
        buttonText = translate('details.sequence-disconnected-alert-button-label');
    } else {
        alertMsg = translate.rich('details.sequence-disconnected-alert-to-collaborator', {
            email: disconnectedAccount?.email,
            owner: disconnectedAccount?.email_account.user.fullName,
            bold: Bold,
        });
    }

    return alertMsg ? (
        <Box
            css={css`
                margin-bottom: ${spacing.space14px};
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                min-height: 76px;
                background-color: ${theme.palette.error.main};
                border-radius: ${spacing.space4px};
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                padding: ${spacing.space16px} ${spacing.space24px};

                color: ${theme.palette.common.white};
                font-size: ${fontSizes.f16};
                font-weight: ${fontWeights.medium};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Box>
                    <AlertTriangle height={20} width={20} stroke={theme.palette.common.white} />
                </Box>

                <Box
                    css={css`
                        margin-left: ${spacing.space12px};
                    `}
                >
                    {alertMsg}
                </Box>
            </Box>

            {buttonText && (
                <Button
                    css={css`
                        white-space: nowrap;
                        padding: ${spacing.space10px} ${spacing.space16px};
                        margin-left: ${spacing.space20px};
                        gap: ${spacing.space8px};
                        min-width: unset;
                    `}
                    variant="outlined"
                    color="inherit"
                    onClick={() => openPopover('account-and-emails')}
                >
                    {buttonText}
                </Button>
            )}
        </Box>
    ) : null;
};

export { EmailDisconnectedAlert };

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);
