import { css } from '@mui/material';

import {
    CoworkerConnectionSentence,
    Notepad,
    ProjectSequenceAtsOverview,
    ProspectEmail,
    ScrollSectionEnum,
    WorkSection,
} from 'prospects';
import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface ProfileTabProps {
    anchor: HTMLElement;
    scrollToSection?: ScrollSectionEnum;
    onScroll: () => void;
}

const ProfileTab: FC<ProfileTabProps> = (props) => {
    const { anchor, scrollToSection, onScroll } = props;

    return (
        <Box
            css={css`
                padding: 0px 24px 24px;
            `}
        >
            <ProspectEmail anchor={anchor} />
            <WorkSection />
            <Notepad />
            <CoworkerConnectionSentence />
            <ProjectSequenceAtsOverview scrollToSection={scrollToSection} onScroll={onScroll} />
        </Box>
    );
};

export { ProfileTab };
