import { jwtVerify } from 'jose';

import { config } from 'config';

const {
    JWT: { algorithm, audience, issuer, signingKey },
} = config;

const hasuraClaimsNamespace = 'https://hasura.io/jwt/claims';

export interface JWTToken {
    userId?: string;
    [hasuraClaimsNamespace]?: {
        'x-hasura-allowed-roles': string[];
        'x-hasura-default-role': string;
        'x-hasura-user-id': string;
        'x-hasura-team-id': string;
    };
}

export const verifyJwt = async (jwt: string): Promise<JWTToken> => {
    const verifyOpts = {
        algorithms: [algorithm],
        audience,
        issuer,
    };
    const { payload } = await jwtVerify(jwt, new TextEncoder().encode(signingKey), verifyOpts);
    const hasuraClaims: JWTToken['https://hasura.io/jwt/claims'] = payload[
        hasuraClaimsNamespace
    ] as JWTToken['https://hasura.io/jwt/claims'];
    if (
        payload.userId &&
        hasuraClaims &&
        hasuraClaims['x-hasura-allowed-roles'] &&
        hasuraClaims['x-hasura-default-role'] &&
        hasuraClaims['x-hasura-user-id'] &&
        hasuraClaims['x-hasura-team-id']
    ) {
        return payload as JWTToken;
    }
    return {};
};
