import styled from '@emotion/styled';
import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material';

import { FC } from 'shared/types';

interface PopoverProps extends MuiPopoverProps {}

const Popover: FC<PopoverProps> = (props) => <MuiPopover {...props} />;

const StyledPopover = styled(Popover)`
    border-radius: 4px;
    .MuiPopover-paper {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    }
`;

export type { PopoverProps };
export { StyledPopover as Popover };
