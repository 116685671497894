import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box, ButtonGroup } from 'shared/components/containers';
import { Button } from 'shared/components/presentational/button';
import { FilterButton } from 'shared/components/presentational/filter-button';
import { Popover } from 'shared/components/presentational/popover';
import { XClose } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

const dates = ['today', 'yesterday', '7d', '14d', '1m', '3m', '12m'] as const;
type DateOptions = typeof dates[number];

const getDateFrom = (dateOption: DateOptions) => {
    const dateOptionDate = new Date();
    /* eslint-disable no-magic-numbers */
    switch (dateOption) {
        case 'today':
            dateOptionDate.setDate(dateOptionDate.getDate() - 1);
            break;
        case 'yesterday':
            dateOptionDate.setDate(dateOptionDate.getDate() - 2);
            break;
        case '7d':
            dateOptionDate.setDate(dateOptionDate.getDate() - 7);
            break;
        case '14d':
            dateOptionDate.setDate(dateOptionDate.getDate() - 14);
            break;
        case '1m':
            dateOptionDate.setMonth(dateOptionDate.getMonth() - 1);
            break;
        case '3m':
            dateOptionDate.setMonth(dateOptionDate.getMonth() - 3);
            break;
        case '12m':
            dateOptionDate.setMonth(dateOptionDate.getMonth() - 12);
            break;
        default:
    }
    /* eslint-enable no-magic-numbers */
    return dateOptionDate;
};

interface DateFilterProps {
    label: string;
    current?: DateOptions;
    onDateClick: (value: DateOptions) => () => void;
    onClearFilter: () => void;
}

const DateFilter: FC<DateFilterProps> = ({ label, current, onDateClick, onClearFilter }) => {
    const translate = useTranslations('date-filter');

    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement>();

    const isActive = Boolean(current);

    const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    return (
        <>
            <FilterButton
                state={popoverAnchor ? 'hover' : isActive ? 'active' : 'default'}
                onClick={handleFilterButtonClick}
            >
                {label}
            </FilterButton>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(undefined)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                    `}
                >
                    <ButtonGroup variant="outlined">
                        {dates.map((dateOption) => (
                            <Button
                                key={dateOption}
                                onClick={onDateClick(dateOption)}
                                variant={dateOption === current ? 'contained' : 'outlined'}
                            >
                                {translate(dateOption)}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={onClearFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export type { DateOptions };
export { DateFilter, getDateFrom };
