import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { Button, Hyperlink, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { ARCHIVE_USER_ARTICLE_URL } from 'shared/constants';
import { colors, fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

export interface UserEmailAccounts {
    userId: string;
    email: string;
    fullName: string;
}
interface InviteAlertModalProps {
    open: boolean;
    onClose: () => void;
    archivedMembers: UserEmailAccounts[];
    existingMembers: UserEmailAccounts[];
}

const InviteAlertModal: FC<InviteAlertModalProps> = (props) => {
    const { open, onClose, archivedMembers, existingMembers } = props;

    const theme = useTheme();
    const translate = useTranslations('settings.team-settings.invite-alert-modal');

    const getMemberDetails = (members: UserEmailAccounts[]) => (
        <Box
            css={css`
                color: ${theme.palette.grey[600]};
            `}
        >
            <ul>
                {members.map((member) => (
                    <li key={member.userId}>
                        <Box
                            css={css`
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            `}
                        >
                            {translate('member-details', {
                                email: member.email,
                                fullName: member.fullName,
                            })}
                        </Box>
                    </li>
                ))}
            </ul>
        </Box>
    );

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    padding: 24px;
                `}
            >
                <ModalCloseButton onClose={onClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('title')}
                </Title>

                {existingMembers.length > 0 && (
                    <Box>
                        {translate('description-existing-members')}
                        {getMemberDetails(existingMembers)}
                    </Box>
                )}

                {archivedMembers.length > 0 && (
                    <Box>
                        {translate.rich('description-archived-members', { link: Link })}
                        {getMemberDetails(archivedMembers)}
                    </Box>
                )}
                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 16px;
                    `}
                >
                    <Button variant="contained" color="error" onClick={onClose}>
                        {translate('confirm-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: ${colors.greens.eucalyptus};
        `}
        href={ARCHIVE_USER_ARTICLE_URL}
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

export { InviteAlertModal };
