import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { MenuPinned, MenuTitle } from 'shared/components/navigational';
import { List } from 'shared/components/presentational';
import { Circle, Folder, SequenceIcon, Target } from 'shared/components/svgs';
import {
    GET_PINNED_SEQUENCES,
    GET_PINNED_SOURCERS,
    GET_PINNED_PROJECTS,
    GET_USER_PINS,
    PinsType,
} from 'shared/graphql/user-pins';
import { useSession } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface PinnableType {
    id: string;
    title: string;
    color: string;
}

interface UserPinsProps {}

const UserPins: FC<UserPinsProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('side-bar');
    const { session, loaded } = useSession();
    const [sequenceIds, setSequenceIds] = useState<string[]>([]);
    const [sourcerIds, setSourcerIds] = useState<string[]>([]);
    const [projectIds, setProjectIds] = useState<string[]>([]);

    const { data } = useQuery<PinsType>(GET_USER_PINS, {
        skip: !loaded || !session,
    });

    const { data: sequencesData } = useQuery<{ sequences: PinnableType[] }>(GET_PINNED_SEQUENCES, {
        skip: !loaded || !session,
        variables: {
            where: { id: { _in: sequenceIds } },
        },
        fetchPolicy: 'cache-and-network',
    });

    const { data: sourcersData } = useQuery<{ sourcers: PinnableType[] }>(GET_PINNED_SOURCERS, {
        skip: !loaded || !session,
        variables: {
            where: { id: { _in: sourcerIds } },
        },
        fetchPolicy: 'cache-and-network',
    });

    const { data: projectsData } = useQuery<{ projects: PinnableType[] }>(GET_PINNED_PROJECTS, {
        skip: !loaded || !session,
        variables: {
            where: { id: { _in: projectIds } },
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data && data.pins) {
            const seqIds = data?.pins.filter((p) => p.targetKind === 'sequences').map((p) => p.targetId);
            if (seqIds && seqIds.length > 0) {
                setSequenceIds(seqIds);
            }

            const srcIds = data?.pins.filter((p) => p.targetKind === 'sourcers').map((p) => p.targetId);
            if (srcIds && srcIds.length > 0) {
                setSourcerIds(srcIds);
            }

            const projIds = data?.pins.filter((p) => p.targetKind === 'projects').map((p) => p.targetId);
            if (projIds && projIds.length > 0) {
                setProjectIds(projIds);
            }
        }
    }, [data]);

    const pins =
        data?.pins.map((p) => {
            switch (p.targetKind) {
                case 'sequences':
                    return {
                        name: 'pin',
                        text: sequencesData?.sequences.find((s) => s.id === p.targetId)?.title ?? '',
                        href: `/sequences/${p.targetId}`,
                        icon: SequenceIcon,
                        color:
                            sequencesData?.sequences.find((s) => s.id === p.targetId)?.color ??
                            theme.palette.common.black,
                    };
                case 'sourcers':
                    return {
                        name: 'pin',
                        text: sourcersData?.sourcers.find((s) => s.id === p.targetId)?.title ?? '',
                        href: `/sourcing/${p.targetId}`,
                        icon: Target,
                        color:
                            sourcersData?.sourcers.find((s) => s.id === p.targetId)?.color ??
                            theme.palette.common.black,
                    };
                case 'projects':
                    return {
                        name: 'pin',
                        text: projectsData?.projects.find((s) => s.id === p.targetId)?.title ?? '',
                        href: `/projects/${p.targetId}`,
                        icon: Folder,
                        color:
                            projectsData?.projects.find((s) => s.id === p.targetId)?.color ??
                            theme.palette.common.black,
                    };
                default:
                    return {
                        name: 'pin',
                        text: 'Unknown',
                        href: '#',
                        icon: Circle,
                        color: theme.palette.common.black,
                    };
            }
        }) ?? [];

    return pins.length > 0 ? (
        <>
            <Box
                css={css`
                    @media (max-width: 1000px) {
                        display: none;
                    }
                `}
            >
                <MenuTitle>{translate('pinned')}</MenuTitle>
            </Box>
            <Box
                css={css`
                    @media (min-width: 1000px) {
                        display: none;
                    }
                `}
            >
                <MenuTitle>{translate('pins')}</MenuTitle>
            </Box>
            <List
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    gap: ${spacing.space4px};
                `}
                type="unordered"
            >
                {pins.map((menuItem, index: number) => (
                    <MenuPinned {...menuItem} key={index} />
                ))}
            </List>
        </>
    ) : null;
};

export { UserPins };
