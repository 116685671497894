import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedCheckboxChecked: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M11.3327 5.5L6.74935 10.0833L4.66602 8"
            stroke={stroke ?? theme?.palette?.secondary?.dark}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect x="0.5" y="0.5" width="15" height="15" rx="0.5" stroke={stroke ?? theme?.palette?.secondary?.dark} />
    </svg>
);

const CheckboxChecked = withTheme(ThemedCheckboxChecked);

export { CheckboxChecked };
