import { Global, css, withTheme } from '@emotion/react';

import { fontFamilies, fontSizes, fontWeights } from 'shared/settings/fonts';
import { FC } from 'shared/types';
import 'normalize.css/normalize.css';
import 'react-day-picker/dist/style.css';

const GlobalStylesContainer: FC<unknown> = (props) => {
    const { theme } = props;

    return (
        <Global
            styles={css`
                html {
                    font-size: ${fontSizes.baseFontSize};
                    scroll-behavior: smooth;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }
                body {
                    background: ${theme?.palette.background.default};
                    font-size: ${fontSizes.f16};
                    font-family: ${fontFamilies.inter};
                    color: ${theme?.palette.common.black};
                    line-height: 1.15;
                }
                a,
                button {
                    cursor: pointer;
                    color: inherit;
                }
                a {
                    text-decoration: none;
                }
                strong {
                    font-weight: ${fontWeights.semiBold};
                }
                p {
                    line-height: 1.5;
                    margin-bottom: 10px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
                .select__menu {
                    z-index: 99 !important;
                }
            `}
        />
    );
};

const GlobalStyles = withTheme(GlobalStylesContainer);

export { GlobalStyles };
