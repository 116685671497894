import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { GetUserRoleQuery, RecipientStatusEnum, UpdateRecipientsMutation, UserTeamQuery } from 'codegen/graphql';
import { AddMultipleProspectsToProject } from 'prospects';
import { AddMultipleRecipientsToSequence } from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { FolderPlus, SequenceIcon, StopCircle, XClose } from 'shared/components/svgs';
import { GET_RECIPIENTS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { SequenceData } from 'shared/graphql/sequences';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl, useConfirmationModal, useSession, useSnackbarAlert } from 'shared/hooks';
import { RecipientSequenceStopped } from 'shared/services';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC, RecipientsQueryData } from 'shared/types';

interface RecipientListEditBarProps {
    sequence: SequenceData;
    recipients: RecipientsQueryData[];
    onClose(): void;
}

const RecipientListEditBar: FC<RecipientListEditBarProps> = React.forwardRef(
    ({ recipients, onClose, sequence }, ref) => {
        const { canEditSequence, canAddToProjectFromExtension, canAddToSequenceFromExtension } = useAccessControl();
        const translate = useTranslations('sequence.recipient-list-table.edit-bar');

        const theme = useTheme();
        const { loaded, session } = useSession();

        const { showModal, hideModal, startModalLoading, stopModalLoading } = useConfirmationModal();
        const { showSnackbarAlert } = useSnackbarAlert();

        const { data } = useQuery<UserTeamQuery>(GET_USER_TEAM);

        const { data: userRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
            skip: !loaded || !session,
            variables: { userId: session?.user?.id },
            fetchPolicy: 'network-only',
        });

        const [addToSequence, setAddToSequence] = useState(false);
        const [openAddProspectsToProject, setOpenAddProspectsToProject] = useState<boolean>(false);

        const [updateRecipients] = useMutation<UpdateRecipientsMutation>(UPDATE_RECIPIENTS, {
            refetchQueries: [getOperationName(GET_RECIPIENTS) as string],
        });

        const canAddToProjects =
            data?.teams?.[0]?.planType && userRoleData?.user_roles_by_pk?.licenseType
                ? canAddToProjectFromExtension(data.teams[0].planType, userRoleData.user_roles_by_pk.licenseType)
                : false;
        const canAddToSequence =
            data?.teams?.[0]?.planType && userRoleData?.user_roles_by_pk?.licenseType
                ? canAddToSequenceFromExtension(data.teams[0].planType, userRoleData.user_roles_by_pk.licenseType)
                : false;

        const prospectIds = recipients.map((recipient) => recipient.prospectId);

        const handleStopConfirm = async () => {
            startModalLoading();
            const prospectsNotInStoppedStatus = recipients
                .filter((r) => !RecipientSequenceStopped.includes(r.status))
                .map((recipient) => recipient.prospectId);

            await updateRecipients({
                variables: {
                    where: {
                        prospectId: { _in: prospectsNotInStoppedStatus },
                        sequenceId: { _eq: sequence.id },
                    },
                    set: {
                        status: RecipientStatusEnum.ManuallyExited,
                        manuallyExitedAt: Date.now(),
                    },
                },
            });
            showSnackbarAlert({
                message: translate('stop-modal.success-alert', {
                    count: prospectsNotInStoppedStatus.length,
                    sequence: sequence.title,
                }),
            });
            handleClose();
            hideModal();
            stopModalLoading();
        };

        const handleClose = () => {
            onClose();
        };

        const handleStop = () => {
            showModal({
                title: translate('stop-modal.title', { count: prospectIds.length }),
                description: translate('stop-modal.description'),
                cancelButton: {
                    text: translate('stop-modal.cancel-label'),
                    onClick: hideModal,
                },
                confirmButton: {
                    text: translate('stop-modal.stop-sequence-label'),
                    onClick: handleStopConfirm,
                },
            });
        };

        const handleAddToProjectOpen = () => {
            setOpenAddProspectsToProject(true);
        };

        const handleAddToProjectClose = () => {
            setOpenAddProspectsToProject(false);
            handleClose();
        };

        const handleAddToSequence = () => setAddToSequence(true);

        const handleCloseAddToSequence = () => {
            setAddToSequence(false);
            handleClose();
        };

        return (
            <>
                <Box
                    css={css`
                        display: flex;
                        align-items: center;

                        width: 1035px;
                        background-color: ${theme.palette.grey[200]};
                        padding: 20px 25px;
                        border-radius: 4px;
                        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                        color: ${theme.palette.common.white};
                        font-weight: ${fontWeights.bold};
                    `}
                    ref={ref}
                >
                    <Box
                        css={css`
                            margin-right: auto;
                            font-size: ${fontSizes.f24};
                        `}
                    >
                        {translate('item-selected', { count: prospectIds.length })}
                    </Box>
                    <Box
                        css={css`
                            display: flex;
                            font-size: ${fontSizes.f16};
                        `}
                    >
                        <Button
                            startIcon={<FolderPlus />}
                            css={css`
                                color: ${theme.palette.common.white};
                            `}
                            disabled={!canAddToProjects}
                            onClick={handleAddToProjectOpen}
                            disableRipple
                        >
                            {translate('add-to-project-label')}
                        </Button>
                        <Button
                            startIcon={<SequenceIcon stroke={theme.palette.common.white} />}
                            css={css`
                                color: ${theme.palette.common.white};
                            `}
                            disabled={!canAddToSequence}
                            onClick={handleAddToSequence}
                            disableRipple
                        >
                            {translate('add-to-sequence-label')}
                        </Button>
                        {canEditSequence(sequence) && (
                            <Button
                                hidden={!canEditSequence(sequence)}
                                startIcon={<StopCircle />}
                                css={css`
                                    color: ${theme.palette.common.white};
                                `}
                                onClick={handleStop}
                                disableRipple
                            >
                                {translate('stop-label')}
                            </Button>
                        )}

                        <Button
                            startIcon={<XClose stroke={theme.palette.common.white} />}
                            onClick={handleClose}
                            disableRipple
                        />
                    </Box>
                </Box>
                <AddMultipleRecipientsToSequence
                    open={addToSequence}
                    prospectIds={prospectIds}
                    sourceSequenceId={sequence.id}
                    onClose={handleCloseAddToSequence}
                />
                <AddMultipleProspectsToProject
                    open={openAddProspectsToProject}
                    onClose={handleAddToProjectClose}
                    prospectIds={prospectIds}
                />
            </>
        );
    }
);

export { RecipientListEditBar };
