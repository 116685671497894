import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Button, ButtonProps } from 'shared/components/presentational/button';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

export const SubmenuButton: FC<ButtonProps> = ({ children, ...rest }) => {
    const theme = useTheme();
    return (
        <Button
            css={css`
                display: block;
                width: 100%;
                height: auto;
                font-size: ${fontSizes.f14};
                text-align: left;
                color: ${theme.palette.common.black};
                padding-right: 22px;
                padding-left: 10px;
                border-bottom: 1px solid ${theme.palette.grey[100]};

                &.Mui-disabled {
                    border-bottom: 1px solid ${theme.palette.grey[100]};
                }
            `}
            disableRipple
            {...rest}
        >
            {children}
        </Button>
    );
};
