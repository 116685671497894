import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface TemplateCrashProps {}

const TemplateCrash: FC<TemplateCrashProps> = () => {
    const translate = useTranslations('errors.crash');

    useEffect(() => {
        throw new Error(translate('throw-error'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Box>{translate('throw-error')}</Box>;
};

export { TemplateCrash };
