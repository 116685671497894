import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { TextFieldIconButton } from 'shared/components/presentational';
import { Send01 } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface MessageInputProps {
    onMessageSend: (text?: string) => void;
}

const MessageInput: FC<MessageInputProps> = (props) => {
    const theme = useTheme();
    const { onMessageSend } = props;

    const handleSend = (value: string) => {
        onMessageSend(value);
    };

    return (
        <Box>
            <TextFieldIconButton
                onButtonClick={handleSend}
                icon={
                    <Box
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 4px;
                            background-color: ${theme.palette.primary.main};
                        `}
                    >
                        <Send01 stroke={theme.palette.common.white} height={16} width={16} />
                    </Box>
                }
            />
        </Box>
    );
};

export { MessageInput };
