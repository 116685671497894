import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { SelectChangeEvent } from '@mui/material';
import { useTranslations } from 'next-intl';

import { LicenseTypesEnum, PlanTypesEnum, TeamRolesEnum, UpdateUserRoleMutation } from 'codegen/graphql';
import { UsersListRow } from 'settings/components/presentational/team/users-list-row';
import { List } from 'shared/components/presentational';
import { GET_TEAM_MEMBERS, TeamMember, roleLabelsType } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { GET_USER_BY_ID, UPDATE_USER_ROLE } from 'shared/graphql/users';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { FC } from 'shared/types';

interface UsersListProps {
    seatsAvailable: number;
    seatsUsed: number;
    currentUserRole: TeamRolesEnum;
    members: TeamMember[];
    roleOptions: Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;
    planType: PlanTypesEnum;
}

export const UsersList: FC<UsersListProps> = ({
    seatsAvailable,
    seatsUsed,
    currentUserRole,
    members,
    roleOptions,
    planType,
}) => {
    const { session } = useSession();
    const translate = useTranslations('settings.team-settings');

    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateMemberRole] = useMutation<UpdateUserRoleMutation>(UPDATE_USER_ROLE, {
        refetchQueries: [
            getOperationName(GET_TEAM_MEMBERS) as string,
            getOperationName(GET_USER_BY_ID) as string,
            getOperationName(GET_USER_ROLE) as string,
        ],
    });

    const handleRoleChanged = (id: string) => async (event: SelectChangeEvent<any>) => {
        const role = [TeamRolesEnum.Owner, TeamRolesEnum.Admin].includes(event.target.value)
            ? event.target.value
            : TeamRolesEnum.User;

        const licenseType =
            event.target.value === LicenseTypesEnum.Reviewer ? LicenseTypesEnum.Reviewer : LicenseTypesEnum.Full;

        await updateMemberRole({ variables: { userId: id, set: { role, licenseType } } });
        if (event.target.value === TeamRolesEnum.Owner) {
            await updateMemberRole({
                variables: {
                    userId: session?.user.id,
                    set: {
                        role: planType === PlanTypesEnum.Solo ? TeamRolesEnum.User : TeamRolesEnum.Admin,
                        licenseType: LicenseTypesEnum.Full,
                    },
                },
            });
        }

        const label = roleOptions.find((r) => r.value === event.target.value)?.label as roleLabelsType;

        showSnackbarAlert({
            severity: 'success',
            message: translate('role-updated', {
                role: translate(label),
            }),
        });
    };

    const membersList = members.map((member, i) => (
        <UsersListRow
            key={i}
            seatsUsed={seatsUsed}
            currentUserRole={currentUserRole}
            member={member}
            roleOptions={roleOptions}
            onRoleChange={handleRoleChanged}
            planType={planType}
            currentUserLicenseType={member.licenseType}
            seatsAvailable={seatsAvailable}
        />
    ));

    return <List type="unordered">{membersList}</List>;
};
