const colors = {
    neutrals: {
        black: '#000',
        white: '#fff',
    },
    blues: {
        cello: '#1D5661',
        eden: '#0F4A54',
    },
    greens: {
        eucalyptus: '#239558',
        emerald: '#55BC7C',
        hintOfGreen: '#DCFEEA',
        jewel_100: '#136F36',
        jewel_200: '#198442',
        keppel: '#36A38D',
        narvik: '#ECF9F1', // narvik 50
        narvik30: '#ECF9F14D',
    },
    grays: {
        miscka: '#DADEE3',
        tundora: '#4D4D4D',
        wildSand: '#F6F6F6',
        alabaster: '#FAFAFA',
        gallery: '#EBEBEB',
        dusty: '#999999',
        titanWhite: '#F8F8F8',
    },
    reds: {
        persianRed: '#CB2929',
        persianRed10: '#CB29291A',
    },
    yellows: {
        picasso: '#FFF6A2',
        picasso20: '#FFF6A233',
        picasso100: '#FFF6A2',
        tulipTree: '#EDB742',
    },
    purples: {
        veronica: '#9747FF',
    },
};

const colorPickerOptions: string[] = [
    '#CD3232', // Red
    '#F46D43', // Orange
    '#FDAE61', // Light orange
    '#FEE08B', // Yellow
    '#ABDDA4', // Green
    '#66C2B7', // Cyan
    '#3288BD', // Blue
    '#A43AAF', // Pink
    '#5E4FA2', // Purple
    '#000000', // Black
];

export { colors, colorPickerOptions };
