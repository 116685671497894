import styled from '@emotion/styled';
import { TableFooter as MuiTableFooter, TableFooterProps as MuiTableFooterProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableFooterProps extends MuiTableFooterProps {}

const TableFooter: FC<TableFooterProps> = (props) => <MuiTableFooter {...props} />;

const StyledTableFooter = styled(TableFooter)``;

export { StyledTableFooter as TableFooter };
