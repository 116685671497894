/* eslint-disable no-magic-numbers */
import { convertPixelsToRem } from 'shared/services';

const baseFontSize = '10px';

const getFontSize = (fontSize: number): string => convertPixelsToRem(`${fontSize}px`, baseFontSize);

const fontFamilies = {
    inter: 'Inter, sans-serif',
    montserrat: 'Montserrat, sans-serif;',
    openSans: '"Open Sans", sans-serif',
    poppins: 'Poppins, sans-serif',
};

const fontSizes = {
    baseFontSize,
    f12: getFontSize(12),
    f14: getFontSize(14),
    f16: getFontSize(16),
    f18: getFontSize(18),
    f20: getFontSize(20),
    f22: getFontSize(22),
    f24: getFontSize(24),
    f26: getFontSize(26),
    f28: getFontSize(28),
    f30: getFontSize(30),
    f32: getFontSize(32),
    f36: getFontSize(36),
    f38: getFontSize(38),
    f40: getFontSize(40),
    f46: getFontSize(46),
    f50: getFontSize(50),
    f54: getFontSize(54),
};

const fontWeights = {
    light: '300',
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
};

export { fontFamilies, fontWeights, fontSizes };
