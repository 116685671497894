import { useTranslations } from 'next-intl';

import { RecipientResponseSentimentTypesEnum, RecipientStatusEnum } from 'codegen/graphql';
import { dateFormatter } from 'shared/services';

export const useRecipientStatus = () => {
    const translate = useTranslations('shared.recipient-status');
    const getStatusTranslation = (recipient: {
        status: RecipientStatusEnum;
        lastStageSent: number;
        completedAt?: number | null;
        lastStageSentAt?: number | null;
        manuallyExitedAt?: number | null;
        meetingScheduledAt?: number | null;
        respondedAt?: number | null;
        recipientResponses: { sentiment?: RecipientResponseSentimentTypesEnum | null }[];
    }) => {
        const sentiment = recipient.recipientResponses[0]?.sentiment;
        switch (recipient.status) {
            case RecipientStatusEnum.CompletedWithNoReply:
                return {
                    status: translate('completed-with-no-reply'),
                    statusDate: dateFormatter(recipient.completedAt ?? 0),
                };

            case RecipientStatusEnum.FindingEmail:
                return { status: translate('finding-email') };

            case RecipientStatusEnum.InProgress:
                return {
                    status: translate('in-progress-stage', { stage: recipient.lastStageSent }),
                    statusDate: dateFormatter(recipient.lastStageSentAt ?? 0),
                };

            case RecipientStatusEnum.ManuallyExited:
                return {
                    status: translate('manually-exited'),
                    statusDate: dateFormatter(recipient.manuallyExitedAt ?? 0),
                };

            case RecipientStatusEnum.MeetingBooked:
                return {
                    status: translate('meeting-booked'),
                    statusDate: dateFormatter(recipient.meetingScheduledAt ?? 0),
                };

            case RecipientStatusEnum.NotStarted:
                return { status: translate('not-started') };

            case RecipientStatusEnum.Responded: {
                if (!sentiment || sentiment === RecipientResponseSentimentTypesEnum.Unknown) {
                    return {
                        status: translate('responded'),
                        statusDate: dateFormatter(recipient.respondedAt ?? 0),
                    };
                }
                return {
                    status: translate('responded-sentiment', {
                        sentiment: translate(`${sentiment}`),
                    }),
                    statusDate: dateFormatter(recipient.respondedAt ?? 0),
                };
            }

            case RecipientStatusEnum.UnableToFindContact:
                return { status: translate('unable-to-find-contact') };

            case RecipientStatusEnum.UnableToSendMessage:
                return { status: translate('unable-to-send-message') };

            case RecipientStatusEnum.Unsubscribed:
                return { status: translate('unsubscribed') };

            case RecipientStatusEnum.MissingTemplateVariables:
                return { status: translate('missing-template-variables') };
            default:
                return { status: '' };
        }
    };
    return { getStatusTranslation };
};
