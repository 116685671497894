import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedIcon: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg {...rest} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M42.6668 48L50.6668 40M50.6668 40L58.6668 48M50.6668 40V56M32.0002 41.3333H20.0002C16.2787 41.3333 14.4179 41.3333 12.9038 41.7926C9.49471 42.8268 6.82694 45.4945 5.7928 48.9036C5.3335 50.4177 5.3335 52.2785 5.3335 56M38.6668 20C38.6668 26.6274 33.2942 32 26.6668 32C20.0394 32 14.6668 26.6274 14.6668 20C14.6668 13.3726 20.0394 8 26.6668 8C33.2942 8 38.6668 13.3726 38.6668 20Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const NoTeamSupportIcon = withTheme(ThemedIcon);

export { NoTeamSupportIcon };
