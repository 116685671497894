import { css } from '@emotion/react';
import { useState } from 'react';

import { FilterButton } from 'shared/components/presentational/filter-button';
import {
    PercentageRangePopover,
    percentageSliderRange,
} from 'shared/components/presentational/percentage-range-popover';
import { FC } from 'shared/types';

interface PercentageRangeFilterProps {
    label: string;
    range: [number, number];
    onRangeChange: (newRange: [number, number]) => void;
}

const PercentageRangeFilter: FC<PercentageRangeFilterProps> = ({ label, range, onRangeChange }) => {
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement>();

    const isActive = range[0] !== percentageSliderRange[0] || range[1] !== percentageSliderRange[1];

    const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    const handlePopoverClose = () => setPopoverAnchor(undefined);

    const handleRangeChange = (newInterestedRange: [number, number]) => {
        onRangeChange(newInterestedRange);
    };

    return (
        <>
            <FilterButton
                state={popoverAnchor ? 'hover' : isActive ? 'active' : 'default'}
                onClick={handleFilterButtonClick}
            >
                {label}
            </FilterButton>
            <PercentageRangePopover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
                range={range}
                onRangeChange={handleRangeChange}
            />
        </>
    );
};

export { PercentageRangeFilter };
