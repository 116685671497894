import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { PaymentMethodInfo } from 'hireflow-shared/types/stripe/payment-method-info';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { TeamRolesEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Title } from 'shared/components/presentational';
import { CreditCard02 } from 'shared/components/svgs';
import { useAccessControl } from 'shared/hooks';
import { capitalizeFirstLetter, createRestApiClient } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC, UserTeam } from 'shared/types';

interface PaymentMethodsProps {
    team: UserTeam;
    role: TeamRolesEnum;
}

const PaymentMethods: FC<PaymentMethodsProps> = (props) => {
    const { team, role } = props;
    const translate = useTranslations('settings.plans-and-billing-settings');
    const theme = useTheme();

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo>();
    const restApiClient = createRestApiClient();

    const { canEditBillingInformation } = useAccessControl();

    const handlePaymentMethodChange = async () => {
        const path = window.location.pathname;
        const customerId = team?.customerId;
        if (customerId) {
            const checkoutSession = await restApiClient.createCheckoutSession({ customerId, path });
            window.location.replace(checkoutSession?.data.session.url);
        }
    };

    useEffect(() => {
        const fetchPaymentMethod = async (customerId: string) => {
            const stripePaymentInfo = await restApiClient.stripePaymentMethod({
                customerId,
            });
            if (stripePaymentInfo.data) setPaymentMethod(stripePaymentInfo.data.paymentMethod);
        };
        if (team?.customerId) {
            fetchPaymentMethod(team.customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.customerId]);

    const paymentSection = paymentMethod ? (
        <Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    column-gap: ${spacing.space10px};
                `}
            >
                <CreditCard02 />
                <Box>
                    {translate('card-ending-in', {
                        brand: capitalizeFirstLetter(paymentMethod.brand),
                        endingNumber: paymentMethod.last4,
                    })}
                </Box>
            </Box>
            <Box
                css={css`
                    margin-left: ${spacing.space32px};
                    font-size: ${fontSizes.f14};
                `}
            >
                {translate('expiry-date', {
                    month: paymentMethod.expiryMonth,
                    // eslint-disable-next-line no-magic-numbers
                    year: String(paymentMethod.expiryYear % 100).slice(-2),
                })}{' '}
            </Box>
        </Box>
    ) : (
        <>{translate('not-available')}</>
    );

    const canEditBilling = canEditBillingInformation(role);

    return (
        <Box>
            <Title type="h2">{translate('payment-methods')}</Title>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-left: ${spacing.space16px};
                `}
            >
                <Box>
                    <Title
                        type="h4"
                        css={css`
                            font-size: 20px;
                            line-height: 30px;
                        `}
                    >
                        {translate('credit-card')}
                    </Title>
                    <p
                        css={css`
                            font-weight: ${fontWeights.normal};
                            font-size: ${fontSizes.f16};
                            line-height: 19px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        {paymentSection}
                    </p>
                </Box>
                <Box>
                    <Button
                        disabled={!canEditBilling}
                        variant="text"
                        href="/"
                        css={css`
                            font-weight: ${fontWeights.bold};
                            font-size: ${fontSizes.f16};
                            line-height: 19px;
                            color: ${theme.palette.primary.main};
                        `}
                        onClick={handlePaymentMethodChange}
                    >
                        {translate('edit')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export { PaymentMethods };
