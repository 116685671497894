import { css } from '@emotion/react';
import Image from 'next/image';

import { Box } from 'shared/components/containers';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface StepProps {
    number: number;
    completed?: boolean;
}

const Step: FC<StepProps> = ({ number, completed = false }) => (
    <Box
        css={css`
            width: ${spacing.space64px};
            height: ${spacing.space64px};
            background: ${completed
                ? `${colors.grays.miscka}`
                : `linear-gradient(0deg, ${colors.greens.eucalyptus}, ${colors.greens.eucalyptus}),
                ${colors.neutrals.white}`};
            border-radius: 100px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: ${spacing.space10px};
            gap: ${spacing.space10px};

            font-family: ${fontFamilies.poppins};
            font-size: ${fontSizes.f32};
            font-weight: ${fontWeights.bold};
            line-height: 34px;
            color: ${colors.neutrals.white};
        `}
    >
        {completed ? <Image src="/check.svg" alt="checkmark" height={24} width={24} /> : number}
    </Box>
);
export { Step };
