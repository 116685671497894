import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { LeverSourcesQuery } from 'codegen/graphql';
import { ConnectEditFormButtons } from 'integrations/components/composite/lever';
import { TeamSettingsDefaults, TeamSettingsSpacer } from 'integrations/components/presentational';
import { ConnectEditTemplate } from 'integrations/components/templates/lever';
import { OverrideToggleFormValues } from 'integrations/types';
import { CreateEditFormValues } from 'integrations/types/lever';
import { Form } from 'shared/components/form';
import { GET_LEVER_SOURCES, LeverAccountByIdData } from 'shared/graphql/integrations/lever';
import { useDynamicSchemaForm, useSession, useSnackbarAlert } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

interface EditFormProps {
    leverAccount: LeverAccountByIdData;
    onClose: () => void;
    refetch: () => void;
}

const EditForm: FC<EditFormProps> = (props) => {
    const { leverAccount, onClose, refetch } = props;
    const translate = useTranslations('integrations.lever');

    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const validationSchema = Yup.object().shape({
        apiToken: Yup.string().required(translate('error-api-token')),
    });

    const { data: leverSourcesData } = useQuery<LeverSourcesQuery>(GET_LEVER_SOURCES);

    const formInitialValues: CreateEditFormValues & OverrideToggleFormValues = {
        useOauth: leverAccount?.useOauth ?? false,
        syncConfidential: leverAccount?.syncConfidential ?? false,
        previousSyncConfidential: leverAccount?.previousSyncConfidential ?? false,
        apiToken: leverAccount?.apiToken ?? '',
        refreshTokenExpiresAt: leverAccount?.refreshTokenExpiresAt ?? 0,
        defaultSourceId: leverAccount?.defaultSourceId ?? '',
        teamOverride: false,
        invalidApiTokenDetectedAt: leverAccount?.invalidApiTokenDetectedAt ?? 0,
    };

    const formMethods = useDynamicSchemaForm<CreateEditFormValues & OverrideToggleFormValues>({
        mode: 'onTouched',
        defaultValues: formInitialValues,
        schema: validationSchema,
    });

    useEffect(() => {
        if (!leverAccount?.apiToken) return;
        formMethods.setValue('apiToken', leverAccount.apiToken);
    }, [formMethods, leverAccount?.apiToken]);

    useEffect(() => {
        if (!formMethods.getValues('useOauth')) {
            formMethods.setSchema(
                Yup.object().shape({
                    apiToken: Yup.string().required(translate('error-api-token')),
                })
            );
        } else {
            formMethods.setSchema(Yup.object().shape({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.getValues('useOauth')]);

    const handleClose = (): void => {
        formMethods.reset();
        onClose();
    };

    const onSubmit = async () => {
        const values = formMethods.getValues();
        const { apiToken, useOauth, defaultSourceId, syncConfidential, previousSyncConfidential } = values;
        const previousSyncConfidentialValue: boolean = !syncConfidential ? false : previousSyncConfidential;

        const restApiClient = createRestApiClient();
        const connectResponse = await restApiClient.connectLeverToken({
            useOauth,
            apiToken,
            defaultSourceId: defaultSourceId || undefined,
            syncConfidential,
            previousSyncConfidential: previousSyncConfidentialValue,
        });

        if (connectResponse?.success) {
            refetch();
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-saved'),
            });
            // make sure to set this value manually or the changes won't be available without a refresh
            formMethods.setValue('previousSyncConfidential', previousSyncConfidentialValue);
            onClose();
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-saved'),
            });
            formMethods.reset(values);
        }
    };

    const isOwner: boolean = leverAccount?.ownerId === session?.user.id;

    return (
        <Form
            css={css`
                width: 100%;
            `}
            onSubmit={formMethods.handleSubmit(onSubmit)}
        >
            <ConnectEditTemplate isEditing formMethods={formMethods} />
            <TeamSettingsSpacer description={translate('team-settings-info')} />
            <TeamSettingsDefaults formMethods={formMethods} data={leverSourcesData?.data} />
            <ConnectEditFormButtons isEditing canEdit={isOwner} onClose={handleClose} />
        </Form>
    );
};

export { EditForm };
