import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Bold } from 'shared/components/composite';
import { Box, Layout } from 'shared/components/containers';
import { Lock } from 'shared/components/svgs';
import { fontFamilies, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AccessDeniedProps {
    component: 'project' | 'sequence' | 'reviewer';
    componentOwner: string;
}

const AccessDenied: FC<AccessDeniedProps> = (props) => {
    const { component, componentOwner } = props;
    const translate = useTranslations('errors.access-denied');

    const message = translate.rich(`description.${component}`, { componentOwner, bold: Bold });

    return (
        <Layout shouldCenterContentWithBackground breadcrumbs={[{ text: translate('layout-title') }]}>
            <Box
                css={css`
                    text-align: center;
                    padding: ${spacing.space24px};
                `}
            >
                <Box>
                    <Lock />
                </Box>
                <Box
                    css={css`
                        margin-top: ${spacing.space24px};

                        font-family: ${fontFamilies.poppins};
                        font-size: ${fontSizes.f32};
                        line-height: 34px;
                    `}
                >
                    {translate('title')}
                </Box>
                <Box
                    css={css`
                        margin-top: ${spacing.space16px};

                        font-family: ${fontFamilies.inter};
                        font-size: ${fontSizes.f16};
                        line-height: 20px;
                    `}
                >
                    {message}
                </Box>
            </Box>
        </Layout>
    );
};

export { AccessDenied };
