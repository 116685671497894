import { css } from '@mui/material';
import _ from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { SequenceStatusEnum } from 'codegen/graphql';
import { EmptyTabState, MessagesExchanged, MessagesInQueue, RecipientsInErrorStatus } from 'prospects';
import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { Eye } from 'shared/components/svgs';
import { useProspectPanel, useSession } from 'shared/hooks';
import { MissingTemplateVariablesOrUnableToSend, PreviewConversationsForStatus } from 'shared/services';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface ProspectConversationTabProps {
    anchor: HTMLElement;
}

const ProspectConversationTab: FC<ProspectConversationTabProps> = (props) => {
    const { session } = useSession();
    const { anchor } = props;
    const { prospectInfo, recipientsConversations } = useProspectPanel();
    const translate = useTranslations('prospects.tabs.conversations-tab');
    const [queueVisible, setQueueVisible] = useState<boolean>(false);
    const [messagesExchanged, setMessagesExchanged] = useState<boolean>(false);
    const [errorStateRecipients, setErrorStateRecipients] = useState<RecipientConversationsData[]>([]);
    const [recipientsInQueue, setRecipientsInQueue] = useState<RecipientConversationsData[]>([]);

    useEffect(() => {
        if (recipientsConversations) {
            setRecipientsInQueue(
                recipientsConversations.filter(
                    (f) =>
                        PreviewConversationsForStatus.includes(f.status) &&
                        f.sequence.userId === session?.user.id &&
                        f.sequence.status === SequenceStatusEnum.Active
                )
            );

            setMessagesExchanged(
                prospectInfo?.inMailSent ||
                    _.flatten(
                        recipientsConversations.map((r) =>
                            r.recipientMessageSends.filter((f) => !f.email_message.bounceReport)
                        )
                    ).length > 0 ||
                    _.flatten(
                        recipientsConversations.map((r) =>
                            r.recipientResponses.filter((f) => !f.email_message?.bounceReport)
                        )
                    ).length > 0
            );

            setErrorStateRecipients(
                recipientsConversations.filter((f) => MissingTemplateVariablesOrUnableToSend.includes(f.status))
            );
        }
    }, [recipientsConversations, prospectInfo?.inMailSent, session]);

    return (
        <Box
            css={css`
                padding: 0px 24px;
            `}
        >
            {recipientsInQueue.length > 0 && (
                <Box
                    css={css`
                        display: flex;
                    `}
                >
                    {!queueVisible && (
                        <Box
                            css={css`
                                display: flex;
                                margin-top: 24px;
                            `}
                        >
                            <Box
                                css={css`
                                    padding: 2px 0px;
                                    margin-right: 10px;
                                `}
                            >
                                <Eye />
                            </Box>
                            <Box
                                onClick={() => {
                                    trackEvent('click_view_queue', {
                                        prospect_id: prospectInfo?.id,
                                        recipient_ids: recipientsConversations?.map((r) => r.id),
                                    });
                                    setQueueVisible(true);
                                }}
                            >
                                <Hyperlink
                                    href="#"
                                    css={css`
                                        font-size: ${fontSizes.f16};
                                        font-weight: ${fontWeights.semiBold};
                                    `}
                                >
                                    {translate('message-queue-label', {
                                        count: recipientsInQueue
                                            .map((r) => r.sequence.stages.length - r.lastStageSent)
                                            .reduce((a, b) => a + b, 0),
                                    })}
                                </Hyperlink>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            {queueVisible && <MessagesInQueue anchor={anchor} recipientsInQueue={recipientsInQueue} />}
            {errorStateRecipients && <RecipientsInErrorStatus recipients={errorStateRecipients} />}
            {messagesExchanged && (
                <Box>
                    <MessagesExchanged recipients={recipientsConversations ?? []} />
                </Box>
            )}
            {!messagesExchanged && recipientsInQueue.length === 0 && errorStateRecipients.length === 0 && (
                <EmptyTabState tab="conversations" />
            )}
        </Box>
    );
};

export { ProspectConversationTab };
