import { NormalizedCacheObject } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { NextIntlProvider } from 'next-intl';
import NextProgress from 'next-progress';
import { AppProps } from 'next/app';
import { useEffect } from 'react';

import { config } from 'config';
import {
    ArchiveUserCheckProvider,
    ConfirmationModalProvider,
    ProspectSearchProvider,
    SettingsProvider,
    SnackbarAlertProvider,
    VersionProvider,
} from 'shared/hooks';
import { getErrorBoundary } from 'shared/hooks/bugsnag-error-boundary';
import { ApolloProvider } from 'shared/hooks/use-apollo';
import { SessionProvider } from 'shared/hooks/use-session';
import { themes } from 'shared/settings';
import { HasuraRole } from 'shared/types';
import { GlobalStyles } from 'styles/global-styles';

const versionElementId: string = 'app-version';
const versionElementStyle = {
    position: 'fixed',
    width: 0,
    height: 0,
    zIndex: -1,
    color: 'transparent',
};

interface CustomPageProps {
    initialApolloState?: NormalizedCacheObject;
    hasuraRole?: HasuraRole;
    messages?: any;
}

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>) {
    const theme = themes.primaryTheme;
    const ErrorBoundary = getErrorBoundary();

    const embedAppVersionInfo = () => {
        if (document.getElementById(versionElementId)) {
            // don't append if element already exists on the page
            return;
        }

        const prodVersion = document.createElement('div');
        prodVersion.innerText = `Prod version: ${config.AppVersion ?? ''}`;

        const stageVersion = document.createElement('div');
        stageVersion.innerText = `Stage version: ${config.StageVersion ?? ''}`;

        const wrapper = document.createElement('div');
        wrapper.setAttribute('id', versionElementId);
        Object.assign(wrapper.style, versionElementStyle);
        wrapper.appendChild(prodVersion);
        wrapper.appendChild(stageVersion);

        document.body.appendChild(wrapper);
    };

    useEffect(() => {
        embedAppVersionInfo();
    }, []);

    return (
        <ErrorBoundary>
            <SessionProvider>
                <VersionProvider>
                    <ApolloProvider initialState={pageProps.initialApolloState} hasuraRole={pageProps.hasuraRole}>
                        <NextIntlProvider messages={pageProps.messages} locale="en-US">
                            <ThemeProvider theme={theme}>
                                <SnackbarAlertProvider>
                                    <ConfirmationModalProvider>
                                        <SettingsProvider>
                                            <ArchiveUserCheckProvider>
                                                <ProspectSearchProvider>
                                                    <GlobalStyles theme={theme} />
                                                    <NextProgress />
                                                    <Component {...pageProps} />
                                                </ProspectSearchProvider>
                                            </ArchiveUserCheckProvider>
                                        </SettingsProvider>
                                    </ConfirmationModalProvider>
                                </SnackbarAlertProvider>
                            </ThemeProvider>
                        </NextIntlProvider>
                    </ApolloProvider>
                </VersionProvider>
            </SessionProvider>
        </ErrorBoundary>
    );
}

export default MyApp;
