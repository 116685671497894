/* eslint-disable max-len */
// cspell:ignore googleusercontent txqbyjf lgxf elhh jwufnut cdnuilkpdr
const serverConfig = JSON.parse(
    process.env.NEXT_SERVER_CONFIG ||
        `{
          "JWT": {
            "algorithm": "HS256",
            "audience": "zeus",
            "issuer": "zeus",
            "signingKey": "a0awe23f1h1f0mb857txqbyjf9lgxf4s4elhh5hq0njs8e4jwufnut6gl7ox00f"
          }
        }`
);

const publicConfig = JSON.parse(
    process.env.NEXT_PUBLIC_APP_CONFIG ||
        `
    {
      "ApiEndpoint": "http://localhost:9090/v2",
      "AppUrl": "http://localhost:3005",
      "AppVersion": "local",
      "StageVersion": "local",
      "Env": "development",
      "ExtensionLink": "https://chrome.google.com/webstore/detail/hireflow/nlaolmnbnedlgedbaehmgjilbhafpkni",
      "GoogleOAuthAppConfig": {
        "clientId": "658509932363-jh531rnp8cdnuilkpdr6j6skh48etb16.apps.googleusercontent.com"
      },
      "GraphAPIEndpoint": "localhost:9080/v1/graphql",
      "LeverConfig": {
        "sandbox": {
          "connectUri": "https://sandbox-lever.auth0.com/authorize?client_id=Xt6YyyAwhDW4HcuBvDy80aE7A4qpqt9P&redirect_uri=http://localhost:3005/oauth/lever&response_type=code&prompt=consent&scope=applications:read:admin%20archive_reasons:read:admin%20contact:write:admin%20feedback:read:admin%20files:write:admin%20interviews:read:admin%20notes:write:admin%20offers:read:admin%20opportunities:write:admin%20postings:write:admin%20resumes:read:admin%20sources:read:admin%20stages:read:admin%20tags:read:admin%20uploads:write:admin%20users:read:admin%20offline_access",
          "connectUriConfidential": "https://sandbox-lever.auth0.com/authorize?client_id=Xt6YyyAwhDW4HcuBvDy80aE7A4qpqt9P&redirect_uri=http://localhost:3005/oauth/lever&response_type=code&prompt=consent&scope=applications:read:admin%20archive_reasons:read:admin%20contact:write:admin%20feedback:read:admin%20files:write:admin%20interviews:read:admin%20notes:write:admin%20offers:read:admin%20opportunities:write:admin%20postings:write:admin%20resumes:read:admin%20sources:read:admin%20stages:read:admin%20tags:read:admin%20uploads:write:admin%20users:read:admin%20confidential:access:admin%20offline_access"
        },
        "prod": {
          "connectUri": "",
          "connectUriConfidential": ""
        }
      },
      "EmailTrackingUrl": "http://localhost:9090/v2/track",
      "BugsnagApiKey": "",
      "MixpanelApiKey": "",
      "FlatFile": {
        "publishableKey": "",
        "environmentId": ""
      },
      "OutlookOAuthAppConfig": {
        "clientId": "c25e5c9b-bb0e-4d87-951e-beef5a559027",
        "redirectUri": "http://localhost:3005/outlook/redirect",
        "scopes": ["user.read", "mail.readwrite", "mail.send", "offline_access"] 
      },
      "PublicPath": "http://localhost:3005"  
    }`
);

const AppVersion = process.env.HIREFLOW_APP_VERSION ?? 'local';
const StageVersion = process.env.HIREFLOW_STAGE_VERSION ?? 'local';

export const config: {
    ApiEndpoint: string;
    AppVersion: string;
    StageVersion: string;
    Env: 'development' | 'stage' | 'production';
    ExtensionLink: string;
    GoogleOAuthAppConfig: {
        clientId: string;
    };
    GraphAPIEndpoint: string;
    LeverConfig: {
        prod: {
            connectUri: string;
            connectUriConfidential: string;
        };
        sandbox: {
            connectUri: string;
            connectUriConfidential: string;
        };
    };
    EmailTrackingUrl: string;
    JWT: {
        algorithm: 'HS256';
        audience: 'zeus';
        issuer: 'zeus';
        signingKey: string;
    };
    BugsnagApiKey: string;
    MixpanelApiKey: string;
    FlatFile: {
        publishableKey: string;
        environmentId: string;
    };
    OutlookOAuthAppConfig: {
        clientId: string;
        redirectUri: string;
        scopes: string[];
    };
    PublicPath: string;
} = { ...publicConfig, ...serverConfig, AppVersion, StageVersion };
