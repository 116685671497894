import styled from '@emotion/styled';
import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import Link from 'next/link';

import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface LoadingButtonProps extends MuiLoadingButtonProps {}

const LoadingButtonContainer: FC<LoadingButtonProps> = (props) => {
    const { children, className, href, ...rest } = props;

    const getButton = () => (
        <MuiLoadingButton disableElevation className={className} {...rest}>
            {children}
        </MuiLoadingButton>
    );

    return href ? <Link href={href}>{getButton()}</Link> : getButton();
};

const buttonColorIsDefault = (color?: string) => color === undefined || color === 'primary';

const LoadingButton = styled(LoadingButtonContainer)<LoadingButtonProps>`
    height: 44px;
    padding: 12px 16px;
    font-size: ${fontSizes.f16};
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;

    ${({ variant, color, theme }) =>
        variant === 'contained' &&
        buttonColorIsDefault(color) &&
        `
        background-color: ${theme.palette.primary.main};
        border: 1px solid ${theme.palette.primary.main};

        &:hover {
            background-color: ${theme.palette.primary.light};
            border: 1px solid ${theme.palette.primary.light};
        }
    `}

    ${({ variant, color, theme }) =>
        variant === 'outlined' &&
        buttonColorIsDefault(color) &&
        `
        color: ${theme.palette.primary.main};
        border: 1px solid ${theme.palette.primary.main};

        &:hover {
            background-color: ${theme.palette.primary.contrastText};
        }
    `}

    ${({ variant }) =>
        variant === 'text' &&
        `
        &:hover {
            background-color: inherit;
        }
    `}

    ${({ variant, color, theme }) =>
        variant === 'text' &&
        buttonColorIsDefault(color) &&
        `
        &:hover {
            background-color: transparent;
            color: ${theme.palette.primary.light};

            path {
                stroke: ${theme.palette.primary.light};
            }
        }
    `}

    ${({ variant, color, theme }) =>
        variant === 'contained' &&
        color === 'error' &&
        `
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.common.white};

        &:hover {
            background-color: ${theme.palette.error.light};
            color: ${theme.palette.common.white};
        }
    `}

    &.Mui-disabled {
        border: unset;
    }
`;

export type { LoadingButtonProps };
export { LoadingButton };
