import { interpolate, Variables } from 'hireflow-shared/utils/email-drafting-utils';

import { SequenceStageSendTimeEnum, SignatureOptionsEnum } from 'codegen/graphql';
import { SequenceStageData } from 'prospects';
import { CustomStage, Stage } from 'sequences/types';
import { SendFromAccountData } from 'shared/graphql/send-from-accounts';

export function customStageFromSequence(
    recipientId: string,
    newStages: Stage[],
    oldStages: Stage[],
    variables: Variables[],
    time: SequenceStageSendTimeEnum
) {
    const customStages: CustomStage[] = [];
    for (const [index, newStage] of newStages.entries()) {
        if (
            newStage.subject !== interpolate(oldStages[index].subject, variables[index]) ||
            newStage.content !== interpolate(oldStages[index].content, variables[index]) ||
            (index === 0 && time !== (oldStages[0].sendTime as SequenceStageSendTimeEnum))
        ) {
            const customStage: CustomStage = {
                recipientId,
                stageIndex: oldStages[index].stageIndex,
                bcc: oldStages[index].bcc,
                cc: oldStages[index].cc,
                content: newStage.content,
                sendFromAccountId: oldStages[index].sendFromAccountId,
                subject: newStage.subject,
                numberOfDays: oldStages[index].numberOfDays,
                replyToLastEmail: oldStages[index].replyToLastEmail,
                sendTime: index === 0 ? time : oldStages[index].sendTime,
                signature: oldStages[index].signature,
                useSignature: oldStages[index].useSignature,
                sendTimeOfDay: oldStages[index].sendTimeOfDay,
                sendTypeOfDays: oldStages[index].sendTypeOfDays,
            };
            customStages.push(customStage);
        }
    }
    return customStages;
}

export function getCombinedStages(sequenceStages: Stage[], customStages: CustomStage[], variables: Variables[]) {
    const combinedStage: Stage[] = [];
    for (const [index, sequenceStage] of sequenceStages.entries()) {
        const customStage = customStages.find((f) => f.stageIndex === sequenceStage.stageIndex);
        if (customStage) {
            combinedStage.push({
                ...sequenceStage,
                content: customStage.content,
                subject: customStage.subject,
                sendTime: customStage.sendTime,
                personalized: true,
            });
        } else {
            combinedStage.push({
                ...sequenceStage,
                content: interpolate(sequenceStage.content, variables[index]),
                subject: interpolate(sequenceStage.subject, variables[index]),
                personalized: false,
            });
        }
    }
    return combinedStage;
}

export function customStageFromPersonalization(recipientId: string, newStage: Stage, oldStage: Stage) {
    const customStage: CustomStage = {
        recipientId,
        stageIndex: oldStage.stageIndex,
        bcc: oldStage.bcc,
        cc: oldStage.cc,
        content: newStage.content !== oldStage.content ? newStage.content : oldStage.content,
        sendFromAccountId: oldStage.sendFromAccountId,
        subject: newStage.subject !== oldStage.subject ? newStage.subject : oldStage.subject,
        numberOfDays: oldStage.numberOfDays,
        replyToLastEmail: oldStage.replyToLastEmail,
        sendTime: oldStage.sendTime,
        signature: oldStage.signature,
        useSignature: oldStage.useSignature,
        sendTimeOfDay: oldStage.sendTimeOfDay,
        sendTypeOfDays: oldStage.sendTypeOfDays,
    };

    return customStage;
}

export function getStageSignature(stage: SequenceStageData, sendFromAccounts: SendFromAccountData[] = []) {
    if (stage.useSignature) {
        const sendFromAccount = sendFromAccounts?.find((account) => account.id === stage.sendFromAccountId);

        if (sendFromAccount) {
            switch (stage.signature) {
                case SignatureOptionsEnum.HireflowSettings: {
                    return `${sendFromAccount?.email_account.user.hireflowSignature || ''}`;
                }
                case SignatureOptionsEnum.ImportFromGmail: {
                    return `${sendFromAccount?.email_account.signature || ''}`;
                }
                default: {
                    return '';
                }
            }
        }
    }
    return '';
}
