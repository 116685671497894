import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { AshbySourcesQuery } from 'codegen/graphql';
import { ConnectEditFormButtons } from 'integrations/components/composite/ashby';
import { TeamSettingsDefaults, TeamSettingsSpacer } from 'integrations/components/presentational';
import { ConnectEditTemplate } from 'integrations/components/templates/ashby';
import { OverrideToggleFormValues } from 'integrations/types';
import { CreateEditFormValues } from 'integrations/types/ashby';
import { Form } from 'shared/components/form';
import { AshbyAccountByIdData, GET_ASHBY_SOURCES } from 'shared/graphql/integrations/ashby';
import { useDynamicSchemaForm, useSession, useSnackbarAlert } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

interface EditFormProps {
    ashbyAccount: AshbyAccountByIdData;
    onClose: () => void;
    refetch: () => void;
}

const EditForm: FC<EditFormProps> = (props) => {
    const { ashbyAccount, onClose, refetch } = props;
    const translate = useTranslations('integrations.ashby');

    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const validationSchema = Yup.object().shape({
        apiToken: Yup.string().required(translate('error-api-token')),
    });

    const { data: ashbySourcesData } = useQuery<AshbySourcesQuery>(GET_ASHBY_SOURCES);

    const formInitialValues: CreateEditFormValues & OverrideToggleFormValues = {
        apiToken: ashbyAccount?.apiToken ?? '',
        defaultSourceId: ashbyAccount?.defaultSourceId ?? '',
        teamOverride: false,
    };

    const formMethods = useDynamicSchemaForm<CreateEditFormValues & OverrideToggleFormValues>({
        mode: 'onTouched',
        defaultValues: formInitialValues,
        schema: validationSchema,
    });

    useEffect(() => {
        if (!ashbyAccount?.apiToken) return;
        formMethods.setValue('apiToken', ashbyAccount.apiToken);
    }, [formMethods, ashbyAccount?.apiToken]);

    const handleClose = (): void => {
        formMethods.reset();
        onClose();
    };

    const onSubmit = async () => {
        const values = formMethods.getValues();
        const { apiToken, defaultSourceId } = values;
        const restApiClient = createRestApiClient();
        const connectResponse = await restApiClient.connectAshbyToken({
            apiToken,
            defaultSourceId: defaultSourceId || undefined,
        });

        if (connectResponse?.success) {
            refetch();
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-saved'),
            });
            onClose();
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-saved'),
            });
            formMethods.reset(values);
        }
    };

    const isOwner: boolean = ashbyAccount?.ownerId === session?.user.id;

    return (
        <Form
            css={css`
                width: 100%;
            `}
            onSubmit={formMethods.handleSubmit(onSubmit)}
        >
            <ConnectEditTemplate isEditing formMethods={formMethods} />
            <TeamSettingsSpacer description={translate('team-settings-info')} />
            <TeamSettingsDefaults formMethods={formMethods} data={ashbySourcesData?.data} />
            <ConnectEditFormButtons isEditing canEdit={isOwner} onClose={handleClose} />
        </Form>
    );
};

export { EditForm };
