import { useMutation } from '@apollo/client';

import { trackEvent } from 'analytics';
import { CreateProjectMembersMutation, CreateProjectMembersMutationVariables } from 'codegen/graphql';
import { logger } from 'logger';
import { AppFlow } from 'shared/enums';
import { CREATE_PROJECT_MEMBERS } from 'shared/graphql/project-members';

export const useCreateProjectMembers = (refetchQueries: string[]) => {
    const [createProjectMembers] = useMutation<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>(
        CREATE_PROJECT_MEMBERS,
        {
            refetchQueries,
        }
    );

    const createAndTrackProjectMembers = async (
        projectMembers: { projectId: string | undefined; prospectId: string }[],
        flow: AppFlow
    ) => {
        try {
            const result = await createProjectMembers({
                variables: { projectMembers },
            });

            const addedProjectMembers = result.data?.insert_project_members?.returning ?? [];

            for (const projectMember of addedProjectMembers) {
                trackEvent('prospect_added_to_project_client', {
                    prospect_id: projectMember.prospectId,
                    project_id: projectMember.projectId,
                    project_member_id: projectMember.id,
                    flow,
                });
            }

            return result;
        } catch (error) {
            logger.error('Error creating project members :', error);
            throw error;
        }
    };

    return { createAndTrackProjectMembers };
};
