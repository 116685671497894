import styled from '@emotion/styled';
import { RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material';

import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RadioGroupProps extends MuiRadioGroupProps {}

const RadioGroup: FC<RadioGroupProps> = (props) => <MuiRadioGroup {...props} />;

const StyledRadioGroup = styled(RadioGroup)`
    gap: ${spacing.space14px};
    .MuiFormControlLabel-root {
        margin: 0;
        padding: ${spacing.space16px};
        ${({ theme }) => `border: 1px solid ${theme.palette.grey[100]};
        border-radius: 4px;
        &:hover {
            background-color: ${theme.palette.primary.contrastText};
        }
        :has(.Mui-checked) {
            background-color: ${theme.palette.primary.contrastText};
            border-color: ${theme.palette.primary.main};
        }
        .MuiFormControlLabel-label {
            font-size: ${spacing.space14px};
        }
        .MuiSvgIcon-root {
            font-size: ${spacing.space16px};
            color: ${theme.palette.primary.main};
        }`};
        &.Mui-disabled {
            .MuiSvgIcon-root {
                color: unset;
            }
            &:hover {
                background-color: unset;
            }
            div {
                color: ${({ theme }) => theme.palette.grey[600]};
            }
               
    }
`;

export { StyledRadioGroup as RadioGroup, type RadioGroupProps };
