import { css, withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedChevron: FC<SvgProps> = (props) => {
    const { theme, direction = 'down', stroke, ...rest } = props;

    const directionToTransform = (chevronDirection: typeof direction) => {
        if (chevronDirection === 'up') return 'transform: rotate(180deg)';
        if (chevronDirection === 'right') return 'transform: rotate(-90deg)';
        if (chevronDirection === 'left') return 'transform: rotate(90deg)';
        return '';
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
                ${directionToTransform(direction)}
            `}
            {...rest}
        >
            <path
                d="M6 9.5L12 15.5L18 9.5"
                stroke={stroke ?? theme?.palette?.primary?.main}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const Chevron = withTheme(ThemedChevron);

export { Chevron };
