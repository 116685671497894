import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CardProps {
    title: string;
    description: string;
    icon: React.ReactNode;
    onClick: () => void;
}

const Card: FC<CardProps> = ({ title, description, icon, onClick }) => (
    <Box
        css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 200px;
            width: 335px;
            border-radius: 8px;
            background-color: ${colors.neutrals.white};
            border: 1px solid ${colors.grays.gallery};
            padding: ${spacing.space24px};
        `}
    >
        <Box
            css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <Box
                css={css`
                    font-size: ${fontSizes.f20};
                    font-family: ${fontFamilies.poppins};
                    font-weight: ${fontWeights.bold};
                `}
            >
                {title}
            </Box>
            <IconButton onClick={onClick}>{icon}</IconButton>
        </Box>
        <Box
            css={css`
                font-size: ${fontSizes.f14};
            `}
        >
            {description}
        </Box>
    </Box>
);

export { Card };
