import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';

const RequestSourcerFormButtons = () => {
    const translate = useTranslations('sourcing.request-a-sourcer-form');
    return (
        <Box
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button type="submit" variant="contained">
                {translate('next-button')}
            </Button>
        </Box>
    );
};

export { RequestSourcerFormButtons };
