import { css, useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Hyperlink, NoSsr } from 'shared/components/presentational';
import { ArrowNarrowUpRight } from 'shared/components/svgs';
import { dateFormatter } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface InfoBoxProps {
    link?: string;
    title: string;
    date?: number | null;
    status?: JSX.Element;
    actionTakenBy?: string;
    disableRedirect: boolean;
    showExternalLinkIcon?: boolean;
}

const InfoBox: FC<InfoBoxProps> = (props) => {
    const theme = useTheme();
    const { link, title, date, status, actionTakenBy, disableRedirect, showExternalLinkIcon = false } = props;

    return (
        <>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Box
                    css={css`
                        color: ${theme.palette.primary.main};
                    `}
                >
                    {!link || disableRedirect ? (
                        <Box>{title}</Box>
                    ) : (
                        <Hyperlink
                            newTab
                            href={link}
                            css={css`
                                display: flex;
                                align-items: center;
                                gap: 8px;
                            `}
                        >
                            {title}
                            {showExternalLinkIcon ? (
                                <ArrowNarrowUpRight
                                    css={css`
                                        margin-top: 2px;
                                        width: 10px;
                                    `}
                                    stroke={theme.palette.primary.main}
                                />
                            ) : null}
                        </Hyperlink>
                    )}
                </Box>
                <NoSsr>{date && <Box>{dateFormatter(date)}</Box>}</NoSsr>
            </Box>
            {status && actionTakenBy && (
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <Box
                        css={css`
                            font-size: ${fontSizes.f14};
                        `}
                    >
                        {status}
                    </Box>
                    <Box
                        css={css`
                            font-size: ${fontSizes.f14};
                            margin-top: ${spacing.space4px};
                            opacity: 0.8;
                        `}
                    >
                        {actionTakenBy}
                    </Box>
                </Box>
            )}
        </>
    );
};

export { InfoBox };
