import { Profile } from 'hireflow-shared/types/profile';
import { createContext, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

export enum ProfileErrorEnum {
    ProfileOutOfNetwork = 'out_of_network_profile',
    ProfileParsingError = 'profile_parsing_error',
    EmailThreadNotFound = 'email_thread_not_found',
    MatchingProfileCouldNotBeFound = 'matching_profile_could_not_be_found',
}

export interface ProfileContextProps {
    profile: Profile | undefined;
    error: ProfileErrorEnum | undefined;
}

const ProfileContext = createContext<ProfileContextProps>({
    error: undefined,
    profile: undefined,
});

const ProfileContextProvider: FC<ProfileContextProps> = ({ children, profile, error }) => {
    const value = useMemo(() => ({ profile, error }), [profile, error]);

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

const useProfile = () => useContext(ProfileContext);

export { ProfileContext, ProfileContextProvider, useProfile };
