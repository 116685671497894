import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { RemovePersonalization } from 'prospects/prospect-remove-personalization-prompt';
import { StageViewFormValues } from 'prospects/types/form-types';
import { Box } from 'shared/components/containers';
import { SelectOption, SelectSequence } from 'shared/components/form';
import { SequenceIcon } from 'shared/components/svgs';
import { NO_SEQUENCE } from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddToSequenceSelectProps {
    anchor: HTMLElement;
    formMethods: UseFormReturn<StageViewFormValues>;
    hidden: boolean;
    disabled: boolean;
    options: SelectOption[];
    onChange: (sequenceId?: string) => void;
}

const AddToSequenceSelect: FC<AddToSequenceSelectProps> = (props) => {
    const { anchor, hidden, disabled, formMethods, options, onChange } = props;

    const { session } = useSession();

    const translate = useTranslations('prospects.tabs.add-tab');
    const translateSequence = useTranslations('sequence');

    const [selectedSequence, setSelectedSequence] = useState<string | undefined>();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [intermediateSequenceId, setIntermediateSequenceId] = useState<string>();

    const { formState } = formMethods;
    const { isDirty } = formMethods.getFieldState('stages', formState);

    const handleChange = (value: string | undefined) => {
        if (isDirty) {
            setIntermediateSequenceId(value);
            setShowWarning(true);
        } else {
            setSelectedSequence(value);
            onChange(value === NO_SEQUENCE ? undefined : value);
        }
    };

    const handleCancel = () => {
        setShowWarning(false);
    };

    const handleRemove = () => {
        setSelectedSequence(intermediateSequenceId);
        onChange(intermediateSequenceId === NO_SEQUENCE ? undefined : intermediateSequenceId);
        setIntermediateSequenceId(undefined);

        setShowWarning(false);
    };

    useEffect(() => {
        if (options && session && session.user && !disabled && !hidden) {
            const preset = options.find((f) => !f.disabled && session.user.lastUsedSequence === f.value);
            if (!selectedSequence) {
                handleChange(preset?.value ?? undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, options, selectedSequence, hidden, disabled]);

    return (
        <Box
            css={css`
                padding-top: ${spacing.space16px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Box>
                    <SequenceIcon width={24} height={24} />
                </Box>
                <Box
                    css={css`
                        margin-left: ${spacing.space12px};
                        width: 445px;
                    `}
                >
                    {options && (
                        <SelectSequence
                            width="100%"
                            name="sequence-select"
                            label={translate('sequence-select-label')}
                            value={selectedSequence ?? NO_SEQUENCE}
                            onChange={handleChange}
                            options={options}
                            disabled={disabled}
                            noOptionsText={translateSequence('no-sequence-found')}
                        />
                    )}
                </Box>
            </Box>
            {showWarning && (
                <RemovePersonalization
                    anchor={anchor}
                    onCancel={handleCancel}
                    onRemove={handleRemove}
                    open={showWarning}
                />
            )}
        </Box>
    );
};

export { AddToSequenceSelect };
