import styled from '@emotion/styled';
import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface ModalProps extends MuiModalProps {
    width?: number;
    padding?: number;
}

const Modal: FC<ModalProps> = ({ children, ...rest }) => (
    <MuiModal {...rest}>
        <Box>{children}</Box>
    </MuiModal>
);

const StyledModal = styled(Modal)`
    & > .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${(props) => (props.width ? props.width : '500')}px;
        padding: ${(props) => (props.padding ? props.padding : '0')}px;
        background-color: ${({ theme }) => theme.palette.common.white};
    }
`;

export type { ModalProps };
export { StyledModal as Modal };
