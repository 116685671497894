import { ShareSequence } from 'sequences/components/presentational/sharing';
import { SharingPopover, SharingPopoverProps } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface TeamPopoverProps extends SharingPopoverProps {
    sequence: {
        id: string;
        user: { id: string; fullName: string; email: string };
        collaborators: {
            id: string;
            user: { id: string; fullName: string; email: string };
        }[];
    };
}

const SequenceSharingPopover: FC<TeamPopoverProps> = ({ sequence, ...rest }) => (
    <SharingPopover {...rest}>
        <ShareSequence sequence={sequence} />
    </SharingPopover>
);

export { SequenceSharingPopover };
