import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedMinusCircle: FC<SvgProps> = ({ stroke, theme, onClick, width, height, ...rest }) => (
    <svg
        {...{
            width: width || '22',
            height: height || '22',
            viewBox: '0 0 22 22',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
            ...(onClick && { onClick }),
            ...rest,
        }}
    >
        <path
            // eslint-disable-next-line max-len
            d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const MinusCircle = withTheme(ThemedMinusCircle);

export { MinusCircle };
