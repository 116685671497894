import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedPin: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M12.5014 15L12.5014 22M8.50141 7.30813V9.43875C8.50141 9.64677 8.50141 9.75078 8.48099 9.85026C8.46287 9.93852 8.43291 10.0239 8.39192 10.1042C8.34572 10.1946 8.28074 10.2758 8.1508 10.4383L6.58102 12.4005C5.91528 13.2327 5.58241 13.6487 5.58203 13.9989C5.5817 14.3035 5.72017 14.5916 5.95818 14.7815C6.23186 15 6.76471 15 7.83041 15H17.1724C18.2381 15 18.771 15 19.0446 14.7815C19.2826 14.5916 19.4211 14.3035 19.4208 13.9989C19.4204 13.6487 19.0875 13.2327 18.4218 12.4005L16.852 10.4383C16.7221 10.2758 16.6571 10.1946 16.6109 10.1042C16.5699 10.0239 16.5399 9.93852 16.5218 9.85026C16.5014 9.75078 16.5014 9.64677 16.5014 9.43875V7.30813C16.5014 7.19301 16.5014 7.13544 16.5079 7.07868C16.5137 7.02825 16.5233 6.97833 16.5367 6.92937C16.5517 6.87424 16.5731 6.8208 16.6158 6.71391L17.6237 4.19423C17.9178 3.45914 18.0648 3.09159 18.0035 2.79655C17.9498 2.53853 17.7965 2.31211 17.5769 2.1665C17.3257 2 16.9299 2 16.1382 2H8.86466C8.07295 2 7.67709 2 7.42591 2.1665C7.20627 2.31211 7.05297 2.53853 6.99935 2.79655C6.93804 3.09159 7.08506 3.45914 7.3791 4.19423L8.38697 6.71391C8.42973 6.8208 8.4511 6.87424 8.46614 6.92937C8.4795 6.97833 8.48912 7.02825 8.4949 7.07868C8.50141 7.13544 8.50141 7.19301 8.50141 7.30813Z"
            stroke={stroke ?? theme?.palette?.primary?.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Pin = withTheme(ThemedPin);

export { Pin };
