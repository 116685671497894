import { css } from '@emotion/react';
import Image from 'next/image';

import { ErrorTemplate } from 'errors/components/templates/error-template';
import { FC } from 'shared/types';

interface Template404Props {}

const Template404: FC<Template404Props> = () => (
    <ErrorTemplate errorCode="404">
        <Image
            css={css`
                border-radius: 50% 0% 50% 50%;

                object-fit: cover;
                object-position: left bottom;
            `}
            src="/404.gif"
            alt="404"
            width={296.5}
            height={298}
        />
    </ErrorTemplate>
);

export { Template404 };
