import { css, useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { List, NoSsr } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { dateTimeFormatter } from 'shared/services';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface CustomInfoMessageProps {
    message: string;
    time?: number;
}

const CustomInfoMessage: FC<CustomInfoMessageProps> = (props) => {
    const { message, time } = props;
    const theme = useTheme();

    return (
        <Box>
            <List
                type="unordered"
                css={css`
                    padding: 24px 8px 0px;
                `}
            >
                <Box
                    component="li"
                    css={css`
                        display: flex;
                        align-items: flex-start;
                    `}
                >
                    <Circle
                        css={css`
                            margin-top: 4px;
                            margin-right: 16px;
                            min-width: 10px;
                            min-height: 10px;
                        `}
                        fill={theme.palette.grey[100]}
                    />
                    <Box>
                        <Box
                            css={css`
                                color: ${theme.palette.common.black};
                                font-size: ${fontSizes.f16};
                                font-weight: ${fontWeights.semiBold};
                            `}
                        >
                            {message}
                        </Box>
                        {time && (
                            <NoSsr>
                                <Box
                                    component="span"
                                    css={css`
                                        color: ${theme.palette.grey[200]};
                                        font-size: ${fontSizes.f14};
                                    `}
                                >
                                    {dateTimeFormatter(time)}
                                </Box>
                            </NoSsr>
                        )}
                    </Box>
                </Box>
            </List>
        </Box>
    );
};

export { CustomInfoMessage };
