import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { EmptyState } from 'shared/components/composite';
import { Button } from 'shared/components/presentational';
import { ArrowNarrowUpRight, UsersCheck } from 'shared/components/svgs';
import { spacing } from 'shared/settings';

const EmptyRecipientsNotice = () => {
    const theme = useTheme();
    const translate = useTranslations('sequence.details');

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <UsersCheck
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                    strokeWidth="4"
                />
            }
            title={translate('no-recipients-yet')}
            description={translate('recipients-description')}
            button={
                <Button
                    variant="contained"
                    endIcon={<ArrowNarrowUpRight />}
                    onClick={() => window.open('https://www.linkedin.com/', '_blank')}
                >
                    {translate('go-to-linkedin')}
                </Button>
            }
        />
    );
};

export { EmptyRecipientsNotice };
