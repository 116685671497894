import { css } from '@emotion/react';

import { Button } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ModalCloseButtonProps {
    onClose: () => void;
}

const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => {
    const { onClose } = props;

    return (
        <Button
            css={css`
                min-width: 24px;
                padding: 0;
                position: absolute;
                right: 0;
                top: 10px;
            `}
            startIcon={<XClose />}
            onClick={onClose}
        />
    );
};

export { ModalCloseButton };
