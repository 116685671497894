import { css, useTheme } from '@mui/material';
import { orderBy } from 'lodash';
import { useTranslations } from 'next-intl';
import React, { useEffect, useRef } from 'react';

import {
    AshbyUserDetailsFragment,
    ProjectStatusEnum,
    SourcerMemberStatusEnum,
    SourcerStatusEnum,
} from 'codegen/graphql';
import { StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { InfoBox, NoSsr } from 'shared/components/presentational';
import { AshbyLogo, Folder, GreenhouseLogo, LeverLogo, SequenceIcon, Target } from 'shared/components/svgs';
import { useAccessControl, useProspectPanel } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { appRoutes, colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

export enum ScrollSectionEnum {
    Sequences = 'sequences',
    Projects = 'projects',
    Ats = 'ats',
    AISourcer = 'ai-sourcer',
}

interface ProjectSequenceAtsOverviewProps {
    scrollToSection?: ScrollSectionEnum;
    onScroll: () => void;
}

const Title: FC<{
    icon: React.ReactNode;
    text: string;
    details: JSX.Element[];
}> = ({ icon, text, details }) => (
    <>
        <Box
            css={css`
                display: flex;
                justify-content: top;
                column-gap: ${spacing.space8px};
                font-size: ${fontSizes.f16};
                font-weight: ${fontWeights.bold};
            `}
        >
            {icon}
            {text}
        </Box>
        {details}
    </>
);

const DetailBox: FC<{
    children: React.ReactNode;
}> = ({ children }) => (
    <Box
        css={css`
            padding-top: ${spacing.space12px};
            padding-bottom: ${spacing.space12px};
            padding-left: ${spacing.space28px};
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            &:last-child {
                padding-bottom: ${spacing.none};
                border: none;
            }
        `}
    >
        {children}
    </Box>
);

const ScrollBox: FC<{
    highlightScrollArea: boolean;
    children: React.ReactNode;
}> = ({ highlightScrollArea, children }) => {
    const theme = useTheme();

    return (
        <Box
            css={css`
                margin-top: ${spacing.space32px};
                background-color: ${highlightScrollArea ? colors.greens.narvik : theme.palette.common.white};
                box-shadow: ${highlightScrollArea ? `0px 0px 20px 20px ${colors.greens.narvik}` : `none`};
                transition: all 1s ease-in-out;
            `}
        >
            {children}
        </Box>
    );
};

const ProjectSequenceAtsOverview: FC<ProjectSequenceAtsOverviewProps> = (props) => {
    const { scrollToSection, onScroll } = props;
    const { recipients, members, sourcerMembers, ashbyData, greenhouseData, leverData } = useProspectPanel();
    const theme = useTheme();
    const { canViewSequence, canViewProject } = useAccessControl();
    const { session } = useSession();
    const translate = useTranslations('prospects.tabs.profile-tab');

    const refSequenceSection = useRef<HTMLElement>(null);
    const refProjectSection = useRef<HTMLElement>(null);
    const refAiSourcerSection = useRef<HTMLElement>(null);
    const refAtsSection = useRef<HTMLElement>(null);

    useEffect(() => {
        if (scrollToSection) {
            switch (scrollToSection) {
                case 'sequences': {
                    refSequenceSection.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                }
                case 'projects': {
                    refProjectSection.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                }
                case 'ai-sourcer': {
                    refAiSourcerSection.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                }
                case 'ats': {
                    refAtsSection.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                }
                default: {
                    break;
                }
            }
            setTimeout(() => {
                onScroll();
                // eslint-disable-next-line no-magic-numbers
            }, 2000);
        }
    }, [scrollToSection, onScroll]);

    const getProjectStatusString = (status: ProjectStatusEnum) => {
        switch (status) {
            case ProjectStatusEnum.Active:
                return translate('member-project-status.active');
            case ProjectStatusEnum.Archived:
                return translate('member-project-status.archived');
            default:
                return '';
        }
    };

    const getSourcerStatusString = (status: SourcerStatusEnum) => {
        switch (status) {
            case SourcerStatusEnum.Draft:
                return translate('sourcer-status.draft');
            case SourcerStatusEnum.Active:
                return translate('sourcer-status.active');
            case SourcerStatusEnum.Paused:
                return translate('sourcer-status.paused');
            case SourcerStatusEnum.Archived:
                return translate('sourcer-status.archived');
            default:
                return '';
        }
    };

    const projectDetails = () => {
        const projectInfoBoxes: JSX.Element[] = [];
        for (const member of members ?? []) {
            const projectInfoBox = (
                <DetailBox key={member.project.id}>
                    <NoSsr>
                        <InfoBox
                            disableRedirect={!canViewProject(member.project)}
                            link={appRoutes.projects.index(member.project.id)}
                            title={member.project.title}
                            status={
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        color: ${colors.grays.tundora};
                                        margin-top: ${spacing.space4px};
                                    `}
                                >
                                    {getProjectStatusString(member.project.status)}
                                </Box>
                            }
                            actionTakenBy={translate('added-by', {
                                name:
                                    session?.user.id === member.addedBy
                                        ? translate('added-by-you')
                                        : member.user.fullName,
                            })}
                            date={member.createdAt}
                        />
                    </NoSsr>
                </DetailBox>
            );
            projectInfoBoxes.push(projectInfoBox);
        }
        return projectInfoBoxes;
    };

    const sequenceDetails = () => {
        const infoBoxes: JSX.Element[] = [];
        for (const recipient of recipients ?? []) {
            const info = (
                <DetailBox key={recipient.sequence.id}>
                    <NoSsr>
                        <InfoBox
                            disableRedirect={!canViewSequence(recipient.sequence)}
                            link={appRoutes.sequences.index(recipient.sequence.id)}
                            title={recipient.sequence.title}
                            status={<StatusChip status={recipient.status} lastStageSent={recipient.lastStageSent} />}
                            actionTakenBy={translate('added-by', {
                                name:
                                    session?.user.id === recipient.user.id
                                        ? translate('added-by-you')
                                        : recipient.user.fullName,
                            })}
                            date={recipient.createdAt}
                        />
                    </NoSsr>
                </DetailBox>
            );
            infoBoxes.push(info);
        }
        return infoBoxes;
    };

    const aiSourcerDetails = () => {
        const infoBoxes: JSX.Element[] = [];
        for (const sourcerMember of sourcerMembers ?? []) {
            const info = (
                <DetailBox key="ai-sourcer">
                    <NoSsr>
                        <InfoBox
                            disableRedirect={false}
                            link={appRoutes.sourcing.index(sourcerMember.sourcer.id)}
                            title={sourcerMember.sourcer.title}
                            date={sourcerMember.addedAt}
                            status={
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        color: ${colors.grays.tundora};
                                        margin-top: ${spacing.space4px};
                                    `}
                                >
                                    {getSourcerStatusString(sourcerMember.sourcer.status)}
                                </Box>
                            }
                            actionTakenBy={translate('added-by', {
                                name:
                                    session?.user.id === sourcerMember.reviewer?.id
                                        ? translate('added-by-you')
                                        : sourcerMember.reviewer?.fullName,
                            })}
                        />
                    </NoSsr>
                </DetailBox>
            );
            infoBoxes.push(info);
        }
        return infoBoxes;
    };

    const getAshbyAddedBy = (addedByUser: AshbyUserDetailsFragment | null | undefined): string => {
        const defaultRes: string = 'Unknown';
        if (!addedByUser) return defaultRes;
        let name = `${addedByUser.firstName || ''} ${addedByUser.lastName || ''}`;
        if (name.trim().length === 0) name = addedByUser.email || '';
        return name || defaultRes;
    };

    const ashbyDetails = () => {
        const projectInfoBoxes: JSX.Element[] = [];
        for (const ashbyDatum of orderBy(ashbyData ?? [], ['addedAt'], ['desc'])) {
            const projectInfoBox = (
                <DetailBox key={ashbyDatum.id}>
                    <NoSsr>
                        <InfoBox
                            showExternalLinkIcon
                            disableRedirect={false}
                            link={ashbyDatum.candidate?.profileUrl ?? ''}
                            title={ashbyDatum.job?.name ?? 'General Opportunity'}
                            status={
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        color: ${colors.grays.tundora};
                                        margin-top: ${spacing.space4px};
                                    `}
                                >
                                    {ashbyDatum.status}
                                </Box>
                            }
                            actionTakenBy={translate('added-by', {
                                name: getAshbyAddedBy(ashbyDatum.addedByUser),
                            })}
                            date={ashbyDatum.addedAt}
                        />
                    </NoSsr>
                </DetailBox>
            );
            projectInfoBoxes.push(projectInfoBox);
        }
        return projectInfoBoxes;
    };

    const getGreenhouseExternalLink = ({
        remoteCandidateId,
        remoteApplicationId,
    }: {
        remoteCandidateId: string;
        remoteApplicationId: string;
    }): string => `https://app3.greenhouse.io/people/${remoteCandidateId}?application_id=${remoteApplicationId}`;

    const greenhouseDetails = () => {
        const projectInfoBoxes: JSX.Element[] = [];
        for (const greenhouseDatum of orderBy(greenhouseData ?? [], ['addedAt'], ['desc'])) {
            const projectInfoBox = (
                <DetailBox key={greenhouseDatum.id}>
                    <NoSsr>
                        <InfoBox
                            showExternalLinkIcon
                            disableRedirect={false}
                            link={getGreenhouseExternalLink({
                                remoteCandidateId: greenhouseDatum.candidate.remoteId.toString(),
                                remoteApplicationId: greenhouseDatum.remoteId.toString(),
                            })}
                            title={greenhouseDatum.jobs[0]?.job?.name ?? 'General Opportunity'}
                            status={
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        color: ${colors.grays.tundora};
                                        margin-top: ${spacing.space4px};
                                    `}
                                >
                                    {greenhouseDatum.status}
                                </Box>
                            }
                            actionTakenBy={translate('added-by', {
                                name: greenhouseDatum.assigneeFullName,
                            })}
                            date={greenhouseDatum.addedAt}
                        />
                    </NoSsr>
                </DetailBox>
            );
            projectInfoBoxes.push(projectInfoBox);
        }
        return projectInfoBoxes;
    };

    const leverDetails = () => {
        const projectInfoBoxes: JSX.Element[] = [];
        for (const leverDatum of orderBy(leverData ?? [], ['opportunity.addedAt'], ['desc'])) {
            const projectInfoBox = (
                <DetailBox key={leverDatum.opportunity.id}>
                    <NoSsr>
                        <InfoBox
                            showExternalLinkIcon
                            disableRedirect={false}
                            link={leverDatum.opportunity.remoteUrl ?? ''}
                            title={leverDatum.posting?.name ?? 'General Opportunity'}
                            status={
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        color: ${colors.grays.tundora};
                                        margin-top: ${spacing.space4px};
                                    `}
                                >
                                    {leverDatum.status.stage?.name ?? 'Unknown Status'}
                                </Box>
                            }
                            actionTakenBy={translate('added-by', {
                                name: leverDatum.addedByUser?.name ?? leverDatum.addedByUser?.email,
                            })}
                            date={leverDatum.opportunity.addedAt}
                        />
                    </NoSsr>
                </DetailBox>
            );
            projectInfoBoxes.push(projectInfoBox);
        }
        return projectInfoBoxes;
    };

    const projectsTitle =
        (members ?? []).length > 0 ? (
            <Title icon={<Folder />} text={translate('projects-title')} details={projectDetails()} />
        ) : null;

    const sequencesTitle =
        (recipients ?? []).length > 0 ? (
            <Title
                icon={
                    <SequenceIcon
                        css={css`
                            width: 20px;
                            height: 20px;
                        `}
                    />
                }
                text={translate('sequence-title')}
                details={sequenceDetails()}
            />
        ) : null;

    const aiSourcerTitle =
        (sourcerMembers ?? []).filter((f) => f.status === SourcerMemberStatusEnum.Accepted).length > 0 ? (
            <Title
                icon={
                    <Target
                        css={css`
                            width: 20px;
                            height: 20px;
                        `}
                        stroke={theme.palette.common.black}
                    />
                }
                text={translate('ai-sourcer-title')}
                details={aiSourcerDetails()}
            />
        ) : null;

    const ashbyTitle =
        (ashbyData ?? []).length > 0 ? (
            <Title
                icon={
                    <AshbyLogo
                        fill={theme.palette.common.black}
                        css={css`
                            width: 20px;
                            height: 20px;
                        `}
                    />
                }
                text={translate('ashby-title')}
                details={ashbyDetails()}
            />
        ) : null;

    const greenhouseTitle =
        (greenhouseData ?? []).length > 0 ? (
            <Title
                icon={
                    <GreenhouseLogo
                        fill={theme.palette.common.black}
                        css={css`
                            width: 20px;
                            height: 20px;
                        `}
                    />
                }
                text={translate('greenhouse-title')}
                details={greenhouseDetails()}
            />
        ) : null;

    const leverTitle =
        (leverData ?? []).length > 0 ? (
            <Title
                icon={
                    <LeverLogo
                        fill={theme.palette.common.black}
                        css={css`
                            width: 20px;
                            height: 20px;
                        `}
                    />
                }
                text={translate('lever-title')}
                details={leverDetails()}
            />
        ) : null;

    return (
        <Box
            css={css`
                margin-top: ${spacing.space64px};
            `}
        >
            {(members ?? []).length > 0 && (
                <Box ref={refProjectSection}>
                    <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.Projects}>
                        {projectsTitle}
                    </ScrollBox>
                </Box>
            )}
            {(recipients ?? []).length > 0 && (
                <Box ref={refSequenceSection}>
                    <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.Sequences}>
                        {sequencesTitle}
                    </ScrollBox>
                </Box>
            )}

            <Box ref={refAiSourcerSection}>
                <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.AISourcer}>
                    {aiSourcerTitle}
                </ScrollBox>
            </Box>

            {(ashbyData ?? []).length > 0 || (greenhouseData ?? []).length > 0 || (leverData ?? []).length > 0 ? (
                <Box ref={refAtsSection}>
                    {(ashbyData ?? []).length > 0 && (
                        <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.Ats}>
                            {ashbyTitle}
                        </ScrollBox>
                    )}

                    {(greenhouseData ?? []).length > 0 && (
                        <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.Ats}>
                            {greenhouseTitle}
                        </ScrollBox>
                    )}

                    {(leverData ?? []).length > 0 && (
                        <ScrollBox highlightScrollArea={scrollToSection === ScrollSectionEnum.Ats}>
                            {leverTitle}
                        </ScrollBox>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

export { ProjectSequenceAtsOverview };
