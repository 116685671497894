import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { UpdateUserMutation } from 'codegen/graphql';
import { EditableSettingsSection } from 'settings/components/containers';
import { Box } from 'shared/components/containers';
import { TextField } from 'shared/components/presentational';
import { UPDATE_USER } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { spacing } from 'shared/settings';

const getUserFullName = (first: string, last: string) => `${first} ${last}`;

const UserName = () => {
    const translate = useTranslations('settings.account-settings.user-name');
    const { session, setSession } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateUser] = useMutation<UpdateUserMutation>(UPDATE_USER);

    const [isEditing, setIsEditing] = useState(false);
    const [firstName, setFirstName] = useState(session!.user.firstName);
    const [lastName, setLastName] = useState(session!.user.lastName);

    useEffect(() => {
        if (session) {
            setFirstName(session!.user.firstName);
            setLastName(session!.user.lastName);
        }
    }, [session]);

    const handleToggleEdit = () => {
        setFirstName(session!.user.firstName);
        setLastName(session!.user.lastName);
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        const fullName = getUserFullName(firstName, lastName);
        await updateUser({
            variables: {
                id: session!.user.id,
                set: {
                    firstName,
                    lastName,
                    fullName,
                },
            },
        });
        setSession({
            user: {
                ...session!.user,
                firstName,
                lastName,
                fullName,
            },
        });
        showSnackbarAlert({ severity: 'success', message: translate('success-alert') });
        setIsEditing(false);
    };

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const editableContent = (
        <Box
            css={css`
                display: flex;
                column-gap: ${spacing.space16px};
            `}
        >
            <TextField
                value={firstName}
                onChange={handleChangeFirstName}
                label={translate('first-name-text-field-label')}
                parentBackgroundColor="green"
                css={css`
                    width: 30%;
                `}
            />
            <TextField
                value={lastName}
                onChange={handleChangeLastName}
                label={translate('last-name-text-field-label')}
                parentBackgroundColor="green"
                css={css`
                    width: 30%;
                `}
            />
        </Box>
    );

    const nonEditableContent = firstName && lastName ? getUserFullName(firstName, lastName) : session!.user.fullName;

    return (
        <EditableSettingsSection
            title={translate('title')}
            isEditing={isEditing}
            onToggleEdit={handleToggleEdit}
            editButtonLabel={translate('edit-button-label')}
            onSave={handleSave}
        >
            {isEditing ? editableContent : nonEditableContent}
        </EditableSettingsSection>
    );
};

export { UserName };
