import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

import { EmptyState } from 'shared/components/composite';
import { Button } from 'shared/components/presentational';
import { CreditCardUpload } from 'shared/components/svgs';
import { OUT_OF_AI_SOURCING_CREDITS, windowFeatures, windowTarget } from 'shared/constants';
import { spacing } from 'shared/settings';

interface NoCreditsPackageProps {}

const NoCreditsPackage: FC<NoCreditsPackageProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.no-sourcer-and-credits');

    const handleContactUs = () => {
        window.open(OUT_OF_AI_SOURCING_CREDITS, windowTarget, windowFeatures);
    };

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <CreditCardUpload
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                />
            }
            title={translate('title')}
            description={translate('description')}
            button={
                <Button
                    variant="contained"
                    css={css`
                        margin-right: ${spacing.space16px};
                    `}
                    onClick={handleContactUs}
                >
                    {translate('contact-us-button')}
                </Button>
            }
        />
    );
};

export { NoCreditsPackage };
