import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedTarget: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '20'}
        height={height ?? '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M13.3333 6.6665V4.1665L15.8333 1.6665L16.6666 3.33317L18.3333 4.1665L15.8333 6.6665H13.3333ZM13.3333 6.6665L9.99994 9.99979M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665M14.1666 9.99984C14.1666 12.301 12.3011 14.1665 9.99996 14.1665C7.69877 14.1665 5.83329 12.301 5.83329 9.99984C5.83329 7.69865 7.69877 5.83317 9.99996 5.83317"
            stroke={stroke ?? theme?.palette.secondary.dark}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Target = withTheme(ThemedTarget);

export { Target };
