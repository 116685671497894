import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { Stars02, XClose } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ChatWindowAlertBarProps {
    showAlert: boolean;
    onClose(): void;
}

const ChatWindowAlertBar: FC<ChatWindowAlertBarProps> = (props) => {
    const { showAlert, onClose } = props;
    const theme = useTheme();
    const translate = useTranslations('sourcing.request-a-sourcer-form.chat-box-specification-section.alert-bar');

    return showAlert ? (
        <Box
            css={css`
                display: flex;
                justify-content: space-between;
                background: #6b17d7;
                color: ${theme.palette.common.white};
                border-radius: 4px;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    padding: 16px;
                    align-items: flex-start;
                    gap: 20px;
                    align-self: stretch;
                    justify-content: space-between;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        background: #9747ff;
                        border-radius: 50%;
                        height: 44px;
                        width: 44px;
                        padding: 12px;
                    `}
                >
                    <Stars02
                        css={css`
                            height: 20px;
                            width: 20px;
                        `}
                        stroke={theme.palette.common.white}
                    />
                </Box>
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                    `}
                >
                    <Box
                        css={css`
                            font-weight: 700;
                            font-size: 16px;
                        `}
                    >
                        {translate('title')}
                    </Box>
                    <Box
                        css={css`
                            font-weight: 400;
                            font-size: 14px;
                            width: 700px;
                        `}
                    >
                        {translate('description')}
                    </Box>
                </Box>
            </Box>
            <Button onClick={onClose} disableRipple startIcon={<XClose stroke={theme.palette.common.white} />} />
        </Box>
    ) : null;
};

export { ChatWindowAlertBar };
