import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { SourcersQuery } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { IconButton, NoSsr } from 'shared/components/presentational';
import { DotsVertical } from 'shared/components/svgs';
import { TableCell, TableRow } from 'shared/components/table';
import { CheckboxColorTitle } from 'shared/components/table/cells';
import { dateFormatter } from 'shared/services';
import { appRoutes, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { SourcerListSubmenu } from 'sourcing/components/tables/sourcer-list-submenu';

type SourcerData = SourcersQuery['sourcers'][number];

interface SourcerListRowProps {
    sourcer: SourcerData;
    isSelected: boolean;
    onToggleSelect: (checked: boolean, sourcerId: string) => void;
}
const SourcerListRow: FC<SourcerListRowProps> = ({ sourcer, isSelected, onToggleSelect }) => {
    const translate = useTranslations('sourcing');
    const theme = useTheme();
    const router = useRouter();

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onToggleSelect(!isSelected, sourcer.id);
    };

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (event.defaultPrevented) return;
        router.push(appRoutes.sourcing.index(sourcer.id));
    };

    return (
        <>
            <TableRow
                key={sourcer.id}
                css={css`
                    ${submenuAnchor &&
                    // simulate hover when submenu is opened
                    `
                    background-color: ${theme.palette.grey[300]};
                    .MuiTableCell-root {
                        background-color: ${theme.palette.grey[300]};
                    }
                    path {
                        stroke: ${theme.palette.primary.light};
                    }
                    `}
                `}
                selected={isSelected}
                onClick={handleRowClick}
            >
                <CheckboxColorTitle
                    color={sourcer.color}
                    title={sourcer.title}
                    isChecked={isSelected}
                    handleChecked={handleSelectChange}
                >
                    {sourcer.user.fullName}
                </CheckboxColorTitle>
                <TableCell>
                    {sourcer.memberStats?.[0].toReview! > 0 ? (
                        <Box
                            component="span"
                            css={css`
                                padding: 4px 6px;
                                background-color: #fff6a2;
                                border-radius: 4px;
                            `}
                        >
                            {translate('sourcing-list-table.candidates-to-review', {
                                toReview: sourcer.memberStats?.[0].toReview!,
                            })}
                        </Box>
                    ) : (
                        <Box
                            component="span"
                            css={css`
                                padding: 4px 6px;
                                background-color: #ecf9f1;
                                border-radius: 4px;
                            `}
                        >
                            {translate('sourcing-list-table.nothing-to-review')}
                        </Box>
                    )}
                </TableCell>
                <TableCell>
                    <Link href={`/projects/${sourcer.project.id}`}>
                        <a
                            css={css`
                                font-weight: 600;
                                color: #198442;
                            `}
                        >
                            {sourcer.project.title}
                        </a>
                    </Link>
                </TableCell>
                <TableCell>
                    <Box
                        css={css`
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) min-content;
                            column-gap: ${spacing.space16px};
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <Box>
                            <NoSsr>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                    title={dateFormatter(sourcer.modifiedAt)}
                                >
                                    {dateFormatter(sourcer.modifiedAt)}
                                </Box>
                            </NoSsr>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                                title={translate('sourcing-list-table.created-by', {
                                    fullName: sourcer.user.fullName,
                                })}
                            >
                                {translate('sourcing-list-table.created-by', { fullName: sourcer.user.fullName })}
                            </Box>
                        </Box>
                        <IconButton
                            css={css`
                                margin-left: auto;
                                ${submenuAnchor &&
                                `
                                padding: 0;
                                margin-right: 8px;
                                `}

                                &:hover path {
                                    stroke: ${theme.palette.primary.light};
                                }
                            `}
                            onClick={handleSubmenuButtonClick}
                            disableRipple
                        >
                            <DotsVertical />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <SourcerListSubmenu sourcer={sourcer} anchor={submenuAnchor} setAnchor={setSubmenuAnchor} />
        </>
    );
};

export { SourcerListRow };
