import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Collapse, IconButton, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useRef } from 'react';

import { GetUserRoleQuery, UserTeamQuery } from 'codegen/graphql';
import { config } from 'config';
import { AccountAndEmailsSettingsPopover } from 'settings/components/presentational/account-and-emails';
import { AtsIntegrationsSettingPopover } from 'settings/components/presentational/ats-integrations';
import { PlansAndBillingPopover } from 'settings/components/presentational/plans-and-billing';
import { TeamPopover } from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { MenuRow } from 'shared/components/navigational';
import { Button, List, Popover } from 'shared/components/presentational';
import {
    Briefcase01,
    Chevron,
    CreditCard01,
    GoogleChrome,
    Mail03,
    SettingsAlternate,
    Users01,
} from 'shared/components/svgs';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { useSettings } from 'shared/hooks/use-settings';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const menuItems = ['account-and-emails', 'ats-integrations', 'plans-and-billing', 'team'] as const;

type MenuItem = typeof menuItems[number];
type IconMap = { [key in MenuItem]: FC<any> | undefined };

interface SettingsMenuProps {
    anchor?: HTMLElement;
    type?: 'button' | 'collapse';
}

const SettingsMenu: FC<SettingsMenuProps> = ({ anchor, type = 'collapse' }) => {
    const translate = useTranslations('settings');
    const theme = useTheme();
    const { session, loaded } = useSession();
    const {
        showMenu,
        openMenu,
        closeMenu,
        toggleMenu,
        activeSetting,
        showPopover,
        openPopover,
        closePopover,
        closeAll,
    } = useSettings();

    const { canViewBillingInformation } = useAccessControl();

    const { data: userRoleData, refetch: refetchUserRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    const { data: userTeamData, refetch: refetchUserTeamData } = useQuery<UserTeamQuery>(GET_USER_TEAM, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        // Closes the menu on the first render. This is done to prevent that
        // the create sequence page renders with the settings menu opened.
        // This can happen when we open the create sequence page
        // with the home page settings menu expanded.
        //
        closeMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const handleOpenPopover = (menuItem: MenuItem) => () => {
        refetchUserRoleData();
        refetchUserTeamData();
        openPopover(menuItem);
    };

    const planType = userTeamData?.teams?.[0]?.planType;
    const role = userRoleData?.user_roles_by_pk?.role;
    const licenseType = userRoleData?.user_roles_by_pk?.licenseType;

    const canViewBilling = canViewBillingInformation(role);
    const filteredMenuItems = menuItems.filter((menuItem) => menuItem !== 'plans-and-billing' || canViewBilling);

    const iconMap: IconMap = {
        'account-and-emails': Briefcase01,
        'ats-integrations': Mail03,
        'plans-and-billing': CreditCard01,
        team: Users01,
    };

    const settingsMenuItem = filteredMenuItems.map((menuItem) => (
        <MenuRow
            name={menuItem}
            key={menuItem}
            href="#!"
            text={translate(menuItem)}
            isActive={menuItem === activeSetting}
            onClick={handleOpenPopover(menuItem)}
        />
    ));

    const settingsMenuItemSmallView = filteredMenuItems.map((menuItem) => (
        <MenuRow
            name={menuItem}
            key={menuItem}
            href="#!"
            text={translate(menuItem)}
            isActive={menuItem === activeSetting}
            onClick={handleOpenPopover(menuItem)}
            icon={iconMap[menuItem]}
        />
    ));

    if (type === 'button') {
        return (
            <>
                <IconButton
                    css={css`
                        position: fixed;
                        bottom: ${spacing.space32px};
                        left: ${spacing.space32px};
                        background-color: ${theme.palette.common.white};
                        padding: 20px;
                        border: 1px solid ${colors.grays.gallery};

                        @media (max-width: 1200px) and (min-width: 1000px) {
                            bottom: ${spacing.space12px};
                            left: ${spacing.space12px};
                            padding: 15px;
                        }

                        @media (max-width: 1000px) {
                            bottom: ${spacing.space8px};
                            left: ${spacing.space8px};
                            padding: 10px;
                        }
                    `}
                    onClick={openMenu}
                    ref={buttonRef}
                >
                    <SettingsAlternate stroke={theme.palette.primary.main} />
                </IconButton>
                <Popover
                    open={showMenu}
                    anchorEl={buttonRef.current}
                    onClose={closeMenu}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List
                        type="unordered"
                        css={css`
                            display: flex;
                            flex-direction: column;
                            padding: ${spacing.space16px};
                            row-gap: ${spacing.space4px};
                        `}
                    >
                        <Box
                            css={css`
                                @media (max-width: 1000px) {
                                    display: none;
                                }
                            `}
                        >
                            {settingsMenuItem}
                        </Box>
                        <Box
                            css={css`
                                @media (min-width: 1000px) {
                                    display: none;
                                }
                            `}
                        >
                            {settingsMenuItemSmallView}
                        </Box>
                    </List>
                </Popover>
                <AtsIntegrationsSettingPopover
                    open={activeSetting === 'ats-integrations' && showPopover}
                    anchorEl={buttonRef.current}
                    onClose={closeAll}
                />
                {planType && licenseType && (
                    <AccountAndEmailsSettingsPopover
                        open={activeSetting === 'account-and-emails' && showPopover}
                        anchorEl={buttonRef.current}
                        onClose={closeAll}
                        planType={planType}
                        licenseType={licenseType}
                    />
                )}
                {userTeamData && role && (
                    <PlansAndBillingPopover
                        open={activeSetting === 'plans-and-billing' && showPopover}
                        anchorEl={buttonRef.current}
                        onClose={closeAll}
                        team={userTeamData?.teams[0]}
                        role={role}
                    />
                )}
                <TeamPopover
                    open={activeSetting === 'team' && showPopover}
                    anchorEl={buttonRef.current}
                    onClose={closeAll}
                />
            </>
        );
    }

    return (
        <Box>
            <Button
                css={css`
                    display: flex;
                    align-items: center;
                    padding: ${spacing.space16px} ${spacing.space32px};
                    width: 100%;
                    height: auto;

                    @media (max-width: 1000px) {
                        padding: ${spacing.space16px} 24px;
                    }
                `}
                onClick={toggleMenu}
            >
                <Box
                    css={css`
                        display: grid;
                        grid-template-columns: minmax(0, 1fr) min-content;
                        column-gap: ${spacing.space16px};
                        align-items: center;
                        width: 100%;
                    `}
                >
                    <Box
                        css={css`
                            text-align: left;
                            font-weight: ${fontWeights.normal};

                            @media (max-width: 1000px) {
                                display: none;
                            }
                        `}
                    >
                        <Box
                            css={css`
                                font-size: ${fontSizes.f14};
                                color: ${theme.palette.common.black};
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            `}
                        >
                            {session && session.user.fullName}
                        </Box>
                        <Box
                            css={css`
                                font-size: ${fontSizes.f12};
                                color: ${theme.palette.grey[200]};
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            `}
                        >
                            {session && session.user.email}
                        </Box>
                    </Box>
                    <Chevron direction={showMenu ? 'down' : 'up'} />
                </Box>
            </Button>
            <Collapse in={showMenu}>
                <List
                    type="unordered"
                    css={css`
                        display: flex;
                        flex-direction: column;
                        row-gap: ${spacing.space4px};
                        margin-bottom: ${spacing.space16px};
                        padding-left: ${spacing.space32px};

                        @media (max-width: 1000px) {
                            padding-left: 22px;
                            padding-right: 22px;
                        }
                    `}
                >
                    <Box
                        css={css`
                            @media (max-width: 1000px) {
                                display: none;
                            }
                        `}
                    >
                        {settingsMenuItem}
                        <MenuRow
                            name="chrome-extension"
                            href={config.ExtensionLink ?? '#!'}
                            text={translate('chrome-extension')}
                            openLinkInNewTab
                        />
                    </Box>
                    <Box
                        css={css`
                            @media (min-width: 1000px) {
                                display: none;
                            }
                        `}
                    >
                        {settingsMenuItemSmallView}
                        <MenuRow
                            name="chrome-extension"
                            href={config.ExtensionLink ?? '#!'}
                            text={translate('chrome-extension')}
                            icon={GoogleChrome}
                            openLinkInNewTab
                        />
                    </Box>
                </List>
            </Collapse>

            {anchor && (
                <AtsIntegrationsSettingPopover
                    open={activeSetting === 'ats-integrations' && showPopover}
                    anchorEl={anchor}
                    onClose={closePopover}
                />
            )}

            {anchor && planType && licenseType && (
                <AccountAndEmailsSettingsPopover
                    open={activeSetting === 'account-and-emails' && showPopover}
                    anchorEl={anchor}
                    onClose={closePopover}
                    planType={planType}
                    licenseType={licenseType}
                />
            )}

            {anchor && userTeamData && role && (
                <PlansAndBillingPopover
                    open={activeSetting === 'plans-and-billing' && showPopover}
                    anchorEl={anchor}
                    onClose={closeAll}
                    team={userTeamData?.teams[0]}
                    role={role}
                />
            )}

            {anchor && (
                <TeamPopover open={activeSetting === 'team' && showPopover} anchorEl={anchor} onClose={closePopover} />
            )}
        </Box>
    );
};

export { SettingsMenu };
