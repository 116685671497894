import { gql } from '@apollo/client';

export const GET_PROJECT_RECIPIENT_SEQUENCES = gql`
    query ProjectRelatedSequences($projectIds: [String!]) {
        recipient_projects(where: { projectId: { _in: $projectIds } }) {
            recipient {
                sequence {
                    id
                    title
                    status
                    userId
                }
            }
        }
    }
`;
