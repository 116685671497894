import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
    AshbyAccountByTeamIdQuery,
    GreenhouseAccountByTeamIdQuery,
    LeverAccountByTeamIdQuery,
    ProjectStatusEnum,
    UserProjectsQuery,
    UserProjectsQueryVariables,
} from 'codegen/graphql';
import { CreateSettingsFormButtons } from 'sequences/components/composite';
import { CreateEditSettingsTemplate } from 'sequences/components/templates';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { Form } from 'shared/components/form';
import { GET_ASHBY_ACCOUNT_BY_TEAM_ID } from 'shared/graphql/integrations/ashby';
import { GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID } from 'shared/graphql/integrations/greenhouse';
import { GET_LEVER_ACCOUNT_BY_TEAM_ID } from 'shared/graphql/integrations/lever';
import { GET_USER_PROJECTS, UserProjectCollaboratorType, userProjectsDefaultVariables } from 'shared/graphql/projects';
import { useSession } from 'shared/hooks';
import { FC } from 'shared/types';

interface CreateSettingsFormProps {
    onSaveAsDraft: () => void;
    onGoToPreviousStep: () => void;
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    onSubmit: () => void;
}

const CreateSettingsForm: FC<CreateSettingsFormProps> = (props) => {
    const { onSaveAsDraft, onGoToPreviousStep, onSubmit, formMethods } = props;

    const { session, loaded } = useSession();

    const topRef = useRef<HTMLElement>(null);

    const { data } = useQuery<UserProjectsQuery, UserProjectsQueryVariables>(GET_USER_PROJECTS, {
        variables: {
            orderBy: userProjectsDefaultVariables.orderBy,
            where: {
                _and: [
                    { project: { status: { _eq: ProjectStatusEnum.Active } } },
                    { collaboratorType: { _eq: UserProjectCollaboratorType.Owner } },
                ],
            },
            withDetails: false,
        },
    });

    // ashby
    const { data: ashbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(GET_ASHBY_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // greenhouse
    const { data: greenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // lever
    const { data: leverAccount } = useQuery<LeverAccountByTeamIdQuery>(GET_LEVER_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    useEffect(() => {
        topRef.current?.scrollIntoView({ behavior: 'auto' });
    }, []);

    return (
        <Box ref={topRef}>
            <Form onSubmit={onSubmit}>
                <CreateEditSettingsTemplate
                    formMethods={formMethods}
                    projects={
                        data?.user_projects.map(({ project }) => ({ label: project!.title, value: project!.id })) ?? []
                    }
                    ashbyConnected={!!ashbyAccount?.data.length}
                    greenhouseConnected={!!greenhouseAccount?.data.length}
                    leverConnected={!!leverAccount?.data.length}
                />
                <CreateSettingsFormButtons onSaveAsDraft={onSaveAsDraft} onGoToPreviousStep={onGoToPreviousStep} />
            </Form>
        </Box>
    );
};

export { CreateSettingsForm };
