import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { HireflowEnumsTeamFeaturesEnum, TeamFeaturesByTeamIdFeatureQuery } from 'codegen/graphql';
import { config } from 'config';
import { ChangeApiTokenSupport } from 'integrations/components/templates';
import { atsTypeLever } from 'integrations/constants';
import { handleConnectOauth } from 'integrations/services/lever';
import { CreateEditFormValues } from 'integrations/types/lever';
import { Box } from 'shared/components/containers';
import { Switch, TextField } from 'shared/components/form';
import { Button, SliderObfuscation, Title } from 'shared/components/presentational';
import { GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE } from 'shared/graphql/teams';
import { useSession } from 'shared/hooks';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { createRestApiClient } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ConnectEditTemplateProps {
    formMethods: UseFormReturn<CreateEditFormValues>;
    isEditing?: boolean;
}

const ConnectEditTemplate: FC<ConnectEditTemplateProps & any> = (props) => {
    const { formMethods, isEditing = false } = props;

    const theme = useTheme();
    const translate = useTranslations('integrations');
    const { session, loaded: sessionLoaded } = useSession();

    const { data: sandboxTeamFeaturesData } = useQuery<TeamFeaturesByTeamIdFeatureQuery>(
        GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE,
        {
            variables: {
                teamId: session?.user.teamId,
                feature: HireflowEnumsTeamFeaturesEnum.LeverSandboxEnabled,
            },
            skip: !sessionLoaded,
        }
    );

    const [decryptedToken, setDecryptedToken] = useState<string>('');

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') event.preventDefault();
    };

    /**
     * this is required to check if the user has chosen to permit confidential api syncs
     */
    const [_, setStoredSyncConfidential, loaded] = useLocalStorage<boolean>('storedSyncConfidential', false);

    // using oauth or api key
    const useOauth: boolean = formMethods.getValues('useOauth');

    // check if the refreshTokenExpiresAt has elapsed
    // this means that it is time for the user to reconnect their Lever oauth
    const refreshTokenExpiresAt: number = formMethods.getValues('refreshTokenExpiresAt');
    const oauthExpired: boolean = Date.now() > refreshTokenExpiresAt;

    // check if we have detected a refresh token and if it has been detected
    // on or after the refresh token was set to expire.
    // this means that the user will need to reconnect their Lever OAuth
    const invalidRefreshTokenDetectedAt: number = formMethods.getValues('invalidApiTokenDetectedAt');
    const invalidRefreshTokenDetected: boolean =
        invalidRefreshTokenDetectedAt !== 0 && invalidRefreshTokenDetectedAt >= refreshTokenExpiresAt;

    const syncConfidential: boolean = formMethods.getValues('syncConfidential');
    const previousSyncConfidential: boolean = formMethods.getValues('previousSyncConfidential');

    const onClick = () => {
        if (!session) return;

        if (loaded && syncConfidential) setStoredSyncConfidential(true);
        const isProduction = config.Env === 'production';
        const isSandbox: boolean = !isProduction || !!sandboxTeamFeaturesData?.data.length;
        handleConnectOauth({
            teamId: session.user.teamId,
            syncConfidential,
            isSandbox,
        });
    };

    const isConnectOauthBtnHidden = (): boolean => {
        if (useOauth && isEditing && !previousSyncConfidential && syncConfidential) return false;
        const invalidOAuthCredentials = oauthExpired || invalidRefreshTokenDetected;
        if (!useOauth || !isEditing || !invalidOAuthCredentials) return true;
        return false;
    };

    const decryptToken = async () => {
        const restApiClient = createRestApiClient();
        const response = await restApiClient.decryptApiToken({ atsType: atsTypeLever });
        setDecryptedToken(response?.data?.decryptedToken ?? '');
    };

    useEffect(() => {
        decryptToken();
    }, []);

    return (
        <>
            <Box
                css={css`
                    padding-bottom: ${spacing.space8px};
                `}
            >
                <Switch
                    disabled={isEditing}
                    label={translate('lever.use-oauth')}
                    formMethods={formMethods}
                    name="useOauth"
                />
            </Box>
            <Box
                css={css`
                    padding-bottom: ${spacing.space8px};
                `}
            >
                <Switch
                    label={translate('lever.sync-confidential')}
                    formMethods={formMethods}
                    name="syncConfidential"
                />
            </Box>
            <Box hidden={useOauth}>
                <TextField
                    type={isEditing ? 'password' : 'text'}
                    disabled={isEditing}
                    css={css`
                        width: 100%;
                    `}
                    parentBackgroundColor="green"
                    control={formMethods.control}
                    name="apiToken"
                    label={translate('lever.api-token')}
                    onKeyDown={keyDownHandler}
                />
                {isEditing ? <ChangeApiTokenSupport /> : null}
                {isEditing ? (
                    <Box
                        css={css`
                            flex-grow: 1;
                            margin: ${spacing.space48px} 0px;
                        `}
                    >
                        <Title
                            type="h4"
                            css={css`
                                font-size: ${fontSizes.f20};
                            `}
                        >
                            {translate('slide-to-view-token')}
                        </Title>
                        <SliderObfuscation text={decryptedToken} />
                    </Box>
                ) : null}
            </Box>
            <Box hidden={isConnectOauthBtnHidden()}>
                <Box
                    css={css`
                        padding-top: ${spacing.space8px};
                        padding-bottom: ${spacing.space8px};
                        color: ${theme.palette.error.main};
                    `}
                >
                    {translate('lever.details.connect-oauth-warning')}
                </Box>
                <Button variant="contained" onClick={onClick}>
                    {translate('lever.details.connect-oauth')}
                </Button>
            </Box>
        </>
    );
};

export { ConnectEditTemplate };
