import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface SendFromEmailProps {
    sendFromEmail: string;
    setSendFromEmail: Dispatch<SetStateAction<string>>;
}

const SendFromEmailContext = createContext<SendFromEmailProps>({
    sendFromEmail: '',
    setSendFromEmail: () => dispatchEvent,
});

/**
 * this provider is used to capture the "send from" selected email address in the "who" section
 * on the create/update sequences pages so that this email can be referenced when linking out
 * to the user's relevant gmail signature page.
 */
const SendFromEmailContextProvider: FC<SendFromEmailProps> = ({ sendFromEmail, setSendFromEmail, children }) => {
    const value = useMemo(() => ({ sendFromEmail, setSendFromEmail }), [sendFromEmail, setSendFromEmail]);

    return <SendFromEmailContext.Provider value={value}>{children}</SendFromEmailContext.Provider>;
};

const useSendFromEmail = () => useContext(SendFromEmailContext);

export { SendFromEmailContext, SendFromEmailContextProvider, useSendFromEmail };
