import { SourcerCollaboratorTypeEnum } from 'codegen/graphql';
import { useSession } from 'shared/hooks/use-session';
import { SourcerForAccessControl } from 'shared/types';

export const useSourcerAccessControl = () => {
    const { session } = useSession();

    const isOwner = (sourcer: SourcerForAccessControl) => sourcer.userId === session?.user.id;
    const isCollaborator = (sourcer: SourcerForAccessControl) =>
        sourcer.collaborators.some((collaborator) => collaborator.userId === session?.user.id);
    const isEditor = (sourcer: SourcerForAccessControl) =>
        sourcer.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === SourcerCollaboratorTypeEnum.Editor
        );

    const isViewer = (sourcer: SourcerForAccessControl) =>
        sourcer.collaborators.some(
            (collaborator) =>
                collaborator.userId === session?.user.id && collaborator.type === SourcerCollaboratorTypeEnum.Viewer
        );
    const canPauseSourcer = (sourcer: SourcerForAccessControl) => isOwner(sourcer);

    const canEditSourcerSettings = (sourcer: SourcerForAccessControl) => isOwner(sourcer);

    const canArchiveSourcer = (sourcer: SourcerForAccessControl) => isOwner(sourcer);

    const canViewSourcer = (sourcer: SourcerForAccessControl) => isOwner(sourcer) || isCollaborator(sourcer);

    const canManageSourcerMembers = (sourcer: SourcerForAccessControl) => isOwner(sourcer) || isEditor(sourcer);

    const canShareSourcer = (sourcer: SourcerForAccessControl) => isOwner(sourcer);

    return {
        canEditSourcerSettings,
        canPauseSourcer,
        canArchiveSourcer,
        canViewSourcer,
        canManageSourcerMembers,
        canShareSourcer,
        isViewer,
    };
};
