import {
    ApolloClient,
    ApolloLink,
    ApolloProvider as DefaultApolloProvider,
    from,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import React, { useEffect, useMemo } from 'react';

import { config } from 'config';
import { logger } from 'logger';
import { HasuraRole } from 'shared/types';
import { ssr } from 'ssr-check';

const secure = config.GraphAPIEndpoint.match(/^(http|ws):\/\//)
    ? false
    : config.GraphAPIEndpoint.match(/^(http|ws)s:\/\//)
    ? true
    : config.Env !== 'development';
const webSocketUri = `${secure ? 'wss' : 'ws'}://${config.GraphAPIEndpoint.replace(/^(http|ws)(s?):\/\//, '')}`;
const httpUri = `${secure ? 'https' : 'http'}://${config.GraphAPIEndpoint.replace(/^(http|ws)(s?):\/\//, '')}`;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            logger.error(message, {
                kind: 'apollo graphql error',
                locations,
                operation: operation?.operationName,
                path,
                variables: operation?.variables,
            })
        );
    }

    if (networkError) {
        logger.warn('Apollo network error', {
            operation: operation?.operationName,
            variables: operation?.variables,
        });
    }
});

const headers = (cookie?: string, hasuraRole?: HasuraRole) => {
    const authHeader: Record<string, string> = {};
    if (cookie) {
        authHeader.cookie = cookie;
    }
    if (hasuraRole) {
        authHeader['x-hasura-role'] = hasuraRole;
    }

    return { ...authHeader };
};

interface SplitLinkParams {
    cookie?: string;
    hasuraRole?: HasuraRole;
}

const splitLink = (params?: SplitLinkParams) => {
    const { cookie, hasuraRole } = params || {};

    const httpLink = new HttpLink({
        fetch,
        uri: httpUri,
        credentials: 'include',
        headers: headers(cookie, hasuraRole),
    });

    let link: ApolloLink = httpLink;

    if (!ssr) {
        const wsLink = new GraphQLWsLink(
            createClient({
                connectionParams: {
                    headers: headers(cookie, hasuraRole),
                },
                url: webSocketUri,
            })
        );
        link = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            httpLink
        );
    }
    return link;
};

interface GetClientParams {
    cookie?: string;
    hasuraRole?: HasuraRole;
}

export const getClient = (params?: GetClientParams) =>
    new ApolloClient({
        ssrMode: ssr,
        cache: new InMemoryCache({
            typePolicies: {
                hireflow_function_interfaces_prospect_contacts: {
                    // The prospect.contacts field is an array of objects, each with a unique id.
                    // However, depending on the prospect, the primary field of a prospect_contact will be different.
                    // So for the same prospect_contact, we can have different primary fields.
                    // This creates a problem with the cache.
                    // The cache uses the id field to identify the object, so it will only store one of the objects.
                    // It will merge the other objects with the same id into the one it has stored,
                    // creating bugs in the UI.
                    // One solution is to disable the cache for this field. That is what we do here.
                    // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
                    keyFields: false,
                },
            },
        }),

        connectToDevTools: true,
        link: from([errorLink, splitLink(params)]),
        defaultOptions: {
            query: {
                notifyOnNetworkStatusChange: true,
            },
            watchQuery: {
                notifyOnNetworkStatusChange: true,
            },
        },
    });

let client: ApolloClient<NormalizedCacheObject>;

interface ApolloProviderProps {
    children: React.ReactNode;
    initialState?: NormalizedCacheObject;
    hasuraRole?: HasuraRole;
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children, initialState, hasuraRole }) => {
    // based on https://developers.wpengine.com/blog/apollo-client-cache-rehydration-in-next-js
    const providerClient = useMemo(() => {
        const newClient = client ?? getClient({ hasuraRole });

        // If your page has Next.js data fetching methods that use Apollo Client,
        // the initial state gets hydrated here
        if (initialState) {
            // Disable network fetches so first client render matches server render.
            // Based on https://github.com/apollographql/apollo-client/issues/4814
            newClient.disableNetworkFetches = true;

            // Get existing cache, loaded during client side data fetching
            const existingCache = newClient.extract();

            // Restore the cache using the data passed from
            // getStaticProps/getServerSideProps combined with the existing cached data
            newClient.cache.restore({ ...existingCache, ...initialState });
        }

        // Create the Apollo Client once in the client
        if (!client) client = newClient;
        return newClient;
    }, [initialState, hasuraRole]);

    // Re-enable network fetches after hydration
    useEffect(() => {
        providerClient.disableNetworkFetches = false;
    }, [providerClient]);

    return <DefaultApolloProvider client={providerClient}>{children}</DefaultApolloProvider>;
};
