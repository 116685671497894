import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedGreenhouseLogo: FC<SvgProps> = ({ fill, theme, ...rest }) => (
    <svg width="11" height="23" viewBox="0 0 11 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M9.60842 6.83215C9.60842 7.94321 9.13895 8.92557 8.36843 9.69466C7.51166 10.5486 6.2706 10.7621 6.2706 11.4887C6.2706 12.4711 7.8544 12.1717 9.37378 13.6878C10.38 14.6922 11 16.0166 11 17.5537C11 20.5862 8.56048 23 5.50009 23C2.43951 23 0 20.589 0 17.5565C0 16.0185 0.621008 14.694 1.6264 13.6907C3.14577 12.1736 4.7294 12.473 4.7294 11.4905C4.7294 10.764 3.48941 10.5506 2.63263 9.69643C1.86212 8.92753 1.39157 7.94499 1.39157 6.79154C1.39157 4.57146 3.21003 2.77651 5.43566 2.77651C5.86449 2.77651 6.24983 2.84074 6.57075 2.84074C7.14814 2.84074 7.44829 2.58473 7.44829 2.1785C7.44829 1.94326 7.34037 1.64474 7.34037 1.32448C7.34037 0.59801 7.96126 0 8.71012 0C9.45986 0 10.0591 0.619727 10.0591 1.36699C10.0591 2.15678 9.43803 2.51954 8.96766 2.69149C8.58232 2.81902 8.28217 2.99001 8.28217 3.3745C8.28217 4.09817 9.60949 4.80386 9.60949 6.83215H9.60842ZM9.18243 17.5565C9.18243 15.4423 7.62046 13.7333 5.50097 13.7333C3.38238 13.7333 1.8204 15.4423 1.8204 17.5565C1.8204 19.6491 3.38238 21.3798 5.50097 21.3798C7.61957 21.3798 9.18243 19.6472 9.18243 17.5565ZM7.91866 6.78964C7.91866 5.44438 6.82723 4.33339 5.50009 4.33339C4.17383 4.33339 3.08223 5.44438 3.08223 6.78964C3.08223 8.135 4.17383 9.24592 5.50009 9.24592C6.82723 9.24592 7.91866 8.135 7.91866 6.78964Z"
            fill={fill ?? theme?.palette?.primary?.main}
        />
    </svg>
);

const GreenhouseLogo = withTheme(ThemedGreenhouseLogo);

export { GreenhouseLogo };
