import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';
import { ChatMessage, Message } from 'sourcing/components/composite';

interface MessageListProps {
    conversations: ChatMessage[];
}

const MessageList: FC<MessageListProps> = (props) => {
    const { conversations } = props;

    const messages = conversations.map((conversation, index) => <Message key={index} message={conversation} />);

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 12px 0px;
                gap: 12px;
            `}
        >
            {messages}
        </Box>
    );
};

export { MessageList };
