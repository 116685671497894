import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { ShareSequenceListSubmenu } from 'sequences/components/presentational/sharing';
import { SequenceCollaboratorForSharing } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { Chevron } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ShareSequenceListRowProps {
    collaborator: SequenceCollaboratorForSharing;
    onRemove: (collaboratorId: string) => void;
}
const ShareSequenceListRow: FC<ShareSequenceListRowProps> = ({ collaborator, onRemove }) => {
    const theme = useTheme();
    const translate = useTranslations('sequence.details.sharing');

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    return (
        <Box
            component="li"
            css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid ${theme.palette.grey[500]};
                &:last-child {
                    border: none;
                }
            `}
        >
            <Box
                css={css`
                    display: flex;
                `}
            >
                <Box
                    css={css`
                        font-size: 16px;
                        margin-right: 5px;
                    `}
                >
                    {collaborator.user.fullName}
                </Box>
                {translate('separator')}
                <Box
                    css={css`
                        font-size: 14px;
                        margin-left: 5px;
                    `}
                >
                    {collaborator.user.email}
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                `}
            >
                <Box>
                    {collaborator.type === 'owner' ? (
                        <Box
                            css={css`
                                height: 40px;
                                margin-right: 40px;
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {translate('owner')}
                        </Box>
                    ) : (
                        translate(collaborator.type)
                    )}
                </Box>
                {collaborator.type !== 'owner' && (
                    <IconButton
                        css={css`
                            width: 40px;
                            height: 40px;
                            margin-left: auto;
                            &:hover path {
                                stroke: ${theme.palette.primary.light};
                            }
                        `}
                        onClick={handleSubmenuButtonClick}
                        disableRipple
                    >
                        {submenuAnchor ? <Chevron direction="up" /> : <Chevron direction="down" />}
                    </IconButton>
                )}
            </Box>

            <ShareSequenceListSubmenu
                collaborator={collaborator}
                anchor={submenuAnchor}
                setAnchor={setSubmenuAnchor}
                onRemove={onRemove}
            />
        </Box>
    );
};
export { ShareSequenceListRow };
