import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { trackEvent } from 'analytics';
import { ProjectMembersCountQuery, ProjectMembersCountQueryVariables, ProjectMembersStatusEnum } from 'codegen/graphql';
import { config } from 'config';
import { UserPins } from 'pins/components/user-pins';
import { SettingsMenu } from 'settings/components/presentational';
import { Box } from 'shared/components/containers';
import { MenuRow, MenuTitle } from 'shared/components/navigational';
import { Hyperlink, List, LogoutButton, Tooltip } from 'shared/components/presentational';
import { MessageAlertCircle } from 'shared/components/svgs';
import { sideMenuSmallWidth, sideMenuWidth } from 'shared/constants';
import { GET_PROJECT_MEMBERS_COUNT } from 'shared/graphql/project-members';
import { useSession } from 'shared/hooks';
import { appRoutes, colors, menuSettings, spacing } from 'shared/settings';
import { FC, SideMenuRow } from 'shared/types';
import { isChromeExtensionInstalled } from 'shared/utils/extension';

const SideMenu: FC<unknown> = () => {
    const { pathname } = useRouter();
    const translate = useTranslations('side-bar');
    const { session, loaded } = useSession();
    const sideMenuRef = useRef<HTMLElement>(null);
    const [sideMenuAnchor, setSideMenuAnchor] = useState<HTMLElement>();
    const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean>(true); // assume true

    const { data } = useQuery<ProjectMembersCountQuery, ProjectMembersCountQueryVariables>(GET_PROJECT_MEMBERS_COUNT, {
        skip: !loaded || !session,
        variables: {
            where: {
                status: { _eq: ProjectMembersStatusEnum.ToReview },
                project: { reviewerId: { _eq: session?.user.id } },
            },
        },
    });

    const checkExtensionInstall = async () => {
        const current = await isChromeExtensionInstalled();
        setIsExtensionInstalled((prev) => {
            if (prev === false && current) {
                trackEvent('extension_installed');
            }
            return current;
        });
    };

    useEffect(() => {
        checkExtensionInstall();
    }, []);

    useEffect(() => {
        setSideMenuAnchor(sideMenuRef.current || undefined);
    }, [sideMenuRef]);

    return (
        <NavWrapper ref={sideMenuRef}>
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow: auto;

                    border-radius: 0px 8px 8px 0px;
                    border-width: 1px 1px 1px 0px;
                    border-style: solid;
                    border-color: ${colors.grays.gallery};
                `}
            >
                <Box
                    css={css`
                        padding-top: 20px;
                        padding-left: ${spacing.space32px};
                        padding-right: ${spacing.space32px};
                        flex-grow: 1;

                        @media (max-width: 1000px) {
                            padding-left: 22px;
                            padding-right: 22px;
                        }
                    `}
                >
                    <Hyperlink href={appRoutes.home.index()}>
                        <Image src="/hireflow-logo.png" alt="hireflow logo" width={30} height={30} />
                    </Hyperlink>

                    <MenuTitle>{translate('main')}</MenuTitle>
                    <List
                        css={css`
                            display: flex;
                            flex-flow: column nowrap;
                            gap: ${spacing.space4px};
                        `}
                        type="unordered"
                    >
                        {menuSettings.mainMenuRows(translate).map((menuItem: SideMenuRow) => (
                            <MenuRow {...menuItem} key={menuItem.text} isActive={pathname.includes(menuItem.name)} />
                        ))}
                    </List>
                    <MenuTitle>{translate('for-you')}</MenuTitle>
                    <List
                        css={css`
                            display: flex;
                            flex-flow: column nowrap;
                            gap: ${spacing.space4px};
                        `}
                        type="unordered"
                    >
                        {menuSettings.forYouMenuRows(translate).map((menuItem: SideMenuRow) => (
                            <MenuRow
                                {...menuItem}
                                key={menuItem.text}
                                isActive={pathname.includes(menuItem.name)}
                                {...(menuItem.name === 'reviewer' && {
                                    count: data?.project_members_aggregate.aggregate?.count,
                                })}
                            />
                        ))}
                    </List>

                    <UserPins />
                </Box>

                {/* extension installed check */}
                {!isExtensionInstalled && (
                    <Box
                        css={css`
                            padding: 20px 32px 0;

                            @media (max-width: 1000px) {
                                padding-left: 22px;
                                padding-right: 22px;
                            }
                        `}
                    >
                        <Box
                            css={css`
                                padding: 12px;

                                border: 1px solid #f6d672;
                                border-radius: 4px;
                                background-color: #fff6a2;

                                font-weight: 700;
                                font-size: 12px;
                                line-height: 17px;
                                font-family: Inter, sans-serif;
                                color: black;

                                @media (max-width: 1000px) {
                                    padding: 0;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            `}
                        >
                            <Box
                                css={css`
                                    @media (max-width: 1000px) {
                                        display: none;
                                    }
                                `}
                            >
                                <MessageAlertCircle stroke="black" />
                            </Box>
                            <Box
                                css={css`
                                    @media (min-width: 1000px) {
                                        display: none;
                                    }
                                `}
                            >
                                <IconButton onClick={() => window.open(config.ExtensionLink ?? '#!')}>
                                    <Tooltip title={translate('extension-installed-tooltip')} placement="right">
                                        <MessageAlertCircle width={18} height={18} stroke="black" />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                            <Box
                                css={css`
                                    @media (max-width: 1000px) {
                                        display: none;
                                    }
                                `}
                            >
                                {translate.rich('extension-installed-check', { link: Link })}
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* settings menu */}
                <Box
                    css={css`
                        margin-bottom: ${spacing.space8px};
                    `}
                >
                    <Box
                        css={css`
                            margin-top: 16px;

                            padding-left: 32px;
                            padding-right: 32px;

                            @media (max-width: 1000px) {
                                padding-left: 22px;
                                padding-right: 22px;
                            }
                        `}
                    >
                        <Divider />
                    </Box>
                    <SettingsMenu anchor={sideMenuAnchor} />
                    <Box
                        css={css`
                            padding-left: 32px;
                            padding-right: 32px;

                            @media (max-width: 1000px) {
                                padding-left: 22px;
                                padding-right: 22px;
                            }
                        `}
                    >
                        <Divider />
                        <LogoutButton />
                    </Box>
                </Box>
            </Box>
        </NavWrapper>
    );
};
const NavWrapper = styled.nav<{ theme?: Theme }>`
    width: ${sideMenuWidth};
    height: 100vh;
    flex-shrink: 0;
    background: ${({ theme }) => theme.palette.common.white};
    position: relative;
    left: 0;
    color: ${({ theme }) => theme.palette.secondary.light};

    @media (max-width: 1000px) {
        width: ${sideMenuSmallWidth};
    }
`;

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: black !important;
        `}
        href={config.ExtensionLink ?? '#!'}
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

export { SideMenu };
