import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedCheck: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M13.3327 4L5.99935 11.3333L2.66602 8"
            stroke={stroke ?? theme?.palette?.common?.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Check = withTheme(ThemedCheck);

export { Check };
