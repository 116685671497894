import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import { UpdateProspectMutation } from 'codegen/graphql';
import {
    AddToUnsubscribeListModal,
    ChangeRecipientStatusModal,
    ReportWrongEmailModal,
    ResendSequenceNewEmailModal,
    StopSequenceModal,
} from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import { GET_PROSPECT_PANEL_INFO, UPDATE_PROSPECT } from 'shared/graphql/prospects';
import { ProspectRecipientData } from 'shared/graphql/recipients';
import { useAccessControl, useProspectPanel, useSnackbarAlert } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectSubmenuProps {
    recipient?: ProspectRecipientData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
    openAddTab: () => void;
}

const ProspectSubmenu: FC<ProspectSubmenuProps> = (props) => {
    const { anchor, openAddTab, recipient: mainRecipient, setAnchor } = props;
    const { prospectInfo, context, addOrUpdateProspect, recipients } = useProspectPanel();
    const {
        canChangeRecipientStatus,
        canReportWrongRecipientEmail,
        canStopSequenceForRecipient,
        canUnsubscribeRecipient,
        canResendSequenceToNewEmail,
    } = useAccessControl();

    const translate = useTranslations('prospects.submenu-options');
    const { showSnackbarAlert } = useSnackbarAlert();

    const profile = prospectInfo?.profile;
    const url = prospectInfo?.urls?.[0]?.url;

    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
    const [showReportWrongEmailModal, setShowReportWrongEmailModal] = useState(false);
    const [showStopSequenceModal, setShowStopSequenceModal] = useState(false);
    const [showResendNewEmailModal, setShowResendNewEmailModal] = useState(false);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

    const handleStatusChange = () => {
        setShowChangeStatusModal(true);
    };

    const handleClickReportWrongEmailSubMenu = () => {
        setShowReportWrongEmailModal(true);
    };

    const handleUnsubscribe = () => {
        setShowUnsubscribeModal(true);
    };

    const handleOpenResendNewEmailModal = () => {
        setShowResendNewEmailModal(true);
    };

    const [updateProspect] = useMutation<UpdateProspectMutation>(UPDATE_PROSPECT, {
        refetchQueries: [getOperationName(GET_PROSPECT_PANEL_INFO) as string],
    });

    const handleMarkAsInMailSent = async () => {
        if (profile && url) {
            let id = context?.prospectId;
            if (!id) {
                id = await addOrUpdateProspect(profile, url);
            }
            if (id) {
                await updateProspect({
                    variables: {
                        id,
                        set: {
                            inMailSent: true,
                            inMailSentTime: Date.now(),
                        },
                    },
                });
            }
            showSnackbarAlert({
                severity: 'success',
                message: translate('mark-as-in-mail-sent', {
                    prospectName: profile.firstNameCustomized ?? profile.firstName,
                }),
            });
            handleOnClose();
            trackEvent('click_mark_as_inmail_sent', { prospect_id: id });
        }
    };

    const handleCloseResendNewEmailModal = () => {
        setShowResendNewEmailModal(false);
        handleOnClose();
    };

    const handleCloseAddToUnsubscribeListModal = () => {
        setShowUnsubscribeModal(false);
        handleOnClose();
    };

    const handleCloseChangeStatusModal = () => {
        setShowChangeStatusModal(false);
        handleOnClose();
    };

    const handleCloseReportWrongEmailModal = () => {
        setShowReportWrongEmailModal(false);
        handleOnClose();
    };

    const handleCloseStopSequenceModal = () => {
        setShowStopSequenceModal(false);
        handleOnClose();
    };

    const handleOpenAddTab = () => {
        openAddTab();
        setAnchor(undefined);
    };

    const handleOnClose = () => setAnchor(undefined);

    const handleStopSequence = () => {
        setShowStopSequenceModal(true);
    };

    // Logic for the disabled state of the submenu buttons uses the mainRecipient, i.e., the one whose status and
    // sequence is shown in the prospect panel top bar. That is context dependent, if we're in the recipients' table
    // then the main recipient is the recipient clicked on the table, otherwise it's the newest recipient tied
    // to this prospect.

    const disableChangeStatus = mainRecipient ? !canChangeRecipientStatus(mainRecipient) : true;

    const disableWrongEmail = mainRecipient ? !canReportWrongRecipientEmail(mainRecipient) : true;

    const disableStopSequence = mainRecipient ? !canStopSequenceForRecipient(mainRecipient) : true;

    const disableUnsubscribed = mainRecipient ? !canUnsubscribeRecipient(mainRecipient) : true;

    const disabledResendNewEmail = mainRecipient ? !canResendSequenceToNewEmail(mainRecipient) : true;

    // In some contexts, we allow multiple recipients to be updated. In those cases, we need to filter out the
    // recipients that can be selected. That depends on the action to be performed. For example, if we're changing
    // the recipients status, the recipient must not have the status 'unsubscribed' or 'meeting_booked'.
    // If the action can't be performed on the main recipient, then the submenu button is disabled even if it could
    // be performed in other recipients. This is to avoid confusion, i.e., believing to be updating the mainRecipient,
    // but update another one instead.

    const changeStatusRecipients =
        context?.flow === 'recipient' && mainRecipient
            ? [mainRecipient]
            : recipients?.filter((recipient) => canChangeRecipientStatus(recipient)) ?? [];

    const reportWrongEmailRecipients =
        context?.flow === 'recipient' && mainRecipient
            ? [mainRecipient]
            : recipients?.filter((recipient) => canReportWrongRecipientEmail(recipient)) ?? [];

    const stopSequenceRecipients =
        context?.flow === 'recipient' && mainRecipient
            ? [mainRecipient]
            : recipients?.filter((recipient) => canStopSequenceForRecipient(recipient)) ?? [];

    const resendSequenceRecipients =
        context?.flow === 'recipient' && mainRecipient
            ? [mainRecipient]
            : recipients?.filter((recipient) => canResendSequenceToNewEmail(recipient)) ?? [];

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleOnClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    <SubmenuButton onClick={handleOpenAddTab}>
                        {translate('add-to-project-or-sequence-label')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disableChangeStatus} onClick={handleStatusChange}>
                        {translate('change-status-label')}
                    </SubmenuButton>
                    <SubmenuButton onClick={handleMarkAsInMailSent} disabled={prospectInfo?.inMailSent ?? false}>
                        {translate('mark-as-inmail-label')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disableStopSequence} onClick={handleStopSequence}>
                        {translate('stop-sequence-label')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disabledResendNewEmail} onClick={handleOpenResendNewEmailModal}>
                        {translate('re-send-sequence-to-new-email')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disableWrongEmail} onClick={handleClickReportWrongEmailSubMenu}>
                        {translate('report-wrong-email-label')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disableUnsubscribed} onClick={handleUnsubscribe}>
                        {translate('unsubscribe-prospect')}
                    </SubmenuButton>
                </Box>
            </Popover>
            <>
                {prospectInfo && profile?.id && mainRecipient && (
                    <ChangeRecipientStatusModal
                        open={showChangeStatusModal}
                        onClose={handleCloseChangeStatusModal}
                        recipients={changeStatusRecipients}
                    />
                )}
                {mainRecipient && (
                    <ReportWrongEmailModal
                        open={showReportWrongEmailModal}
                        recipients={reportWrongEmailRecipients}
                        onClose={handleCloseReportWrongEmailModal}
                    />
                )}
                {mainRecipient && prospectInfo && (
                    <StopSequenceModal
                        open={showStopSequenceModal}
                        recipients={stopSequenceRecipients}
                        onClose={handleCloseStopSequenceModal}
                    />
                )}
                {mainRecipient && prospectInfo && (
                    <ResendSequenceNewEmailModal
                        open={showResendNewEmailModal}
                        onClose={handleCloseResendNewEmailModal}
                        recipients={resendSequenceRecipients}
                    />
                )}
                {mainRecipient && prospectInfo && (
                    <AddToUnsubscribeListModal
                        open={showUnsubscribeModal}
                        onClose={handleCloseAddToUnsubscribeListModal}
                        recipientId={mainRecipient.id}
                        sequenceId={mainRecipient.sequence.id}
                        name={
                            mainRecipient.prospect.profile?.fullNameCustomized ??
                            mainRecipient.prospect.profile?.fullName ??
                            ''
                        }
                    />
                )}
            </>
        </>
    );
};

export { ProspectSubmenu };
