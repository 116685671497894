import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface UserNoticeProps {
    icon: React.ReactNode;
    title: string;
    description: string;
}

const UserNotice: FC<UserNoticeProps> = ({ icon, title, description, children }) => {
    const theme = useTheme();

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: ${spacing.space16px};

                background-color: ${theme.palette.common.white};
                border-radius: 4px;
                height: 717px;
            `}
        >
            {icon}
            <Box
                css={css`
                    font-weight: ${fontWeights.bold};
                    font-size: ${fontSizes.f32};
                `}
            >
                {title}
            </Box>
            <Box
                css={css`
                    font-weight: ${fontWeights.normal};
                    font-size: ${fontSizes.f16};
                    width: 550px;
                    text-align: center;
                `}
            >
                {description}
            </Box>
            {children}
        </Box>
    );
};

export { UserNotice };
