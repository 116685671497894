import { gql, useMutation, useQuery } from '@apollo/client';
import { Reference, getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import {
    InviteSequenceCollaboratorsMutation,
    InviteSequenceCollaboratorsMutationVariables,
    LicenseTypesEnum,
    RemoveSequenceCollaboratorMutation,
    RemoveSequenceCollaboratorMutationVariables,
    UsersQuery,
    UsersQueryVariables,
} from 'codegen/graphql';
import { ShareSequenceForm, ShareSequenceList } from 'sequences/components/presentational/sharing';
import { ExtendedSequenceCollaboratorType } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { INVITE_SEQUENCE_COLLABORATORS, REMOVE_SEQUENCE_COLLABORATOR } from 'shared/graphql/sequence-collaborators';
import { GET_SEQUENCE_COLLABORATOR_ACTIVITIES } from 'shared/graphql/sequence-collaborators-activities';
import { GET_USERS } from 'shared/graphql/users';
import { useSnackbarAlert } from 'shared/hooks';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface ShareSequenceProps {
    sequence: {
        id: string;
        user: { id: string; fullName: string; email: string };
        collaborators: {
            id: string;
            user: { id: string; fullName: string; email: string };
        }[];
    };
}
const ShareSequence: FC<ShareSequenceProps> = ({ sequence }) => {
    const theme = useTheme();
    const { showSnackbarAlert } = useSnackbarAlert();
    const translate = useTranslations('sequence.details.sharing');

    const sequenceCollaboratorsIncludingOwner = [
        ...sequence.collaborators.map((c) => ({ ...c, type: 'viewer' as ExtendedSequenceCollaboratorType })),
        { type: 'owner' as ExtendedSequenceCollaboratorType, user: sequence.user },
    ];
    // get all users that can be added as collaborators
    const { data: userData } = useQuery<UsersQuery, UsersQueryVariables>(GET_USERS, {
        variables: {
            where: {
                id: { _nin: sequenceCollaboratorsIncludingOwner.map((collaborator) => collaborator.user.id) },
                licenseType: { _neq: LicenseTypesEnum.Archived },
            },
        },
    });

    const [inviteSequenceCollaborators] = useMutation<
        InviteSequenceCollaboratorsMutation,
        InviteSequenceCollaboratorsMutationVariables
    >(INVITE_SEQUENCE_COLLABORATORS, {
        refetchQueries: [getOperationName(GET_SEQUENCE_COLLABORATOR_ACTIVITIES) as string],
        update(cache, { data }) {
            cache.modify({
                id: cache.identify(sequence),
                fields: {
                    collaborators(existingCollaboratorRefs = []) {
                        const newCollaboratorRefs =
                            data?.insert_sequence_collaborators?.newCollaborators.map((newCollaborator) => {
                                const newCollaboratorRef = cache.writeFragment({
                                    data: newCollaborator,
                                    fragment: gql`
                                        fragment NewSequenceCollaborator on sequence_collaborators {
                                            id
                                        }
                                    `,
                                });
                                return newCollaboratorRef;
                            }) ?? [];

                        return [...existingCollaboratorRefs, ...newCollaboratorRefs];
                    },
                },
            });
        },
    });

    const [removeSequenceCollaborator] = useMutation<
        RemoveSequenceCollaboratorMutation,
        RemoveSequenceCollaboratorMutationVariables
    >(REMOVE_SEQUENCE_COLLABORATOR, {
        update(cache, { data }) {
            const idToRemove = data?.deleted?.id;
            cache.modify({
                id: cache.identify(sequence),
                fields: {
                    collaborators(existingCollaboratorsRefs, { readField }) {
                        return existingCollaboratorsRefs.filter(
                            (collaboratorRef: Reference) => idToRemove !== readField('id', collaboratorRef)
                        );
                    },
                },
            });
        },
    });

    const contributorOptions =
        userData?.users.map((user) => ({ label: user.fullName, value: user.email, id: user.id })) ?? [];

    const addCollaborators = async (userIds: string[]) => {
        const newCollaborators = userIds.map((userId) => ({ sequenceId: sequence.id, userId }));
        try {
            await inviteSequenceCollaborators({
                variables: {
                    collaborators: newCollaborators,
                },
            });
            showSnackbarAlert({
                severity: 'success',
                message: translate('add-collaborator-success-alert'),
            });
        } catch (e: any) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('add-collaborator-error-alert'),
            });
        }
    };

    const onRemove = async (collaboratorId: string) => {
        try {
            await removeSequenceCollaborator({
                variables: {
                    id: collaboratorId,
                },
            });
            showSnackbarAlert({
                severity: 'success',
                message: translate('collaborator-removed-alert'),
            });
        } catch (e) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('request-failed'),
            });
        }
    };

    return (
        <Box>
            <ShareSequenceForm userOptions={contributorOptions} addCollaborators={addCollaborators} />
            <Box
                css={css`
                    padding: 10px;
                `}
            >
                <Box
                    component="p"
                    css={css`
                        font-weight: ${fontWeights.medium};
                        font-size: ${fontSizes.f12};
                        line-height: 15px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        color: ${theme.palette.grey[200]};
                    `}
                >
                    {translate('nbr-of-collaborators', { count: sequenceCollaboratorsIncludingOwner.length })}
                </Box>

                <ShareSequenceList collaborators={sequenceCollaboratorsIncludingOwner} onRemove={onRemove} />
            </Box>
        </Box>
    );
};

export { ShareSequence };
