// These thresholds are used to determine the color of the metrics in the project and sequence details pages.

const neutralThreshold = 0;

const openPercentageThresholds = {
    green: 0.6,
    yellow: 0.3,
};

const repliesPercentageThresholds = {
    green: 0.2,
    yellow: 0.05,
};

const interestedPercentageThresholds = {
    green: 0.1,
    yellow: 0.02,
};

export { neutralThreshold, openPercentageThresholds, repliesPercentageThresholds, interestedPercentageThresholds };
