import { css } from '@emotion/react';
import { Avatar, useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectCardNameProps {
    firstName: string;
    lastName: string;
    fullName: string;
    location?: string | null;
    profileImageUri?: string | null;
}

const ProspectCardName: FC<ProspectCardNameProps> = ({ firstName, lastName, fullName, location, profileImageUri }) => {
    const theme = useTheme();
    // Sometimes the last name is an empty string. When that happens the lastNameInitial is undefined.
    // That can cause problems with the Avatar component. So we use the nullish coalescing operator to
    // make sure that the lastNameInitial is always a string.
    const firstNameInitial = firstName[0] ?? '';
    const lastNameInitial = lastName[0] ?? '';

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                gap: ${spacing.space12px};
            `}
        >
            <Avatar
                css={css`
                    width: 36px;
                    height: 36px;
                    color: black;
                `}
                src={profileImageUri || undefined}
            >{`${firstNameInitial}${lastNameInitial}`}</Avatar>
            <Box>
                <Box
                    css={css`
                        font-size: ${fontSizes.f16};
                    `}
                >
                    {fullName}
                </Box>
                <Box
                    css={css`
                        font-size: ${fontSizes.f14};
                        color: ${theme.palette.grey[200]};
                    `}
                >
                    {location}
                </Box>
            </Box>
        </Box>
    );
};

export { ProspectCardName };
