import { css, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';

import { trackEvent } from 'analytics';
import { TextField } from 'shared/components/presentational';
import { useProspectPanel } from 'shared/hooks';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface NotepadProps {}

const debounceMs = 500;

const Notepad: FC<NotepadProps> = () => {
    const { addOrUpdateProspect, prospectInfo } = useProspectPanel();
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs.profile-tab');

    const [notes, setNotes] = useState<string>('');
    const profile = prospectInfo?.profile;
    const url = prospectInfo?.urls?.[0]?.url;

    useEffect(() => {
        if (profile && profile.notes && notes.length === 0) {
            setNotes(profile.notes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const handleSave = async (notesText: string) => {
        if (prospectInfo && profile && url && notesText.trim() !== profile.notes?.trim()) {
            const updatedProfile = { ...profile, notes: notesText.trim() };
            await addOrUpdateProspect(updatedProfile, url);
            trackEvent('edit_notepad', { prospect_id: prospectInfo.id });
        }
    };

    const debouncedOnNotepadChange = useMemo(
        () => debounce(handleSave, debounceMs),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prospectInfo]
    );

    const handleNotepadTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
        debouncedOnNotepadChange(event.target.value);
    };

    const handleOnBlur = () => {
        setNotes(notes.trim());
        debouncedOnNotepadChange(notes.trim());
    };

    return (
        <TextField
            value={notes}
            onChange={handleNotepadTextChange}
            multiline
            label={translate('notepad-title')}
            InputLabelProps={{ shrink: true }}
            maxRows={4}
            placeholder={translate('notepad-placeholder')}
            onBlur={handleOnBlur}
            css={css`
                width: 100%;
                .MuiInputLabel-root,
                .MuiInputLabel-root:hover,
                .MuiInputLabel-root.Mui-focused {
                    font-size: ${fontSizes.f16};
                    font-weight: unset;
                    color: ${theme.palette.grey['600']};
                }
            `}
        />
    );
};

export { Notepad };
