import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { TextField, TextFieldProps } from 'shared/components/presentational';
import { InputAdornment } from 'shared/components/presentational/input-adornment';
import { MagnifyingGlass } from 'shared/components/svgs';
import { FC } from 'shared/types';

type FilterTextFieldProps = TextFieldProps;

const FilterTextField: FC<FilterTextFieldProps> = ({
    placeholder,
    value,
    onChange,
    parentBackgroundColor = 'gray',
    ...rest
}) => {
    const translate = useTranslations('filter-text-field');
    const theme = useTheme();
    const [focused, setFocused] = useState(false);
    const [hover, setHover] = useState(false);

    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    return (
        <Box
            css={css`
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            `}
        >
            <TextField
                parentBackgroundColor={parentBackgroundColor}
                hiddenLabel
                placeholder={placeholder ?? translate('keyword-filter-placeholder')}
                value={value}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <MagnifyingGlass
                                stroke={value !== '' || focused || hover ? theme.palette.primary.main : undefined}
                            />
                        </InputAdornment>
                    ),
                }}
                css={css`
                    margin-right: auto;
                    flex-grow: 1;

                    .MuiInputBase-root {
                        height: 36px;
                    }
                `}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                {...rest}
            />
        </Box>
    );
};

export { FilterTextField };
