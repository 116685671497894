import { isArray, isEmpty, isObject } from 'lodash';

type RemoveEmptyProperties = Record<string, unknown>;

/**
 * This function is a utility function used to remove all empty properties from a given object.
 * An "empty" property is one whose value is considered empty as per the isEmpty() function.
 * The operation is applied recursively on nested objects.
 * Array properties are not recursively cleaned, meaning empty objects within arrays will not be removed.
 * Keys with null values will not be removed.
 */
function removeEmptyProperties(obj: RemoveEmptyProperties): RemoveEmptyProperties {
    return Object.fromEntries(
        Object.entries(obj)
            .map(([k, v]) => [k, isObject(v) && !isArray(v) ? removeEmptyProperties({ ...v }) : v])
            .filter(([_, v]) => !(isObject(v) && !isArray(v) && isEmpty(v)))
    );
}

export { removeEmptyProperties };
