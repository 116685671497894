import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useTranslations } from 'next-intl';

import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoResultsStateProps {
    title?: string;
    description?: string | React.ReactNode;
    clearFilters?: () => void;
}

const NoResultsState: FC<NoResultsStateProps> = (props) => {
    const { title, description, clearFilters, ...rest } = props;

    const translate = useTranslations('shared.no-results-state');

    return (
        <Box className="root" {...rest}>
            <Box className="title">{title || translate('title')}</Box>
            <Box className="description">
                {description || translate('description')}
                <Box component="span" className="link" onClick={clearFilters}>
                    {translate('clear-filters-action-text')}
                </Box>
                <Box component="span">.</Box>
            </Box>
        </Box>
    );
};

const StyledNoResultsState = styled(NoResultsState)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: ${spacing.space16px};

    background-color: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.grey[600]};
    padding: 120px 150px;
    margin-top: ${spacing.space8px};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.grey[500]};
    text-align: center;

    .title {
        font-weight: ${fontWeights.bold};
        font-size: ${fontSizes.f24};
        font-family: ${fontFamilies.poppins};

        line-height: ${fontSizes.f28};

        max-width: 735px;
    }

    .description {
        font-weight: ${fontWeights.normal};
        font-size: ${fontSizes.f16};
        font-family: ${fontFamilies.inter};

        line-height: 19.36px;

        max-width: 735px;
    }

    .link {
        cursor: pointer;
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

export { StyledNoResultsState as NoResultsState };
