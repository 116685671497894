import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { ActionButtons } from 'shared/components/composite';
import { Box, Layout } from 'shared/components/containers';
import { Button, Stepper } from 'shared/components/presentational';
import { SequenceIcon } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateSequenceTemplateProps {
    activeStep: 0 | 1;
    onSaveAsDraft: () => void;
    onExit: () => void;
    draftExists?: boolean;
}

const CreateSequenceTemplate: FC<CreateSequenceTemplateProps> = (props) => {
    const { children, activeStep, onSaveAsDraft, draftExists, onExit } = props;
    const translate = useTranslations('sequence');

    const getRightActionButtons = (): React.ReactNode => (
        <div>
            <Button variant="outlined" onClick={onSaveAsDraft}>
                {translate('save-as-draft')}
            </Button>
            <Button
                variant="outlined"
                css={css`
                    margin-left: ${spacing.space16px};
                `}
                onClick={onExit}
            >
                {translate('exit')}
            </Button>
        </div>
    );

    return (
        <Layout
            breadcrumbs={
                draftExists
                    ? [{ text: translate('update-draft-sequence') }]
                    : [{ text: translate('create-a-sequence') }]
            }
            icon={<SequenceIcon />}
            hasSideMenu={false}
            hasActionPanel={false}
            hasHeader={false}
            isFixedWidth
        >
            <Box
                css={css`
                    @media (max-width: 1200px) {
                        padding: 0 ${spacing.space64px};
                    }
                `}
            >
                <ActionButtons
                    title={draftExists ? translate('update-draft-sequence') : translate('create-a-sequence')}
                    rightActionButtons={getRightActionButtons()}
                />
                <Stepper activeStep={activeStep} steps={['Stages', 'Settings']} />
                {children}
            </Box>
        </Layout>
    );
};

export { CreateSequenceTemplate };
