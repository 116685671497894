import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedCircle: FC<SvgProps> = ({ fill, theme, ...rest }) => (
    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <circle cx="2" cy="2" r="2" fill={fill ?? theme?.palette.secondary.dark} />
    </svg>
);

const Circle = withTheme(ThemedCircle);

export { Circle };
