import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect } from 'react';
import * as Yup from 'yup';

import { GreenhouseApplicationTypeEnum, GreenhouseSourcesQuery } from 'codegen/graphql';
import { ConnectEditFormButtons } from 'integrations/components/composite/greenhouse';
import { TeamSettingsDefaults, TeamSettingsSpacer } from 'integrations/components/presentational';
import { ConnectEditTemplate } from 'integrations/components/templates/greenhouse';
import { OverrideToggleFormValues } from 'integrations/types';
import { CreateEditFormValues } from 'integrations/types/greenhouse';
import { Form } from 'shared/components/form';
import { Hyperlink } from 'shared/components/presentational';
import { ADD_HIREFLOW_AS_SOURCE_GREENHOUSE } from 'shared/constants';
import { GET_GREENHOUSE_SOURCES, GreenhouseAccountByIdData } from 'shared/graphql/integrations/greenhouse';
import { useDynamicSchemaForm, useSession, useSnackbarAlert } from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

interface EditFormProps {
    greenhouseAccount: GreenhouseAccountByIdData;
    onClose: () => void;
    refetch: () => void;
}

const EditForm: FC<EditFormProps> = (props) => {
    const { greenhouseAccount, onClose, refetch } = props;
    const translate = useTranslations('integrations.greenhouse');

    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const validationSchema = Yup.object().shape({
        apiToken: Yup.string().required(translate('error-api-token')),
    });

    const { data: greenhouseSourcesData } = useQuery<GreenhouseSourcesQuery>(GET_GREENHOUSE_SOURCES);

    const formInitialValues: CreateEditFormValues & OverrideToggleFormValues = {
        apiToken: greenhouseAccount?.apiToken ?? '',
        defaultSourceId: greenhouseAccount?.defaultSourceId ?? '',
        addAsApplicationType:
            greenhouseAccount?.defaultCreateAsApplicationType ?? GreenhouseApplicationTypeEnum.Prospect,
        teamOverride: false,
    };

    const formMethods = useDynamicSchemaForm<CreateEditFormValues & OverrideToggleFormValues>({
        mode: 'onTouched',
        defaultValues: formInitialValues,
        schema: validationSchema,
    });

    useEffect(() => {
        if (!greenhouseAccount?.apiToken) return;
        formMethods.setValue('apiToken', greenhouseAccount.apiToken);
    }, [formMethods, greenhouseAccount?.apiToken]);

    const handleClose = (): void => {
        formMethods.reset();
        onClose();
    };

    const onSubmit = async () => {
        const values = formMethods.getValues();
        const { apiToken, defaultSourceId, addAsApplicationType } = values;
        const restApiClient = createRestApiClient();
        const connectResponse = await restApiClient.connectGreenhouseToken({
            apiToken,
            defaultSourceId: defaultSourceId || undefined,
            defaultCreateAsApplicationType: addAsApplicationType || GreenhouseApplicationTypeEnum.Prospect,
        });

        if (connectResponse?.success) {
            refetch();
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-saved'),
            });
            onClose();
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-saved'),
            });
            formMethods.reset(values);
        }
    };

    const isOwner: boolean = greenhouseAccount?.ownerId === session?.user.id;

    return (
        <Form
            css={css`
                width: 100%;
            `}
            onSubmit={formMethods.handleSubmit(onSubmit)}
        >
            <ConnectEditTemplate isEditing formMethods={formMethods} />
            <TeamSettingsSpacer
                description={translate.rich('team-settings-info', {
                    link: AddHireflowAsSourceLink,
                })}
            />
            <TeamSettingsDefaults formMethods={formMethods} data={greenhouseSourcesData?.data} canCreateProspect />
            <ConnectEditFormButtons isEditing canEdit={isOwner} onClose={handleClose} />
        </Form>
    );
};

export { EditForm };

const AddHireflowAsSourceLink = (children: ReactNode) => (
    <Hyperlink href={ADD_HIREFLOW_AS_SOURCE_GREENHOUSE} newTab>
        {children}
    </Hyperlink>
);
