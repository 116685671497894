import { CircularProgress as MuiCircularProgress, css, useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface CircularProgressProps {}

const CircularProgress: FC<CircularProgressProps> = () => {
    const theme = useTheme();

    return (
        <Box
            css={css`
                color: ${theme.palette.primary.main};
            `}
        >
            <MuiCircularProgress />
        </Box>
    );
};

export { CircularProgress };
