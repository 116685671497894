import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface MenuPinnedProps {
    name: string;
    text: string;
    href: string;
    icon: FC<any>;
    color: string;
}

const MenuPinned: FC<MenuPinnedProps> = ({ text, href, icon: Icon, color }) => {
    const theme = useTheme();
    return (
        <Hyperlink
            href={href}
            css={css`
                display: flex;
                flex-flow: row nowrap;
                padding: 4px;
                align-items: center;
                gap: ${spacing.space16px};
                color: ${theme.palette.secondary.light};
                font-family: ${fontFamilies.poppins};
                font-size: ${fontSizes.f14};
                font-weight: ${fontWeights.semiBold};

                &:hover {
                    color: ${theme.palette.secondary.main};
                }
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    padding: 4px;
                    // the code below is used to create the background circle with 0.2 opacity
                    // inspired by https://css-tricks.com/snippets/css/transparent-background-images/
                    position: relative;
                    ::before {
                        border-radius: 50%;
                        content: '';
                        background: ${color};
                        position: absolute;
                        inset: 0;
                        opacity: 0.2;
                    }
                `}
            >
                <Icon width={12} height={12} stroke={color} />
            </Box>

            <Box
                css={css`
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                `}
            >
                {text}
            </Box>
        </Hyperlink>
    );
};

export { MenuPinned };
