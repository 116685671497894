import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { ReactNode } from 'react';

import { trackEvent } from 'analytics';
import { PlanTypesEnum, SequenceStatusEnum, UpdateSequencesMutation, UserTeamQuery } from 'codegen/graphql';
import { SequenceTab } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { Button, Hyperlink } from 'shared/components/presentational';
import { Archive, Pause, PlayCircle, XClose } from 'shared/components/svgs';
import { SUPPORT_HIREFLOW_EMAIL } from 'shared/constants';
import {
    GET_SEQUENCES,
    GET_SEQUENCES_COUNT_BY_STATUS,
    GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
    UPDATE_SEQUENCES,
} from 'shared/graphql/sequences';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { useConfirmationModal, useSnackbarAlert } from 'shared/hooks';
import { colors, fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

const Link = (children: ReactNode) => (
    <Hyperlink
        css={css`
            color: ${colors.greens.eucalyptus};
        `}
        href={`mailto:${SUPPORT_HIREFLOW_EMAIL}`}
        newTab
    >
        <u>{children}</u>
    </Hyperlink>
);

interface SequenceListEditBarProps {
    sequenceIds: string[];
    tab?: SequenceTab;
    onClose(): void;
}

const SequenceListEditBar: FC<SequenceListEditBarProps> = React.forwardRef(
    ({ sequenceIds, tab = 'all', onClose }, ref) => {
        const translate = useTranslations('sequence.sequence-list-table.edit-bar');
        const theme = useTheme();
        const { showModal, hideModal } = useConfirmationModal();
        const { showSnackbarAlert } = useSnackbarAlert();

        const { data, loading } = useQuery<UserTeamQuery>(GET_USER_TEAM);

        const [updateSequences] = useMutation<UpdateSequencesMutation>(UPDATE_SEQUENCES, {
            refetchQueries: [
                getOperationName(GET_SEQUENCES) as string,
                getOperationName(GET_SEQUENCES_COUNT_BY_STATUS) as string,
                getOperationName(GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS) as string,
            ],
        });

        const teamPlan = data?.teams?.[0]?.planType;
        const owner = data?.teams?.[0]?.owner?.[0].fullName;

        const handlePauseSequences = async () => {
            try {
                const res = await updateSequences({
                    variables: {
                        where: {
                            id: { _in: sequenceIds },
                            status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                        },
                        set: {
                            status: SequenceStatusEnum.Paused,
                        },
                    },
                });
                if (res.data) {
                    const pausedCount = res.data?.update_sequences?.affected_rows ?? 0;
                    const notPausedCount = sequenceIds.length - pausedCount;
                    showSnackbarAlert({
                        severity: 'success',
                        message:
                            pausedCount === 0
                                ? translate('pause-success-0-paused')
                                : translate('pause-success', { pausedCount, notPausedCount }),
                    });
                    const pausedSequences = res.data.update_sequences?.returning.map((a) => a.id);
                    if (pausedSequences) {
                        for (const pausedSequence of pausedSequences) {
                            trackEvent('click_pause', { sequence_id: pausedSequence, value: 'sequence' });
                        }
                    }
                }
            } catch (error) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('pause-fail'),
                });
            }
            onClose();
        };

        const handleResumeSequences = async () => {
            const res = await updateSequences({
                variables: {
                    where: { id: { _in: sequenceIds }, status: { _eq: SequenceStatusEnum.Paused } },
                    set: { status: SequenceStatusEnum.Active },
                },
            });
            showSnackbarAlert({
                severity: 'success',
                message: translate('sequences-are-now-active'),
            });

            hideModal();
            onClose();
            const resumedSequencesIds = res?.data?.update_sequences?.returning.map((a) => a.id) ?? [];
            for (const sequenceId of resumedSequencesIds) {
                trackEvent('click_resume', { sequence_id: sequenceId, value: 'sequence' });
            }
        };

        const handleResumeSequenceConfirm = () => {
            if (teamPlan === PlanTypesEnum.Expired) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate.rich('sequence-cannot-be-resumed-plan-type-expired', {
                        owner,
                        link: Link,
                        email: SUPPORT_HIREFLOW_EMAIL,
                    }),
                });
                return;
            }

            showModal({
                title: translate('resume-modal-header'),
                description: translate('resume-modal-description'),
                confirmButton: {
                    text: translate('resume-modal-resume-now-button-label'),
                    onClick: handleResumeSequences,
                },
                cancelButton: {
                    text: translate('resume-modal-cancel-button-label'),
                    onClick: hideModal,
                },
            });
        };

        const handleArchiveSequences = async () => {
            try {
                const res = await updateSequences({
                    variables: {
                        where: { id: { _in: sequenceIds } },
                        set: {
                            status: SequenceStatusEnum.Archived,
                        },
                    },
                });
                if (res.data) {
                    const archivedCount = res.data?.update_sequences?.affected_rows ?? 0;
                    const notArchivedCount = sequenceIds.length - archivedCount;
                    showSnackbarAlert({
                        severity: 'success',
                        message:
                            archivedCount === 0
                                ? translate('archive-success-0-archived')
                                : translate('archive-success', { archivedCount, notArchivedCount }),
                    });
                    const archivedSequences = res.data.update_sequences?.returning.map((a) => a.id);
                    if (archivedSequences) {
                        for (const archivedSequence of archivedSequences) {
                            trackEvent('click_archive', { sequence_id: archivedSequence, value: 'sequence' });
                        }
                    }
                }
            } catch (error) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('archive-fail'),
                });
            }
            onClose();
        };

        const getActionButtons = () => {
            const pauseButton = (
                <Button
                    key="pause-button"
                    startIcon={<Pause />}
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={handlePauseSequences}
                    disableRipple
                >
                    {translate('pause-sequences')}
                </Button>
            );

            const resumeButton = (
                <Button
                    key="resume-button"
                    startIcon={<PlayCircle />}
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={handleResumeSequenceConfirm}
                    disableRipple
                >
                    {translate('resume-sequences')}
                </Button>
            );

            const archiveButton = (
                <Button
                    key="archive-button"
                    startIcon={<Archive />}
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={handleArchiveSequences}
                    disableRipple
                >
                    {translate('archive-sequences')}
                </Button>
            );

            switch (tab) {
                case 'active':
                    return [pauseButton, archiveButton];
                case 'draft':
                    return [archiveButton];
                case 'paused':
                    return [resumeButton, archiveButton];
                case 'archived':
                    return [];
                case 'all':
                    return [pauseButton, archiveButton];
                default:
                    return [];
            }
        };

        if (loading) {
            return null;
        }

        return (
            <Box
                css={css`
                    display: flex;
                    align-items: center;

                    width: 960px;
                    background-color: ${theme.palette.grey[200]};
                    padding: 20px 25px;
                    border-radius: 4px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                    color: ${theme.palette.common.white};
                    font-weight: ${fontWeights.bold};
                `}
                ref={ref}
            >
                <Box
                    css={css`
                        margin-right: auto;
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('items-selected', { count: sequenceIds.length })}
                </Box>
                <Box
                    css={css`
                        display: flex;
                        font-size: ${fontSizes.f16};
                    `}
                >
                    {getActionButtons()}
                </Box>
                <Button startIcon={<XClose stroke={theme.palette.common.white} />} onClick={onClose} disableRipple />
            </Box>
        );
    }
);

export { SequenceListEditBar };
