import { SelectChangeEvent, css, useTheme } from '@mui/material';
import { formatEmail } from 'hireflow-shared/lib/emails/email-utils';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { ContactChannelsEnum, ContactKindsEnum, SequenceEmailPreferenceEnum } from 'codegen/graphql';
import { RecipientAddManualEmail } from 'prospects';
import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { Button } from 'shared/components/presentational';
import { Circle, Inbox01, Plus } from 'shared/components/svgs';
import { ContactUpdate } from 'shared/graphql/contacts';
import { useProspectPanel, useSession } from 'shared/hooks';
import { StatusEligibleForContactUpdate, pickCurrentWorkEmail, pickPersonalEmail } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectContact } from 'shared/types/prospect';

interface SelectRecipientEmailProp {
    anchor?: HTMLElement;
    contacts: ProspectContact[];
    onSelect: (email: string | undefined) => void;
    sequenceId: string;
}

const SelectRecipientEmail: FC<SelectRecipientEmailProp> = (props) => {
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('prospects.tabs.add-tab');

    const { onSelect, anchor, sequenceId, contacts } = props;
    const { prospectInfo, recipients, sequencesData, addProspectContact } = useProspectPanel();

    const [selectedContact, setSelectedContact] = useState<ProspectContact | undefined>(undefined);
    const [noEmailOnFileText, setNoEmailOnFileText] = useState<string>(translate('let-hireflow-find-email'));

    const [addManual, setAddManual] = useState<boolean>(false);
    const [newManualEmail, setNewManualEmail] = useState<string | undefined>(undefined);

    const profile = prospectInfo?.profile;
    const emailPreference = sequencesData!.find((f) => f.id === sequenceId)?.emailPreference;

    useEffect(() => {
        if (contacts.length > 0 && profile) {
            let contact: ProspectContact | undefined | null;
            if (emailPreference) {
                if (
                    [
                        SequenceEmailPreferenceEnum.PersonalEmailOnly,
                        SequenceEmailPreferenceEnum.PersonalPreferred,
                    ].includes(emailPreference)
                ) {
                    contact = pickPersonalEmail(contacts);
                } else if (
                    [SequenceEmailPreferenceEnum.WorkEmailOnly, SequenceEmailPreferenceEnum.WorkPreferred].includes(
                        emailPreference
                    )
                ) {
                    contact = pickCurrentWorkEmail(contacts, profile);
                }
            }

            setSelectedContact(contact ?? undefined);
            onSelect(contact?.value ?? undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, emailPreference, profile]);

    useEffect(() => {
        if (emailPreference) {
            setNoEmailOnFileText(
                [SequenceEmailPreferenceEnum.PersonalEmailOnly, SequenceEmailPreferenceEnum.PersonalPreferred].includes(
                    emailPreference
                )
                    ? translate('let-hireflow-find-personal-email')
                    : [SequenceEmailPreferenceEnum.WorkEmailOnly, SequenceEmailPreferenceEnum.WorkPreferred].includes(
                          emailPreference
                      )
                    ? translate('let-hireflow-find-work-email')
                    : translate('let-hireflow-find-email')
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailPreference]);

    useEffect(() => {
        if (newManualEmail && contacts) {
            const filteredContact = contacts.find((c) => c.value === newManualEmail);
            if (filteredContact) {
                setSelectedContact(filteredContact);
                onSelect(filteredContact.value);
                setNewManualEmail(undefined);
            }
        }
    }, [newManualEmail, contacts, onSelect]);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const filteredContacts = contacts?.filter((c) => c.id === event.target.value);
        if (!filteredContacts || filteredContacts.length === 0) {
            setSelectedContact(undefined);
            onSelect(undefined);
            return;
        } // handling edge case where the resultant array can be empty
        setSelectedContact(filteredContacts?.[0]);
        onSelect(filteredContacts?.[0].value);
    };

    const handleSaveNewEmail = (email: string) => {
        const formattedEmail = formatEmail(email);

        const contactUpdates: ContactUpdate = {
            kind: ContactKindsEnum.Unknown,
            value: formattedEmail,
            standardizedValue: formattedEmail,
            channel: ContactChannelsEnum.Email,
            invalid: false,
            primary: true,
            userMarkedInvalid: false,
        };

        const recipientIds = recipients
            ?.filter((f) => f.user.id === session?.user.id && StatusEligibleForContactUpdate.includes(f.status))
            .map((r) => r.id);

        addProspectContact([contactUpdates], recipientIds ?? []);
        setNewManualEmail(formattedEmail);
    };

    const toggleAddManual = () => {
        setAddManual(!addManual);
        trackEvent('click_enter_email_manually', { prospect_id: prospectInfo?.id });
    };

    /**
     * if sequence has work preferred or work only email preference,
     * then we need to check if we have found an email using pickCurrentWorkEmail
     * if not then we do not show any emails in the drop down
     *
     * this is to avoid customers adding a personal email or a very old work email
     * to the recipient at the time of adding them to the sequence and that resulting
     * in a bounced email
     */

    const getEmailOptions = () => {
        if (
            emailPreference &&
            [SequenceEmailPreferenceEnum.WorkEmailOnly, SequenceEmailPreferenceEnum.WorkPreferred].includes(
                emailPreference
            ) &&
            !selectedContact
        ) {
            return [];
        }
        return contacts;
    };

    const emailOptions = [
        <MenuItem key="no-email-on-file" value="no-email-on-file">
            {!selectedContact && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            <Box
                css={css`
                    color: ${theme.palette.grey[600]};
                `}
            >
                {noEmailOnFileText}
            </Box>
        </MenuItem>,
        ...getEmailOptions().map((o) => (
            <MenuItem key={o.id} value={o.id}>
                {o.id === selectedContact?.id && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {o.value}
            </MenuItem>
        )),
    ];

    const selectDropDown = addManual ? null : emailOptions ? (
        <Select
            label={translate('recipients-email-label')}
            name="email"
            value={selectedContact?.id || 'no-email-on-file'}
            width="100%"
            onChange={handleChange}
            variant="outlined"
        >
            {emailOptions}
            <Button variant="text" onClick={toggleAddManual} startIcon={<Plus />}>
                {translate('manually-add-email-label')}
            </Button>
        </Select>
    ) : null;

    return (
        <Box
            css={css`
                padding-top: ${spacing.space12px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Box>
                    <Inbox01 width={24} height={24} />
                </Box>
                <Box
                    css={css`
                        margin-left: ${spacing.space12px};
                        width: 445px;
                    `}
                >
                    {selectDropDown}
                </Box>
            </Box>
            {!selectedContact && (
                <Box
                    css={css`
                        margin-left: 37px;
                        width: 406px;
                        font-size: ${fontSizes.f12};
                        font-weight: ${fontWeights.normal};
                        color: ${theme.palette.grey[200]};
                    `}
                >
                    {translate('finding-email-description')}
                </Box>
            )}

            <RecipientAddManualEmail
                anchor={anchor}
                onClose={() => setAddManual(false)}
                open={addManual}
                handleSaveNewEmail={handleSaveNewEmail}
            />
        </Box>
    );
};

export { SelectRecipientEmail };
