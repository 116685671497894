import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedPlus: FC<SvgProps> = ({ stroke, strokeWidth = '2', theme, ...rest }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M10,3v14 M3,10h14"
            stroke={stroke ?? theme?.palette.secondary.dark}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Plus = withTheme(ThemedPlus);

export { Plus };
