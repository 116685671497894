import { useQuery } from '@apollo/client';
import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import {
    InviteStatusEnum,
    LicenseTypesEnum,
    OrderBy,
    PlanTypesEnum,
    TeamRolesEnum,
    UserTeamQuery,
} from 'codegen/graphql';
import { BasePrivileges, Seats } from 'settings/components/presentational/plans-and-billing/plan-privileges';
import { Box } from 'shared/components/containers';
import { Button, NoSsr, Title } from 'shared/components/presentational';
import { EnterpriseLevel } from 'shared/components/svgs';
import { OUT_OF_AI_SOURCING_CREDITS, windowFeatures, windowTarget } from 'shared/constants';
import { GET_TEAM_INVITES, GET_TEAM_MEMBERS, GET_USER_TEAM, TeamInvitesData, TeamMember } from 'shared/graphql/teams';
import { useSession } from 'shared/hooks';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

export interface TeamMemberData {
    role: TeamRolesEnum;
    licenseType: LicenseTypesEnum;
}

interface EnterprisePlanProps {
    message?: string;
    aiSourcerCredits: number;
    planType: PlanTypesEnum;
    showAISourcerCredits?: boolean;
}

const EnterprisePlan: FC<EnterprisePlanProps> = (props) => {
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('settings.plans-and-billing-settings');
    const { aiSourcerCredits, message, planType, showAISourcerCredits = true } = props;

    const [allTeamMembers, setAllTeamMembers] = useState<TeamMemberData[]>();

    const { data: userTeamData } = useQuery<UserTeamQuery>(GET_USER_TEAM);

    const { data: invitesData } = useQuery<{ team_invites: TeamInvitesData }>(GET_TEAM_INVITES, {
        variables: {
            where: {
                _and: [
                    { team: { id: { _eq: session?.user.teamId } } },
                    { status: { _neq: InviteStatusEnum.Accepted } },
                ],
            },
            orderBy: { invitee: OrderBy.Asc },
        },
    });

    const { data: membersData } = useQuery<{ users: Array<TeamMember> }>(GET_TEAM_MEMBERS, {
        variables: { where: { team: { id: { _eq: session?.user.teamId } } }, orderBy: { email: OrderBy.Asc } },
    });

    const totalSeats = userTeamData?.teams[0].seatsCount ?? 0;

    useEffect(() => {
        if (membersData && invitesData) {
            setAllTeamMembers(
                membersData.users
                    .map((u) => ({ role: u.role, licenseType: u.licenseType }))
                    .concat(invitesData.team_invites.map((i) => ({ role: i.role, licenseType: i.licenseType })))
            );
        }
    }, [membersData, invitesData]);

    const handleContactUs = () => {
        window.open(OUT_OF_AI_SOURCING_CREDITS, windowTarget, windowFeatures);
    };

    const contactUsSection =
        session?.user.role === TeamRolesEnum.Owner ? (
            <Button
                css={css`
                    font-weight: ${fontWeights.semiBold};
                    font-size: ${spacing.space16px};
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: ${theme.palette.primary.main};
                    margin-top: 6px;
                    padding: 0;
                `}
                variant="text"
                onClick={handleContactUs}
            >
                {translate('contact-us')}
            </Button>
        ) : (
            <Box
                css={css`
                    margin-top: ${spacing.space6px};
                    font-size: ${fontSizes.f14};
                `}
            >
                {translate('contact-owner', { owner: userTeamData?.teams?.[0]?.owner?.[0]?.fullName ?? '' })}
            </Box>
        );

    return [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) ? (
        <Box
            css={css`
                background: ${theme?.palette.background.default};
                border: 1px solid ${theme?.palette.grey[500]};
                border-radius: ${spacing.space8px};
                padding: ${spacing.space24px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Title
                    type="h3"
                    css={css`
                        display: flex;
                        font-size: ${fontSizes.f24};
                        line-height: 28px;
                        color: ${theme.palette.common.black};
                        margin: ${spacing.none};
                        margin-bottom: ${spacing.space16px};
                        svg {
                            margin-right: ${spacing.space16px};
                        }
                    `}
                >
                    <EnterpriseLevel />
                    {planType === PlanTypesEnum.Enterprise
                        ? translate('enterprise-level')
                        : translate('enterprise-level-free-trial')}
                </Title>
                {message && (
                    <NoSsr>
                        <Box
                            component="p"
                            css={css`
                                font-family: ${fontFamilies.inter};
                                font-style: normal;
                                font-weight: ${fontWeights.normal};
                                font-size: ${fontSizes.f12};
                                line-height: 15px;
                                text-align: right;
                                margin: 0;
                                color: ${theme.palette.common.black};
                            `}
                        >
                            {message}
                        </Box>
                    </NoSsr>
                )}
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <BasePrivileges
                    planType={planType}
                    aiSourcerCredits={aiSourcerCredits}
                    showAISourcerCredits={showAISourcerCredits}
                />
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: ${spacing.space16px};
                `}
            >
                <Box
                    css={css`
                        width: 64%;
                        padding: ${spacing.space16px};
                        background: ${theme.palette.common.white};
                    `}
                >
                    <Title
                        css={css`
                            font-weight: ${fontWeights.bold};
                            font-size: 20px;
                            line-height: 30px;
                            color: ${theme.palette.common.black};
                            margin: 0;
                        `}
                        type="h4"
                    >
                        {translate('seats')}
                    </Title>
                    {allTeamMembers && <Seats teamMembers={allTeamMembers} totalSeats={totalSeats} />}
                </Box>
                <Box
                    css={css`
                        background: ${theme.palette.primary.contrastText};
                        border-radius: 4px;
                        padding: ${spacing.space16px};
                        width: 33%;
                        height: min-content;
                    `}
                >
                    <p
                        css={css`
                            font-size: ${fontSizes.f14};
                            font-weight: ${fontWeights.bold};
                            line-height: 17px;
                            color: ${theme.palette.common.black};
                            margin: 0;
                        `}
                    >
                        {translate('need-more-credits')}
                    </p>
                    {contactUsSection}
                </Box>
            </Box>
        </Box>
    ) : null;
};

export { EnterprisePlan };
