import { gql } from '@apollo/client';

export interface PinType {
    id: string;
    targetId: string;
    targetKind: string;
}

export interface PinsType {
    pins: Array<PinType>;
}

export const GET_USER_PINS = gql`
    query GetUserPins {
        pins: user_pins {
            id
            targetId
            targetKind
        }
    }
`;

export const GET_PINNED_SEQUENCES = gql`
    query GetPinnedSequences($where: sequences_bool_exp!) {
        sequences(where: $where) {
            id
            title
            color
        }
    }
`;

export const GET_PINNED_SOURCERS = gql`
    query GetPinnedSourcers($where: sourcers_bool_exp!) {
        sourcers(where: $where) {
            id
            title
            color
        }
    }
`;

export const GET_PINNED_PROJECTS = gql`
    query GetPinnedProjects($where: projects_bool_exp!) {
        projects(where: $where) {
            id
            title
            color
        }
    }
`;

export const GET_USER_PIN_BY_ID = gql`
    query UserPinById($id: String!) {
        pin: user_pins_by_pk(id: $id) {
            id
            targetId
            targetKind
        }
    }
`;

export const CREATE_USER_PIN = gql`
    mutation CreateUserPin($userPin: user_pins_insert_input!) {
        inserted: insert_user_pins_one(object: $userPin) {
            id
        }
    }
`;

export const REMOVE_USER_PIN = gql`
    mutation RemoveUserPin($id: String!) {
        deleted: delete_user_pins_by_pk(id: $id) {
            id
        }
    }
`;
