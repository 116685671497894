import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { MessageChatSquare } from 'shared/components/svgs';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface EmptyTabStateProps {
    tab: 'conversations' | 'history';
}

const EmptyTabState: FC<EmptyTabStateProps> = (props) => {
    const theme = useTheme();
    const { tab } = props;
    const translate = useTranslations('prospects.tabs.empty-tab-state');

    const title = tab === 'conversations' ? translate('conversations-title') : translate('history-title');
    const description =
        tab === 'conversations' ? translate('conversations-description') : translate('history-description');

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 160px;
            `}
        >
            <MessageChatSquare
                css={css`
                    width: 64px;
                    height: 64px;
                `}
                stroke={theme.palette.grey[200]}
            />
            <Title
                css={css`
                    margin: 20px 0 0;
                    color: ${theme.palette.grey[200]};
                    font-size: ${fontSizes.f24};
                `}
                type="h3"
            >
                {title}
            </Title>
            <p
                css={css`
                    padding: 0 80px;
                    color: ${theme.palette.grey[200]};
                    font-size: ${fontSizes.f16};
                    text-align: center;
                    line-height: 20px;
                `}
            >
                {description}
            </p>
        </Box>
    );
};

export { EmptyTabState };
