import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { Box } from 'shared/components/containers';
import { Button, EditCloseButton, TextField } from 'shared/components/presentational';
import { useProspectPanel } from 'shared/hooks';
import { colors, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface WorkSectionProps {}

const WorkSection: FC<WorkSectionProps> = () => {
    const { addOrUpdateProspect, prospectInfo } = useProspectPanel();
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs.profile-tab');

    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);

    const [role, setRole] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [school, setSchool] = useState<string>('');

    const profile = prospectInfo?.profile;
    const url = prospectInfo?.urls?.[0]?.url;

    useEffect(() => {
        if (profile) {
            setRole(profile.titleCustomized ?? profile.title ?? '');
            setCompany(profile.latestCompanyCustomized ?? profile.latestCompany ?? '');
            setSchool(profile.latestSchoolCustomized ?? profile.latestSchool ?? '');
        }
    }, [profile]);

    const handleSave = async () => {
        if (prospectInfo && profile && url) {
            const roleChanged = profile.title !== role.trim();
            const companyChanged = profile.latestCompany !== company.trim();
            const schoolChanged = profile.latestSchool !== school.trim();

            const updatedProfile = {
                ...profile,
                titleCustomized: roleChanged ? role.trim() : null,
                latestSchoolCustomized: schoolChanged ? school.trim() : null,
                latestCompanyCustomized: companyChanged ? company.trim() : null,
            };
            await addOrUpdateProspect(updatedProfile, url);
            handleCloseEdit();

            trackEvent('edit_work_and_education', {
                prospect_id: prospectInfo.id,
                value: [
                    roleChanged ? 'role' : null,
                    companyChanged ? 'company' : null,
                    schoolChanged ? 'school' : null,
                ].filter((v) => !!v),
            });
        }
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.value);
    };

    const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    };

    const handleSchoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSchool(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleCloseEdit = () => {
        setSchool(school.trim());
        setRole(role.trim());
        setCompany(company.trim());
        setEditing(false);
    };

    const handleMouseOver = () => {
        setShowEdit(true);
    };

    const handleMouseOut = () => {
        setShowEdit(false);
    };

    const actionButtons = (
        <EditCloseButton
            editing={showEdit && !editing}
            onEdit={handleEditClick}
            onClose={handleCloseEdit}
            editButtonLabel={translate('edit-button-label')}
            closeButtonLabel={translate('close-button-label')}
        />
    );

    const editableContent = (
        <Box>
            <Box
                css={css`
                    padding-top: ${spacing.space12px};
                    padding-bottom: ${spacing.space12px};
                `}
            >
                <TextField
                    parentBackgroundColor="green"
                    value={role}
                    onChange={handleRoleChange}
                    onKeyDown={handleKeyPress}
                    label={translate('work-education.role-label')}
                    css={css`
                        width: 100%;
                        padding-bottom: ${spacing.space12px};
                    `}
                />
                <TextField
                    parentBackgroundColor="green"
                    value={company}
                    onChange={handleCompanyChange}
                    onKeyDown={handleKeyPress}
                    label={translate('work-education.company-label')}
                    css={css`
                        width: 100%;
                        padding-bottom: ${spacing.space12px};
                    `}
                />
                <TextField
                    parentBackgroundColor="green"
                    value={school}
                    onChange={handleSchoolChange}
                    onKeyDown={handleKeyPress}
                    label={translate('work-education.school-label')}
                    css={css`
                        width: 100%;
                        padding-bottom: ${spacing.space12px};
                    `}
                />
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: ${spacing.space12px};
                `}
            >
                <Button variant="contained" onClick={handleSave}>
                    {translate('work-education.save-button-label')}
                </Button>
            </Box>
        </Box>
    );

    const roleAtCompanySection =
        role.length > 0 && company.length > 0 ? (
            <Box>
                {translate('work-education.role-at-company', {
                    role,
                    company,
                })}
            </Box>
        ) : (
            <Box>
                {role} {company}
            </Box>
        );

    const staticContent = (
        <Box
            css={css`
                padding-top: ${spacing.space12px};
            `}
        >
            {roleAtCompanySection}
            <Box>{school}</Box>
        </Box>
    );

    return (
        <Box
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            css={css`
                margin-top: ${spacing.space4px};
                padding: ${spacing.space12px};
                background-color: ${editing ? colors.greens.narvik30 : theme.palette.common.white};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: ${spacing.space4px};
                `}
            >
                <Box
                    css={css`
                        padding-top: ${spacing.space12px};
                        font-size: ${fontSizes.f12};
                        color: ${theme.palette.grey[600]};
                    `}
                >
                    {translate('work-education.label')}
                </Box>
                <Box hidden={!showEdit && !editing}>{actionButtons}</Box>
            </Box>
            {editing ? editableContent : staticContent}
        </Box>
    );
};

export { WorkSection };
