import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { CoworkerConnectionTooltip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { EmailContentPreview } from 'shared/components/editor';
import { InfoCircle } from 'shared/components/svgs';
import { useProspectPanel } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CoworkerConnectionSentenceProps {}

const CoworkerConnectionSentence: FC<CoworkerConnectionSentenceProps> = () => {
    const { context } = useProspectPanel();
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs.profile-tab');

    const [coworkerConnectionSentence, setCoworkerConnectionSentence] = useState<string>();

    useEffect(() => {
        if (context && context.magicSentence) setCoworkerConnectionSentence(context.magicSentence);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.magicSentence]);

    return (
        <Box
            css={css`
                margin-top: ${spacing.space12px};
                padding: 8px 8px;

                font-weight: 300;
                letter-spacing: 0.02em;
                color: ${coworkerConnectionSentence ? 'rgba(0, 0, 0, 0.87)' : '#9E9F9E'};
                background-color: #fafafa;

                border: 1px solid #ebebeb;
                border-radius: 4px;

                &:hover {
                    border: 1px solid #dadee3;
                }
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #9b9b9b !important;
                    letter-spacing: normal !important;
                    font-size: 1.2rem;
                    font-weight: 300;
                    opacity: 1 !important;
                    margin-bottom: ${spacing.space6px};
                    text-transform: uppercase;
                `}
            >
                {translate('coworker-connection-sentence-title')}
                <CoworkerConnectionTooltip coworkerConnectionSentence={coworkerConnectionSentence}>
                    <InfoCircle height={16} width={16} stroke={theme.palette.primary.main} />
                </CoworkerConnectionTooltip>
            </Box>
            <EmailContentPreview
                content={coworkerConnectionSentence ?? translate('coworker-connection-sentence-placeholder')}
            />
        </Box>
    );
};

export { CoworkerConnectionSentence };
