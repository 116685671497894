import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedCheckCircle02: FC<SvgProps> = ({ theme, stroke = theme?.palette?.primary?.main, ...rest }) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M17.7611 0L35.5281 0L35.5281 17.7611C35.5281 20.0943 35.0686 22.4047 34.1757 24.5603C33.2828 26.7158 31.9741 28.6745 30.3243 30.3243C28.6745 31.9741 26.7158 33.2828 24.5603 34.1757C22.4047 35.0686 20.0943 35.5281 17.7611 35.5281C13.05 35.5266 8.53245 33.654 5.20176 30.3222C1.87107 26.9904 -2.564e-07 22.4722 0 17.7611C0.00155371 13.0511 1.87331 8.53435 5.20383 5.20383C8.53435 1.87331 13.0511 0.00155371 17.7611 0Z"
            fill="#55BC7C"
        />
        <path
            // eslint-disable-next-line max-len
            d="M17.7682 -3.8147e-06L35.5293 -3.8147e-06L5.20642 30.3287C2.72223 27.844 1.03054 24.6785 0.345238 21.2324C-0.340073 17.7863 0.0117683 14.2144 1.35628 10.9682C2.70079 7.72209 4.97759 4.94748 7.89884 2.99518C10.8201 1.04288 14.2546 0.000555038 17.7682 -3.8147e-06Z"
            fill="#339E5C"
        />
        <path
            // eslint-disable-next-line max-len
            d="M12.7497 17L16.2497 20.5L23.2497 13.5M29.6663 17C29.6663 23.4434 24.443 28.6667 17.9997 28.6667C11.5564 28.6667 6.33301 23.4434 6.33301 17C6.33301 10.5567 11.5564 5.33337 17.9997 5.33337C24.443 5.33337 29.6663 10.5567 29.6663 17Z"
            stroke={stroke}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const CheckCircle02 = withTheme(ThemedCheckCircle02);

export { CheckCircle02 };
