import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);

export { Bold };
