import { useTranslations } from 'next-intl';

import { LicenseTypesEnum, PlanTypesEnum } from 'codegen/graphql';
import { SendFromAccounts, UserEmailAccounts } from 'settings/components/presentational/account-and-emails';
import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface EmailAccountsProps {
    planType: PlanTypesEnum;
    licenseType: LicenseTypesEnum;
}

const EmailAccounts: FC<EmailAccountsProps> = (props) => {
    const { planType, licenseType } = props;
    const translate = useTranslations('settings.email-settings');

    return (
        <Box>
            <Title type="h2">{translate('email-accounts')}</Title>
            <UserEmailAccounts planType={planType} licenseType={licenseType} />
            <SendFromAccounts planType={planType} licenseType={licenseType} />
        </Box>
    );
};

export { EmailAccounts };
