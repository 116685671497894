import { SettingsPopover, SettingsPopoverProps } from 'settings/components/presentational/settings-popover';
import { Team } from 'settings/components/presentational/team/team';
import { FC } from 'shared/types';

interface TeamPopoverProps extends SettingsPopoverProps {}

const TeamPopover: FC<TeamPopoverProps> = ({ ...rest }) => (
    <SettingsPopover {...rest}>
        <Team />
    </SettingsPopover>
);

export { TeamPopover };
