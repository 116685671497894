import styled from '@emotion/styled';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface NumberInsideCircleProps {
    number: number | string;
}

const NumberInsideCircle: FC<NumberInsideCircleProps> = (props) => {
    const { number } = props;
    return <Box {...props}>{number}</Box>;
};

const StyledNumberInsideCircle = styled(NumberInsideCircle)`
    background: ${({ theme }) => theme.palette.warning.main};
    border-radius: 100px;
    padding: 10px;
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`;

export { StyledNumberInsideCircle as NumberInsideCircle };
