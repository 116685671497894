import { FormEventHandler } from 'react';

import { FC } from 'shared/types';

interface FormProps {
    onSubmit?: FormEventHandler<HTMLFormElement>;
}

const Form: FC<FormProps> = (props) => {
    const { onSubmit, children, ...rest } = props;

    return (
        <form onSubmit={onSubmit} noValidate {...rest}>
            {children}
        </form>
    );
};

export { Form };
