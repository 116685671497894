import { useLazyQuery, useQuery } from '@apollo/client';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { css, useTheme } from '@mui/material';
import { truncate } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { type FormatOptionLabelMeta } from 'react-select';

import {
    AshbyAccountByTeamIdQuery,
    GreenhouseAccountByTeamIdQuery,
    HireflowEnumsTeamFeaturesEnum,
    LeverAccountByTeamIdQuery,
    ProjectStatusEnum,
    ProjectsWithAtsMappingQuery,
    ProjectsWithAtsMappingQueryVariables,
    TeamFeaturesByTeamIdFeatureQuery,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Option, SelectChip, SelectChipProps } from 'shared/components/form';
import { AshbyLogo, GreenhouseLogo, LeverLogo } from 'shared/components/svgs';
import {
    GET_ASHBY_ACCOUNT_BY_TEAM_ID,
    GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
    GET_LEVER_ACCOUNT_BY_TEAM_ID,
} from 'shared/graphql/integrations';
import { GET_PROJECTS_WITH_ATS_MAPPING, projectsDefaultVariables } from 'shared/graphql/projects';
import { GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE } from 'shared/graphql/teams';
import { useSession } from 'shared/hooks';
import { colors } from 'shared/settings';
import { FCProps } from 'shared/types';
import { getOwner, maxNameLength } from 'shared/utils';

interface ProjectOption extends Option {
    user: ProjectsWithAtsMappingQuery['projects'][number]['user'];
    ashby_project_mapping: ProjectsWithAtsMappingQuery['projects'][number]['ashby_project_mapping'];
    greenhouse_project_mapping: ProjectsWithAtsMappingQuery['projects'][number]['greenhouse_project_mapping'];
    lever_project_mapping: ProjectsWithAtsMappingQuery['projects'][number]['lever_project_mapping'];
}

interface SelectProjectProps<Values extends FieldValues> extends SelectChipProps<Values> {}

const SelectProject = <Values extends FieldValues>(props: SelectProjectProps<Values> & FCProps) => {
    const { options: projectOptions, ...rest } = props;

    const theme = useTheme();
    const { session, loaded } = useSession();
    const [options, setOptions] = useState<ProjectOption[]>([]);

    const { data: teamFeaturesData } = useQuery<TeamFeaturesByTeamIdFeatureQuery>(
        GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE,
        {
            variables: {
                teamId: session?.user.teamId,
                feature: HireflowEnumsTeamFeaturesEnum.AtsLinkageProjects,
            },
            skip: !loaded,
        }
    );

    // ashby
    const { data: ashbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(GET_ASHBY_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // greenhouse
    const { data: greenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    // lever
    const { data: leverAccount } = useQuery<LeverAccountByTeamIdQuery>(GET_LEVER_ACCOUNT_BY_TEAM_ID, {
        skip: !loaded,
        variables: { teamId: session?.user.teamId },
    });

    const hasTeamFeatureAccess: boolean = !!teamFeaturesData?.data.length;
    const isAshbyConnected: boolean = !!ashbyAccount?.data.length;
    const isGreenhouseConnected: boolean = !!greenhouseAccount?.data.length;
    const isLeverConnected: boolean = !!leverAccount?.data.length;
    const isAtsConnected: boolean = isAshbyConnected || isGreenhouseConnected || isLeverConnected;

    const [getProjectsData] = useLazyQuery<ProjectsWithAtsMappingQuery, ProjectsWithAtsMappingQueryVariables>(
        GET_PROJECTS_WITH_ATS_MAPPING
    );

    const fetchProjectsData = useCallback(
        async () => {
            if (!projectOptions) {
                return;
            }

            const { data } = await getProjectsData({
                variables: {
                    ...projectsDefaultVariables,
                    where: {
                        id: { _in: projectOptions.map((p) => p.value) },
                        status: { _in: [ProjectStatusEnum.Active] },
                    },
                },
            });

            if (!data || !data.projects) {
                return;
            }

            setOptions(
                data.projects.map((project) => {
                    const matchedOption = projectOptions.find((option) => option.value === project.id);
                    return {
                        label: project.title,
                        value: project.id,
                        isDisabled: matchedOption && matchedOption.isDisabled,
                        user: project.user,
                        ashby_project_mapping: project.ashby_project_mapping,
                        greenhouse_project_mapping: project.greenhouse_project_mapping,
                        lever_project_mapping: project.lever_project_mapping,
                    };
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [projectOptions]
    );

    useEffect(() => {
        fetchProjectsData();
    }, [projectOptions, fetchProjectsData]);

    const getAtsIcon = useCallback(
        (option: ProjectOption) => {
            let mappingExists: boolean = false;
            let res: EmotionJSX.Element = (
                <Box
                    css={css`
                        width: 16px;
                    `}
                />
            );

            if (isAshbyConnected && option.ashby_project_mapping?.id) {
                mappingExists = true;
                res = <AshbyLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }
            if (isGreenhouseConnected && option.greenhouse_project_mapping?.id) {
                mappingExists = true;
                res = <GreenhouseLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }
            if (isLeverConnected && option.lever_project_mapping?.id) {
                mappingExists = true;
                res = <LeverLogo fill={theme.palette.primary.main} width={16} height={16} />;
            }

            return {
                mappingExists,
                icon: res,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAshbyConnected, isGreenhouseConnected, isLeverConnected]
    );

    const getOptionChild = useCallback(
        (projectOption: ProjectOption, hideTransparentIcon: boolean) => {
            const iconResponse = getAtsIcon(projectOption);
            const hideIcon = !iconResponse.mappingExists && hideTransparentIcon;

            return (
                <Box
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        width: 100%;
                    `}
                >
                    {hasTeamFeatureAccess && isAtsConnected && !hideIcon && iconResponse.icon}
                    <Box>{truncate(projectOption.label, { length: maxNameLength })}</Box>
                    <Box
                        css={css`
                            flex: 1;

                            display: flex;
                            justify-content: flex-end;

                            color: ${colors.grays.dusty};
                            font-size: 11px;
                            white-space: nowrap;
                        `}
                    >
                        {getOwner({ option: projectOption })}
                    </Box>
                </Box>
            );
        },
        [getAtsIcon, hasTeamFeatureAccess, isAtsConnected]
    );

    const formatOptionLabel = useCallback(
        (option: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => {
            const projectOption = options.find((o) => o.value === option.value);
            // use the matched option to determine if the transparent ats logo should be hidden
            const matchedOption = formatOptionLabelMeta.selectValue.find((o) => o.value === option.value);

            return getOptionChild(
                projectOption ?? (option as ProjectOption),
                formatOptionLabelMeta.context === 'value' && !!matchedOption
            );
        },
        [getOptionChild, options]
    );

    return (
        <SelectChip
            {...rest}
            css={css`
                z-index: 100;
            `}
            options={options.sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled))}
            formatOptionLabel={formatOptionLabel}
        />
    );
};

export { SelectProject };
export type { ProjectOption };
