import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Hyperlink } from 'shared/components/presentational';
import { hireflowSupport } from 'shared/constants';
import { FC } from 'shared/types';

interface ContactUsProps {}

/**
 * ===== NOTE =====
 * using <div> instead of <Box> here as BugsnagErrorBoundary does not build with <Box> components when using codebuild
 * the same goes for spacing, font and font sizing imports, so these have been manually set
 * ===== END =====
 */
const ContactUs: FC<ContactUsProps> = () => {
    const translate = useTranslations('errors.contact-support');

    return (
        <div
            css={css`
                margin-top: 8px;
            `}
        >
            <span>{translate('first')}</span>{' '}
            <Hyperlink newTab href={hireflowSupport}>
                {translate('link')}
            </Hyperlink>{' '}
            <span>{translate('last')}</span>
        </div>
    );
};

export { ContactUs };
