import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';

import { Box } from 'shared/components/containers';
import { Slider } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SliderObfuscationProps {
    text: string;
}

const StyledSlider = styled(Slider)`
    .MuiSlider-thumb {
        height: 30px;
        width: 30px;
    }

    .MuiSlider-track {
        height: 20px;
    }

    .MuiSlider-rail {
        background-color: transparent;
    }
` as typeof Slider;

// these values prevent the slider thumb from "clipping" over other elements
const minSliderValue = 4;
const maxSliderValue = 96;

const SliderObfuscation: FC<SliderObfuscationProps> = ({ text }) => {
    const [value, setValue] = useState<number>(maxSliderValue);

    const handleChange = (_: Event, newValue: number | number[]) => {
        if (newValue >= minSliderValue && newValue <= maxSliderValue) setValue(newValue as number);
    };

    return (
        <Box
            css={css`
                margin-bottom: ${spacing.space16px};
                cursor: pointer;
                user-select: none;
                position: relative;
            `}
        >
            <Box
                css={css`
                    height: 20px;
                    margin: 20px 0px;
                    width: calc(100% - 2%);
                    display: grid;
                    place-items: center;
                    overflow: hidden;
                    position: absolute;
                    background-color: white;
                    border-radius: 4px;
                `}
            >
                {text}
            </Box>
            <Box
                css={css`
                    height: 60px;
                    padding: 15px 0px;
                    width: 100%;
                    display: grid;
                    place-items: center;
                    overflow: hidden;
                    position: absolute;
                    z-index: 2;
                `}
            >
                <StyledSlider aria-label="slider obfuscation" value={value} min={0} max={100} onChange={handleChange} />
            </Box>
        </Box>
    );
};

export { SliderObfuscation };
