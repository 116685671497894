// cspell:ignore itph4zl1e8
import { css } from '@emotion/react';
import Head from 'next/head';

import { config } from 'config';
import { ArchiveUserNotice, PageHeader, VersionUpdateBar } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { ActionPanel, SideMenu } from 'shared/components/navigational';
import { ConfirmationModal, SnackbarAlert } from 'shared/components/presentational';
import { layoutZIndex, sideMenuSmallWidth, sideMenuWidth } from 'shared/constants';
import { colors, spacing } from 'shared/settings';
import { FC, PageHeaderBreadcrumb } from 'shared/types';

interface LayoutProps {
    breadcrumbs: PageHeaderBreadcrumb[];
    icon?: React.ReactNode;
    hasSideMenu?: boolean;
    hasActionPanel?: boolean;
    hasHeader?: boolean;
    isFixedWidth?: boolean;
    shouldCenterContent?: boolean;
    shouldCenterContentWithBackground?: boolean;
    hideSearchBar?: boolean;
}

const Layout: FC<LayoutProps> = (props) => {
    const {
        breadcrumbs,
        icon,
        children,
        hasSideMenu = true,
        hasActionPanel = false,
        hasHeader = true,
        isFixedWidth,
        shouldCenterContent = false,
        shouldCenterContentWithBackground = false,
        hideSearchBar = false,
    } = props;
    const headerTitle = `Hireflow | ${breadcrumbs[breadcrumbs.length - 1]?.text ?? ''}`;

    return (
        <Box
            component="div"
            css={css`
                display: flex;
                height: 100vh;
            `}
        >
            <Head>
                <title>{headerTitle}</title>
                <meta name="description" content="Hireflow - Source Smarter, Not Harder" />
                <link rel="icon" href="/hireflow-logo.png" />
                <script
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.intercomSettings = {
                            app_id: "sl1v9ugl"
                          };
                        `,
                    }}
                />
                <script
                    /* eslint-disable max-len */
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/sl1v9ugl';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                        `,
                    }}
                    /* eslint-enable max-len */
                />
                {config.Env === 'production' && (
                    <script
                        /* eslint-disable max-len */
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "itph4zl1e8");
                        `,
                        }}
                        /* eslint-enable max-len */
                    />
                )}
            </Head>

            {hasSideMenu && <SideMenu />}

            <Box
                css={css`
                    ${!isFixedWidth ? `width: calc(100% - ${sideMenuWidth})` : `width: 100%`};
                    overflow-y: auto;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 1000px) {
                        ${!isFixedWidth ? `width: calc(100% - ${sideMenuSmallWidth})` : `width: 100%`};
                    }
                `}
            >
                {hasHeader && (
                    <Box
                        css={css`
                            position: absolute;
                            z-index: ${layoutZIndex};
                            ${!isFixedWidth ? `width: calc(100% - ${sideMenuWidth})` : `width: 100%`};

                            padding: ${spacing.space16px} ${spacing.space32px};

                            background-color: ${colors.grays.alabaster};
                            border-bottom: 1px solid ${colors.grays.gallery};
                            box-shadow: 0px 4px 30px 0px #ebebeb80;

                            @media (max-width: 1000px) {
                                width: calc(100% - ${sideMenuSmallWidth});
                            }
                        `}
                    >
                        <PageHeader breadcrumbs={breadcrumbs} icon={icon} hideSearchBar={hideSearchBar} />
                    </Box>
                )}

                <Box
                    css={css`
                        padding-top: ${spacing.space48px};

                        @media (max-width: 1200px) {
                            padding-top: ${spacing.space64px};
                        }

                        ${!isFixedWidth
                            ? `
                                margin: ${spacing.space32px} ${spacing.space32px};
                                flex: 1;

                                @media (max-width: 1200px) {
                                    margin: ${spacing.space16px} ${spacing.space32px};
                                }
                        `
                            : `
                                margin: 0 auto;
                                margin-top: ${spacing.space48px};

                                @media (max-width: 1200px) {
                                    margin-top: ${spacing.space16px};
                                }
                        `}
                        ${shouldCenterContent
                            ? `    
                                display: flex;
                                flex-flow: column;
                                align-items: center;
                                justify-content: center;`
                            : null}
                        ${shouldCenterContentWithBackground
                            ? `    
                                display: flex;
                                flex-flow: column;
                                align-items: center;
                                justify-content: center;

                                background: ${colors.neutrals.white};
                                border-radius: ${spacing.space4px};
                                border: 1px solid ${colors.grays.gallery};
                                `
                            : null}
                    `}
                >
                    <main>{children}</main>
                </Box>
                {hasActionPanel && <ActionPanel />}
            </Box>
            <SnackbarAlert />
            <ConfirmationModal />
            <VersionUpdateBar />
            <ArchiveUserNotice />
        </Box>
    );
};

export { Layout };
