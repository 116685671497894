import { DocumentNode, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useSession, useTabVisibility } from 'shared/hooks';

export function useQueryRefresh<TData = any, TVariables = any>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
) {
    const { loaded: sessionLoaded } = useSession();
    const isVisible = useTabVisibility();

    const queryResult = useQuery<TData, TVariables>(query, options);

    useEffect(() => {
        if (sessionLoaded && isVisible) {
            queryResult.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    return queryResult;
}
