import { LicenseTypesEnum, PlanTypesEnum } from 'codegen/graphql';
import { SelectOption } from 'shared/components/form';

const dailySendOptionsNone: SelectOption[] = [{ value: '0', label: '0' }];

const dailySendOptionsSoloOrTrial: SelectOption[] = [
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '150', label: '150' },
    { value: '200', label: '200' },
];

const dailySendOptionsEnterprise: SelectOption[] = [
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '150', label: '150' },
    { value: '200', label: '200' },
    { value: '250', label: '250' },
    { value: '300', label: '300' },
    { value: '350', label: '350' },
    { value: '400', label: '400' },
    { value: '450', label: '450' },
    { value: '500', label: '500' },
];

const getDailySendLimitOptions = (planType: PlanTypesEnum, licenseType: LicenseTypesEnum): SelectOption[] => {
    if ([PlanTypesEnum.Solo, PlanTypesEnum.FreeTrial].includes(planType)) {
        return dailySendOptionsSoloOrTrial;
    }
    if (
        [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(planType) &&
        licenseType !== LicenseTypesEnum.Reviewer
    ) {
        return dailySendOptionsEnterprise;
    }

    if (licenseType === LicenseTypesEnum.Reviewer || planType === PlanTypesEnum.Expired) {
        return dailySendOptionsNone;
    }

    return dailySendOptionsNone;
};

export { getDailySendLimitOptions };
