import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import React from 'react';

import { SequenceCollaboratorForSharing } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ShareSequenceListSubmenuProps {
    collaborator: SequenceCollaboratorForSharing;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
    onRemove: (collaboratorId: string) => void;
}

const ShareSequenceListSubmenu: FC<ShareSequenceListSubmenuProps> = ({ collaborator, anchor, setAnchor, onRemove }) => {
    const translate = useTranslations('sequence.details.sharing');

    // we can use the non-null assertion operator here because we know that
    // the collaborator is not the owner and the id exists
    const collaboratorId = collaborator.id!;
    const handleSubmenuPopoverClose = () => setAnchor(undefined);
    const handleRemove = () => {
        onRemove(collaboratorId);
        handleSubmenuPopoverClose();
    };

    return (
        <Popover
            css={css`
                .MuiPaper-root {
                    // !important is needed to make the transform stick.
                    transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                }
            `}
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={handleSubmenuPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box
                css={css`
                    border-radius: 4px;
                `}
            >
                <SubmenuButton onClick={handleRemove}>{translate('remove-label')}</SubmenuButton>
            </Box>
        </Popover>
    );
};

export { ShareSequenceListSubmenu };
