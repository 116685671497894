import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useTranslations } from 'next-intl';

import { trackEvent } from 'analytics';
import { UpdateSequencesMutation, SequenceStatusEnum } from 'codegen/graphql';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import {
    GET_SEQUENCES,
    GET_SEQUENCES_COUNT_BY_STATUS,
    GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
    UPDATE_SEQUENCES,
} from 'shared/graphql/sequences';
import { useSnackbarAlert } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SequenceListTopSubmenuProps {
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
}

const SequenceListTopSubmenu: FC<SequenceListTopSubmenuProps> = ({ anchor, setAnchor }) => {
    const translate = useTranslations('sequence');
    const handleSubmenuPopoverClose = () => setAnchor(undefined);

    const { showSnackbarAlert } = useSnackbarAlert();

    const [updateSequences] = useMutation<UpdateSequencesMutation>(UPDATE_SEQUENCES, {
        refetchQueries: [
            getOperationName(GET_SEQUENCES) as string,
            getOperationName(GET_SEQUENCES_COUNT_BY_STATUS) as string,
            getOperationName(GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS) as string,
        ],
    });

    const handlePauseAll = async () => {
        try {
            const res = await updateSequences({
                variables: {
                    where: {
                        status: { _in: [SequenceStatusEnum.Active, SequenceStatusEnum.Ready] },
                    },
                    set: {
                        status: SequenceStatusEnum.Paused,
                    },
                },
            });
            showSnackbarAlert({
                severity: 'success',
                message: translate('sequence-list-table.paused-all'),
            });
            if (res.data) {
                const pausedSequences = res.data.update_sequences?.returning.map((a) => a.id);
                if (pausedSequences) {
                    trackEvent('click_pause_all', { sequence_ids: pausedSequences, value: 'sequence' });
                }
            }
        } catch (error) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('sequence-list-table.pause-all-fail'),
            });
        }
    };

    return (
        <Popover
            css={css`
                .MuiPaper-root {
                    // !important is needed to make the transform stick.
                    transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                }
            `}
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={handleSubmenuPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box
                css={css`
                    border-radius: 4px;
                `}
            >
                <SubmenuButton onClick={handlePauseAll}>{translate('pause-all')}</SubmenuButton>
            </Box>
        </Popover>
    );
};

export { SequenceListTopSubmenu };
