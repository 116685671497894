import { gql } from '@apollo/client';

import { OrderBy, ProjectsOrderBy, ProjectsQuery, ProjectStatusEnum, UserProjectsQuery } from 'codegen/graphql';

export type ProjectsData = ProjectsQuery['projects'][number];
export type ProjectsProps = {
    initialData: {
        data: NonNullable<ProjectsData>[];
        count: number;
    };
};
export type UserProjectsData = UserProjectsQuery['user_projects'][number];
export type UserProjectsProps = {
    initialData: {
        data: NonNullable<UserProjectsData>[];
        count: number;
    };
    waitingRoomData: NonNullable<UserProjectsData>[];
};
export enum UserProjectCollaboratorType {
    Owner = 'owner',
    Editor = 'editor',
    Viewer = 'viewer',
}

export const projectsDefaultOrderBy: keyof ProjectsOrderBy = 'createdAt';
export const projectsDefaultOrderByDirection = OrderBy.Desc;

export const projectsDefaultVariables = {
    orderBy: { [projectsDefaultOrderBy]: projectsDefaultOrderByDirection },
    limit: 10,
    offset: 0,
    where: { status: { _in: [ProjectStatusEnum.Active] } },
    withDetails: true,
};

export const userProjectsDefaultVariables = {
    orderBy: { project: { [projectsDefaultOrderBy]: projectsDefaultOrderByDirection } },
    limit: 10,
    offset: 0,
    where: { project: { status: { _in: [ProjectStatusEnum.Active] } } },
    withDetails: true,
};

const PROJECT_DETAILS = gql`
    fragment ProjectDetails on projects {
        id
        title
        description
        status
        color
        defaultUserProject
        stats {
            added
            ready
            inSequence
            opens
            replies
            interested
            opensPercentage
            repliesPercentage
            interestedPercentage
        }
        isProtected
        reviewerId
        userId
        user {
            id
            firstName
            lastName
            fullName
            email
        }
        collaborators {
            id
            userId
            type
            user {
                id
                fullName
                email
            }
        }
        project_members {
            prospectId
            projectId
        }
        modifiedAt
        createdAt
        atsSyncSetting
        ashby_project_mapping {
            id
            job {
                name
                remoteId
            }
        }
        greenhouse_project_mapping {
            id
            job {
                name
                remoteId
            }
        }
        lever_project_mapping {
            id
            posting {
                name
                remoteId
            }
        }
    }
`;

// queries
export const GET_USER_PROJECTS = gql`
    query UserProjects(
        $orderBy: user_projects_order_by!
        $limit: Int
        $offset: Int = 0
        $where: user_projects_bool_exp!
        $withDetails: Boolean!
    ) {
        user_projects_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        user_projects(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where) {
            project {
                id
                title
                ...ProjectDetails @include(if: $withDetails)
            }
        }
    }

    ${PROJECT_DETAILS}
`;

export const GET_USER_PROJECTS_OWNERS = gql`
    query ProjectsOwners($userId: String! = "") {
        projects(
            distinct_on: userId
            where: { _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }] }
        ) {
            id
            user {
                id
                fullName
            }
        }
    }
`;

export const GET_PROJECTS = gql`
    query Projects(
        $orderBy: projects_order_by!
        $limit: Int
        $offset: Int = 0
        $where: projects_bool_exp!
        $withDetails: Boolean!
    ) {
        projects_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        projects(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where) {
            id
            title
            ...ProjectDetails @include(if: $withDetails)
        }
    }

    ${PROJECT_DETAILS}
`;

export const GET_PROJECTS_WITH_ATS_MAPPING = gql`
    query ProjectsWithAtsMapping($where: projects_bool_exp!) {
        projects(where: $where) {
            id
            title
            user {
                fullName
            }
            ashby_project_mapping {
                id
            }
            greenhouse_project_mapping {
                id
            }
            lever_project_mapping {
                id
            }
        }
    }
`;

export const GET_PROJECTS_WITH_PENDING_MEMBER_REVIEWS = gql`
    query ProjectsWithPendingMemberReviews(
        $orderBy: projects_order_by!
        $limit: Int!
        $offset: Int!
        $reviewerId: String! = ""
        $withDetails: Boolean!
    ) {
        projects_aggregate(
            where: { project_members: { status: { _eq: to_review } }, reviewerId: { _eq: $reviewerId } }
        ) {
            aggregate {
                count
            }
        }
        projects(
            order_by: [$orderBy]
            limit: $limit
            offset: $offset
            where: { project_members: { status: { _eq: to_review } }, reviewerId: { _eq: $reviewerId } }
        ) {
            id
            ...ProjectDetails @include(if: $withDetails)
            project_members_aggregate(where: { status: { _eq: to_review } }) {
                aggregate {
                    count
                }
            }
        }
    }

    ${PROJECT_DETAILS}
`;

export const GET_PROJECT_BY_ID = gql`
    query ProjectById($id: String!) {
        projects_by_pk(id: $id) {
            id
            ...ProjectDetails
        }
    }

    ${PROJECT_DETAILS}
`;

/**
 * the query should include the defaultUserProjects ie Waiting Room projects shared with the current user as well
 * current user's defaultUserProject i.e. the Waiting Room is not included in the aggregate count
 * but we should include the defaultUserProjects shared with the current user but not owned by the current user
 */
export const GET_USER_PROJECTS_COUNT_BY_STATUS = gql`
    query UserProjectsByStatus($currentUserId: String!) {
        active: user_projects_aggregate(
            where: {
                _or: [
                    { project: { status: { _eq: active }, defaultUserProject: { _is_null: true } } }
                    {
                        project: {
                            status: { _eq: active }
                            defaultUserProject: { _eq: true }
                            userId: { _neq: $currentUserId }
                        }
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
        archived: user_projects_aggregate(
            where: {
                _or: [
                    { project: { status: { _eq: archived }, defaultUserProject: { _is_null: true } } }
                    {
                        project: {
                            status: { _eq: archived }
                            defaultUserProject: { _eq: true }
                            userId: { _neq: $currentUserId }
                        }
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
        draft: user_projects_aggregate(
            where: {
                _or: [
                    { project: { status: { _eq: draft }, defaultUserProject: { _is_null: true } } }
                    {
                        project: {
                            status: { _eq: draft }
                            defaultUserProject: { _eq: true }
                            userId: { _neq: $currentUserId }
                        }
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
        all: user_projects_aggregate(
            where: {
                _or: [
                    { project: { defaultUserProject: { _is_null: true } } }
                    { project: { defaultUserProject: { _eq: true }, userId: { _neq: $currentUserId } } }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USER_PROJECTS_FOR_PROSPECT_PANEL = gql`
    query UserProjectsForProspectPanel($orderBy: user_projects_order_by!, $where: user_projects_bool_exp!) {
        user_projects(order_by: [$orderBy], where: $where) {
            project {
                id
                title
                isProtected
            }
        }
    }
`;

// mutations
export const CREATE_PROJECT = gql`
    mutation CreateProject($project: projects_insert_input!) {
        inserted: insert_projects_one(object: $project) {
            id
        }
    }
`;

export const DUPLICATE_PROJECT = gql`
    mutation DuplicateProject($project: projects_insert_input!, $projectActivity: project_activities_insert_input!) {
        inserted: insert_projects_one(object: $project) {
            id
        }
        insert_project_activities_one(object: $projectActivity) {
            id
        }
    }
`;

export const UPDATE_PROJECTS = gql`
    mutation UpdateProjects($where: projects_bool_exp!, $set: projects_set_input!) {
        update_projects(where: $where, _set: $set) {
            affected_rows
            returning {
                id
                ...ProjectDetails
            }
        }
    }

    ${PROJECT_DETAILS}
`;

export const UPDATE_PROJECT_BY_ID = gql`
    mutation UpdateProjectById($id: String!, $set: projects_set_input!) {
        updated: update_projects_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;

export const INSERT_PROJECT_SEQUENCE_MAPPINGS = gql`
    mutation InsertProjectSequenceMappings($objects: [project_sequence_mapping_insert_input!]!) {
        insert_project_sequence_mapping(
            objects: $objects
            on_conflict: { constraint: project_sequence_mapping_pkey, update_columns: [] }
        ) {
            affected_rows
        }
    }
`;

export const DELETE_PROJECT_SEQUENCE_MAPPINGS = gql`
    mutation DeleteProjectSequenceMappings($where: project_sequence_mapping_bool_exp!) {
        delete_project_sequence_mapping(where: $where) {
            affected_rows
        }
    }
`;

export const GET_USER_OWNED_PROJECTS_COUNT_BY_STATUS = gql`
    query UserOwnedProjectsByStatus($currentUserId: String) {
        active: user_projects_aggregate(
            where: {
                project: {
                    status: { _eq: active }
                    defaultUserProject: { _is_null: true }
                    userId: { _eq: $currentUserId }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        archived: user_projects_aggregate(
            where: {
                project: {
                    status: { _eq: archived }
                    defaultUserProject: { _is_null: true }
                    userId: { _eq: $currentUserId }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        draft: user_projects_aggregate(
            where: {
                project: {
                    status: { _eq: draft }
                    defaultUserProject: { _is_null: true }
                    userId: { _eq: $currentUserId }
                }
            }
        ) {
            aggregate {
                count
            }
        }
        all: user_projects_aggregate(
            where: { project: { defaultUserProject: { _is_null: true }, userId: { _eq: $currentUserId } } }
        ) {
            aggregate {
                count
            }
        }
    }
`;
