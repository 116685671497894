import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedLayersThree: FC<SvgProps> = ({ width, height, stroke, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '20'}
        height={height ?? '20'}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M1.66663 9.99997L9.70182 14.0176C9.81114 14.0722 9.86579 14.0996 9.92313 14.1103C9.97391 14.1198 10.026 14.1198 10.0768 14.1103C10.1341 14.0996 10.1888 14.0722 10.2981 14.0176L18.3333 9.99997M1.66663 14.1666L9.70182 18.1842C9.81114 18.2389 9.86579 18.2662 9.92313 18.277C9.97391 18.2865 10.026 18.2865 10.0768 18.277C10.1341 18.2662 10.1888 18.2389 10.2981 18.1842L18.3333 14.1666M1.66663 5.83331L9.70182 1.81571C9.81114 1.76105 9.86579 1.73372 9.92313 1.72297C9.97391 1.71344 10.026 1.71344 10.0768 1.72297C10.1341 1.73372 10.1888 1.76105 10.2981 1.81571L18.3333 5.83331L10.2981 9.8509C10.1888 9.90556 10.1341 9.93289 10.0768 9.94365C10.026 9.95317 9.97391 9.95317 9.92313 9.94365C9.86579 9.93289 9.81114 9.90556 9.70182 9.8509L1.66663 5.83331Z"
            stroke={stroke ?? theme?.palette.secondary.light}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const LayersThree = withTheme(ThemedLayersThree);

export { LayersThree };
