import styled from '@emotion/styled';

import { FC } from 'shared/types';

interface ListProps {
    type: 'ordered' | 'unordered';
    defaultStyle?: boolean; // * applies default browser styles. default=false
}

const ListContainer: FC<ListProps> = (props) => {
    const { className, children, type, ...rest } = props;

    if (type === 'ordered') {
        return (
            <ol className={className} {...rest}>
                {children}
            </ol>
        );
    }

    return (
        <ul className={className} {...rest}>
            {children}
        </ul>
    );
};

const List = styled(ListContainer)<ListProps>`
    ${({ defaultStyle }) =>
        !defaultStyle &&
        `
      list-style: none;
      margin: 0;              
      padding: 0;
    `}

    @media (max-width: 1200px) {
        // background-color: green;
    }
`;

export { List };
