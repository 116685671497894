import { css, useTheme } from '@mui/material';
import { formatEmail } from 'hireflow-shared/lib/emails/email-utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { ContactChannelsEnum, ContactKindsEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, EditCloseButton, TextField } from 'shared/components/presentational';
import { MinusCircle } from 'shared/components/svgs';
import { ContactUpdate } from 'shared/graphql/contacts';
import { useProspectPanel, useSession } from 'shared/hooks';
import { isEmailValid, StatusEligibleForContactUpdate } from 'shared/services';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectNoEmailProps {}

const ProspectNoEmail: FC<ProspectNoEmailProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('prospects.tabs.profile-tab');
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [newEmailText, setNewEmailText] = useState<string>('');

    const { session } = useSession();
    const { addProspectContact, recipients } = useProspectPanel();

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleMouseOver = () => {
        setShowEdit(true);
    };

    const handleMouseOut = () => {
        setShowEdit(false);
    };

    const handleCloseEditEmail = () => {
        setEditing(false);
    };

    const handleSaveEmail = () => {
        if (!isEmailValid(newEmailText)) {
            return;
        }

        const contactUpdates: ContactUpdate = {
            kind: ContactKindsEnum.Unknown,
            value: formatEmail(newEmailText),
            standardizedValue: formatEmail(newEmailText),
            channel: ContactChannelsEnum.Email,
            primary: true,
            invalid: false,
            userMarkedInvalid: false,
        };

        const recipientIds = recipients
            ?.filter((f) => f.user.id === session?.user.id && StatusEligibleForContactUpdate.includes(f.status))
            .map((r) => r.id);

        addProspectContact([contactUpdates], recipientIds ?? []);

        setEditing(false);
        setNewEmailText('');
    };

    const handleNewEmailTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmailText(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleSaveEmail();
        }
    };

    const actionButtons = (
        <EditCloseButton
            editing={showEdit && !editing}
            onEdit={handleEditClick}
            onClose={handleCloseEditEmail}
            editButtonLabel={translate('edit-button-label')}
            closeButtonLabel={translate('close-button-label')}
        />
    );

    const editableContent = (
        <Box>
            <Box
                css={css`
                    padding-top: ${spacing.space12px};
                    padding-bottom: ${spacing.space24px};
                `}
            >
                <Box
                    css={css`
                        padding-bottom: ${spacing.space8px};
                    `}
                >
                    {translate('no-email.description')}
                </Box>
                <TextField
                    value={newEmailText}
                    onChange={handleNewEmailTextChange}
                    onKeyDown={handleKeyPress}
                    label={translate('no-email.email-text-label')}
                    inputRef={(input) => input && input.focus()}
                    css={css`
                        width: 100%;
                    `}
                />
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: ${spacing.space24px};
                `}
            >
                <Button variant="contained" onClick={handleSaveEmail}>
                    {translate('no-email.save-button-label')}
                </Button>
            </Box>
        </Box>
    );

    const staticContent = (
        <>
            <Box
                css={css`
                    padding-top: ${spacing.space12px};
                    padding-bottom: ${spacing.space16px};
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <Box
                        css={css`
                            padding-right: ${spacing.space8px};
                        `}
                    >
                        <MinusCircle stroke={theme.palette.grey[200]} />
                    </Box>
                    <Box>
                        {translate.rich('no-email.label', {
                            // eslint-disable-next-line react/no-unstable-nested-components
                            bold: (children) => (
                                <Box
                                    component="span"
                                    css={css`
                                        font-weight: ${fontWeights.bold};
                                    `}
                                >
                                    {children}
                                </Box>
                            ),
                        })}
                    </Box>
                </Box>
            </Box>
            {editing && editableContent}
        </>
    );

    return (
        <Box
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            css={css`
                margin-top: ${spacing.space4px};
                padding: ${spacing.none} ${spacing.space12px};
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                background-color: ${editing ? colors.greens.narvik30 : theme.palette.common.white};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: ${spacing.space4px};
                `}
            >
                <Box
                    css={css`
                        padding-top: ${spacing.space12px};
                        font-size: ${fontSizes.f12};
                        color: ${theme.palette.grey[600]};
                    `}
                >
                    {translate('email-label')}
                </Box>
                <Box hidden={!showEdit && !editing}>{actionButtons}</Box>
            </Box>
            {staticContent}
        </Box>
    );
};

export { ProspectNoEmail };
