import { gql } from '@apollo/client';

export const CREATE_PROSPECT_CUSTOM_MESSAGE = gql`
    mutation CreateProspectCustomMessage($prospectCustomMessage: prospect_custom_messages_insert_input!) {
        inserted: insert_prospect_custom_messages_one(object: $prospectCustomMessage) {
            id
            customMessage
        }
    }
`;
