/**
 * Adapted from https://usehooks.com/useLocalStorage/
 */

import { useCallback, useEffect, useState } from 'react';

import { logger } from 'logger';
import { ssr } from 'ssr-check';

const getLocalStorageKey = <T>(key: string, defaultVal: T): T => {
    try {
        const val = localStorage.getItem(key);
        const { value, expireAt } = JSON.parse(val!);
        if (value !== undefined && value !== null && (expireAt === -1 || expireAt > Date.now())) {
            return value;
        }
        localStorage.removeItem(key);
        return defaultVal;
    } catch (err) {
        localStorage.removeItem(key);
        return defaultVal;
    }
};

const setLocalStorageKey = <T>(key: string, value: T, expireAt: number) => {
    if (value === undefined || value === null) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, JSON.stringify({ value, expireAt }));
    }
};

// Hook
export function useLocalStorage<T>(key: string, initialValue: T, expireAfterMs = -1) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(initialValue);
    const [loaded, setLoaded] = useState(false);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                // Save state
                setStoredValue(valueToStore);
                // Save to local storage
                if (!ssr) {
                    const expireAt = expireAfterMs === -1 ? -1 : Date.now() + expireAfterMs;
                    setLocalStorageKey(key, valueToStore, expireAt);
                }
            } catch (err) {
                // A more advanced implementation would handle the error case
                logger.warn('error in useLocalStorage', { err });
            }
        },
        [key, expireAfterMs, storedValue]
    );

    useEffect(() => {
        try {
            // Get from local storage by key
            const item = getLocalStorageKey(key, initialValue);
            setStoredValue(item ?? initialValue);
        } catch (err) {
            // If error also return initialValue
            logger.warn('error in useLocalStorage', { err });
            setStoredValue(initialValue);
        }
        setLoaded(true);
    }, [key, initialValue]);

    return [storedValue, setValue, loaded] as const;
}
