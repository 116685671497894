import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { EmailAccountProviderEnum } from 'codegen/graphql';
import { config } from 'config';
import { signOutIntercom } from 'external/intercom';
import { logger } from 'logger';
import { Box } from 'shared/components/containers/box';
import { Logout } from 'shared/components/svgs';
import { useSession } from 'shared/hooks/use-session';
import { createRestApiClient } from 'shared/services';
import { fontFamilies, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ssr } from 'ssr-check';

const LogoutButton: FC<unknown> = (props) => {
    const { session } = useSession();
    const translate = useTranslations('side-bar');
    const restApiClient = createRestApiClient();

    /**
     * Reset the local storage when the user logs out
     */
    const resetLocalStorage = () => {
        if (!ssr) {
            localStorage.removeItem('storedSequenceForm');
        }
    };

    const handleLogout = async () => {
        try {
            await restApiClient.logout();
            resetLocalStorage();
        } catch (error: any) {
            logger.error(`Error logging out: ${error}`);
        } finally {
            const redirectUrl =
                session?.user.loginEmailProvider === EmailAccountProviderEnum.Outlook
                    ? // eslint-disable-next-line max-len
                      `https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id=${config.OutlookOAuthAppConfig.clientId}&post_logout_redirect_uri=${config.PublicPath}`
                    : '/';
            window.location.href = redirectUrl;
            signOutIntercom();
        }
    };

    return (
        <Box
            component="button"
            onClick={handleLogout}
            css={css`
                display: flex;
                align-items: center;
                background: none;
                border: none;
                margin: ${spacing.space16px};
                margin-left: 5px;
                padding: ${spacing.none};
                font-weight: ${fontWeights.semiBold};
                font-family: ${fontFamilies.montserrat};
            `}
            {...props}
        >
            <Box
                component="span"
                css={css`
                    margin-right: ${spacing.space16px};
                `}
            >
                <Logout width={16} height={16} />
            </Box>
            <Box
                css={css`
                    @media (max-width: 1000px) {
                        display: none;
                    }
                `}
            >
                {translate('logout')}
            </Box>
        </Box>
    );
};

export { LogoutButton };
