import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ConnectEditFormButtonsProps {
    canEdit: boolean;
    isEditing?: boolean;
    onClose: () => void;
}

const ConnectEditFormButtons: FC<ConnectEditFormButtonsProps> = ({ canEdit, isEditing = false, onClose }) => {
    const translate = useTranslations('integrations.lever');

    return (
        <Box
            css={css`
                display: flex;
                justify-content: flex-end;
                gap: ${spacing.space8px};
                margin-top: ${spacing.space8px};
            `}
        >
            {isEditing ? (
                <Button variant="outlined" onClick={onClose}>
                    {translate('details.cancel')}
                </Button>
            ) : null}
            <Button disabled={!canEdit} type="submit" variant="contained">
                {isEditing ? translate('details.submit') : translate('connect.submit')}
            </Button>
        </Box>
    );
};

export { ConnectEditFormButtons };
