import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { SourcerMemberStatusEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Button, Hyperlink } from 'shared/components/presentational';
import { AlertTriangle } from 'shared/components/svgs';
import { appRoutes, colors, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectSourcerMember } from 'shared/types/prospect';

interface ProspectSourcerConfirmAddProps {
    sourcerMember: ProspectSourcerMember;
    handleGoBack: () => void;
    handleConfirmAndContinue: () => void;
}

const ProspectSourcerConfirmAdd: FC<ProspectSourcerConfirmAddProps> = (props) => {
    const theme = useTheme();
    const { sourcerMember, handleGoBack, handleConfirmAndContinue } = props;
    const translate = useTranslations('prospects.tabs.add-tab.sourcer-extension-confirm-add-tab');

    return (
        <Box
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 24px 24px 0px;
                border-radius: 4px;
                height: calc(100vh - 296px);
            `}
        >
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                `}
            >
                <AlertTriangle width={63} height={71} stroke={colors.yellows.tulipTree} />
                <Box
                    css={css`
                        margin: 8px;
                        font-size: 24px;
                        font-weight: ${fontWeights.bold};
                    `}
                >
                    {translate('header')}
                </Box>
                <Box
                    css={css`
                        font-size: 16px;
                        text-align: center;
                        margin: 0 48px 0;
                    `}
                >
                    {sourcerMember.status === SourcerMemberStatusEnum.Snoozed
                        ? translate.rich('description-in-snooze', {
                              name: sourcerMember.firstName,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  sourcerMember.sourcer.title,
                                  appRoutes.sourcing.index(sourcerMember.sourcer.id)
                              ),
                          })
                        : translate.rich('description-in-review', {
                              name: sourcerMember.firstName,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  sourcerMember.sourcer.title,
                                  appRoutes.sourcing.index(sourcerMember.sourcer.id)
                              ),
                          })}
                </Box>
                <Box
                    css={css`
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin: 24px;
                        column-gap: 12px;
                    `}
                >
                    <Button
                        css={css`
                            width: 200px;
                        `}
                        variant="outlined"
                        onClick={handleGoBack}
                    >
                        {translate('button-go-back')}
                    </Button>
                    <Button
                        css={css`
                            width: 200px;
                        `}
                        variant="contained"
                        onClick={handleConfirmAndContinue}
                    >
                        {translate('button-confirm-and-add')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const ClickableEntity = (primaryTheme: string, title: string, link: string) => () =>
    (
        <Box
            component="span"
            css={css`
                color: ${primaryTheme};
            `}
        >
            <Hyperlink newTab href={link}>
                {title}
            </Hyperlink>
        </Box>
    );

export { ProspectSourcerConfirmAdd };
