import { FC, SourcerData } from 'shared/types';
import { SourcerMemberList } from 'sourcing/components/composite';
import { NoCreditsNotice, NothingToReviewNotice } from 'sourcing/components/presentational';

interface ToReviewProps {
    count: number;
    sourcer: SourcerData;
    showNoCreditsNotice: boolean;
}

const ToReview: FC<ToReviewProps> = ({ count, sourcer, showNoCreditsNotice }) =>
    showNoCreditsNotice ? (
        <NoCreditsNotice />
    ) : count > 0 ? (
        <SourcerMemberList sourcer={sourcer} />
    ) : (
        <NothingToReviewNotice />
    );

export { ToReview };
