import {
    connectIntegrationOptionAshby,
    connectIntegrationOptionGreenhouse,
    connectIntegrationOptionLever,
} from 'integrations/constants';
import { SelectOption } from 'shared/components/form';

const connectNewAtsOptions = (): SelectOption[] => [
    {
        value: connectIntegrationOptionAshby,
        label: connectIntegrationOptionAshby,
    },
    {
        value: connectIntegrationOptionGreenhouse,
        label: connectIntegrationOptionGreenhouse,
    },
    {
        value: connectIntegrationOptionLever,
        label: connectIntegrationOptionLever,
    },
];

export { connectNewAtsOptions };
