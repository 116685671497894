import { css, useTheme } from '@mui/material';

import ReactQuill from 'shared/components/editor/react-quill-wrapper';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface EmailSignaturePreviewProps {
    signatureHtml: string;
    fontColor?: string;
}

const EmailSignaturePreview: FC<EmailSignaturePreviewProps> = (props) => {
    const { signatureHtml, fontColor } = props;
    const theme = useTheme();

    const delta = {
        ops: [
            {
                insert: {
                    signature: signatureHtml,
                },
            },
        ],
    };

    return (
        <ReactQuill
            value={delta as any}
            onClearVariable={() => {}}
            onEditorTouched={() => {}}
            onEdited={() => {}}
            setFormValue={() => {}}
            isSubjectTouched={false}
            selectedVariable=""
            readOnly
            modules={{
                toolbar: false,
                clipboard: {
                    matchVisual: false,
                },
            }}
            css={css`
                .editor {
                    border: 0px !important;
                }
                .ql-editor {
                    font-size: ${fontSizes.f16};
                    padding: 0;
                    p {
                        color: ${fontColor ?? theme.palette.common.black};
                        a {
                            text-decoration: none;
                            pointer-events: none;
                            color: ${fontColor ?? theme.palette.common.black};
                        }
                    }
                }

                .ql-container {
                    background: transparent;
                    border: 0px !important;
                    padding: 0px;
                    max-height: 100%;
                    overflow: auto;
                }
            `}
        />
    );
};

export { EmailSignaturePreview };
