import { css, useTheme } from '@mui/material';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Subject } from 'sequences/components/form';
import { Box } from 'shared/components/containers';
import { Editor } from 'shared/components/editor';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CompactEmailEditorProps {
    formMethods: UseFormReturn<any>;
    index: number;
    disabled?: boolean;
    disabledPersonalization?: boolean;
    showSubject?: boolean;
    modules?: Record<string, any>;
    singleStageEdit?: boolean;
    signatureHtml?: string;
}

const CompactEmailEditor: FC<CompactEmailEditorProps> = (props) => {
    const theme = useTheme();
    const { formMethods, index, disabled = false, showSubject, modules, singleStageEdit, signatureHtml } = props;
    const [selectedVariable, setSelectedVariable] = useState<string>('');
    const [isEditorTouched, setIsEditorTouched] = useState<boolean>(false);
    const [isSubjectTouched, setIsSubjectTouched] = useState<boolean>(false);

    const handleClearVariable = () => {
        setSelectedVariable('');
    };

    const handleIsEditorTouched = (touched: boolean) => {
        setIsEditorTouched(touched);
    };

    const handleIsSubjectTouched = (touched: boolean) => {
        setIsSubjectTouched(touched);
    };

    return (
        <Box>
            {showSubject && (
                <Box
                    css={css`
                        margin-bottom: ${spacing.space12px};
                    `}
                >
                    <Subject
                        formMethods={formMethods}
                        index={index}
                        isEditorTouched={isEditorTouched}
                        onClearVariable={handleClearVariable}
                        selectedVariable={selectedVariable}
                        onSubjectTouched={handleIsSubjectTouched}
                        fullWidth
                        singleStageEdit={singleStageEdit}
                        personalize
                    />
                </Box>
            )}
            <Box>
                <Editor
                    formMethods={formMethods}
                    index={index}
                    isSubjectTouched={showSubject ? isSubjectTouched : false}
                    name={`stages.${index}.content`}
                    onEditorTouched={handleIsEditorTouched}
                    onClearVariable={handleClearVariable}
                    selectedVariable={selectedVariable}
                    disabled={disabled}
                    compact
                    showFooter={false}
                    modules={modules ?? undefined}
                    signatureHtml={signatureHtml}
                    css={css`
                        .editor {
                            border: 1px solid ${colors.greens.narvik};
                            border-radius: 4px;
                        }
                        .ql-toolbar {
                            background-color: ${theme.palette.common.white};
                            padding-bottom: ${spacing.none};
                            border-bottom: 1px solid ${colors.greens.narvik} !important;
                        }
                        .ql-container {
                            padding: ${spacing.none};
                            max-height: 100%;
                            overflow: auto;
                        }
                        .ql-editor {
                            background-color: ${theme.palette.common.white};
                        }
                    `}
                />
            </Box>
        </Box>
    );
};

export { CompactEmailEditor };
