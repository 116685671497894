/* eslint-disable no-magic-numbers */
import { css } from '@emotion/react';
import _ from 'lodash';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';

import { Box } from 'shared/components/containers';
import { TextField } from 'shared/components/presentational';
import { Button } from 'shared/components/presentational/button';
import { Popover, PopoverProps } from 'shared/components/presentational/popover';
import { Slider } from 'shared/components/presentational/slider';
import { XClose } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

const percentageSliderRange: [number, number] = [0, 100];
const debounceTime = 500; // ms

interface PercentageRangePopoverProps extends PopoverProps {
    range: [number, number];
    onRangeChange: (range: [number, number]) => void;
}

const PercentageRangePopover: FC<PercentageRangePopoverProps> = ({
    range: rangeProp,
    onRangeChange,
    open,
    ...rest
}) => {
    const translate = useTranslations('percentage-range-popover');

    const debouncedOnRangeChange = useMemo(() => debounce(onRangeChange, debounceTime), [onRangeChange]);

    const [range, setRange] = useState(rangeProp);
    const [minimumToShow, setMinimumToShow] = useState(String(range[0]));
    const [maximumToShow, setMaximumToShow] = useState(String(range[1]));

    /**
     * if the range props do not match the range value then clear the filter
     */
    useEffect(() => {
        if (!_.isEqual(rangeProp, range)) handleClearFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangeProp]);

    const handleRangeChange = (newRange: [number, number]) => {
        setRange(newRange);
        debouncedOnRangeChange(newRange);
    };

    const replaceNonNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    };

    const handleMinimumPercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinimumToShow(event.target.value);
        handleRangeChange([clamp(Number(event.target.value), percentageSliderRange[0], range[1]), range[1]]);
    };

    const handleMaximumPercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMaximumToShow(event.target.value);
        handleRangeChange([range[0], clamp(Number(event.target.value), range[0], percentageSliderRange[1])]);
    };

    const handleMinimumPercentBlur = () => {
        setMinimumToShow(String(range[0]));
    };
    const handleMaximumPercentBlur = () => {
        setMaximumToShow(String(range[1]));
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const typedNewValue = newValue as [number, number];
        setMinimumToShow(String(typedNewValue[0]));
        setMaximumToShow(String(typedNewValue[1]));
        handleRangeChange(typedNewValue);
    };

    const handleClearFilter = () => {
        handleRangeChange(percentageSliderRange);
        setMinimumToShow(String(percentageSliderRange[0]));
        setMaximumToShow(String(percentageSliderRange[1]));
    };

    return (
        <Popover
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            css={css`
                margin-top: 8px;
            `}
            {...rest}
        >
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    padding: 28px;
                    width: 337px;
                `}
            >
                <Slider
                    min={percentageSliderRange[0]}
                    max={percentageSliderRange[1]}
                    value={range}
                    onChange={handleSliderChange}
                />
                <Box
                    css={css`
                        margin-top: 8px;
                        display: flex;
                        column-gap: 24px;
                    `}
                >
                    <TextField
                        label={translate('minimum-percent-label')}
                        value={minimumToShow}
                        onChange={handleMinimumPercentChange}
                        onInput={replaceNonNumeric}
                        onBlur={handleMinimumPercentBlur}
                        inputProps={{ inputMode: 'numeric' }}
                    />
                    <TextField
                        label={translate('maximum-percent-label')}
                        value={maximumToShow}
                        onChange={handleMaximumPercentChange}
                        onInput={replaceNonNumeric}
                        onBlur={handleMaximumPercentBlur}
                        inputProps={{ inputMode: 'numeric' }}
                    />
                </Box>
                <Button
                    variant="text"
                    css={css`
                        margin-top: ${spacing.space32px};
                        margin-left: auto;
                        padding: 0;
                        height: auto;
                    `}
                    onClick={handleClearFilter}
                    startIcon={<XClose />}
                    disableRipple
                >
                    {translate('clear-filter-label')}
                </Button>
            </Box>
        </Popover>
    );
};

export { PercentageRangePopover, percentageSliderRange };
