import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Box } from 'shared/components/containers';
import { User01 } from 'shared/components/svgs';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';
import { ChatMessage } from 'sourcing/components/composite';

interface MessageProps {
    message: ChatMessage;
}

const Message: FC<MessageProps> = (props) => {
    const { message } = props;
    const theme = useTheme();

    const translate = useTranslations('sourcing.request-a-sourcer-form.chat-box-specification-section.message-list');

    const icon =
        message.from === 'agent' ? (
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                    background-color: ${colors.greens.narvik};
                    border-radius: 50%;
                `}
            >
                <Image src="/hireflow-logo.png" alt="hireflow logo" width={18} height={18} />
            </Box>
        ) : (
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                    background-color: ${colors.blues.cello};
                    border-radius: 50%;
                `}
            >
                <User01 height={20} width={20} stroke={theme.palette.common.white} />
            </Box>
        );

    const sender = message.from === 'agent' ? translate('agent') : translate('user');

    const { text } = message;

    return (
        <Box
            css={css`
                display: flex;
                justify-content: flex-start;
                gap: 8px;
            `}
        >
            {icon}
            <Box
                css={css`
                display flex;
                flex-flow: row nowrap;
            `}
            >
                <Box
                    css={css`
                        font-weight: 700;
                        font-size: 14px;
                    `}
                >
                    {sender}
                </Box>
                <Box
                    css={css`
                        font-weight: 400;
                        font-size: 12px;
                    `}
                >
                    {text}
                </Box>
            </Box>
        </Box>
    );
};

export { Message };
