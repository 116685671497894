import { config } from 'config';

const baseUri = 'https://login.microsoftonline.com/common/oauth2/v2.0';

function getLoginLink() {
    const { clientId } = config.OutlookOAuthAppConfig;
    const redirectUri = encodeURI(config.OutlookOAuthAppConfig.redirectUri);
    const scope = encodeURIComponent(config.OutlookOAuthAppConfig.scopes.join(' '));
    return (
        `${baseUri}/authorize?` +
        `client_id=${clientId}` +
        `&response_type=code` +
        `&redirect_uri=${redirectUri}` +
        `&response_mode=query` +
        `&scope=${scope}` +
        `&prompt=select_account`
    );
}

export { getLoginLink };
