import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowNarrowLeft: FC<SvgProps> = ({ stroke, theme, width = '18', height = '14', onClick, ...rest }) => (
    <svg
        {...{
            width,
            height,
            viewBox: '0 0 18 14',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
            ...(onClick && { onClick }),
            ...rest,
        }}
    >
        <path
            d="M17 7H1M1 7L7 13M1 7L7 1"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowNarrowLeft = withTheme(ThemedArrowNarrowLeft);

export { ArrowNarrowLeft };
