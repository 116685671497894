import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';

import {
    OrderBy,
    SourcerMemberRejectionReasonsEnum,
    SourcerMembersQuery,
    SourcerMembersQueryVariables,
    SourcerMemberStatusEnum,
    UpdateSourcerMembersMutation,
    UpdateSourcerMembersMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Snackbar } from 'shared/components/presentational';
import { TablePagination } from 'shared/components/table';
import {
    GET_SOURCER_MEMBERS,
    sourcerMembersDefaultVariables,
    UPDATE_SOURCER_MEMBERS,
} from 'shared/graphql/sourcer-members';
import { useQueryRefresh, useSourcerAccessControl } from 'shared/hooks';
import { spacing } from 'shared/settings';
import { FC, SourcerData } from 'shared/types';
import { SourcerMemberCard, SourcerMemberCardEditBar } from 'sourcing/components/composite';
import { reviewIntentToSourcerStatus, sourcerStatusToReviewIntent } from 'sourcing/utils';

// prevents cards that were not updated from being re-rendered
const SourcerMemberCardMemo = React.memo(SourcerMemberCard);

interface SourcerMemberListProps {
    sourcer: SourcerData;
}

export type SourcerMemberCardStatusOptions = Exclude<SourcerMemberStatusEnum, SourcerMemberStatusEnum.ToReview>;

export type SourcerMemberCardReview = {
    status?: SourcerMemberCardStatusOptions;
    rejectionReasons?: SourcerMemberRejectionReasonsEnum[];
    notes?: string;
};

const SourcerMemberList: FC<SourcerMemberListProps> = ({ sourcer }) => {
    const { canManageSourcerMembers } = useSourcerAccessControl();

    const [offset, setOffset] = useState(sourcerMembersDefaultVariables.offset);
    const [reviews, setReviews] = useState(new Map<string, SourcerMemberCardReview>());

    const sourcerMembersPerPage = 10;

    const { data: sourcerMembersData } = useQueryRefresh<SourcerMembersQuery, SourcerMembersQueryVariables>(
        GET_SOURCER_MEMBERS,
        {
            variables: {
                ...sourcerMembersDefaultVariables,
                where: { sourcerId: { _eq: sourcer.id }, status: { _eq: SourcerMemberStatusEnum.ToReview } },
                offset,
                orderBy: { availableAt: OrderBy.Asc, id: OrderBy.Desc },
                limit: sourcerMembersPerPage,
            },
            fetchPolicy: 'network-only',
        }
    );

    const [updateSourcerMembers] = useMutation<UpdateSourcerMembersMutation, UpdateSourcerMembersMutationVariables>(
        UPDATE_SOURCER_MEMBERS
    );

    useEffect(() => {
        if (sourcerMembersData) {
            const reviewMap = new Map<string, SourcerMemberCardReview>();

            for (const sourcerMember of sourcerMembersData.sourcer_members) {
                const { id, reviewIntent, rejectionReasonsIntent, notes } = sourcerMember;

                const review: SourcerMemberCardReview = {
                    status: reviewIntentToSourcerStatus(reviewIntent),
                    rejectionReasons: rejectionReasonsIntent,
                    notes: notes ?? reviews.get(id)?.notes,
                };

                reviewMap.set(id, review);
            }

            setReviews(reviewMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourcerMembersData]);

    const handleReviewIntentChanged = useCallback(
        async (sourcerMemberId: string, status?: SourcerMemberCardStatusOptions) => {
            const reviewIntent = sourcerStatusToReviewIntent(status);
            await updateSourcerMembers({
                variables: {
                    where: { id: { _eq: sourcerMemberId } },
                    set: {
                        reviewIntent,
                    },
                },
            });
        },
        [updateSourcerMembers]
    );

    const handleRejectionReasonIntentChange = useCallback(
        async (sourcerMemberId: string, rejectionReasons?: string[]) => {
            await updateSourcerMembers({
                variables: {
                    where: { id: { _eq: sourcerMemberId } },
                    set: {
                        rejectionReasonsIntent: rejectionReasons,
                    },
                },
            });
        },
        [updateSourcerMembers]
    );

    const handleStatusUpdate = useCallback(
        (sourcerMemberId: string, newStatus: SourcerMemberCardStatusOptions) => {
            setReviews((reviewMap) => {
                const oldReview = reviewMap.get(sourcerMemberId);
                if (oldReview?.status === newStatus) {
                    // if trying to update to the same status, remove the review
                    reviewMap.delete(sourcerMemberId);
                    handleReviewIntentChanged(sourcerMemberId);
                    return new Map(reviewMap);
                }
                handleReviewIntentChanged(sourcerMemberId, newStatus);
                return new Map(reviewMap.set(sourcerMemberId, { status: newStatus }));
            });
        },
        [setReviews, handleReviewIntentChanged]
    );

    const handleNotesChange = useCallback(
        (sourcerMemberId: string, newNotes: string) => {
            setReviews((reviewMap) => {
                const oldReview = reviewMap.get(sourcerMemberId);
                return new Map(reviewMap.set(sourcerMemberId, { ...oldReview, notes: newNotes }));
            });
        },
        [setReviews]
    );

    const handleRejectionReasonsChange = useCallback(
        (sourcerMemberId: string, newRejectionReasons: SourcerMemberRejectionReasonsEnum[]) => {
            setReviews((reviewMap) => {
                const oldReview = reviewMap.get(sourcerMemberId);
                handleRejectionReasonIntentChange(sourcerMemberId, newRejectionReasons);
                return new Map(reviewMap.set(sourcerMemberId, { ...oldReview, rejectionReasons: newRejectionReasons }));
            });
        },
        [setReviews, handleRejectionReasonIntentChange]
    );

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setOffset(newPage * sourcerMembersPerPage);
    };

    if (!sourcerMembersData) return null;

    return (
        <>
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    gap: ${spacing.space24px};
                `}
            >
                {sourcerMembersData.sourcer_members.map((sourcerMember) => (
                    <SourcerMemberCardMemo
                        key={sourcerMember.id}
                        sourcerMember={sourcerMember}
                        onStatusUpdate={handleStatusUpdate}
                        status={reviews.get(sourcerMember.id)?.status}
                        rejectionReasons={reviews.get(sourcerMember.id)?.rejectionReasons}
                        notes={reviews.get(sourcerMember.id)?.notes}
                        onNotesChange={handleNotesChange}
                        onRejectionReasonsChange={handleRejectionReasonsChange}
                        creditsPerApproval={sourcer.creditsPerApproval}
                        showInsights={
                            sourcer.insightsVisibleAt && sourcerMember.availableAt
                                ? sourcer.insightsVisibleAt < sourcerMember.availableAt
                                : false
                        }
                        viewOnly={!canManageSourcerMembers(sourcer)}
                    />
                ))}
                <TablePagination
                    css={css`
                        align-self: flex-end;
                    `}
                    // Issue: https://mui.com/material-ui/guides/typescript/#usage-of-component-prop
                    // @ts-expect-error
                    component="div"
                    count={sourcerMembersData.sourcer_members_aggregate.aggregate?.count ?? 0}
                    page={offset / sourcerMembersPerPage}
                    onPageChange={handlePageChange}
                    rowsPerPage={sourcerMembersPerPage}
                    rowsPerPageOptions={[]}
                />
            </Box>
            {canManageSourcerMembers(sourcer) ? (
                <Snackbar
                    open={reviews.size > 0}
                    autoHideDuration={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    css={css`
                        display: flex;
                        max-width: 960px;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                    `}
                >
                    <SourcerMemberCardEditBar
                        reviews={reviews}
                        sourcerMembers={sourcerMembersData.sourcer_members}
                        sourcer={sourcer}
                    />
                </Snackbar>
            ) : null}
        </>
    );
};

export { SourcerMemberList };
