import { config } from 'config';
import { Session } from 'shared/hooks/use-session';

let isInitialized: boolean = false;

export function registerIntercom(session: Session) {
    if (config.Env === 'production') {
        if ((window as any).Intercom) {
            if (isInitialized) return;
            isInitialized = true;

            const { email, fullName, createdAt } = session.user;

            (window as any).Intercom('boot', {
                // eslint-disable-next-line no-magic-numbers
                created_at: createdAt / 1000,
                email,
                name: fullName,
            });
        }
    }
}

export function showIntercom() {
    if (config.Env === 'production' && (window as any).Intercom) {
        (window as any).Intercom('show');
    }
}

export function signOutIntercom() {
    if (config.Env === 'production') {
        if ((window as any).Intercom) {
            (window as any).Intercom('shutdown');
        }
    }
}
