import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowLeft: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
            stroke={stroke ?? theme?.palette.primary.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowLeft = withTheme(ThemedArrowLeft);

export { ArrowLeft };
