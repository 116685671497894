import { css } from '@emotion/react';

import { ProspectCardLinkedInButton, ProspectCardName } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { IconButton } from 'shared/components/presentational';
import { DotsVertical } from 'shared/components/svgs';
import { FC } from 'shared/types';

interface ProspectCardHeaderProps {
    firstName: string;
    lastName: string;
    fullName: string;
    profileImageUri?: string | null;
    linkedinUrl?: string;
    location?: string | null;
    onSubmenuClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ProspectCardHeader: FC<ProspectCardHeaderProps> = ({
    firstName,
    lastName,
    fullName,
    location,
    profileImageUri,
    linkedinUrl,
    onSubmenuClick,
}) => (
    <Box
        css={css`
            display: flex;
            justify-content: space-between;
        `}
    >
        <ProspectCardName
            firstName={firstName}
            lastName={lastName}
            fullName={fullName}
            location={location}
            profileImageUri={profileImageUri}
        />
        <Box>
            <ProspectCardLinkedInButton linkedinUrl={linkedinUrl} />
            {onSubmenuClick && (
                <IconButton onClick={onSubmenuClick} disableRipple>
                    <DotsVertical />
                </IconButton>
            )}
        </Box>
    </Box>
);

export { ProspectCardHeader };
