import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState, useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import { UsersQuery, UsersQueryVariables } from 'codegen/graphql';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { SelectChip } from 'shared/components/form';
import { Button } from 'shared/components/presentational';
import { Minus, Plus } from 'shared/components/svgs';
import { GET_USERS } from 'shared/graphql/users';
import { useSession } from 'shared/hooks';
import { isEmailValid } from 'shared/services';
import { FC } from 'shared/types';

interface CcBccProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    index: number;
    disabled?: boolean;
    isEdit?: boolean;
}

const CcBcc: FC<CcBccProps> = (props) => {
    const { formMethods, index, disabled = false, isEdit } = props;
    const translate = useTranslations('sequence.create.stages');
    const [isAdditionalEmailsOpen, setIsAdditionalEmailsOpen] = useState(false);

    const { session } = useSession();

    // get all users teammates
    const { data: userData } = useQuery<UsersQuery, UsersQueryVariables>(GET_USERS, {
        variables: { where: { id: { _neq: session?.user.id } } },
    });

    const stages = formMethods.getValues('stages');

    useEffect(() => {
        if (stages[index]?.cc?.length || stages[index]?.bcc?.length) {
            setIsAdditionalEmailsOpen(true);
        }
    }, [stages, index]);

    const ccState = formMethods.getFieldState(`stages.${index}.cc`, formMethods.formState);
    const cc = useWatch({
        control: formMethods.control,
        name: `stages.${index}.cc`,
    });

    useEffect(() => {
        if (ccState.isDirty && cc.length > 0) {
            trackEvent('add_cc', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
            });
        }
    }, [cc, ccState.isDirty, isEdit]);

    const bccState = formMethods.getFieldState(`stages.${index}.bcc`, formMethods.formState);
    const bcc = useWatch({
        control: formMethods.control,
        name: `stages.${index}.bcc`,
    });

    useEffect(() => {
        if (bccState.isDirty && bcc.length > 0) {
            trackEvent('add_bcc', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
            });
        }
    }, [bcc, bccState.isDirty, isEdit]);

    const toggleAdditionalEmails = (): void => {
        setIsAdditionalEmailsOpen(!isAdditionalEmailsOpen);
    };

    const formatCcBccLabel = (inputValue: string) => translate('add-cc-bcc-email', { email: inputValue });

    const options = userData?.users.map((user) => ({ label: user.fullName, value: user.email })) || [];

    return (
        <Box>
            <Box
                css={css`
                    position: absolute;
                    top: 18px;
                    left: 78%;
                `}
            >
                <Button
                    disabled={disabled}
                    variant="text"
                    onClick={toggleAdditionalEmails}
                    startIcon={isAdditionalEmailsOpen ? <Minus /> : <Plus />}
                    css={css`
                        opacity: ${disabled ? '0.5' : '1'};
                    `}
                >
                    {translate(isAdditionalEmailsOpen ? 'remove-cc-bcc' : 'add-cc-bcc')}
                </Button>
            </Box>

            <Box
                css={css`
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.2s ease-in-out, margin-top 0.2s ease-in-out;
                    position: relative;

                    ${isAdditionalEmailsOpen &&
                    `
                        margin-top: 8px;
                        max-height: 500px;
                        overflow: visible;
                    `}
                `}
            >
                <SelectChip
                    isCreatable
                    isDisabled={disabled}
                    options={options}
                    name={`stages.${index}.cc`}
                    placeholder="cc"
                    formMethods={formMethods}
                    formatCreateLabel={formatCcBccLabel}
                    isValidNewOption={isEmailValid}
                    css={css`
                        z-index: 101;
                        width: 580px;
                    `}
                />
                <SelectChip
                    isCreatable
                    isDisabled={disabled}
                    options={options}
                    name={`stages.${index}.bcc`}
                    placeholder="bcc"
                    formMethods={formMethods}
                    isValidNewOption={isEmailValid}
                    css={css`
                        z-index: 100;
                        width: 580px;
                    `}
                />
            </Box>
        </Box>
    );
};

export { CcBcc };
