import { useMutation, useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';

import { trackEvent } from 'analytics';
import {
    EmailAccountProviderEnum,
    InsertTestEmailMessagesMutation,
    SendFromAccountsByIdQuery,
    SendFromAccountsByIdQueryVariables,
    SignatureOptionsEnum,
} from 'codegen/graphql';
import { signatureOptions } from 'sequences/settings';
import { CreateEditSequenceFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { FormControlLabel, Select } from 'shared/components/form';
import { Button, Checkbox, Hyperlink, IconButton } from 'shared/components/presentational';
import { ArrowUpRight } from 'shared/components/svgs';
import { windowFeatures, windowTarget } from 'shared/constants';
import { GET_SEND_FROM_ACCOUNTS_BY_ID } from 'shared/graphql/send-from-accounts';
import { INSERT_TEST_EMAIL_MESSAGES } from 'shared/graphql/sequences';
import { useSendFromEmail, useSession, useSettings, useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EditorFooterProps {
    formMethods: UseFormReturn<CreateEditSequenceFormValues>;
    index: number;
    disabled: boolean;
    signatureHtml: string;
    isEdit?: boolean;
}

const EditorFooter: FC<EditorFooterProps> = (props) => {
    const { className, formMethods, index, disabled, signatureHtml, isEdit } = props;
    const { session } = useSession();
    const translate = useTranslations('sequence.create.stages');
    const theme = useTheme();
    const [sendTestEmail] = useMutation<InsertTestEmailMessagesMutation>(INSERT_TEST_EMAIL_MESSAGES);
    const { showSnackbarAlert } = useSnackbarAlert();
    const { openHireflowSignature } = useSettings();
    const { sendFromEmail } = useSendFromEmail();
    const [signatureChanged, setSignatureChanged] = useState<boolean>(false);
    const [useSignatureChanged, setUseSignatureChanged] = useState<boolean>(false);
    const [isOutlookAccount, setIsOutlookAccount] = useState<boolean>(false);

    const signatureState = formMethods.getFieldState(`stages.${index}.signature`, formMethods.formState);

    const useSignatureState = formMethods.getFieldState(`stages.${index}.useSignature`, formMethods.formState);

    const [getSendFromAccountDetails, { data: sendFromAccountData }] = useLazyQuery<
        SendFromAccountsByIdQuery,
        SendFromAccountsByIdQueryVariables
    >(GET_SEND_FROM_ACCOUNTS_BY_ID);

    const selectedSignatureType = useWatch({
        control: formMethods.control,
        name: `stages.${index}.signature`,
    });

    const useSignature = useWatch({
        control: formMethods.control,
        name: `stages.${index}.useSignature`,
    });

    const selectedSendFromAccountId = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendFromAccountId`,
    });

    useEffect(() => {
        if (selectedSendFromAccountId) {
            getSendFromAccountDetails({
                variables: {
                    id: selectedSendFromAccountId,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSendFromAccountId]);

    useEffect(() => {
        if (sendFromAccountData) {
            const emailProvider = sendFromAccountData.send_from_accounts_by_pk?.email_account.provider;
            if (emailProvider === EmailAccountProviderEnum.Outlook) {
                setIsOutlookAccount(true);
                formMethods.setValue(`stages.${index}.signature`, SignatureOptionsEnum.HireflowSettings, {
                    shouldDirty: true,
                });
            } else {
                setIsOutlookAccount(false);
            }
        } else {
            setIsOutlookAccount(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendFromAccountData]);

    useEffect(() => {
        if (signatureState.isDirty) {
            trackEvent('select_signature', {
                page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                value: selectedSignatureType === SignatureOptionsEnum.ImportFromGmail ? 'gmail' : 'hireflow',
            });
            setSignatureChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSignatureType, signatureState.isDirty]);

    useEffect(() => {
        if (signatureChanged) {
            if (index === 0) {
                const newStages = formMethods.getValues('stages').map((s, i) => {
                    if (i > 0) {
                        return {
                            ...s,
                            signature: selectedSignatureType,
                        };
                    }
                    return s;
                });
                formMethods.setValue('stages', newStages);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureChanged, selectedSignatureType]);

    useEffect(() => {
        if (useSignatureState.isDirty) {
            setUseSignatureChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useSignature, useSignatureState.isDirty]);

    useEffect(() => {
        if (useSignatureChanged) {
            if (index === 0) {
                const newStages = formMethods.getValues('stages').map((s, i) => {
                    if (i > 0) {
                        return {
                            ...s,
                            useSignature,
                        };
                    }
                    return s;
                });
                formMethods.setValue('stages', newStages);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useSignatureChanged, useSignature]);

    const handleSendEmail = async () => {
        const stages = formMethods.getValues('stages');

        let body = stages[index]?.content;
        if (stages[index]?.useSignature) {
            body += signatureHtml;
        }

        const res = await sendTestEmail({
            variables: {
                // eslint-disable-next-line no-magic-numbers
                sendAt: Math.floor(new Date().getTime() / 1000),
                sendFromAccountEmail: sendFromEmail,
                template: { subject: stages[index]?.subject, body },
            },
        });

        if (res.data?.insert_test_email_messages?.affected_rows) {
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-email-sent-to', {
                    recipient: session?.user.email,
                }),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-email-sent'),
            });
        }

        trackEvent('click_send_test_email', {
            page_name: isEdit ? 'sequence_details' : 'sequence_creation',
        });
    };

    const openGmailSignatureEditPage = (): void => {
        const url: string = `https://mail.google.com/?shxr=0&authuser=${sendFromEmail}#settings/general`;
        window.open(url, windowTarget, windowFeatures);
    };

    const handleEditClick = () => {
        openHireflowSignature();
    };

    const handleArrowClick = (): void => openGmailSignatureEditPage();

    return (
        <Box
            className={className}
            css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                background-color: ${theme.palette.grey[300]};
                border-top: 1px solid ${theme.palette.grey[100]};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                {isOutlookAccount ? (
                    <FormControlLabel
                        label={translate('use-signature-from-hireflow-settings')}
                        control={
                            <Checkbox
                                disabled={disabled}
                                formMethods={formMethods}
                                name={`stages.${index}.useSignature`}
                            />
                        }
                        css={css`
                            .MuiFormControlLabel-label {
                                font-size: ${fontSizes.f16};
                                margin-left: 8px;
                            }
                        `}
                    />
                ) : (
                    <>
                        <FormControlLabel
                            label={translate('use-signature-from')}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    formMethods={formMethods}
                                    name={`stages.${index}.useSignature`}
                                />
                            }
                            css={css`
                                .MuiFormControlLabel-label {
                                    font-size: ${fontSizes.f16};
                                    margin-left: 8px;
                                }
                            `}
                        />
                        <Select
                            isDefault
                            disabled={disabled || sendFromEmail === ''}
                            options={signatureOptions()}
                            formMethods={formMethods}
                            name={`stages.${index}.signature`}
                            value={selectedSignatureType || SignatureOptionsEnum.ImportFromGmail}
                            css={css`
                                .MuiSelect-select {
                                    background-color: ${theme.palette.common.white};
                                    margin-top: 0;
                                }
                            `}
                            width="212px"
                        />
                    </>
                )}
                {/* don't show this option if the disabled prop has been set */}
                {!disabled && selectedSignatureType === SignatureOptionsEnum.HireflowSettings ? (
                    <Hyperlink
                        css={css`
                            margin-left: ${spacing.space16px};
                        `}
                        href="#"
                        onClick={handleEditClick}
                    >
                        {translate('edit')}
                    </Hyperlink>
                ) : null}
                {selectedSignatureType === SignatureOptionsEnum.ImportFromGmail ? (
                    <IconButton
                        css={css`
                            margin: auto 10px;
                            path {
                                stroke: ${theme.palette.primary.light};
                            }
                            &:hover path {
                                opacity: 0.5;
                            }
                        `}
                        onClick={handleArrowClick}
                        disableRipple
                    >
                        <ArrowUpRight />
                    </IconButton>
                ) : null}
            </Box>
            <Button variant="text" onClick={handleSendEmail} disabled={disabled || !sendFromEmail}>
                {translate('send-me-a-test-email')}
            </Button>
        </Box>
    );
};

const StyledEditorFooter = styled(EditorFooter)``;

export { StyledEditorFooter as EditorFooter };
