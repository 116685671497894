import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackLoadPage, trackLoadTab } from 'analytics';
import { SequenceStatusEnum, SequencesByStatusQuery, SequencesByStatusQueryVariables } from 'codegen/graphql';
import { CreateNewSequenceButton, SequenceListTopSubmenu } from 'sequences/components/presentational';
import { SequenceTab } from 'sequences/types';
import { ActionButtons } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { IconButton, TabWithCount, Tabs, TabsContainer } from 'shared/components/presentational';
import { DotsVertical } from 'shared/components/svgs';
import { GET_SEQUENCES_COUNT_BY_STATUS } from 'shared/graphql/sequences';
import { useSession } from 'shared/hooks';
import { FC } from 'shared/types';

interface SequenceListMenuProps {}

const SequenceListMenu: FC<SequenceListMenuProps> = () => {
    const theme = useTheme();

    useEffect(() => {
        trackLoadPage('sequence_parent');
    }, []);

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    return (
        <>
            <IconButton
                css={css`
                    margin-right: 16px;
                    &:hover path {
                        stroke: ${theme.palette.primary.light};
                    }
                `}
                onClick={handleSubmenuButtonClick}
                disableRipple
            >
                <DotsVertical />
            </IconButton>

            <SequenceListTopSubmenu anchor={submenuAnchor} setAnchor={setSubmenuAnchor} />
        </>
    );
};

interface SequenceListTabsProps {
    selectedTab: SequenceTab;
    onTabChange: (_: any, newValue: string) => void;
}

const SequenceListTabs: FC<SequenceListTabsProps> = ({ selectedTab, onTabChange }) => {
    const translate = useTranslations('sequence');
    const { session, loaded } = useSession();

    const { data } = useQuery<SequencesByStatusQuery, SequencesByStatusQueryVariables>(GET_SEQUENCES_COUNT_BY_STATUS, {
        skip: !loaded,
        variables: { userId: session?.user.id },
        fetchPolicy: 'no-cache',
    });

    const activeCount = data ? data.active.aggregate!.count : 0;
    const emailDisconnectedCount = data ? data.emailDisconnected.aggregate!.count : 0;
    const readyCount = data ? data.ready.aggregate!.count : 0;
    const draftCount = data ? data.draft.aggregate!.count : 0;
    const pausedCount = data ? data.paused.aggregate!.count : 0;
    const archivedCount = data ? data.archived.aggregate!.count : 0;
    const allCount = activeCount + emailDisconnectedCount + readyCount + draftCount + pausedCount + archivedCount;

    useEffect(() => {
        trackLoadTab(
            selectedTab === SequenceStatusEnum.Active
                ? 'sequence_parent_active_tab'
                : selectedTab === SequenceStatusEnum.Draft
                ? 'sequence_parent_draft_tab'
                : selectedTab === SequenceStatusEnum.Paused
                ? 'sequence_parent_paused_tab'
                : selectedTab === SequenceStatusEnum.Archived
                ? 'sequence_parent_archived_tab'
                : 'sequence_parent_all_tab'
        );
    }, [selectedTab]);

    return (
        <TabsContainer>
            <Tabs value={selectedTab} onChange={onTabChange} textColor="primary" indicatorColor="primary">
                <TabWithCount
                    value={SequenceStatusEnum.Active}
                    active={selectedTab === SequenceStatusEnum.Active}
                    label={translate('active')}
                    count={activeCount + emailDisconnectedCount + readyCount + draftCount}
                />
                <TabWithCount
                    value={SequenceStatusEnum.Draft}
                    active={selectedTab === SequenceStatusEnum.Draft}
                    label={translate('drafts')}
                    count={draftCount}
                />
                <TabWithCount
                    value={SequenceStatusEnum.Paused}
                    active={selectedTab === SequenceStatusEnum.Paused}
                    label={translate('paused')}
                    count={pausedCount}
                />
                <TabWithCount
                    value={SequenceStatusEnum.Archived}
                    active={selectedTab === SequenceStatusEnum.Archived}
                    label={translate('archived')}
                    count={archivedCount}
                />
                <TabWithCount value="all" active={selectedTab === 'all'} label={translate('all')} count={allCount} />
            </Tabs>

            <ActionButtons
                rightActionButtons={
                    <Box
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <CreateNewSequenceButton />
                        <SequenceListMenu />
                    </Box>
                }
            />
        </TabsContainer>
    );
};

export { SequenceListTabs };
