import styled from '@emotion/styled';

import { Box } from 'shared/components/containers';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RadioInputLabelProps {
    title: string;
    description: string;
}

const RadioInputLabel: FC<RadioInputLabelProps> = ({ title, description }) => (
    <Box>
        <Box className="radio-input-label-title">{title}</Box>
        <Box className="radio-input-label-description">{description}</Box>
    </Box>
);

const StyledRadioInputLabel = styled(RadioInputLabel)`
    .radio-input-description {
        font-size: ${spacing.space12px};
        color: ${({ theme }) => `color:${theme.palette.grey[200]}`}
`;

export { StyledRadioInputLabel as RadioInputLabel };
