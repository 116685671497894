import { css } from '@emotion/react';
import Image from 'next/image';

import { ErrorTemplate } from 'errors/components/templates/error-template';
import { FC } from 'shared/types';

interface Template500Props {}

const Template500: FC<Template500Props> = () => (
    <ErrorTemplate errorCode="500">
        <Image
            css={css`
                border-radius: 50% 0% 50% 50%;

                object-fit: cover;
                object-position: left bottom;
            `}
            src="/500.gif"
            alt="500"
            width={296.5}
            height={298}
        />
    </ErrorTemplate>
);

export { Template500 };
