import { gql } from '@apollo/client';

import { OrderBy, ProjectMembersOrderBy, ProjectMembersQuery } from 'codegen/graphql';

export type ProjectMembersData = ProjectMembersQuery['project_members'][number];
export const projectMembersDefaultOrderBy: keyof ProjectMembersOrderBy = 'createdAt';
export const projectMembersDefaultOrderByDirection = OrderBy.Desc;

export const projectMembersDefaultVariables = {
    orderBy: { [projectMembersDefaultOrderBy]: projectMembersDefaultOrderByDirection },
    limit: 25,
    offset: 0,
    where: {},
};

export const PROJECT_MEMBER_DETAILS = gql`
    fragment ProjectMemberDetails on project_members {
        prospectId
        user {
            id
            fullName
        }
        createdAt
        modifiedAt
        addedBy
        project {
            id
            title
            description
            status
            userId
            collaborators {
                id
                userId
            }
        }
    }
`;

/**
 * get the aggregate count for each sequence stage given the prospect for given project members for a given project id
 */
export const GET_PROJECT_MEMBERS_BY_PROJECT_SEQUENCE_STAGES_COUNT = gql`
    query ProjectMembersByProjectSequenceStagesCount($projectId: String!) {
        projects_by_pk(id: $projectId) {
            project_members {
                prospect {
                    recipients(where: { recipient_projects: { projectId: { _eq: $projectId } } }) {
                        sequence {
                            stages_aggregate {
                                aggregate {
                                    count
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PROJECT_MEMBERS_BY_PROJECT = gql`
    query ProjectMembersByProject(
        $projectId: String!
        $orderBy: project_members_order_by!
        $limit: Int!
        $offset: Int!
        $where: project_members_bool_exp!
    ) {
        project_members_aggregate(where: { projectId: { _eq: $projectId } }) {
            aggregate {
                count
            }
        }

        projects_by_pk(id: $projectId) {
            id
            project_members_aggregate(where: $where) {
                aggregate {
                    count
                }
            }
            project_members(where: $where, order_by: [$orderBy], limit: $limit, offset: $offset) {
                id
                status
                prospectId
                projectId
                createdAt
                modifiedAt
                addedBy: user {
                    id
                    firstName
                    lastName
                    fullName
                }
                project {
                    id
                    userId
                    collaborators {
                        id
                        userId
                        type
                    }
                }
                prospect {
                    id
                    addedBy
                    inMailSent
                    profile {
                        id
                        firstName
                        lastName
                        fullName
                        latestCompany
                        latestSchool
                        title
                        notes
                        firstNameCustomized
                        lastNameCustomized
                        fullNameCustomized
                        latestCompanyCustomized
                        latestSchoolCustomized
                        titleCustomized
                        location
                    }
                    urls {
                        url
                    }
                    contacts {
                        invalid
                        kind
                        channel
                        primary
                        value
                    }
                    recipients(
                        where: { recipient_projects: { projectId: { _eq: $projectId } } }
                        order_by: { createdAt: desc }
                    ) {
                        id
                        createdAt
                        status
                        lastStageSent
                        nextMessageScheduledAt
                        addedBy
                        user {
                            id
                        }
                        sequence {
                            id
                            title
                        }
                        prospect {
                            id
                            profile {
                                fullName
                            }
                            contacts {
                                id
                                kind
                                value
                                standardizedValue
                                channel
                                primary
                                invalid
                            }
                        }
                        recipientResponses: recipient_responses(order_by: { createdAt: desc }) {
                            sentiment
                            createdAt
                        }
                        contacts {
                            id
                            recipientId
                            contactId
                            channel
                            createdAt
                            modifiedAt
                        }
                    }
                    sourcer_members {
                        id
                    }
                }
            }
        }
    }
`;

export const GET_PROJECT_MEMBERS_PROSPECT_IDS = gql`
    query ProjectMembersProspectIds(
        $orderBy: project_members_order_by! = { createdAt: desc }
        $limit: Int
        $offset: Int! = 0
        $where: project_members_bool_exp! = {}
    ) {
        project_members(where: $where, order_by: [$orderBy], limit: $limit, offset: $offset) {
            id
            prospectId
        }
    }
`;

export const GET_PROJECT_MEMBERS_AND_PROSPECTS = gql`
    query ProjectMembersAndProspects($where: project_members_bool_exp! = {}) {
        project_members(where: $where) {
            id
            addedBy: user {
                id
                firstName
                lastName
                fullName
            }
            prospect {
                id
                urls {
                    url
                }
            }
        }
    }
`;

export const GET_PROJECT_MEMBERS = gql`
    query ProjectMembers(
        $orderBy: project_members_order_by!
        $limit: Int
        $offset: Int = 0
        $where: project_members_bool_exp!
    ) {
        project_members_aggregate(where: $where) {
            aggregate {
                count
            }
        }

        project_members(where: $where, order_by: [$orderBy], limit: $limit, offset: $offset) {
            id
            prospectId
            projectId
            createdAt
            prospect {
                id
                profile {
                    id
                    firstName
                    lastName
                    fullName
                    latestCompany
                    title
                    latestSchool
                    firstNameCustomized
                    lastNameCustomized
                    fullNameCustomized
                    latestCompanyCustomized
                    latestSchoolCustomized
                    titleCustomized
                    education(order_by: [{ endDateYear: desc_nulls_last }]) {
                        id
                        degree
                        fieldOfStudy
                        startDateYear
                        endDateYear
                        schoolName
                    }
                    experience(
                        order_by: [
                            { current: desc_nulls_last }
                            { endDateYear: desc_nulls_last }
                            { endDateMonth: desc_nulls_last }
                        ]
                    ) {
                        id
                        companyName
                        title
                        startDateMonth
                        startDateYear
                        endDateMonth
                        endDateYear
                        current
                        companyLogo
                    }
                    location
                }
                urls {
                    url
                }
                contacts {
                    channel
                    primary
                    value
                }
                recipients {
                    id
                    createdAt
                    status
                    lastStageSent
                    nextMessageScheduledAt
                    sequence {
                        id
                        title
                    }
                    prospect {
                        id
                        profile {
                            fullName
                        }
                    }
                    recipientResponses: recipient_responses(order_by: { createdAt: desc }) {
                        sentiment
                        createdAt
                    }
                }
            }
        }
    }
`;

export const GET_PROJECT_MEMBERS_COUNT = gql`
    query ProjectMembersCount($where: project_members_bool_exp = {}) {
        project_members_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_PROJECT_MEMBERS_CONTRIBUTORS_SEQUENCES = gql`
    query ProjectMembersContributorsSequences($projectId: String!) {
        projects_by_pk(id: $projectId) {
            project_members(where: { projectId: { _eq: $projectId } }) {
                addedBy: user {
                    id
                    firstName
                    lastName
                    fullName
                }
                prospect {
                    recipients(where: { recipient_projects: { projectId: { _eq: $projectId } } }) {
                        sequence {
                            id
                            title
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_PROJECT_MEMBERS = gql`
    mutation CreateProjectMembers($projectMembers: [project_members_insert_input!] = {}) {
        insert_project_members(
            objects: $projectMembers
            on_conflict: { constraint: project_members_prospectId_projectId_key, update_columns: [] }
        ) {
            affected_rows
            returning {
                id
                projectId
                prospectId
            }
        }
    }
`;

export const DELETE_PROJECT_MEMBERS = gql`
    mutation DeleteProjectMembers($where: project_members_bool_exp!) {
        delete_project_members(where: $where) {
            affected_rows
        }
    }
`;

export const UPDATE_PROJECT_MEMBERS = gql`
    mutation UpdateProjectMembers($where: project_members_bool_exp!, $set: project_members_set_input!) {
        update_project_members(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`;

export const MOVE_PROJECT_MEMBERS = gql`
    mutation MoveProjectMembers(
        $projectMembersToMove: [String!]! = []
        $projectMembersToDelete: [String!]! = []
        $targetProject: String!
    ) {
        update_project_members(where: { id: { _in: $projectMembersToMove } }, _set: { projectId: $targetProject }) {
            returning {
                id
            }
        }
        delete_project_members(where: { id: { _in: $projectMembersToDelete } }) {
            affected_rows
        }
    }
`;

export const REVIEW_PROJECT_MEMBERS = gql`
    mutation ReviewProjectMembers($approvedMembersIds: [String!] = [], $rejectedMembersIds: [String!] = []) {
        approved: update_project_members(where: { id: { _in: $approvedMembersIds } }, _set: { status: accepted }) {
            count: affected_rows
        }
        rejected: update_project_members(where: { id: { _in: $rejectedMembersIds } }, _set: { status: rejected }) {
            count: affected_rows
        }
    }
`;
