import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import {
    GetSequenceCollaboratorsQuery,
    GetSequenceCollaboratorsQueryVariables,
    OrderBy,
    RecipientStatusEnum,
    RecipientsCountQuery,
    RecipientsCountQueryVariables,
    SendFromAccountStatusEnum,
    SendFromAccountsQuery,
    SendFromAccountsQueryVariables,
    SequenceStatusEnum,
    SignatureOptionsEnum,
} from 'codegen/graphql';
import { CcBcc, SendFromSelect, SendTime, Subject } from 'sequences/components/form';
import { PersonalizeOptions } from 'sequences/components/presentational';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box, Container } from 'shared/components/containers';
import { Editor } from 'shared/components/editor';
import { Switch } from 'shared/components/form';
import { Button, Title } from 'shared/components/presentational';
import { Minus } from 'shared/components/svgs';
import { GET_RECIPIENTS_COUNT } from 'shared/graphql/recipients';
import { GET_SEND_FROM_ACCOUNTS } from 'shared/graphql/send-from-accounts';
import { GET_SEQUENCE_COLLABORATORS } from 'shared/graphql/sequence-collaborators';
import {
    SendFromEmailContextProvider,
    useConfirmationModal,
    useMagicSentence,
    useSequenceStageOperations,
    useSession,
} from 'shared/hooks';
import { createRestApiClient } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const restApiClient = createRestApiClient();

const emptySignature = '';

interface ComposeEmailProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    index: number;
    readOnly?: boolean;
    isEdit?: boolean;
    sequenceStatus?: SequenceStatusEnum;
    sequenceId?: string;
}

const ComposeEmail: FC<ComposeEmailProps> = (props) => {
    const { formMethods, index, readOnly = false, isEdit, sequenceId, sequenceStatus } = props;

    const [selectedVariable, setSelectedVariable] = useState<string>('');
    const [signatureHtml, setSignatureHtml] = useState<string>('');
    const translate = useTranslations('sequence.create.stages');

    const { showModal, hideModal } = useConfirmationModal();
    const { magicSentenceUsed, checkMagicSentenceUsed } = useMagicSentence();
    const { handleRemoveStage } = useSequenceStageOperations();

    const [isSubjectTouched, setIsSubjectTouched] = useState<boolean>(false);
    const [isEditorTouched, setIsEditorTouched] = useState<boolean>(false);
    const [isSubjectLastTouched, setIsSubjectLastTouched] = useState<boolean>(false);
    const [sendFromEmail, setSendFromEmail] = useState<string>('');
    const theme = useTheme();
    const { session } = useSession();

    const {
        data: sendFromAccountsData,
        loading: sendFromAccountsLoading,
        refetch: refetchSendFromAccountsData,
    } = useQuery<SendFromAccountsQuery, SendFromAccountsQueryVariables>(GET_SEND_FROM_ACCOUNTS, {
        variables: {
            where: readOnly
                ? {
                      status: {
                          _eq: SendFromAccountStatusEnum.Approved,
                      },
                      id: {
                          _eq: formMethods.getValues(`stages.${index}.sendFromAccountId`),
                      },
                  }
                : {
                      status: {
                          _eq: SendFromAccountStatusEnum.Approved,
                      },
                      userId: {
                          _eq: session?.user.id,
                      },
                  },
        },
    });

    const { data: sequenceCollaboratorsData, loading: sequenceCollaboratorsLoading } = useQuery<
        GetSequenceCollaboratorsQuery,
        GetSequenceCollaboratorsQueryVariables
    >(GET_SEQUENCE_COLLABORATORS, {
        variables: {
            where: {
                sequenceId: {
                    _eq: sequenceId,
                },
            },
            orderBy: {
                createdAt: OrderBy.Desc,
            },
        },
        skip: !sequenceId || !isEdit || readOnly,
    });

    const { data: inProgressRecipientsCountData } = useQuery<RecipientsCountQuery, RecipientsCountQueryVariables>(
        GET_RECIPIENTS_COUNT,
        {
            variables: {
                where: {
                    sequenceId: {
                        _eq: sequenceId,
                    },
                    status: {
                        _eq: RecipientStatusEnum.InProgress,
                    },
                },
            },
            skip: !sequenceId || !isEdit || readOnly,
        }
    );

    const handleSelectedVariableClick = (variable: string) => {
        setSelectedVariable(variable);
        trackEvent('select_variables', {
            page_name: isEdit ? 'sequence_details' : 'sequence_creation',
            value: variable,
        });
    };

    const handleClearVariable = () => {
        setSelectedVariable('');
    };

    const handleIsEditorTouched = (touched: boolean) => {
        setIsEditorTouched(touched);
    };

    const handleIsSubjectTouched = (touched: boolean) => {
        setIsSubjectTouched(touched);
    };

    const handleRemoveStageClick = (selectedStageIndex: number) => {
        showModal({
            title: translate('are-you-sure-you-want-to-remove-this-stage', {
                stageNumber: selectedStageIndex + 1,
            }),
            confirmButton: {
                color: 'error',
                text: translate('remove-and-delete-this-stage'),
                onClick: () => {
                    handleRemoveStage(selectedStageIndex);
                    hideModal();
                    trackEvent('click_remove_stage', {
                        page_name: isEdit ? 'sequence_details' : 'sequence_creation',
                    });
                },
            },
            cancelButton: {
                text: translate('cancel'),
                onClick: hideModal,
            },
        });
    };

    const previousStageAccountId = useWatch({
        control: formMethods.control,
        name: `stages.${index - 1}.sendFromAccountId`,
        disabled: index === 0,
        defaultValue: '',
    });

    const currentStageAccountId = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendFromAccountId`,
        defaultValue: '',
    });

    const selectedSignatureType = useWatch({
        control: formMethods.control,
        name: `stages.${index}.signature`,
        defaultValue: '',
    });

    const useSignatureForStage = useWatch({
        control: formMethods.control,
        name: `stages.${index}.useSignature`,
        defaultValue: false,
    });

    const selectedAccountId = useWatch({
        control: formMethods.control,
        name: `stages.${index}.sendFromAccountId`,
        defaultValue: '',
    });

    const isUserOwnedAccount =
        sendFromAccountsData?.send_from_accounts.find((account) => account.id === selectedAccountId)?.email_account.user
            .id === session?.user.id;

    const allowSendAsReply: boolean = index >= 1 && previousStageAccountId === currentStageAccountId;

    // show a disclaimer text if the selected sobo account does not belong to a sequence collaborator or to the user
    const showNewCollaboratorDisclaimer =
        !sendFromAccountsLoading &&
        !sequenceCollaboratorsLoading &&
        !readOnly &&
        !isUserOwnedAccount &&
        !sequenceCollaboratorsData?.sequence_collaborators.some(
            (c) =>
                c.userId ===
                sendFromAccountsData?.send_from_accounts.find((a) => a.id === selectedAccountId)?.email_account.user.id
        );

    // set email for context provider if nothing has been selected
    // this ensures the context provider value is correct when the sequence is being edited,
    // in which case the user might not select an email from the send-from dropdown
    useEffect(() => {
        setSendFromEmail(
            sendFromAccountsData?.send_from_accounts.find((a) => a.id === selectedAccountId)?.email ??
                sendFromAccountsData?.send_from_accounts[0]?.email ??
                ''
        );
    }, [selectedAccountId, sendFromAccountsData, setSendFromEmail]);

    useEffect(() => {
        if (!allowSendAsReply) {
            formMethods.setValue(`stages.${index}.replyToLastEmail`, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowSendAsReply, index]);

    const handleOnEditorKeyUp = () => {
        checkMagicSentenceUsed();
    };

    useEffect(() => {
        checkMagicSentenceUsed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVariable]);

    useEffect(() => {
        if (isSubjectTouched) setIsSubjectLastTouched(true);
        else if (isEditorTouched) setIsSubjectLastTouched(false);
    }, [isSubjectTouched, isEditorTouched]);

    useEffect(() => {
        if (useSignatureForStage && sendFromEmail) setSignatureForPreview();
        else setSignatureHtml('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useSignatureForStage, selectedSignatureType, sendFromEmail]);

    const setSignatureForPreview = async (): Promise<void> => {
        const sendFromAccount = sendFromAccountsData?.send_from_accounts.find(
            (account) => account.email === sendFromEmail
        );

        if (!sendFromAccount) {
            setSignatureHtml(emptySignature);
            return;
        }

        switch (selectedSignatureType) {
            case SignatureOptionsEnum.HireflowSettings: {
                setSignatureHtml(sendFromAccount?.email_account.user.hireflowSignature || emptySignature);
                break;
            }
            case SignatureOptionsEnum.ImportFromGmail: {
                const response = await restApiClient.getGmailSignature({ email: sendFromAccount?.email || '' });
                setSignatureHtml(response?.data?.signature || emptySignature);
                break;
            }
            default: {
                setSignatureHtml(session!.user.hireflowSignature || emptySignature);
                break;
            }
        }
    };

    const isNotFirstStage = index !== 0;

    const sequenceHasNotBeenSent =
        sequenceStatus && [SequenceStatusEnum.Draft, SequenceStatusEnum.Ready].includes(sequenceStatus);

    const showRemoveStageButton = (isEdit && isNotFirstStage && sequenceHasNotBeenSent) || (!isEdit && isNotFirstStage);

    const numberOfInProgressRecipients = inProgressRecipientsCountData?.recipients_aggregate.aggregate?.count || 0;
    const sequenceHasInProgressRecipients = numberOfInProgressRecipients !== 0;

    const handleEmailConnected = async (email: string) => {
        if (isEdit) return;

        const updateSendFromAccount = await refetchSendFromAccountsData();
        const sendFromAccount = updateSendFromAccount.data?.send_from_accounts.find((a) => a.email === email);

        if (!sendFromAccount) return;

        const updatedStages = formMethods.getValues('stages').map((stage) => ({
            ...stage,
            sendFromAccountId: sendFromAccount.id,
        }));

        formMethods.setValue('stages', updatedStages);

        // trigger the validations again
        formMethods.trigger('stages');
    };

    return (
        <SendFromEmailContextProvider sendFromEmail={sendFromEmail} setSendFromEmail={setSendFromEmail}>
            <Box
                css={css`
                    margin-bottom: ${spacing.space48px};
                    position: relative;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    <Title
                        type="h2"
                        css={css`
                            min-width: 72px;

                            font-size: ${fontSizes.f32};
                        `}
                    >
                        {translate('stage')} {index + 1}
                    </Title>
                    {showRemoveStageButton && (
                        <Button
                            disabled={readOnly}
                            variant="text"
                            color="error"
                            startIcon={<Minus stroke={theme.palette.error.main} />}
                            onClick={() => handleRemoveStageClick(index)}
                            css={css`
                                padding-right: ${spacing.none};
                            `}
                        >
                            {translate('remove-this-stage')}
                        </Button>
                    )}
                </Box>

                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: ${spacing.space16px};
                        max-width: 960px;
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            margin-top: ${spacing.space16px};
                            min-width: 72px;

                            font-size: ${fontSizes.f20};
                        `}
                    >
                        {translate('who')}
                    </Title>
                    <Box
                        css={css`
                            max-width: 852px;
                            width: 100%;
                        `}
                    >
                        <Container
                            css={css`
                                padding: 16px 24px;
                                position: relative;
                            `}
                        >
                            <SendFromSelect
                                showTooltip={sequenceHasInProgressRecipients}
                                disabled={readOnly || sequenceHasInProgressRecipients}
                                formMethods={formMethods}
                                index={index}
                                isEdit={isEdit}
                                sendFromAccounts={sendFromAccountsData?.send_from_accounts || []}
                                showNewCollaboratorDisclaimer={showNewCollaboratorDisclaimer}
                                onNewEmailAdd={handleEmailConnected}
                            />
                            <CcBcc disabled={readOnly} formMethods={formMethods} index={index} isEdit={isEdit} />
                        </Container>
                    </Box>
                </Box>

                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            margin-top: ${spacing.space16px};
                            min-width: 72px;

                            font-size: ${fontSizes.f20};
                        `}
                    >
                        {translate('when')}
                    </Title>

                    <Box
                        css={css`
                            max-width: 852px;
                            width: 100%;
                        `}
                    >
                        <Container
                            css={css`
                                padding: 16px 24px;
                            `}
                        >
                            <SendTime
                                disabled={readOnly}
                                formMethods={formMethods}
                                index={index}
                                isEdit={isEdit}
                                isUserOwnedAccount={isUserOwnedAccount}
                            />
                        </Container>
                    </Box>
                </Box>

                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    <Title
                        type="h4"
                        css={css`
                            margin-top: ${spacing.space16px};
                            min-width: 72px;

                            font-size: ${fontSizes.f20};
                        `}
                    >
                        {translate('email')}
                    </Title>
                    <Box
                        css={css`
                            max-width: 852px;
                            width: 100%;
                        `}
                    >
                        <Container
                            css={css`
                                padding: 16px 24px;
                            `}
                        >
                            <PersonalizeOptions
                                disabled={readOnly}
                                onClick={handleSelectedVariableClick}
                                showMagicSentence={!magicSentenceUsed && !isSubjectLastTouched}
                            />
                            <Box
                                css={css`
                                    display: flex;
                                    margin-bottom: ${spacing.space16px};
                                `}
                            >
                                <Subject
                                    disabled={readOnly}
                                    formMethods={formMethods}
                                    index={index}
                                    isEditorTouched={isEditorTouched}
                                    onClearVariable={handleClearVariable}
                                    selectedVariable={selectedVariable}
                                    onSubjectTouched={handleIsSubjectTouched}
                                    isEdit={isEdit}
                                />
                                {index >= 1 && (
                                    <Switch
                                        formMethods={formMethods}
                                        label={translate('reply-to-last-email')}
                                        name={`stages.${index}.replyToLastEmail`}
                                        disabled={readOnly || !allowSendAsReply}
                                    />
                                )}
                            </Box>
                            <Editor
                                disabled={readOnly}
                                formMethods={formMethods}
                                index={index}
                                isSubjectTouched={isSubjectTouched}
                                name={`stages.${index}.content`}
                                onClearVariable={handleClearVariable}
                                onEditorTouched={handleIsEditorTouched}
                                selectedVariable={selectedVariable}
                                onKeyUp={handleOnEditorKeyUp}
                                modules={{ magicUrl: true }}
                                signatureHtml={signatureHtml}
                                isEdit={isEdit}
                            />
                        </Container>
                    </Box>
                </Box>
            </Box>
        </SendFromEmailContextProvider>
    );
};

export { ComposeEmail };
