import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Hyperlink, NumberInsideCircle } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { fontFamilies, fontWeights, spacing } from 'shared/settings';
import { FC, SideMenuRow } from 'shared/types';

interface Props extends SideMenuRow {
    isActive?: boolean;
    openLinkInNewTab?: boolean;
    onClick?: () => void;
    count?: number;
}

const MenuRow: FC<Props> = (props) => {
    const { text, href, icon: Icon, color, isActive, openLinkInNewTab, onClick, count } = props;
    const theme = useTheme();

    return (
        <Box
            component="li"
            css={css`
                font-weight: ${fontWeights.semiBold};
                font-family: ${fontFamilies.poppins};
                display: flex;
                padding: 4px;
            `}
            onClick={onClick}
        >
            <Hyperlink
                href={href}
                target={openLinkInNewTab ? '_blank' : undefined}
                css={css`
                    display: flex;
                    align-items: center;
                    margin-left: -12px;
                    color: ${isActive ? theme.palette.primary.main : theme.palette.secondary.light};
                    .menu-row-icon {
                        margin-left: ${spacing.space8px};
                        margin-right: ${spacing.space16px};
                        path {
                            stroke: ${isActive ? theme.palette.primary.main : theme.palette.secondary.light};
                        }
                    }
                    &:hover {
                        color: ${isActive ? theme.palette.primary.main : theme.palette.secondary.main};
                        .menu-row-icon {
                            path {
                                stroke: ${isActive ? theme.palette.primary.main : theme.palette.secondary.main};
                            }
                        }
                    }
                `}
            >
                <Circle
                    css={css`
                        margin-right: ${Icon ? 0 : spacing.space16px};
                        circle {
                            fill: ${isActive ? theme.palette.primary.main : 'transparent'};
                        }
                    `}
                />
                {Icon ? <Icon className="menu-row-icon" width={20} height={20} fill={color} /> : null}

                <Box
                    css={css`
                        display: flex;
                        align-items: center;

                        @media (max-width: 1000px) {
                            display: none;
                        }
                    `}
                >
                    <Box
                        component="span"
                        css={css`
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        `}
                    >
                        {text}
                    </Box>
                    {typeof count === 'number' && count > 0 && (
                        <NumberInsideCircle
                            css={css`
                                margin-left: 6px;
                            `}
                            number={count}
                        />
                    )}
                </Box>
            </Hyperlink>
        </Box>
    );
};

export { MenuRow };
