import { gql } from '@apollo/client';

export interface ProfileUpdates {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    latestCompany?: string;
    latestSchool?: string;
    title?: string;
    location?: string;
}

export const PROFILE_DETAILS = gql`
    fragment ProfileDetails on profiles {
        id
        prospectId
        firstName
        lastName
        fullName
        title
        latestCompany
        latestSchool
        notes
        firstNameCustomized
        lastNameCustomized
        fullNameCustomized
        titleCustomized
        latestCompanyCustomized
        latestSchoolCustomized
        location
    }
`;

export const CREATE_PROFILE_EXPERIENCE_AND_EDUCATION = gql`
    mutation CreateProfileExperienceAndEducation(
        $educations: [profile_education_insert_input!] = {}
        $experiences: [profile_experience_insert_input!] = {}
    ) {
        insert_profile_education(objects: $educations) {
            affected_rows
        }
        insert_profile_experience(objects: $experiences) {
            affected_rows
        }
    }
`;

/**
 * GET_PROFILE_BY_ID_EDUCATION_DETAILS and GET_PROFILE_BY_ID_EXPERIENCE_DETAILS
 * should conform to the data updated in addOrUpdateProspect()
 * if the data is not matched correctly then some records may be updated with incorrect values (such as null)
 */
const GET_PROFILE_BY_ID_EDUCATION_DETAILS = gql`
    fragment ProfileEducationDetails on profile_education {
        degree
        fieldOfStudy
        schoolName
        startDateYear
        endDateYear
        schoolUrl
        pageUrl: schoolUrl
    }
`;

const GET_PROFILE_BY_ID_EXPERIENCE_DETAILS = gql`
    fragment ProfileExperienceDetails on profile_experience {
        companyName
        title
        current
        startDateMonth
        startDateYear
        endDateMonth
        endDateYear
        companyLogo
        companyUrl
    }
`;

/**
 * special case for the extension (when attempting to match a profile from gmail)
 * used to match the Profile type in shared/types
 */
export const GET_PROFILE_BY_ID = gql`
    query ProfileById($id: String!) {
        data: profiles_by_pk(id: $id) {
            firstName
            lastName
            fullName
            educations: education {
                ...ProfileEducationDetails
            }
            currentEducation: education(order_by: { createdAt: asc }, limit: 1) {
                ...ProfileEducationDetails
            }
            positions: experience {
                ...ProfileExperienceDetails
            }
            currentPositions: experience(where: { current: { _eq: true } }) {
                ...ProfileExperienceDetails
            }
            publicLink: urls(
                order_by: { createdAt: desc }
                limit: 1
                where: { url: { _ilike: "%linkedin.com/in/%" } }
            ) {
                url
            }
            location
        }
    }
    ${GET_PROFILE_BY_ID_EDUCATION_DETAILS}
    ${GET_PROFILE_BY_ID_EXPERIENCE_DETAILS}
`;
