import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { EmptyState } from 'shared/components/composite';
import { Hyperlink } from 'shared/components/presentational';
import { UsersCheck } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NothingToReviewNoticeProps {}

const NothingToReviewNotice: FC<NothingToReviewNoticeProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.nothing-to-review-notice');

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <UsersCheck
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                    strokeWidth="4"
                />
            }
            title={translate('title')}
            description={translate('description')}
            button={
                <Hyperlink
                    href="#!"
                    css={css`
                        height: ${spacing.space44px};
                    `}
                >
                    {translate('change-sourcer-speed-label')}
                </Hyperlink>
            }
        />
    );
};

export { NothingToReviewNotice };
