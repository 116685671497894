import {
    BigintComparisonExp,
    InputMaybe,
    RecipientResponseSentimentTypesEnum,
    RecipientStatusEnum,
} from 'codegen/graphql';

export const StatusOptionLabelValues = [
    'responded',
    'responded-interested',
    'responded-not-interested',
    'meeting-booked',
    'completed-with-no-reply',
    'in-progress',
    'unable-to-find-contact',
    'unable-to-send-message',
    'missing-template-variables',
    'manually-exited',
    'unsubscribed',
    'not-started',
    'finding-email',
] as const;
export type StatusOptionLabel = typeof StatusOptionLabelValues[number];
export type StatusOption = {
    label: StatusOptionLabel | `in-progress-stage-${string}`;
    status: RecipientStatusEnum[];
    sentiments: RecipientResponseSentimentTypesEnum[];
};

/**
 * iterates over the checkedStatus array. For every status string that includes 'in-progress-stage-',
 * it extracts the stage number from the status string and appends it to inProgressStageNumberFilter.
 * include any stages (by number) that are referenced
 * if 'in-progress' (no stage) is selected just return that
 */
export const buildLastStageSentFilter = ({
    checkedStatus,
}: {
    checkedStatus: StatusOption['label'][];
}): InputMaybe<BigintComparisonExp> => {
    if (checkedStatus.includes('in-progress')) {
        return { _gt: 0 };
    }

    let res: InputMaybe<BigintComparisonExp> | undefined;
    let inProgressStageNumberFilter: number[] = [];
    for (const cs of checkedStatus) {
        if (cs.includes('in-progress-stage-')) {
            inProgressStageNumberFilter = [
                ...inProgressStageNumberFilter,
                parseInt(cs.replace('in-progress-stage-', ''), 10),
            ];
        }
    }
    if (inProgressStageNumberFilter.length > 0) res = { _in: inProgressStageNumberFilter };
    return res ?? {};
};

/**
 * don't include "responded" if "interested" OR "not interested"
 * included. do if both or neither are included.
 */
export const buildStatusFilter = ({
    includeInProgress,
    isStatusFilterApplied,
    statusFilters,
    isSentimentFilterApplied,
    sentimentFilters,
}: {
    includeInProgress: boolean;
    isStatusFilterApplied: boolean;
    statusFilters: RecipientStatusEnum[];
    isSentimentFilterApplied: boolean;
    sentimentFilters: RecipientResponseSentimentTypesEnum[];
}) => {
    if (!isStatusFilterApplied) {
        return {};
    }

    let modifiedStatusFilters = statusFilters;
    if (!includeInProgress) {
        modifiedStatusFilters = statusFilters.filter((f) => f !== RecipientStatusEnum.InProgress);
    }
    return {
        _in: isSentimentFilterApplied
            ? sentimentFilters.includes(RecipientResponseSentimentTypesEnum.Interested) &&
              sentimentFilters.includes(RecipientResponseSentimentTypesEnum.NotInterested)
                ? modifiedStatusFilters
                : modifiedStatusFilters.filter((f) => f !== RecipientStatusEnum.Responded)
            : modifiedStatusFilters,
    };
};
