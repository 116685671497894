import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { EmptyState } from 'shared/components/composite';
import { UsersCheck } from 'shared/components/svgs';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface NoProspectsNoticeProps {}
const NoProspectsNotice: FC<NoProspectsNoticeProps> = () => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.no-prospects-notice');

    return (
        <EmptyState
            css={css`
                height: 717px;
            `}
            icon={
                <UsersCheck
                    css={css`
                        width: ${spacing.space64px};
                        height: ${spacing.space64px};
                    `}
                    stroke={theme.palette.common.black}
                    strokeWidth="4"
                />
            }
            title={translate('title')}
            description={translate('description')}
        />
    );
};

export { NoProspectsNotice };
