import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { CreateUserPinMutation, RemoveUserPinMutation, SequencesQuery } from 'codegen/graphql';
import { IconButton } from 'shared/components/presentational';
import { Pin } from 'shared/components/svgs';
import { Pinned } from 'shared/components/svgs/pinned';
import { CREATE_USER_PIN, GET_USER_PINS, REMOVE_USER_PIN, PinsType } from 'shared/graphql/user-pins';
import { useSession } from 'shared/hooks/use-session';
import { FC } from 'shared/types';

type SequenceData = SequencesQuery['sequences'][number];

interface PinButtonProps {
    sequence: SequenceData;
}

const PinButton: FC<PinButtonProps> = ({ sequence }) => {
    const theme = useTheme();
    const { session } = useSession();
    const [pinId, setPinId] = useState('');

    const { data, refetch } = useQuery<PinsType>(GET_USER_PINS);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequence]);

    useEffect(() => {
        if (data && data.pins) {
            const pins = data?.pins.filter((p) => p.targetKind === 'sequences' && p.targetId === sequence.id);
            if (pins && pins.length > 0) {
                setPinId(pins[0].id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const [createUserPin] = useMutation<CreateUserPinMutation>(CREATE_USER_PIN, {
        refetchQueries: [getOperationName(GET_USER_PINS) as string],
    });
    const [removeUserPin] = useMutation<RemoveUserPinMutation>(REMOVE_USER_PIN, {
        refetchQueries: [getOperationName(GET_USER_PINS) as string],
    });

    const handlePinButtonClick = async () => {
        if (pinId) {
            const result = await removeUserPin({
                variables: {
                    id: pinId,
                },
            });

            if (result?.data?.deleted?.id === pinId) {
                setPinId('');
            }
        } else {
            const result = await createUserPin({
                variables: {
                    userPin: {
                        userId: session?.user?.id,
                        targetId: sequence.id,
                        targetKind: 'sequences',
                    },
                },
            });
            setPinId(result?.data?.inserted?.id as string);
        }
    };

    return (
        <IconButton
            css={css`
                padding: 10px;
                &:hover {
                    background-color: ${theme?.palette.common.white};
                }
            `}
            onClick={handlePinButtonClick}
        >
            {pinId ? <Pinned /> : <Pin />}
        </IconButton>
    );
};

export { PinButton };
