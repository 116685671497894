import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import Select, {
    components,
    DropdownIndicatorProps,
    type FormatOptionLabelMeta,
    GroupBase,
    PlaceholderProps,
    StylesConfig,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { Plus } from 'shared/components/svgs';
import { fontSizes, spacing } from 'shared/settings';
import { FC, FCProps } from 'shared/types';

interface Option {
    label: string;
    value: string;
    isDisabled?: boolean;
}

interface SelectChipProps<Values extends FieldValues> {
    formMethods?: UseFormReturn<Values>;
    formatCreateLabel?: (inputValue: string) => string;
    isCreatable?: boolean;
    isDisabled?: boolean;
    isMulti?: boolean;
    name: Path<Values>;
    options: Option[];
    placeholder: string;
    onChange?: (selectedOptions: any) => void;
    value?: Option[];
    noOptionsMessage?: string;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    formatOptionLabel?: (option: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode;
}

const { ValueContainer, Placeholder, DropdownIndicator } = components;

const SelectChip = <Values extends FieldValues>(
    props: SelectChipProps<Values> &
        Pick<CreatableAdditionalProps<Option, GroupBase<Option>>, 'isValidNewOption'> &
        FCProps
) => {
    const {
        name,
        formMethods,
        options,
        className,
        placeholder,
        isCreatable,
        isDisabled,
        isMulti,
        isValidNewOption,
        onChange,
        formatCreateLabel,
        value,
        noOptionsMessage,
        onKeyDown,
        formatOptionLabel,
    } = props;
    const theme = useTheme();

    formMethods?.register(name);
    formMethods?.watch(name);

    const handleChange = (selectedOptions: any): void => {
        formMethods?.setValue(name, selectedOptions, { shouldDirty: true, shouldValidate: true });
    };

    const defaultProps: StateManagerProps<Option, boolean, GroupBase<Option>> = {
        className,
        classNamePrefix: 'select',
        closeMenuOnSelect: true,
        components: {
            ClearIndicator: () => null,
            DropdownIndicator: !isCreatable ? null : DropdownIndicatorContainer,
            IndicatorSeparator: () => null,
            ValueContainer: CustomValueContainer,
        },
        isDisabled,
        isMulti: isMulti ?? true,
        onChange: onChange || handleChange,
        options,
        placeholder,
        styles: defaultStyles(theme),
        value: value || formMethods?.getValues(name),
        id: name,
        instanceId: name,
        noOptionsMessage: () => noOptionsMessage,
        onKeyDown,
        formatOptionLabel,
    };

    return isCreatable ? (
        <CreatableSelect formatCreateLabel={formatCreateLabel} isValidNewOption={isValidNewOption} {...defaultProps} />
    ) : (
        <Select {...defaultProps} />
    );
};

const CustomValueContainer: FC<any> = ({ children, ...props }) => (
    <ValueContainer {...props}>
        <PlaceholderContainer {...props} />
        {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
);

const PlaceholderContainer = (props: PlaceholderProps) => {
    const { isFocused, selectProps } = props;

    return (
        <Placeholder {...props} isFocused={isFocused}>
            {selectProps.placeholder}
        </Placeholder>
    );
};

const DropdownIndicatorContainer = (props: DropdownIndicatorProps<Option, boolean, GroupBase<Option>>) => (
    <DropdownIndicator {...props}>
        <Plus />
    </DropdownIndicator>
);

const defaultStyles = ({ palette }: Theme): StylesConfig<Option, boolean, GroupBase<Option>> => ({
    // container
    control: (styles, { hasValue, selectProps, isFocused }) => ({
        ...styles,
        border: isFocused ? `1px solid ${palette.primary.main}` : 'none',
        paddingBottom: hasValue || selectProps.inputValue ? '5px' : '0',
    }),
    option: (styles, { isSelected, isDisabled }) => ({
        ...styles,
        color: palette.grey[200],
        background: isSelected ? palette.primary.contrastText : 'none',
        opacity: isDisabled ? '0.5' : '1.0',
    }),
    placeholder: (provided, { hasValue, selectProps }) => ({
        color: palette.grey[200],
        fontSize: (hasValue || selectProps.inputValue) && fontSizes.f12,
        top: hasValue || selectProps.inputValue ? '-2px' : '20%',
    }),
    input: (styles, { hasValue, selectProps }) => ({
        ...styles,
        marginTop: (hasValue || selectProps.inputValue) && '10px',
    }),
});

const StyledSelectChip = styled(SelectChip)`
    ${({ theme: { palette } }) => `
    // container
    .select__control {
        background-color: ${palette.grey[300]};
        box-shadow: none;
        margin-bottom: ${spacing.space8px};
        padding: 10px;
        width: 100%;
        min-height: 70px;
        border: 1px solid ${palette.grey[500]};

        &:hover {
            border-color: ${palette.grey[100]};
        }
        &.select__control--is-focused {
            border-color: ${palette.primary.main};
        }
    }

    // placeholder
    .select__placeholder {
        left: 10px;
        margin-bottom: 10px;
        position: absolute;
        transition: top 0.1s, font-size 0.1s;
    }

    .select__value-container--has-value {
        overflow: visible;
        .select__placeholder {
            top: -8px;
        }
    }

    .select__single-value {
        margin-top: 10px;
    }

    // chip container
    .select__multi-value {
        background-color: ${palette.primary.contrastText};
        padding: 8px 12px;
        border-radius: 4px;
        margin-top: 14px;
    }

    // chip content
    .select__multi-value__label {
        font-size: ${fontSizes.f14};
    }

    // dropdown option
    .select__option {
        padding-top: 15px;
        padding-bottom: 15px;
        margin: 0px;

        &:hover {
            background-color: ${palette.primary.contrastText};
        }
    }

    // menu
    .select__menu {
        max-width: 580px;
        margin-top: 1px;
    }
`}
` as typeof SelectChip;

export { StyledSelectChip as SelectChip };
export type { Option, SelectChipProps };
