import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';

import {
    ReviewSourcerMembersMutation,
    ReviewSourcerMembersMutationVariables,
    SourcerMemberRejectionReasonsEnum,
    SourcerMemberStatusEnum,
    UpdateSourcerMembersMutation,
    UpdateSourcerMembersMutationVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { LargeSwitch } from 'shared/components/form';
import { Button, Hyperlink } from 'shared/components/presentational';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import {
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    REVIEW_SOURCER_MEMBERS,
    UPDATE_SOURCER_MEMBERS,
    createApproveUpsertObject,
} from 'shared/graphql/sourcer-members';
import { useProspectCreator, useSession, useSourcerAccessControl, useAiSourcingCredits } from 'shared/hooks';
import { appRoutes, colors, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectSourcerMember } from 'shared/types/prospect';
import { SourcerMemberCardRejectionDetails } from 'sourcing/components/composite';
import { NoAISourcingCreditsModal } from 'sourcing/components/modals';

const debounceMs = 500;

interface SourcerReviewTabProps {
    sourcerMember: ProspectSourcerMember;
    onClose: () => void;
    handleConfirmAndClose: (action: SourcerMemberStatusEnum.Accepted | SourcerMemberStatusEnum.Rejected) => void;
}

const SourcerReviewTab: FC<SourcerReviewTabProps> = (props) => {
    const theme = useTheme();
    const { session, loaded } = useSession();
    const { sourcerMember, onClose, handleConfirmAndClose } = props;
    const translate = useTranslations('prospects.tabs.add-tab.sourcer-extension-add-tab');

    const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();
    const { canManageSourcerMembers } = useSourcerAccessControl();
    const { createProspectsFromSourcerMembers } = useProspectCreator();

    const [showRejectDialog, setShowRejectDialog] = useState<boolean>(false);
    const [rejectionReasons, setRejectionReasons] = useState<SourcerMemberRejectionReasonsEnum[]>([]);
    const [showNoAISourcingCreditsModal, setShowNoAISourcingCreditsModal] = useState<boolean>(false);

    const [reviewAction, setReviewAction] = useState<
        SourcerMemberStatusEnum.Accepted | SourcerMemberStatusEnum.Rejected
    >(SourcerMemberStatusEnum.Accepted);
    const [notes, setNotes] = useState<string>();

    const [reviewSourcerMembers] = useMutation<ReviewSourcerMembersMutation, ReviewSourcerMembersMutationVariables>(
        REVIEW_SOURCER_MEMBERS,
        {
            refetchQueries: [
                getOperationName(GET_SOURCER_MEMBERS) as string,
                getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
                getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            ],
        }
    );

    const [updateSourcerMembers] = useMutation<UpdateSourcerMembersMutation, UpdateSourcerMembersMutationVariables>(
        UPDATE_SOURCER_MEMBERS,
        {
            refetchQueries: [
                getOperationName(GET_SOURCER_MEMBERS) as string,
                getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
                getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            ],
        }
    );

    const handleSaveNotes = async (notesText: string) => {
        await updateSourcerMembers({
            variables: {
                where: { id: { _eq: sourcerMember.id } },
                set: {
                    notes: notesText.trim(),
                },
            },
        });
    };

    const debounceNotesChanged = useMemo(
        () => debounce(handleSaveNotes, debounceMs),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sourcerMember]
    );

    const handleActionChange = async (action: SourcerMemberStatusEnum.Accepted | SourcerMemberStatusEnum.Rejected) => {
        setReviewAction(action);
        if (action === SourcerMemberStatusEnum.Rejected) {
            setShowRejectDialog(true);
        } else {
            setShowRejectDialog(false);
        }
    };

    const handleNotesChange = (_1: string, newNote: string) => {
        setNotes(newNote);
        debounceNotesChanged(newNote);
    };

    const handleRejectionReasonChange = async (
        _1: string,
        newRejectionReasons: SourcerMemberRejectionReasonsEnum[]
    ) => {
        setRejectionReasons(newRejectionReasons);
        await updateSourcerMembers({
            variables: {
                where: { id: { _eq: sourcerMember.id } },
                set: {
                    rejectionReasonsIntent: newRejectionReasons,
                },
            },
        });
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleActionChange(event.target.checked ? SourcerMemberStatusEnum.Accepted : SourcerMemberStatusEnum.Rejected);
    };

    const handleConfirm = async () => {
        if (reviewAction === SourcerMemberStatusEnum.Accepted) {
            const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
                getAiSourcingCreditsAvailable(),
                isPayOnApproveEnabled(),
            ]);
            const creditsPerApproval = sourcerMember.sourcer.deductCustomCredits
                ? sourcerMember.sourcer.creditsPerApproval
                : 1;

            if (!shouldDeductCreditOnApprove || aiSourcingCreditsAvailable >= creditsPerApproval) {
                const sourcerMemberWithProspect = await createProspectsFromSourcerMembers([sourcerMember]);
                const approveUpdateObject = createApproveUpsertObject({ sourcerMember, reviewerId: session!.user.id });
                if (sourcerMemberWithProspect?.length === 1) {
                    await reviewSourcerMembers({
                        variables: {
                            sourcer_members: [
                                { ...approveUpdateObject, prospectId: sourcerMemberWithProspect[0].prospect?.id },
                            ],
                        },
                    });
                }
            } else {
                // show error for not enough credits
                setShowNoAISourcingCreditsModal(true);
                return;
            }
        } else if (reviewAction === SourcerMemberStatusEnum.Rejected) {
            await updateSourcerMembers({
                variables: {
                    where: { id: { _eq: sourcerMember.id } },
                    set: {
                        status: SourcerMemberStatusEnum.Rejected,
                        reviewerId: session!.user.id,
                        rejectedAt: Date.now(),
                    },
                },
            });
        }

        handleConfirmAndClose(reviewAction);
    };

    const handleCloseShowAiSourcingCreditsModal = () => {
        setShowNoAISourcingCreditsModal(false);
    };

    if (!loaded) {
        return null;
    }

    return (
        <Box
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 24px 24px 0px;
                background-color: ${colors.greens.narvik};
                border: 1px solid ${colors.greens.hintOfGreen};
                border-radius: 4px;
                height: calc(100vh - 296px);
            `}
        >
            <Box
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                `}
            >
                <Box
                    css={css`
                        margin: 8px;
                        font-size: 24px;
                        font-weight: ${fontWeights.bold};
                    `}
                >
                    {translate('header')}
                </Box>
                <Box
                    css={css`
                        font-size: 16px;
                        text-align: center;
                        margin: 0 32px 0;
                    `}
                >
                    {translate.rich(
                        sourcerMember.status === SourcerMemberStatusEnum.Snoozed
                            ? 'description-snoozed'
                            : 'description-to-review',
                        {
                            entity: ClickableEntity(
                                theme.palette.primary.main,
                                sourcerMember.sourcer.title,
                                appRoutes.sourcing.index(sourcerMember.sourcer.id)
                            ),
                        }
                    )}
                </Box>
                {canManageSourcerMembers(sourcerMember.sourcer) ? (
                    <>
                        <Box
                            css={css`
                                margin: 24px;
                            `}
                        >
                            <LargeSwitch
                                onChange={handleSwitchChange}
                                name="extension-sourcer-member-accepted-rejected-intent-switch"
                                checked={reviewAction === SourcerMemberStatusEnum.Accepted}
                            />
                        </Box>
                        {showRejectDialog && (
                            <Box
                                css={css`
                                    margin: 24px;
                                    width: 400px;
                                `}
                            >
                                <SourcerMemberCardRejectionDetails
                                    notes={notes}
                                    sourcerMemberId={sourcerMember.id}
                                    rejectionReasons={rejectionReasons}
                                    onNotesChange={handleNotesChange}
                                    onRejectionReasonsChange={handleRejectionReasonChange}
                                />
                            </Box>
                        )}
                        <Button onClick={handleConfirm}>{translate('confirm-button-label')}</Button>
                    </>
                ) : (
                    <Button
                        css={css`
                            margin-top: 16px;
                        `}
                        onClick={onClose}
                    >
                        {translate('dismiss-button-label')}
                    </Button>
                )}
            </Box>
            <NoAISourcingCreditsModal
                open={showNoAISourcingCreditsModal}
                onClose={handleCloseShowAiSourcingCreditsModal}
            />
        </Box>
    );
};

const ClickableEntity = (primaryTheme: string, title: string, link: string) => () =>
    (
        <Box
            component="span"
            css={css`
                color: ${primaryTheme};
            `}
        >
            <Hyperlink newTab href={link}>
                {title}
            </Hyperlink>
        </Box>
    );

export { SourcerReviewTab };
