export const OUT_OF_AI_SOURCING_CREDITS = 'https://calendly.com/daniel-park-1/ai-sourcing-credits';
export const CONTACT_US_URL = 'https://calendly.com/hireflowai/hireflow-demo?month=2023-04';
export const CONTACT_US_SUPPORT_URL = 'https://hireflow.ai/support';
export const BOOK_A_DEMO_URL = 'https://www.hireflow.com/demo';
export const TERMS_OF_SERVICE_URL = 'https://www.hireflow.com/terms-of-service';
export const PRIVACY_POLICY_URL = 'https://www.hireflow.com/privacy';
export const GOOGLE_API_SERVICES_USER_DATA_POLICY_URL =
    'https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes';
export const HIREFLOW_HOME_URL = 'https://www.hireflow.ai';
export const ARCHIVE_USER_ARTICLE_URL = ' https://intercom.help/hireflow/en/articles/8659533-how-to-un-archive-a-user';
export const ADD_HIREFLOW_AS_SOURCE_GREENHOUSE =
    'https://intercom.help/hireflow/en/articles/8800577-adding-hireflow-as-a-greenhouse-source';
export const SOBO_SUPPORT_ARTICLE_URL =
    'https://intercom.help/hireflow/en/articles/8127509-can-i-send-outreach-emails-on-behalf-of-someone-else';

export const SUPPORT_HIREFLOW_EMAIL = 'support@hireflow.ai';
