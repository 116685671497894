import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedFolder: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '20'}
        height={height ?? '18'}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M10.8333 4.83333L9.90368 2.9741C9.63613 2.439 9.50235 2.17144 9.30277 1.97597C9.12628 1.80311 8.91356 1.67164 8.68002 1.59109C8.41593 1.5 8.1168 1.5 7.51854 1.5H4.33329C3.39987 1.5 2.93316 1.5 2.57664 1.68166C2.26304 1.84144 2.00807 2.09641 1.84828 2.41002C1.66663 2.76654 1.66663 3.23325 1.66663 4.16667V4.83333M1.66663 4.83333H14.3333C15.7334 4.83333 16.4335 4.83333 16.9683 5.10582C17.4387 5.3455 17.8211 5.72795 18.0608 6.19836C18.3333 6.73314 18.3333 7.4332 18.3333 8.83333V12.5C18.3333 13.9001 18.3333 14.6002 18.0608 15.135C17.8211 15.6054 17.4387 15.9878 16.9683 16.2275C16.4335 16.5 15.7334 16.5 14.3333 16.5H5.66663C4.26649 16.5 3.56643 16.5 3.03165 16.2275C2.56124 15.9878 2.17879 15.6054 1.93911 15.135C1.66663 14.6002 1.66663 13.9001 1.66663 12.5V4.83333Z"
            stroke={stroke ?? theme?.palette.secondary.light}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Folder = withTheme(ThemedFolder);

export { Folder };
