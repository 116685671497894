// cspell:ignore googleauthcode
import { InvoiceInfo } from 'hireflow-shared/types/stripe/invoice-info';
import { PaymentMethodInfo } from 'hireflow-shared/types/stripe/payment-method-info';
import { SubscriptionInfo } from 'hireflow-shared/types/stripe/subscription-details';
import { HrefFilePayload } from 'hireflow-shared/utils/file-utils';

import { EmailAccountStatusEnum, GreenhouseApplicationTypeEnum } from 'codegen/graphql';
import { config } from 'config';
import { AtsType } from 'integrations/types';
import { logger } from 'logger';
import { Session } from 'shared/hooks/use-session';

const apiEndpoint = config.ApiEndpoint;

interface RestApiResponse {
    data: any;
    success: boolean;
}

interface AuthenticateResponse extends RestApiResponse {
    data: {
        session: Session;
    };
}

interface AddEmailAccountResponse extends RestApiResponse {
    data: {
        emailAccount?: {
            email: string;
            status: EmailAccountStatusEnum;
        };
    };
}

interface GetGmailSignatureResponse extends RestApiResponse {
    data: {
        signature: string;
    };
}

interface GetMagicSentenceResponse extends RestApiResponse {
    data: {
        magicSentence: string | null;
        generatedSentenceId: string | null;
    };
}

interface TrackGeneratedMagicSentence extends RestApiResponse {}

interface CreateRestApiClientParams {
    // SSR clients will need to manually set Cookie header
    headers?: HeadersInit;
}

interface ConnectLeverOauthProps {
    code: string;
    state: string;
    syncConfidential: boolean;
}

interface ConnectAshbyTokenProps {
    apiToken: string;
    defaultSourceId?: string;
}

interface ConnectGreenhouseTokenProps {
    apiToken: string;
    defaultSourceId?: string;
    defaultCreateAsApplicationType?: GreenhouseApplicationTypeEnum;
}

interface ConnectLeverTokenProps {
    useOauth?: boolean;
    syncConfidential?: boolean;
    previousSyncConfidential?: boolean;
    apiToken: string;
    defaultSourceId?: string;
}

interface GetTokenResponseData {
    data: string | null;
    success: boolean;
}

interface DecryptApiTokenResponse extends RestApiResponse {
    data: {
        decryptedToken: string;
    };
}

interface StripeInvoiceDetailProps extends RestApiResponse {
    data: { invoices: InvoiceInfo[] };
    success: boolean;
}

interface CreateCheckoutSessionProps extends RestApiResponse {
    data: {
        session: {
            id: string;
            url: string;
        };
    };
}

interface PaymentMethodProps extends RestApiResponse {
    data: { paymentMethod: PaymentMethodInfo | undefined };
    success: boolean;
}

interface SubscriptionInfoProps extends RestApiResponse {
    data: { subscriptionInfo: SubscriptionInfo | undefined };
}

interface VersionResponse extends RestApiResponse {
    data: { version: string };
}

interface DownloadAiInsightsPromptResponse extends RestApiResponse {
    data: {
        aiInsightsPrompt: [
            {
                role: 'system' | 'user' | 'assistant' | 'function';
                content: string;
            }
        ];
    } | null;
    success: boolean;
}

interface SaveProspectProfileHtmlResponse extends RestApiResponse {
    data: {};
    success: boolean;
}
interface UploadImageResponse extends RestApiResponse {
    data: {
        imageUrl: string;
    };
}

async function getTokenResponse(response: Response): Promise<GetTokenResponseData> {
    if (response.ok) {
        const res = await response?.body?.getReader()?.read();
        const decodedValue: string = new TextDecoder().decode(res?.value);
        return { data: decodedValue, success: true };
    }
    return { data: null, success: false };
}

const createRestApiClient = (params?: CreateRestApiClientParams) => {
    const { headers } = params || {};

    const apiCall = async (input: RequestInfo, init?: RequestInit): Promise<RestApiResponse> => {
        try {
            const response = await fetch(input, {
                ...init,
                credentials: 'include',
                headers: { ...init?.headers, ...headers },
            });
            if (response.ok) {
                let data: any = null;
                data = await response.json();
                return { data, success: true };
            }
        } catch (err) {
            if (err instanceof Error && !err.message.toLowerCase().includes('failed to fetch')) {
                // Log the error if it does not contain "Failed to fetch"
                logger.error('api call error', { input, init, err });
            }
        }
        return { data: null, success: false };
    };

    const authenticate = async (data: { code: string }): Promise<AuthenticateResponse> => {
        const { code } = data;
        const endpoint = `${apiEndpoint}/authenticate`;
        return apiCall(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                googleauthcode: code,
            },
            method: 'post',
        });
    };

    const authenticateOutlook = async (data: { code: string }): Promise<AuthenticateResponse> => {
        const { code } = data;
        const endpoint = `${apiEndpoint}/authenticate`;
        return apiCall(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                outlookauthcode: code,
            },
            method: 'post',
        });
    };

    const logout = async () => {
        const endpoint = `${apiEndpoint}/logout`;
        return apiCall(endpoint, {
            method: 'post',
        });
    };

    const canImpersonate = async () =>
        apiCall(`${apiEndpoint}/can-impersonate`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
        });

    const impersonate = async (email: string) =>
        apiCall(`${apiEndpoint}/impersonate`, {
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });

    const addEmailAccount = async (data: { code: string }): Promise<AddEmailAccountResponse> => {
        const { code } = data;
        const endpoint = `${apiEndpoint}/add-email-account`;
        return apiCall(endpoint, {
            body: JSON.stringify({ code }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const getGmailSignature = async (data: { email: string }): Promise<GetGmailSignatureResponse> => {
        const { email } = data;
        const endpoint = `${apiEndpoint}/sync-email-account-signature`;
        return apiCall(endpoint, {
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const getMagicSentence = async (data: { prospectId: string }): Promise<GetMagicSentenceResponse> => {
        const { prospectId } = data;
        const endpoint = `${apiEndpoint}/personalization/magic-sentence`;
        return apiCall(endpoint, {
            body: JSON.stringify({ prospectId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const trackGeneratedMagicSentence = async (data: {
        generatedSentenceId: string;
    }): Promise<TrackGeneratedMagicSentence> => {
        const { generatedSentenceId } = data;
        const endpoint = `${apiEndpoint}/personalization/generated-sentence-tracking`;
        return apiCall(endpoint, {
            body: JSON.stringify({ generatedSentenceId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const unsubscribe = async (data: { token: string }): Promise<RestApiResponse> => {
        const { token } = data;
        const endpoint = `${apiEndpoint}/unsubscribe`;
        return apiCall(endpoint, {
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const approveSoboRequest = async (data: { token: string }): Promise<RestApiResponse> => {
        const { token } = data;
        const endpoint = `${apiEndpoint}/sobo/authorize`;
        return apiCall(endpoint, {
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    /**
     * create a lever account that will use oauth credentials
     */
    const connectLeverOauth = async ({ code, state, syncConfidential }: ConnectLeverOauthProps) => {
        const endpoint = `${apiEndpoint}/oauth/lever`;

        try {
            const response = await fetch(endpoint, {
                body: JSON.stringify({ code, state, syncConfidential }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                credentials: 'include',
                ...headers,
            });
            if (response.ok) {
                return { data: null, success: true };
            }
        } catch (err) {
            logger.error('cannot connect Lever OAuth account', { code, state, err });
        }
        return { data: null, success: false };
    };

    /**
     * create an ashby account with an api token
     */
    const connectAshbyToken = async ({ apiToken, defaultSourceId }: ConnectAshbyTokenProps) => {
        const endpoint = `${apiEndpoint}/token/ashby`;

        try {
            const response = await fetch(endpoint, {
                body: JSON.stringify({ apiToken, defaultSourceId }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                credentials: 'include',
                ...headers,
            });
            return await getTokenResponse(response);
        } catch (err) {
            logger.error('cannot connect Ashby API token', { err });
        }
        return { data: null, success: false };
    };

    /**
     * create a greenhouse account with an api token
     */
    const connectGreenhouseToken = async ({
        apiToken,
        defaultSourceId,
        defaultCreateAsApplicationType,
    }: ConnectGreenhouseTokenProps) => {
        const endpoint = `${apiEndpoint}/token/greenhouse`;

        try {
            const response = await fetch(endpoint, {
                body: JSON.stringify({
                    apiToken,
                    defaultSourceId,
                    defaultCreateAsApplicationType,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                credentials: 'include',
                ...headers,
            });
            return await getTokenResponse(response);
        } catch (err) {
            logger.error('cannot connect Greenhouse API token', { err });
        }
        return { data: null, success: false };
    };

    /**
     * create a lever account with an api token
     */
    const connectLeverToken = async ({
        useOauth,
        apiToken,
        defaultSourceId,
        syncConfidential,
        previousSyncConfidential,
    }: ConnectLeverTokenProps) => {
        const endpoint = `${apiEndpoint}/token/lever`;

        try {
            const response = await fetch(endpoint, {
                body: JSON.stringify({
                    useOauth,
                    apiToken,
                    defaultSourceId,
                    syncConfidential,
                    previousSyncConfidential,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                credentials: 'include',
                ...headers,
            });
            return await getTokenResponse(response);
        } catch (err) {
            logger.error('cannot connect Lever API token', { err });
        }
        return { data: null, success: false };
    };

    /**
     * decrypt the api token based on given ats type
     * don't pass the encrypted tokens as these are already available from the backend
     */
    const decryptApiToken = async (data: { atsType: AtsType }): Promise<DecryptApiTokenResponse> => {
        const { atsType } = data;
        const endpoint = `${apiEndpoint}/token/decrypt`;
        return apiCall(endpoint, {
            body: JSON.stringify({ atsType }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const stripeInvoiceDetails = async (data: { customerId: string }): Promise<StripeInvoiceDetailProps> => {
        const { customerId } = data;
        const endpoint = `${apiEndpoint}/stripe/billing-details`;
        return apiCall(endpoint, {
            body: JSON.stringify({ customerId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const createCheckoutSession = async (data: {
        customerId: string;
        path: string;
    }): Promise<CreateCheckoutSessionProps> => {
        const { customerId, path } = data;
        const endpoint = `${apiEndpoint}/stripe/checkout-session`;
        return apiCall(endpoint, {
            body: JSON.stringify({ customerId, path }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const createUpgradeCheckoutSession = async (data: {
        customerId: string;
        path: string;
        price: string;
    }): Promise<CreateCheckoutSessionProps> => {
        const { customerId, path, price } = data;
        const endpoint = `${apiEndpoint}/stripe/upgrade-checkout-session`;
        return apiCall(endpoint, {
            body: JSON.stringify({ customerId, path, price }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const stripePaymentMethod = async (data: { customerId: string }): Promise<PaymentMethodProps> => {
        const { customerId } = data;
        const endpoint = `${apiEndpoint}/stripe/payment-method`;
        return apiCall(endpoint, {
            body: JSON.stringify({ customerId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const stripeSubscriptionDetails = async (data: { subscriptionId: string }): Promise<SubscriptionInfoProps> => {
        const { subscriptionId } = data;
        const endpoint = `${apiEndpoint}/stripe/subscription-info`;
        return apiCall(endpoint, {
            body: JSON.stringify({ subscriptionId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const getSession = async () =>
        apiCall(`${apiEndpoint}/session`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
        });

    const getVersion = async (): Promise<VersionResponse> =>
        apiCall(`${apiEndpoint}/version`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
        });

    const addOutlookEmailAccount = async (data: { code: string }): Promise<AddEmailAccountResponse> => {
        const { code } = data;
        const endpoint = `${apiEndpoint}/add-outlook-email-account`;
        return apiCall(endpoint, {
            body: JSON.stringify({ code }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const downloadAiInsightsPrompt = async (data: {
        sourcerMemberId: string;
    }): Promise<DownloadAiInsightsPromptResponse> => {
        const { sourcerMemberId } = data;
        const endpoint = `${apiEndpoint}/ai-insights/download-prompt`;
        return apiCall(endpoint, {
            body: JSON.stringify({ sourcerMemberId }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const saveProspectProfileHtml = async (data: {
        prospectHtml: string;
        prospectUrl: string;
    }): Promise<SaveProspectProfileHtmlResponse> => {
        const { prospectHtml, prospectUrl } = data;
        const endpoint = `${apiEndpoint}/extension/save-prospect-profile-html`;
        return apiCall(endpoint, {
            body: JSON.stringify({ prospectHtml, prospectUrl }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    const uploadImage = async (hrefPayload: HrefFilePayload): Promise<UploadImageResponse> => {
        const endpoint = `${apiEndpoint}/upload-image`;

        return apiCall(endpoint, {
            body: JSON.stringify(hrefPayload),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
        });
    };

    return {
        authenticate,
        canImpersonate,
        impersonate,
        logout,
        addEmailAccount,
        getGmailSignature,
        getMagicSentence,
        trackGeneratedMagicSentence,
        unsubscribe,
        approveSoboRequest,
        connectLeverOauth,
        connectAshbyToken,
        connectGreenhouseToken,
        connectLeverToken,
        decryptApiToken,
        stripeInvoiceDetails,
        createCheckoutSession,
        stripePaymentMethod,
        createUpgradeCheckoutSession,
        stripeSubscriptionDetails,
        getSession,
        getVersion,
        authenticateOutlook,
        addOutlookEmailAccount,
        downloadAiInsightsPrompt,
        saveProspectProfileHtml,
        uploadImage,
    };
};

export type { AuthenticateResponse, AddEmailAccountResponse };
export { createRestApiClient };
