import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedIcon: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '24'}
        height={height ?? '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14M15 9H15.01M8 9H10M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5 9C15.5 9.27614 15.2761 9.5 15 9.5C14.7239 9.5 14.5 9.27614 14.5 9C14.5 8.72386 14.7239 8.5 15 8.5C15.2761 8.5 15.5 8.72386 15.5 9Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ProLevel = withTheme(ThemedIcon);

export { ProLevel };
