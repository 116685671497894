import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { TextField } from 'shared/components/presentational';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerMemberCardRejectionNotesProps {
    sourcerMemberId: string;
    notes?: string;
    onNotesChange: (sourcerMemberId: string, newNotes: string) => void;
}

const SourcerMemberCardRejectionNotes: FC<SourcerMemberCardRejectionNotesProps> = ({
    sourcerMemberId,
    notes,
    onNotesChange,
}) => {
    const translate = useTranslations('sourcing.sourcer-member-card.rejection-notes-section');

    const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onNotesChange(sourcerMemberId, event.target.value);
    };

    return (
        <TextField
            value={notes}
            onChange={handleNotesChange}
            label={translate('notes-label')}
            multiline
            minRows={2}
            maxRows={4}
            InputProps={{ style: { fontSize: `${fontSizes.f16}` } }}
            inputRef={(input) => input && input.focus()}
            parentBackgroundColor="gray"
            css={css`
                width: 100%;
            `}
        />
    );
};

export { SourcerMemberCardRejectionNotes };
