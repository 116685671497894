import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import {
    AddGoogleEmailButton,
    AddOutlookEmailButton,
    Modal,
    ModalCloseButton,
    Title,
} from 'shared/components/presentational';
import { AddEmailAccountResponse } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddEmailModalProps {
    open: boolean;
    onClose: () => void;
    onAddEmail: ({ data: { emailAccount } }: AddEmailAccountResponse) => void;
}

const AddEmailModal: FC<AddEmailModalProps> = (props) => {
    const { open, onClose, onAddEmail } = props;
    const translate = useTranslations('settings.email-settings.user-email-accounts');

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                css={css`
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={onClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('add-an-email-account-modal-title')}
                </Title>
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    `}
                >
                    <AddGoogleEmailButton onEmailAdd={onAddEmail} />
                    <AddOutlookEmailButton onEmailAdd={onAddEmail} />
                </Box>
            </Box>
        </Modal>
    );
};

export { AddEmailModal };
