import { gql } from '@apollo/client';

import { OrderBy, SequencesQuery, SequenceStatusEnum } from 'codegen/graphql';
import { USER_DETAILS } from 'shared/graphql/users';

export const NO_SEQUENCE: string = 'no-sequence';
export type SequenceData = SequencesQuery['sequences'][number];
export type SequencesProps = {
    initialData: {
        data: NonNullable<SequenceData>[];
        count: number;
        allCount: number;
    };
};

export const sequencesDefaultOrderBy = 'createdAt';
export const sequencesDefaultOrderByDirection = OrderBy.Desc;

export const sequencesDefaultVariables = {
    orderBy: { [sequencesDefaultOrderBy]: sequencesDefaultOrderByDirection },
    limit: 10,
    offset: 0,
    where: {
        status: {
            _in: [
                SequenceStatusEnum.Active,
                SequenceStatusEnum.EmailDisconnected,
                SequenceStatusEnum.Ready,
                SequenceStatusEnum.Draft,
            ],
        },
    },
    withDetails: true,
};

export const SEQUENCE_DETAILS = gql`
    fragment SequenceDetails on sequences {
        id
        userId
        title
        color
        status
        createdAt
        modifiedAt
        collaborators {
            id
            userId
            user: collaborator {
                id
                fullName
                email
            }
        }
        stats {
            added
            active
            finished
            opensPercentage
            repliesPercentage
            interestedPercentage
        }
        emailPreference
        color
        user {
            id
            fullName
            email
        }
        stages(order_by: { stageIndex: asc }) {
            id
            bcc
            cc
            content
            numberOfDays
            replyToLastEmail
            sendTime
            sendTimeOfDay
            sendTypeOfDays
            signature
            useSignature
            subject
            stageIndex
            sendFromAccountId
            sendFromAccount: send_from_account {
                id
                status
                email
                email_account {
                    status
                    user {
                        ...UserDetails
                    }
                }
            }
        }
        projects {
            project {
                id
                title
            }
        }
        collaborators {
            id
            userId
            collaborator {
                id
                fullName
            }
        }
        ashby_sequence_mapping {
            id
            job {
                name
                remoteId
            }
        }
        greenhouse_sequence_mapping {
            id
            job {
                name
                remoteId
            }
        }
        lever_sequence_mapping {
            id
            posting {
                name
                remoteId
            }
        }
    }

    ${USER_DETAILS}
`;

export const GET_SEQUENCES_COUNT = gql`
    query SequencesCount($where: sequences_bool_exp!) {
        sequences: sequences_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_SEQUENCES_COUNT_BY_STATUS = gql`
    query SequencesByStatus($userId: String) {
        active: sequences_aggregate(
            where: {
                status: { _eq: active }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
        ready: sequences_aggregate(
            where: {
                status: { _eq: ready }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
        draft: sequences_aggregate(
            where: {
                status: { _eq: draft }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
        paused: sequences_aggregate(
            where: {
                status: { _eq: paused }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
        archived: sequences_aggregate(
            where: {
                status: { _eq: archived }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
        emailDisconnected: sequences_aggregate(
            where: {
                status: { _eq: email_disconnected }
                _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_SEQUENCES = gql`
    query Sequences(
        $orderBy: sequences_order_by!
        $limit: Int
        $offset: Int = 0
        $where: sequences_bool_exp!
        $withDetails: Boolean!
    ) {
        sequences_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        sequences(order_by: [$orderBy], limit: $limit, offset: $offset, where: $where) {
            id
            title
            ...SequenceDetails @include(if: $withDetails)
        }
    }

    ${SEQUENCE_DETAILS}
`;

export const GET_SEQUENCES_WITH_ATS_MAPPING = gql`
    query SequencesWithAtsMapping($where: sequences_bool_exp!) {
        sequences(where: $where) {
            id
            title
            user {
                fullName
            }
            ashby_sequence_mapping {
                id
            }
            greenhouse_sequence_mapping {
                id
            }
            lever_sequence_mapping {
                id
            }
        }
    }
`;

export const GET_SEQUENCE_BY_ID = gql`
    query SequenceById($id: String!) {
        sequences_by_pk(id: $id) {
            id
            ...SequenceDetails
        }
    }

    ${SEQUENCE_DETAILS}
`;

export const GET_USER_SEQUENCES_OWNERS = gql`
    query SequencesOwners($userId: String! = "") {
        sequences(
            distinct_on: userId
            where: { _or: [{ userId: { _eq: $userId } }, { collaborators: { userId: { _eq: $userId } } }] }
        ) {
            id
            user {
                id
                fullName
            }
        }
    }
`;

export const CREATE_SEQUENCE = gql`
    mutation CreateSequence($sequence: sequences_insert_input!) {
        insert_sequences(objects: [$sequence]) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const DUPLICATE_SEQUENCE = gql`
    mutation DuplicateSequence(
        $sequence: sequences_insert_input!
        $sequenceActivity: sequence_activities_insert_input!
    ) {
        insert_sequences(objects: [$sequence]) {
            affected_rows
            returning {
                id
            }
        }
        insert_sequence_activities_one(object: $sequenceActivity) {
            sequenceId
        }
    }
`;

export const UPDATE_SEQUENCES = gql`
    mutation UpdateSequences($where: sequences_bool_exp!, $set: sequences_set_input!) {
        update_sequences(where: $where, _set: $set) {
            affected_rows
            returning {
                id
                ...SequenceDetails
            }
        }
    }

    ${SEQUENCE_DETAILS}
`;

export const UPDATE_SEQUENCE_BY_ID = gql`
    mutation UpdateSequenceById($id: String!, $set: sequences_set_input!) {
        update_sequences_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
            ...SequenceDetails
        }
    }

    ${SEQUENCE_DETAILS}
`;

export const UPDATE_SEQUENCE_AND_STAGES = gql`
    mutation UpdateSequenceAndStages(
        $sequenceId: String!
        $sequence: sequences_set_input!
        $stages: [sequence_stages_insert_input!]!
    ) {
        update_sequences_by_pk(pk_columns: { id: $sequenceId }, _set: $sequence) {
            id
            ...SequenceDetails
        }
        update_sequence_stages(
            where: { _and: { sequenceId: { _eq: $sequenceId }, isDeleted: { _eq: false } } }
            _set: { isDeleted: true }
        ) {
            affected_rows
        }
        insert_sequence_stages(objects: $stages) {
            affected_rows
        }
    }

    ${SEQUENCE_DETAILS}
`;

export const UPDATE_SEQUENCE_STAGES = gql`
    mutation UpdateSequenceStages($sequenceId: String!, $stages: [sequence_stages_insert_input!]!) {
        update_sequence_stages(
            where: { _and: { sequenceId: { _eq: $sequenceId }, isDeleted: { _eq: false } } }
            _set: { isDeleted: true }
        ) {
            affected_rows
        }
        insert_sequence_stages(objects: $stages) {
            affected_rows
        }
    }
`;

export const INSERT_TEST_EMAIL_MESSAGES = gql`
    mutation INSERT_TEST_EMAIL_MESSAGES($template: jsonb!, $sendAt: bigint!, $sendFromAccountEmail: String!) {
        insert_test_email_messages(
            objects: { template: $template, sendAt: $sendAt, sendFromAccountEmail: $sendFromAccountEmail }
        ) {
            affected_rows
        }
    }
`;

export const GET_USER_SEQUENCES = gql`
    query UserSequences($where: sequences_bool_exp!) {
        sequences(where: $where, order_by: { title: asc }) {
            id
            userId
            title
            color
            status
            createdAt
            modifiedAt
            emailPreference
            user {
                id
                fullName
            }
            stages(order_by: { stageIndex: asc }) {
                id
                bcc
                cc
                content
                numberOfDays
                replyToLastEmail
                sendTime
                sendTimeOfDay
                sendTypeOfDays
                signature
                useSignature
                subject
                stageIndex
                sendFromAccountId
                sendFromAccount: send_from_account {
                    id
                    status
                    email
                    email_account {
                        status
                        user {
                            ...UserDetails
                        }
                    }
                }
            }
        }
    }
    ${USER_DETAILS}
`;

export const GET_SEQUENCE_DETAIL_STATS_BY_TIME_PERIOD = gql`
    query SequenceDetailStatsByTimePeriod($sequence: String, $start_time: bigint, $end_time: bigint) {
        stats: get_sequence_detail_stats_by_time_period(
            args: { sequence: $sequence, start_time: $start_time, end_time: $end_time }
        ) {
            sequenceId
            stageIndex
            opens
            replies
            interested
            opensPercentage
            repliesPercentage
            interestedPercentage
        }
    }
`;

export const GET_SEQUENCES_USER_EMAIL_ACCOUNTS_USED = gql`
    query SequenceEmailAccountsUsed($userId: String!) {
        sequences {
            id
            status
            title
            stages(where: { _and: { send_from_account: { userId: { _eq: $userId } } }, isDeleted: { _eq: false } }) {
                id
            }
            user {
                fullName
            }
        }
    }
`;

export const GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS = gql`
    query SequencesOwnedByStatus($userId: String) {
        active: sequences_aggregate(where: { status: { _eq: active }, userId: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        ready: sequences_aggregate(where: { status: { _eq: ready }, userId: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        draft: sequences_aggregate(where: { status: { _eq: draft }, userId: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        paused: sequences_aggregate(where: { status: { _eq: paused }, userId: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        archived: sequences_aggregate(where: { status: { _eq: archived }, userId: { _eq: $userId } }) {
            aggregate {
                count
            }
        }
        emailDisconnected: sequences_aggregate(
            where: { status: { _eq: email_disconnected }, userId: { _eq: $userId } }
        ) {
            aggregate {
                count
            }
        }
    }
`;
