import { gql } from '@apollo/client';

import { SendFromAccountsQuery } from 'codegen/graphql';

export type SendFromAccountData = SendFromAccountsQuery['send_from_accounts'][number];

export const GET_SEND_FROM_ACCOUNTS = gql`
    query SendFromAccounts($where: send_from_accounts_bool_exp!) {
        send_from_accounts(where: $where) {
            id
            status
            email
            user {
                id
                fullName
            }
            email_account {
                status
                user {
                    id
                    fullName
                    hireflowSignature
                }
                signature
            }
        }
    }
`;

export const ADD_SEND_FROM_ACCOUNTS = gql`
    mutation AddSendFromAccounts($sendFromAccounts: [send_from_accounts_insert_input!]!) {
        insert_send_from_accounts(
            objects: $sendFromAccounts
            on_conflict: {
                constraint: send_from_accounts_userId_email_key
                update_columns: [status]
                where: { status: { _neq: approved } }
            }
        ) {
            affected_rows
            returning {
                status
                email_account {
                    email
                    user {
                        id
                        email
                        fullName
                    }
                }
            }
        }
    }
`;

export const REMOVE_SEND_FROM_ACCOUNT = gql`
    mutation RemoveSendFromAccount($id: String!) {
        update_send_from_accounts_by_pk(pk_columns: { id: $id }, _set: { status: removed }) {
            status
            email_account {
                email
                user {
                    id
                    email
                    fullName
                }
            }
            user {
                id
                fullName
            }
        }
    }
`;

export const UPDATE_SEND_FROM_ACCOUNT = gql`
    mutation UpdateSendFromAccount($id: String!, $set: send_from_accounts_set_input!) {
        update_send_from_accounts_by_pk(pk_columns: { id: $id }, _set: $set) {
            status
            email_account {
                email
                user {
                    id
                    email
                    fullName
                }
            }
            user {
                id
                fullName
            }
        }
    }
`;

export const GET_SEND_FROM_ACCOUNTS_BY_ID = gql`
    query SendFromAccountsById($id: String!) {
        send_from_accounts_by_pk(id: $id) {
            id
            userId
            email_account {
                email
                provider
            }
        }
    }
`;
