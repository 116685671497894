import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { Box } from 'shared/components/containers';
import { Button, TextField } from 'shared/components/presentational';
import { XClose } from 'shared/components/svgs';
import { useProspectPanel } from 'shared/hooks';
import { colors, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface EditNameProps {
    hidden: boolean;
    onClose: () => void;
}

const EditName: FC<EditNameProps> = (props) => {
    const { hidden, onClose } = props;
    const { prospectInfo, addOrUpdateProspect } = useProspectPanel();
    const translate = useTranslations('prospects.profile');
    const theme = useTheme();

    const profile = prospectInfo?.profile;
    const url = prospectInfo?.urls?.[0]?.url;

    const [firstNameCustomized, setFirstNameCustomized] = useState<string>('');
    const [lastNameCustomized, setLastNameCustomized] = useState<string>('');

    useEffect(() => {
        if (profile) {
            setFirstNameCustomized(profile.firstNameCustomized ?? profile.firstName);
            setLastNameCustomized(profile.lastNameCustomized ?? profile.lastName);
        }
    }, [profile]);

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastNameCustomized(event.target.value);
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstNameCustomized(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleNameSave();
        }
    };

    const handleNameSave = async () => {
        if (prospectInfo && profile && url) {
            const firstNameChanged = profile.firstName !== firstNameCustomized.trim();
            const lastNameChanged = profile.lastName !== lastNameCustomized.trim();
            const fullNameChanged =
                profile.fullName !== firstNameCustomized.trim().concat(' ').concat(lastNameCustomized.trim());
            const updatedProfile = {
                ...profile,
                firstNameCustomized: firstNameChanged ? firstNameCustomized.trim() : null,
                lastNameCustomized: lastNameChanged ? lastNameCustomized.trim() : null,
                fullNameCustomized: fullNameChanged
                    ? firstNameCustomized.trim().concat(' ').concat(lastNameCustomized.trim())
                    : null,
            };
            await addOrUpdateProspect(updatedProfile, url);
            handleCloseEditName();

            trackEvent('edit_name', {
                prospect_id: prospectInfo.id,
                value: [firstNameChanged ? 'first_name' : null, lastNameChanged ? 'middle_and_last_name' : null].filter(
                    (v) => !!v
                ),
            });
        }
    };

    const handleCloseEditName = () => {
        setFirstNameCustomized(firstNameCustomized.trim());
        setLastNameCustomized(lastNameCustomized.trim());
        onClose();
    };

    return (
        <Box
            hidden={hidden}
            css={css`
                background-color: ${colors.greens.narvik30};
                border-radius: ${spacing.space4px};
                margin-top: ${spacing.space24px};
                padding: ${spacing.space16px} ${spacing.space12px};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: ${spacing.space16px};
                `}
            >
                <Box
                    css={css`
                        padding-top: ${spacing.space4px};
                        font-size: ${fontSizes.f14};
                        color: ${theme.palette.grey[200]};
                    `}
                >
                    {translate('name-label')}
                </Box>
                <Button
                    variant="text"
                    startIcon={<XClose />}
                    onClick={handleCloseEditName}
                    css={css`
                        height: ${spacing.space24px};
                        padding-right: ${spacing.none};
                    `}
                >
                    {translate('close-button-label')}
                </Button>
            </Box>
            <Box
                css={css`
                    padding-bottom: ${spacing.space12px};
                `}
            >
                <TextField
                    value={firstNameCustomized}
                    onChange={handleFirstNameChange}
                    onKeyDown={handleKeyPress}
                    label={translate('first-name-label')}
                    css={css`
                        width: 100%;
                    `}
                    parentBackgroundColor="green"
                />
            </Box>
            <Box>
                <TextField
                    value={lastNameCustomized}
                    onChange={handleChangeLastName}
                    onKeyDown={handleKeyPress}
                    label={translate('middle-last-name-label')}
                    css={css`
                        width: 100%;
                    `}
                    parentBackgroundColor="green"
                />
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    margin-top: ${spacing.space24px};
                    margin-bottom: ${spacing.space8px};
                `}
            >
                <Button variant="contained" onClick={handleNameSave}>
                    {translate('save-button-label')}
                </Button>
            </Box>
        </Box>
    );
};

export { EditName };
