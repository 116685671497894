import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { Box } from 'shared/components/containers';
import { EllipsisText } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface TableHeaderEllipsisTextProps {
    children: ReactNode;
}

const TableHeaderEllipsisText: FC<TableHeaderEllipsisTextProps> = ({ children }) => (
    <Box
        css={css`
            min-width: 40px;
        `}
    >
        <EllipsisText>{children}</EllipsisText>
    </Box>
);

export { TableHeaderEllipsisText };
