import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import {
    SourcerOverviewStatsQuery,
    SourcerOverviewStatsQueryVariables,
    TeamAiSourcingCreditsQuery,
    TeamAiSourcingCreditsQueryVariables,
} from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { OUT_OF_AI_SOURCING_CREDITS, appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import { SOURCER_OVERVIEW_STATS } from 'shared/graphql/sourcers';
import { GET_TEAM_AI_SOURCING_CREDITS } from 'shared/graphql/teams';
import { useQueryRefresh } from 'shared/hooks';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcersOverViewBarProps {
    // this is specifically for teams who pay per AI sourcer instead of per sourcer member approved/created
    // credits don't make sense for the user in this scenario (even if we still track them on the backend)
    showCreditInfo?: boolean;
}

const OverviewBox: FC<{ label: string; value: string | number }> = ({ label, value }) => {
    const theme = useTheme();
    return (
        <Box
            css={css`
                min-width: 200px;
                height: 80px;
                background-color: ${theme.palette.common.white};
                border: 1px solid ${colors.grays.gallery};
                border-radius: 8px;

                @media (max-width: 1000px) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            `}
        >
            <Box
                css={css`
                    margin: 16px 16px 6px;
                    font-size: ${fontSizes.f12};
                    text-transform: uppercase;
                `}
            >
                {label}
            </Box>
            <Box
                css={css`
                    margin: 0px 16px 16px;
                    font-size: ${fontSizes.f24};
                    font-weight: ${fontWeights.bold};
                `}
            >
                {value}
            </Box>
        </Box>
    );
};

const SourcersOverViewBar: FC<SourcersOverViewBarProps> = ({ showCreditInfo = true }) => {
    const translate = useTranslations('sourcing.overview-bar');
    const theme = useTheme();

    const { data: overviewStats, loading: loadingOverviewStats } = useQueryRefresh<
        SourcerOverviewStatsQuery,
        SourcerOverviewStatsQueryVariables
    >(SOURCER_OVERVIEW_STATS, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: sourcingCreditsData, loading: loadingSourcingCredits } = useQueryRefresh<
        TeamAiSourcingCreditsQuery,
        TeamAiSourcingCreditsQueryVariables
    >(GET_TEAM_AI_SOURCING_CREDITS, {
        fetchPolicy: 'cache-and-network',
    });

    const contactUs = (
        <Box
            css={css`
                min-width: 200px;
                height: 80px;
                background-color: ${colors.greens.narvik};
                border: 1px solid ${colors.grays.gallery};
                border-radius: 8px;

                @media (max-width: 1000px) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            `}
        >
            <Box
                css={css`
                    margin: 16px 16px 6px;
                    font-size: ${fontSizes.f12};
                `}
            >
                {translate('need-more-credits-label')}
            </Box>
            <Box
                css={css`
                    margin: 0px 16px 16px;
                    font-size: ${fontSizes.f24};
                    font-weight: ${fontWeights.bold};
                    color: ${theme.palette.primary.main};
                `}
            >
                <Hyperlink newTab href={OUT_OF_AI_SOURCING_CREDITS}>
                    {translate.rich('contact-us-link')}
                </Hyperlink>
            </Box>
        </Box>
    );

    if (loadingOverviewStats || loadingSourcingCredits || !overviewStats || !sourcingCreditsData) return null;

    const numberOfActiveSourcers = overviewStats.sourcers_aggregate.aggregate?.count ?? 0;
    const totalAmountPerDay = overviewStats.sourcers_aggregate.aggregate?.sum?.amountPerDay ?? 0;
    // eslint-disable-next-line no-magic-numbers
    const amountPerMonth = totalAmountPerDay * 20;

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;
                gap: ${spacing.space8px};

                margin-bottom: ${spacing.space8px};
                max-width: ${appMaxWidth};
                overflow-x: scroll;

                @media (max-width: 1000px) {
                    max-width: ${appMaxSmallWidth};
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            `}
        >
            <OverviewBox label={translate('active-sourcers-label')} value={numberOfActiveSourcers} />
            {showCreditInfo && (
                <OverviewBox
                    label={translate('credit-total-label')}
                    value={
                        (sourcingCreditsData.teams ?? []).length > 0 ? sourcingCreditsData.teams[0].aiSourcerCredits : 0
                    }
                />
            )}
            {showCreditInfo && (
                <OverviewBox
                    label={translate('expected-monthly-usage-label')}
                    value={translate('credits-total-value', { number: amountPerMonth })}
                />
            )}
            {showCreditInfo && contactUs}
        </Box>
    );
};

export { SourcersOverViewBar };
