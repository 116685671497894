/* eslint-disable max-len */
import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedHireflowDarkMode: FC<SvgProps> = ({ ...rest }) => (
    <svg {...rest} width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.641771 7.13767C0.81198 5.25703 1.74806 3.51878 3.24408 2.30531C4.74011 1.09184 6.67353 0.502555 8.61902 0.66709L15.9545 1.28747L15.3127 8.37843C15.1425 10.2591 14.2064 11.9973 12.7104 13.2108C11.2144 14.4243 9.28097 15.0136 7.33548 14.849C5.38999 14.6845 3.5918 13.7796 2.33648 12.3334C1.08117 10.8873 0.471563 9.01831 0.641771 7.13767Z"
            fill="#CFF9D8"
        />
        <path
            d="M15.312 8.37888L15.9538 1.28791L2.33609 12.3336C3.27223 13.412 4.51812 14.1978 5.91624 14.5917C7.31435 14.9856 8.80189 14.9699 10.1908 14.5466C11.5797 14.1233 12.8075 13.3114 13.7191 12.2136C14.6307 11.1157 15.185 9.78125 15.312 8.37888Z"
            fill="#A9E0B4"
        />
        <path
            d="M0.655443 20.7624L1.29721 13.6715L8.63269 14.2918C10.5782 14.4564 12.3764 15.3613 13.6317 16.8074C14.887 18.2536 15.4966 20.1225 15.3264 22.0032C15.1562 23.8838 14.2201 25.6221 12.7241 26.8356C11.2281 28.049 9.29464 28.6383 7.34915 28.4738C5.40366 28.3092 3.60547 27.4044 2.35015 25.9582C1.09484 24.512 0.485235 22.6431 0.655443 20.7624Z"
            fill="#4EC7B8"
        />
        <path
            d="M0.655104 20.7625L1.29688 13.6715L12.7235 26.8353C11.6079 27.7401 10.2397 28.3048 8.79212 28.4581C7.34449 28.6113 5.88236 28.3461 4.59062 27.6961C3.29887 27.0461 2.23552 26.0405 1.53501 24.8063C0.834499 23.5721 0.528291 22.1649 0.655104 20.7625Z"
            fill="#46BBAD"
        />
        <path
            d="M21.4301 28.4735L14.0947 27.8531L14.7364 20.7622C14.9066 18.8815 15.8427 17.1433 17.3388 15.9298C18.8348 14.7163 20.7682 14.127 22.7137 14.2916C24.6592 14.4561 26.4574 15.361 27.7127 16.8071C28.968 18.2533 29.5776 20.1223 29.4074 22.0029C29.2372 23.8835 28.3011 25.6218 26.8051 26.8353C25.3091 28.0487 23.3756 28.638 21.4301 28.4735Z"
            fill="#55BC7C"
        />
        <path
            d="M21.4313 28.4736L14.0959 27.8532L27.7136 16.8074C28.6496 17.8859 29.2338 19.2084 29.3923 20.6078C29.5508 22.0071 29.2765 23.4205 28.6041 24.6692C27.9317 25.9179 26.8913 26.9458 25.6146 27.623C24.3379 28.3001 22.8821 28.5961 21.4313 28.4736Z"
            fill="#339E5C"
        />
        <path
            d="M22.7261 0.666522C24.6716 0.831056 26.4698 1.73593 27.7251 3.18209C28.9804 4.62825 29.59 6.49722 29.4198 8.37786L28.778 15.4688L21.4426 14.8484C19.4971 14.6839 17.6989 13.779 16.4436 12.3329C15.1883 10.8867 14.5786 9.01775 14.7489 7.13711C14.9191 5.25647 15.8551 3.51821 17.3512 2.30474C18.8472 1.09127 20.7806 0.501987 22.7261 0.666522Z"
            fill="#FFC657"
        />
        <path
            d="M21.4425 14.8486L28.778 15.469L17.3514 2.30522C16.2358 3.21015 15.4229 4.41451 15.0155 5.76602C14.608 7.11753 14.6242 8.55549 15.0621 9.89808C15.5 11.2407 16.3399 12.4276 17.4756 13.3088C18.6113 14.19 19.9918 14.7258 21.4425 14.8486Z"
            fill="#F7B552"
        />
        <path
            d="M15.9538 1.28791L15.312 8.37888C15.185 9.78125 14.6307 11.1157 13.7191 12.2136L2.33609 12.3336L15.9538 1.28791Z"
            fill="#A9E0B4"
        />
    </svg>
);

const HireflowDarkMode = withTheme(ThemedHireflowDarkMode);

export { HireflowDarkMode };
