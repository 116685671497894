import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { AshbyAccountByTeamIdQuery, GreenhouseAccountByTeamIdQuery, LeverAccountByTeamIdQuery } from 'codegen/graphql';
import { NewConnectionFormValues } from 'integrations/types';
import {
    AddNewConnection,
    AshbySettings,
    GreenhouseSettings,
    LeverSettings,
} from 'settings/components/presentational/ats-integrations';
import { Box } from 'shared/components/containers';
import { Button, Title } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import {
    GET_ASHBY_ACCOUNT_BY_TEAM_ID,
    GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
    GET_LEVER_ACCOUNT_BY_TEAM_ID,
} from 'shared/graphql/integrations';
import { useSession } from 'shared/hooks';
import { spacing } from 'shared/settings';

const ApisAndIntegrations = () => {
    const { session } = useSession();
    const translate = useTranslations('settings.account-settings');
    const [isAddingConnection, setIsAddingConnection] = useState<boolean>(false);

    const { data: ashbyAccount, refetch: refetchAshbyAccount } = useQuery<AshbyAccountByTeamIdQuery>(
        GET_ASHBY_ACCOUNT_BY_TEAM_ID,
        {
            variables: { teamId: session?.user.teamId },
        }
    );

    const { data: greenhouseAccount, refetch: refetchGreenhouseAccount } = useQuery<GreenhouseAccountByTeamIdQuery>(
        GET_GREENHOUSE_ACCOUNT_BY_TEAM_ID,
        {
            variables: { teamId: session?.user.teamId },
        }
    );

    const { data: leverAccount, refetch: refetchLeverAccount } = useQuery<LeverAccountByTeamIdQuery>(
        GET_LEVER_ACCOUNT_BY_TEAM_ID,
        {
            variables: { teamId: session?.user.teamId },
        }
    );

    const validationSchema = Yup.object().shape({
        atsType: Yup.string().required(translate('error-ats-type')),
    });

    const formInitialValues: NewConnectionFormValues = {
        atsType: '',
    };

    const formMethods = useForm<NewConnectionFormValues>({
        mode: 'onBlur',
        defaultValues: formInitialValues,
        resolver: yupResolver(validationSchema),
    });

    const handleToggleNewConnection = () => {
        setIsAddingConnection(!isAddingConnection);
    };

    const handleClose = () => {
        formMethods.reset();
        setIsAddingConnection(false);
    };

    return (
        <Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
            >
                <Title type="h2">{translate('apis-and-integrations')}</Title>
                <Box
                    css={css`
                        margin-bottom: ${spacing.space8px};
                    `}
                >
                    {!isAddingConnection ? (
                        <Button
                            variant="outlined"
                            onClick={handleToggleNewConnection}
                            startIcon={<Plus />}
                            disableRipple
                        >
                            {translate('add-new-connection-button-label')}
                        </Button>
                    ) : null}
                </Box>
            </Box>
            <AddNewConnection
                formMethods={formMethods}
                isAddingConnection={isAddingConnection}
                onClose={handleClose}
                ashbyConnected={!!ashbyAccount?.data[0]}
                greenhouseConnected={!!greenhouseAccount?.data[0]}
                leverConnected={!!leverAccount?.data[0]}
                refetchAshbyAccount={refetchAshbyAccount}
                refetchGreenhouseAccount={refetchGreenhouseAccount}
                refetchLeverAccount={refetchLeverAccount}
            />
            <AshbySettings integration={ashbyAccount?.data[0]} refetch={refetchAshbyAccount} />
            <GreenhouseSettings integration={greenhouseAccount?.data[0]} refetch={refetchGreenhouseAccount} />
            <LeverSettings integration={leverAccount?.data[0]} refetch={refetchLeverAccount} />
        </Box>
    );
};

export { ApisAndIntegrations };
