import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { ReactNode, useState } from 'react';

import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { CONTACT_US_URL } from 'shared/constants';
import { FC } from 'shared/types';
import { ChatWindowAlertBar, MessageInput, MessageList } from 'sourcing/components/composite';

const Link = (children: ReactNode) => (
    <Hyperlink href={CONTACT_US_URL} newTab>
        {children}
    </Hyperlink>
);

export interface ChatMessage {
    from: 'user' | 'agent';
    text: string;
}

interface ChatWindowProps {}

const ChatWindow: FC<ChatWindowProps> = () => {
    const translate = useTranslations('sourcing.request-a-sourcer-form.chat-box-specification-section');
    const [showAlertBar, setShowAlertBar] = useState(true);

    const [conversations, setConversations] = useState<ChatMessage[]>([]);

    const handleAlertBarClose = () => {
        setShowAlertBar(false);
    };

    const sendMessage = async (text?: string) => {
        if (!text) {
            return;
        }

        setConversations((prev) => [...prev, { from: 'user', text }]);
    };

    return (
        <Box
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            `}
        >
            <Box>
                <ChatWindowAlertBar showAlert={showAlertBar} onClose={handleAlertBarClose} />
            </Box>
            <Box
                css={css`
                    flex-grow: 1;
                    overflow-y: auto;
                `}
            >
                <MessageList conversations={conversations} />
            </Box>
            <Box
                css={css`
                    flex-shrink: 0;
                `}
            >
                <MessageInput onMessageSend={sendMessage} />
            </Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    font-size: 12px;
                `}
            >
                {translate.rich('disclaimer', { link: Link })}
            </Box>
        </Box>
    );
};

export { ChatWindow };
