import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';

import { Box } from 'shared/components/containers';
import { Button } from 'shared/components/presentational';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface SourcerEditBarProps {
    onDiscardChanges: () => void;
    onSaveChanges: () => void;
}

const SourcerEditBar: FC<SourcerEditBarProps> = React.forwardRef(({ onDiscardChanges, onSaveChanges }, ref) => {
    const translate = useTranslations('sourcing.details');
    const theme = useTheme();

    return (
        <Box
            css={css`
                display: flex;
                align-items: center;

                width: 960px;
                background-color: ${theme.palette.grey[200]};
                padding: 20px 25px;
                border-radius: 4px;
                box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

                color: ${theme.palette.common.white};
                font-weight: ${fontWeights.bold};
            `}
            ref={ref}
        >
            <Box
                css={css`
                    margin-right: auto;
                    font-size: ${fontSizes.f24};
                `}
            >
                {translate('edits-made')}
            </Box>
            <Box
                css={css`
                    display: flex;
                    font-size: ${fontSizes.f16};
                `}
            >
                <Button
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={onDiscardChanges}
                    disableRipple
                >
                    {translate('discard-changes')}
                </Button>
                <Button
                    variant="contained"
                    css={css`
                        color: ${theme.palette.common.white};
                    `}
                    onClick={onSaveChanges}
                    disableRipple
                >
                    {translate('save-changes')}
                </Button>
            </Box>
        </Box>
    );
});

export { SourcerEditBar };
