import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { Title } from 'shared/components/presentational';
import { FC } from 'shared/types';

interface ActionButtonsProps {
    title?: string | undefined;
    leftActionButtons?: React.ReactNode;
    rightActionButtons?: React.ReactNode;
}

const ActionButtons: FC<ActionButtonsProps> = (props) => {
    const { title, leftActionButtons, rightActionButtons } = props;

    return (
        <Box
            component="div"
            css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
            `}
        >
            <Box
                component="div"
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                {title && (
                    <Title
                        type="h1"
                        flat
                        css={css`
                            font-size: 36px;
                            line-height: 54px;
                            margin-right: 16px;
                            display: flex;
                            align-items: center;
                        `}
                    >
                        {title}
                    </Title>
                )}
                {leftActionButtons}
            </Box>

            <Box
                component="div"
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                {rightActionButtons}
            </Box>
        </Box>
    );
};

export { ActionButtons };
