import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedBellRinging04: FC<SvgProps> = ({ stroke, theme, strokeWidth = '2', ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            // eslint-disable-next-line max-len
            d="M15.6459 18.0149C16.0747 19.6153 15.125 21.2603 13.5246 21.6891C11.9242 22.118 10.2792 21.1682 9.85036 19.5678M2.05683 11.6134C1.66734 10.2152 2.07238 8.70361 3.10878 7.6875M12.1463 5.74097C12.4505 5.19367 12.5507 4.53217 12.3759 3.87957C12.0185 2.54591 10.6477 1.75445 9.314 2.11181C7.98034 2.46916 7.18888 3.84 7.54624 5.17367C7.7211 5.82627 8.13863 6.34904 8.67573 6.67091M20.319 6.72012C19.9572 5.31451 18.8506 4.20792 17.445 3.84614M17.4906 9.4454C17.1337 8.11327 16.1808 6.99931 14.8415 6.34858C13.5022 5.69784 11.8863 5.56365 10.3492 5.9755C8.81214 6.38736 7.47981 7.31154 6.64533 8.54473C5.81085 9.77792 5.54258 11.2191 5.89952 12.5512C6.4901 14.7553 6.37542 16.5137 6.00247 17.8498C5.5774 19.3727 5.36487 20.1341 5.42228 20.287C5.48798 20.4619 5.53549 20.5099 5.70973 20.5774C5.86202 20.6364 6.50179 20.465 7.78133 20.1222L19.6464 16.9429C20.9259 16.6001 21.5657 16.4286 21.6681 16.3014C21.7852 16.1558 21.8024 16.0905 21.7718 15.9061C21.7451 15.745 21.1803 15.1919 20.0508 14.0856C19.0597 13.1149 18.0812 11.6495 17.4906 9.4454Z"
            stroke={stroke ?? theme?.palette.primary.main}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const BellRinging04 = withTheme(ThemedBellRinging04);

export { BellRinging04 };
