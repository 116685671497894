import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedSettings: FC<SvgProps> = ({ stroke, width, height, theme, ...rest }) => (
    <svg
        {...rest}
        width={width ?? '21'}
        height={height ?? '22'}
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            // eslint-disable-next-line max-len
            d="M11.0005 14C12.6573 14 14.0005 12.6569 14.0005 11C14.0005 9.34315 12.6573 8 11.0005 8C9.34363 8 8.00049 9.34315 8.00049 11C8.00049 12.6569 9.34363 14 11.0005 14Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            // eslint-disable-next-line max-len
            d="M8.28957 18.3711L8.87402 19.6856C9.04776 20.0768 9.3313 20.4093 9.69024 20.6426C10.0492 20.8759 10.4681 21.0001 10.8962 21C11.3244 21.0001 11.7433 20.8759 12.1022 20.6426C12.4612 20.4093 12.7447 20.0768 12.9185 19.6856L13.5029 18.3711C13.711 17.9047 14.0609 17.5159 14.5029 17.26C14.9477 17.0034 15.4622 16.8941 15.9729 16.9478L17.4029 17.1C17.8286 17.145 18.2582 17.0656 18.6396 16.8713C19.021 16.6771 19.3379 16.3763 19.5518 16.0056C19.766 15.635 19.868 15.2103 19.8455 14.7829C19.823 14.3555 19.677 13.9438 19.4251 13.5978L18.5785 12.4344C18.277 12.0171 18.1159 11.5148 18.1185 11C18.1184 10.4866 18.281 9.98635 18.5829 9.57111L19.4296 8.40778C19.6814 8.06175 19.8275 7.65007 19.85 7.22267C19.8725 6.79528 19.7704 6.37054 19.5562 6C19.3423 5.62923 19.0255 5.32849 18.644 5.13423C18.2626 4.93997 17.833 4.86053 17.4074 4.90556L15.9774 5.05778C15.4667 5.11141 14.9521 5.00212 14.5074 4.74556C14.0645 4.48825 13.7144 4.09736 13.5074 3.62889L12.9185 2.31444C12.7447 1.92317 12.4612 1.59072 12.1022 1.3574C11.7433 1.12408 11.3244 0.99993 10.8962 1C10.4681 0.99993 10.0492 1.12408 9.69024 1.3574C9.3313 1.59072 9.04776 1.92317 8.87402 2.31444L8.28957 3.62889C8.0825 4.09736 7.73245 4.48825 7.28957 4.74556C6.84479 5.00212 6.33024 5.11141 5.81957 5.05778L4.38513 4.90556C3.95946 4.86053 3.52987 4.93997 3.14844 5.13423C2.76702 5.32849 2.45014 5.62923 2.23624 6C2.02206 6.37054 1.92002 6.79528 1.94251 7.22267C1.96499 7.65007 2.11103 8.06175 2.36291 8.40778L3.20957 9.57111C3.51151 9.98635 3.67411 10.4866 3.67402 11C3.67411 11.5134 3.51151 12.0137 3.20957 12.4289L2.36291 13.5922C2.11103 13.9382 1.96499 14.3499 1.94251 14.7773C1.92002 15.2047 2.02206 15.6295 2.23624 16C2.45036 16.3706 2.76727 16.6712 3.14864 16.8654C3.53001 17.0596 3.95949 17.1392 4.38513 17.0944L5.81513 16.9422C6.3258 16.8886 6.84034 16.9979 7.28513 17.2544C7.72966 17.511 8.08134 17.902 8.28957 18.3711Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Settings = withTheme(ThemedSettings);

export { Settings };
