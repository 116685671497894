import { gql } from '@apollo/client';

import { ContactChannelsEnum, ContactKindsEnum } from 'codegen/graphql';

export interface ContactUpdate {
    kind: ContactKindsEnum;
    value: string;
    standardizedValue: string;
    channel: ContactChannelsEnum;
    primary?: boolean;
    prospectId?: string;
    invalid: boolean;
    userMarkedInvalid: boolean;
}

export interface Contacts {
    id: string;
    kind: ContactKindsEnum;
    value: string;
    standardizedValue: string;
    channel: ContactChannelsEnum;
    primary: boolean;
    invalid: boolean;
}

export const INSERT_PROSPECT_CONTACTS = gql`
    mutation insertProspectContacts($objects: [prospect_contacts_insert_input!]!) {
        insert_prospect_contacts(objects: $objects) {
            returning {
                id
                kind
                value
                channel
                prospectId
            }
        }
    }
`;

export const DELETE_RECIPIENT_EMAIL = gql`
    mutation deleteRecipientEmail($recipientId: String, $prospectId: String, $contactId: String) {
        delete_recipient_contacts(where: { recipientId: { _eq: $recipientId } }) {
            affected_rows
        }
        set_primary_null: update_prospect_contacts(
            where: {
                _and: [{ prospectId: { _eq: $prospectId } }, { primary: { _eq: true } }, { id: { _eq: $contactId } }]
            }
            _set: { primary: null, invalid: true, userMarkedInvalid: true }
        ) {
            returning {
                id
                kind
                value
                standardizedValue
                channel
                primary
            }
        }
    }
`;

export const UPDATE_RECIPIENT_EMAIL = gql`
    mutation deleteAndUpdateProspectPrimaryEmail(
        $recipientId: String
        $prospectId: String
        $contactId: String
        $value: String
        $kind: contact_kinds_enum
    ) {
        delete_recipient_contacts(where: { recipientId: { _eq: $recipientId } }) {
            affected_rows
        }
        set_primary_null: update_prospect_contacts(
            where: { _and: [{ prospectId: { _eq: $prospectId } }, { primary: { _eq: true } }] }
            _set: { primary: null }
        ) {
            affected_rows
        }
        set_invalid: update_prospect_contacts(
            where: { id: { _eq: $contactId } }
            _set: { invalid: true, userMarkedInvalid: true }
        ) {
            affected_rows
        }
        insert_recipient_contacts_one(
            object: {
                recipientId: $recipientId
                prospect_contact: {
                    data: {
                        prospectId: $prospectId
                        kind: $kind
                        channel: email
                        value: $value
                        standardizedValue: $value
                        primary: true
                        invalid: false
                    }
                    on_conflict: {
                        constraint: prospect_contacts_prospectId_standardizedValue_channel_key
                        update_columns: [primary, kind, invalid]
                    }
                }
            }
            on_conflict: { constraint: recipient_contacts_recipientId_channel_key, update_columns: [contactId] }
        ) {
            prospect_contact {
                id
                kind
                value
                channel
                primary
                invalid
            }
        }
    }
`;

export const RECIPIENT_CONTACT_UPDATE_FOR_RESEND_SEQUENCE = gql`
    mutation RecipientContactUpdateForResendSequence(
        $recipientId: String
        $prospectId: String
        $contactId: String
        $value: String
        $kind: contact_kinds_enum
    ) {
        delete_recipient_contacts(where: { recipientId: { _eq: $recipientId } }) {
            affected_rows
        }
        set_primary_null: update_prospect_contacts(where: { id: { _eq: $contactId } }, _set: { primary: null }) {
            affected_rows
        }
        insert_recipient_contacts_one(
            object: {
                recipientId: $recipientId
                prospect_contact: {
                    data: {
                        prospectId: $prospectId
                        kind: $kind
                        channel: email
                        value: $value
                        standardizedValue: $value
                        primary: null
                        invalid: false
                    }
                    on_conflict: {
                        constraint: prospect_contacts_prospectId_standardizedValue_channel_key
                        update_columns: [kind, invalid]
                    }
                }
            }
            on_conflict: { constraint: recipient_contacts_recipientId_channel_key, update_columns: [contactId] }
        ) {
            prospect_contact {
                id
                kind
                value
                channel
                primary
                invalid
            }
        }
    }
`;

export const UPDATE_PROSPECT_CONTACTS = gql`
    mutation UpdateProspectContacts($prospectId: String, $objects: [prospect_contacts_insert_input!]!) {
        update_prospect_contacts(
            where: { _and: [{ prospectId: { _eq: $prospectId } }, { primary: { _eq: true } }] }
            _set: { primary: null }
        ) {
            affected_rows
        }
        insert_prospect_contacts(
            objects: $objects
            on_conflict: {
                constraint: prospect_contacts_prospectId_standardizedValue_channel_key
                update_columns: [primary, kind, invalid, userMarkedInvalid]
            }
        ) {
            returning {
                id
                kind
                channel
                value
                prospectId
                primary
            }
        }
    }
`;

export const CREATE_RECIPIENT_CONTACTS = gql`
    mutation CreateRecipientContacts(
        $recipientIds: [String!] = []
        $recipientContacts: [recipient_contacts_insert_input!] = {}
    ) {
        delete_recipient_contacts(where: { recipientId: { _in: $recipientIds } }) {
            affected_rows
        }
        insert_recipient_contacts(
            objects: $recipientContacts
            on_conflict: { constraint: recipient_contacts_recipientId_channel_key, update_columns: [contactId] }
        ) {
            affected_rows
        }
    }
`;
