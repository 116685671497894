import { NUM_UNKNOWN_VARIABLES_DISPLAY, getUnknownVariables } from 'hireflow-shared/utils/email-drafting-utils';
import { uniq } from 'lodash';

export interface UnknownVariablesError {
    unknownVariablesString: string;
    count?: number;
}

export const validVariables = (value?: string): boolean => {
    if (value) {
        const unknownVariables = uniq(getUnknownVariables(value));
        return unknownVariables.length === 0;
    }
    return true;
};

export const getUnknownVariablesError = (value: string) => {
    const unknownVariables = uniq(getUnknownVariables(value));
    const numUnknownVariables = unknownVariables.length;
    if (numUnknownVariables > 0) {
        const unknownVariablesString = unknownVariables.splice(0, NUM_UNKNOWN_VARIABLES_DISPLAY).join(', ');
        const errorMessage: UnknownVariablesError =
            numUnknownVariables > NUM_UNKNOWN_VARIABLES_DISPLAY
                ? { unknownVariablesString, count: numUnknownVariables - NUM_UNKNOWN_VARIABLES_DISPLAY }
                : { unknownVariablesString };
        return errorMessage;
    }
    return undefined;
};
