import { createContext, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface TitleColorUpdateHandlerProps {
    color: string | null | undefined;
    isEditable: boolean;
    handleTitleUpdate: (_title: string) => () => void;
    handleColorUpdate: (_color: string) => () => void;
}

const TitleColorUpdateHandlerContext = createContext<TitleColorUpdateHandlerProps>({} as TitleColorUpdateHandlerProps);

const TitleColorUpdateHandlerProvider: FC<TitleColorUpdateHandlerProps> = ({
    color,
    isEditable,
    handleTitleUpdate,
    handleColorUpdate,
    children,
}) => {
    const value = useMemo(
        () => ({
            color,
            isEditable,
            handleTitleUpdate,
            handleColorUpdate,
        }),
        [color, isEditable, handleTitleUpdate, handleColorUpdate]
    );

    return <TitleColorUpdateHandlerContext.Provider value={value}>{children}</TitleColorUpdateHandlerContext.Provider>;
};

const useTitleColorUpdateHandler = () => useContext(TitleColorUpdateHandlerContext);

export { TitleColorUpdateHandlerProvider, TitleColorUpdateHandlerContext, useTitleColorUpdateHandler };
