import { useMutation, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    GetUserRoleQuery,
    RecipientsQuery,
    RecipientStatusEnum,
    UpdateProspectMutation,
    UpdateRecipientsMutation,
    UpdateRecipientsMutationVariables,
    UserTeamQuery,
} from 'codegen/graphql';
import { logger } from 'logger';
import { ProspectPanel } from 'prospects';
import { PanelContext } from 'prospects/prospect-panel-provider';
import { PanelTabEnum } from 'prospects/utils';
import {
    AddToUnsubscribeListModal,
    ChangeRecipientStatusModal,
    ReportWrongEmailModal,
    ResendSequenceNewEmailModal,
    StopSequenceModal,
} from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { Popover, SubmenuButton } from 'shared/components/presentational';
import { CopyLink } from 'shared/components/svgs';
import { UPDATE_PROSPECT, GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { GET_RECIPIENTS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl, useSession, useSnackbarAlert } from 'shared/hooks';
import { RecipientSequenceStopped } from 'shared/services';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

type RecipientData = RecipientsQuery['recipients'][number];

interface RecipientListSubmenuProps {
    recipient: RecipientData;
    anchor?: HTMLButtonElement;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | undefined>>;
    prospectPanelContext: PanelContext;
}

const RecipientListSubmenu: FC<RecipientListSubmenuProps> = ({
    recipient,
    anchor,
    setAnchor,
    prospectPanelContext,
}) => {
    const theme = useTheme();
    const { session, loaded } = useSession();

    const { canEditRecipient } = useAccessControl();
    const translate = useTranslations('sequence.recipient-list-table');
    const { showSnackbarAlert } = useSnackbarAlert();

    const [showPanel, setShowPanel] = useState<boolean>(false);

    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const [showReportWrongEmailModal, setShowReportWrongEmailModal] = useState(false);
    const [showResendNewEmailModal, setShowResendNewEmailModal] = useState(false);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const [showStopSequenceModal, setShowStopSequenceModal] = useState(false);

    const { canAddToProjectFromExtension, canAddToSequenceFromExtension } = useAccessControl();

    const [updateProspect] = useMutation<UpdateProspectMutation>(UPDATE_PROSPECT, {
        refetchQueries: [
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            getOperationName(GET_RECIPIENTS) as string,
        ],
    });

    const [updateRecipients] = useMutation<UpdateRecipientsMutation, UpdateRecipientsMutationVariables>(
        UPDATE_RECIPIENTS,
        {
            refetchQueries: [getOperationName(GET_RECIPIENTS) as string],
        }
    );

    const { data, loading } = useQuery<UserTeamQuery>(GET_USER_TEAM);

    const { data: userRoleData, loading: loadingUserRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    if (!data || loading || !userRoleData || loadingUserRoleData) {
        return null;
    }

    const canAddToProjects =
        data?.teams?.[0]?.planType && userRoleData.user_roles_by_pk?.licenseType
            ? canAddToProjectFromExtension(data.teams[0].planType, userRoleData.user_roles_by_pk.licenseType)
            : false;
    const canAddToSequence =
        data?.teams?.[0]?.planType && userRoleData.user_roles_by_pk?.licenseType
            ? canAddToSequenceFromExtension(data.teams[0].planType, userRoleData.user_roles_by_pk.licenseType)
            : false;
    const canRequeueRecipient =
        canEditRecipient(recipient) && recipient.status === RecipientStatusEnum.UnableToSendMessage;

    const onPanelClose = () => {
        handleCloseSubmenuPopover();
        setShowPanel(false);
    };

    const handleCloseSubmenuPopover = () => setAnchor(undefined);

    const handleCloseChangeStatusModal = () => {
        setShowChangeStatusModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseResendNewEmailModal = () => {
        setShowResendNewEmailModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseReportWrongEmailModal = () => {
        setShowReportWrongEmailModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseAddToUnsubscribeListModal = () => {
        setShowUnsubscribeModal(false);
        handleCloseSubmenuPopover();
    };

    const handleCloseStopSequenceModal = () => {
        setShowStopSequenceModal(false);
        handleCloseSubmenuPopover();
    };

    const handleClickReportWrongEmailSubMenu = () => setShowReportWrongEmailModal(true);

    const handleClickChangeStatusSubmenuButton = () => setShowChangeStatusModal(true);

    const handleClickResendNewEmailSubmenuButton = () => setShowResendNewEmailModal(true);

    const handleClickAddToUnsubscribeList = () => setShowUnsubscribeModal(true);

    const handleClickStopSequenceForRecipientSubmenuButton = () => setShowStopSequenceModal(true);

    const handleClickShareSubmenuButton = () => {
        navigator.clipboard.writeText(getPrimaryProfileUrl(recipient.prospect.urls!));
        showSnackbarAlert({
            backgroundColor: theme.palette.grey[200],
            message: translate('link-to-prospect-copied-to-clipboard'),
            startIcon: <CopyLink />,
        });
        setAnchor(undefined);
        trackEvent('click_copy_link_to_prospect', { recipient_id: recipient.id, sequence_id: recipient.sequence.id });
    };

    const handleClickAddToAProjectOrSequenceSubmenuButton = () => {
        setAnchor(undefined);
        setShowPanel(true);
        trackEvent('click_add_to_project_or_sequence_kebab', {
            prospect_id: recipient.prospect.id,
            recipient_id: recipient.id,
            sequence_id: recipient.sequence.id,
        });
    };

    const handleMarkAsInMailSent = async () => {
        await updateProspect({
            variables: {
                id: recipient.prospectId,
                set: {
                    inMailSent: true,
                    inMailSentTime: Date.now(),
                },
            },
        });
        showSnackbarAlert({
            severity: 'success',
            message: translate('submenu.mark-as-in-mail-sent', {
                prospectName: recipient.prospect.profile?.firstNameCustomized ?? recipient.prospect.profile?.firstName,
            }),
        });
        handleCloseSubmenuPopover();
        trackEvent('click_mark_as_inmail_sent', { prospect_id: recipient.prospectId });
    };

    const handleRetryEmailingProspect = async () => {
        try {
            const recipientContactEmail = recipient.prospect.contacts.find(
                (pc) => pc.id === recipient.contacts[0]?.contactId
            )?.value;

            if (!recipientContactEmail) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('submenu.no-recipient-contact-error-alert'),
                });

                logger.error(`Failed to requeue recipient ${recipient.id}. No recipient contact exists`, { recipient });
                return;
            }

            handleCloseSubmenuPopover();

            const res = await updateRecipients({
                variables: {
                    where: {
                        id: {
                            _eq: recipient.id,
                        },
                    },
                    set: {
                        status:
                            recipient.lastStageSent > 0
                                ? RecipientStatusEnum.InProgress
                                : RecipientStatusEnum.NotStarted,
                    },
                },
            });

            if (res.data?.update_recipients?.returning.length) {
                showSnackbarAlert({
                    severity: 'success',
                    message: translate('submenu.retry-emailing-recipient-success-alert', {
                        email: recipientContactEmail,
                    }),
                });
            } else {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('submenu.retry-emailing-recipient-error-alert', {
                        email: recipientContactEmail,
                    }),
                });

                logger.error(`Error when re-queueing recipient ${recipient.id}`, { recipient });
            }
        } catch (err) {
            showSnackbarAlert({
                severity: 'error',
                message: translate('submenu.unknown-error-alert'),
            });

            logger.error(`Unknown error when re-queueing recipient ${recipient.id}`, { err, recipient });
        }
    };

    // Disable the mark as in mail sent option if the prospect has inMailSent set to true
    const disableMarkAsInMailSent =
        recipient.prospect.inMailSent !== undefined &&
        recipient.prospect.inMailSent !== null &&
        recipient.prospect.inMailSent;

    return (
        <>
            <Popover
                css={css`
                    .MuiPaper-root {
                        // !important is needed to make the transform stick.
                        transform: translate(${spacing.none}, ${spacing.space8px}) !important;
                    }
                `}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleCloseSubmenuPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box
                    css={css`
                        border-radius: 4px;
                    `}
                >
                    <SubmenuButton
                        disabled={!canAddToProjects && !canAddToSequence}
                        onClick={handleClickAddToAProjectOrSequenceSubmenuButton}
                    >
                        {translate('submenu.add-to-a-project-or-sequence-button-label')}
                    </SubmenuButton>
                    <SubmenuButton
                        onClick={handleClickChangeStatusSubmenuButton}
                        disabled={
                            !canEditRecipient(recipient) ||
                            recipient.status === RecipientStatusEnum.Unsubscribed ||
                            recipient.status === RecipientStatusEnum.MeetingBooked
                        }
                    >
                        {translate('submenu.change-status-button-label')}
                    </SubmenuButton>
                    <SubmenuButton disabled={disableMarkAsInMailSent} onClick={handleMarkAsInMailSent}>
                        {translate('submenu.mark-as-in-mail-sent-label')}
                    </SubmenuButton>
                    <SubmenuButton
                        onClick={handleClickStopSequenceForRecipientSubmenuButton}
                        disabled={!canEditRecipient(recipient) || RecipientSequenceStopped.includes(recipient.status)}
                    >
                        {translate('submenu.stop-sequence-for-prospect-button-label')}
                    </SubmenuButton>
                    <SubmenuButton
                        onClick={handleClickResendNewEmailSubmenuButton}
                        disabled={!canEditRecipient(recipient)}
                    >
                        {translate('submenu.resend-sequence-to-new-email-button-label')}
                    </SubmenuButton>
                    <SubmenuButton
                        onClick={handleClickReportWrongEmailSubMenu}
                        disabled={!canEditRecipient(recipient) || recipient.contacts.length === 0}
                    >
                        {translate('submenu.report-wrong-email-button-label')}
                    </SubmenuButton>
                    <SubmenuButton
                        onClick={handleClickAddToUnsubscribeList}
                        disabled={!canEditRecipient(recipient) || recipient.status === RecipientStatusEnum.Unsubscribed}
                    >
                        {translate('submenu.add-to-unsubscribe-list')}
                    </SubmenuButton>
                    <SubmenuButton onClick={handleClickShareSubmenuButton}>
                        {translate('submenu.shared-button-label')}
                    </SubmenuButton>
                    <SubmenuButton onClick={handleRetryEmailingProspect} disabled={!canRequeueRecipient}>
                        {translate('submenu.retry-emailing-recipient-label')}
                    </SubmenuButton>
                </Box>
            </Popover>
            <ChangeRecipientStatusModal
                open={showChangeStatusModal}
                onClose={handleCloseChangeStatusModal}
                recipients={[recipient]}
            />
            <ResendSequenceNewEmailModal
                open={showResendNewEmailModal}
                onClose={handleCloseResendNewEmailModal}
                recipients={[recipient]}
            />
            <ReportWrongEmailModal
                open={showReportWrongEmailModal}
                recipients={[recipient]}
                onClose={handleCloseReportWrongEmailModal}
            />
            <AddToUnsubscribeListModal
                open={showUnsubscribeModal}
                onClose={handleCloseAddToUnsubscribeListModal}
                recipientId={recipient.id}
                sequenceId={recipient.sequence.id}
                name={recipient.prospect.profile?.fullNameCustomized ?? recipient.prospect.profile?.fullName ?? ''}
            />
            <StopSequenceModal
                open={showStopSequenceModal}
                recipients={[recipient]}
                onClose={handleCloseStopSequenceModal}
            />
            <ProspectPanel
                isPanelOpen={showPanel}
                hideProspectPanel={onPanelClose}
                prospectContext={prospectPanelContext}
                tab={PanelTabEnum.ADD}
            />
        </>
    );
};

export type { RecipientData };
export { RecipientListSubmenu };
