import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { BaseSyntheticEvent, useCallback, useState } from 'react';

import { SequenceByIdQuery, SequenceStatusEnum } from 'codegen/graphql';
import { EmailDisconnectedAlert } from 'sequences/components/presentational';
import { PinButton } from 'sequences/components/presentational/pin-button';
import { SequenceSharingPopover } from 'sequences/components/presentational/sharing';
import { SequenceListSubmenu } from 'sequences/components/tables';
import { DetailsHeaderAts } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Button, IconButton } from 'shared/components/presentational';
import { Circle, DotsVertical } from 'shared/components/svgs';
import { appMaxSmallWidth, appMaxWidth } from 'shared/constants';
import { useAccessControl } from 'shared/hooks';
import { capitalizeFirstLetter } from 'shared/services';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { getAshbyJobLink, getGreenhouseJobLink, getLeverPostingLink } from 'shared/utils';

type SequenceByIdData = NonNullable<SequenceByIdQuery['sequences_by_pk']>;
interface DetailsHeaderProps {
    sequence: SequenceByIdData;
}

const DetailsHeader: FC<DetailsHeaderProps> = (props) => {
    const { sequence } = props;
    const { canEditSequence } = useAccessControl();
    const theme = useTheme();

    const translate = useTranslations('sequence.details');
    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleShare = (event: BaseSyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSharePopover = () => {
        setAnchorEl(null);
    };

    const getAtsLink = useCallback(() => {
        let name: string = '';
        let link: string | undefined;

        if (sequence.ashby_sequence_mapping?.id) {
            name = sequence.ashby_sequence_mapping.job?.name;
            if (sequence.ashby_sequence_mapping.job?.remoteId) {
                link = getAshbyJobLink(sequence.ashby_sequence_mapping.job.remoteId);
            }
        } else if (sequence.greenhouse_sequence_mapping?.id) {
            name = sequence.greenhouse_sequence_mapping.job?.name;
            if (sequence.greenhouse_sequence_mapping.job?.remoteId) {
                link = getGreenhouseJobLink(sequence.greenhouse_sequence_mapping.job.remoteId);
            }
        } else if (sequence.lever_sequence_mapping?.id) {
            name = sequence.lever_sequence_mapping.posting?.name;
            if (sequence.lever_sequence_mapping.posting?.remoteId) {
                link = getLeverPostingLink(sequence.lever_sequence_mapping.posting.remoteId);
            }
        }

        return <DetailsHeaderAts translate={translate} link={link} name={name} />;
    }, [sequence, translate]);

    return (
        <>
            {sequence.status === SequenceStatusEnum.EmailDisconnected && <EmailDisconnectedAlert sequence={sequence} />}
            <Box
                css={css`
                    margin-bottom: 12px;

                    max-width: ${appMaxWidth};
                    overflow-x: scroll;

                    @media (max-width: 1000px) {
                        max-width: ${appMaxSmallWidth};
                    }

                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 16px;
                    `}
                >
                    <Box
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 4px;
                            min-width: 70%;
                        `}
                    >
                        <Box
                            css={css`
                                display: flex;
                                align-items: center;
                                font-size: ${fontSizes.f14};
                                margin-top: ${spacing.none};
                            `}
                        >
                            {capitalizeFirstLetter(sequence.status)}
                            <Circle
                                fill={theme.palette.common.black}
                                css={css`
                                    margin: 0 ${spacing.space8px};
                                `}
                            />
                            {translate('owned-by')}
                            <Box
                                component="span"
                                css={css`
                                    margin-left: 3px;
                                `}
                            >
                                {sequence.user.fullName}
                            </Box>
                        </Box>

                        {getAtsLink()}
                    </Box>

                    <Box
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: ${spacing.space10px};
                        `}
                    >
                        {canEditSequence(sequence) ? null : (
                            <>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        font-weight: ${fontWeights.bold};
                                    `}
                                    component="div"
                                >
                                    {translate('view-only')}
                                </Box>
                                <Box component="div">{translate('circle-connector')}</Box>
                            </>
                        )}
                        <Box
                            component="div"
                            css={css`
                                white-space: nowrap;
                            `}
                        >
                            {translate('number-of-contributors', {
                                contributorsCount: sequence.collaborators.length + 1,
                            })}
                        </Box>
                        {canEditSequence(sequence) ? (
                            <>
                                <Box component="div">{translate('circle-connector')}</Box>
                                <Button
                                    css={css`
                                        margin: 0;
                                        padding: 0;
                                    `}
                                    onClick={handleShare}
                                >
                                    {translate('share')}
                                </Button>
                            </>
                        ) : null}
                        <PinButton sequence={sequence} />
                        <IconButton
                            css={css`
                                margin: auto 10px;
                                &:hover path {
                                    stroke: ${theme.palette.primary.light};
                                }
                            `}
                            onClick={handleSubmenuButtonClick}
                            disableRipple
                        >
                            <DotsVertical />
                        </IconButton>
                    </Box>
                    <SequenceListSubmenu sequence={sequence} anchor={submenuAnchor} setAnchor={setSubmenuAnchor} />
                    <SequenceSharingPopover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseSharePopover}
                        sequence={sequence}
                    />
                </Box>
            </Box>
        </>
    );
};

export { DetailsHeader };
