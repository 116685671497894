import { CheckVerified02, Folder, SequenceIcon, Target, LineChart } from 'shared/components/svgs';
import { appRoutes } from 'shared/settings/routes';
import { SideMenuRow } from 'shared/types';

const mainMenuRows = (translate: (key: any) => string): SideMenuRow[] => [
    {
        name: 'projects',
        icon: Folder,
        text: translate('projects'),
        href: appRoutes.projects.index(),
    },
    {
        name: 'sequences',
        icon: SequenceIcon,
        text: translate('sequences'),
        href: appRoutes.sequences.index(),
    },
    {
        name: 'sourcing',
        icon: Target,
        text: translate('sourcing'),
        href: appRoutes.sourcing.index(),
    },
];

const forYouMenuRows = (translate: (key: any) => string): SideMenuRow[] => [
    {
        name: 'reviewer',
        icon: CheckVerified02,
        text: translate('reviewer'),
        href: appRoutes.reviewer.index(),
    },
    {
        name: 'reports',
        icon: LineChart,
        text: translate('reports'),
        href: appRoutes.reports.index(),
    },
];

const menuSettings = {
    mainMenuRows,
    forYouMenuRows,
};

export { menuSettings };
