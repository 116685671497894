import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { FC } from 'shared/types';

interface AtsSyncSettingsAdvancedSpacerProps {}

const AtsSyncSettingsAdvancedSpacer: FC<AtsSyncSettingsAdvancedSpacerProps> = () => {
    const translate = useTranslations('integrations');

    return (
        <Box
            css={css`
                padding-bottom: 8px;

                font-size: 16px;
                font-weight: bold;
            `}
        >
            {translate('advanced-options')}
        </Box>
    );
};

export { AtsSyncSettingsAdvancedSpacer };
