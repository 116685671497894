import { createContext, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface SequenceStageOperationsProps {
    handleRemoveStage: (_: number) => void;
}

const SequenceStageOperationsContext = createContext<SequenceStageOperationsProps>({} as SequenceStageOperationsProps);

const SequenceStageOperationsContextProvider: FC<SequenceStageOperationsProps> = ({ handleRemoveStage, children }) => {
    const value = useMemo(
        () => ({
            handleRemoveStage,
        }),
        [handleRemoveStage]
    );

    return <SequenceStageOperationsContext.Provider value={value}>{children}</SequenceStageOperationsContext.Provider>;
};

const useSequenceStageOperations = () => useContext(SequenceStageOperationsContext);

export { SequenceStageOperationsContextProvider, SequenceStageOperationsContext, useSequenceStageOperations };
