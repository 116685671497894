import { useQuery } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import * as Yup from 'yup';

import { HireflowEnumsTeamFeaturesEnum, TeamFeaturesByTeamIdFeatureQuery } from 'codegen/graphql';
import { config } from 'config';
import { ConnectEditFormButtons } from 'integrations/components/composite/lever';
import { ConnectEditTemplate } from 'integrations/components/templates/lever';
import { handleConnectOauth } from 'integrations/services/lever';
import { CreateEditFormValues } from 'integrations/types/lever';
import { Form } from 'shared/components/form';
import { GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE } from 'shared/graphql/teams';
import { useDynamicSchemaForm, useSession, useSnackbarAlert } from 'shared/hooks';
import { useLocalStorage } from 'shared/hooks/use-local-storage';
import { createRestApiClient } from 'shared/services';
import { FC } from 'shared/types';

interface ConnectFormProps {
    onClose: () => void;
    refetch: () => void;
}

const ConnectForm: FC<ConnectFormProps> = ({ onClose, refetch }) => {
    const translate = useTranslations('integrations.lever');

    const { session, loaded: sessionLoaded } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const validationSchema = Yup.object().shape({
        apiToken: Yup.string().required(translate('error-api-token')),
    });

    const { data: sandboxTeamFeaturesData } = useQuery<TeamFeaturesByTeamIdFeatureQuery>(
        GET_TEAM_FEATURES_BY_TEAM_ID_FEATURE,
        {
            variables: {
                teamId: session?.user.teamId,
                feature: HireflowEnumsTeamFeaturesEnum.LeverSandboxEnabled,
            },
            skip: !sessionLoaded,
        }
    );

    /**
     * this is required to check if the user has chosen to permit confidential api syncs
     */
    const [_, setStoredSyncConfidential, loaded] = useLocalStorage<boolean>('storedSyncConfidential', false);

    const formInitialValues: CreateEditFormValues = {
        useOauth: false,
        syncConfidential: false,
        previousSyncConfidential: false,
        apiToken: '',
        refreshTokenExpiresAt: 0,
        defaultSourceId: '',
        invalidApiTokenDetectedAt: 0,
    };

    const formMethods = useDynamicSchemaForm<CreateEditFormValues>({
        mode: 'onBlur',
        defaultValues: formInitialValues,
        schema: validationSchema,
    });

    useEffect(() => {
        if (!formMethods.getValues('useOauth')) {
            formMethods.setSchema(
                Yup.object().shape({
                    apiToken: Yup.string().required(translate('error-api-token')),
                })
            );
        } else {
            formMethods.setSchema(Yup.object().shape({}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.getValues('useOauth')]);

    const onSubmit = async () => {
        if (!session) return;

        const values = formMethods.getValues();
        const { apiToken, useOauth, syncConfidential } = values;

        if (useOauth) {
            // check that localStorage is available, and that syncConfidential is set
            // the value set in localStorage doesn't matter, what we're checking for later is
            // just that is exists there. it will then be removed once the connection has been established
            if (loaded && formMethods.getValues('syncConfidential')) setStoredSyncConfidential(true);

            const isProduction = config.Env === 'production';
            const isSandbox: boolean = !isProduction || !!sandboxTeamFeaturesData?.data.length;

            handleConnectOauth({
                teamId: session.user.teamId,
                syncConfidential: formMethods.getValues('syncConfidential'),
                isSandbox,
            });
            return;
        }

        const restApiClient = createRestApiClient();
        const connectResponse = await restApiClient.connectLeverToken({
            apiToken,
            syncConfidential,
        });

        if (connectResponse?.success) {
            refetch();
            onClose();
            showSnackbarAlert({
                severity: 'success',
                message: translate('success-saved'),
            });
        } else {
            showSnackbarAlert({
                severity: 'error',
                message: translate('error-saved'),
            });
        }
    };

    return (
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <ConnectEditTemplate formMethods={formMethods} />
            <ConnectEditFormButtons canEdit onClose={onClose} />
        </Form>
    );
};

export { ConnectForm };
