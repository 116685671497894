import { useApolloClient, useLazyQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { SourcerMemberByIdQuery, SourcerMemberByIdQueryVariables, SourcerMemberStatusEnum } from 'codegen/graphql';
import { SelectProjectModal } from 'shared/components/modals';
import { AppFlow, SourcerMemberApprovalAction } from 'shared/enums';
import {
    GET_SOURCER_MEMBERS,
    GET_SOURCER_MEMBERS_COUNT_BY_STATUS,
    GET_SOURCER_MEMBER_BY_ID,
    SourcerMemberData,
} from 'shared/graphql/sourcer-members';
import { useCreateProjectMembers, useProspectCreator, useSession, useSnackbarAlert } from 'shared/hooks';
import { useAiSourcingCredits } from 'shared/hooks/use-ai-sourcing-credits';
import { useApproveSourcerMembers } from 'shared/hooks/use-approve-sourcer-member';
import { FC } from 'shared/types';
import { NoAISourcingCreditsModal } from 'sourcing/components/modals/no-ai-sourcing-credits';
import { ProspectAlreadyReviewedModal } from 'sourcing/components/modals/prospect-already-reviewed-modal';

interface AddMultipleSourcerMembersToProjectProps {
    creditsPerApproval: number;
    sourcerMembers: SourcerMemberData[];
    sourcerProjectId?: string;
    open: boolean;
    onClose: () => void;
    flow: SourcerMemberApprovalAction;
    checkIfSourcerMemberIsStillInReview?: boolean;
}

const AddMultipleSourcerMembersToProject: FC<AddMultipleSourcerMembersToProjectProps> = ({
    open,
    onClose,
    sourcerMembers = [],
    sourcerProjectId,
    creditsPerApproval,
    flow,
    checkIfSourcerMemberIsStillInReview = false,
}) => {
    const translate = useTranslations('sourcing.add-multiple-sourcer-members-to-project-modal');
    const { showSnackbarAlert } = useSnackbarAlert();
    const apolloClient = useApolloClient();

    const [showNoAiSourcingNotice, setShowNoAiSourcingNotice] = useState<boolean>(false);
    const [openProspectAlreadyReviewedModal, setOpenProspectAlreadyReviewedModal] = useState<boolean>(false);

    const { session } = useSession();
    const { getAiSourcingCreditsAvailable, isPayOnApproveEnabled } = useAiSourcingCredits();

    const { createProspectsFromSourcerMembers, filterProjectDuplicates } = useProspectCreator();

    const { createAndTrackProjectMembers } = useCreateProjectMembers([]);

    const refetchQueries = [
        getOperationName(GET_SOURCER_MEMBERS) as string,
        getOperationName(GET_SOURCER_MEMBERS_COUNT_BY_STATUS) as string,
    ];

    const { approveAndTrackSourcerMembers } = useApproveSourcerMembers(refetchQueries);

    const [getSourcerMemberById, { data: sourcerMemberByIdData }] = useLazyQuery<
        SourcerMemberByIdQuery,
        SourcerMemberByIdQueryVariables
    >(GET_SOURCER_MEMBER_BY_ID, {
        fetchPolicy: 'network-only',
    });

    const [loading, setLoading] = useState(false);

    const handleCloseNoAiSourcingNotice = () => {
        setShowNoAiSourcingNotice(false);
    };

    const handleAddToProject = async (project: { id: string; title: string }) => {
        if (!loading) setLoading(true);

        if (checkIfSourcerMemberIsStillInReview) {
            // when we're adding from the sourcer member card kebab menu, we want
            // to check if the sourcer member was already reviewed. If so, we want
            // to block the approve and show a modal explaining that the
            // sourcer member was already reviewed.

            // we're only checking the first sourcer member because in this case
            // we only have one sourcer member to add
            const sourcerMember = sourcerMembers[0];

            const { data } = await getSourcerMemberById({ variables: { id: sourcerMember.id } });
            const isSourcerMemberStillInReview = data?.sourcerMember?.status === SourcerMemberStatusEnum.ToReview;

            if (!isSourcerMemberStillInReview) {
                onClose();
                setOpenProspectAlreadyReviewedModal(true);
                setLoading(false);
                return;
            }
        }

        const [aiSourcingCreditsAvailable, shouldDeductCreditOnApprove] = await Promise.all([
            getAiSourcingCreditsAvailable(),
            isPayOnApproveEnabled(),
        ]);

        let sourcerMembersToAdd = sourcerMembers;

        if (shouldDeductCreditOnApprove) {
            const countOfMemberCanBeApproved = Math.floor(aiSourcingCreditsAvailable / creditsPerApproval);
            sourcerMembersToAdd =
                countOfMemberCanBeApproved < sourcerMembers.length
                    ? sourcerMembers.slice(0, countOfMemberCanBeApproved)
                    : sourcerMembers;
        }

        if (sourcerMembersToAdd.length > 0) {
            try {
                const sourcerMembersToAddIds = sourcerMembersToAdd.map((sourcerMember) => sourcerMember.id);

                const sourcerMembersWithProspect = await createProspectsFromSourcerMembers(sourcerMembersToAdd);

                const prospectsToAddToProject = await filterProjectDuplicates(
                    sourcerMembersWithProspect!.map((sourcerMember) => sourcerMember.prospect!),
                    project.id
                );

                await createAndTrackProjectMembers(
                    prospectsToAddToProject?.map((prospect) => ({
                        projectId: project.id,
                        prospectId: prospect.id,
                    })),
                    AppFlow.AiSourcing
                );

                await approveAndTrackSourcerMembers({
                    sourcerMemberIds: sourcerMembersToAddIds,
                    flow,
                    reviewerId: session?.user.id,
                });

                showSnackbarAlert({
                    severity: 'success',
                    message: translate('add-success-alert', {
                        numberOfProspects: sourcerMembersToAdd.length,
                        projectTitle: project.title,
                    }),
                });
            } catch (error) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('add-error-alert'),
                });
            } finally {
                onClose();
                setLoading(false);
            }
        } else {
            onClose();
            setLoading(false);
            setShowNoAiSourcingNotice(true);
        }
    };

    const handleProspectAlreadyAddedModalClose = () => {
        apolloClient.refetchQueries({ include: refetchQueries });
        setOpenProspectAlreadyReviewedModal(false);
    };

    const upToDateSourcerMember = sourcerMemberByIdData?.sourcerMember;
    return (
        <>
            <SelectProjectModal
                open={open}
                onClose={onClose}
                onConfirm={handleAddToProject}
                buttonLabel={translate('button-label')}
                {...(sourcerProjectId && { projectsToExcludeFromDropdown: [sourcerProjectId] })}
                loading={loading}
            />
            <NoAISourcingCreditsModal open={showNoAiSourcingNotice} onClose={handleCloseNoAiSourcingNotice} />
            {upToDateSourcerMember && (
                <ProspectAlreadyReviewedModal
                    open={openProspectAlreadyReviewedModal}
                    onClose={handleProspectAlreadyAddedModalClose}
                    sourcerMember={upToDateSourcerMember}
                />
            )}
        </>
    );
};

export { AddMultipleSourcerMembersToProject };
