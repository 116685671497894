import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';

import { trackEvent } from 'analytics';
import { Box } from 'shared/components/containers';
import { SelectChip } from 'shared/components/form';
import { Button } from 'shared/components/presentational';
import { useSnackbarAlert } from 'shared/hooks';
import { isEmailValid } from 'shared/services';
import { FC } from 'shared/types';

interface FormValues {
    users: { label: string; value: string; id: string }[];
}

interface ShareSequenceFormProps {
    userOptions: { label: string; value: string; id: string }[];
    addCollaborators: (userIds: string[]) => void;
}
const ShareSequenceForm: FC<ShareSequenceFormProps> = ({ userOptions, addCollaborators }) => {
    const { showSnackbarAlert } = useSnackbarAlert();
    const translate = useTranslations('sequence.details.sharing');

    const validationSchema = Yup.object().shape({
        users: Yup.array()
            .min(1, translate('select-by-email-or-name'))
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string().email(),
                    id: Yup.string(),
                })
            ),
    });
    const formMethods = useForm<FormValues>({
        defaultValues: { users: [] },
        resolver: yupResolver(validationSchema),
    });

    const userList = useWatch({ control: formMethods.control, name: 'users' });

    const usersState = formMethods.getFieldState(`users`, formMethods.formState);

    useEffect(() => {
        if (usersState.isDirty) {
            trackEvent('select_contributors', {
                page_name: 'sequence_details',
            });
        }
    }, [usersState.isDirty]);

    const onSubmit: SubmitHandler<FormValues> = ({ users }) => {
        addCollaborators(users.map((user) => user.id));
        formMethods.reset();
    };

    const onErrors = (errors: any) => {
        showSnackbarAlert({
            severity: 'error',
            message: errors?.users?.message,
        });
    };

    return (
        <Box
            css={css`
                display: flex;
                align-items: flex-start;
                min-height: 40px;
            `}
        >
            <SelectChip
                options={userOptions}
                name="users"
                placeholder={userList && userList.length === 0 ? translate('add-by-email-or-first-name') : ''}
                formMethods={formMethods}
                isValidNewOption={isEmailValid}
                css={css`
                    width: 100%;
                    z-index: 100;
                    margin-right: 8px;
                    .select__control {
                        min-height: 46px;
                        padding: 4px;
                    }
                    .select__multi-value {
                        margin-top: 4px;
                    }
                `}
            />

            <Button
                disableElevation
                variant="contained"
                css={css`
                    height: 44px;
                `}
                onClick={formMethods.handleSubmit(onSubmit, onErrors)}
            >
                {translate('invite-button-label')}
            </Button>
        </Box>
    );
};

export { ShareSequenceForm };
