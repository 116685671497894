import { getFieldName, getMissingVariables } from 'hireflow-shared/utils/email-drafting-utils';
import { flatten, includes, uniq } from 'lodash';

import { ProfileDetailsFragment, SequenceDetailsFragment as Sequence } from 'codegen/graphql';
import { PartialBy } from 'shared/types';

export const prospectProfileVariables = [
    'firstNameCustomized',
    'fullNameCustomized',
    'latestCompanyCustomized',
    'latestSchoolCustomized',
    'titleCustomized',
] as const;

const prospectVariables = [...prospectProfileVariables, 'customMessage'] as const;

export type ProspectVariables = typeof prospectVariables[number];

export type ProspectProfileVariables = typeof prospectProfileVariables[number];
export type ProspectProfileLike = PartialBy<ProfileDetailsFragment, 'prospectId' | 'id'>;

export function checkForProspectMissingVariables(
    prospectProfile: ProspectProfileLike,
    sequence: Sequence,
    customMessage?: string
) {
    const missingProspectVariables = findProspectMissingVariables(prospectProfile, sequence, customMessage);
    return Boolean(missingProspectVariables.length);
}

export function findProspectMissingVariables(
    prospectProfile: ProspectProfileLike,
    sequence: Sequence,
    customMessage?: string
) {
    const missingVariables = uniq(
        flatten(
            sequence.stages.map((stage) =>
                getMissingVariables(stage.content, prospectProfile, undefined, customMessage)
            )
        )
    ).flatMap((f) => (f ? [getFieldName(f)] : []));

    // We only care about variables that belong to the prospect (profile variables and custom message)
    const missingProspectVariables = missingVariables.filter((missingVariable): missingVariable is ProspectVariables =>
        includes(prospectVariables, missingVariable)
    );
    return missingProspectVariables;
}
