import { SourcerMemberReviewIntentEnum, SourcerMemberStatusEnum } from 'codegen/graphql';

export function reviewIntentToSourcerStatus(reviewIntent: SourcerMemberReviewIntentEnum) {
    switch (reviewIntent) {
        case SourcerMemberReviewIntentEnum.ToApprove: {
            return SourcerMemberStatusEnum.Accepted;
        }
        case SourcerMemberReviewIntentEnum.ToReject: {
            return SourcerMemberStatusEnum.Rejected;
        }
        case SourcerMemberReviewIntentEnum.ToSnooze: {
            // return SourcerMemberStatusEnum.Snoozed;
            // snoozed is being removed for the time being from the UI
            // if db had snoozed, it will be converted to accepted for the reviewIntent
            return SourcerMemberStatusEnum.Accepted;
        }
        default: {
            return SourcerMemberStatusEnum.Accepted;
        }
    }
}

export function sourcerStatusToReviewIntent(status?: SourcerMemberStatusEnum) {
    switch (status) {
        case SourcerMemberStatusEnum.Rejected: {
            return SourcerMemberReviewIntentEnum.ToReject;
        }
        case SourcerMemberStatusEnum.Snoozed: {
            return SourcerMemberReviewIntentEnum.ToSnooze;
        }
        case SourcerMemberStatusEnum.Accepted: {
            return SourcerMemberReviewIntentEnum.ToApprove;
        }
        default: {
            return SourcerMemberReviewIntentEnum.None;
        }
    }
}
