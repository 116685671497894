import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { UseFormReturn } from 'react-hook-form';

import { ConnectForm as AshbyConnectForm } from 'integrations/components/forms/ashby';
import { ConnectForm as GreenhouseConnectForm } from 'integrations/components/forms/greenhouse';
import { ConnectForm as LeverConnectForm } from 'integrations/components/forms/lever';
import {
    connectIntegrationOptionAshby,
    connectIntegrationOptionGreenhouse,
    connectIntegrationOptionLever,
} from 'integrations/constants';
import { connectNewAtsOptions } from 'integrations/settings';
import { NewConnectionFormValues } from 'integrations/types';
import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { Button } from 'shared/components/presentational';
import { Circle, XClose } from 'shared/components/svgs';
import { fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddNewConnectionProps {
    formMethods: UseFormReturn<NewConnectionFormValues>;
    isAddingConnection: boolean;
    onClose: () => void;
    greenhouseConnected?: boolean;
    leverConnected?: boolean;
    ashbyConnected?: boolean;
    refetchAshbyAccount: () => void;
    refetchGreenhouseAccount: () => void;
    refetchLeverAccount: () => void;
}

const AddNewConnection: FC<AddNewConnectionProps> = (props) => {
    const {
        formMethods,
        isAddingConnection,
        onClose,
        greenhouseConnected,
        leverConnected,
        ashbyConnected,
        refetchAshbyAccount,
        refetchGreenhouseAccount,
        refetchLeverAccount,
    } = props;
    const translate = useTranslations('settings.account-settings');
    const theme = useTheme();

    const getConnectNewAtsOptions = () =>
        connectNewAtsOptions().map((option) => (
            <MenuItem
                key={option.label}
                value={option.value}
                disabled={
                    (option.value === connectIntegrationOptionAshby && ashbyConnected) ||
                    (option.value === connectIntegrationOptionGreenhouse && greenhouseConnected) ||
                    (option.value === connectIntegrationOptionLever && leverConnected)
                }
            >
                {option.value === formMethods.getValues('atsType') && (
                    <Circle
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                )}
                {option.label}
            </MenuItem>
        ));

    const isAddingAshby = (): boolean => formMethods.getValues('atsType') === connectIntegrationOptionAshby;
    const isAddingGreenhouse = (): boolean => formMethods.getValues('atsType') === connectIntegrationOptionGreenhouse;
    const isAddingLever = (): boolean => formMethods.getValues('atsType') === connectIntegrationOptionLever;

    if (!isAddingConnection) return null;
    return (
        <Box
            css={css`
                background-color: ${isAddingConnection ? `${theme.palette.primary.contrastText}50` : 'transparent'};
                border-bottom: 1px solid ${theme.palette.grey[100]};
                padding: ${spacing.space24px} ${spacing.space16px};
                transition: all 0.5s ease-in-out;
            `}
        >
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-bottom: ${isAddingConnection ? spacing.space16px : spacing.space4px};
                `}
            >
                <Button
                    variant="text"
                    onClick={onClose}
                    startIcon={<XClose />}
                    disableRipple
                    css={css`
                        font-weight: ${fontWeights.semiBold};
                        padding: 0;
                    `}
                >
                    {translate('close-new-connection-button-label')}
                </Button>
            </Box>

            <Select
                css={css`
                    width: 100%;
                `}
                parentBackgroundColor="green"
                formMethods={formMethods}
                name="atsType"
                value={formMethods.getValues('atsType')}
                label={translate('ats-type')}
            >
                {getConnectNewAtsOptions()}
            </Select>

            <Box
                css={css`
                    visibility: ${isAddingAshby() ? 'visible' : 'hidden'};
                    height: ${isAddingAshby() ? '100%' : '0'};
                `}
            >
                <AshbyConnectForm onClose={onClose} refetch={refetchAshbyAccount} />
            </Box>

            <Box
                css={css`
                    visibility: ${isAddingGreenhouse() ? 'visible' : 'hidden'};
                    height: ${isAddingGreenhouse() ? '100%' : '0'};
                `}
            >
                <GreenhouseConnectForm onClose={onClose} refetch={refetchGreenhouseAccount} />
            </Box>

            <Box
                css={css`
                    visibility: ${isAddingLever() ? 'visible' : 'hidden'};
                    height: ${isAddingLever() ? '100%' : '0'};
                `}
            >
                <LeverConnectForm onClose={onClose} refetch={refetchLeverAccount} />
            </Box>
        </Box>
    );
};

export { AddNewConnection };
