import { css, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { config } from 'config';
import { getLoginLink } from 'ms-auth';
import { Button } from 'shared/components/presentational';
import { MicrosoftLogo } from 'shared/components/svgs';
import { AddEmailAccountResponse } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface AddOutlookEmailButtonProps {
    onEmailAdd: (response: AddEmailAccountResponse) => void;
}

const AddOutlookEmailButton: FC<AddOutlookEmailButtonProps> = (props) => {
    const theme = useTheme();

    const translate = useTranslations('settings.email-settings');
    const { onEmailAdd } = props;

    const [inProgress, setInProgress] = useState(false);

    const handleClick = () => {
        setInProgress(true);
        const redirectUrl = getLoginLink();
        const popupWindow = window.open(redirectUrl, translate('microsoft-login-popup-title'), 'width=600,height=800');

        if (popupWindow) {
            popupWindow.focus();
        }
    };

    const handleMessage = (event: MessageEvent) => {
        const { data, origin } = event;
        if (origin === config.PublicPath && data) {
            const emailAddResponse: AddEmailAccountResponse = data as AddEmailAccountResponse;
            if (emailAddResponse && emailAddResponse.data && emailAddResponse.data.emailAccount) {
                onEmailAdd(data);
                setInProgress(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button
            disabled={inProgress}
            onClick={handleClick}
            css={css`
                color: ${theme.palette.grey[200]};
                font-size: ${fontSizes.f14};
                border: 1px solid ${theme.palette.grey[100]};
                border-radius: 2px;
                background-color: ${theme.palette.common.white};
                padding: ${spacing.space8px} ${spacing.space32px} ${spacing.space8px} ${spacing.space16px};

                &:hover {
                    background-color: ${theme.palette.grey[300]};
                }
            `}
            startIcon={
                <MicrosoftLogo
                    css={css`
                        margin-right: ${spacing.space8px};
                    `}
                />
            }
        >
            {translate('sign-in-with-microsoft-button-label')}
        </Button>
    );
};
export { AddOutlookEmailButton };
