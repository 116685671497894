import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { SourcerStatusEnum, SourcersByStatusQuery, SourcersByStatusQueryVariables } from 'codegen/graphql';
import { ActionButtons } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { TabWithCount, Tabs, TabsContainer } from 'shared/components/presentational';
import { GET_SOURCERS_COUNT_BY_STATUS } from 'shared/graphql/sourcers';
import { useQueryRefresh } from 'shared/hooks';
import { FC } from 'shared/types';
import { CreateNewSourcerButton } from 'sourcing/components/presentational';
import { SourcingListMenu } from 'sourcing/components/tables';
import { SourcerTab } from 'sourcing/types';

interface SourcerListTabsProps {
    selectedTab: SourcerTab;
    onTabChange: (_: any, newValue: string) => void;
}

const SourcerListTabs: FC<SourcerListTabsProps> = (props) => {
    const { selectedTab, onTabChange } = props;
    const translate = useTranslations('sourcing');

    const { data } = useQueryRefresh<SourcersByStatusQuery, SourcersByStatusQueryVariables>(
        GET_SOURCERS_COUNT_BY_STATUS,
        {
            fetchPolicy: 'cache-and-network',
        }
    );

    const activeCount = data ? data.active.aggregate!.count + data.draft.aggregate!.count : 0;
    const archivedCount = data ? data.archived.aggregate!.count : 0;
    const draftCount = data ? data.draft.aggregate!.count : 0;
    const pausedCount = data ? data.paused.aggregate!.count : 0;
    const allCount = activeCount + draftCount + pausedCount + archivedCount;

    return (
        <TabsContainer>
            <Tabs value={selectedTab} onChange={onTabChange} textColor="primary" indicatorColor="primary">
                <TabWithCount
                    value={SourcerStatusEnum.Active}
                    active={selectedTab === SourcerStatusEnum.Active}
                    label={translate('active')}
                    count={activeCount}
                />
                <TabWithCount
                    value={SourcerStatusEnum.Draft}
                    active={selectedTab === SourcerStatusEnum.Draft}
                    label={translate('draft')}
                    count={draftCount}
                />
                <TabWithCount
                    value={SourcerStatusEnum.Paused}
                    active={selectedTab === SourcerStatusEnum.Paused}
                    label={translate('paused')}
                    count={pausedCount}
                />
                <TabWithCount
                    value={SourcerStatusEnum.Archived}
                    active={selectedTab === SourcerStatusEnum.Archived}
                    label={translate('archived')}
                    count={archivedCount}
                />
                <TabWithCount value="all" active={selectedTab === 'all'} label={translate('all')} count={allCount} />
            </Tabs>

            <ActionButtons
                rightActionButtons={
                    <Box
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <CreateNewSourcerButton />
                        <SourcingListMenu />
                    </Box>
                }
            />
        </TabsContainer>
    );
};

export { SourcerListTabs };
