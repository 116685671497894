import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';

import { Box } from 'shared/components/containers';
import { Checkbox } from 'shared/components/presentational';
import { TableCell } from 'shared/components/table/table-cell';
import { fontSizes } from 'shared/settings';
import { FC } from 'shared/types';

interface CheckboxColorTitleProps {
    color: string;
    title: string;
    showCheckbox?: boolean;
    isChecked: boolean;
    handleChecked: (event: React.MouseEvent<HTMLButtonElement>) => void;
    atsJobLink?: EmotionJSX.Element | null;
    children: React.ReactNode;
}

const CheckboxColorTitle: FC<CheckboxColorTitleProps> = (props) => {
    const { color, title, showCheckbox = true, isChecked, handleChecked, atsJobLink, children } = props;

    const theme = useTheme();

    return (
        <TableCell component="th" scope="row">
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                    ${!showCheckbox && 'padding-left: 40px;'}
                `}
            >
                {showCheckbox && (
                    <Checkbox
                        css={css`
                            margin-right: 8px;
                        `}
                        checked={isChecked}
                        onClick={handleChecked}
                    />
                )}
                <Box
                    css={css`
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        width: 100%;
                        gap: 8px;
                    `}
                >
                    <Box
                        css={css`
                            background-color: ${color};
                            width: 12px;
                            min-width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            position: relative;
                            top: 4px;
                        `}
                    />
                    <Box>
                        <Box
                            css={css`
                                white-space: nowrap;
                                overflow: hidden;
                                max-width: 250px;
                                text-overflow: ellipsis;
                            `}
                            title={title}
                        >
                            {title}
                        </Box>
                        <Box
                            component="span"
                            css={css`
                                font-size: ${fontSizes.f12};
                                color: ${theme.palette.grey[200]};
                            `}
                        >
                            {children}
                        </Box>
                    </Box>
                </Box>
                {atsJobLink && <Box onClick={(e) => e.stopPropagation()}>{atsJobLink}</Box>}
            </Box>
        </TableCell>
    );
};

export { CheckboxColorTitle };
