import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { PresetDateRange, DateSelectorWithPresets } from 'shared/components/presentational';
import { HorizontalBarChart02 } from 'shared/components/svgs';
import { colors, spacing } from 'shared/settings';
import { FC } from 'shared/types';

export type DateSelectorGroupByOption = 'day' | 'week' | 'month' | 'quarter' | 'all-time';

interface DateSelectorWithGroupingProps {
    allTimeStartDate?: DateTime;
    defaultGroupBy?: DateSelectorGroupByOption;
    onChangeFromDate?: (date: DateTime) => void;
    onChangeToDate?: (date: DateTime) => void;
    onChangeDatePreset?: (preset: PresetDateRange['value']) => void;
    onChangeGroupBy?: (grouping: DateSelectorGroupByOption) => void;
}

const DateSelectorWithGrouping: FC<DateSelectorWithGroupingProps> = (props) => {
    const { allTimeStartDate, defaultGroupBy, onChangeFromDate, onChangeToDate, onChangeDatePreset, onChangeGroupBy } =
        props;

    const translate = useTranslations('shared.date-selector-with-grouping');

    const [selectedGroupBy, setSelectedGroupBy] = useState<DateSelectorGroupByOption>(defaultGroupBy || 'day');
    const [selectedPreset, setSelectedPreset] = useState<PresetDateRange['value']>('lastWeek');

    useEffect(() => {
        onChangeGroupBy?.(selectedGroupBy);
    }, [onChangeGroupBy, selectedGroupBy]);

    useEffect(() => {
        onChangeDatePreset?.(selectedPreset);
    }, [onChangeDatePreset, selectedPreset]);

    const handleChangeGroupBy = (event: SelectChangeEvent<unknown>) => {
        setSelectedGroupBy(event.target.value as DateSelectorGroupByOption);
    };

    const handleChangePreset = (preset: PresetDateRange['value']) => {
        if (preset === 'allTime') setSelectedGroupBy('all-time');
        setSelectedPreset(preset);
    };

    const showGroupByDay = !['lastYear', 'yearToDate', 'allTime'].includes(selectedPreset);
    const showGroupByMonth = !['lastWeek'].includes(selectedPreset);
    const showGroupByQuarter = !['lastWeek', 'lastMonth', 'monthToDate'].includes(selectedPreset);
    const showGroupByAllTime = selectedPreset === 'allTime';

    if (!showGroupByDay && selectedGroupBy === 'day') setSelectedGroupBy('week');
    if (!showGroupByMonth && selectedGroupBy === 'month') setSelectedGroupBy('week');
    if (!showGroupByQuarter && selectedGroupBy === 'quarter') setSelectedGroupBy('week');
    if (!showGroupByAllTime && selectedGroupBy === 'all-time') setSelectedGroupBy('week');

    return (
        <Box
            css={css`
                width: 100%;

                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: ${spacing.space8px};
                gap: ${spacing.space8px};
            `}
        >
            <DateSelectorWithPresets
                allTimeStartDate={allTimeStartDate}
                onChangeFromDate={onChangeFromDate}
                onChangeToDate={onChangeToDate}
                onChangeDatePreset={handleChangePreset}
            />
            <Box
                css={css`
                    padding: 0 ${spacing.space8px};

                    display: flex;
                    align-items: center;
                    gap: ${spacing.space16px};
                `}
            >
                <HorizontalBarChart02
                    css={css`
                        transform: rotate(90deg);
                    `}
                />
                <Select
                    css={css`
                        width: 170px;
                        margin-bottom: 0;

                        .MuiOutlinedInput-root {
                            background-color: ${colors.neutrals.white};
                        }

                        .MuiOutlinedInput-input {
                            padding-top: 21px;
                            padding-bottom: 12px;
                        }
                    `}
                    name="grouping-selector"
                    value={selectedGroupBy}
                    onChange={handleChangeGroupBy}
                    disabled={selectedPreset === 'allTime'}
                >
                    {showGroupByDay && <MenuItem value="day">{translate('day')}</MenuItem>}
                    <MenuItem value="week">{translate('week')}</MenuItem>
                    {showGroupByMonth && <MenuItem value="month">{translate('month')}</MenuItem>}
                    {showGroupByQuarter && <MenuItem value="quarter">{translate('quarter')}</MenuItem>}
                    {showGroupByAllTime && <MenuItem value="all-time">{translate('all-time')}</MenuItem>}
                </Select>
            </Box>
        </Box>
    );
};

export { DateSelectorWithGrouping };
