import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    CreateRecipientResponseMutation,
    CreateRecipientResponseMutationVariables,
    RecipientResponseMethodTypesEnum,
    RecipientResponseSentimentSourceEnum,
    RecipientResponseSentimentTypesEnum,
    RecipientStatusEnum,
    UpdateRecipientsMutation,
    UpdateRecipientsMutationVariables,
} from 'codegen/graphql';
import { ChooseSequenceModal } from 'sequences/components/modals';
import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import { Button, Modal, ModalCloseButton, Radio, RadioGroup, Title } from 'shared/components/presentational';
import { GET_PROSPECT_PANEL_INFO } from 'shared/graphql/prospects';
import { GET_RECIPIENT_ACTIVITIES } from 'shared/graphql/recipient-activities';
import { CREATE_RECIPIENT_RESPONSE, GET_RECIPIENTS, UPDATE_RECIPIENTS } from 'shared/graphql/recipients';
import { useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

export type StopSequenceDecision =
    | 'stop-sequence-only'
    | 'stop-sequence-replied-interested'
    | 'stop-sequence-replied-not-interested';

interface RecipientDetailsForStopSequenceModal {
    id: string;
    status: RecipientStatusEnum;
    sequence: { id: string; title: string };
    prospect: {
        profile?: {
            fullName: string;
            fullNameCustomized?: string | null;
        } | null;
    };
}

interface StopSequenceModalProps {
    open: boolean;
    recipients: RecipientDetailsForStopSequenceModal[];
    onClose: () => void;
}

const StopSequenceModal: FC<StopSequenceModalProps> = (props) => {
    const { open, recipients, onClose } = props;
    const { showSnackbarAlert } = useSnackbarAlert();
    const translate = useTranslations('sequence.recipient-list-table');

    const [selectedRecipient, setSelectedRecipient] = useState<RecipientDetailsForStopSequenceModal | undefined>(
        recipients.length === 1 ? recipients[0] : undefined
    );

    const [selectedSequence, setSelectedSequence] = useState<string | undefined>();
    const [decision, setDecision] = useState<StopSequenceDecision>('stop-sequence-only');

    const [createRecipientResponse] = useMutation<
        CreateRecipientResponseMutation,
        CreateRecipientResponseMutationVariables
    >(CREATE_RECIPIENT_RESPONSE, {
        refetchQueries: [
            getOperationName(GET_RECIPIENTS) as string,
            getOperationName(GET_RECIPIENT_ACTIVITIES) as string,
            getOperationName(GET_PROSPECT_PANEL_INFO) as string,
        ],
    });

    const [updateRecipients] = useMutation<UpdateRecipientsMutation, UpdateRecipientsMutationVariables>(
        UPDATE_RECIPIENTS,
        {
            refetchQueries: [
                getOperationName(GET_RECIPIENTS) as string,
                getOperationName(GET_RECIPIENT_ACTIVITIES) as string,
                getOperationName(GET_PROSPECT_PANEL_INFO) as string,
            ],
        }
    );

    const handleSequenceChange = (value: string) => {
        setSelectedSequence(value);
    };

    const handleConfirmSequence = () => {
        setSelectedRecipient(recipients.find((recipient) => recipient.sequence.id === selectedSequence));
    };

    const handleClose = () => {
        setSelectedRecipient(recipients.length === 1 ? recipients[0] : undefined);
        setSelectedSequence(undefined);
        onClose();
    };

    const handleConfirmRemove = async () => {
        if (!selectedRecipient) {
            return;
        }

        const status =
            decision === 'stop-sequence-only' ? RecipientStatusEnum.ManuallyExited : RecipientStatusEnum.Responded;
        const recipientResponse =
            decision === 'stop-sequence-replied-interested'
                ? {
                      recipientId: selectedRecipient.id,
                      method: RecipientResponseMethodTypesEnum.UserMarkedResponded,
                      sentiment: RecipientResponseSentimentTypesEnum.Interested,
                      sentimentSource: RecipientResponseSentimentSourceEnum.User,
                  }
                : decision === 'stop-sequence-replied-not-interested'
                ? {
                      recipientId: selectedRecipient.id,
                      method: RecipientResponseMethodTypesEnum.UserMarkedResponded,
                      sentiment: RecipientResponseSentimentTypesEnum.NotInterested,
                      sentimentSource: RecipientResponseSentimentSourceEnum.User,
                  }
                : {};
        const manuallyExitedAt = decision === 'stop-sequence-only' ? { manuallyExitedAt: Date.now() } : {};
        const respondedAt = decision !== 'stop-sequence-only' ? { respondedAt: Date.now() } : {};

        await updateRecipients({
            variables: {
                where: {
                    id: { _eq: selectedRecipient.id },
                },
                set: {
                    status,
                    ...respondedAt,
                    ...manuallyExitedAt,
                },
            },
        });

        if (decision !== 'stop-sequence-only') {
            await createRecipientResponse({
                variables: {
                    insertData: {
                        ...recipientResponse,
                    },
                },
            });
        }

        showSnackbarAlert({
            message: translate('stop-sequence-for-recipient-modal.success-alert', {
                sequenceName: selectedRecipient.sequence.title,
                prospectName:
                    selectedRecipient.prospect.profile?.fullNameCustomized ??
                    selectedRecipient.prospect.profile?.fullName,
            }),
        });
        handleClose();
        trackEvent('click_stop_sequence_for_this_prospect', {
            sequence_id: selectedRecipient.sequence.id,
            recipient_id: selectedRecipient.id,
        });
    };

    const handleDecisionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDecision(event.target.value as StopSequenceDecision);
    };

    return selectedRecipient ? (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                        margin-bottom: ${spacing.space16px};
                    `}
                >
                    {translate('stop-sequence-for-recipient-modal.header', {
                        name:
                            selectedRecipient.prospect.profile?.fullNameCustomized ??
                            selectedRecipient.prospect.profile?.fullName,
                    })}
                </Title>
                <Box>{translate('stop-sequence-for-recipient-modal.description')}</Box>
                <Box
                    css={css`
                        margin: 20px 0px;
                    `}
                >
                    <RadioGroup onChange={handleDecisionChange} value={decision}>
                        <FormControlLabel
                            value="stop-sequence-only"
                            control={<Radio />}
                            label={translate('stop-sequence-for-recipient-modal.stop-sequence-only-option')}
                        />
                        <FormControlLabel
                            value="stop-sequence-replied-interested"
                            control={<Radio />}
                            label={translate('stop-sequence-for-recipient-modal.stop-sequence-replied-interested')}
                        />
                        <FormControlLabel
                            value="stop-sequence-replied-not-interested"
                            control={<Radio />}
                            label={translate('stop-sequence-for-recipient-modal.stop-sequence-replied-not-interested')}
                        />
                    </RadioGroup>
                </Box>
                <Box
                    css={css`
                        display: flex;
                        margin-top: ${spacing.space16px};
                        justify-content: flex-end;
                        gap: ${spacing.space10px};
                    `}
                >
                    <Button onClick={handleClose} variant="outlined" color="inherit">
                        {translate('stop-sequence-for-recipient-modal.cancel-button-label')}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleConfirmRemove}>
                        {translate('stop-sequence-for-recipient-modal.stop-sequence-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    ) : (
        <ChooseSequenceModal
            open={open}
            handleClose={handleClose}
            recipients={recipients}
            selectedSequence={selectedSequence}
            handleSequenceChange={handleSequenceChange}
            handleConfirmSequence={handleConfirmSequence}
        />
    );
};

export { StopSequenceModal };
