import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { kebabCase } from 'lodash';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { trackEvent } from 'analytics';
import {
    CreateSourcerNotesMutation,
    CreateSourcerNotesMutationVariables,
    SourcerStatusEnum,
    UpdateSourcersMutation,
    UpdateSourcersMutationVariables,
} from 'codegen/graphql';
import { logger } from 'logger';
import { Box } from 'shared/components/containers';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import {
    CREATE_SOURCER_NOTES,
    GET_SOURCERS,
    GET_SOURCERS_COUNT_BY_STATUS,
    UPDATE_SOURCERS,
} from 'shared/graphql/sourcers';
import { useSession, useSnackbarAlert } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC, SnakeToKebabCase } from 'shared/types';
import { PauseSourcerModalReasons } from 'sourcing/components/modals';
import { SourcerPauseReasonsEnum } from 'sourcing/types';

interface ArchiveSourcersModalProps {
    open: boolean;
    onClose: () => void;
    sourcerIds: string[];
}

const ArchiveSourcersModal: FC<ArchiveSourcersModalProps> = ({ open, onClose, sourcerIds }) => {
    const translate = useTranslations('sourcing.modals.pause-archive-modal');
    const { session } = useSession();
    const { showSnackbarAlert } = useSnackbarAlert();

    const [pauseReasons, setPauseReasons] = useState<SourcerPauseReasonsEnum[]>([]);
    const [notes, setNotes] = useState<string>('');

    const [updateSourcers] = useMutation<UpdateSourcersMutation, UpdateSourcersMutationVariables>(UPDATE_SOURCERS, {
        refetchQueries: [
            getOperationName(GET_SOURCERS) as string,
            getOperationName(GET_SOURCERS_COUNT_BY_STATUS) as string,
        ],
    });

    const [createSourcerNotes] = useMutation<CreateSourcerNotesMutation, CreateSourcerNotesMutationVariables>(
        CREATE_SOURCER_NOTES
    );

    const handlePauseReasonsChange = (newPauseReasons: SourcerPauseReasonsEnum[]) => {
        setPauseReasons(newPauseReasons);
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    };

    const handleArchiveSourcer = async () => {
        try {
            const res = await updateSourcers({
                variables: {
                    // target only not archived sourcers
                    where: { id: { _in: sourcerIds }, status: { _neq: SourcerStatusEnum.Archived } },
                    set: { status: SourcerStatusEnum.Archived },
                },
            });

            const archivedSourcerIds = res.data?.update_sourcers?.returning.map((a) => a.id) ?? [];
            const logLikeNote = `${session?.user.email} marked this sourcer as archived`;
            const feedback = `"${pauseReasons
                .map((reason) =>
                    translate(`pause-modal-reasons-${kebabCase(reason) as SnakeToKebabCase<SourcerPauseReasonsEnum>}`)
                )
                .join('; ')}; ${notes}"`;
            const note =
                pauseReasons.length > 0 || notes
                    ? `${logLikeNote} and attached the following feedback: ${feedback}`
                    : `${logLikeNote}.`;
            await createSourcerNotes({
                variables: {
                    notes: archivedSourcerIds.map((sourcerId) => ({ sourcerId, note })),
                },
            });

            const archivedCount = archivedSourcerIds.length;
            const notArchivedCount = sourcerIds.length - archivedCount;

            showSnackbarAlert({
                severity: 'success',
                message:
                    archivedCount === 0
                        ? translate('archive-success-0-archived')
                        : translate('archive-success', { archivedCount, notArchivedCount }),
            });
            handleClose();

            for (const sourcerId of archivedSourcerIds) {
                trackEvent('click_archive', { sourcer_id: sourcerId, value: 'sourcer' });
            }
        } catch (error: any) {
            showSnackbarAlert({ severity: 'error', message: translate('archive-error') });
            logger.error(error);
        }
    };

    const handleClose = () => {
        setPauseReasons([]);
        setNotes('');
        onClose();
    };

    return (
        <Modal width={560} open={open} onClose={handleClose}>
            <Box
                css={css`
                    display: flex;
                    flex-flow: column nowrap;
                    gap: ${spacing.space12px};
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    css={css`
                        margin-bottom: ${spacing.none};
                        font-size: ${fontSizes.f24};
                        max-width: 420px;
                    `}
                    type="h3"
                >
                    {translate('archive-many-modal-header')}
                </Title>
                <Box>{translate('archive-many-modal-description')}</Box>
                <PauseSourcerModalReasons
                    title={translate('archive-many-modal-reasons-title')}
                    pauseReasons={pauseReasons}
                    onPauseReasonsChange={handlePauseReasonsChange}
                    notes={notes}
                    onNotesChange={handleNotesChange}
                />

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        padding: ${spacing.space16px} 0px 0px;
                        gap: ${spacing.space16px};
                    `}
                >
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                        {translate('modal-cancel-button-label')}
                    </Button>
                    <Button variant="contained" onClick={handleArchiveSourcer}>
                        {translate('archive-modal-archive-now-button-label')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export { ArchiveSourcersModal };
