import { css } from '@emotion/react';
import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';
import { useTranslations } from 'next-intl';
import { ReactNode, useCallback, useState } from 'react';

import { ProspectCardExperienceRow } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Button, NoSsr } from 'shared/components/presentational';
import { Chevron } from 'shared/components/svgs';
import { calculateSumOfWorkExperiences, isExperienceValid } from 'shared/services';
import { fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface Experience {
    id: string;
    title: string;
    companyName?: string | null;
    companyLogo?: string | null;
    startDateMonth?: number | null;
    startDateYear?: number | null;
    endDateMonth?: number | null;
    endDateYear?: number | null;
    current: boolean;
}

interface ProspectCardExperienceProps {
    experiences: Experience[];
}

const MAX_EXPERIENCES = 3;

const ProspectCardExperience: FC<ProspectCardExperienceProps> = ({ experiences }) => {
    const translate = useTranslations('shared.prospect-card.work-table');
    const theme = useTheme();
    const [showAll, setShowAll] = useState(false);

    const validExperiences = experiences.filter((exp) => isExperienceValid(exp));

    const numberOfDifferentOrganizations = uniqBy(
        validExperiences.filter((exp) => Boolean(exp.companyName)),
        'companyName'
    ).length;

    const mostRecentExperiences = [...validExperiences].slice(0, MAX_EXPERIENCES);
    const visibleExperiences = showAll ? validExperiences : mostRecentExperiences;
    const showButton = validExperiences.length > MAX_EXPERIENCES && !showAll;

    const handleShowAllExperiences = () => {
        setShowAll(true);
    };

    const getSumOfWorkExperiences = useCallback(
        () => calculateSumOfWorkExperiences(validExperiences),
        [validExperiences]
    );

    return (
        <TableContainer
            css={css`
                padding: 0px 24px 0px 24px;
            `}
        >
            <Table>
                <TableBody>
                    <TableRow
                        css={css`
                            .MuiTableCell-root {
                                font-size: ${fontSizes.f16};
                                color: ${theme.palette.common.black};
                                border: 0;
                                padding: 0;
                            }
                        `}
                    >
                        <TableCell
                            component="th"
                            scope="row"
                            width="120px"
                            css={css`
                                vertical-align: top;
                                min-width: 130px;
                            `}
                            // eslint-disable-next-line no-magic-numbers
                            rowSpan={showButton ? visibleExperiences.length + 2 : visibleExperiences.length + 1}
                        >
                            {translate.rich('header', { bold: Bold })}
                        </TableCell>
                        <TableCell>
                            {/* Removing the years of experience because the calculation is not accurate
                            <NoSsr>
                                {translate('summary', {
                                    organizations: numberOfDifferentOrganizations,
                                    yearsOfExperience: Math.round(getSumOfWorkExperiences().as('years')),
                                })}
                            </NoSsr>
                            */}
                        </TableCell>
                    </TableRow>

                    {visibleExperiences.map((exp, index) => (
                        <ProspectCardExperienceRow
                            key={exp.id}
                            exp={exp}
                            disableBorder={index === visibleExperiences.length - 1}
                        />
                    ))}

                    {showButton && (
                        <TableRow
                            css={css`
                                .MuiTableCell-root {
                                    border: 0;
                                    padding: 0;
                                }
                            `}
                        >
                            <TableCell>
                                <Button
                                    css={css`
                                        padding: 0;
                                        height: 28px;
                                        font-weight: ${fontWeights.semiBold};
                                    `}
                                    startIcon={<Chevron />}
                                    variant="text"
                                    disableRipple
                                    onClick={handleShowAllExperiences}
                                >
                                    {translate('show-more-button', {
                                        jobCount: validExperiences.length - MAX_EXPERIENCES,
                                    })}
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export { ProspectCardExperience };
export { type Experience };

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);
