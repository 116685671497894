import { css, IconButton, useTheme } from '@mui/material';
import { formatEmail } from 'hireflow-shared/lib/emails/email-utils';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { ContactChannelsEnum, ContactKindsEnum, ProspectContacts, RecipientStatusEnum } from 'codegen/graphql';
import { ProspectPrimaryChangeModal } from 'prospects';
import { Box } from 'shared/components/containers';
import { Select, SelectOption } from 'shared/components/form';
import { Button, EditCloseButton, TextField } from 'shared/components/presentational';
import { MinusCircle, Plus } from 'shared/components/svgs';
import { ContactUpdate } from 'shared/graphql/contacts';
import { ProspectRecipientData } from 'shared/graphql/recipients';
import { useProspectPanel, useSession } from 'shared/hooks';
import { isEmailValid, StatusEligibleForContactUpdate } from 'shared/services';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';
import { ProspectContact } from 'shared/types/prospect';

interface ProspectMultipleEmailProps {
    anchor: HTMLElement;
    prospectId: string;
    contacts: ProspectContact[];
}

const ProspectMultipleEmail: FC<ProspectMultipleEmailProps> = (props) => {
    const { anchor, contacts, prospectId } = props;
    const theme = useTheme();

    const { recipients, addProspectContact } = useProspectPanel();
    const { session } = useSession();

    const translate = useTranslations('prospects.tabs.profile-tab');
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);

    const [managedEmails, setManagedEmails] = useState<ProspectContact[]>(contacts);
    const [newEmails, setNewEmails] = useState<{ email: string; isValid: boolean }[]>([]);

    const [showPrimaryChangedPrompt, setShowPrimaryChangedPrompt] = useState<boolean>(false);
    const [affectedRecipients, setAffectedRecipients] = useState<ProspectRecipientData[]>();

    useEffect(() => {
        const oldPrimaryEmail = contacts.find((c) => c.primary);
        const newPrimaryEmail = managedEmails.find((m) => m.primary);

        if (oldPrimaryEmail?.id !== newPrimaryEmail?.id) {
            const affected = recipients?.filter(
                (f) =>
                    [RecipientStatusEnum.InProgress, RecipientStatusEnum.NotStarted].includes(f.status) &&
                    f.contacts[0]?.contactId !== newPrimaryEmail?.id &&
                    f.user.id === session?.user.id
            );
            setAffectedRecipients(affected);
        } else {
            setAffectedRecipients([]);
        }
    }, [managedEmails, contacts, recipients, session]);

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            setManagedEmails(contacts);
        }
    }, [editing, contacts]);

    const staticEmailOptions = [...managedEmails]
        .sort((a, b) => (a.primary === b.primary ? 0 : a.primary ? -1 : 1))
        .map((e) =>
            e.primary && !e.invalid ? (
                <Box key={'box-'.concat(e.value)}>
                    {translate.rich('email-primary', {
                        email: e.value,
                    })}
                </Box>
            ) : (
                <Box key={'box-'.concat(e.value)}>{e.value}</Box>
            )
        );

    const reset = () => {
        setNewEmails([]);
        setEditing(false);
        setAffectedRecipients(undefined);
    };

    const handleCloseEditEmail = () => {
        reset();
    };

    const handleEmailRemove = (email: string) => {
        let updated = [...managedEmails];
        updated = updated.map((e) => {
            if (e.value === email) {
                return { ...e, invalid: true, userMarkedInvalid: true, primary: undefined };
            }
            return e;
        });
        setManagedEmails(updated);
        trackEvent('click_delete_email_address', { prospect_id: prospectId });
    };

    const handleAddAnotherEmail = () => {
        setNewEmails([...newEmails, { email: '', isValid: true }]);
    };

    const handleNewEmailRemove = (index: any) => {
        const updated = [...newEmails];
        updated.splice(index, 1);
        setNewEmails(updated);
    };

    const handleNewEmailChange = (index: number, event: any) => {
        const updated = [...newEmails];
        updated[index].email = event.target.value;
        if (!updated[index].isValid) {
            updated[index].isValid = true;
        }
        setNewEmails(updated);
    };

    const handleSelectChange = (event: any) => {
        let updated = [...managedEmails];
        updated = updated.map((e) => {
            if (e.value === event.target.value) {
                return { ...e, invalid: false, primary: true };
            }
            return { ...e, primary: false };
        });
        setManagedEmails(updated);
        trackEvent('select_primary_email', { prospect_id: prospectId, tab: 'profile', email: event.target.value });
    };

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleMouseOver = () => {
        setShowEdit(true);
    };

    const handleMouseOut = () => {
        setShowEdit(false);
    };

    const getCombinedContacts = () => {
        const allContacts: ContactUpdate[] = [];

        const existingContactUpdates = managedEmails.map((f) => {
            // We need to cast the enums here because the graphql code gen doesn't
            // generate the correct types for the enums fields when we're using a computed field
            const updatedContact: Partial<ProspectContacts> = {
                kind: f.kind as ContactKindsEnum,
                value: f.value,
                standardizedValue: f.standardizedValue,
                channel: f.channel as ContactChannelsEnum,
                prospectId,
                invalid: f.invalid,
                primary: f.primary ? f.primary : null,
                userMarkedInvalid: f.userMarkedInvalid,
            };
            return updatedContact;
        });

        const newEmailsUpdate = newEmails
            .filter((f) => f.email.length > 0)
            .map((e, index) => {
                const newContact: ContactUpdate = {
                    kind: ContactKindsEnum.Unknown,
                    value: formatEmail(e.email),
                    standardizedValue: formatEmail(e.email),
                    channel: ContactChannelsEnum.Email,
                    prospectId,
                    invalid: false,
                    primary:
                        index === 0 && existingContactUpdates.filter((f) => f.primary).length === 0 ? true : undefined,
                    userMarkedInvalid: false,
                };
                return newContact;
            });

        return allContacts.concat(existingContactUpdates as ContactUpdate[]).concat(newEmailsUpdate);
    };

    const updateContacts = async (updateEmailFoundRecipient: boolean) => {
        let recipientIds = recipients
            ?.filter((f) => f.user.id === session?.user.id && StatusEligibleForContactUpdate.includes(f.status))
            .map((r) => r.id);

        recipientIds =
            updateEmailFoundRecipient && affectedRecipients && affectedRecipients.length > 0
                ? recipientIds?.concat(affectedRecipients?.map((a) => a.id))
                : recipientIds;

        addProspectContact(getCombinedContacts(), recipientIds ?? []);
        setEditing(false);
        reset();
    };

    const handleSaveEmail = async () => {
        let error = false;
        const updated = [...newEmails];
        for (const [index, newEmail] of updated.entries()) {
            if (
                newEmail.email.length > 0 &&
                (!isEmailValid(newEmail.email) ||
                    newEmails.filter((f) => f.email === newEmail.email).length !== 1 ||
                    contacts.filter((c) => c.value === newEmail.email).length !== 0)
            ) {
                updated[index].isValid = false;
                error = true;
            }
        }
        setNewEmails(updated);
        if (error) {
            return;
        }

        if ((affectedRecipients ?? []).length > 0) {
            setShowPrimaryChangedPrompt(true);
        } else {
            updateContacts(false);
        }
    };

    const handleSavePrimaryChange = async (decision: boolean) => {
        setShowPrimaryChangedPrompt(false);
        updateContacts(decision);
    };

    const handleCancelPrimaryChange = () => {
        let updated = [...managedEmails];
        updated = updated.map((e) => {
            if (e.value === contacts.find((f) => f.primary)?.value) {
                return { ...e, invalid: false, primary: true };
            }
            return { ...e, primary: false };
        });
        setManagedEmails(updated);
        setShowPrimaryChangedPrompt(false);
    };

    const options: SelectOption[] = managedEmails
        .filter((f) => !f.invalid)
        .map((e) => ({ label: e.value, value: e.value }));

    const addEmailSection = newEmails.map((newEmail, index) => (
        <Box
            key={`add-email-${index}`}
            css={css`
                display: flex;
                justify-content: space-between;
                padding-bottom: ${spacing.space12px};
                align-items: center;
            `}
        >
            <TextField
                parentBackgroundColor="green"
                hiddenLabel
                value={newEmail.email}
                helperText={
                    newEmail.isValid &&
                    newEmails.filter((f) => f.email === newEmail.email).length === 1 &&
                    contacts.filter((c) => c.value === newEmail.email).length === 0
                        ? null
                        : translate('invalid-email')
                }
                css={css`
                    width: 100%;
                    height: fit-content;
                    padding-left: ${spacing.space12px};
                    padding-right: ${spacing.space12px};
                `}
                onChange={(e) => handleNewEmailChange(index, e)}
            />
            <IconButton onClick={() => handleNewEmailRemove(index)}>
                <MinusCircle stroke={theme.palette.error.main} />
            </IconButton>
        </Box>
    ));

    const validEmailOptions = managedEmails
        .filter((f) => !f.invalid && !f.userMarkedInvalid)
        .map((email, index) => (
            <Box
                key={`managed-valid-email-${index}`}
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: ${spacing.space12px};
                    align-items: center;
                `}
            >
                <TextField
                    parentBackgroundColor="green"
                    value={email.value}
                    hiddenLabel
                    css={css`
                        width: 100%;
                        padding-left: ${spacing.space12px};
                        padding-right: ${spacing.space12px};
                    `}
                    inputProps={{ readOnly: true }}
                />
                <IconButton onClick={() => handleEmailRemove(email.value)}>
                    <MinusCircle stroke={theme.palette.error.main} />
                </IconButton>
            </Box>
        ));

    const invalidEmailOptions = managedEmails
        .filter((f) => f.invalid && !f.userMarkedInvalid)
        .map((email, index) => (
            <Box
                key={`managed-invalid-email-${index}`}
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: ${spacing.space12px};
                    align-items: center;
                `}
            >
                <Box
                    css={css`
                        width: 100%;
                        height: 56px;
                        padding: 16px 12px 16px;
                        margin-left: 12px;
                        margin-right: 12px;
                        background-color: ${theme.palette.common.white};
                        color: ${colors.grays.dusty};
                        border-radius: 4px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    `}
                >
                    {translate('multiple-emails.bounced-email', { email: email.value })}
                </Box>

                <IconButton onClick={() => handleEmailRemove(email.value)}>
                    <MinusCircle stroke={theme.palette.error.main} />
                </IconButton>
            </Box>
        ));

    const managedEmailOptions = [...validEmailOptions, ...invalidEmailOptions];

    const editableContent = (
        <Box
            hidden={!editing}
            css={css`
                padding-top: ${spacing.space16px};
            `}
        >
            <Box
                css={css`
                    font-weight: ${fontWeights.bold};
                    font-size: ${fontSizes.f16};
                    padding-bottom: ${spacing.space8px};
                `}
            >
                {translate('multiple-emails.primary-email-label')}
            </Box>
            <Select
                isDefault
                disableUnderline
                options={options}
                value={managedEmails.filter((f) => !f.invalid && f.primary)[0]?.value ?? ''}
                onChange={handleSelectChange}
                name="primary-email-select"
                width="100%"
                variant="filled"
                css={css`
                    width: 100%;
                    background-color: ${theme.palette.common.white};
                `}
            />
            <Box
                css={css`
                    font-weight: ${fontWeights.bold};
                    font-size: ${fontSizes.f16};
                    padding-top: ${spacing.space12px};
                    padding-bottom: ${spacing.space8px};
                `}
            >
                {translate('multiple-emails.manage-emails-label')}
            </Box>
            <Box>{managedEmailOptions}</Box>
            <Box>{addEmailSection}</Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: ${spacing.space24px};
                `}
            >
                <Button
                    variant="outlined"
                    startIcon={<Plus />}
                    onClick={handleAddAnotherEmail}
                    css={css`
                        background-color: ${theme.palette.common.white};
                    `}
                >
                    {translate('multiple-emails.add-another-email-button-label')}
                </Button>
                <Button variant="contained" onClick={handleSaveEmail}>
                    {translate('multiple-emails.save-button-label')}
                </Button>
            </Box>
        </Box>
    );

    const staticContent = (
        <Box
            css={css`
                padding-top: ${spacing.space8px};
                padding-bottom: ${spacing.space4px};
            `}
        >
            {staticEmailOptions}
        </Box>
    );

    const actionButtons = (
        <EditCloseButton
            editing={showEdit && !editing}
            onEdit={handleEditClick}
            onClose={handleCloseEditEmail}
            editButtonLabel={translate('edit-button-label')}
            closeButtonLabel={translate('close-button-label')}
        />
    );

    return (
        <Box
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            css={css`
                margin-top: ${spacing.space4px};
                padding: ${spacing.none} ${spacing.space12px};
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                background-color: ${editing ? colors.greens.narvik30 : theme.palette.common.white};
            `}
        >
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-top: ${spacing.space4px};
                `}
            >
                <Box
                    css={css`
                        padding-top: ${spacing.space12px};
                        font-size: ${fontSizes.f12};
                        color: ${theme.palette.grey[600]};
                    `}
                >
                    {translate('email-label')}
                </Box>
                <Box hidden={!showEdit && !editing}>{actionButtons}</Box>
            </Box>
            {editing ? editableContent : staticContent}
            {affectedRecipients && anchor && (
                <ProspectPrimaryChangeModal
                    emails={contacts
                        .filter((f) =>
                            affectedRecipients
                                ?.map((a) => a.contacts.length > 0 && a.contacts[0].contactId)
                                .includes(f.id)
                        )
                        .map((e) => e.value ?? [])}
                    sequences={affectedRecipients.map((a) => a.sequence.title)}
                    open={showPrimaryChangedPrompt}
                    anchor={anchor}
                    onCancel={handleCancelPrimaryChange}
                    onSave={handleSavePrimaryChange}
                />
            )}
        </Box>
    );
};

export { ProspectMultipleEmail };
