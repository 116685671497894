import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';

import { PlanTypesEnum } from 'codegen/graphql';
import { showIntercom } from 'external/intercom';
import { Box } from 'shared/components/containers';
import { Hyperlink } from 'shared/components/presentational';
import { UserUp1 } from 'shared/components/svgs';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';

interface MvpUpgradeFlowProps {
    planType: PlanTypesEnum;
    type: 'email' | 'sobo';
}

const MvpUpgradeFlow: FC<MvpUpgradeFlowProps> = (props) => {
    const { planType, type } = props;

    const translate = useTranslations('settings.shared-settings.mvp');
    const theme = useTheme();

    const href = 'mailto:daniel@hireflow.ai';

    const handleClick = () => {
        showIntercom();
    };

    // never show this component for users on the growth / growth trial plans
    if (planType === PlanTypesEnum.Enterprise || planType === PlanTypesEnum.EnterpriseFreeTrial) {
        return null;
    }

    return (
        <Box
            css={css`
                padding: 16px 24px 16px 24px;
                margin-top: 16px;

                border-radius: 8px;

                font-family: Inter, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;

                background-color: ${colors.greens.hintOfGreen};

                display: flex;
                align-items: center;
                gap: 16px;
            `}
        >
            <Box>
                <UserUp1 stroke={theme.palette.common.black} strokeWidth="2" />
            </Box>
            <Box>
                <Box component="span">{translate(`first-${type}`)}</Box>{' '}
                <Hyperlink newTab href={href}>
                    {translate('link')}
                </Hyperlink>{' '}
                <Box component="span">{translate('second')}</Box>{' '}
                <Box
                    component="span"
                    css={css`
                        color: ${theme.palette.primary.main};

                        &:hover {
                            color: ${theme.palette.primary.light};
                            cursor: pointer;
                        }
                    `}
                    onClick={handleClick}
                >
                    {translate('popup')}
                </Box>{' '}
                <Box component="span">{translate('third')}</Box>
            </Box>
        </Box>
    );
};

export { MvpUpgradeFlow };
