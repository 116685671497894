import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { trackEvent } from 'analytics';
import {
    GetUserRoleQuery,
    SequencesOwnedByStatusQuery,
    SequencesOwnedByStatusQueryVariables,
    UserTeamQuery,
} from 'codegen/graphql';
import { Button } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import { GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS } from 'shared/graphql/sequences';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl, useSession } from 'shared/hooks';
import { appRoutes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateNewSequenceButtonProps {}

const CreateNewSequenceButton: FC<CreateNewSequenceButtonProps> = () => {
    const translate = useTranslations('sequence');
    const { session, loaded } = useSession();
    const { canCreateSequences } = useAccessControl();

    const { data: sequenceData } = useQuery<SequencesOwnedByStatusQuery, SequencesOwnedByStatusQueryVariables>(
        GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
        {
            skip: !loaded,
            variables: { userId: session?.user.id },
            fetchPolicy: 'cache-and-network',
        }
    );

    const { data: teamData } = useQuery<UserTeamQuery>(GET_USER_TEAM, {
        fetchPolicy: 'no-cache',
    });

    const { data: userRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded,
        variables: { userId: session?.user.id },
        fetchPolicy: 'no-cache',
    });

    const nonArchivedCount = sequenceData
        ? sequenceData.active.aggregate!.count +
          sequenceData.emailDisconnected.aggregate!.count +
          sequenceData.ready.aggregate!.count +
          sequenceData.draft.aggregate!.count +
          sequenceData.paused.aggregate!.count
        : 0;

    const planType = teamData && teamData.teams.length > 0 ? teamData.teams[0].planType : undefined;
    const licenseType =
        userRoleData && userRoleData.user_roles_by_pk ? userRoleData.user_roles_by_pk.licenseType : undefined;

    const canCreate = planType && licenseType ? canCreateSequences(planType, licenseType, nonArchivedCount) : false;

    const handleClick = () => {
        trackEvent('click_create_sequence', { page_name: 'sequence_parent' });
    };

    return (
        <Button
            disabled={!canCreate}
            href={appRoutes.sequences.create.index()}
            variant="contained"
            css={css`
                margin-right: ${spacing.space16px};
            `}
            startIcon={<Plus stroke="white" />}
            onClick={handleClick}
        >
            {translate('new-sequence')}
        </Button>
    );
};

export { CreateNewSequenceButton };
