import dynamic from 'next/dynamic';

const ReactQuill = dynamic(() => import('shared/components/editor/react-quill'), {
    ssr: false,
});

export default ReactQuill;

// This solution is based on this:
// https://github.com/vasturiano/react-force-graph/issues/333
// https://github.com/vasturiano/react-force-graph/issues/324#issuecomment-942725048
