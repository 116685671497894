import { withTheme } from '@emotion/react';

import { FC } from 'shared/types';

interface GoogleLogoProps {}

const ThemedGoogleLogo: FC<GoogleLogoProps> = ({ ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M20.208 12.1944C20.208 11.5881 20.1536 11.0052 20.0525 10.4456H12V13.7529H16.6015C16.4032 14.8216 15.8009 15.7271 14.8953 16.3334V18.4787H17.6585C19.2753 16.9902 20.208 14.7983 20.208 12.1944Z"
            fill="#4285F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 20.55C14.3085 20.55 16.2439 19.7844 17.6585 18.4786L14.8953 16.3333C14.1297 16.8463 13.1503 17.1494 12 17.1494C9.77308 17.1494 7.88819 15.6454 7.21585 13.6245H4.35938V15.8397C5.76624 18.634 8.65769 20.55 12 20.55Z"
            fill="#34A853"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M7.21511 13.6246C7.04411 13.1116 6.94695 12.5637 6.94695 12.0001C6.94695 11.4366 7.04411 10.8886 7.21511 10.3756V8.1604H4.35863C3.77956 9.31465 3.44922 10.6205 3.44922 12.0001C3.44922 13.3798 3.77956 14.6856 4.35863 15.8399L7.21511 13.6246Z"
            fill="#FBBC05"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M12 6.85076C13.2553 6.85076 14.3823 7.28215 15.2684 8.12938L17.7207 5.67708C16.24 4.29742 14.3046 3.4502 12 3.4502C8.65769 3.4502 5.76624 5.36617 4.35938 8.16047L7.21585 10.3757C7.88819 8.35479 9.77308 6.85076 12 6.85076Z"
            fill="#EA4335"
        />
    </svg>
);

const GoogleLogo = withTheme(ThemedGoogleLogo);

export { GoogleLogo };
