import mixpanel from 'mixpanel-browser';

import { config } from 'config';
import { Session } from 'shared/hooks/use-session';

type EventName =
    | 'load_page'
    | 'load_tab'
    | 'load_extension'
    | 'click_download_CSV'
    | 'filter_by_opens_and_clicks'
    | 'select_variables'
    | 'click_archive'
    | 'click_pause'
    | 'click_pause_all'
    | 'click_resume'
    | 'click_create_project'
    | 'click_create_sequence'
    | 'click_create_sourcer'
    | 'click_duplicate'
    | 'select_new_thread'
    | 'select_sobo'
    | 'select_signature'
    | 'select_custom_sending_time'
    | 'select_contributors'
    | 'edit_contributor_permissions'
    | 'select_project_reviewer'
    | 'edit_sequence_title'
    | 'select_days_between_stage'
    | 'select_days_optimization'
    | 'select_time_optimization'
    | 'add_cc'
    | 'add_bcc'
    | 'edit_email_subject'
    | 'edit_email_content'
    | 'select_template'
    | 'click_send_test_email'
    | 'click_remove_stage'
    | 'click_add_stage'
    | 'select_email_preference'
    | 'click_report_wrong_email'
    | 'click_mark_as_inmail_sent'
    | 'click_stop_sequence_for_this_prospect'
    | 'click_add_to_project_or_sequence_kebab'
    | 'click_add_to_unsubscribe_list'
    | 'click_copy_link_to_prospect'
    | 'click_remove_from_this_project'
    | 'click_move_to_another_project'
    | 'click_change_status'
    | 'click_project_icon'
    | 'click_sequence_icon'
    | 'click_ats_icon'
    | 'click_personalize_email_sequence_toggle'
    | 'click_add_prospect'
    | 'click_enter_email_manually'
    | 'select_primary_email'
    | 'click_delete_email_address'
    | 'edit_work_and_education'
    | 'edit_notepad'
    | 'edit_reason'
    | 'edit_name'
    | 'click_view_queue'
    | 'click_gmail_link'
    | 'click_linkedin_link'
    | 'load_missing_variable_dialogue'
    | 'edit_missing_variable'
    | 'click_import_data'
    | 'import_data_successful'
    | 'import_data_failure'
    | 'prospect_added_to_project_client'
    | 'extension_installed'
    | 'prospect_approved_client'
    | 'click_outlook_link';

type Page =
    | 'sign_in'
    | 'project_parent'
    | 'project_details'
    | 'sequence_parent'
    | 'sequence_details'
    | 'sourcer_parent'
    | 'sourcer_details'
    | 'team_metrics'
    | 'reviewer_parent'
    | 'reviewer_details'
    | 'sobo_approval'
    | 'reports_activity_by_status'
    | 'reports_diversity_dashboard';

type Tab =
    | 'project_parent_active_tab'
    | 'project_parent_archived_tab'
    | 'project_parent_all_tab'
    | 'project_details_prospects_tab'
    | 'project_details_for_review_tab'
    | 'project_details_sequences_tab'
    | 'project_details_edit_tab'
    | 'project_details_activity_log_tab'
    | 'project_details_analytics_tab'
    | 'sequence_details_recipients_tab'
    | 'sequence_parent_active_tab'
    | 'sequence_parent_draft_tab'
    | 'sequence_parent_paused_tab'
    | 'sequence_parent_archived_tab'
    | 'sequence_parent_all_tab'
    | 'sequence_details_recipients_tab'
    | 'sequence_details_edit_tab'
    | 'sequence_details_settings_tab'
    | 'sequence_details_activity_log_tab'
    | 'sequence_details_analytics_tab'
    | 'sourcer_details_to_review_tab'
    | 'sourcer_details_snoozed_tab'
    | 'sourcer_details_rejected_tab'
    | 'sourcer_details_added_to_projects_tab'
    | 'sourcer_details_edit_tab';

const isProduction = () => config.Env === 'production' || config.Env === 'stage';

if (isProduction() && config.MixpanelApiKey) {
    mixpanel.init(config.MixpanelApiKey);
}

export const setMixpanelUser = (session: Session) => {
    if (isProduction() && session.user) {
        const { user } = session;

        mixpanel.identify(user.email);
        mixpanel.register({
            distinct_id: user.email,
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            team_id: user.teamId,
            user_email: user.email,
            user_id: user.id,
            user_name: user.fullName,
            ...(user.createdAt ? { created: new Date(user.createdAt).toISOString() } : {}),
            ...(user.teamDomain ? { teamDomain: user.teamDomain } : {}),
        });
        mixpanel.people.set_once('$user_id', user.id);
        mixpanel.people.set_once('$team_id', user.teamId);
        mixpanel.people.set_once('$user_name', user.fullName);
        mixpanel.people.set_once('$first_name', user.firstName);
        mixpanel.people.set_once('$last_name', user.lastName);
        mixpanel.people.set_once('$user_email', user.email);
        if (user.createdAt) {
            mixpanel.people.set_once('$created', new Date(user.createdAt).toISOString());
        }
        if (user.teamDomain) {
            mixpanel.people.set_once('$team_domain', user.teamDomain);
        }
    }
};

export const track = (
    eventName: EventName,
    params?: {
        [index: string]: any;
    }
) => {
    if (isProduction()) {
        mixpanel.track(eventName, { origin: 'webapp', ...params, version: 'v2', env: config.Env });
    }
};

export const trackEvent = (event: EventName, params?: { [index: string]: any }) => {
    track(event, params);
};

// Event Name: load_page
export const trackLoadPage = (page: Page, id?: string) => {
    track('load_page', { page_name: page, id });
};

// Event Name: load_tab
export const trackLoadTab = (tab: Tab) => {
    track('load_tab', { tab });
};
