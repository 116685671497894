import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { getPrimaryProfileUrl } from 'hireflow-shared/types/profile';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { RecipientsQuery, RecipientStatusEnum } from 'codegen/graphql';
import { ProspectPanel } from 'prospects';
import { PanelContext } from 'prospects/prospect-panel-provider';
import { RecipientListSubmenu } from 'sequences/components/tables';
import { MAX_EMAIL_CLICKS_TO_DISPLAY, MAX_EMAIL_OPENS_TO_DISPLAY } from 'sequences/constants';
import { AiSourcedTarget, StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Checkbox, EllipsisText, Hyperlink, IconButton, NoSsr } from 'shared/components/presentational';
import { DotsVertical, LinkedinLogo } from 'shared/components/svgs';
import { TableCell, TableRow } from 'shared/components/table';
import { dateFormatter, relateDateFormatter, timeFormatter } from 'shared/services';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

type RecipientData = RecipientsQuery['recipients'][number];
interface RecipientListRowProps {
    recipient: RecipientData;
    isSelected: boolean;
    onToggleSelect: (checked: boolean, sequenceId: string) => void;
    isSubmenuHidden: boolean;
}

const RecipientListRow: FC<RecipientListRowProps> = ({ recipient, isSelected, onToggleSelect, isSubmenuHidden }) => {
    const translate = useTranslations('sequence.recipient-list-table');
    const theme = useTheme();

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();
    const [showPanel, setShowPanel] = useState<boolean>(false);

    const { profile } = recipient.prospect;

    const recipientContactEmail =
        recipient.contacts.length > 0
            ? recipient.prospect.contacts?.find((f) => f.id === recipient.contacts[0].contactId)?.value
            : '';

    const prospectContext: PanelContext = {
        flow: 'recipient',
        prospectId: recipient.prospectId,
        sequenceId: recipient.sequence.id,
        url: recipient.prospect?.urls?.[0]?.url!,
    };

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const handleSelectChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onToggleSelect(!isSelected, recipient.prospectId);
    };

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (!event.defaultPrevented) {
            setShowPanel(true);
        }
    };

    const onPanelClose = () => {
        setShowPanel(false);
    };

    const date = dateFormatter(recipient.createdAt);
    const time = timeFormatter(recipient.createdAt);
    const dateAdded = `${date} ${time}`;

    const getStatusDescription = () => {
        switch (recipient.status) {
            case RecipientStatusEnum.CompletedWithNoReply:
                return translate('on-date', {
                    date: dateFormatter(recipient.completedAt ?? 0),
                });

            case RecipientStatusEnum.FindingEmail:
                return translate('added-on', {
                    date: dateFormatter(recipient.createdAt),
                });

            case RecipientStatusEnum.InProgress:
                return translate('sent-on', {
                    date: dateFormatter(recipient.lastStageSentAt ?? 0),
                });

            case RecipientStatusEnum.ManuallyExited:
                return translate('on-date', {
                    date: dateFormatter(recipient.manuallyExitedAt ?? 0),
                });

            case RecipientStatusEnum.Unsubscribed:
                return translate('unsubscribed');

            case RecipientStatusEnum.MeetingBooked:
                return translate('on-date', {
                    date: dateFormatter(recipient.meetingScheduledAt ?? 0),
                });

            case RecipientStatusEnum.NotStarted: {
                if (!recipient.nextMessageScheduledAt) {
                    return translate('empty-placeholder');
                }

                return translate('sending-in', { date: relateDateFormatter(recipient.nextMessageScheduledAt) });
            }
            case RecipientStatusEnum.Responded:
                return translate('on-date', {
                    date: dateFormatter(recipient.respondedAt ?? 0),
                });

            case RecipientStatusEnum.UnableToFindContact:
            case RecipientStatusEnum.MissingTemplateVariables:
                return translate('added-on', {
                    date: dateFormatter(recipient.createdAt),
                });
            default:
                return '';
        }
    };

    const isProspectAiSourced = recipient.prospect.sourcer_members.length > 0;

    return (
        <>
            <TableRow
                key={recipient.prospectId}
                css={css`
                    ${submenuAnchor &&
                    // simulate hover when submenu is opened
                    `
                    background-color: ${theme.palette.grey[300]};
                    .MuiTableCell-root {
                        background-color: ${theme.palette.grey[300]};
                    }
                    `}
                `}
                selected={isSelected}
                onClick={handleRowClick}
            >
                <TableCell component="th" scope="row">
                    <Box
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Checkbox
                            css={css`
                                margin-right: 8px;
                            `}
                            checked={isSelected}
                            onClick={handleSelectChange}
                        />
                        <Box
                            css={css`
                                display: grid;
                                grid-template-columns: minmax(0, 1fr) min-content;
                                column-gap: ${spacing.space16px};
                                align-items: center;
                                width: 100%;
                            `}
                        >
                            <Box
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                `}
                            >
                                <Box
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                    `}
                                >
                                    <Box
                                        css={css`
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        `}
                                        title={
                                            recipient.prospect.profile?.firstNameCustomized ??
                                            recipient.prospect.profile?.firstName
                                        }
                                    >
                                        {recipient.prospect.profile?.fullNameCustomized ??
                                            recipient.prospect.profile?.fullName}
                                    </Box>
                                    {isProspectAiSourced && (
                                        <AiSourcedTarget
                                            title={translate('ai-sourced-tooltip')}
                                            width={14}
                                            height={14}
                                        />
                                    )}
                                </Box>
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f12};
                                        color: ${theme.palette.grey[200]};

                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                >
                                    {recipientContactEmail || translate('empty-placeholder')}
                                </Box>
                            </Box>
                            <Hyperlink href={getPrimaryProfileUrl(recipient.prospect?.urls!)} newTab>
                                <LinkedinLogo
                                    css={css`
                                        path {
                                            fill-opacity: 0.1;
                                        }

                                        &:hover {
                                            path {
                                                fill-opacity: 1;
                                            }
                                        }
                                    `}
                                />
                            </Hyperlink>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <StatusChip
                        status={recipient.status}
                        lastStageSent={recipient.lastStageSent}
                        sentiment={recipient.recipientResponses[0]?.sentiment}
                    />
                    <NoSsr>
                        <EllipsisText
                            css={css`
                                font-size: ${fontSizes.f12};
                                color: ${theme.palette.grey[200]};
                                text-align: left;
                            `}
                        >
                            {getStatusDescription()}
                        </EllipsisText>
                    </NoSsr>
                </TableCell>
                <TableCell align="center">
                    {profile?.latestCompanyCustomized ??
                    profile?.latestCompany ??
                    profile?.titleCustomized ??
                    profile?.title ? (
                        <Box
                            css={css`
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                            `}
                        >
                            <EllipsisText
                                css={css`
                                    text-align: left;
                                `}
                            >
                                {profile.latestCompanyCustomized ?? profile?.latestCompany}
                            </EllipsisText>
                            <EllipsisText
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};
                                    text-align: left;
                                `}
                            >
                                {profile?.titleCustomized ?? profile?.title}
                            </EllipsisText>
                        </Box>
                    ) : (
                        translate('empty-placeholder')
                    )}
                </TableCell>
                {/* We don't want to display more than 20 opens/clicks as there are messages that are opened
                or clicked systematically and thus have thousands of email opens/clicks on file, which is 
                confusing for users 
                see: https://app.asana.com/0/1204016351863611/1205758985042493/f
                */}
                <TableCell align="center">
                    {Math.min(MAX_EMAIL_OPENS_TO_DISPLAY, recipient.emailStats?.[0]?.opens || 0)}
                </TableCell>
                <TableCell align="center">
                    {Math.min(MAX_EMAIL_CLICKS_TO_DISPLAY, recipient.emailStats?.[0]?.clicks || 0)}
                </TableCell>
                <TableCell>
                    <Box
                        css={css`
                            display: grid;
                            grid-template-columns: minmax(0, 1fr) min-content;
                            column-gap: ${spacing.space16px};
                            align-items: center;
                            width: 100%;
                        `}
                    >
                        <Box>
                            <NoSsr>
                                <Box
                                    css={css`
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    `}
                                    title={dateAdded}
                                >
                                    {dateAdded}
                                </Box>
                            </NoSsr>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                    color: ${theme.palette.grey[200]};

                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                `}
                            >
                                {isProspectAiSourced ? translate('ai-sourced') : ''}
                            </Box>
                        </Box>
                        {!isSubmenuHidden ? (
                            <IconButton
                                css={css`
                                    margin-left: auto;
                                    ${submenuAnchor &&
                                    `
                                    padding: 0;
                                    margin-right: 8px;
                                    path {
                                        stroke: ${theme.palette.primary.light};
                                    }
                                    `}

                                    &:hover path {
                                        stroke: ${theme.palette.primary.light};
                                    }
                                `}
                                onClick={handleSubmenuButtonClick}
                                disableRipple
                            >
                                <DotsVertical />
                            </IconButton>
                        ) : null}
                    </Box>
                </TableCell>
            </TableRow>
            <RecipientListSubmenu
                recipient={recipient}
                anchor={submenuAnchor}
                setAnchor={setSubmenuAnchor}
                prospectPanelContext={prospectContext}
            />
            <ProspectPanel isPanelOpen={showPanel} hideProspectPanel={onPanelClose} prospectContext={prospectContext} />
        </>
    );
};

export { RecipientListRow };
