import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowRight: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M5 12H19M19 12L12 5M19 12L12 19"
            stroke={stroke ?? theme?.palette.primary.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowRight = withTheme(ThemedArrowRight);

export { ArrowRight };
