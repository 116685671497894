import { config } from 'config';
import { Session } from 'shared/hooks/use-session';

let isInitialized: boolean = false;

export function registerClarity(session: Session) {
    if (config.Env === 'production') {
        if ((window as any).clarity) {
            // no need to keep calling 'identify'
            if (isInitialized) return;
            isInitialized = true;

            const { id, email } = session.user;

            // https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
            (window as any).clarity('consent');

            // https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api#api-call
            (window as any).clarity(
                'identify',
                id, // "custom-id"
                email // "custom-session-id"
            );
        }
    }
}
