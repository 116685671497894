import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useTranslations } from 'next-intl';

import { ProjectStatusEnum, SequenceStatusEnum, UpdateSequencesMutation } from 'codegen/graphql';
import { Bold } from 'shared/components/composite';
import { AlertBar } from 'shared/components/presentational';
import {
    GET_SEQUENCES,
    GET_SEQUENCES_COUNT_BY_STATUS,
    GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS,
    UPDATE_SEQUENCES,
} from 'shared/graphql/sequences';
import { useAccessControl, useListSubmenuTab, useSnackbarAlert, useSourcerAccessControl } from 'shared/hooks';
import { FC, SourcerData } from 'shared/types';

interface SourcerAlertsProps {
    sourcer: SourcerData;
}

const SourcerAlerts: FC<SourcerAlertsProps> = (props) => {
    const { sourcer } = props;
    const translate = useTranslations('sourcing.alert-bar');

    const { setCurrentTab } = useListSubmenuTab();
    const { showSnackbarAlert } = useSnackbarAlert();

    const { canViewProject, canViewAndAddProspectsToProject, accessControlLoaded } = useAccessControl();
    const { isViewer } = useSourcerAccessControl();

    const [updateSequences] = useMutation<UpdateSequencesMutation>(UPDATE_SEQUENCES, {
        refetchQueries: [
            getOperationName(GET_SEQUENCES) as string,
            getOperationName(GET_SEQUENCES_COUNT_BY_STATUS) as string,
            getOperationName(GET_USER_SEQUENCES_OWNED_COUNT_BY_STATUS) as string,
        ],
    });

    const projectArchived = sourcer.project.status === ProjectStatusEnum.Archived;
    const projectUnshared = !canViewProject(sourcer.project);
    const projectMissingEditPermission =
        !projectUnshared && !canViewAndAddProspectsToProject(sourcer.project) && !isViewer(sourcer);

    const sequencePaused = sourcer.sequence ? sourcer.sequence.status === SequenceStatusEnum.Paused : false;
    const sequenceArchived = sourcer.sequence ? sourcer.sequence.status === SequenceStatusEnum.Archived : false;

    const unpauseSequence = async () => {
        const sequenceId = sourcer.sequence?.id;
        if (sequenceId) {
            try {
                await updateSequences({
                    variables: {
                        where: {
                            id: { _eq: sequenceId },
                        },
                        set: {
                            status: SequenceStatusEnum.Active,
                        },
                    },
                });
                showSnackbarAlert({
                    severity: 'success',
                    message: translate('sequence-paused.unpaused'),
                });
            } catch (error) {
                showSnackbarAlert({
                    severity: 'error',
                    message: translate('sequence-paused.unpause-error'),
                });
            }
        }
    };

    const openEditTab = () => {
        // eslint-disable-next-line no-magic-numbers
        setCurrentTab(4);
    };

    return accessControlLoaded ? (
        <>
            <AlertBar
                message={translate('project-archived.message')}
                buttonLabel={translate('project-archived.button')}
                onClick={openEditTab}
                showAlert={projectArchived}
            />
            <AlertBar
                message={translate('project-unshared.message')}
                buttonLabel={translate('project-unshared.button')}
                onClick={openEditTab}
                showAlert={projectUnshared}
            />
            <AlertBar
                message={translate.rich('project-no-editor-permission.message', { bold: Bold })}
                buttonLabel={translate('project-no-editor-permission.button')}
                onClick={openEditTab}
                showAlert={projectMissingEditPermission}
            />
            <AlertBar
                message={translate('sequence-paused.message')}
                buttonLabel={translate('sequence-paused.button')}
                onClick={unpauseSequence}
                showAlert={sequencePaused}
            />
            <AlertBar
                message={translate('sequence-archived.message')}
                buttonLabel={translate('sequence-archived.button')}
                onClick={openEditTab}
                showAlert={sequenceArchived}
            />
        </>
    ) : null;
};

export { SourcerAlerts };
