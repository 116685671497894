import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { trackEvent } from 'analytics';
import { emailTemplates } from 'sequences/settings';
import { Box } from 'shared/components/containers';
import { Button, List, Modal, ModalCloseButton } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface EmailTemplatesModalProps {
    index: number;
    open: boolean;
    onClose: () => void;
    onConfirm: (selectedOptionId: string) => void;
    isEdit?: boolean;
    isReplyToLastEmailChecked?: boolean;
}

interface EmailOption {
    id: string;
    title: string;
}

const EmailTemplatesModal: FC<EmailTemplatesModalProps> = (props) => {
    const { onConfirm, index, open, onClose, isEdit, isReplyToLastEmailChecked } = props;

    const defaultSelectedOptionId = index > 0 && isReplyToLastEmailChecked ? 'prospectFocusedFollowUp' : 'blank';

    const [selectedOptionId, setSelectedOptionId] = useState<string>(defaultSelectedOptionId);
    const theme = useTheme();
    const translate = useTranslations('sequence.create.stages');

    useEffect(() => {
        setSelectedOptionId(defaultSelectedOptionId);
    }, [defaultSelectedOptionId]);

    const handleClick = () => {
        onConfirm(selectedOptionId);
        trackEvent('select_template', {
            page_name: isEdit ? 'sequence_details' : 'sequence_creation',
            value: selectedOptionId,
        });
    };

    const handleSetSelectedOption = (option: EmailOption) => {
        setSelectedOptionId(option.id);
    };

    const initialEmailOptions: EmailOption[] = [
        {
            id: 'blank',
            title: translate('blank'),
        },
        {
            id: 'simple',
            title: translate('simple'),
        },
        {
            id: 'companyFocused',
            title: translate('company-focused'),
        },
        {
            id: 'prospectFocusedInitial',
            title: translate('prospect-focused'),
        },
        {
            id: 'careerNextSteps',
            title: translate('career-next-steps'),
        },
        {
            id: 'longForm',
            title: translate('long-form'),
        },
    ];

    const followUpEmailOptions: EmailOption[] = [
        {
            id: 'prospectFocusedFollowUp',
            title: translate('prospect-focused'),
        },
        {
            id: 'inboxBump',
            title: translate('inbox-bump'),
        },
        {
            id: 'coreResponsibilities',
            title: translate('core-responsibilities'),
        },
        {
            id: 'careerGoals',
            title: translate('career-goals'),
        },
        {
            id: 'jobDescription',
            title: translate('job-description'),
        },
        {
            id: 'short',
            title: translate('short'),
        },
    ];

    const emailOptions: EmailOption[] =
        index > 0 && isReplyToLastEmailChecked ? followUpEmailOptions : initialEmailOptions;

    return (
        <Modal
            open={open}
            onClose={onClose}
            css={css`
                & > .MuiBox-root {
                    width: 800px;
                    height: 486px;
                    padding: 0;
                }
            `}
        >
            <Box
                css={css`
                    display: flex;
                `}
            >
                <ModalCloseButton onClose={onClose} />

                <Box
                    css={css`
                        width: 275px;
                        background-color: ${theme.palette.background.default};
                    `}
                >
                    <List type="unordered">
                        {emailOptions.map((menuItem: EmailOption) => (
                            <Box
                                key={menuItem.id}
                                onClick={() => handleSetSelectedOption(menuItem)}
                                css={css`
                                    padding-left: 20px;
                                    padding-top: 24px;
                                    font-weight: ${fontWeights.semiBold};
                                    cursor: pointer;
                                    color: ${selectedOptionId === menuItem.id
                                        ? theme.palette.primary.main
                                        : theme.palette.secondary.light};
                                `}
                            >
                                <Circle
                                    fill={
                                        selectedOptionId === menuItem.id
                                            ? theme.palette.primary.main
                                            : theme.palette.common.white
                                    }
                                    css={css`
                                        position: relative;
                                        top: -3px;
                                        margin-right: 4px;
                                    `}
                                />
                                {menuItem.title}
                            </Box>
                        ))}
                    </List>
                </Box>

                <Box
                    css={css`
                        width: 100%;
                        height: 100%;
                    `}
                >
                    <Box
                        css={css`
                            padding-top: 36px;
                            overflow-y: auto;
                            height: 380px;
                            margin: 0 50px;
                            border-bottom: 1px solid ${theme.palette.grey[100]};
                        `}
                    >
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: (emailTemplates as any)[selectedOptionId] }} />
                    </Box>

                    <Box
                        css={css`
                            padding: 25px;
                            padding-right: 50px;
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <Button variant="contained" onClick={handleClick}>
                            {translate('start-with-this-template')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export { EmailTemplatesModal };
