import { css } from '@emotion/react';
import { CircularProgress, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { ChangeEvent, FocusEvent, useState } from 'react';

import { RecipientStatusEnum } from 'codegen/graphql';
import { ProspectPanel } from 'prospects';
import { StatusChip } from 'shared/components/composite';
import { Box } from 'shared/components/containers';
import { Button, EllipsisText, Popover } from 'shared/components/presentational';
import { MagnifyingGlass } from 'shared/components/svgs';
import { useProspectSearch, useSession } from 'shared/hooks';
import { appRoutes, colors, fontFamilies, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ProspectSearchBarProps extends OutlinedInputProps {}

const ProspectSearchBar: FC<ProspectSearchBarProps> = ({ ...textFieldProps }) => {
    const translate = useTranslations('search-bar');
    const { session } = useSession();
    const router = useRouter();
    const { searchProspects, areSearchResultsLoading, searchResults } = useProspectSearch();

    const [selectedProspectId, setSelectedProspectId] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLInputElement | null>();

    const MINIMUM_SEARCH_LENGTH = 3;
    const shouldDisplayResultsPreview = searchQuery.length >= MINIMUM_SEARCH_LENGTH && !!popoverAnchor;

    const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
        const newSearchQuery = event.target.value;
        setSearchQuery(newSearchQuery);
        if (newSearchQuery.length < MINIMUM_SEARCH_LENGTH) {
            return;
        }

        const MAXIMUM_RESULTS_FOR_PREVIEW = 5;

        searchProspects({
            variables: {
                query: {
                    search_query: newSearchQuery,
                    team_id: session?.user.teamId,
                    max_results: MAXIMUM_RESULTS_FOR_PREVIEW,
                },
            },
        });

        setPopoverAnchor(event.currentTarget);
    };

    const handleClearSearchInput = () => {
        setSearchQuery('');
    };

    const handleResultsPreviewClose = () => {
        setPopoverAnchor(null);
    };

    const handleClickOnResult = (prospectId: string) => {
        setSelectedProspectId(prospectId);
    };

    const handlePanelClose = () => setSelectedProspectId(null);

    const handleSearchInputFocus = (event: FocusEvent<HTMLInputElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    const handleSeeAllResultsClick = () => {
        router.push({ pathname: appRoutes.searchProspects.index(), query: { search: searchQuery } });
    };

    const renderResultsPreviewRows = () => {
        if (!searchResults?.prospects.length) {
            return (
                <Box
                    css={css`
                        background-color: ${colors.neutrals.white};
                        padding: ${spacing.space16px} ${spacing.space24px};
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: ${fontSizes.f16};
                    `}
                >
                    {areSearchResultsLoading ? translate('loading') : translate('no-results-found')}
                </Box>
            );
        }

        return searchResults.prospects.map((result, index) => {
            const renderTitleAndCompany = () => {
                if (result.title && result.company) {
                    return `${result.title} • ${result.company}`;
                }
                if (result.title) {
                    return result.title;
                }
                if (result.company) {
                    return result.company;
                }
                return '';
            };

            return (
                <Box
                    key={result.prospectId}
                    css={css`
                        padding: ${spacing.space8px};
                        background-color: ${colors.neutrals.white};
                    `}
                >
                    <Box
                        css={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            padding: ${spacing.space16px} ${spacing.space20px};
                            height: 100px;

                            cursor: pointer;
                            border-bottom: ${index === searchResults.prospects.length - 1
                                ? 'none'
                                : `1px solid ${colors.grays.gallery}`};

                            &:hover {
                                background-color: ${colors.greens.narvik};
                                border-radius: ${spacing.space8px};
                            }
                        `}
                        onClick={() => handleClickOnResult(result.prospectId)}
                    >
                        <Box
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: ${spacing.space8px};
                            `}
                        >
                            <Box>
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f16};
                                    `}
                                >
                                    <EllipsisText>{result.fullName}</EllipsisText>
                                </Box>
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f12};
                                        color: ${colors.grays.dusty};
                                    `}
                                >
                                    <EllipsisText>{result.primaryEmailAddress}</EllipsisText>
                                </Box>
                            </Box>
                            <Box
                                css={css`
                                    font-size: ${fontSizes.f12};
                                `}
                            >
                                <Box component="span">{renderTitleAndCompany()}</Box>
                            </Box>
                        </Box>

                        <Box
                            css={css`
                                font-size: ${fontSizes.f12};
                            `}
                        >
                            <StatusChip
                                status={result.latestSequenceStatus as RecipientStatusEnum}
                                lastStageSent={result.latestStageSent}
                            />
                        </Box>
                    </Box>
                    <ProspectPanel
                        isPanelOpen={result.prospectId === selectedProspectId}
                        hideProspectPanel={handlePanelClose}
                        prospectContext={{
                            flow: 'recipient',
                            prospectId: result.prospectId,
                            url: result.profileUrl,
                        }}
                    />
                </Box>
            );
        });
    };

    const renderSeeAllResultsRow = () => {
        if (!searchResults?.prospects.length) {
            return null;
        }
        return (
            <Box
                css={css`
                    background-color: ${colors.neutrals.white};
                    padding: ${spacing.space24px} ${spacing.space16px};
                    font-size: ${fontSizes.f16};
                    color: ${colors.greens.eucalyptus};
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        background-color: ${colors.greens.narvik};
                        border-radius: ${spacing.space8px};
                    }
                `}
                onClick={handleSeeAllResultsClick}
            >
                {translate('see-all-results')}
            </Box>
        );
    };

    const getEndAdornmentForSearchInput = () => {
        if (!searchQuery) {
            return null;
        }

        if (areSearchResultsLoading) {
            return <CircularProgress size={16} />;
        }

        return (
            <Button
                css={css`
                    height: 36px;
                    font-size: ${fontSizes.f12};
                `}
                variant="text"
                onClick={handleClearSearchInput}
            >
                {translate('clear')}
            </Button>
        );
    };

    return (
        <Box
            css={css`
                width: 448px;
            `}
        >
            <OutlinedInput
                css={css`
                    .MuiOutlinedInput-root {
                        height: 44px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-radius: 100px;
                        border: 1px solid ${colors.greens.eucalyptus};
                    }

                    .MuiOutlinedInput-input {
                        padding: ${spacing.space10px} ${spacing.space16px};
                        font-size: ${fontSizes.f12};
                        font-family: ${fontFamilies.inter};
                    }
                `}
                startAdornment={<MagnifyingGlass stroke={colors.greens.eucalyptus} />}
                endAdornment={getEndAdornmentForSearchInput()}
                placeholder={translate('search-for-prospects-across-your-team')}
                value={searchQuery}
                onChange={handleChangeSearchInput}
                onFocus={handleSearchInputFocus}
                fullWidth
                autoComplete="off"
                {...textFieldProps}
            />
            <Popover
                anchorEl={popoverAnchor}
                open={shouldDisplayResultsPreview}
                onClose={handleResultsPreviewClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 22 }}
                css={css`
                    margin-top: 8px;
                `}
                // below is the best way I found to set the width of the popover
                // happy to change it if anyone has a better idea
                // eslint-disable-next-line max-len
                // https://stackoverflow.com/questions/49985038/how-do-you-change-the-width-and-heigth-for-popover-in-material
                PaperProps={{ style: { width: '448px' } }}
                disableAutoFocus
                disableEnforceFocus
            >
                <Box
                    css={css`
                    background-color: ${colors.neutrals.white}
                    border-radius: ${spacing.space8px}
                    border: 1px solid ${colors.grays.gallery}
            `}
                >
                    {renderResultsPreviewRows()}
                </Box>
                {renderSeeAllResultsRow()}
            </Popover>
        </Box>
    );
};

export { ProspectSearchBar };
