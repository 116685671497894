import styled from '@emotion/styled';

import { fontFamilies } from 'shared/settings';
import { FC } from 'shared/types';

type TitleType = 'h1' | 'h2' | 'h3' | 'h4';

interface TitleProps {
    className?: string;
    type: TitleType;
    center?: boolean;
    flat?: boolean;
}

const TitleContainer: FC<TitleProps> = (props) => {
    const { children, type, className } = props;

    if (type === 'h1') {
        return <h1 className={className}>{children}</h1>;
    }
    if (type === 'h2') {
        return <h2 className={className}>{children}</h2>;
    }
    if (type === 'h3') {
        return <h3 className={className}>{children}</h3>;
    }
    if (type === 'h4') {
        return <h4 className={className}>{children}</h4>;
    }

    return null;
};

const Title = styled(TitleContainer)<TitleProps>`
    color: inherit;
    font-family: ${fontFamilies.poppins};

    ${({ center }) => center && `text-align:center`};
    ${({ flat }) => (flat ? `margin:0;` : `margin: 5px 0 10px;`)};
`;

export type { TitleType, TitleProps };
export { Title };
