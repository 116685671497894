import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { ComposeCompactEmail } from 'sequences/components/form';
import { CreateEditSequenceStagesFormValues } from 'sequences/types';
import { Box } from 'shared/components/containers';
import { colors } from 'shared/settings';
import { FC } from 'shared/types';

interface SequenceStagesPreviewProps {
    formMethods: UseFormReturn<CreateEditSequenceStagesFormValues>;
    stages: UseFieldArrayReturn<CreateEditSequenceStagesFormValues, 'stages'>;
}

const SequenceStagesPreview: FC<SequenceStagesPreviewProps> = (props) => {
    const { formMethods, stages } = props;
    const translate = useTranslations(
        'project.prospect-list-table.edit-bar.add-to-sequence-modal.choose-sequence-form'
    );

    if (!stages.fields || stages.fields.length === 0) return null;

    return (
        <>
            <Box
                css={css`
                    padding-top: 16px;
                    padding-bottom: 8px;

                    font-family: Inter, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: ${colors.grays.tundora};
                `}
            >
                {translate('sequence-preview-title')}
            </Box>
            <Box
                css={css`
                    max-height: 40vh;
                    overflow-y: scroll;

                    padding: 16px 24px;
                    background-color: ${colors.grays.alabaster};
                    border: 1px solid ${colors.grays.gallery};
                    border-radius: 4px;
                `}
            >
                {stages.fields.map((_stage, index) => (
                    <Box key={index}>
                        <Box
                            css={css`
                                padding-top: ${index === 0 ? '8px' : '24px'};
                                padding-bottom: 12px;

                                text-transform: uppercase;
                                font-weight: 700;
                            `}
                        >
                            {translate('stage', { index: index + 1 })}
                        </Box>
                        <ComposeCompactEmail formMethods={formMethods} index={index} disabled />
                    </Box>
                ))}
            </Box>
        </>
    );
};

export { SequenceStagesPreview };
