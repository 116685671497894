import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import React from 'react';

import { Box } from 'shared/components/containers';
import { EllipsisText } from 'shared/components/presentational/ellipsis-text';
import { FC } from 'shared/types';

interface FilterDescriptionProps {
    children: Array<React.ReactNode>;
}

const FilterBarDescription: FC<FilterDescriptionProps> = ({ children }) => {
    const translate = useTranslations('filter-bar-description');
    return (
        <EllipsisText>
            {children.flatMap((filter, i) =>
                i === 0 ? (
                    <Box component="span" key={`${i}-filter`}>
                        {filter}
                    </Box>
                ) : (
                    [
                        <Box
                            component="span"
                            key={`${i}-and`}
                            css={css`
                                white-space: pre;
                                text-overflow: ellipsis;
                            `}
                        >
                            {translate('and-connector')}
                        </Box>,
                        <Box component="span" key={`${i}-filter`}>
                            {filter}
                        </Box>,
                    ]
                )
            )}
        </EllipsisText>
    );
};

export { FilterBarDescription };
