import { gql } from '@apollo/client';
import { DateTime } from 'luxon';

import {
    OrderBy,
    SourcerMemberRejectionReasonsEnum,
    SourcerMemberRejectionsConstraint,
    SourcerMemberReviewIntentEnum,
    SourcerMembersQuery,
    SourcerMemberStatusEnum,
} from 'codegen/graphql';
import { PROFILE_DETAILS } from 'shared/graphql/profile';
import { RECIPIENT_DETAILS } from 'shared/graphql/recipients';

export type SourcerMembersData = SourcerMembersQuery['sourcer_members'];
export type SourcerMemberData = SourcerMembersQuery['sourcer_members'][number];

export const SOURCER_MEMBER_DETAILS = gql`
    fragment SourcerMemberDetails on sourcer_members {
        id
        firstName
        lastName
        fullName
        status
        location
        profileImageUri
        prospect {
            id
            profile {
                ...ProfileDetails
            }
            urls {
                url
            }
            recipients {
                ...RecipientDetails
            }
            contacts(where: { channel: { _eq: email } }) {
                value
            }
        }
        reviewer {
            id
            fullName
        }
        snoozedUntil
        education(order_by: [{ endDateYear: desc_nulls_last }]) {
            id
            degree
            fieldOfStudy
            startDateYear
            endDateYear
            schoolName
        }
        experience(
            order_by: [
                { current: desc_nulls_last }
                { endDateYear: desc_nulls_last }
                { endDateMonth: desc_nulls_last }
            ]
        ) {
            id
            companyName
            title
            startDateMonth
            startDateYear
            endDateMonth
            endDateYear
            current
            companyLogo
        }
        createdAt
        modifiedAt
        urls {
            url
        }
        insights {
            id
            sourcerMemberId
            insight
        }
        sourcer {
            id
            project {
                id
            }
        }
        addedAt
        rejectedAt
        snoozedAt
        notes
        reviewIntent
        rejectionReasonsIntent
        availableAt
    }

    ${PROFILE_DETAILS}
    ${RECIPIENT_DETAILS}
`;

export const sourcerMembersDefaultOrderBy = 'createdAt';
export const sourcerMembersDefaultOrderByDirection = OrderBy.Desc;

export const sourcerMembersDefaultVariables = {
    orderBy: { [sourcerMembersDefaultOrderBy]: sourcerMembersDefaultOrderByDirection },
    limit: 25,
    offset: 0,
    where: {},
    withDetails: true,
};

export const GET_SOURCER_MEMBERS = gql`
    query SourcerMembers(
        $orderBy: sourcer_members_order_by!
        $limit: Int
        $offset: Int = 0
        $where: sourcer_members_bool_exp!
        $withDetails: Boolean!
    ) {
        sourcer_members_aggregate(where: $where) {
            aggregate {
                count
            }
        }
        sourcer_members(order_by: [$orderBy], where: $where, offset: $offset, limit: $limit) {
            id
            ...SourcerMemberDetails @include(if: $withDetails)
        }
    }

    ${SOURCER_MEMBER_DETAILS}
`;

export const GET_SOURCER_MEMBER_BY_ID = gql`
    query SourcerMemberById($id: String!) {
        sourcerMember: sourcer_members_by_pk(id: $id) {
            id
            status
            reviewer {
                id
                fullName
            }
        }
    }
`;

export const GET_SOURCER_MEMBERS_COUNT_BY_STATUS = gql`
    query SourcerMembersByStatus($id: String!) {
        to_review: sourcer_members_aggregate(where: { status: { _eq: to_review }, sourcerId: { _eq: $id } }) {
            aggregate {
                count
            }
        }
        snoozed: sourcer_members_aggregate(where: { status: { _eq: snoozed }, sourcerId: { _eq: $id } }) {
            aggregate {
                count
            }
        }
        rejected: sourcer_members_aggregate(where: { status: { _eq: rejected }, sourcerId: { _eq: $id } }) {
            aggregate {
                count
            }
        }
        accepted: sourcer_members_aggregate(where: { status: { _eq: accepted }, sourcerId: { _eq: $id } }) {
            aggregate {
                count
            }
        }
    }
`;

export const UPDATE_SOURCER_MEMBER_BY_ID = gql`
    mutation UpdateSourcerMemberById($id: String!, $set: sourcer_members_set_input!) {
        update_sourcer_members_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
            ...SourcerMemberDetails
        }
    }
    ${SOURCER_MEMBER_DETAILS}
`;

export const UPDATE_SOURCER_MEMBERS = gql`
    mutation UpdateSourcerMembers($where: sourcer_members_bool_exp!, $set: sourcer_members_set_input!) {
        update_sourcer_members(where: $where, _set: $set) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const REVIEW_SOURCER_MEMBERS = gql`
    mutation ReviewSourcerMembers($sourcer_members: [sourcer_members_insert_input!]!) {
        insert_sourcer_members(
            objects: $sourcer_members
            on_conflict: {
                constraint: sourcer_members_pkey
                update_columns: [status, notes, snoozedUntil, rejectedAt, addedAt, snoozedAt, reviewerId, reviewIntent]
            }
        ) {
            affected_rows
            returning {
                id
                status
                prospect {
                    id
                    profile {
                        ...ProfileDetails
                    }
                    urls {
                        url
                    }
                    customMessages {
                        id
                        prospectId
                        sequenceId
                        customMessage
                    }
                }
            }
        }
    }
    ${PROFILE_DETAILS}
`;

export const UPDATE_SOURCER_MEMBERS_PROSPECT_ID = gql`
    mutation UpdateSourcerMembersProspectId($sourcerMembers: [sourcer_members_insert_input!]!) {
        insert_sourcer_members(
            objects: $sourcerMembers
            on_conflict: { constraint: sourcer_members_pkey, update_columns: [prospectId] }
        ) {
            affected_rows
            returning {
                id
                status
                prospect {
                    id
                    createdAt
                    urls {
                        url
                    }
                    profile {
                        ...ProfileDetails
                        experiences: experience {
                            companyName
                            title
                            current
                            startDateMonth
                            startDateYear
                            endDateMonth
                            endDateYear
                            companyLogo
                            companyId
                            companyUrl
                        }
                        educations: education {
                            id
                            degree
                            fieldOfStudy
                            schoolName
                            startDateYear
                            endDateYear
                            schoolId
                            schoolUrl
                        }
                    }
                    customMessages {
                        id
                        prospectId
                        sequenceId
                        customMessage
                    }
                }
            }
        }
    }
    ${PROFILE_DETAILS}
`;

export const createRejectUpsertObject = (
    sourcerMember: SourcerMemberData,
    reviewerId: string,
    notes?: string,
    rejectionReasons: SourcerMemberRejectionReasonsEnum[] = []
) => ({
    id: sourcerMember.id,
    sourcerId: sourcerMember.sourcer.id,
    firstName: sourcerMember.firstName,
    lastName: sourcerMember.lastName,
    fullName: sourcerMember.fullName,
    status: SourcerMemberStatusEnum.Rejected,
    reviewerId,
    rejectedAt: Date.now(),
    notes,
    sourcer_member_rejections: {
        data: rejectionReasons.map((reason) => ({ reason })),
        on_conflict: {
            constraint: SourcerMemberRejectionsConstraint.SourcerMemberRejectionsPkey,
            update_columns: [],
        },
    },
    reviewIntent: SourcerMemberReviewIntentEnum.None,
});

export const createSnoozeUpsertObject = (sourcerMember: SourcerMemberData, reviewerId: string) => ({
    id: sourcerMember.id,
    sourcerId: sourcerMember.sourcer.id,
    firstName: sourcerMember.firstName,
    lastName: sourcerMember.lastName,
    fullName: sourcerMember.fullName,
    status: SourcerMemberStatusEnum.Snoozed,
    reviewerId,
    snoozedAt: Date.now(),
    snoozedUntil: DateTime.now().plus({ days: 3 }).valueOf(),
    reviewIntent: SourcerMemberReviewIntentEnum.None,
});

/**
 * Creates a sourcer_members_insert_input object that can be used to update multiple sourcer members at once
 * by employing upsert feature
 */
export const createApproveUpsertObject = ({
    sourcerMember,
    reviewerId,
}: {
    sourcerMember: SourcerMemberData;
    reviewerId: string;
}) => ({
    id: sourcerMember.id,
    sourcerId: sourcerMember.sourcer.id,
    firstName: sourcerMember.firstName,
    lastName: sourcerMember.lastName,
    fullName: sourcerMember.fullName,
    status: SourcerMemberStatusEnum.Accepted,
    reviewerId,
    addedAt: Date.now(),
    reviewIntent: SourcerMemberReviewIntentEnum.None,
});
