import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';

import { trackEvent } from 'analytics';
import { GetUserRoleQuery, UserTeamQuery } from 'codegen/graphql';
import { Button } from 'shared/components/presentational';
import { Plus } from 'shared/components/svgs';
import { GET_USER_TEAM } from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl, useSession } from 'shared/hooks';
import { appRoutes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface CreateNewSourcerButtonProps {}

const CreateNewSourcerButton: FC<CreateNewSourcerButtonProps> = () => {
    const translate = useTranslations('sourcing');

    const { session, loaded } = useSession();

    const { canAccessAISourcing } = useAccessControl();

    const { data: teamData } = useQuery<UserTeamQuery>(GET_USER_TEAM, { fetchPolicy: 'no-cache' });

    const { data: userRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded || !session,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    if (!teamData || !userRoleData || !userRoleData.user_roles_by_pk) return null;

    const handleClick = () => {
        trackEvent('click_create_sourcer', { page_name: 'sourcer_parent' });
    };

    return (
        <Button
            disabled={!canAccessAISourcing(teamData.teams?.[0]?.planType, userRoleData.user_roles_by_pk.licenseType)}
            href={appRoutes.sourcing.create.index()}
            variant="contained"
            css={css`
                margin-right: ${spacing.space16px};
                height: ${spacing.space44px};
            `}
            startIcon={<Plus stroke="white" />}
            onClick={handleClick}
        >
            {translate('right-action-button')}
        </Button>
    );
};

export { CreateNewSourcerButton };
