import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useState } from 'react';

import { ProjectMemberActivityTypesEnum, RecipientActivityTypesEnum } from 'codegen/graphql';
import { ProjectMemberActivity } from 'projects/types';
import { EmptyTabState } from 'prospects/prospect-empty-tab-state';
import { Box } from 'shared/components/containers';
import { Hyperlink, List, NoSsr } from 'shared/components/presentational';
import { Circle } from 'shared/components/svgs';
import { RecipientActivity } from 'shared/graphql/recipient-activities';
import { useAccessControl, useProspectPanel } from 'shared/hooks';
import { dateTimeFormatter } from 'shared/services';
import { appRoutes, fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

type MarkAsInMailActivity = {
    id: string;
    type: string;
    inMailSent: boolean;
    createdAt: number;
};

interface ProspectHistoryTabProps {}

const ProspectHistoryTab: FC<ProspectHistoryTabProps> = () => {
    const { canViewSequence, canViewProject } = useAccessControl();
    const { prospectInfo, recipientActivities, projectMemberActivities } = useProspectPanel();
    const translate = useTranslations('prospects.tabs.history-tab');
    const theme = useTheme();

    const profile = prospectInfo?.profile;

    const [combinedActivities, setCombinedActivities] = useState<
        (RecipientActivity | ProjectMemberActivity | MarkAsInMailActivity)[]
    >([]);

    useEffect(() => {
        if (recipientActivities && projectMemberActivities && prospectInfo) {
            let updates: (RecipientActivity | ProjectMemberActivity | MarkAsInMailActivity)[] = [];
            updates = recipientActivities;

            if (prospectInfo.inMailSent && prospectInfo.inMailSentTime) {
                updates = updates.concat([
                    {
                        id: prospectInfo.id,
                        type: 'in-mail-sent',
                        inMailSent: true,
                        createdAt: prospectInfo.inMailSentTime ?? 0,
                    },
                ]);
            }

            updates = updates
                .concat(
                    projectMemberActivities.filter((a) => a.type !== ProjectMemberActivityTypesEnum.AddedToSequence)
                )
                .sort((a, b) => b.createdAt - a.createdAt);
            setCombinedActivities(updates);
        }
    }, [projectMemberActivities, recipientActivities, prospectInfo]);

    const isProjectMemberActivity = (activity: any): activity is ProjectMemberActivity =>
        typeof activity?.project?.title === 'string';

    const isInMailActivity = (activity: any): activity is MarkAsInMailActivity =>
        typeof activity?.inMailSent === 'boolean';

    const composeLogMessage = (activity: RecipientActivity | ProjectMemberActivity | MarkAsInMailActivity) => {
        const prospect = profile?.fullNameCustomized ?? profile?.fullName;

        if (isProjectMemberActivity(activity)) {
            switch (activity.type as ProjectMemberActivityTypesEnum) {
                case ProjectMemberActivityTypesEnum.AddedToProject:
                    return translate.rich('add-to-project', {
                        user: activity.user.fullName,
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.project.title,
                            appRoutes.projects.index(activity.project.id),
                            !canViewProject(activity.project)
                        ),
                        bold: Bold,
                    });
                case ProjectMemberActivityTypesEnum.Removed:
                    return translate.rich('remove-from-project', {
                        user: activity.user.fullName,
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.project.title,
                            appRoutes.projects.index(activity.project.id),
                            !canViewProject(activity.project)
                        ),
                        bold: Bold,
                    });
                case ProjectMemberActivityTypesEnum.AwaitingReview:
                    return translate.rich('awaiting-project-review', {
                        user: activity.user.fullName,
                        prospect,
                        reviewer: activity.reviewer?.fullName,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.project.title,
                            appRoutes.projects.index(activity.project.id),
                            !canViewProject(activity.project)
                        ),
                        bold: Bold,
                    });
                case ProjectMemberActivityTypesEnum.ReviewApproved:
                    return translate.rich('project-review-approved', {
                        prospect,
                        reviewer: activity.reviewer?.fullName,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.project.title,
                            appRoutes.projects.index(activity.project.id),
                            !canViewProject(activity.project)
                        ),
                        bold: Bold,
                    });
                case ProjectMemberActivityTypesEnum.ReviewRejected:
                    return translate.rich('project-review-rejected', {
                        prospect,
                        reviewer: activity.reviewer?.fullName,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.project.title,
                            appRoutes.projects.index(activity.project.id),
                            !canViewProject(activity.project)
                        ),
                        bold: Bold,
                    });
                default:
                    return '';
            }
        } else if (isInMailActivity(activity)) {
            return translate.rich('in-mail-sent', { bold: Bold });
        } else {
            switch (activity.type as RecipientActivityTypesEnum) {
                case RecipientActivityTypesEnum.Added:
                    return translate.rich('add-to-sequence', {
                        user: activity.user?.fullName ?? translate('hireflow'),
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });
                case RecipientActivityTypesEnum.Replied:
                    return translate.rich('replied', {
                        stage: activity.lastStageSent,
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });
                case RecipientActivityTypesEnum.Interested:
                    if (activity.user) {
                        return translate.rich('manually-marked-interested', {
                            user: activity.user.fullName,
                            prospect,
                            entity: ClickableEntity(
                                theme.palette.primary.main,
                                activity.recipient.sequence.title,
                                appRoutes.sequences.index(activity.recipient.sequence.id),
                                !canViewSequence(activity.recipient.sequence)
                            ),
                            bold: Bold,
                        });
                    }
                    return translate.rich('marked-interested', {
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });

                case RecipientActivityTypesEnum.NotInterested:
                    if (activity.user) {
                        return translate.rich('manually-marked-not-interested', {
                            user: activity.user.fullName,
                            prospect,
                            entity: ClickableEntity(
                                theme.palette.primary.main,
                                activity.recipient.sequence.title,
                                appRoutes.sequences.index(activity.recipient.sequence.id),
                                !canViewSequence(activity.recipient.sequence)
                            ),
                            bold: Bold,
                        });
                    }
                    return translate.rich('marked-not-interested', {
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });

                case RecipientActivityTypesEnum.Stopped:
                    return translate.rich('manually-exited', {
                        user: activity.user?.fullName,
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });
                case RecipientActivityTypesEnum.SentStage:
                    return translate.rich('sent-stage-x', {
                        stage: activity.lastStageSent,
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });
                case RecipientActivityTypesEnum.Unsubscribed:
                    return activity.user
                        ? translate.rich('manually-unsubscribed', {
                              user: activity.user?.fullName ?? translate('hireflow'),
                              stage: activity.lastStageSent,
                              prospect,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  activity.recipient.sequence.title,
                                  appRoutes.sequences.index(activity.recipient.sequence.id),
                                  !canViewSequence(activity.recipient.sequence)
                              ),
                              bold: Bold,
                          })
                        : translate.rich('unsubscribed', {
                              stage: activity.lastStageSent,
                              prospect,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  activity.recipient.sequence.title,
                                  appRoutes.sequences.index(activity.recipient.sequence.id),
                                  !canViewSequence(activity.recipient.sequence)
                              ),
                              bold: Bold,
                          });
                case RecipientActivityTypesEnum.Finished:
                    return translate.rich('finished', {
                        prospect,
                        entity: ClickableEntity(
                            theme.palette.primary.main,
                            activity.recipient.sequence.title,
                            appRoutes.sequences.index(activity.recipient.sequence.id),
                            !canViewSequence(activity.recipient.sequence)
                        ),
                        bold: Bold,
                    });
                case RecipientActivityTypesEnum.MeetingBooked:
                    return activity.user
                        ? translate.rich('manually-marked-meeting-booked', {
                              user: activity.user?.fullName ?? translate('hireflow'),
                              prospect,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  activity.recipient.sequence.title,
                                  appRoutes.sequences.index(activity.recipient.sequence.id),
                                  !canViewSequence(activity.recipient.sequence)
                              ),
                              bold: Bold,
                          })
                        : translate.rich('meeting-booked', {
                              prospect,
                              entity: ClickableEntity(
                                  theme.palette.primary.main,
                                  activity.recipient.sequence.title,
                                  appRoutes.sequences.index(activity.recipient.sequence.id),
                                  !canViewSequence(activity.recipient.sequence)
                              ),
                              bold: Bold,
                          });
                default:
                    return '';
            }
        }
    };

    const formatDate = (date: number) => (
        <NoSsr>
            <Box
                component="span"
                css={css`
                    color: ${theme.palette.grey[200]};
                    font-size: ${fontSizes.f14};
                `}
            >
                {dateTimeFormatter(date)}
            </Box>
        </NoSsr>
    );

    const allActivities = combinedActivities?.map((activity) => (
        <Box
            component="li"
            css={css`
                display: flex;
                align-items: flex-start;
            `}
            key={`${activity.type}-${activity.id}`}
        >
            <Circle
                css={css`
                    margin-top: 4px;
                    margin-right: 16px;
                    min-width: 10px;
                    min-height: 10px;
                `}
                fill={theme.palette.grey[100]}
            />
            <Box component="div">
                {composeLogMessage(activity)}
                <br />
                {formatDate(activity.createdAt)}
            </Box>
        </Box>
    ));

    return allActivities.length > 0 ? (
        <Box
            css={css`
                padding: 0px 24px;
            `}
        >
            <List
                type="unordered"
                css={css`
                    padding: 20px 10px;
                    li:not(:last-child) {
                        margin-bottom: ${spacing.space24px};
                    }
                `}
            >
                {allActivities}
            </List>
        </Box>
    ) : (
        <EmptyTabState tab="history" />
    );
};

const ClickableEntity = (primaryTheme: string, title: string, link: string, disabled: boolean) => () =>
    (
        <Box
            component="span"
            css={css`
                color: ${primaryTheme};
            `}
        >
            {disabled ? (
                <span>{title}</span>
            ) : (
                <Hyperlink newTab href={link}>
                    {title}
                </Hyperlink>
            )}
        </Box>
    );

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
        `}
    >
        {children}
    </Box>
);

export { ProspectHistoryTab };
