import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedArrowNarrowRight: FC<SvgProps> = ({ stroke, theme, ...rest }) => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M1 7H17M17 7L11 1M17 7L11 13"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ArrowNarrowRight = withTheme(ThemedArrowNarrowRight);

export { ArrowNarrowRight };
