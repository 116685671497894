import { css, useTheme } from '@mui/material';
import { interpolate, substituteMagicSentence, Variables } from 'hireflow-shared/utils/email-drafting-utils';
import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { trackEvent } from 'analytics';
import { SequenceStageSendTimeEnum } from 'codegen/graphql';
import { SequenceStageData } from 'prospects';
import { RemovePersonalization } from 'prospects/prospect-remove-personalization-prompt';
import { DraftPersonalizedSequenceStages, StageViewFormValues } from 'prospects/types/form-types';
import { Box } from 'shared/components/containers';
import { CompactEmailEditor, EmailContentPreview, EmailSignaturePreview } from 'shared/components/editor';
import { Switch } from 'shared/components/form';
import { Chip } from 'shared/components/presentational';
import { useProspectPanel, useSession } from 'shared/hooks';
import { getStageSignature } from 'shared/services/custom-stage-utils';
import { colors, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface SequenceStagesViewProps {
    personalizedSequenceStages?: DraftPersonalizedSequenceStages;
    anchor?: HTMLElement;
    formMethods: UseFormReturn<StageViewFormValues>;
    variables: Variables[];
    sequenceId: string;
    setPersonalizedStages: (draftPersonalizedSequenceStages: DraftPersonalizedSequenceStages | undefined) => void;
    magicSentence: string | null;
    missingFields: string[];
}

const SequenceStagesView: FC<SequenceStagesViewProps> = (props) => {
    const {
        anchor,
        formMethods,
        sequenceId,
        variables,
        setPersonalizedStages,
        personalizedSequenceStages,
        magicSentence,
        missingFields,
    } = props;
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('prospects.tabs.add-tab');
    const { context, sequencesData, sendFromAccountsData } = useProspectPanel();
    const [personalize, setPersonalize] = useState<boolean>(false);
    const [sequenceStages, setSequenceStages] = useState<SequenceStageData[]>([]);
    const [warnRemovePersonalization, setWarnRemovePersonalization] = useState<boolean>(false);

    const { formState } = formMethods;
    const { isDirty } = formMethods.getFieldState('stages', formState);
    const selectedSequenceStages = useWatch({ control: formMethods.control, name: 'stages' });
    const customizedInitialTime = useWatch({ control: formMethods.control, name: 'time' });

    useEffect(() => {
        if (sequenceId) {
            setPersonalize(
                (personalizedSequenceStages &&
                    (personalizedSequenceStages?.stages ?? []).length > 0 &&
                    personalizedSequenceStages?.sequenceId === sequenceId &&
                    personalizedSequenceStages.userId === session?.user.id &&
                    missingFields.length === 0) ??
                    false
            );
            setSequenceStages(sequencesData?.find((f) => f.id === sequenceId)?.stages ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sequenceId, missingFields]);

    useEffect(() => {
        if (context?.url && session) {
            setPersonalizedStages({
                sequenceId,
                profileUrl: context.url,
                stages: personalize ? selectedSequenceStages : [],
                time: customizedInitialTime,
                userId: session.user.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSequenceStages, customizedInitialTime, personalize]);

    const handleRemovePersonalization = () => {
        let stages = sequencesData?.filter((f) => f.id === sequenceId)[0]?.stages;
        stages = stages?.map((stage, index) => {
            let { content, subject } = stage;

            subject = interpolate(subject, variables[index]);

            content = interpolate(content, variables[index]);
            content = substituteMagicSentence(content, magicSentence);

            return { ...stage, subject, content };
        });
        formMethods.reset({
            stages,
            time:
                stages && stages.length > 0
                    ? stages[0].sendTime ?? SequenceStageSendTimeEnum.AsSoonAsPossible
                    : SequenceStageSendTimeEnum.AsSoonAsPossible,
        });
        setPersonalize(!personalize);
        setWarnRemovePersonalization(false);
    };

    const handleSwitchClick = () => {
        if (personalize && isDirty) {
            setWarnRemovePersonalization(true);
        } else {
            setPersonalize(!personalize);
        }
        trackEvent('click_personalize_email_sequence_toggle');
    };

    const editableContent = formMethods.getValues('stages')?.map((stage, index) => (
        <Box
            key={`stage.${index}.box`}
            css={css`
                margin: ${spacing.space24px} ${spacing.none};
                border-radius: ${spacing.space4px};
            `}
        >
            <Chip
                key={`stage.${index}.chip`}
                label={translate('personalize-email-stages', { number: index + 1 })}
                variant="filled"
                css={css`
                    margin-bottom: ${spacing.space12px};
                    background-color: ${colors.yellows.picasso};
                    font-size: ${fontSizes.f16};
                    font-weight: ${fontWeights.bold};
                `}
            />
            <CompactEmailEditor
                key={`stages.${index}.compactEmail`}
                formMethods={formMethods}
                index={index}
                showSubject
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'bullet' }, { list: 'ordered' }],
                    ],
                    clipboard: {
                        matchVisual: false,
                    },
                    magicUrl: true,
                }}
                signatureHtml={getStageSignature(stage, sendFromAccountsData)}
            />
        </Box>
    ));

    const staticContent = sequenceStages?.map((stage, index) => {
        let body = interpolate(stage.content, variables[index]);
        body = substituteMagicSentence(body, magicSentence);

        const signature = getStageSignature(stage, sendFromAccountsData);

        return (
            <Box
                key={index}
                css={css`
                    margin: ${spacing.space24px} ${spacing.none};
                `}
            >
                <Box
                    css={css`
                        font-size: ${fontSizes.f16};
                        color: ${theme.palette.secondary.main};
                        padding: ${spacing.space12px} ${spacing.none};
                    `}
                >
                    {translate.rich('preview-of-stage', { number: index + 1, bold: Bold })}
                </Box>
                <Box
                    css={css`
                        border: 1px solid ${theme.palette.grey[500]};
                        border-radius: ${spacing.space4px};
                        font-size: ${fontSizes.f16};
                        color: ${theme.palette.grey[200]};
                        padding: ${spacing.space12px};
                    `}
                >
                    <EmailContentPreview content={body} fontColor={theme.palette.grey[200]} />
                    <EmailSignaturePreview signatureHtml={`<br>${signature}`} fontColor={theme.palette.grey[200]} />
                </Box>
            </Box>
        );
    });

    return (
        <Box
            css={css`
                margin-top: ${spacing.space36px};
                background-color: ${personalize ? colors.greens.narvik30 : theme.palette.grey[300]};
                padding: ${spacing.space24px};
            `}
        >
            <Switch
                disabled={missingFields.length > 0}
                key={translate('personalize-sequence-label')}
                label={translate('personalize-sequence-label')}
                checked={personalize}
                onChange={handleSwitchClick}
                formMethods={formMethods}
            />
            {personalize ? editableContent : staticContent}
            <RemovePersonalization
                anchor={anchor}
                open={warnRemovePersonalization}
                onCancel={() => setWarnRemovePersonalization(false)}
                onRemove={handleRemovePersonalization}
            />
        </Box>
    );
};

const Bold = (children: ReactNode) => (
    <Box
        component="span"
        css={css`
            font-weight: bold;
            color: black;
        `}
    >
        {children}
    </Box>
);

export { SequenceStagesView };
