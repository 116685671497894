import { config } from 'config';
import { windowFeatures, windowTarget } from 'shared/constants';

interface HandleConnectOauthProps {
    teamId: string;
    syncConfidential: boolean;
    isSandbox: boolean;
}

const handleConnectOauth = async ({ teamId, syncConfidential, isSandbox }: HandleConnectOauthProps) => {
    let url: string;

    if (syncConfidential && isSandbox) {
        url = config.LeverConfig.sandbox.connectUriConfidential;
    } else if (syncConfidential && !isSandbox) {
        url = config.LeverConfig.prod.connectUriConfidential;
    } else if (!syncConfidential && isSandbox) {
        url = config.LeverConfig.sandbox.connectUri;
    } else {
        url = config.LeverConfig.prod.connectUri;
    }

    // include the state param in the uri
    // this is required by Lever
    // we pass the teamId and verify server side
    url = `${url}&state=${teamId}`;

    window.open(url, windowTarget, windowFeatures);
};

export { handleConnectOauth };
