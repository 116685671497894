import {
    SequenceEmailPreferenceEnum,
    SequenceStageSendTimeEnum,
    SequenceStageSendTimeOfDayEnum,
    SequenceStageSendTypeOfDayEnum,
    SignatureOptionsEnum,
} from 'codegen/graphql';
import { SelectOption } from 'shared/components/form';
import { Translate } from 'shared/utils';

const sendTimeOptions = (translate: Translate, typeOfDay?: string): SelectOption[] => [
    {
        value: SequenceStageSendTimeEnum.AsSoonAsPossible,
        label: translate('as-soon-as-possible'),
    },
    {
        value: SequenceStageSendTimeEnum.InAFewHours,
        label: translate('in-a-few-hours'),
    },
    {
        value: SequenceStageSendTimeEnum.TheNextDay,
        label:
            typeOfDay === SequenceStageSendTypeOfDayEnum.AllDays
                ? translate('tomorrow')
                : translate('the-next-business-day'),
    },
];

const sendTypeOfDaysStageOneOptions = (translate: Translate): SelectOption[] => [
    {
        value: SequenceStageSendTypeOfDayEnum.WeekDays,
        label: translate('excluding-weekends'),
    },
    {
        value: SequenceStageSendTypeOfDayEnum.AllDays,
        label: translate('including-weekends'),
    },
];

const sendTypeOfDaysOptions = (translate: Translate): SelectOption[] => [
    {
        value: SequenceStageSendTypeOfDayEnum.WeekDays,
        label: translate('business-days'),
    },
    {
        value: SequenceStageSendTypeOfDayEnum.AllDays,
        label: translate('regular-days'),
    },
];

const sendTimeOfDayOptions = (translate: Translate, isUserOwnedAccount: boolean): SelectOption[] => [
    {
        value: SequenceStageSendTimeOfDayEnum.AutoOptimizeTime,
        label: translate('auto-optimize-time'),
    },
    {
        value: SequenceStageSendTimeOfDayEnum.MorningYourTimezone,
        label: isUserOwnedAccount ? translate('morning-your-timezone') : translate('morning-teammate-timezone'),
    },
    {
        value: SequenceStageSendTimeOfDayEnum.AfternoonYourTimezone,
        label: isUserOwnedAccount ? translate('afternoon-your-timezone') : translate('afternoon-teammate-timezone'),
    },
];

const signatureOptions = (): SelectOption[] => [
    {
        value: SignatureOptionsEnum.HireflowSettings,
        label: 'Hireflow Settings',
    },
    {
        value: SignatureOptionsEnum.ImportFromGmail,
        label: 'Gmail',
    },
];

const preferenceOptions = (): SelectOption[] => [
    {
        value: SequenceEmailPreferenceEnum.PersonalEmailOnly,
        label: 'Personal Email Only',
    },
    {
        value: SequenceEmailPreferenceEnum.PersonalPreferred,
        label: 'Personal Email Preferred',
    },
    {
        value: SequenceEmailPreferenceEnum.WorkPreferred,
        label: 'Work Email Preferred',
    },
    {
        value: SequenceEmailPreferenceEnum.WorkEmailOnly,
        label: 'Work Email Only',
    },
];

const detailsPageHeaderOptions = (translate: Translate): SelectOption[] => [
    {
        value: 'edit_sequence',
        label: translate('details.edit-sequence'),
    },
    {
        value: 'duplicate',
        label: translate('details.duplicate'),
    },
    {
        value: 'pause',
        label: translate('details.pause'),
    },
    {
        value: 'archive',
        label: translate('details.archive'),
    },
    // Commenting out for now, as per product.
    // {
    //     value: 'download_data',
    //     label: translate('details.download-data'),
    // },
];

const statsOptions = (translate: Translate): SelectOption[] => [
    {
        value: 'all_time',
        label: translate('details.all-time'),
    },
    {
        value: 'yesterday',
        label: translate('details.yesterday'),
    },
    {
        value: 'last_week',
        label: translate('details.last-week'),
    },
    {
        value: 'last_month',
        label: translate('details.last-month'),
    },
];

export {
    detailsPageHeaderOptions,
    sendTimeOptions,
    statsOptions,
    sendTimeOfDayOptions,
    sendTypeOfDaysOptions,
    signatureOptions,
    preferenceOptions,
    sendTypeOfDaysStageOneOptions,
};
