import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import xor from 'lodash/xor';
import { useTranslations } from 'next-intl';
import { BaseSyntheticEvent, useEffect, useState } from 'react';

import {
    GetUserRoleQuery,
    InviteStatusEnum,
    LicenseTypesEnum,
    OrderBy,
    PlanTypesEnum,
    TeamRolesEnum,
    TeamsQuery,
} from 'codegen/graphql';
import {
    AddMemberModal,
    InviteesList,
    LinkedInCompanyUrl,
    NoTeamSupport,
    UsersList,
} from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { FormControlLabel } from 'shared/components/form';
import { Button, Checkbox, FilterButton, FilterTextField, Popover } from 'shared/components/presentational';
import { Plus, XClose } from 'shared/components/svgs';
import {
    GET_TEAM_BY_ID,
    GET_TEAM_INVITES,
    GET_TEAM_MEMBERS,
    TeamInvitesData,
    TeamMember,
    roleLabelsType,
} from 'shared/graphql/teams';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useAccessControl } from 'shared/hooks';
import { useSession } from 'shared/hooks/use-session';
import { fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const roleOptions = [
    {
        label: 'role-reviewer-label',
        value: LicenseTypesEnum.Reviewer,
    },
    {
        label: 'role-full-label',
        value: LicenseTypesEnum.Full,
    },
    {
        label: 'role-admin-label',
        value: TeamRolesEnum.Admin,
    },
    {
        label: 'role-owner-label',
        value: TeamRolesEnum.Owner,
    },
    {
        label: 'role-archived-label',
        value: LicenseTypesEnum.Archived,
    },
] as Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;

const roleOptionsSolo = [
    {
        label: 'role-full-label',
        value: LicenseTypesEnum.Full,
    },
    {
        label: 'role-owner-label',
        value: TeamRolesEnum.Owner,
    },
    {
        label: 'role-archived-label',
        value: LicenseTypesEnum.Archived,
    },
] as Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;

const initEnterpriseFilters: Array<TeamRolesEnum | LicenseTypesEnum> = [
    LicenseTypesEnum.Reviewer,
    LicenseTypesEnum.Full,
    TeamRolesEnum.Admin,
    TeamRolesEnum.Owner,
];

const initSoloFilters: Array<TeamRolesEnum | LicenseTypesEnum> = [LicenseTypesEnum.Full, TeamRolesEnum.Owner];

interface TeamProps {}

const Team: FC<TeamProps> = () => {
    const theme = useTheme();
    const { session, loaded } = useSession();
    const translate = useTranslations('settings.team-settings');
    const [rolePopoverAnchor, setRolePopoverAnchor] = useState<HTMLButtonElement>();
    const [roleFilters, setRoleFilters] = useState<Array<TeamRolesEnum | LicenseTypesEnum>>([]);
    const isRoleFilterApplied = roleFilters.length > 0;
    const [keyword, setKeyword] = useState<string>('');
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);

    const [invitedUsers, setInvitedUsers] = useState<TeamInvitesData>([]);
    const [members, setMembers] = useState<Array<TeamMember>>([]);

    const { data: userTeamData } = useQuery<TeamsQuery>(GET_TEAM_BY_ID, {
        skip: !loaded,
        variables: { id: session?.user?.teamId },
        fetchPolicy: 'network-only',
    });

    const { data: userRoleData } = useQuery<GetUserRoleQuery>(GET_USER_ROLE, {
        skip: !loaded,
        variables: { userId: session?.user?.id },
        fetchPolicy: 'network-only',
    });

    const teamId = session?.user.teamId;
    const currentPlan = userTeamData?.teams_by_pk?.planType;
    const seatsCount = userTeamData?.teams_by_pk?.seatsCount ?? 0;
    const currentUserRole = userRoleData?.user_roles_by_pk?.role;
    const currentUserLicenseType = userRoleData?.user_roles_by_pk?.licenseType;

    const { canAccessTeamsPage, canAddTeamMembers } = useAccessControl();

    const { data: invitesData, refetch: refetchInvitesData } = useQuery<{ team_invites: TeamInvitesData }>(
        GET_TEAM_INVITES,
        {
            variables: {
                where: {
                    _and: [{ team: { id: { _eq: teamId } } }, { status: { _neq: InviteStatusEnum.Accepted } }],
                },
                orderBy: { invitee: OrderBy.Asc },
            },
        }
    );

    const { data: membersData, refetch: refetchMembersData } = useQuery<{ users: Array<TeamMember> }>(
        GET_TEAM_MEMBERS,
        {
            variables: { where: { team: { id: { _eq: teamId } } }, orderBy: { email: OrderBy.Asc } },
        }
    );

    useEffect(() => {
        if (
            userTeamData?.teams_by_pk?.planType &&
            [PlanTypesEnum.Enterprise, PlanTypesEnum.EnterpriseFreeTrial].includes(userTeamData?.teams_by_pk?.planType)
        ) {
            setRoleFilters(initEnterpriseFilters);
        } else {
            setRoleFilters(initSoloFilters);
        }
    }, [userTeamData]);

    useEffect(() => {
        refetchInvitesData();
        refetchMembersData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTeamData, userRoleData]);

    const usedInviteesSeats =
        invitesData?.team_invites?.filter((invite) => invite.licenseType !== LicenseTypesEnum.Reviewer)?.length ?? 0;
    const usedMembersSeats =
        membersData?.users?.filter((user) => user.licenseType === LicenseTypesEnum.Full)?.length ?? 0;
    const seatsUsed = usedInviteesSeats + usedMembersSeats;

    useEffect(() => {
        const filteredTeamMembers: TeamMember[] = isRoleFilterApplied
            ? membersData?.users.filter((u) => {
                  const value =
                      u.role === TeamRolesEnum.Owner
                          ? TeamRolesEnum.Owner
                          : // the team admin being archived is not possible because of the trigger
                          // when a team admin is archived we change the role to user
                          // this check is for fail safe
                          u.role === TeamRolesEnum.Admin && u.licenseType !== LicenseTypesEnum.Archived
                          ? TeamRolesEnum.Admin
                          : u.licenseType === LicenseTypesEnum.Reviewer
                          ? LicenseTypesEnum.Reviewer
                          : u.licenseType === LicenseTypesEnum.Archived
                          ? LicenseTypesEnum.Archived
                          : LicenseTypesEnum.Full;

                  return roleFilters.includes(value);
              }) ?? []
            : membersData?.users ?? [];

        const filteredMembersWithKeyword = keyword
            ? filteredTeamMembers.filter((m) => m.email.includes(keyword) || m.fullName.toLowerCase().includes(keyword))
            : filteredTeamMembers;

        setMembers(filteredMembersWithKeyword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membersData, roleFilters, keyword]);

    useEffect(() => {
        const filteredTeamInvites: TeamInvitesData = isRoleFilterApplied
            ? invitesData?.team_invites.filter((i) => {
                  const value =
                      // the team admin being archived is not possible because of the trigger
                      // when a team admin is archived we change the role to user
                      // this check is for fail safe
                      i.role === TeamRolesEnum.Admin && i.licenseType !== LicenseTypesEnum.Archived
                          ? TeamRolesEnum.Admin
                          : i.licenseType === LicenseTypesEnum.Reviewer
                          ? LicenseTypesEnum.Reviewer
                          : i.licenseType === LicenseTypesEnum.Archived
                          ? LicenseTypesEnum.Archived
                          : LicenseTypesEnum.Full;
                  return roleFilters.includes(value);
              }) ?? []
            : invitesData?.team_invites ?? [];

        const filteredInvitesWithKeyword = keyword
            ? filteredTeamInvites.filter((m) => m.invitee.includes(keyword))
            : filteredTeamInvites;

        setInvitedUsers(filteredInvitesWithKeyword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitesData, roleFilters, keyword]);

    const usersCount = (membersData?.users.length ?? 0) + (invitesData?.team_invites.length ?? 0);
    const filteredUsersCount = (members.length ?? 0) + (invitedUsers.length ?? 0);

    const handleAddMemberClick = () => setShowAddMemberModal(true);
    const handleCloseAddMemberModal = () => {
        setShowAddMemberModal(false);
    };

    const handleRoleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setRoleFilters(roleFilters);
        setRolePopoverAnchor(event.currentTarget);
    };

    const handleRoleCheckboxClick = (value: TeamRolesEnum | LicenseTypesEnum) => () =>
        setRoleFilters(xor(roleFilters, [value]));
    const handleRolePopoverClose = () => setRolePopoverAnchor(undefined);
    const handleClearRoleFilter = () => setRoleFilters([]);
    const handleKeywordChange = (e: BaseSyntheticEvent) => {
        setKeyword(e.target.value);
    };

    const roleOptionCheckboxes = (currentPlan === PlanTypesEnum.Solo ? roleOptionsSolo : roleOptions).map(
        ({ label, value }) => (
            <FormControlLabel
                key={label}
                checked={roleFilters.includes(value)}
                onChange={handleRoleCheckboxClick(value)}
                label={translate(label)}
                control={<Checkbox />}
                css={css`
                    .MuiFormControlLabel-label {
                        font-size: ${fontSizes.f16};
                        margin-left: 16px;
                    }
                `}
            />
        )
    );

    const roleFilter = (
        <>
            <FilterButton
                state={rolePopoverAnchor ? 'hover' : isRoleFilterApplied ? 'active' : 'default'}
                onClick={handleRoleFilterButtonClick}
            >
                {translate('role-filter-label')}
            </FilterButton>
            <Popover
                open={Boolean(rolePopoverAnchor)}
                anchorEl={rolePopoverAnchor}
                onClose={handleRolePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                css={css`
                    margin-top: 8px;
                `}
            >
                <Box
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: ${spacing.space32px};
                        width: 337px;
                    `}
                >
                    {roleOptionCheckboxes}
                    <Button
                        variant="text"
                        css={css`
                            margin-top: ${spacing.space32px};
                            margin-left: auto;
                            padding: 0;
                        `}
                        onClick={handleClearRoleFilter}
                        startIcon={<XClose />}
                    >
                        {translate('clear-filter-label')}
                    </Button>
                </Box>
            </Popover>
        </>
    );

    if (!currentPlan || !currentUserRole || !currentUserLicenseType) return null;

    const canAddMemberPermission = canAddTeamMembers(currentPlan, currentUserRole, seatsUsed < seatsCount);
    const addMemberButton = (
        <>
            <Button
                disabled={!canAddMemberPermission}
                disableElevation
                variant="contained"
                css={css`
                    font-family: ${fontFamilies.inter};
                    font-weight: ${fontWeights.normal};
                    font-size: ${fontSizes.f16};
                    line-height: 19px;
                    color: ${theme.palette.common.white};
                    height: 44px;
                `}
                startIcon={<Plus stroke="white" />}
                onClick={handleAddMemberClick}
            >
                {translate('add-a-team-member')}
            </Button>

            <AddMemberModal
                open={showAddMemberModal}
                onClose={handleCloseAddMemberModal}
                seatsCount={seatsCount}
                seatsUsed={seatsUsed}
                planType={currentPlan}
            />
        </>
    );

    if (!canAccessTeamsPage(currentPlan, currentUserLicenseType)) return <NoTeamSupport />;

    return (
        <Box>
            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 32px;
                `}
            >
                <Box>
                    {userTeamData && userTeamData.teams_by_pk && (
                        <h2
                            css={css`
                                font-family: ${fontFamilies.poppins};
                                font-weight: ${fontWeights.bold};
                                font-size: ${fontSizes.f32};
                                line-height: 34px;
                                color: ${theme.palette.common.black};
                                margin: 0;
                                padding: 0;
                            `}
                        >
                            {translate('team-title', { name: userTeamData.teams_by_pk.name })}
                        </h2>
                    )}
                    <p
                        css={css`
                            font-family: ${fontFamilies.inter};
                            font-weight: ${fontWeights.medium};
                            font-size: ${fontSizes.f12};
                            line-height: 15px;
                            letter-spacing: 0.02em;
                            text-transform: uppercase;
                            color: ${theme.palette.common.black};
                        `}
                    >
                        {translate('team-members', { usersCount })}
                    </p>
                </Box>
                {addMemberButton}
            </Box>

            <Box
                css={css`
                    display: flex;
                    justify-content: space-between;
                    gap: ${spacing.space36px};
                `}
            >
                {roleFilter}
                <FilterTextField
                    parentBackgroundColor="white"
                    placeholder={translate('keyword-filter-placeholder')}
                    value={keyword}
                    onChange={handleKeywordChange}
                    css={css`
                        width: 100%;
                    `}
                />
            </Box>

            <Box
                component="p"
                css={css`
                    font-family: ${fontFamilies.inter};
                    font-weight: ${fontWeights.normal};
                    font-size: ${fontSizes.f14};
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    color: ${theme.palette.common.black};
                    margin: 0;
                    margin-top: 20px;
                `}
            >
                {!isRoleFilterApplied &&
                    `${translate('no-filters-applied')} • ${translate('users-count', {
                        usersCount: filteredUsersCount,
                    })}`}

                {isRoleFilterApplied &&
                    `${roleFilters
                        .map((f) => roleOptions.find((o) => o.value === f)?.label as roleLabelsType)
                        .map((label) => translate(label))
                        .join(', ')} • ${translate('users-count', { usersCount: filteredUsersCount })}`}
            </Box>

            <InviteesList
                seatsUsed={seatsUsed}
                currentUserRole={currentUserRole}
                invitedUsers={invitedUsers}
                roleOptions={(currentPlan === PlanTypesEnum.Solo ? roleOptionsSolo : roleOptions).filter(
                    (f) => f.value !== LicenseTypesEnum.Archived
                )}
                planType={currentPlan}
                seatsAvailable={seatsCount - seatsUsed > 0 ? seatsCount - seatsUsed : 0}
            />
            <UsersList
                seatsUsed={seatsUsed}
                currentUserRole={currentUserRole}
                members={members}
                roleOptions={(currentPlan === PlanTypesEnum.Solo ? roleOptionsSolo : roleOptions).filter(
                    (f) => f.value !== LicenseTypesEnum.Archived
                )}
                planType={currentPlan}
                seatsAvailable={seatsCount - seatsUsed > 0 ? seatsCount - seatsUsed : 0}
            />
            <LinkedInCompanyUrl />
        </Box>
    );
};

export { Team };
