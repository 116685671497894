import { css } from '@emotion/react';

import { Box } from 'shared/components/containers';
import { Title, Modal, ModalCloseButton, LoadingButton, Button } from 'shared/components/presentational';
import { useConfirmationModal } from 'shared/hooks';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface ConfirmationModalProps {}

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
    const { children, ...rest } = props;
    const { isModalOpen, hideModal, modalContent, isModalLoading } = useConfirmationModal();
    const { text: confirmButtonText, ...confirmButtonRest } = modalContent?.confirmButton || {};
    const { text: cancelButtonText, ...cancelButtonRest } = modalContent?.cancelButton || {};

    return (
        <Modal {...rest} open={isModalOpen} onClose={hideModal}>
            <Box
                css={css`
                    position: relative;
                    padding: ${spacing.space32px};
                `}
            >
                <ModalCloseButton onClose={hideModal} />

                {modalContent?.title && (
                    <Title
                        type="h3"
                        css={css`
                            font-size: ${fontSizes.f24};
                        `}
                    >
                        {modalContent.title}
                    </Title>
                )}

                {modalContent?.description && <p>{modalContent.description}</p>}

                {modalContent?.children}
                {children}

                <Box
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        margin-top: ${spacing.space32px};
                    `}
                >
                    {cancelButtonText && (
                        <Button
                            variant="outlined"
                            color="inherit"
                            css={css`
                                margin-right: ${spacing.space16px};
                            `}
                            {...cancelButtonRest}
                        >
                            {cancelButtonText}
                        </Button>
                    )}

                    {confirmButtonText && (
                        <LoadingButton variant="contained" loading={isModalLoading} {...confirmButtonRest}>
                            {confirmButtonText}
                        </LoadingButton>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export type { ConfirmationModalProps };
export { ConfirmationModal };
