import styled from '@emotion/styled';
import { TableSortLabel as MuiTableSortLabel, TableSortLabelProps as MuiTableSortLabelProps } from '@mui/material';

import { FC } from 'shared/types';

interface TableSortLabelProps extends MuiTableSortLabelProps {}

const TableSortLabel: FC<TableSortLabelProps> = (props) => <MuiTableSortLabel {...props} />;

const StyledTableSortLabel = styled(TableSortLabel)`
    ${({ theme }) => `
    & .MuiTableSortLabel-icon, &.Mui-active .MuiTableSortLabel-icon {
        color: ${theme.palette.primary.main}
    }
`}
`;

export { StyledTableSortLabel as TableSortLabel };
