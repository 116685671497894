import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { UseFormReturn } from 'react-hook-form';

import { GreenhouseApplicationTypeEnum } from 'codegen/graphql';
import { OverrideToggleFormValues } from 'integrations/types';
import { Box } from 'shared/components/containers';
import { AutoComplete, MenuItem, Select, SelectOption } from 'shared/components/form';
import { Circle } from 'shared/components/svgs';
import { AshbySourcesData, GreenhouseSourcesData, LeverSourcesData } from 'shared/graphql/integrations';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

const applicationTypeSelectOptions = (): SelectOption[] => [
    {
        value: GreenhouseApplicationTypeEnum.Candidate,
        label: 'Candidate',
    },
    {
        value: GreenhouseApplicationTypeEnum.Prospect,
        label: 'Prospect',
    },
];

interface TeamSettingsDefaultsProps {
    formMethods: UseFormReturn<OverrideToggleFormValues & any>;
    data?: GreenhouseSourcesData | LeverSourcesData | AshbySourcesData;
    canCreateProspect?: boolean;
}

const TeamSettingsDefaults: FC<TeamSettingsDefaultsProps> = (props) => {
    const { formMethods, data, canCreateProspect = false } = props;
    const translate = useTranslations('integrations');

    const getAtsSourceOptions = () => data?.map((option) => ({ label: option.name, value: option.id })) ?? [];

    const applicationTypeMenuOptions = applicationTypeSelectOptions().map((option) => (
        <MenuItem value={option.value} key={option.value}>
            {option.value === formMethods.getValues('addAsApplicationType') && (
                <Circle
                    css={css`
                        margin-right: 10px;
                    `}
                />
            )}
            {option.label}
        </MenuItem>
    ));

    const handleChange = (value: string) => {
        formMethods.setValue('defaultSourceId', value, { shouldDirty: true });
    };

    return (
        <>
            <Box
                css={css`
                    padding-top: ${spacing.space8px};
                `}
            >
                <AutoComplete
                    css={css`
                        width: 100%;
                    `}
                    parentBackgroundColor="green"
                    formMethods={formMethods}
                    name="defaultSourceId"
                    value={formMethods.getValues('defaultSourceId')}
                    label={translate('default-source-id')}
                    options={getAtsSourceOptions()}
                    onChange={handleChange}
                    noOptionsText={translate('ats-dropdown-no-option-label')}
                />
            </Box>
            {canCreateProspect && (
                <Box
                    css={css`
                        padding-top: ${spacing.space8px};
                    `}
                >
                    <Select
                        label={translate('greenhouse.add-as-application-type')}
                        variant="outlined"
                        formMethods={formMethods}
                        value={formMethods.getValues('addAsApplicationType')}
                        name="addAsApplicationType"
                        css={css`
                            flex: 1;
                        `}
                    >
                        {applicationTypeMenuOptions}
                    </Select>
                </Box>
            )}
        </>
    );
};

export { TeamSettingsDefaults };
