import { useTranslations } from 'next-intl';

import { RecipientStatusEnum } from 'codegen/graphql';
import { CustomInfoMessage } from 'prospects/prospect-custom-info-message';
import { Box } from 'shared/components/containers';
import { useProspectPanel } from 'shared/hooks';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface RecipientsInErrorStatusProps {
    recipients: RecipientConversationsData[];
}

const RecipientsInErrorStatus: FC<RecipientsInErrorStatusProps> = (props) => {
    const { recipients } = props;
    const translate = useTranslations('prospects.tabs.conversations-tab');
    const { prospectInfo } = useProspectPanel();

    const profile = prospectInfo?.profile;

    const name = profile?.firstNameCustomized ?? profile?.firstName;

    const customInfoMessages = recipients.map((r) => (
        <CustomInfoMessage
            key={r.id}
            message={
                r.status === RecipientStatusEnum.MissingTemplateVariables
                    ? translate('recipient-status-error-missing-variables', {
                          name,
                          sequence: r.sequence.title,
                      })
                    : translate('recipient-status-error-unable-to-send', { name, sequence: r.sequence.title })
            }
        />
    ));

    return <Box>{customInfoMessages}</Box>;
};

export { RecipientsInErrorStatus };
