import { getCookies } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { logger } from 'logger';
import { JWTToken, verifyJwt } from 'shared/services/verify-jwt';

export const decodeJwtPayload = async (options: OptionsType): Promise<JWTToken> => {
    try {
        const cookies = getCookies(options);
        const payload = await verifyJwt(cookies.jwt!);
        return payload;
    } catch (err) {
        logger.error('Error decoding JWT payload', { err });
        return {};
    }
};
