import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import { EmailMessages, RecipientResponseMethodTypesEnum, RecipientStatusEnum } from 'codegen/graphql';
import { MessageView } from 'prospects';
import { Box } from 'shared/components/containers';
import { useProspectPanel } from 'shared/hooks';
import { FC } from 'shared/types';
import { RecipientConversationsData } from 'shared/types/prospect';

interface MessagesExchangedProps {
    recipients: RecipientConversationsData[];
}

export interface MessageExchange {
    id: string;
    inMail: boolean;
    stageIndex: number;
    customStage: boolean;
    fromProspect: boolean;
    sequenceTitle: string;
    emailMessage: DeepPartial<EmailMessages>;
    internalTime: number;
    status?: RecipientStatusEnum;
}

const MessagesExchanged: FC<MessagesExchangedProps> = ({ recipients }) => {
    const { prospectInfo, contacts } = useProspectPanel();

    const invalidContactIds = contacts?.filter((f) => f.invalid).map((m) => m.id) ?? [];

    let allMessagesExchanged = _.flatten(
        recipients?.map((recipient) =>
            recipient.recipientMessageSends
                .filter((f) => {
                    const isInvalidContact = f.email_message.contactId
                        ? invalidContactIds.includes(f.email_message.contactId)
                        : false;
                    const isBounceReport = f.email_message.bounceReport;

                    return !isInvalidContact && !isBounceReport;
                })
                .map((messageSent) => {
                    const exchangedMessage: MessageExchange = {
                        id: messageSent.email_message.id,
                        inMail: false,
                        stageIndex: messageSent.stageIndex,
                        customStage: !!messageSent.customStageId,
                        fromProspect: false,
                        sequenceTitle: recipient.sequence.title,
                        emailMessage: messageSent.email_message,
                        internalTime: messageSent.email_message.internalDate,
                        status: recipient.status,
                    };
                    return exchangedMessage;
                })
        )
    ).concat(
        _.flatten(
            recipients?.map((recipient) =>
                recipient.recipientResponses
                    .filter(
                        (f) =>
                            !f.email_message?.bounceReport &&
                            f.method === RecipientResponseMethodTypesEnum.EmailResponseDetected
                    )
                    .map((response) => {
                        const responseReceived: MessageExchange = {
                            id: response.email_message!.id,
                            inMail: false,
                            stageIndex: -1,
                            customStage: false,
                            fromProspect: true,
                            sequenceTitle: recipient.sequence.title,
                            emailMessage: response.email_message!,
                            internalTime: response.email_message!.internalDate,
                            status: recipient.status,
                        };

                        return responseReceived;
                    })
            )
        )
    );

    allMessagesExchanged =
        prospectInfo && prospectInfo.inMailSent && prospectInfo.inMailSentTime
            ? allMessagesExchanged
                  .concat({
                      id: `in-mail-${prospectInfo?.inMailSentTime}`,
                      inMail: true,
                      stageIndex: 0,
                      customStage: false,
                      fromProspect: false,
                      sequenceTitle: '',
                      emailMessage: {},
                      internalTime: prospectInfo.inMailSentTime,
                  })
                  .sort((a, b) => b.internalTime - a.internalTime)
            : allMessagesExchanged.sort((a, b) => b.internalTime - a.internalTime);

    const messages = allMessagesExchanged.map((message) => (
        <Box key={message.emailMessage.id}>
            <MessageView message={message} />
        </Box>
    ));

    return <Box>{messages}</Box>;
};

export { MessagesExchanged };
