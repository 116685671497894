import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedInfoCircle: FC<SvgProps> = ({ stroke, theme, onClick, width, height, ...rest }) => (
    <svg
        {...{
            width: width ?? '24',
            height: height ?? '24',
            viewBox: '0 0 24 24',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
            ...(onClick && { onClick }),
            ...rest,
        }}
    >
        <path
            // eslint-disable-next-line max-len
            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke={stroke ?? theme?.palette.common.black}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const InfoCircle = withTheme(ThemedInfoCircle);

export { InfoCircle };
