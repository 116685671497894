import { css } from '@emotion/react';
import { SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { LicenseTypesEnum, PlanTypesEnum, TeamRolesEnum, UserStatusEnum } from 'codegen/graphql';
import { MemberListSubmenu } from 'settings/components/presentational/team';
import { Box } from 'shared/components/containers';
import { MenuItem, Select } from 'shared/components/form';
import { IconButton, NoSsr } from 'shared/components/presentational';
import { Circle, DotsVertical } from 'shared/components/svgs';
import { TeamMember, roleLabelsType } from 'shared/graphql/teams';
import { useSession } from 'shared/hooks';
import { dateFormatter } from 'shared/services';
import { fontFamilies, fontSizes, fontWeights } from 'shared/settings';
import { FC } from 'shared/types';

interface UsersListRowProps {
    seatsUsed: number;
    seatsAvailable: number;
    currentUserRole: TeamRolesEnum;
    member: TeamMember;
    roleOptions: Array<{ label: roleLabelsType; value: TeamRolesEnum | LicenseTypesEnum }>;
    onRoleChange: (id: string) => (event: SelectChangeEvent<any>) => void;
    planType: PlanTypesEnum;
    currentUserLicenseType: LicenseTypesEnum;
}

const UsersListRow: FC<UsersListRowProps> = ({
    member,
    seatsUsed,
    roleOptions,
    currentUserRole,
    onRoleChange,
    planType,
    currentUserLicenseType,
    seatsAvailable,
}) => {
    const theme = useTheme();
    const { session } = useSession();
    const translate = useTranslations('settings.team-settings');

    const [submenuAnchor, setSubmenuAnchor] = useState<HTMLButtonElement>();

    const canChangeRolePermission =
        currentUserRole === TeamRolesEnum.Owner ||
        (currentUserRole === TeamRolesEnum.Admin && member.role !== TeamRolesEnum.Owner);

    const canRemoveMemberPermission =
        currentUserRole === TeamRolesEnum.Owner ||
        (currentUserRole === TeamRolesEnum.Admin && TeamRolesEnum.User === member.role);

    const isCurrentUser = session?.user.email === member.email;
    const isArchived = member.status === UserStatusEnum.Archived;

    const selectedValue = [TeamRolesEnum.Owner, TeamRolesEnum.Admin].includes(member.role)
        ? member.role
        : member.licenseType === LicenseTypesEnum.Reviewer
        ? LicenseTypesEnum.Reviewer
        : LicenseTypesEnum.Full;

    const handleSubmenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setSubmenuAnchor(event.currentTarget);
    };

    const iconButton =
        !isCurrentUser && canRemoveMemberPermission ? (
            <IconButton
                css={css`
                    margin: 0px;
                    margin-left: 10px;
                    &:hover path {
                        stroke: ${theme.palette.primary.light};
                    }
                `}
                onClick={handleSubmenuButtonClick}
                disableRipple
            >
                <DotsVertical />
            </IconButton>
        ) : null;

    const memberListSubmenu = !isCurrentUser ? (
        <MemberListSubmenu
            id={member.id}
            member
            anchor={submenuAnchor}
            setAnchor={setSubmenuAnchor}
            isArchived={isArchived}
            seatsAvailable={seatsAvailable}
            planType={planType}
            totalSeats={seatsAvailable + seatsUsed}
        />
    ) : null;

    const availableSeats = member.team.seatsCount - seatsUsed;

    return (
        <li
            css={css`
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid ${theme.palette.grey[500]};
                padding: 24px 0;
            `}
        >
            <Box>
                <Box
                    component="p"
                    css={css`
                        font-family: ${fontFamilies.inter};
                        font-weight: ${fontWeights.normal};
                        font-size: ${fontSizes.f16};
                        line-height: 19px;
                        color: ${theme.palette.common.black};
                        margin: 0;
                    `}
                >
                    {member.fullName}
                </Box>

                <Box
                    component="p"
                    css={css`
                        font-family: ${fontFamilies.inter};
                        font-weight: ${fontWeights.normal};
                        font-size: ${fontSizes.f12};
                        line-height: 15px;
                        color: ${theme.palette.grey[200]};
                        margin: 0;
                    `}
                >
                    {member.email}
                </Box>
                <Box
                    component="p"
                    css={css`
                        font-family: ${fontFamilies.inter};
                        font-weight: ${fontWeights.normal};
                        font-size: ${fontSizes.f12};
                        line-height: 15px;
                        color: ${theme.palette.grey[200]};
                        margin: 8px 0 0 0;
                    `}
                >
                    {member.status === 'active' && (
                        <NoSsr>
                            {translate('user-active-since', { activationDate: dateFormatter(member.createdAt) })}
                        </NoSsr>
                    )}
                    {member.status === 'approval_needed' && translate('user-approval-needed')}
                    {member.status === 'archived' && translate('user-archived')}
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                {!isArchived && (
                    <Select
                        disabled={isCurrentUser || !canChangeRolePermission}
                        name="role"
                        value={selectedValue}
                        onChange={onRoleChange(member.id)}
                        css={css`
                            width: 176px;
                            margin-bottom: 0px;
                            ${(isCurrentUser || !canRemoveMemberPermission) && 'margin-right: 30px;'}
                            .MuiSelect-select {
                                height: 24px;
                                margin: 0;
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                font-family: ${fontFamilies.inter};
                                font-style: normal;
                                font-weight: ${fontWeights.normal};
                                font-size: ${fontSizes.f16};
                                line-height: 19px;
                                color: ${theme.palette.common.black};
                            }
                            .MuiSelect-icon {
                                right: 10px;
                                top: 10px !important;
                            }
                        `}
                    >
                        {roleOptions
                            .filter((r) => {
                                if (currentUserRole === TeamRolesEnum.User) {
                                    return true;
                                }
                                if (currentUserRole === TeamRolesEnum.Owner) {
                                    return true;
                                }

                                if (currentUserRole === TeamRolesEnum.Admin) {
                                    if (member.role === TeamRolesEnum.Owner) return true;
                                    if (r.value !== TeamRolesEnum.Owner) return true;
                                }
                                return false;
                            })
                            .map((role, i) => (
                                <MenuItem
                                    value={role.value}
                                    disabled={
                                        availableSeats <= 0 &&
                                        member.licenseType === LicenseTypesEnum.Reviewer &&
                                        role.value !== LicenseTypesEnum.Reviewer
                                    }
                                    key={i}
                                    css={css`
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                    `}
                                >
                                    <Box
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        {role.value === selectedValue && (
                                            <Circle
                                                css={css`
                                                    margin-right: 10px;
                                                `}
                                            />
                                        )}
                                        {translate(role.label)}
                                    </Box>
                                    <Box
                                        component="p"
                                        css={css`
                                            margin: 0;
                                            font-size: ${fontSizes.f12};
                                            line-height: 15px;
                                            color: ${theme.palette.common.black};
                                        `}
                                    >
                                        {currentUserLicenseType === LicenseTypesEnum.Reviewer &&
                                            role.value !== LicenseTypesEnum.Reviewer &&
                                            translate('seats-available', {
                                                availableSeats,
                                            })}
                                    </Box>
                                </MenuItem>
                            ))}
                    </Select>
                )}
                {iconButton}
            </Box>
            {memberListSubmenu}
        </li>
    );
};

export { UsersListRow };
