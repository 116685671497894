import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { GetUserRoleQuery, GetUserRoleQueryVariables, LicenseTypesEnum } from 'codegen/graphql';
import { config } from 'config';
import { GET_USER_ROLE } from 'shared/graphql/user-roles';
import { useSession } from 'shared/hooks';
import { FC } from 'shared/types';

// eslint-disable-next-line no-magic-numbers
const pollInterval = config.Env === 'production' ? 15 * 60 * 1000 : 5 * 60 * 1000;

const excludedPaths: string[] = [
    '/extension-action',
    '/extension-launcher',
    '/popup.html',
    '/frame.html',
    '/sidebar.html',
    '/extension-panel',
];

const ArchiveUserCheckContext = createContext<{ isUserArchived: boolean }>({ isUserArchived: false });

const ArchiveUserCheckProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const { session, loaded } = useSession();

    const [isArchived, setIsArchived] = useState<boolean>(false);

    const { data, startPolling, stopPolling } = useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GET_USER_ROLE, {
        fetchPolicy: 'network-only',
        skip: !loaded || !session?.user?.id || excludedPaths.includes(router.pathname),
        variables: {
            userId: session?.user?.id ?? '',
        },
    });

    useEffect(() => {
        if (data?.user_roles_by_pk?.licenseType === LicenseTypesEnum.Archived) {
            setIsArchived(true);
            stopPolling();
        } else {
            startPolling(pollInterval);
        }
    }, [data, startPolling, stopPolling]);

    const value = useMemo(() => ({ isUserArchived: isArchived }), [isArchived]);

    return <ArchiveUserCheckContext.Provider value={value}>{children}</ArchiveUserCheckContext.Provider>;
};

const useArchivedUserCheck = () => useContext(ArchiveUserCheckContext);
export { useArchivedUserCheck, ArchiveUserCheckProvider };
