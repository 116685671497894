import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { kebabCase } from 'lodash';
import { useTranslations } from 'next-intl';

import { Box } from 'shared/components/containers';
import { Chip, TextField } from 'shared/components/presentational';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC, SnakeToKebabCase } from 'shared/types';
import { SourcerPauseReasonsEnum } from 'sourcing/types';

interface PauseSourcerModalReasonsProps {
    pauseReasons?: SourcerPauseReasonsEnum[];
    onPauseReasonsChange: (newPauseReasons: SourcerPauseReasonsEnum[]) => void;
    notes?: string;
    onNotesChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    title: string;
}

const PauseSourcerModalReasons: FC<PauseSourcerModalReasonsProps> = ({
    pauseReasons = [],
    onPauseReasonsChange,
    notes,
    onNotesChange,
    title,
}) => {
    const theme = useTheme();
    const translate = useTranslations('sourcing.modals.pause-archive-modal');

    const pauseReasonsOptions = Object.values(SourcerPauseReasonsEnum).map((reason) => ({
        value: reason,
        label: translate(`pause-modal-reasons-${kebabCase(reason) as SnakeToKebabCase<SourcerPauseReasonsEnum>}`),
    }));

    const handleToggleReason = (pauseReason: SourcerPauseReasonsEnum) => () => {
        if (pauseReasons.includes(pauseReason)) {
            const newRejectionReasons = pauseReasons.filter((reason) => reason !== pauseReason);
            onPauseReasonsChange(newRejectionReasons);
        } else {
            onPauseReasonsChange([...pauseReasons, pauseReason]);
        }
    };

    return (
        <Box
            css={css`
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                margin-top: ${spacing.space16px};
                padding: ${spacing.space16px};
                gap: ${spacing.space16px};
                background: ${theme.palette.grey[300]};
                border: 1px solid ${theme.palette.grey[500]};
                border-radius: 4px;
            `}
        >
            <Box
                css={css`
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                `}
            >
                <Box
                    component="span"
                    css={css`
                        font-weight: ${fontWeights.bold};
                    `}
                >
                    {title}
                </Box>
                <Box
                    css={css`
                        font-size: ${fontSizes.f12};
                        text-transform: uppercase;
                        color: ${theme.palette.grey[600]};
                    `}
                    component="span"
                >
                    {translate('pause-modal-reasons-optional-label')}
                </Box>
            </Box>
            <Box
                css={css`
                    display: flex;
                    flex-flow: row wrap;
                    gap: ${spacing.space8px};
                `}
            >
                {pauseReasonsOptions.map((pauseReasonOption) => (
                    <Chip
                        css={css`
                            background: ${theme.palette.common.white};
                        `}
                        selected={pauseReasons?.includes(pauseReasonOption.value)}
                        clickable
                        key={pauseReasonOption.value}
                        onClick={handleToggleReason(pauseReasonOption.value)}
                        variant="outlined"
                        label={pauseReasonOption.label}
                    />
                ))}
            </Box>
            <TextField
                value={notes}
                onChange={onNotesChange}
                label={translate('pause-modal-reasons-note-label')}
                multiline
                minRows={3}
                maxRows={5}
                InputProps={{ style: { fontSize: `${fontSizes.f16}` } }}
                inputRef={(input) => input && input.focus()}
                parentBackgroundColor="gray"
                css={css`
                    width: 100%;
                `}
            />
        </Box>
    );
};

export { PauseSourcerModalReasons };
