import { createTheme, Theme } from '@mui/material/styles';

import { colors } from 'shared/settings/colors';
import { fontFamilies } from 'shared/settings/fonts';

const primaryTheme: Theme = createTheme({
    palette: {
        background: {
            default: colors.grays.alabaster,
        },
        error: {
            main: colors.reds.persianRed,
            contrastText: colors.reds.persianRed10,
        },
        primary: {
            main: colors.greens.eucalyptus,
            light: colors.greens.emerald,
            dark: colors.greens.hintOfGreen,
            contrastText: colors.greens.narvik,
        },
        secondary: {
            main: colors.blues.cello,
            light: colors.blues.eden,
            dark: colors.greens.jewel_200,
            contrastText: colors.greens.keppel,
        },
        grey: {
            100: colors.grays.miscka,
            200: colors.grays.tundora,
            300: colors.grays.alabaster,
            400: colors.grays.wildSand,
            500: colors.grays.gallery,
            600: colors.grays.dusty,
        },
        success: {
            main: colors.greens.eucalyptus,
        },
        warning: {
            main: colors.yellows.tulipTree,
        },
        info: {
            main: colors.yellows.picasso,
            light: colors.yellows.tulipTree,
            contrastText: colors.yellows.picasso20,
        },
        common: {
            white: colors.neutrals.white,
            black: colors.neutrals.black,
        },
    },
    typography: {
        fontFamily: fontFamilies.inter,
    },
});

const themes = {
    primaryTheme,
};

export { themes };
