import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';

import { FC } from 'shared/types';

interface MagicSentenceProps {
    magicSentenceUsed: boolean;
    setMagicSentenceUsed: Dispatch<SetStateAction<boolean>>;
    checkMagicSentenceUsed: () => void;
}

const MagicSentenceContext = createContext<MagicSentenceProps>({} as MagicSentenceProps);

/**
 * check if the magic sentence is used within any of the stages for a given sequence
 * this check should be executed if:
 * - the magic sentence is inserted
 * - the magic sentence is removed
 * - changes are discarded
 * - a stage is removed
 */
const MagicSentenceContextProvider: FC<MagicSentenceProps> = ({
    magicSentenceUsed,
    setMagicSentenceUsed,
    checkMagicSentenceUsed,
    children,
}) => {
    const value = useMemo(
        () => ({
            magicSentenceUsed,
            setMagicSentenceUsed,
            checkMagicSentenceUsed,
        }),
        [magicSentenceUsed, setMagicSentenceUsed, checkMagicSentenceUsed]
    );

    return <MagicSentenceContext.Provider value={value}>{children}</MagicSentenceContext.Provider>;
};

const useMagicSentence = () => useContext(MagicSentenceContext);

export { MagicSentenceContextProvider, MagicSentenceContext, useMagicSentence };
