import styled from '@emotion/styled';
import { css, TableBody as MuiTableBody, TableBodyProps as MuiTableBodyProps } from '@mui/material';

import { NoResultsState } from 'shared/components/composite';
import { Skeleton } from 'shared/components/presentational';
import { TableCell, TableRow } from 'shared/components/table';
import { fontSizes, fontWeights, spacing } from 'shared/settings';
import { FC } from 'shared/types';

const borderRadius = spacing.space4px;
const defaultColSpan = 100;
const defaultLoadingRows = 10;

interface TableBodyProps extends MuiTableBodyProps {
    colSpan?: number; // avoid defaulting colSpan to a large number (like 100) as this can cause performance issues
    hidden?: boolean;
    loading?: boolean; // prevents the no results state component from "flashing" when querying
    loadingRows?: number;
    clearFilters?: () => void;
}

const TableBody: FC<TableBodyProps> = (props) => {
    const {
        colSpan = defaultColSpan,
        hidden = false,
        loading = false,
        loadingRows = defaultLoadingRows,
        clearFilters,
        ...rest
    } = props;

    const renderLoadingRows = () =>
        Array.from({ length: loadingRows }, (_, index) => (
            <TableRow key={index}>
                <TableCell colSpan={colSpan}>
                    <Skeleton variant="text" animation="wave" />
                </TableCell>
            </TableRow>
        ));

    return hidden ? (
        <MuiTableBody>
            {loading ? (
                renderLoadingRows()
            ) : (
                <TableRow>
                    <TableCell
                        colSpan={colSpan}
                        css={css`
                            padding: 0;
                            border-bottom: unset;
                        `}
                    >
                        <NoResultsState clearFilters={clearFilters} />
                    </TableCell>
                </TableRow>
            )}
        </MuiTableBody>
    ) : (
        <MuiTableBody {...rest} />
    );
};

const StyledTableBody = styled(TableBody)`
    &:before {
        content: '-';
        display: block;
        line-height: 8px;
        color: transparent;
    }

    .MuiTableRow-root:first-of-type {
        td,
        th {
            border-top: 1px solid ${({ theme }) => theme.palette.grey[500]};
        }
        th:first-of-type {
            border-top-left-radius: ${borderRadius};
        }
        td:last-of-type {
            border-top-right-radius: ${borderRadius};
        }
    }

    .MuiTableRow-root:last-of-type {
        th:first-of-type {
            border-bottom-left-radius: ${borderRadius};
        }
        td:last-of-type {
            border-bottom-right-radius: ${borderRadius};
        }
    }

    .MuiTableRow-root {
        cursor: pointer;
        th {
            border-left: 1px solid ${({ theme }) => theme.palette.grey[500]};
        }
    }

    ${({ theme }) => `
    .MuiTableRow-root:hover:not(.Mui-selected) {
        background-color: ${theme.palette.grey[300]};

        .MuiTableCell-root {
            background-color: ${theme.palette.grey[300]};
        }
    }

    .MuiTableCell-root {
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeights.normal};
        
        background-color: ${theme.palette.common.white};
        border-bottom: 1px solid ${theme.palette.grey[500]};
        border-right: 1px solid ${theme.palette.grey[500]};
    }
    `}
`;

export { StyledTableBody as TableBody };
