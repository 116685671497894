import { withTheme } from '@emotion/react';

import { FC, SvgProps } from 'shared/types';

const ThemedMinimize01: FC<SvgProps> = ({ stroke, theme, width, height, fill, ...rest }) => (
    <svg
        {...rest}
        width={width || '20'}
        height={height || '20'}
        viewBox={`0 0 ${width || '20'} ${height || '20'}`}
        fill={fill || 'none'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 12H8M8 12V18M8 12L1 19M18 8H12M12 8V2M12 8L19 1"
            stroke={stroke ?? theme?.palette.common.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Minimize01 = withTheme(ThemedMinimize01);

export { Minimize01 };
