import { css } from '@mui/material';
import { useTranslations } from 'next-intl';

import { RecipientStatusEnum } from 'codegen/graphql';
import { Box } from 'shared/components/containers';
import { SelectSequence } from 'shared/components/form';
import { Button, Modal, ModalCloseButton, Title } from 'shared/components/presentational';
import { NO_SEQUENCE } from 'shared/graphql/sequences';
import { fontSizes, spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface RecipientDetailsForSequenceSelection {
    id: string;
    status: RecipientStatusEnum;
    sequence: {
        id: string;
        title: string;
    };
}

interface ChooseSequenceModalProps {
    open: boolean;
    handleClose: () => void;
    recipients: RecipientDetailsForSequenceSelection[];
    selectedSequence: string | undefined;
    handleSequenceChange: (value: string) => void;
    handleConfirmSequence: () => void;
}

const ChooseSequenceModal: FC<ChooseSequenceModalProps> = (props) => {
    const { open, handleClose, recipients, selectedSequence, handleConfirmSequence, handleSequenceChange } = props;
    const translate = useTranslations('sequence.recipient-list-table');

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                css={css`
                    padding: ${spacing.space24px};
                `}
            >
                <ModalCloseButton onClose={handleClose} />
                <Title
                    type="h3"
                    css={css`
                        font-size: ${fontSizes.f24};
                    `}
                >
                    {translate('choose-recipient-form.title')}
                </Title>
                <Box
                    css={css`
                        display: flex;
                        flex-flow: column nowrap;
                        gap: ${spacing.space12px};
                    `}
                >
                    <Box>{translate('choose-recipient-form.description')}</Box>
                    <SelectSequence
                        width="100%"
                        label={translate('choose-recipient-form.input-label')}
                        name="seqSelect"
                        options={recipients
                            .filter(
                                (recipient) =>
                                    recipient.status !== RecipientStatusEnum.MeetingBooked &&
                                    recipient.status !== RecipientStatusEnum.Unsubscribed
                            )
                            .map((recipient) => ({
                                value: recipient.sequence.id,
                                label: recipient.sequence.title,
                            }))}
                        value={selectedSequence ?? NO_SEQUENCE}
                        onChange={handleSequenceChange}
                        noOptionsText={translate('choose-recipient-form.no-sequence-found')}
                    />
                    <Box
                        css={css`
                            display: flex;
                            margin-top: ${spacing.space16px};
                            justify-content: flex-end;
                            gap: ${spacing.space10px};
                        `}
                    >
                        <Button onClick={handleClose} variant="outlined" color="inherit">
                            {translate('choose-recipient-form.button-cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!selectedSequence || selectedSequence === NO_SEQUENCE}
                            onClick={handleConfirmSequence}
                        >
                            {translate('choose-recipient-form.button-next')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export { ChooseSequenceModal };
