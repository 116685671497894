import { css } from '@emotion/react';
import { styled, MenuItem, useTheme } from '@mui/material';
import Image from 'next/image';
import { ChangeEvent, useEffect, useState } from 'react';

import { Box } from 'shared/components/containers';
import { IconButton, Popover, Title, TextField } from 'shared/components/presentational';
import { useTitleColorUpdateHandler } from 'shared/hooks';
import { colorPickerOptions, colors } from 'shared/settings';
import { FC } from 'shared/types';

interface BreadcrumbProps {
    title: string;
    icon?: React.ReactNode;
    isLastItem?: boolean;
}

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
    const { title, icon, isLastItem = false } = props;

    const theme = useTheme();
    const { color: currentColor, isEditable, handleTitleUpdate, handleColorUpdate } = useTitleColorUpdateHandler();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [editableTitle, setEditableTitle] = useState<string>(title);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEditableTitle(event.target.value);
    };

    useEffect(() => {
        setEditableTitle(title);
    }, [title]);

    const open: boolean = Boolean(anchorEl);
    const popoverId: string | undefined = open ? 'breadcrumb-popover' : undefined;

    return (
        <>
            <Title
                type="h1"
                flat
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: ${isLastItem ? colors.neutrals.black : 'inherit'};
                `}
            >
                {isLastItem && icon && (
                    <IconButton
                        onClick={handleClick}
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        {icon}
                    </IconButton>
                )}
                <Box
                    css={css`
                        flex: 1;
                    `}
                >
                    {handleTitleUpdate !== undefined && isLastItem ? (
                        <TextField
                            disabled={!isEditable}
                            value={editableTitle}
                            onChange={handleChange}
                            onBlur={() => {
                                handleTitleUpdate(editableTitle)();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleTitleUpdate(editableTitle)();
                                }
                            }}
                            css={css`
                                min-width: 100%;
                                flex: 1;
                                .MuiFilledInput-root {
                                    width: 100%;
                                    transition: width 0.5s;
                                }
                                .MuiFilledInput-root:hover,
                                .MuiFilledInput-root.Mui-focused {
                                    padding: 0 4px;
                                    transition: padding 0.2s;
                                }
                                .MuiFilledInput-root:not(:hover) {
                                    transition: padding 0.2s;
                                }
                                input.MuiFilledInput-input {
                                    font-size: 24px;
                                    font-weight: bold;
                                    padding: 0;
                                    width: 100%;
                                    text-overflow: ellipsis;
                                }
                                .MuiFilledInput-root {
                                    border-color: ${theme.palette.grey[300]};
                                    border-width: 2px;
                                }
                                .MuiFilledInput-root:hover,
                                .MuiFilledInput-root.Mui-focused {
                                    border-color: ${theme.palette.grey[100]};
                                }
                                .MuiFilledInput-root.Mui-focused {
                                    background-color: ${theme.palette.common.white};
                                }
                                .MuiFilledInput-root.Mui-disabled {
                                    background-color: unset;
                                    border: unset;
                                }
                                .MuiFilledInput-input.Mui-disabled {
                                    -webkit-text-fill-color: ${theme.palette.common.black};
                                }
                            `}
                        />
                    ) : (
                        title
                    )}
                </Box>
            </Title>

            {handleColorUpdate !== undefined && (
                <Popover
                    id={popoverId}
                    open={!isEditable ? false : open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        css={css`
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            max-width: 300px;
                        `}
                    >
                        {colorPickerOptions.map((color: string) => (
                            <MenuItem value={color} key={color}>
                                <Box
                                    onClick={handleColorUpdate(color)}
                                    css={css`
                                        background-color: ${color};
                                        width: 28px;
                                        height: 28px;
                                        border-radius: 50%;
                                        padding: 6px;
                                    `}
                                >
                                    {color === currentColor && (
                                        <Image src="/check.svg" alt="checkmark" width={16} height={16} />
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                    </Box>
                </Popover>
            )}
        </>
    );
};

const StyledBreadcrumb = styled(Breadcrumb)``;

export { StyledBreadcrumb as Breadcrumb };
