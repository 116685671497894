import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { UseFormReturn } from 'react-hook-form';

import { OverrideToggleFormValues } from 'integrations/types';
import { Box } from 'shared/components/containers';
import { Switch } from 'shared/components/form';
import { spacing } from 'shared/settings';
import { FC } from 'shared/types';

interface TeamSettingsOverrideToggleProps {
    formMethods: UseFormReturn<OverrideToggleFormValues & any>;
}

const TeamSettingsOverrideToggle: FC<TeamSettingsOverrideToggleProps> = ({ formMethods }) => {
    const translate = useTranslations('integrations');

    return (
        <Box
            css={css`
                padding-bottom: ${spacing.space8px};
            `}
        >
            <Switch label={translate('team-settings-override-label')} formMethods={formMethods} name="teamOverride" />
        </Box>
    );
};

export { TeamSettingsOverrideToggle };
